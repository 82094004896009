import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import { PlayerStatistics } from '@app/types/teamsTypes';
import {
  BlockContainer, StatsBlock, Value, Caption, RedCardImage, YellowCardImage,
} from '@app/components/Statistics';

interface DisciplineStatisticsProps {
  statistics?: PlayerStatistics;
}

const DisciplineStatistics = ({ statistics }: DisciplineStatisticsProps): React.ReactElement => (
  <BlockContainer>
    <StatsBlock>
      <YellowCardImage />
      <Value>{statistics?.yellowCards ?? 0}</Value>
      <Caption><FormattedHTMLMessage id="statistics.yellowCards" /></Caption>
    </StatsBlock>
    <StatsBlock>
      <RedCardImage />
      <Value>{statistics?.redCards ?? 0}</Value>
      <Caption><FormattedHTMLMessage id="statistics.redCards" /></Caption>
    </StatsBlock>
  </BlockContainer>
);

export default DisciplineStatistics;
