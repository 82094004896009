import { KenticoElement } from './responseTypes';
import {
  AssetElementValue,
  DateTimeElementValue,
  MultiplyChoiceElementValue, NumberElementValue, RichTextElementValue,
  TaxonomyElementValue,
  TextElementValue,
  UrlSlugElementValue,
  ItemWithSeo,
} from './elementTypes';

export enum TeamSquadTypes {
  MenSquad = 'men_squad',
  WomenSquad = 'women_squad',
  PrimaveraSquad = 'primavera_squad',
  FuturoSquad = 'futuro_squad'
}

export interface KenticoPlayer extends KenticoElement, ItemWithSeo {
  id: TextElementValue;
  first_name: TextElementValue;
  last_name: TextElementValue;
  squad: MultiplyChoiceElementValue;
  teams: TaxonomyElementValue;
  number: NumberElementValue;
  position: MultiplyChoiceElementValue;
  birth_date: DateTimeElementValue;
  birth_place: TextElementValue;
  nationality: TextElementValue;
  height: TextElementValue;
  weight: TextElementValue;
  preferred_foot: MultiplyChoiceElementValue;
  join_date: DateTimeElementValue;
  store_url: TextElementValue;
  biography: RichTextElementValue;
  photo_large: AssetElementValue;
  photo_medium: AssetElementValue;
  photo_small: AssetElementValue;
  url: UrlSlugElementValue;
  hide_player: MultiplyChoiceElementValue;
}

export interface KenticoCoach extends KenticoElement, ItemWithSeo {
  id: TextElementValue;
  title__h2_: TextElementValue;
  coach_name: TextElementValue;
  coach_surname: TextElementValue;
  coach_birthdate: DateTimeElementValue;
  coach_birthplace: TextElementValue;
  coach_nationality: TextElementValue;
  coach_since: DateTimeElementValue;
  at_ac_milan_since: DateTimeElementValue;

  career_title__h3_: TextElementValue;
  career: RichTextElementValue;

  biography_title__h3_: TextElementValue;
  biography: RichTextElementValue;

  coach_photo: AssetElementValue;

  position: MultiplyChoiceElementValue;

  squad: MultiplyChoiceElementValue;
  teams: TaxonomyElementValue;

  url: UrlSlugElementValue;

  hide_player: MultiplyChoiceElementValue;
}


export interface KenticoTeamName extends KenticoElement {
  team_opta_id: TextElementValue;
  regular_name: TextElementValue;
  shortname: TextElementValue;
}
