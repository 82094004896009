import { AdSlotStatus, AdBaseStatus } from '@app/types/googleTypes';
import { GoogleActions, GoogleActionTypes } from '@app/store/actionTypes/googleActionTypes';

export type GoogleState = {
  gpt: {
    slots: AdSlotStatus;
    isDownloaded: AdBaseStatus;
  };
};

const initialState: GoogleState = {
  gpt: {
    slots: {},
    isDownloaded: null,
  },
};

export default (
  state = initialState,
  action: GoogleActions,
): GoogleState => {
  switch (action.type) {
    case GoogleActionTypes.SET_GOOGLE_PUBLISHER_TAGS_STATUS:
      return {
        ...state,
        gpt: {
          slots: state.gpt.slots,
          isDownloaded: action.payload,
        },
      };
    case GoogleActionTypes.SET_GOOGLE_AD_SLOT_STATUS:
      return {
        ...state,
        gpt: {
          slots: { ...state.gpt.slots, ...action.payload },
          isDownloaded: state.gpt.isDownloaded,
        },
      };
    case GoogleActionTypes.RESET_GOOGLE_AD_SLOT_STATUS:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-case-declarations
      const { [action.payload]: removedSlot, ...restSlots } = state.gpt.slots;
      return {
        ...state,
        gpt: {
          slots: restSlots,
          isDownloaded: state.gpt.isDownloaded,
        },
      };
    default:
      return state;
  }
};
