import React from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { AppState } from '@app/store/reducers';
import { TopNews } from '@app/types/newsTypes';
import { CarouselTopNews } from '@app/components';

const TicketsCarousel = (): React.ReactElement => {
  const slides = useSelector<AppState, TopNews[]>(
    (state) => state.ticketing.carousel,
    isEqual,
  );

  return (<CarouselTopNews isOverlapped={false} slides={slides} />);
};

export default TicketsCarousel;
