import React from 'react';
import { CircleSvg } from '@app/components/Icons/CircleInfosIcon';

const CircleBundleIcon = (): React.ReactElement => (
  <CircleSvg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <g fill="none" fillRule="evenodd">
      <circle cx="15" cy="15" r="15" fill="#C9240D" />
      <g fill="#FFF" fillRule="nonzero">
        <path d="M13.203 11.827a.4.4 0 0 0-.483-.293l-.972.236a.4.4 0 1 0 .19.778l.971-.237a.4.4 0 0 0 .294-.484zM11.066 12.348a.4.4 0 0 0-.484-.294l-.972.237a.4.4 0 1 0 .19.777l.972-.237a.4.4 0 0 0 .294-.483z" />
        <path d="M23.141 9.158l-1.287-.313a.5.5 0 0 0-.533.206 1.794 1.794 0 0 1-1.905.739 1.792 1.792 0 0 1-1.35-1.532.5.5 0 0 0-.378-.428L16.4 7.516a1.101 1.101 0 0 0-1.329.809l-.035.145-.105-.432a1.092 1.092 0 0 0-.497-.679 1.09 1.09 0 0 0-.832-.13l-1.287.314a.5.5 0 0 0-.378.428 1.794 1.794 0 0 1-1.351 1.532 1.796 1.796 0 0 1-1.905-.739.5.5 0 0 0-.533-.206l-1.287.313c-.285.07-.526.246-.679.498a1.08 1.08 0 0 0-.132.831l2.698 11.076c.07.285.246.526.497.679a1.096 1.096 0 0 0 .832.13l4.335-1.057 5.512 1.342a1.103 1.103 0 0 0 .83-.13c.252-.152.429-.394.498-.678l2.698-11.075a1.09 1.09 0 0 0-.13-.831 1.09 1.09 0 0 0-.679-.498zM9.84 21.113a.097.097 0 0 1-.075-.012.099.099 0 0 1-.046-.061l-1.828-7.506.743-.182a.4.4 0 0 0-.189-.777l-.743.181-.68-2.792a.097.097 0 0 1 .012-.075.099.099 0 0 1 .061-.045l.974-.238a2.801 2.801 0 0 0 2.751.87 2.8 2.8 0 0 0 2.044-2.038l.974-.237a.097.097 0 0 1 .075.013c.016.01.037.029.045.06l.562 2.308-.133.545-.503.122a.4.4 0 1 0 .19.777l.1-.024-1.801 7.398a1.09 1.09 0 0 0 .284 1.026l-2.817.687zM22.978 10.25l-.68 2.792-.743-.181a.4.4 0 1 0-.189.777l.743.182-1.828 7.507a.099.099 0 0 1-.045.06.097.097 0 0 1-.075.013l-6.742-1.642a.099.099 0 0 1-.061-.045.097.097 0 0 1-.013-.075l1.828-7.507.753.183a.398.398 0 0 0 .484-.294.4.4 0 0 0-.294-.483l-.753-.184.68-2.792a.102.102 0 0 1 .098-.077c.008 0 .015.001.023.004l.973.236a2.8 2.8 0 0 0 2.044 2.038c1.011.246 2.074-.1 2.752-.87l.973.237a.1.1 0 0 1 .072.121z" />
        <path d="M20.39 12.577l-.972-.236a.4.4 0 0 0-.19.777l.972.237a.398.398 0 0 0 .484-.294.4.4 0 0 0-.294-.484zM18.252 12.056l-.971-.237a.4.4 0 1 0-.19.778l.972.237a.398.398 0 0 0 .484-.294.402.402 0 0 0-.295-.484z" />
      </g>
    </g>
  </CircleSvg>
);

export default CircleBundleIcon;
