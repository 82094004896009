import React from 'react';
import { IntlProvider } from 'react-intl';
import { Route, Redirect } from 'react-router-dom';
import trimEnd from 'lodash/trimEnd';

import * as Translations from '@app/locales';
import { AppLanguages } from '@app/constants/localizationConstants';

export const WrappedIntlProvider: React.FC = (({ children }) => (
  <Route path="/:lang([a-z]{0,2})">
    {({ match, location }): React.ReactElement => {
      const params = match ? match.params : {};
      const { lang } = params;
      const hasCurrentLang = AppLanguages.find((language) => language === lang);

      const defaultLanguage = AppLanguages[1];
      if (!hasCurrentLang && lang === '') {
        return <Redirect to={`/${defaultLanguage}`} />;
      }

      const urlWithoutTrailingSlashes = trimEnd(location.pathname, '/');

      if (location.pathname !== urlWithoutTrailingSlashes) {
        return <Redirect to={urlWithoutTrailingSlashes} />;
      }

      const language = hasCurrentLang ?? defaultLanguage;
      return (
        // Please note: it is important to set defaultLocale as current lang. Otherwise local is set incorrectly in ssr
        <IntlProvider locale={language} defaultLocale={language} messages={Translations[language]}>
          {children}
        </IntlProvider>
      );
    }}
  </Route>
));

export default WrappedIntlProvider;
