import { useIntl } from 'react-intl';

import { Footer } from '@app/types/configurationTypes';
import { shallowEqual, useSelector } from 'react-redux';
import { AppState } from '@app/store/reducers';

export function useFooter(): Footer {
  const { locale } = useIntl();
  return useSelector<AppState, Footer>(
    (state) => state.configuration[locale]?.footer,
    shallowEqual,
  );
}
