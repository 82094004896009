import React from 'react';
import Helmet from 'react-helmet';

interface MetaJsonLDProps {
  json: unknown;
}

const MetaJsonLD = ({ json }: MetaJsonLDProps): React.ReactElement => (
  <Helmet>
    <script type="application/ld+json">
      {JSON.stringify(json)}
    </script>
  </Helmet>
);

export default MetaJsonLD;
