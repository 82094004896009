import React from 'react';

import { findAndFormatSubMenuLinkByIdProps, formatRelatedItemLinkProps } from '@app/helpers/menuHelpers';

import { theme } from '@app/styles';
import {
  ColorizedButton, ColorizedButtonLink, ColorizedExternalLink, ColorizedNoSeoButtonLink,
} from '@app/components/Buttons/ColorizedButton';
import { useMenu } from '@app/components/Hooks';
import { useMenSquadAllCompetitionsParams } from '@app/pages/Season/Season/SeasonHooks';

import { WebCtaButton, WebCtaButtonTemplateType } from '@app/types/webTemplateTypes';
import { MenuItemTypes, RelatedMenuItem } from '@app/types/configurationTypes';
import AppRoutes from '@app/constants/routes';

interface WebCtaButtonElementProps {
  data: WebCtaButton;
  disableSeoTitleTag?: boolean;
  onClick?: () => void;
}

const WebCtaButtonElement = ({
  data, onClick, disableSeoTitleTag,
}: WebCtaButtonElementProps): React.ReactElement | null => {
  const menu = useMenu();
  const menuItems = Object.values(menu ?? {});
  const { seasonId, urlSlug } = useMenSquadAllCompetitionsParams();

  // TODO:REWORK:STYLES:AFTER ColorizedButton will be refactoried
  const buttonStyle = data.template === WebCtaButtonTemplateType.Transparent ? {
    color: 'transparent',
    borderColor: theme.color.freeSpeechRed,
    invertBackgroundColor: theme.color.freeSpeechRed,
    invertColor: '#ffffff',
  } : {
    color: theme.color.freeSpeechRed,
  };

  switch (data.menuItem?.type) {
    case MenuItemTypes.ExternalLink:
      return (
        <ColorizedExternalLink
          {...buttonStyle}
          url={data.menuItem?.external?.url?.href ?? ''}
        >
          {data.label}
        </ColorizedExternalLink>
      );
    case MenuItemTypes.BaseMenuItem:
      // eslint-disable-next-line no-case-declarations
      const ButtonLink = disableSeoTitleTag ? ColorizedNoSeoButtonLink : ColorizedButtonLink;

      return menu ? (
        <ButtonLink
          {...buttonStyle}
          {...findAndFormatSubMenuLinkByIdProps(menuItems, data.menuItem?.id)}
          onClick={onClick}
        >
          {data.label}
        </ButtonLink>
      ) : null;
    case MenuItemTypes.RelatedMenuItem:
      return (
        <ColorizedButtonLink
          {...buttonStyle}
          {...formatRelatedItemLinkProps(data.menuItem as RelatedMenuItem, seasonId, urlSlug)}
          onClick={onClick}
        >
          {data.label}
        </ColorizedButtonLink>
      );
    default: return onClick ? (
      <ColorizedButton {...buttonStyle} onClick={(): void => onClick()}>
        {data.label}
      </ColorizedButton>
    ) : (
      <ColorizedButtonLink {...buttonStyle} pathKey={AppRoutes.Home.path}>
        {data.label}
      </ColorizedButtonLink>
    );
  }
};

export default WebCtaButtonElement;
