import cloneDeep from 'lodash/cloneDeep';

import { TopNews } from '@app/types/newsTypes';
import {
  LocalizedTicketingConfiguration, LocalizedTicketingSubscription, TicketingSingle, PackageTicketsInfo,
} from '@app/types/ticketingTypes';
import { TicketingActions, TicketingActionTypes } from '@app/store/actionTypes/ticketingActionTypes';

export type TicketingState = {
  configuration: LocalizedTicketingConfiguration;
  carousel: TopNews[];
  single: TicketingSingle;
  packages: PackageTicketsInfo[];
  subscription: LocalizedTicketingSubscription;
};

const initialState: TicketingState = {
  configuration: {},
  carousel: [],
  single: null,
  packages: [],
  subscription: {},
};

export default (
  state: TicketingState = cloneDeep(initialState),
  action: TicketingActions,
): TicketingState => {
  switch (action.type) {
    case TicketingActionTypes.SET_TICKETING_CONFIGURATION:
      return {
        ...state,
        configuration: action.payload,
      };
    case TicketingActionTypes.RESET_TICKETING_CONFIGURATION:
      return {
        ...state,
        configuration: initialState.configuration,
      };
    case TicketingActionTypes.SET_TICKETING_SINGLE:
      return {
        ...state,
        single: action.payload,
      };
    case TicketingActionTypes.RESET_TICKETING_SINGLE:
      return {
        ...state,
        single: initialState.single,
      };
    case TicketingActionTypes.SET_TICKETING_PACKAGES:
      return {
        ...state,
        packages: action.payload,
      };
    case TicketingActionTypes.RESET_TICKETING_PACKAGES:
      return {
        ...state,
        packages: initialState.packages,
      };
    case TicketingActionTypes.SET_TICKETING_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload,
      };
    case TicketingActionTypes.RESET_TICKETING_SUBSCRIPTION:
      return {
        ...state,
        subscription: initialState.subscription,
      };
    case TicketingActionTypes.SET_TICKETING_CAROUSEL:
      return {
        ...state,
        carousel: action.payload,
      };
    case TicketingActionTypes.RESET_TICKETING_CAROUSEL:
      return {
        ...state,
        carousel: initialState.carousel,
      };
    default:
      return state;
  }
};
