import React from 'react';
import styled from 'styled-components';

import { LocalizedDate } from '@app/components/Localization';
import { PremiumBadge } from '@app/components';

interface NewsContentHeadingProps {
  publicationDate: string;
  categoryName: string;
  title: string;
  subTitle: string;
  isPremium: boolean;
}

const NewsContentHeading = ({
  title, categoryName, publicationDate, subTitle, isPremium,
}: NewsContentHeadingProps): React.ReactElement => (
  <NewsHeadingSection>
    <NewsTaxonomyContainer>
      <div>
        <NewsTaxonomy>
          {categoryName}
        </NewsTaxonomy>
        {isPremium && (<PremiumBadge />)}
      </div>
      <NewsPublicationDate>
        <LocalizedDate date={publicationDate} />
      </NewsPublicationDate>
    </NewsTaxonomyContainer>
    <NewsTitle>
      {title}
    </NewsTitle>
    <VideoSubTitle>{subTitle}</VideoSubTitle>
  </NewsHeadingSection>
);

export default NewsContentHeading;

const NewsHeadingSection = styled.section`
  width: 100%;
  padding: 0;
  margin: 0;
  background: transparent;
  color: inherit; // Use defined on page text color
`;

const NewsTaxonomyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 0;
`;

const NewsTaxonomy = styled.span`
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  padding-right: 35px;
  margin-right: 15px;

  &::after {
    content: "";
    height: 3px;
    width: 25px;
    border: none;
    background-color: ${(props): string => props.theme.color.freeSpeechRed};
    position: absolute;
    right: 0;
    top: calc((100% - 3px) / 2);
  }
`;

const NewsPublicationDate = styled.span`
  font-size: 14px;
`;

const NewsTitle = styled.h1`
  font-size: 25px;
  font-weight: bold;
  text-transform: uppercase;
`;

const VideoSubTitle = styled.h2`
  text-align: justify;
  margin: 10px 0;
  padding: 0;
  font-weight: bold;
  font-size: 14px;
`;
