import React from 'react';
import { Devices } from '@app/styles';
import { WebHallOfFameHighlights } from '@app/types/webTemplateTypes';
import { WebCtaButtonElement } from '@app/components/WebTemplates/elements';
import styled from 'styled-components';

export const HOFBanner = ({ title, description, cta }: WebHallOfFameHighlights['banner']): React.ReactElement => (
  <Banner>
    <BannerText>
      <BannerTitle>{title}</BannerTitle>
      <BannerDescription>{description}</BannerDescription>
    </BannerText>
    <BannerCta>
      <WebCtaButtonElement data={cta} />
    </BannerCta>
  </Banner>
);

const Banner = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  align-content: center;
  gap: 25px;
  padding: 30px;
  margin: 0;

  width: 100%;
  height: auto;
  min-height: 140px;

  background-image: url(/images/hall-of-fame/banner_xs.webp);
  background-size: cover;
  background-position: center;
  background-color: black;
  overflow: hidden;
  z-index: 1;

  @media ${Devices.tabletLarge} {
    flex-direction: row;
    align-items: center;
    margin: 50px 0;
    background-image: url(/images/hall-of-fame/banner.webp);
  }

  &::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }
`;

const BannerText = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
  gap: 15px;
  color: white;
  z-index: 10;

  @media ${Devices.tabletLarge} {
    gap: 0px;
  }
`;

const BannerTitle = styled.h2`
  text-transform: uppercase;
  font-family: MilanTypeRev, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.42px;
  color: #fff;
`;

const BannerDescription = styled.p`
  font-size: 20px;
  font-family: Raleway, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.42px;
  color: #fff;
`;

const BannerCta = styled.div`
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  align-content: center;
  z-index: 10;

  & > * {
    min-width: 150px;
    min-height: 40px;
    font-family: MilanTypeRev, Helvetica Neue, Helvetica, Arial, sans-serif!important;
    font-size: 16px!important;
    font-weight: bold!important;
    font-stretch: normal!important;
    font-style: normal!important;
    line-height: normal!important;
    letter-spacing: 0.34px!important;
    text-align: center!important;
    padding: 0 20px!important;

    & > * {
      font-family: MilanTypeRev, Helvetica Neue, Helvetica, Arial, sans-serif!important;
      font-size: 16px!important;
      font-weight: bold!important;
      font-stretch: normal!important;
      font-style: normal!important;
      line-height: normal!important;
      letter-spacing: 0.34px!important;
      text-align: center!important;
    }
  }
`;
