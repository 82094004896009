import React from 'react';

const HorizontalArrowLeftIcon = (): React.ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 9 15">
    <g fillRule="nonzero">
      <path d="M7.6 7.284L1.357 14.4 1 13.993l6.243-7.115z" />
      <path d="M7.243 7.522L1 .407 1.357 0 7.6 7.116z" />
    </g>
  </svg>
);

export default HorizontalArrowLeftIcon;
