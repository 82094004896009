import React from 'react';
import styled from 'styled-components';

import { WebDepartment, WebTemplateTypes } from '@app/types/webTemplateTypes';
import { PageTitle } from '@app/components';
import { Devices } from '@app/styles';

import { ContainerMedium } from '../components';
import WebTemplateSelect from '../WebTemplateSelect';
import WebPartnerElement from './WebPartnerElement';

interface WebDepartmentElementProps {
  data: WebDepartment;
}

const WebDepartmentElement = ({ data }: WebDepartmentElementProps): React.ReactElement => (
  <Container>
    {data?.title && (<PageTitle>{data?.title}</PageTitle>)}
    {data?.name && (<Name>{data?.name}</Name>)}
    <Content>
      {data?.content?.filter((content) => content?.id !== 'scudetto').map((content) => { // TODO:REMOVE:FILTER
        // TODO:REVERT:WHEN:ALL:DEPARTMENT:ITEMS:WILL:BE:RESTYLED [THE SAME WAY AS WEB-PARTNERS]
        switch (content?.type) {
          case WebTemplateTypes.WebPartner: return (
            <WebPartnerElement key={content?.id} type={data.id} data={{ ...content, showDivider: false }} />
          );
          default: return (
            <ContentItem key={content?.id}>
              <WebTemplateSelect data={content} />
              {data?.showDivider && <Divider />}
            </ContentItem>
          );
        }
      })}
    </Content>
    <RedDivider />
  </Container>
);

const RedDivider = styled.div`
  margin-top: 40px;
  width: 50px;
  height: 3px;
  background-color: ${(props): string => props.theme.color.harleyDavidsonOrange};
`;

const Container = styled(ContainerMedium)`
  &:last-of-type ${RedDivider} {
    display: none;
  }
`;

const Name = styled.h2`
  margin-top: 25px;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 15px;
  letter-spacing: 0.23px;
  text-transform: uppercase;
  color: #000000;

  @media ${Devices.largeMobile} {
    font-size: 17px;
  }

  @media ${Devices.desktop} {
    font-size: 20px;
  }
`;

const Divider = styled.div`
  display: none;
  width: 1px;
  height: 280px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 90px 20px 0 40px;

  @media ${Devices.desktop} {
    display: block;
  }
`;

const Content = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ContentItem = styled.div`
  margin: 0 20px 20px 0;
  display: flex;
  flex-direction: row;

  &:nth-of-type(2n) {
    margin-right: 0;
  }

  @media ${Devices.largeMobile} {
    &:nth-of-type(2n) {
      margin-right: 20px;
    }
  }

  @media ${Devices.desktop} {
    &:last-of-type ${Divider}, &:nth-of-type(3n) ${Divider} {
      display: none;
    }
  }
`;

export default WebDepartmentElement;
