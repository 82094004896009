import { TeamStatistics } from '@app/types/teamsTypes';
import { sendRequestTyped } from './request';
import { convertTeamStatistics } from './converters/teamsConverter';
import { FeedTypes } from './constants/feedTypes';
import { OptaTeamCompetitionResponse } from './types/responseTypes';

interface GetTeamDataInterface {
  seasonId: string;
  teamId: string;
  optaId: string;
}

/* eslint-disable @typescript-eslint/camelcase */
export async function getTeamStatistics(requestParams: GetTeamDataInterface): Promise<TeamStatistics> {
  const params = {
    feed_type: FeedTypes.teams,
    json: true,
    season_id: requestParams.seasonId,
    team_id: requestParams.teamId,
    competition: requestParams.optaId,
  };

  const optaResponse = await sendRequestTyped<OptaTeamCompetitionResponse>({
    path: 'team_competition.php',
    params,
  });

  return convertTeamStatistics(optaResponse);
}
