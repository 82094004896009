import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { GA_EVENT, GA_CATEGORY } from '@app/types/googleTypes';
import { getStaticImageConfig } from '@app/helpers/imageHelper';
import { pushGAEvent } from '@app/helpers/googleHelpers';
import { useHeader } from '@app/components/Header/HeaderHooks';
import { usePartnersHeaderData } from '@app/components/Partners/PartnersHooks';
import { useLanguage } from '@app/components/Hooks';

const TopHeader: React.FC = () => {
  const header = useHeader();
  const hasSocial = !!header?.social?.length;
  const language = useLanguage();
  const partners = usePartnersHeaderData();
  return (
    <Container>
      <SocialSection>
        {header?.social?.map(({ icon, url, name }) => (
          <SocialIcon key={name}>
            <a
              href={url.href}
              onClick={(): void => pushGAEvent({
                event: GA_EVENT.CLICK_MENU,
                category: GA_CATEGORY.SOCIAL_ICON,
                label: name,
                language,
              })}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                {...getStaticImageConfig({ url: icon?.url, height: 15 })}
                alt={name}
              />
            </a>
          </SocialIcon>
        ))}
      </SocialSection>
      <SponsorsSection showDivider={hasSocial}>
        {partners.map(({
          id, title, redirectUrl, photo,
        }) => (
          <SponsorIcon
            key={id}
            onClick={(): void => pushGAEvent({
              event: GA_EVENT.CLICK_MENU,
              category: GA_CATEGORY.PARTNER_ICON,
              label: title,
              language,
            })}
          >
            {id === 'scudetto' ? (
              <img src={photo?.url} alt={photo?.description} />
            ) : (
              <a
                href={redirectUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={photo?.url} alt={photo?.description} />
              </a>
            )}
          </SponsorIcon>
        ))}
      </SponsorsSection>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  background-color: white;
  height: var(--top-header-height);

  @media ${Devices.largeMobile} {
    padding: 0 var(--mobile-header-padding);
  }

  @media ${Devices.desktopSmall} {
    justify-content: flex-end;
  }
`;

const LinksSection = styled.ul`
  display: flex;
  align-items: center;
  padding: 0;
  position: relative;
`;

const SocialSection = styled(LinksSection)`
  display: none;

  @media ${Devices.tablet} {
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    justify-content: center;
    padding-right: 15px;

    height: unset;
    overflow: unset;
  }

  @media ${Devices.desktopSmall} {
    padding-right: 25px;
  }
`;

const SponsorsSection = styled(LinksSection)<{ showDivider?: boolean }>`
  flex: 1 1 auto;
  justify-content: flex-end;

  @media ${Devices.tablet} {
    flex: 0 0 auto;
    justify-content: flex-end;
    padding-right: 5px;
  }

  @media ${Devices.desktopSmall} {
    padding-left: 25px;
  }

  ${({ showDivider }): string => (showDivider ? `
    &:last-child::before {
      content: "";
      border-left: 1px solid rgba(0,0,0,.2);
      height: 14px;
      left: -10px;
      display: block;
      position: absolute;

      @media ${Devices.desktopSmall} {
        left: 0;
        height: 25px;
      }
    }
  ` : '')};
`;

const SocialIcon = styled.li`
  height: 15px;
  padding: 0 6px;
  margin: 5px 0;

  & img {
    height: 100%;
  }
`;

const SponsorIcon = styled.li`
  width: 60px;
  height: 40px;
  margin-left: 10px;

  &:first-child {
    margin-left: 0;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  @media ${Devices.tablet} {
    margin-left: 15px;

    &:first-child {
      margin-left: 0;
    }
  }

  @media ${Devices.desktopSmall} {
    width: 80px;
    height: 60px;
  }

  & img {
    width: 100%;
    height: auto;
  }
`;

export default TopHeader;
