import { useContext, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { AppState } from '@app/store/reducers';
import { MountContext } from '@app/ReactContext';
import { getTicketingPackages, resetTicketingPackages } from '@app/store/actions/ticketingActions';
import { PackageTicketsInfo } from '@app/types/ticketingTypes';
import { useLanguage } from '@app/components/Hooks';

export function useTicketsPackages(): PackageTicketsInfo[] {
  return useSelector<AppState, PackageTicketsInfo[]>(
    (state) => state.ticketing.packages,
    shallowEqual,
  );
}

export function useTicketsPackagesDownload(): void {
  const { isInitialMount } = useContext(MountContext);
  const dispatch = useDispatch();
  const language = useLanguage();
  const packages = useTicketsPackages();

  useEffect(() => {
    (!isInitialMount || !packages.length) && dispatch(getTicketingPackages(language));
    return (): void => { dispatch(resetTicketingPackages()); };
  }, [language]);
}
