import {
  SetSearchBarVisibility,
  SetSearchInProgress,
  ResetSearch,
  SearchActionTypes,
} from '@app/store/actionTypes/searchActionTypes';

export const setSearchBarVisibility = (isVisible: boolean): SetSearchBarVisibility => ({
  type: SearchActionTypes.SET_SEARCH_BAR_VISIBILITY,
  payload: isVisible,
});

export const setSearchInProgress = (state: boolean): SetSearchInProgress => ({
  type: SearchActionTypes.SET_SEARCH_IN_PROGRESS,
  payload: state,
});

export const resetSearch = (): ResetSearch => ({
  type: SearchActionTypes.RESET_SEARCH,
});
