import React, { useState, useEffect, useRef } from 'react';
import { push } from 'connected-react-router';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { CloseIcon } from '@app/components/Icons';
import { setSearchBarVisibility } from '@app/store/actions/searchActions';
import {
  useIsSearchPageValidation,
  useIsSearchBarVisible,
  useSearchPagePath,
  useSearchParams,
} from './SearchBarHooks';
import SearchSubmit from './SearchSubmit';

const SearchBar = React.memo((): React.ReactElement | null => {
  const isSearchPage = useIsSearchPageValidation();
  const { query, category } = useSearchParams();
  const searchPagePath = useSearchPagePath();
  const isVisible = useIsSearchBarVisible();

  const [isDeleteQueryVisible, setIsDeleteQueryVisible] = useState(!!query);

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const searchInputRef = useRef(null);
  const searchInputEl = searchInputRef?.current as unknown as HTMLInputElement;

  const setSearchValue = (value = ''): void => {
    if (searchInputEl && typeof (searchInputEl?.value) === 'string') searchInputEl.value = value;
  };

  const focusSearchInput = (): void => {
    if (searchInputEl && searchInputEl.focus) searchInputEl.focus();
  };

  const onSearchValueDelete = (): void => {
    setIsDeleteQueryVisible(false);
    setSearchValue('');
  };

  const onSearchValueSubmit = (event): void => {
    event.preventDefault();
    dispatch(push(`${searchPagePath}?query=${encodeURI(searchInputEl?.value)}&category=${category}`));
  };

  useEffect(() => {
    setIsDeleteQueryVisible(query?.length > 0);
    setSearchValue(query);
  }, [query]);

  useEffect(() => {
    if (isVisible) setTimeout(focusSearchInput, 300);
    // if (!isVisible) setSearchValue('');
  }, [isVisible]);

  useEffect(() => {
    if (isSearchPage) {
      if (isVisible) setSearchValue(query);
    } else {
      dispatch(setSearchBarVisibility(false));
    }
  }, [isSearchPage]);

  return isVisible !== null ? (
    <GlobalWrapper isVisible={isVisible}>
      <SearchWrapper>
        <SearchForm onSubmit={onSearchValueSubmit} autoComplete="off">
          <SearchInput
            type="text"
            id="search-query"
            name="search-query"
            placeholder={formatMessage({ id: 'news.search.bar.placeholder' })}
            ref={searchInputRef}
            defaultValue={query}
            aria-autocomplete="none"
            autoFocus={false}
          />
          {isDeleteQueryVisible ? (
            <SearchDelete onClick={onSearchValueDelete}>
              <CloseIcon />
            </SearchDelete>
          ) : (
            <SearchSubmit />
          )}
        </SearchForm>
      </SearchWrapper>
    </GlobalWrapper>
  ) : null;
});

export default SearchBar;

const GlobalWrapper = styled.section<{ isVisible: boolean }>`
  position: absolute;
  z-index: 3;

  bottom: -80px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;

  width: 100%;
  height: 80px;
  margin: 0;
  padding: 0;

  background: black;
  outline: none;

  -webkit-animation: ${({ isVisible }): string => (isVisible
    ? 'scale-in-ver-top 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both'
    : 'scale-out-ver-top 0.1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both'
  )};
  animation: ${({ isVisible }): string => (isVisible
    ? 'scale-in-ver-top 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;'
    : 'scale-out-ver-top 0.1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both'
  )};
  animation-delay: ${({ isVisible }): string => (isVisible ? '0.0s' : 'none')};

  @media ${Devices.desktopSmall} {}
`;

const SearchWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin: auto;
`;

const SearchForm = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  padding: 0 var(--mobile-header-padding);

  @media ${Devices.desktopSmall} {
    padding: 0 var(--desktop-header-padding) 0 var(--nav-header-left-padding);
  }
`;

const SearchInput = styled.input`
  width: 100%;
  margin-right: 20px;

  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  background: transparent;
  border: none;
  outline: none;
  caret-color: white;
  color: white;

  &::placeholder {
    font-family: ${(props): string => props.theme.fontFamily.raleway};
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    opacity: 0.4;
    padding-left: 4px;

    @media ${Devices.largeMobile} {
      font-size: 16px;
    }
    @media ${Devices.tablet} {
      font-size: 18px;
    }
  }
`;

const SearchDelete = styled.div`
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  border: none;
  outline: unset;
  color: white;
`;
