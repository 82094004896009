import { useParams } from 'react-router-dom';

import AppRoutes from '@app/constants/routes';
import * as Translations from '@app/locales/index';
import { useLanguage } from '@app/components/Hooks';
import { useIsStandingsAvailableCheck, useIsResultsAvailableCheck } from '@app/pages/MatchCenter/MatchCenterHooks';
import { MenuItemTypes } from '@app/types/configurationTypes';
import { SecondLevelMenuItems } from '@app/components/SecondLevelMenu';

export const useMatchCenterTabs = (): SecondLevelMenuItems => {
  const { urlSlug = '', tab = '' } = useParams();
  const language = useLanguage();
  const isStandingsAvailable = useIsStandingsAvailableCheck();
  const isResultsAvailable = useIsResultsAvailableCheck();
  const menuItemData = {
    external: {
      id: '',
      name: '',
      url: {
        href: '',
        target: '',
        rel: '',
      },
    },
    related: {
      id: '',
      name: '',
      topLevel: {},
      type: MenuItemTypes.RelatedMenuItem,
    },
  };

  return [
    {
      id: 'matchcenter.tabs.lineups',
      name: Translations[language]?.['matchcenter.tabs.lineups'],
      linkData: {
        pathKey: AppRoutes.MatchCenter.path,
        pathParams: { urlSlug, tab: Translations[language]?.['matchcenter.tabs.lineups'].toLowerCase() },
      },
      isActive: Translations[language]?.['matchcenter.tabs.lineups'].toLowerCase() === tab,
      isVisible: true,
      type: MenuItemTypes.BaseMenuItem,
      ...menuItemData,
    },
    {
      id: 'matchcenter.tabs.statistics',
      name: Translations[language]?.['matchcenter.tabs.statistics'],
      linkData: {
        pathKey: AppRoutes.MatchCenter.path,
        pathParams: { urlSlug, tab: Translations[language]?.['matchcenter.tabs.statistics'].toLowerCase() },
      },
      isActive: Translations[language]?.['matchcenter.tabs.statistics'].toLowerCase() === tab,
      isVisible: true,
      type: MenuItemTypes.BaseMenuItem,
      ...menuItemData,
    },
    {
      id: 'matchcenter.tabs.results',
      name: Translations[language]?.['matchcenter.tabs.results'],
      linkData: {
        pathKey: AppRoutes.MatchCenter.path,
        pathParams: { urlSlug, tab: Translations[language]?.['matchcenter.tabs.results'].toLowerCase() },
      },
      isActive: Translations[language]?.['matchcenter.tabs.results'].toLowerCase() === tab,
      isVisible: isResultsAvailable,
      type: MenuItemTypes.BaseMenuItem,
      ...menuItemData,
    },
    {
      id: 'matchcenter.tabs.standings',
      name: Translations[language]?.['matchcenter.tabs.standings'],
      linkData: {
        pathKey: AppRoutes.MatchCenter.path,
        pathParams: { urlSlug, tab: Translations[language]?.['matchcenter.tabs.standings'].toLowerCase() },
      },
      isActive: Translations[language]?.['matchcenter.tabs.standings'].toLowerCase() === tab,
      isVisible: isStandingsAvailable,
      type: MenuItemTypes.BaseMenuItem,
      ...menuItemData,
    },
  ] as unknown as SecondLevelMenuItems;
};
