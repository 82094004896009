import { convertTeamId } from '@app/services/opta/converters/teamsConverter';
import { mapImageData, mapSeoData } from '@app/helpers/configurationHelpers';
import {
  Coach, Player, PlayerPosition, TeamNames,
} from '@app/types/teamsTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { KenticoCoach, KenticoPlayer, KenticoTeamName } from '../types/teamsTypes';
import { KenticoItem } from '../types/responseTypes';

export function convertPlayer(kentico: KenticoPlayer, kenticoId: string, language: LanguageType): Player {
  return {
    id: kentico.id?.value,
    kenticoId,
    firstName: kentico.first_name?.value,
    lastName: kentico.last_name?.value,
    number: kentico.number?.value,
    position: kentico.position?.value[0].codename as PlayerPosition,
    positionName: kentico.position?.value[0].name,
    photoLarge: mapImageData(kentico.photo_large),
    storeUrl: kentico.store_url?.value,
    birthDate: kentico.birth_date?.value,
    birthPlace: kentico.birth_place?.value,
    nationality: kentico.nationality?.value,
    height: kentico.height?.value,
    weight: kentico.weight?.value,
    preferredFoot: kentico.preferred_foot?.value[0]?.name,
    joinDate: kentico.join_date?.value,
    biography: kentico.biography?.value,
    urlSlug: kentico.url?.value,
    hide: kentico.hide_player?.value?.[0]?.codename === 'hide',
    ...mapSeoData(kentico, language),
  };
}

export function convertCoach(kentico: KenticoCoach, kenticoId: string, language: LanguageType): Coach {
  return {
    id: kentico.id?.value,
    kenticoId,
    firstName: kentico.coach_name?.value,
    lastName: kentico.coach_surname?.value,
    position: PlayerPosition.Coach,
    photoLarge: mapImageData(kentico.coach_photo),
    birthDate: kentico.coach_birthdate?.value,
    birthPlace: kentico.coach_birthplace?.value,
    nationality: kentico.coach_nationality?.value,
    joinDate: kentico.at_ac_milan_since?.value,
    coachSince: kentico.coach_since?.value,
    // eslint-disable-next-line no-underscore-dangle
    biographyTitle: kentico.biography_title__h3_?.value,
    biography: kentico.biography?.value,
    // eslint-disable-next-line no-underscore-dangle
    careerTitle: kentico.career_title__h3_?.value,
    career: kentico.career?.value,

    hide: kentico.hide_player?.value?.[0]?.codename === 'hide',
    ...mapSeoData(kentico, language),
  };
}

export function convertTeamNames(teams: KenticoItem<KenticoTeamName>[], sliceId: boolean): TeamNames {
  return teams
    .reduce((accumulator, team) => {
      const teamOptaId = team?.elements?.team_opta_id?.value ?? '';
      const teamId = sliceId ? convertTeamId(teamOptaId) : teamOptaId;
      const teamName = team?.elements?.regular_name?.value ?? '';
      const shortTeamName = team?.elements?.shortname.value ?? '';

      if (teamName || shortTeamName) {
        accumulator[teamId] = {
          teamName,
          shortTeamName,
        };
      }
      return accumulator;
    }, {} as TeamNames);
}
