import React from 'react';

import { WebCtaImage } from '@app/types/webTemplateTypes';
import { MenuItemTypes, RelatedMenuItem } from '@app/types/configurationTypes';

import { findAndFormatSubMenuLinkByIdProps, formatRelatedItemLinkProps } from '@app/helpers/menuHelpers';

import { Image } from '@app/components';
import { useLanguage, useMenu } from '@app/components/Hooks';
import { useMenSquadAllCompetitionsParams } from '@app/pages/Season/Season/SeasonHooks';

import LocalizedLink from '@app/components/Localization/LocalizedLink';
import { getStaticImageConfig3x } from '@app/helpers/imageHelper';
import { pushGAEvent } from '@app/helpers/googleHelpers';

type WebCtaImageElement = React.FC<{data: WebCtaImage}>;
const WebCtaImageElement: WebCtaImageElement = ({ data }) => {
  const menu = useMenu();
  const menuItems = Object.values(menu ?? {});
  const { seasonId, urlSlug } = useMenSquadAllCompetitionsParams();
  const language = useLanguage();

  const onCtaClick = (): void => { if (data.analytic) pushGAEvent({ ...data.analytic, language }); };

  switch (data.menuItem?.type) {
    case MenuItemTypes.ExternalLink:
      return (
        <a
          href={data.menuItem?.external?.url?.href ?? ''}
          rel="noopener noreferrer"
          target="_blank"
          onClick={onCtaClick}
        >
          <Image
            src={`${data.image.url}`}
            description={data.image.description}
            additionalImageProps={getStaticImageConfig3x({ url: data.image.url })}
          />
        </a>
      );
    case MenuItemTypes.BaseMenuItem:
      return menu ? (
        <LocalizedLink
          {...findAndFormatSubMenuLinkByIdProps(menuItems, data.menuItem?.id)}
          onClick={onCtaClick}
        >
          <Image
            src={`${data.image.url}`}
            description={data.image.description}
            additionalImageProps={getStaticImageConfig3x({ url: data.image.url })}
          />
        </LocalizedLink>
      ) : null;
    case MenuItemTypes.RelatedMenuItem:
      return (
        <LocalizedLink
          {...formatRelatedItemLinkProps(data.menuItem as RelatedMenuItem, seasonId, urlSlug)}
          onClick={onCtaClick}
        >
          <Image
            src={`${data.image.url}`}
            description={data.image.description}
            additionalImageProps={getStaticImageConfig3x({ url: data.image.url })}
          />
        </LocalizedLink>
      );
    default: return null;
  }
};

export default WebCtaImageElement;
