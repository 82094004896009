import { useEffect } from 'react';

import { useLanguage } from '@app/components/Hooks';
import { pushGAEvent } from '@app/helpers/googleHelpers';

import { GA_CATEGORY, GA_EVENT } from '@app/types/googleTypes';

// Google Analytic - GTM special case for mailto HR link click
export const useMailToHrLinkClick = (content: string): void => {
  const label = 'hrsupport@acmilan.com';
  const language = useLanguage();
  const useEventListener = content && content.includes(label);

  const onLinkClick = (e): void => {
    e.preventDefault();
    e.stopPropagation();

    pushGAEvent({
      event: GA_EVENT.CLICK_HR_EMAIL,
      category: GA_CATEGORY.HR,
      language,
      label,
    });

    window.open(`mailto:${label}`, '_self');
  };

  useEffect(() => {
    const mailToEl = document.querySelectorAll(`[href="mailto:${label}"]`)?.[0];
    if (useEventListener && mailToEl) mailToEl.addEventListener('click', onLinkClick);
    return (): void => { if (mailToEl) mailToEl.removeEventListener('click', onLinkClick); };
  }, [useEventListener]);
};
