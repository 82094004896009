import { NextMatches } from '@app/types/matchTypes';
import { AppLanguageTypes } from '@app/types/localizationTypes';
import {
  WebCtaButton, WebGoogleAds, WebPartner, WebPartnerBottom,
} from '@app/types/webTemplateTypes';

export interface Configuration {
  menu: Menu;
  competitions: Competitions;
  nextMatches: NextMatches;
  header: Header;
  footer: Footer;
  home: Seo | {};
  cookie: CookieBanner;
  errorPages: ErrorPages;
  partners: Partners;
  shareIcons: ShareIcons;
  syncCalendarUrl: string;
  vanityUrls: VanityUrl[] | null;
}

export interface SeoData {
  title: string;
  description: string;
  canonicalTag: string;
  meta: boolean;
  socialTitle: string;
  socialDescription: string;
  socialImageUrl: string;
}

export interface Seo {
  seo: SeoData;
}

export enum GeoTargetingAppearingTypes {
  Allow = 'allow',
  Deny = 'deny',
}

export interface GeoTargetingData {
  active: boolean;
  appearing: GeoTargetingAppearingTypes;
  countries: string[];
}

export interface GeoTargeting {
  geo: GeoTargetingData;
}

export interface ExternalLink {
  href: string;
  target: string;
  rel: string;
}

export type LocalizedConfiguration = {
  [value in AppLanguageTypes]: Configuration;
}

export enum MenuItemTypes {
  GameMenuItem = 'game',
  BaseMenuItem = 'menu_item',
  ExternalLink = 'external_link',
  RelatedMenuItem = 'related_menu_item',
}

export interface BaseMenuItem extends Seo, GeoTargeting {
  id: string;
  value: string;
  name: string;
  url: string;
  external: {
    name: string;
    url: ExternalLink;
  };
  related: RelatedMenuItem;
  layout: LayoutType;
  isVisible: boolean;
  isPartnersInvisible: boolean;
  optaId: string;
  type: MenuItemTypes;
  banner: WebGoogleAds | null;
}

export interface ExternalLinkMenuItem {
  id: string;
  name: string;
  url: ExternalLink;
}

export interface RelatedMenuItem {
  id: string;
  name: string;
  topLevel: BaseMenuItem;
  secondLevel?: BaseMenuItem;
  thirdLevel?: BaseMenuItem;
  type: MenuItemTypes.RelatedMenuItem;
}

export type GameMenu = {
  id: string;
  name: string;
}

export interface GameMenuItem {
  type: MenuItemTypes.GameMenuItem;
  item: GameMenu;
}

interface MenuItemRelatedItem {
  type: MenuItemTypes.RelatedMenuItem;
  item: RelatedMenuItem;
}

interface ExternalLinkRelatedItem {
  type: MenuItemTypes.ExternalLink;
  item: ExternalLinkMenuItem;
}

export type RelatedItemsType = MenuItemRelatedItem | ExternalLinkRelatedItem;

export type MenuIds = 'web_news' | 'web_teams' | 'web_season' | 'web_ticket_office' | 'web_roster_archive';

export type Menu = {
  [key in MenuIds]: MenuItem;
} | null

export type NewsSubMenuId = 'news_tutte_news' | 'news_video' | 'news_fotogallery';
export type TeamSubMenuId = 'squadre_primasquadramaschile' | 'squadre_primasquadrafemminile' | 'squadre_primavera' | 'youth_teams' | 'squadre_milanfuturo';
export type SeasonSubMenuId = 'stagione_calendari' | 'stagione_classifiche';
export type TicketingSubMenuId = 'web_tickets';
export type RosterArchiveSubMenuId = 'web_archive_men_first_team';
export type SubMenuId = NewsSubMenuId | TeamSubMenuId | SeasonSubMenuId | TicketingSubMenuId | RosterArchiveSubMenuId;

export interface MenuItem extends BaseMenuItem {
  navigation: {
    [key in SubMenuId]: SubMenuItem;
  };
  relatedItems: RelatedItemsType[];
}

export type LocalizedMenuItem = {
  [value in AppLanguageTypes]: MenuItem | null
}

export interface SubMenuItem extends BaseMenuItem {
  categories: BaseMenuItem[];
}

export type LocalizedSubMenuItem = { [value in AppLanguageTypes]: SubMenuItem | null };

export type CategoryMenu = BaseMenuItem[];
export type LocalizedCategoryMenu = { [value in AppLanguageTypes]: CategoryMenu };

export type LayoutType = 'plain_2x2' | 'plain_2x3' | 'plain_3x3' | 'overlay_2x2' | 'overlay_2x3' | 'overlay_3x3';

export interface LayoutConfigurationItem {
  itemsToDownload: number;
  isOverlay: boolean;
}

export type LayoutConfiguration = {
  [key in LayoutType]: LayoutConfigurationItem;
}

export type Competitions = {
  [CompetitionId: string]: Competition;
};

export enum CompetitionTypes {
  All = 'all',
  Cup = 'cup',
  League = 'league',
  Tournament = 'tournament',
}

export interface Competition {
  id: string;
  url: string;
  type: CompetitionTypes;
  name: string;
  optaId: string;
  categoryId: string;
  qualifiersMode: boolean;
  logo?: Image;
  seasonIds: {
    seasonId: string;
    seasonName: string;
    isActive: boolean;
  }[];
}

export interface Social {
  name: string;
  url: ExternalLink;
  icon: Image;
}

export interface FooterItem {
  copyright: string;
  legalLinks: RelatedItemsType[];
  social: Social[];
}

export type Footer = FooterItem | null;

export interface Image {
  url: string;
  description: string;
}

export interface HeaderItem {
  social: Social[];
}

export type Header = HeaderItem | null;

export interface PartnersItem {
  bottom: WebPartnerBottom;
  header: WebPartner[];
}

export type Partners = PartnersItem | null;

export interface CookieBannerItem {
  text: string;
  cta: {
    position: string;
    accept: WebCtaButton;
    decline: WebCtaButton;
  };
}

export type CookieBanner = CookieBannerItem | null;

export interface ErrorPagesItem {
  titleSorry: string;
  titleNotFound: string;
  cta: WebCtaButton[];
}

export type ErrorPages = ErrorPagesItem | null;

export enum ShareIconsType {
  Facebook = 'facebook',
  Twitter = 'twitter',
  WhatsApp = 'whatsapp',
  Weibo = 'weibo',
  WeChat = 'wechat',
}

export interface ShareIconsItem {
  type: ShareIconsType;
  icon: Image;
}

export type ShareIcons = ShareIconsItem[] | null;

export interface VanityUrl {
  id: string;
  url: string;
  relatedItem: RelatedMenuItem;
}
