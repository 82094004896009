import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import AppRoutes from '@app/constants/routes';
import { TeamStats } from '@app/types/standingsTypes';
import { GA_EVENT, GA_CATEGORY } from '@app/types/googleTypes';
import { pushGAEvent } from '@app/helpers/googleHelpers';
import { isTeamMilan, getTeamNameFromKentico } from '@app/helpers/teamHelpers';
import { useLanguage } from '@app/components/Hooks';

import { useTeamNamesSelector } from '@app/pages/Teams/TeamsHooks';
import { useActiveUrlSlugForCompetitionSeriaA } from '@app/pages/Season/Season/SeasonHooks';
import { SeasonSection, LinkToSeason, SeasonInnerContainer } from './HomeSeasonComponents';

interface HomeStandingsProps {
  standings: TeamStats[];
  seasonId: string;
}

const Standings = ({ standings, seasonId }: HomeStandingsProps): React.ReactElement => {
  const language = useLanguage();
  const teamNames = useTeamNamesSelector();
  const urlSlug = useActiveUrlSlugForCompetitionSeriaA();

  return (
    <SeasonSection headerTranslationId="home.standings">
      <SeasonInnerContainer>
        <Table>
          <thead>
            <tr>
              <TableHead />
              <TableHeadCompetition><FormattedMessage id="competition.serieA" /></TableHeadCompetition>
              <TableHead><b><FormattedMessage id="standings.points" /></b></TableHead>
              <TableHead><FormattedMessage id="standings.played" /></TableHead>
              <TableHead><FormattedMessage id="standings.goalsDiff" /></TableHead>
            </tr>
          </thead>
          <tbody>
            {
              standings.map((team) => (
                <TableBodyRow key={team.id} isMilan={isTeamMilan(team.id)}>
                  <TableDataPosition>{team.position}</TableDataPosition>
                  <TableDataName>
                    <TeamNameContainer>
                      <Logo src={team.logoUrl} alt={team.teamName} />
                      {getTeamNameFromKentico(team.teamName, team.id, teamNames)}
                    </TeamNameContainer>
                  </TableDataName>
                  <TableDataStats><b>{team.points}</b></TableDataStats>
                  <TableDataStats>{team.played}</TableDataStats>
                  <TableDataStats>{team.goalsDiff}</TableDataStats>
                </TableBodyRow>
              ))
            }
          </tbody>
        </Table>
        <LinkToSeason
          pathKey={AppRoutes.Standings.path}
          pathParams={{ urlSlug, seasonId }}
          onClick={(): void => pushGAEvent({
            event: GA_EVENT.CLICK_HOME,
            category: GA_CATEGORY.STANDINGS,
            label: `see_${GA_CATEGORY.STANDINGS}`,
            language,
          })}
        >
          <FormattedMessage id="home.seeStandings" />
        </LinkToSeason>
      </SeasonInnerContainer>
    </SeasonSection>
  );
};

export default Standings;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 18px;
  color: ${({ theme }): string => theme.color.darkGray};
`;

const TableHead = styled.th`
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-weight: normal;
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 7px;

  @media ${Devices.tablet} {
    font-size: 14px;
  }
`;

const TableHeadCompetition = styled(TableHead)`
  font-weight: bold;
  text-align: left;
  padding-left: 8px;
`;

const TableBodyRow = styled.tr<{ isMilan: boolean }>`
  color: ${(props): string => (props.isMilan ? props.theme.color.harleyDavidsonOrange : 'inherit')};
  font-weight: ${(props): string => (props.isMilan ? 'bold' : 'normal')};
  height: 42px;
  border-bottom: solid 1px rgb(50, 50, 50, 0.1);
  letter-spacing: 0.33px;
  text-align: center;
  vertical-align: middle;

  &:last-child {
    border-bottom: none;
  }
`;

const TableDataPosition = styled.td`
  font-size: 14px;
  color: ${(props): string => props.theme.color.cheninYellow};
  font-weight: bold;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  width: 40px;

  @media ${Devices.tablet} {
    font-size: 16px;
  }
`;

const TableDataName = styled.td`
  text-align: left;
  padding-left: 15px;
  font-size: 13px;
  text-transform: uppercase;
  font-family: ${(props): string => props.theme.fontFamily.milan};

  @media ${Devices.tablet} {
    font-size: 15px;
  }
`;

const TeamNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 15px;
`;

const TableDataStats = styled.td`
  width: 15%;
  font-size: 13px;

  @media ${Devices.tablet} {
    font-size: 15px;
  }
`;
