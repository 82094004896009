import { useEffect } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Errors, PageError } from '@app/types/errorTypes';
import { QuestionData } from '@app/types/faqQuestionTypes';
import { AppState } from '@app/store/reducers';
import { getQuestionData, resetQuestion } from '@app/store/actions/questionActions';
import { resetWebTemplateError } from '@app/store/actions/webTemplateActions';

import { useLanguage, useIsConfigLoaded } from '@app/components/Hooks';
import {
  useThirdLevelMenu,
  useTopLevelMenu,
  useWebTemplateError,
} from '@app/pages/WebTemplate/WebTemplateHooks';

export const useQuestion = (): QuestionData | undefined => {
  const language = useLanguage();

  return useSelector<AppState, QuestionData | undefined>(
    (state) => state.question[language],
    shallowEqual,
  );
};

export function useQuestionDownload(): void {
  const {
    topLevel = '', secondLevel = '', categoryName = '', tab = '',
  } = useParams();
  const language = useLanguage();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getQuestionData({
      topLevel, secondLevel, categoryName, tab, language,
    }));

    return (): void => {
      dispatch(resetQuestion());
      dispatch(resetWebTemplateError());
    };
  }, []);
}

export function useQuestionValidation(): Errors {
  const language = useLanguage();
  const topLevel = useTopLevelMenu()[language];
  const thirdLevelMenu = useThirdLevelMenu();
  const error = useWebTemplateError();

  const isConfigLoaded = useIsConfigLoaded();

  const question = useQuestion();

  if (error) {
    return error;
  }

  const hasNoQuestionData = question && !question?.question?.urlSlug;

  // url is incorrect if menu items or question haven't found
  return isConfigLoaded && (!topLevel || !thirdLevelMenu || hasNoQuestionData)
    ? PageError.NotFound : null;
}
