import * as React from 'react';
import styled from 'styled-components';

import { useIsHomeDetection } from '@app/components/Header/HeaderHooks';
import { Devices } from '@app/styles';
import { LocalizedDate } from '@app/components/Localization';

interface NewsItemInfoProps {
  time: string;
  title: string;
  background: string;
  color: string;
  isHighlighted?: boolean;
  isLarge?: boolean;
}

const NewsItemInfo = ({
  time, title, background, color, isHighlighted = false, isLarge = false,
}: NewsItemInfoProps): React.ReactElement => {
  const isHome = useIsHomeDetection();

  return (
    <Container background={background} color={color} isHighlighted={isHighlighted}>
      <Time isHighlighted={isHighlighted || isLarge}>
        <LocalizedDate date={time} />
        <Line isHighlighted={isHighlighted} />
      </Time>
      <Title isHighlighted={isHighlighted} isLarge={isLarge} as={isHome ? 'h3' : 'h2'}>
        { title }
      </Title>
    </Container>
  );
};

interface ContainerProps {
  background: string;
  color: string;
  isHighlighted: boolean;
}

const Container = styled.div<ContainerProps>`
  padding: 15px;
  color: ${({ color }): string => color};
  background: ${({ background }): string => background};
  height: 100%;

  @media ${Devices.tablet} {
    padding: ${({ isHighlighted }): string => (isHighlighted ? '40px' : '10px')} 10px 10px;
  }
`;

const Time = styled.span<{ isHighlighted: boolean }>`
  font-size: 12px;
  letter-spacing: 0.33px;
  position: relative;
  margin-bottom: 10px;
  display: inline-block;

  @media ${Devices.tablet} {
    font-size: ${({ isHighlighted }): string => (isHighlighted ? '14px' : '12px')};
  }
`;

const Line = styled.hr<{isHighlighted: boolean}>`
  height: 2px;
  width: 15px;
  border: none;
  background-color: ${(props): string => props.theme.color.freeSpeechRed};
  position: absolute;
  right: -25px;
  top: 0;

  @media ${Devices.tablet} {
    height: ${({ isHighlighted }): string => (isHighlighted ? '3px' : '2px')};
    width: ${({ isHighlighted }): string => (isHighlighted ? '25px' : '15px')};
    right: ${({ isHighlighted }): string => (isHighlighted ? '-40px' : '-25px')};
  }
`;

const Title = styled.h3<{ isHighlighted: boolean; isLarge: boolean }>`
  line-height: 1.25;
  text-transform: uppercase;\
  display: -webkit-box;
  -webkit-line-clamp: ${({ isLarge }): number => (isLarge ? 2 : 3)};;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: ${({ isLarge }): string => (isLarge ? '18px' : '16px')};

  @media ${Devices.tablet} {
    font-size: ${({ isHighlighted, isLarge }): string => {
    /* eslint-disable indent */
      if (isHighlighted) return '26px';
      return isLarge ? '21px' : '16px';
    }};
    line-height: 1.6;
  }
`;
/* eslint-enable indent */

export default NewsItemInfo;
