import { Team } from '@app/types/teamsTypes';
import { TeamSubMenuId } from '@app/types/configurationTypes';
import { TeamSubMenuTypeMap } from '@app/constants/teamsConstants';
import { TeamSquadTypes } from '../types/teamsTypes';

export function mapTeamSquadType(type?: TeamSubMenuId): TeamSquadTypes | null {
  switch (type) {
    case TeamSubMenuTypeMap.men: return TeamSquadTypes.MenSquad;
    case TeamSubMenuTypeMap.women: return TeamSquadTypes.WomenSquad;
    case TeamSubMenuTypeMap.primavera: return TeamSquadTypes.PrimaveraSquad;
    case TeamSubMenuTypeMap.futuro: return TeamSquadTypes.FuturoSquad;
    default: return null;
  }
}

export function sortPlayers(players: Team): Team {
  return players.sort((playerA, playerB) => {
    const nameA = playerA.lastName?.toUpperCase();
    const nameB = playerB.lastName?.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
}
