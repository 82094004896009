import React from 'react';
import styled from 'styled-components';

import { getFullScreenImgConfig } from '@app/helpers/imageHelper';
import { WebPageImage, WebPageImageSizeType } from '@app/types/webTemplateTypes';

import { Image } from '@app/components';
import { ContainerLarge, ContainerMedium } from '../components';

interface WebPageImageElementProps {
  data: WebPageImage;
}

const WebPageImageElement: React.FC<WebPageImageElementProps> = ({ data }: WebPageImageElementProps) => {
  let Container;
  switch (data?.size) {
    case WebPageImageSizeType.Big:
      Container = ContainerLarge;
      break;
    case WebPageImageSizeType.Medium:
      Container = ContainerMedium;
      break;
    default:
      Container = ContainerMedium;
  }
  return (
    data?.image?.url ? (
      <Container>
        <ContainerWithPadding>
          <Image
            src={data.image.url}
            description={data.image.description}
            additionalImageProps={getFullScreenImgConfig(data.image.url)}
          />
        </ContainerWithPadding>
      </Container>
    ) : null
  );
};

const ContainerWithPadding = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
`;

export default WebPageImageElement;
