import React from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import AppRoutes from '@app/constants/routes';

import { PageTitle, CategoryMenu, PlayersCategory } from '@app/components';
import { PageWrapper } from '@app/pages/components';
import { ContainerMedium } from '@app/components/WebTemplates/components';
import { useSecondLevelMenu } from '@app/pages/WebTemplate/WebTemplateHooks';
import { useLanguage } from '@app/components/Hooks';
import HallOfFamePlayerCard from './HallOfFamePlayer';
import { useGroupsOfPlayers, useHallOfFameDownload, useHallOfFameValidation } from './HallOfFameHooks';

const HallOfFame: React.FC = () => {
  const { topLevel = '', secondLevel = '' } = useParams();
  const language = useLanguage();
  const playerGroups = useGroupsOfPlayers();
  const validationError = useHallOfFameValidation();
  useHallOfFameDownload();

  const secondLevelMenu = useSecondLevelMenu()?.[language];
  const categories = secondLevelMenu?.categories ?? [];
  const pathParams = {
    topLevel,
    secondLevel,
  };

  return (
    <PageWrapper error={validationError}>
      <ContainerMedium>
        <PageTitle>
          <FormattedMessage id="hallOfFame.header" />
        </PageTitle>
        <CategoryMenu items={categories} pathKey={AppRoutes.WebTemplate.path} pathParams={pathParams} color="black" />
        {
          !!playerGroups.length && playerGroups.map(({ id, categoryName, players }) => (
            <PlayersCategory key={id} type={categoryName} isLarge>
              {
                players.map((player) => (
                  <HallOfFamePlayerCard player={player} key={player.urlSlug} />
                ))
              }
            </PlayersCategory>
          ))
        }
      </ContainerMedium>
    </PageWrapper>
  );
};

export default HallOfFame;
