import React from 'react';
import styled from 'styled-components';
import { Devices } from '@app/styles';
import { WebHallOfFameInductee } from '@app/types/webTemplateTypes';
import { HOFFilter } from './hof.filter';

interface Props {
  inductees: WebHallOfFameInductee[] | undefined | null;
  filters: string;
}

export const HOFInducteesFilters = ({ inductees, filters }: Props): React.ReactElement | null => {
  const years = (inductees || [])
    .map(({ year }) => Number(year))
    .filter((year, i, array) => array.indexOf(year) === i)
    .sort((a, b) => b - a);

  const positions = (inductees || [])
    .map(({ role }) => role)
    .filter((year, i, array) => array.indexOf(year) === i)
    .sort();

  const showYearsFilter = ['only_years', 'both_filters'].includes(filters);
  const showPositionsFilter = ['only_roles', 'both_filters'].includes(filters);

  return (showYearsFilter || showPositionsFilter) ? (
    <Filters>
      {showYearsFilter ? <HOFFilter name="year" options={years} /> : null}
      {showPositionsFilter ? <HOFFilter name="position" options={positions} /> : null}
    </Filters>
  ) : null;
};

const Filters = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 10px;
  width: 100%;

  @media ${Devices.largeMobile} {
    flex-direction: row;
    gap: 10px;
    width: auto;
  }
`;
