import React from 'react';
import { FormattedMessage } from 'react-intl';
import { format, isValid, parseISO } from 'date-fns';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { MatchTicketsInfo, TicketType } from '@app/types/ticketingTypes';
import { mapLanguageTypeToDateFnsLocale } from '@app/helpers/localizationHelper';
import { getResponsiveImageConfig, getStaticImageConfig } from '@app/helpers/imageHelper';
import { TicketingDateFormatMap } from '@app/constants/dateTimeConstants';

import { useLanguage } from '@app/components/Hooks';
import { Image } from '@app/components';
import { TicketsButtons, TicketsIcons } from '../../components';

interface TicketsSingleMatchProps {
  matchTickets: MatchTicketsInfo;
}

const SingleMatch = ({ matchTickets }: TicketsSingleMatchProps): React.ReactElement => {
  const language = useLanguage();

  const parsedMatchTime = parseISO(matchTickets?.matchTime ?? '');
  const matchDate = isValid(parsedMatchTime) ? format(parsedMatchTime, TicketingDateFormatMap[language], {
    locale: mapLanguageTypeToDateFnsLocale(language),
  }) : '';
  const matchTime = isValid(parsedMatchTime) ? format(parsedMatchTime, 'HH:mm', {
    locale: mapLanguageTypeToDateFnsLocale(language),
  }) : '';
  const hasCompanion = !!matchTickets?.companion?.url[language];
  const adUrl = matchTickets.companion.ad.url;

  return (
    <BlockWrapper hasCompanion={hasCompanion}>
      <Block>
        <MatchInfo>
          <MatchHeader>
            <FormattedMessage
              id="ticketing.matchHeader"
              values={{ home: matchTickets.teams.home.name, away: matchTickets.teams.away.name }}
            />
          </MatchHeader>
          <TeamsInfo>
            <TeamLogo>
              <img
                {...getStaticImageConfig({ url: matchTickets.teams.home.logoUrl.url, width: 46, height: 46 })}
                alt={matchTickets.teams.home.logoUrl.description}
              />
            </TeamLogo>
            <GameInfo>
              <img
                {...getStaticImageConfig({ url: matchTickets.competition.logo.url, width: 28, height: 33 })}
                alt={matchTickets.competition.logo.description}
              />
              {matchDate && (<MatchDate>{matchDate}</MatchDate>)}
              {matchTickets.kenticoMatchTimeTBC
                ? (<MatchTime>{matchTime}</MatchTime>)
                : (<NoMatchTime><FormattedMessage id="ticketing.noMatchTime" /></NoMatchTime>)}
            </GameInfo>
            <TeamLogo>
              <img
                {...getStaticImageConfig({ url: matchTickets.teams.away.logoUrl.url, width: 46, height: 46 })}
                alt={matchTickets.teams.away.logoUrl.description}
              />
            </TeamLogo>
          </TeamsInfo>
          <TeamsNames>
            <span>{matchTickets.teams.home.name || matchTickets.teams.home.shortName}</span>
            <span>{matchTickets.teams.away.name || matchTickets.teams.away.shortName}</span>
          </TeamsNames>
          <StartingPrice isVisible={!!matchTickets.startingPrice?.length}>
            <FormattedMessage id="ticketing.startingFrom" values={{ amount: matchTickets.startingPrice }} />
          </StartingPrice>
          <TicketsIcons ticket={matchTickets} />
        </MatchInfo>
        <TicketsButtons ticket={matchTickets} ticketType={TicketType.Single} />
      </Block>
      {hasCompanion && (
        <a href={matchTickets.companion.url[language]} target="_blank" rel="noopener noreferrer">
          <BlockCompanion>
            <Image
              src={adUrl}
              description={matchTickets.companion.ad.description}
              additionalImageProps={
                getResponsiveImageConfig(
                  adUrl,
                  {
                    1024: 460, 769: 920, 602: 730, 376: 565,
                  },
                )
              }
            />
          </BlockCompanion>
        </a>
      )}
    </BlockWrapper>
  );
};

const BlockWrapper = styled.div<{ hasCompanion: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  @media ${Devices.desktop} {
    flex-direction: row;
    width: ${({ hasCompanion }): string => (hasCompanion ? '960px' : '480px')};
  }
`;

const Block = styled.div`
  width: 100%;
  height: auto;
  background-color: #ffffff;
  margin: 0 0 20px 0;
  display: flex;
  flex-direction: column;
  padding: 30px;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  justify-content: space-around;

  @media ${Devices.tablet} {
    height: 190px;
    flex-direction: row;
    padding: 20px;
  }

  @media ${Devices.desktop} {
    width: 460px;
    margin: 0 20px 20px 0;
  }
`;

const MatchInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;

  @media ${Devices.tablet} {
    flex: 0 0 50%;
    margin-bottom: 0;
  }
`;

const MatchHeader = styled.h2`
  display: none;
`;

const TeamsInfo = styled.div`
  display: flex;
  flex-direction: row;
`;

const TeamLogo = styled.div`
  margin-top: 32px;
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    max-width: 46px;
    max-height: 46px;
  }
`;

const GameInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;

  & > img {
    max-width: 28px;
    max-height: 33px;
  }
`;

const MatchDate = styled.h3`
  margin-top: 6px;
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.26px;
  text-align: center;
  color: #000000;
`;

const MatchTime = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-align: center;
  color: #000000;
`;

const NoMatchTime = styled.p`
  margin-top: 5px;
  padding: 0 10px;
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.22px;
  text-align: center;
  color: ${(props): string => props.theme.color.freeSpeechRed};
  text-transform: uppercase;
`;

const TeamsNames = styled.div`
  margin-top: 10px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.26px;
  color: #000000;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
`;

const StartingPrice = styled.div<{ isVisible: boolean }>`
  margin-top: 10px;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.22px;
  text-align: center;
  color: ${(props): string => props.theme.color.freeSpeechRed};
  text-transform: uppercase;
  visibility: ${({ isVisible }): string => (isVisible ? 'visible' : 'hidden')}
`;

const BlockCompanion = styled(Block)`
  padding: 0!important;
  height: auto;

  @media ${Devices.tablet} {
    height: auto;
  }

`;

export default SingleMatch;
