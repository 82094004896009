import { Configuration } from '@app/types/configurationTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { mapConfig } from '@app/helpers/configurationHelpers';
import { langCodeToKenticoLangMap } from '@app/constants/localizationConstants';
import { UserCountryType } from '@app/types/userTypes';

import sendRequest, { sendLocalServerRequest } from './request';

export async function getInitialConfiguration(
  language: LanguageType, country: UserCountryType,
): Promise<Configuration> {
  return sendRequest({
    path: '/configuration_web',
    params: {
      depth: 4,
      language: langCodeToKenticoLangMap[language],
      'system.language': langCodeToKenticoLangMap[language],
    },
  })
    .then((data) => mapConfig(data, language, country))
    .catch((error) => sendLocalServerRequest({
      path: '/api/onrewind/config',
      params: {
        language: langCodeToKenticoLangMap[language],
      },
    })
      .then((data) => mapConfig(data, language, country))
      .catch(() => { throw error; }));
}
