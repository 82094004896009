import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { DailyMotionPlayerProps } from '@app/types/dailyMotionPlayerTypes';
import { useLanguage, useIsLoggedInSelector } from '@app/components/Hooks';
import { checkIsBrowser } from '@app/helpers/documentHelpers';

import { PremiumBadge } from '@app/components';
import { PremiumBadgeWrapper } from '@app/components/News/NewsItemSections';
import {
  VideoPlayerContainer, VideoPlayerLoader, VideoPlayerPremiumWrapper,
} from '../VideoPlayersStyledComponents';

import DailyMotionPlayerPreview from './components/DailyMotionPlayerPreview';

const DailyMotionPlayer = ({
  videoId, coverImageUrl, isPremium = false, hidePremiumLabel = false,
}: DailyMotionPlayerProps): React.ReactElement => {
  const isLoggedIn = useIsLoggedInSelector();
  const isBrowser = checkIsBrowser();
  const language = useLanguage();
  const playerId = `dm-player-${videoId}`;

  const initPlayer = (): void => {
    const dmPlayer = document.getElementById('dm-player');
    const dmPlayerVideo = document.createElement('div');

    dmPlayerVideo.id = playerId;
    dmPlayer && dmPlayer.appendChild(dmPlayerVideo);

    window.dailymotion
      .createPlayer(playerId, { video: videoId })
      .then((player) => player.setSubtitles(language))
      .catch((error) => console.error('DM createPlayer error: ', error));
  };

  const setPlayerSubtitles = (): void => window.dailymotion
    .getPlayer()
    .then((player) => player.setSubtitles(language))
    .catch((error) => console.error('DM destroyPlayer error: ', error));

  const destroyPlayer = (): void => window.dailymotion
    .getPlayer()
    .then((player) => player.destroy())
    .catch((error) => console.error('DM destroyPlayer error: ', error));

  useEffect(() => {
    if (videoId) initPlayer();
    return (): void => { if (videoId) destroyPlayer(); };
  }, [videoId]);

  useEffect(() => (): void => { if (videoId) setPlayerSubtitles(); }, [language]);

  if (isBrowser && isPremium && !isLoggedIn) {
    return (<DailyMotionPlayerPreview url={coverImageUrl} isPremium={isPremium} />);
  }

  return videoId ? (
    <VideoPlayerPremiumWrapper>
      <div id="dm-player" />
      {isPremium && !hidePremiumLabel && (
        <PremiumBadgeWrapper left>
          <PremiumBadge />
        </PremiumBadgeWrapper>
      )}
    </VideoPlayerPremiumWrapper>
  ) : (
    <VideoPlayerContainer>
      <VideoPlayerLoader>
        <FormattedMessage id="video.loading" />
      </VideoPlayerLoader>
    </VideoPlayerContainer>
  );
};

export default DailyMotionPlayer;
