import { LanguageType } from '@app/types/localizationTypes';
import { langCodeToKenticoLangMap } from '@app/constants/localizationConstants';
import {
  KenticoWebTemplateElement,
  KenticoWebTemplateModularContent,
} from '@app/services/kentico/types/webTemplateTypes';
import { convertWebTemplate } from '@app/services/kentico/converters/webTemplateConverter';
import { WebTemplate } from '@app/types/webTemplateTypes';
import { RequestInterface } from './types/requestTypes';
import { sendRequestTyped } from './request';

export async function getWebTemplateData(path: string, language: LanguageType): Promise<WebTemplate> {
  const params: RequestInterface = {
    path: `/${path}`,
    params: {
      depth: 10,
      language: langCodeToKenticoLangMap[language],
      'system.language': langCodeToKenticoLangMap[language],
    },
  };
  const kenticoResponse = await sendRequestTyped<KenticoWebTemplateElement, KenticoWebTemplateModularContent>(
    params,
  );

  return convertWebTemplate(kenticoResponse);
}
