import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import '@app/styles/fonts.css';
import configureStore from '@app/store';
import { App } from '@app/components';
import { detectUser } from '@app/store/actions/userActions';

const { store, history } = configureStore();

detectUser(store.dispatch);

ReactDOM.hydrate(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);

if (process.env.NODE_ENV === 'production') {
  const buildTime = new Date(process.env.BUILD_TIME || Date.now());
  const appVersion = process.env.COMMIT_HASH;

  console.log(`%cbuild version: ${appVersion}\nbuild time: ${buildTime}`,
    'border: solid 1px blue; padding: 8px; font-weight: bold;');
}
