import React from 'react';
import styled from 'styled-components';

import * as Translations from '@app/locales/index';
import { useLanguage } from '@app/components/Hooks';
import {
  MatchBall, MatchShots, MatchPasses, MatchTackles, MatchDisciplines,
} from './components';

const MatchStatistics = (): React.ReactElement => {
  const language = useLanguage();

  return (
    <Container>
      <Heading>{Translations[language]?.['matchcenter.tabs.statistics']}</Heading>
      <MatchBall />
      <MatchShots />
      <MatchPasses />
      <MatchTackles />
      <MatchDisciplines />
    </Container>
  );
};

export default MatchStatistics;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
`;

const Heading = styled.h2`
  display: none;
`;
