import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import remove from 'lodash/remove';

import { AppState } from '@app/store/reducers';
import { Competitions } from '@app/types/configurationTypes';
import {
  Player, PlayerStatistics, TeamStatistics, LocalizedPlayer,
} from '@app/types/teamsTypes';

import { getPlayersStatistics, setPlayerPageMultiLangRoute } from '@app/store/actions/teamsActions';
import { useLanguage, useMenu } from '@app/components/Hooks';

import {
  useLocalizedSubMenuItem, useSubMenuItem, useTeam, useTeamSubMenuId,
} from '@app/pages/Teams/TeamsHooks';
import { getLocalizedPlayer, gepPlayerTabs } from '@app/helpers/teamHelpers';
import { mapTeamTypeToCompetition } from '@app/helpers/configurationHelpers';
import { MountContext } from '@app/ReactContext';
import { Errors, PageError } from '@app/types/errorTypes';

export function usePlayersStatisticsDownload(): void {
  const { isInitialMount } = useContext(MountContext);
  const { locale } = useIntl();
  const language = useLanguage();
  const { teamType = '' } = useParams();
  const teamSubMenuId = useTeamSubMenuId();

  const dispatch = useDispatch();

  const hasItems = useSelector<AppState, boolean>(
    (state) => !!state.teams.teamsStatistics[teamSubMenuId],
    shallowEqual,
  );

  useEffect(() => {
    if (teamType && (!isInitialMount || !hasItems)) {
      // check if data has been loaded on server before very first render
      dispatch(getPlayersStatistics({ teamType, language }));
    }
  }, [teamType, locale]);
}

export function useTeamStatistics(): TeamStatistics | undefined {
  const teamSubMenuId = useTeamSubMenuId();
  return useSelector<AppState, TeamStatistics | undefined>(
    (state) => state.teams.teamsStatistics[teamSubMenuId],
    shallowEqual,
  );
}

function useLocalizedPlayer(): LocalizedPlayer {
  const { urlSlug = '' } = useParams();
  const language = useLanguage();
  const teamSubMenuId = useTeamSubMenuId();

  return useSelector<AppState, LocalizedPlayer>(
    (state) => getLocalizedPlayer(state, teamSubMenuId, language, urlSlug) as LocalizedPlayer,
    shallowEqual,
  );
}

export function usePlayer(): Player | undefined {
  const language = useLanguage();
  return useLocalizedPlayer()?.[language];
}

export function usePlayerStatistics(): PlayerStatistics | undefined | PageError {
  const player = usePlayer();
  const playerId = player?.id?.trim() ?? '';
  const teamStatistics = useTeamStatistics();
  const statistics = teamStatistics?.players[playerId.startsWith('p') ? playerId.substr(1) : playerId];
  if (teamStatistics?.players && !statistics) return PageError.NotFound;
  return statistics;
}

export interface Tab {
  name: string;
  component: () => React.ReactElement;
  caption: string;
}

export function useTabs(): Tab[] {
  const subItemId = useTeamSubMenuId();
  const locale = useLanguage();
  const competitions = useSelector<AppState, Competitions>(
    (state) => state.configuration[locale]?.competitions,
    shallowEqual,
  );
  const competition = mapTeamTypeToCompetition(subItemId, competitions);
  const tabs = gepPlayerTabs(locale);

  if (!competition) {
    remove(tabs, (tab) => tab.name === 'player.tabs.statistics');
  }

  return tabs;
}

export function useActiveTab(): Tab | undefined {
  const tabs = useTabs();
  const { tab } = useParams();
  return tabs.find((item) => item.caption === tab);
}

export function usePlayersMultiLangUrl(): void {
  const dispatch = useDispatch();
  const { teamType = '', urlSlug = '', tab = '' } = useParams();
  const language = useLanguage();
  const localizedSubItem = useLocalizedSubMenuItem();
  const tabs = useTabs();
  const selectedTab = tabs.find((item) => item.caption === tab) || tabs[0];

  useEffect(() => {
    if (localizedSubItem) {
      dispatch(setPlayerPageMultiLangRoute({
        teamType,
        language,
        tab,
        urlSlug,
      }));
    }
  }, [localizedSubItem, urlSlug, selectedTab]);
}

export function useRouterParamsValidation(): Errors {
  const subItem = useSubMenuItem();
  const menu = useMenu();

  const activeTab = useActiveTab();
  const isIncorrectTabId = !activeTab;

  const player = usePlayer();
  const teamSubMenuId = useTeamSubMenuId();
  const team = useTeam(teamSubMenuId);
  const isIncorrectPlayerId = Boolean(team?.length && !player);

  return menu !== null && (!subItem || isIncorrectTabId || isIncorrectPlayerId)
    ? PageError.NotFound
    : null;
}
