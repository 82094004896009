import uniqWith from 'lodash/uniqWith';

import * as HomeService from '@app/services/kentico/homeService';
import * as CarouselService from '@app/services/kentico/carouselService';
import { getMatchResultsData, getSDMatchResultsData } from '@app/services/opta/matchService';
import { waitForConfigurationDownload } from '@app/store/actions/configurationActions';
import { setCurrentRoute } from '@app/store/actions/currentRouteActions';
import { isOptaSDCompetition } from '@app/services/opta/helpers/competitionHelpers';
import { Action } from '@app/types/actionTypes';
import { TopNews } from '@app/types/newsTypes';
import {
  SetMatchBox, SetSequence, SetCarouselSlides, ResetHomeData, SetHomeLandingData, SetHomeSliderData,
  SetHomeGoogleAds, SetGameId, SetEmbeddedOverlay,
} from '@app/store/actionTypes/homeActionTypes';
import { MatchResult, HomeMatch } from '@app/types/matchTypes';
import { HomeBlocks, HomeMatchBoxConfiguration } from '@app/types/homeConfigurationTypes';
import { LanguageType } from '@app/types/localizationTypes';
import AppRoutes from '@app/constants/routes';
import {
  WebEmbeddedOverlay, WebGoogleAds, WebHomeLanding, WebHomeSlider,
} from '@app/types/webTemplateTypes';

function setMatchBox(matchBox: HomeMatch[]): SetMatchBox {
  return {
    type: 'HOME/SET_MATCHBOX',
    payload: matchBox,
  };
}

function setGameId(gameId: string): SetGameId {
  return {
    type: 'HOME/SET_GAME_ID',
    payload: gameId,
  };
}

function setEmbeddedOverlay(data: WebEmbeddedOverlay): SetEmbeddedOverlay {
  return {
    type: 'HOME/SET_EMBEDDED_OVERLAY',
    payload: data,
  };
}

function setSequence(sequence: HomeBlocks[]): SetSequence {
  return {
    type: 'HOME/SET_SEQUENCE',
    payload: sequence,
  };
}

function setCarouselData(slides: TopNews[]): SetCarouselSlides {
  return {
    type: 'HOME/SET_CAROUSEL_SLIDES',
    payload: slides,
  };
}

function setHomeLandingData(data: WebHomeLanding): SetHomeLandingData {
  return {
    type: 'HOME/SET_HOME_LANDING_DATA',
    payload: data,
  };
}

function setHomeSliderData(data: WebHomeSlider): SetHomeSliderData {
  return {
    type: 'HOME/SET_HOME_SLIDER_DATA',
    payload: data,
  };
}

function setHomeGoogleAds(data: WebGoogleAds): SetHomeGoogleAds {
  return {
    type: 'HOME/SET_HOME_GOOGLE_ADS',
    payload: data,
  };
}

export const resetHomeData = (): ResetHomeData => ({ type: 'HOME/RESET' });

function getHomeMatches(matches: MatchResult[], matchBoxConfiguration: HomeMatchBoxConfiguration): HomeMatch[] {
  return matchBoxConfiguration.reduce((homeMatches, { gameId, competitionId }) => {
    const match = matches?.find((game) => game.gameOptaId === gameId);

    if (match) {
      homeMatches.push({
        ...match,
        competitionId,
      });
    }

    return homeMatches;
  }, [] as HomeMatch[]);
}

export function getMatchBoxData(
  matchBoxConfiguration: HomeMatchBoxConfiguration,
  language: LanguageType,
): Action {
  return async (dispatch, getState): Promise<void> => {
    try {
      const state = getState();
      const uniqCompetitions = uniqWith(matchBoxConfiguration, (game1, game2) => (
        game1.competitionId === game2.competitionId && game1.seasonId === game2.seasonId
      ));

      const matchRequests = uniqCompetitions.reduce((acc, { competitionId, seasonId }) => {
        // Todo think if we should call methoв on language change (for now it is not called)
        const competitionOptaId = state.configuration[language]?.competitions[competitionId]?.optaId;

        if (competitionOptaId) {
          const matchRequest = isOptaSDCompetition(competitionId)
            ? getSDMatchResultsData({ id: competitionId, seasonId, optaId: competitionOptaId })
            : getMatchResultsData({ seasonId, optaId: competitionOptaId, language });

          acc.push(matchRequest);
        }

        return acc;
      }, [] as Promise<MatchResult[]>[]);

      const allMatches = await Promise.all(matchRequests);
      const games = getHomeMatches(allMatches.flat(), matchBoxConfiguration);

      dispatch(setMatchBox(games));
    } catch (e) {
      console.error('Error on fetching schedule', e);
      // Todo clarify with PO error scenario
    }
  };
}

export function getHomeCarouselData(carousel: string, language: LanguageType): Action {
  return async (dispatch): Promise<void> => {
    try {
      const carouselData = await CarouselService.getCarouselData({ carousel, language });
      dispatch(setCarouselData(carouselData));
    } catch (e) {
      console.error('Error on fetching home configuration', e);
      // Todo clarify with PO error scenario
    }
  };
}

export function getHomeData({ language }: { language: LanguageType }): Action {
  return async (dispatch): Promise<void> => {
    try {
      const homeConfiguration = await HomeService.getHomeConfiguration(language);
      await waitForConfigurationDownload();

      dispatch(setGameId(homeConfiguration.gameId));
      dispatch(setEmbeddedOverlay(homeConfiguration.embeddedOverlay));
      dispatch(setSequence(homeConfiguration.sequence));
      dispatch(getHomeCarouselData(homeConfiguration.carousel, language));
      return dispatch(getMatchBoxData(homeConfiguration.matchBox, language));
    } catch (e) {
      console.error('Error on fetching home configuration', e);
      // Todo clarify with PO error scenario
    }
  };
}

export function setHomePageMultiLangRoute(): Action {
  return (dispatch): void => {
    dispatch(setCurrentRoute({
      pathId: AppRoutes.Home.path,
    }));
  };
}

interface HomeData {
  id: HomeBlocks;
  language: LanguageType;
}

export function getHomeLandingData({ id, language }: HomeData): Action {
  return async (dispatch): Promise<void> => {
    try {
      const homeLandingData = await HomeService.getHomeLandingData(id, language);
      await waitForConfigurationDownload();

      dispatch(setHomeLandingData(homeLandingData));
    } catch (e) {
      console.error('Error on fetching home configuration', e);
      // Todo clarify with PO error scenario
    }
  };
}

export function getHomeSliderData({ id, language }: HomeData): Action {
  return async (dispatch): Promise<void> => {
    try {
      const homeSliderData = await HomeService.getHomeSliderData(id, language);
      await waitForConfigurationDownload();

      dispatch(setHomeSliderData(homeSliderData));
    } catch (e) {
      console.error('Error on fetching home configuration', e);
      // Todo clarify with PO error scenario
    }
  };
}

export function getHomeGoogleAds({ id, language }: HomeData): Action {
  return async (dispatch): Promise<void> => {
    await waitForConfigurationDownload();

    try {
      const homeGoogleAds = await HomeService.getHomeGoogleAds(id, language);

      if (homeGoogleAds) dispatch(setHomeGoogleAds(homeGoogleAds));
    } catch (e) {
      console.error('Error on fetching home google ads', e);
      // Todo clarify with PO error scenario
    }
  };
}
