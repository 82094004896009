import React from 'react';

const ExpandArrow = (): React.ReactElement => (
  <svg width="11px" height="8px" viewBox="0 0 11 8" version="1.1">
    <g id="Web_Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
      <g id="icon" transform="translate(-1199.000000, -91.000000)" stroke="#000000" strokeWidth="2">
        <g id="Menu">
          <g id="Language" transform="translate(1150.000000, 89.000000)">
            <g id="Group-2" transform="translate(50.000000, 2.000000)">
              <path d="M5,6 L8.45337415,1.76911212" id="Line-2" transform="translate(7.000000, 3.500000) rotate(4.847610) translate(-7.000000, -3.500000) " />
              <path d="M1,6.92759055 L4.45337415,2.69670267" id="Line-2" transform="translate(3.000000, 4.427591) scale(1, -1) rotate(4.847610) translate(-3.000000, -4.427591) " />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ExpandArrow;
