import React from 'react';

import {
  ArticleModularContentItem, BaseNewsItem, Gallery, ArticleContentType, VideoItem,
} from '@app/types/newsTypes';
import { WebGoogleAds, WebPageSeparatorSizeType } from '@app/types/webTemplateTypes';
import { GalleryPreview, NewsContentLink, VideoPlayer } from '@app/components';
import {
  WebEditorialElement, WebPageSeparatorElement, WebGoogleAdsElement,
  WebEmbeddedElement, WebEmbeddedOverlayElement, WebEmbeddedOneTrustElement,
} from '@app/components/WebTemplates/elements';

interface NewsContentProps {
  content: ArticleModularContentItem[];
}

const NewsContent = ({ content }: NewsContentProps): React.ReactElement => (
  <>
    {
      content.map(({ type, value }, key) => {
        const keygen = `${type}${key}`;
        switch (type) {
          case ArticleContentType.TEXT:
            return <WebEditorialElement content={(value ?? '') as string} key={keygen} />;
          case ArticleContentType.EMBEDDED:
            return <WebEmbeddedElement content={(value ?? '') as string} key={keygen} />;
          case ArticleContentType.EMBEDDED_ONETRUST:
            return <WebEmbeddedOneTrustElement content={(value ?? '') as string} key={keygen} />;
          case ArticleContentType.EMBEDDED_OVERLAY:
            return <WebEmbeddedOverlayElement content={(value ?? '') as string} key={keygen} />;
          case ArticleContentType.VIDEO:
            return <VideoPlayer video={(value as VideoItem) ?? undefined} isEmbed key={keygen} />;
          case ArticleContentType.PHOTO:
            return <GalleryPreview gallery={value as Gallery} key={keygen} />;
          case ArticleContentType.NEWS:
            return <NewsContentLink news={value as BaseNewsItem} key={keygen} />;
          case ArticleContentType.SEPARATOR:
            return <WebPageSeparatorElement size={value as WebPageSeparatorSizeType} key={keygen} />;
          case ArticleContentType.GOOGLE_ADS:
            return <WebGoogleAdsElement data={value as WebGoogleAds} key={keygen} />;
          default:
            return null;
        }
      })
    }
  </>
);

export default NewsContent;
