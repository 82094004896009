import React, { useState, useRef, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styled from 'styled-components';

import { checkIsBrowser } from '@app/helpers/documentHelpers';

interface ImageProps {
  src: string;
  preloadSrc?: string;
  description: string;
  additionalImageProps?: React.ImgHTMLAttributes<HTMLImageElement>;
  onImageLoad?: () => void;
  onPreloadImageLoad?: () => void;
  className?: string; // passed by styled component automatically
  useLazyLoading?: boolean;
}

const LazyImage = ({
  src, preloadSrc, description, additionalImageProps, onImageLoad, onPreloadImageLoad, className, useLazyLoading,
}: ImageProps): React.ReactElement | null => {
  const [isLoaded, setImageLoadedState] = useState(false);
  const imageRef = useRef(null);
  const imageEl = imageRef?.current as unknown as HTMLImageElement;
  // we need to add additional check because sometimes image is loaded before JS code starts to execute
  const isImageLoaded = isLoaded || imageEl?.complete;

  const [isPreviewLoaded, setPreviewImageLoadedState] = useState(false);
  const imagePreviewRef = useRef(null);
  const imagePreviewEl = imagePreviewRef?.current as unknown as HTMLImageElement;
  // we need to add additional check because sometimes image is loaded before JS code starts to execute
  const isPreviewImageLoaded = isPreviewLoaded || imagePreviewEl?.complete;

  const isBrowser = checkIsBrowser();

  useEffect(() => {
    isImageLoaded && onImageLoad && onImageLoad();
    isPreviewImageLoaded && onPreloadImageLoad && onPreloadImageLoad();
  }, [isImageLoaded, isPreviewImageLoaded]);

  return isBrowser ? (
    <>
      <PreloadImage
        src={preloadSrc ?? `${src}?w=${20}&auto=format`}
        alt={description}
        isVisible={!isImageLoaded}
        onLoad={(): void => setPreviewImageLoadedState(true)}
        className={className}
        as={useLazyLoading ? LazyLoadImage : 'img'}
        ref={imagePreviewRef}
      />
      <ImageElement
        src={src}
        {...additionalImageProps}
        alt={description}
        onLoad={(): void => setImageLoadedState(true)}
        isVisible={isImageLoaded}
        className={className}
        as={useLazyLoading ? LazyLoadImage : 'img'}
        ref={imageRef}
      />
    </>
  ) : null;
};

const ImageElement = styled.img<{ isVisible: boolean }>`
  width: 100%;
  position: ${({ isVisible }): string => (isVisible ? 'relative' : 'absolute')};
  top: 0;
  z-index: ${({ isVisible }): number => (isVisible ? 0 : -1)};
  min-height: 1px; // required for correct lazy-load on tablets and desktop
  min-width: 1px; // required for correct lazy-load on tablets and desktop
`;

const PreloadImage = styled.img<{ isVisible: boolean }>`
  width: 100%;
  display:  ${({ isVisible }): string => (isVisible ? 'block' : 'none')};
  position: relative;
  filter: blur(10px);
`;

export default LazyImage;
