import { sendRequestTyped } from '@app/services/kentico/request';
import { langCodeToKenticoLangMap } from '@app/constants/localizationConstants';

import { LanguageType } from '@app/types/localizationTypes';
import { HallOfFamePlayer } from '@app/types/hallOfFameTypes';
import { KenticoItem } from '@app/services/kentico/types/responseTypes';
import { WebHallOfFameInductee } from '@app/types/webTemplateTypes';
import { RequestParamsInterface } from '@app/services/kentico/types/requestTypes';

import { KenticoWebHallOfFameInducteeData, WebTemplate } from './types/webTemplateTypes';
import { KenticoHallOfFameElement } from './types/hallOfFameTypes';
import { convertHallOfFame } from './converters/HallOfFameConverter';
import { convertWebHallOfFameInductee } from './converters/webTemplateConverter';

export const getHallOfFame = async (language: LanguageType): Promise<HallOfFamePlayer[]> => {
  const response = await sendRequestTyped<KenticoHallOfFameElement>({
    params: {
      'system.type': 'player_halloffame',
      language: langCodeToKenticoLangMap[language],
      'system.language': langCodeToKenticoLangMap[language],
    },
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return convertHallOfFame(response);
};

export async function getHallOfFameInductee(slug = '', language = 'it'): Promise<WebHallOfFameInductee> {
  const params: RequestParamsInterface = {
    'system.type': 'hall_of_fame_inductee',
    'elements.url_slug': slug,
    language: langCodeToKenticoLangMap[language],
    'system.language': langCodeToKenticoLangMap[language],
  };

  const response = await sendRequestTyped({ params });
  const item = response?.items?.[0] as KenticoItem<KenticoWebHallOfFameInducteeData>;

  return convertWebHallOfFameInductee(response as WebTemplate, item);
}

export async function getHallOfFameInductees(language = 'it'): Promise<WebHallOfFameInductee[]> {
  const params: RequestParamsInterface = {
    'system.type': 'hall_of_fame_inductee',
    language: langCodeToKenticoLangMap[language],
    'system.language': langCodeToKenticoLangMap[language],
  };

  const response = await sendRequestTyped({ params });
  // eslint-disable-next-line max-len
  return response?.items?.map((item) => convertWebHallOfFameInductee(response as WebTemplate, item as KenticoItem<KenticoWebHallOfFameInducteeData>)) || [];
}
