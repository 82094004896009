import { LocalizedNewsData, LocalizedWebTemplateData, LocalizedWebTemplateError } from '@app/types/webTemplateTypes';
import { NewsList } from '@app/types/newsTypes';
import { LanguageType } from '@app/types/localizationTypes';

export enum WebTemplateActionTypes {
  LOADING_WEB_TEMPLATE_DATA = 'WEB_TEMPLATE/LOADING_WEB_TEMPLATE_DATA',
  SET_WEB_TEMPLATE_DATA = 'WEB_TEMPLATE/SET_WEB_TEMPLATE_DATA',
  SET_NEWS_DATA = 'WEB_TEMPLATE/SET_NEWS_DATA',
  SET_NEWS_LOADING_STATE = 'WEB_TEMPLATE/SET_NEWS_LOADING_STATE',
  RESET_WEB_TEMPLATE_DATA = 'WEB_TEMPLATE/RESET_WEB_TEMPLATE_DATA',
  SET_WEB_TEMPLATE_ERROR = 'WEB_TEMPLATE/SET_WEB_TEMPLATE_ERROR',
  RESET_WEB_TEMPLATE_ERROR = 'WEB_TEMPLATE/RESET_WEB_TEMPLATE_ERROR',
}

export interface LoadingWebTemplateData {
  type: WebTemplateActionTypes.LOADING_WEB_TEMPLATE_DATA;
  payload: boolean;
}

export interface SetWebTemplateDataPayload {
  templates: {
    data: LocalizedWebTemplateData;
    id: string;
  };
  newsData: LocalizedNewsData;
}

export interface SetWebTemplateData {
  type: WebTemplateActionTypes.SET_WEB_TEMPLATE_DATA;
  payload: SetWebTemplateDataPayload;
}

export interface SetNewsDataActionPayload {
  id: string;
  newsData: NewsList;
  language: LanguageType;
}

export interface SetNewsData {
  type: WebTemplateActionTypes.SET_NEWS_DATA;
  payload: SetNewsDataActionPayload;
}

export interface SetNewsLoadingStateActionPayload {
  id: string;
  isLoading: boolean;
  language: LanguageType;
}

export interface SetNewsLoadingState {
  type: WebTemplateActionTypes.SET_NEWS_LOADING_STATE;
  payload: SetNewsLoadingStateActionPayload;
}

export interface ResetWebTemplateData {
  type: WebTemplateActionTypes.RESET_WEB_TEMPLATE_DATA;
}

export interface SetWebTemplateError {
  type: WebTemplateActionTypes.SET_WEB_TEMPLATE_ERROR;
  payload: LocalizedWebTemplateError;
}

export interface ResetWebTemplateError {
  type: WebTemplateActionTypes.RESET_WEB_TEMPLATE_ERROR;
}

export type WebTemplateActions = SetWebTemplateData | ResetWebTemplateData | SetWebTemplateError
  | ResetWebTemplateError | LoadingWebTemplateData | SetNewsData | SetNewsLoadingState;
