import React from 'react';
import styled from 'styled-components';

import { getLineupFormation, getPlayerNumberByFormation } from '@app/helpers/matchHelpers';
import { useLineupsSelector } from '@app/pages/MatchCenter/MatchCenterHooks';

const Line = ({ dots, players }: {dots: number[]; players: []}): React.ReactElement => (
  <GridLine>
    {dots.map((number) => <GridNumber>{getPlayerNumberByFormation(players, number)}</GridNumber>)}
  </GridLine>
);

const Formation = ({ team }: {team: unknown}): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore // TODO:REMOVE:ignore
  const numbers = getLineupFormation(team?.formation ?? '');

  return (
    <>
      { // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore // TODO:REMOVE:ignore
        `1${team?.formation}`.split('').map((indexPlus, i) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore // TODO:REMOVE:ignore
          const nextIndex = `1${team?.formation}`
            .slice(0, i)
            .split('')
            .reduce((a, b) => Number(a) + Number(b), 0);
          return (
            <Line
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore // TODO:REMOVE:ignore
              players={team?.players} // eslint-disable-next-line react/no-array-index-key
              key={indexPlus + i}
              dots={numbers.slice(nextIndex, nextIndex + Number(indexPlus))}
            />
          );
        })
      }
    </>
  );
};

const FormationGrid = (): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore // TODO:REMOVE:ignore
  const { home, away } = useLineupsSelector() ?? {};
  const arePlayersLoaded = !!(home?.players?.length && away?.players?.length);
  return (
    <Container>
      <img src="/images/field.svg" alt="formation" width="100%" />
      {arePlayersLoaded && (
        <Grid>
          <Side>
            <Formation team={home} />
          </Side>
          <Side away>
            <Formation team={away} />
          </Side>
        </Grid>
      )}
    </Container>
  );
};

export default FormationGrid;

const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 200px;
  background-color: #00985b;
  padding: 1px;
  margin-bottom: 30px;
`;

const Grid = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const GridLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 20px;
  height: 100%;
`;

const GridNumber = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: 11px;
  font-weight: 600;
`;

const Side = styled.div<{ away?: boolean }>`
  display: flex;
  flex: 0 0 50%;
  flex-direction: ${({ away = false }): string => (away ? 'row-reverse' : 'row')};
  align-items: center;
  justify-content: space-around;

  & ${GridLine} {
    flex-direction: ${({ away = false }): string => (away ? 'column' : 'column-reverse')};
  }

  & ${GridNumber} {
    background-color: ${({ away = false, theme }): string => (away ? 'black' : theme.color.freeSpeechRed)};
  }
`;
