import React, { useEffect, useState } from 'react';

import { GoogleAdsViewProps, GoogleAdsViewTypes } from '@app/types/googleTypes';
import { GoogleAdsFullscreen, GoogleAdsHelloBar, GoogleAdsStandard } from './components';
import { useAdsStatus, useGPT } from './GoogleAdsHooks';

const GoogleAds = React.memo(({
  adUnit, slotId, size, text, color, background, animated, view = GoogleAdsViewTypes.Standard, onSlotRender, Container,
}: GoogleAdsViewProps): React.ReactElement | null => {
  const useOverlay = [GoogleAdsViewTypes.Fullscreen, GoogleAdsViewTypes.HelloBar].includes(view);

  const [isModalOpened, setIsModalOpened] = useState(useOverlay);
  const [isModalPreview, setIsModalPreview] = useState(useOverlay);

  const useSlotInit = useGPT({ adUnit, slotId, size });
  const visibility = useAdsStatus(adUnit);
  const isRendered = visibility !== null;

  useEffect(() => {
    if (isRendered) {
      if (useOverlay && visibility === true) setIsModalPreview(false);
      if (useOverlay && visibility === false) setIsModalOpened(false);
      if (onSlotRender) onSlotRender(visibility === false);
    }
  }, [isRendered]);

  if (!useSlotInit) return null;

  switch (view) {
    case GoogleAdsViewTypes.Fullscreen: return (
      <GoogleAdsFullscreen
        adUnit={adUnit}
        slotId={slotId}
        size={size}
        text={text}
        color={color}
        background={background}
        isOpened={isModalOpened}
        isPreview={isModalPreview}
        onClose={(): void => setIsModalOpened(false)}
      />
    );
    case GoogleAdsViewTypes.HelloBar: return (
      <GoogleAdsHelloBar
        adUnit={adUnit}
        slotId={slotId}
        size={size}
        text={text}
        color={color}
        background={background}
        isOpened={isModalOpened}
        isPreview={isModalPreview}
        onClose={(): void => setIsModalOpened(false)}
      />
    );
    default: case GoogleAdsViewTypes.Standard: return (
      <GoogleAdsStandard
        adUnit={adUnit}
        slotId={slotId}
        size={size}
        animated={animated}
        Container={Container}
      />
    );
  }
});

export default GoogleAds;
