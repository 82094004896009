import styled from 'styled-components';

export const VideoPlayerElement = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 590px;
  border: 0;
  z-index: 10;
`;

export const VideoPlayerPremiumWrapper = styled.div`
  position: relative;
`;

export const VideoPlayerContainer = styled.section`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 20px;
  height: 0;
  z-index: 0;
  overflow: hidden;
`;

export const VideoPlayerLoader = styled.section`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 590px;
  z-index: 1;
  text-transform: uppercase;
  animation: blinker 1s linear infinite;
  box-shadow: 0 0 4px -2px rgba(102,102,102,1);

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`;
