import { encodeQueryData } from '../helpers/requestHelpers';

const Hostname = process.env.API === 'production'
  ? 'identity.acmilan.com/identity'
  : 'acmilan--partial.sandbox.my.site.com/identity';
const CallbackUrl = process.env.API === 'production'
  ? 'https://acmilan.com'
  : 'https://acmilan-web-integ.netcosports.com';// : 'https://localhost:3050';
const ConsumerKey = process.env.API === 'production'
  ? '3MVG9fTLmJ60pJ5KiKCzxM1KcWKwDaxVL1YfGBNWGetLFRvWi3HCfLxm8et83pJMXu28BZi1mbqQhAPkJ03T.'
  : '3MVG9ZPHiJTk7yFxX7Ifa1BlwoIcTRT2Gedd2p2iQbmji.kEMcn8hiFHHztVaUzgkSOssvo6VdXtyi33K0W1e';

export { Hostname };

export interface RequestInterface {
  method?: RequestMethodEnum;
  path: SalesforcePathEnum;
  params?: RequestParamsInterface;
}

export enum RequestMethodEnum {
  Get = 'GET',
  Post = 'POST',
}

export interface RequestParamsInterface {
  [name: string]: string | number | boolean | undefined;
}

export enum SalesforcePathEnum {
  GetToken = '/token',
  RefreshToken = '/token',
  Logout = '/revoke',
}

export function GetAuthenticationUrl(language: string): string {
  const authURL = 'services/oauth2/authorize';
  const expId = `expid_${language ?? 'it'}website`;
  const responseType = 'response_type=code';
  const clientId = `client_id=${ConsumerKey}`;
  const redirectURL = `redirect_uri=${CallbackUrl}/${language ?? 'it'}`;

  return `//${Hostname}/${authURL}/${expId}?${responseType}&${clientId}&${redirectURL}&state=web`;
}

export function GetProfileUrl(language?: string): string {
  return `//${Hostname}/s/myprofile${language ? `?language=${language}` : ''}`;
}

export function GetRegistrationUrl(language?: string): string {
  return `//${Hostname}/s/fast-register?source=website${language ? `&language=${language}` : ''}`;
}

export const GetLogoutUrl = (language?: string): string => (
  `//${Hostname}/services/auth/idp/oidc/logout?retUrl=/identity/website?lang=${language ?? 'it'}`
);

function sendRequestInternal(options: RequestInterface): Promise<Response> {
  const Url = `https://${Hostname}/services/oauth2${options.path}`;
  const QueryString = options.params ? `?${encodeQueryData(options.params)}` : '';
  const RequestUrl = Url + QueryString;
  return fetch(`/api/oauth/salesforce?url=${encodeURIComponent(RequestUrl)}`, { method: options.method });
}

export function GetToken(code: string): Promise<Response> {
  /* eslint-disable @typescript-eslint/camelcase */
  const RequestParams = {
    code,
    grant_type: 'authorization_code',
    client_id: ConsumerKey,
    redirect_uri: CallbackUrl,
  };
  /* eslint-enable @typescript-eslint/camelcase */
  return sendRequestInternal({
    method: RequestMethodEnum.Post,
    path: SalesforcePathEnum.GetToken,
    params: RequestParams,
  });
}

export function RefreshToken(refreshToken: string): Promise<Response> {
  /* eslint-disable @typescript-eslint/camelcase */
  const RequestParams = {
    refresh_token: refreshToken,
    grant_type: 'refresh_token',
    client_id: ConsumerKey,
    redirect_uri: CallbackUrl,
  };
  /* eslint-enable @typescript-eslint/camelcase */
  return sendRequestInternal({
    method: RequestMethodEnum.Post,
    path: SalesforcePathEnum.RefreshToken,
    params: RequestParams,
  });
}

export function Logout(AccessToken: string): Promise<Response> {
  /* eslint-disable @typescript-eslint/camelcase */
  const RequestParams = {
    token: AccessToken,
  };
  /* eslint-enable @typescript-eslint/camelcase */
  return sendRequestInternal({
    method: RequestMethodEnum.Post,
    path: SalesforcePathEnum.Logout,
    params: RequestParams,
  });
}
