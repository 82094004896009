import React from 'react';
import { FormattedMessage } from 'react-intl';

import theme from '@app/styles/theme';
import { MatchStatistics } from '@app/types/matchTypes';
import { useLineupsSelector } from '@app/pages/MatchCenter/MatchCenterHooks';
import { CircleGraph } from '@app/components/Graphs/CircleGraph';
import {
  BlockContainerColumn, Caption, CircleCaption, StatsBlock, StatsBlockColumn, Value,
  FieldInsideImage, FieldOutsideImage, ValueAndGraph,
} from '@app/components/Statistics';

const MatchShots = (): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore // TODO:REMOVE:ignore
  const { home, away } = useLineupsSelector() ?? {};

  return (
    <BlockContainerColumn>
      <StatsBlock marginTop="20px" marginBottom="40px">
        <StatsBlock>
          <Value>{`${home?.stats?.[MatchStatistics.STATS_TOTAL_SHOTS]}`}</Value>
        </StatsBlock>
        <StatsBlock>
          <Caption><FormattedMessage id="statistics.totalShots" /></Caption>
        </StatsBlock>
        <StatsBlock>
          <Value>{`${away?.stats?.[MatchStatistics.STATS_TOTAL_SHOTS]}`}</Value>
        </StatsBlock>
      </StatsBlock>
      <StatsBlock marginBottom="20px">
        <StatsBlockColumn>
          <StatsBlock marginBottom="20px">
            <ValueAndGraph
              caption="statistics.onTarget"
              progress={
                (home?.stats?.[MatchStatistics.STATS_SHOTS_ON_TARGET] * 100)
                / home?.stats?.[MatchStatistics.STATS_TOTAL_SHOTS]
              }
              value={home?.stats?.[MatchStatistics.STATS_SHOTS_ON_TARGET]}
            />
          </StatsBlock>
          <StatsBlock marginBottom="20px">
            <ValueAndGraph
              caption="statistics.offTarget"
              progress={
                (home?.stats?.[MatchStatistics.STATS_SHOTS_OF_TARGET] * 100)
                / home?.stats?.[MatchStatistics.STATS_TOTAL_SHOTS]
              }
              value={home?.stats?.[MatchStatistics.STATS_SHOTS_OF_TARGET]}
            />
          </StatsBlock>
          <StatsBlock>
            <ValueAndGraph
              caption="statistics.blocked"
              progress={
                (home?.stats?.[MatchStatistics.STATS_BLOCKED_SHOTS] * 100)
                / home?.stats?.[MatchStatistics.STATS_TOTAL_SHOTS]
              }
              value={home?.stats?.[MatchStatistics.STATS_BLOCKED_SHOTS]}
            />
          </StatsBlock>
        </StatsBlockColumn>
        <StatsBlockColumn>
          <StatsBlock marginBottom="20px">
            <ValueAndGraph
              strokeHomeColor={theme.color.veryDarkGray}
              caption="statistics.onTarget"
              progress={
                (away?.stats?.[MatchStatistics.STATS_SHOTS_ON_TARGET] * 100)
                / away?.stats?.[MatchStatistics.STATS_TOTAL_SHOTS]
              }
              value={away?.stats?.[MatchStatistics.STATS_SHOTS_ON_TARGET]}
            />
          </StatsBlock>
          <StatsBlock marginBottom="20px">
            <ValueAndGraph
              strokeHomeColor={theme.color.veryDarkGray}
              caption="statistics.offTarget"
              progress={
                (away?.stats?.[MatchStatistics.STATS_SHOTS_OF_TARGET] * 100)
                / away?.stats?.[MatchStatistics.STATS_TOTAL_SHOTS]
              }
              value={away?.stats?.[MatchStatistics.STATS_SHOTS_OF_TARGET]}
            />
          </StatsBlock>
          <StatsBlock>
            <ValueAndGraph
              strokeHomeColor={theme.color.veryDarkGray}
              caption="statistics.blocked"
              progress={
                (away?.stats?.[MatchStatistics.STATS_BLOCKED_SHOTS] * 100)
                / away?.stats?.[MatchStatistics.STATS_TOTAL_SHOTS]
              }
              value={away?.stats?.[MatchStatistics.STATS_BLOCKED_SHOTS]}
            />
          </StatsBlock>
        </StatsBlockColumn>
      </StatsBlock>
      <StatsBlock marginTop="20px" marginBottom="20px">
        <Caption><FormattedMessage id="statistics.shotsDistribution" /></Caption>
      </StatsBlock>
      <StatsBlock marginBottom="20px">
        <StatsBlock>
          <FieldInsideImage />
        </StatsBlock>
        <StatsBlock>
          <FieldOutsideImage />
        </StatsBlock>
        <StatsBlock />
        <StatsBlock>
          <FieldInsideImage />
        </StatsBlock>
        <StatsBlock>
          <FieldOutsideImage />
        </StatsBlock>
      </StatsBlock>
      <StatsBlock marginBottom="20px">
        <StatsBlock>
          <CircleGraph
            width={50}
            height={50}
            value={(home?.stats?.[MatchStatistics.STATS_SHOTS_FROM_BOX] * 100)
            / (home?.stats?.[MatchStatistics.STATS_TOTAL_SHOTS] * 100)}
          >
            <CircleCaption>{home?.stats?.[MatchStatistics.STATS_SHOTS_FROM_BOX]}</CircleCaption>
          </CircleGraph>
        </StatsBlock>
        <StatsBlock>
          <CircleGraph
            width={50}
            height={50}
            value={(home?.stats?.[MatchStatistics.STATS_SHOTS_OUT_BOX] * 100)
            / (home?.stats?.[MatchStatistics.STATS_TOTAL_SHOTS] * 100)}
          >
            <CircleCaption>{home?.stats?.[MatchStatistics.STATS_SHOTS_OUT_BOX]}</CircleCaption>
          </CircleGraph>
        </StatsBlock>
        <StatsBlock />
        <StatsBlock>
          <CircleGraph
            strokeAwayColor={theme.color.veryDarkGray}
            width={50}
            height={50}
            value={(away?.stats?.[MatchStatistics.STATS_SHOTS_FROM_BOX] * 100)
            / (away?.stats?.[MatchStatistics.STATS_TOTAL_SHOTS] * 100)}
          >
            <CircleCaption>{away?.stats?.[MatchStatistics.STATS_SHOTS_FROM_BOX]}</CircleCaption>
          </CircleGraph>
        </StatsBlock>
        <StatsBlock>
          <CircleGraph
            strokeAwayColor={theme.color.veryDarkGray}
            width={50}
            height={50}
            value={(away?.stats?.[MatchStatistics.STATS_SHOTS_OUT_BOX] * 100)
            / (away?.stats?.[MatchStatistics.STATS_TOTAL_SHOTS] * 100)}
          >
            <CircleCaption>{away?.stats?.[MatchStatistics.STATS_SHOTS_OUT_BOX]}</CircleCaption>
          </CircleGraph>
        </StatsBlock>
      </StatsBlock>
    </BlockContainerColumn>
  );
};

export default MatchShots;
