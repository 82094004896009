import React from 'react';
import { WebHallOfFameHighlights } from '@app/types/webTemplateTypes';
import WebGoogleAds from '@app/components/WebTemplates/elements/WebGoogleAdsElement';
import {
  HOFPage, HOFLogo, HOFBanner, HOFSectionHighlights, HOFInducteeHighlight,
} from './hof.atoms';

export const HallOfFameHighlights = ({
  title, logo, banner, inductees, sponsor,
}: WebHallOfFameHighlights): React.ReactElement | null => (
  <HOFPage>
    <h1 style={{ position: 'absolute', opacity: 0 }}>{title}</h1>
    <HOFLogo {...logo} />
    <HOFBanner {...banner} />
    {sponsor ? <WebGoogleAds data={sponsor} /> : null}
    <HOFSectionHighlights>
      {inductees.map((inductee) => (
        <HOFInducteeHighlight {...inductee} key={inductee.name.full} />
      ))}
    </HOFSectionHighlights>
  </HOFPage>
);
