import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLanguage } from '@app/components/Hooks';
import { WebHallOfFameInductee } from '@app/types/webTemplateTypes';
import { Devices } from '@app/styles';
import styled from 'styled-components';
import * as Translations from '@app/locales';
import { HOFInducteeLink } from './hof.inductee.link';
import { HOFInducteeThumbnail } from './hof.inductee.card';

interface Props {
  inductees: WebHallOfFameInductee[];
  position: string;
  year: number;
}

export const HOFInducteesGrid = ({
  inductees, year, position,
}: Props): React.ReactElement => {
  const players = inductees?.filter((inductee): boolean => (
    position === 'all' ? true : inductee.role === position
  ));
  const language = useLanguage();
  const title = Translations[language]?.['hof.inductee.grid.title']?.replace('{year}', year);

  return (
    <Container>
      <Heading>
        <h2>{title}</h2>
        <span>{year}</span>
      </Heading>
      <Grid>
        {players?.length ? (
          <>
            {players.map(({ name, slug, image: { card } }) => (
              <HOFInducteeLink slug={slug} key={name.full}>
                <HOFInducteeThumbnail url={card.url} alt={card.description} />
              </HOFInducteeLink>
            ))}
          </>
        ) : (
          <NotFound>
            <h6>
              <FormattedMessage id="hof.inductee.not-found" />
            </h6>
          </NotFound>
        )}
      </Grid>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

const Heading = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  max-width: 50%;
  height: 80px;

  & > h2,
  & > span {
    font-family: MilanTypeRev, Helvetica Neue, Helvetica, Arial, sans-serif!important;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #d8be6d;
    text-transform: uppercase;
    cursor: default;

    &:hover {
      cursor: default;
    }
  }

  & > span {
    position: absolute;
    top: 0;
    right: -169px;
    font-size: 100px;
    background-image: linear-gradient(to bottom, #d8be6d, transparent);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    opacity: .5;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  width: 100%;
  height: auto;


  @media ${Devices.largeMobile} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${Devices.tabletLarge} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const NotFound = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:5px;
  aspect-ratio: 30/42;

  width: 100%;
  background-color: rgba(0, 0, 0, 0.21);

  font-family: MilanTypeRev, Helvetica Neue, Helvetica, Arial, sans-serif !important;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: uppercase;
  cursor: default;
`;
