import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const Side = ({ isHome, side }): React.ReactElement | null => {
  const opta = isHome ? 'home' : 'away';
  const kentico = side?.length ? side : undefined;

  return (
    <Status isHome={side === 'home' || isHome} hide={side === null}>
      <span>
        <FormattedMessage id={`match.team.${kentico || opta}`} />
      </span>
    </Status>
  );
};

export default Side;

const Status = styled.div<{ isHome: boolean; hide: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0 0 18px;
  width: 16px;
  height: 16px;

  padding: 2px 4px 0;

  font-family: MilanTypeRev;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 12px;
  letter-spacing: 0.17px;
  text-align: center;

  opacity: ${({ hide }): string => (hide ? '0' : '1')};
  background: ${({ isHome }): string => (isHome ? '#c9240d' : 'black')};
  color: white;
`;
