import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import AppRoutes from '@app/constants/routes';
import { LocalizedLink } from '@app/components/Localization';

const MobileLogo: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <LocalizedLink
      pathKey={AppRoutes.Home.path}
      onClick={(): void => { window.scrollTo(0, 0); }}
    >
      <Logo src="/images/acm_125_years.png" alt={formatMessage({ id: 'mainLogo.alt' })} />
    </LocalizedLink>
  );
};

const Logo = styled.img`
  height: 60px;
  margin-left: 25px;
`;

export default MobileLogo;
