import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { getNewsArticleSchema } from '@app/helpers/structuredMarkupHelpers';
import { Devices } from '@app/styles';
import { dispatchNewsPremiumClickEvent } from '@app/helpers/newsHelpers';
import { getArticleByUrlSlug, getLatestArticles } from '@app/store/actions/newsActions';
import { NewsNavigationTypeMap } from '@app/constants/newsConstants';
import { GoogleAdsMap } from '@app/constants/googleAdsConstants';
import { NewsTypes } from '@app/types/newsTypes';

import {
  NewsContent, NewsContentCover, NewsContentContainer, NewsContentHeading, GoogleAds,
  SocialSharing, CarouselLatestArticles, SecondLevelMenu, Metadata, MetaJsonLD,
} from '@app/components';
import { PageValidationWrapper } from '@app/pages/components';

import { useNewsBreadcrumbsJsonLD, useIsLoggedInSelector } from '@app/components/Hooks';
import { useMenuSelector, useNewsSocialPreviewImageUrl } from '@app/pages/News/NewsHooks';
import {
  useArticle, useDownloadArticle, useResetArticle, useRouterParamsValidation,
} from '@app/pages/ArticleLanding/ArticleLandingHooks';

const ArticleLanding = (): React.ReactElement => {
  useDownloadArticle();
  useResetArticle();

  const [isPrivateMode, setPrivateMode] = useState(false);

  const { secondLevelMenuItems } = useMenuSelector(NewsNavigationTypeMap.articles);
  const { categoryName = '' } = useParams();

  const isLoggedIn = useIsLoggedInSelector();
  const article = useArticle();
  const validationError = useRouterParamsValidation();
  const breadcrumbsJsonLD = useNewsBreadcrumbsJsonLD(article?.seo?.title ?? '', NewsTypes.ARTICLES);
  const socialImageUrl = useNewsSocialPreviewImageUrl(article);

  const articleJsonLD = article ? getNewsArticleSchema({
    title: article.title,
    articleUrl: window?.REQUEST_URL || window?.location?.href,
    datePublished: article.publicationDate,
    photoUrl: article.image.url,
    photoDescription: article.image.description,
  }) : null;

  useEffect((): void => {
    if (article?.isPremium && !isLoggedIn) {
      dispatchNewsPremiumClickEvent(window.location.pathname, true);
      setPrivateMode(true);
    }
  }, []);

  return (
    <ArticlePage error={validationError} isPrivate={isPrivateMode}>
      { !!article?.seo && <Metadata seo={{ ...article.seo, socialType: 'article', socialImageUrl }} /> }
      { articleJsonLD && <MetaJsonLD json={articleJsonLD} /> }
      { !!article?.seo && <MetaJsonLD json={breadcrumbsJsonLD} /> }
      <SecondLevelMenu items={secondLevelMenuItems} />
      <NewsContentCover
        cover={article?.cover ?? null}
        image={article?.image}
      />
      <NewsContentContainer>
        <SocialSharingContainer>
          <SocialSharing url={window.location.href} onlyIcons />
        </SocialSharingContainer>
        <GoogleAds {...GoogleAdsMap.articleLanding} />
        <NewsContentHeading
          title={article?.title ?? ''}
          categoryName={categoryName}
          publicationDate={article?.publicationDate ?? ''}
          subTitle={article?.subTitle ?? ''}
          isPremium={!!article?.isPremium}
        />
        <NewsContent content={article?.content ?? []} />
        <SocialSharingContainer>
          <SocialSharing url={window.location.href} />
        </SocialSharingContainer>
      </NewsContentContainer>
      <LatestNews>
        <LatestNewsContainer>
          <CarouselLatestArticles />
        </LatestNewsContainer>
      </LatestNews>
    </ArticlePage>
  );
};

ArticleLanding.serverFetch = [getArticleByUrlSlug, getLatestArticles];

export default ArticleLanding;

const ArticlePage = styled(PageValidationWrapper)<{ isPrivate: boolean }>`
  filter: ${({ isPrivate }): string => (isPrivate ? 'blur(10px)!important' : 'none')};
  width: 100%;
  background-color: white;
  color: black;
`;

const SocialSharingContainer = styled.section`
  padding: 20px 0;
`;

const LatestNews = styled.section`
  padding: 20px 0;
  background-color: #f5f5f5;

  @media ${Devices.tablet} {
    padding: 50px 0;
  }
`;

const LatestNewsContainer = styled.div`
  max-width: 920px;
  margin: 0 auto;
`;
