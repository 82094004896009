import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

const GalleryIcon = (): React.ReactElement => {
  const { formatMessage } = useIntl();
  return (<GalleryIconImage src="/images/galleryIcon.svg" alt={formatMessage({ id: 'news.gallery.icon.alt' })} />);
};

export default GalleryIcon;

const GalleryIconImage = styled.img`
  position: absolute;
  left: 15px;
  top: 15px;
  height: 20px;
  z-index: 11;
  transform-origin: 50% 65%;
  transition: transform 2s ease-in-out;
`;
