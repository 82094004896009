import React from 'react';

import { MatchHeader } from '@app/pages/MatchCenter/components';
import { useMatchCenterByGameId, useMatchResultsSelector } from '@app/pages/MatchCenter/MatchCenterHooks';
import { useGameId } from '../HomeHooks';

const HomeMatchCenter = (): React.ReactElement | null => {
  const gameId = useGameId();
  const result = useMatchResultsSelector();

  useMatchCenterByGameId(gameId);

  return result && (<MatchHeader gameId={gameId} />);
};

export default HomeMatchCenter;
