import styled from 'styled-components';

export const Value = styled.span<{ isSmall?: boolean }>`
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: ${({ isSmall }): string => (isSmall ? '20px' : '40px')};
  font-weight: bold;
  text-align: right;
  color: #000000;
  margin: 0 10px 0 15px;
`;

export const Caption = styled.span<{ isSmall?: boolean }>`
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: ${({ isSmall }): string => (isSmall ? '12px' : '14px')};
  font-weight: normal;
  line-height: ${({ isSmall }): string => (isSmall ? '1.5' : '1.29')};
  letter-spacing: -0.01px;
  text-align: center;
  color: #000000;
`;

export const CircleCaption = styled.span`
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 25px;
  font-weight: bold;
  letter-spacing: -0.02px;
  color: #000000;
`;
