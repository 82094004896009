import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { Image } from '@app/components';
import { getFullScreenImgConfig } from '@app/helpers/imageHelper';

import { useMailToHrLinkClick } from './WebGoogleAnalyticHooks';

interface WebEditorialElementProps {
  content: string;
}

const WebEditorialElement = React.memo(({ content }: WebEditorialElementProps): React.ReactElement => {
  const sections = content
    .split(/(<\s*figure[^>]*>(.*?)<\s*\/\s*figure>)/)
    .filter((content) => !content.includes('<figure'))
    // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
    .map((content) => (content.match(/(src="(.*?)")/)?.[2] ?? content));

  useMailToHrLinkClick(content);

  return (
    <Container>
      {sections.map((content) => (content.startsWith('http')
        ? (
          <Image
            src={content}
            description=""
            additionalImageProps={getFullScreenImgConfig(content)}
          />
        )/* eslint-disable-next-line react/no-danger */
        : (<div dangerouslySetInnerHTML={{ __html: content }} />)))}
    </Container>
  );
});

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 960px;
  overflow-x: auto;

  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: 14px;
  letter-spacing: 0.23px;
  line-height: 20px;
  text-align: justify;
  color: #232323;
  padding: 10px;

  & img {
    padding: 20px 0;
  }

  & h1 {
    line-height: 30px;
  }

  & h2 {
    line-height: 28px;
  }

  & h3 {
    line-height: 26px;
  }

  & h4 {
    line-height: 24px;
  }

  & h5 {
    line-height: 22px;
  }

  & ul {
    list-style-type: disc;
    padding-left: 30px;
    padding-bottom: 10px;
  }

  & figure {
    margin: unset;
    text-align: center;
    padding: 20px 0;
  }

  & img {
    width: 100%;
    height: auto
  }

  & table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    margin: 10px 0;
  }
  & tr:first-of-type td {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.26px;
    color: ${(props): string => props.theme.color.harleyDavidsonOrange};
    border-bottom: solid 2px ${(props): string => props.theme.color.harleyDavidsonOrange};
    padding: 5px;

    &:first-of-type {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  & tr td {
    font-size: 10px;
    letter-spacing: 0.23px;
    text-align: left;
    color: #000000;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    padding: 10px 5px;
    min-width: 50px;

    &:first-of-type {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  & > div img {
    width: 100%;
  }

  & > div > p, & > div > div, & > div > img {
    margin-bottom: 10px;
  }

  & a {
    color: ${(props): string => props.theme.color.harleyDavidsonOrange};

    &:hover,
    &:visited,
    &:active {
      color: ${(props): string => props.theme.color.harleyDavidsonOrange};
    }
  }
  @media ${Devices.desktop} {
    padding: 20px 0;

    & tr:first-of-type td {
      font-size: 16px;
      padding: 10px 5px;

      &:first-of-type {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
    & tr td {
      font-size: 14px;
      padding: 20px 10px;
      min-width: 100px;

      &:first-of-type {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
`;

export default WebEditorialElement;
