import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import QRCode from 'react-qr-code';

import { Modal } from '@app/components';
import { Devices } from '@app/styles';

interface SocialWeChatProps {
  url: string;
  onClose: () => void;
  open: boolean;
}

const SocialWeChat = ({ url, open, onClose }: SocialWeChatProps): React.ReactElement => (
  <Modal open={open} onClose={onClose}>
    <SocialWeChatContainer>
      <Header>
        <FormattedMessage id="share.wechat.header" />
      </Header>
      <QRCode value={url} />
      <Text>
        <FormattedMessage id="share.wechat.text" />
      </Text>
    </SocialWeChatContainer>
  </Modal>
);

const SocialWeChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 95vw;
  margin: auto;
  padding: 40px 20px;
  border-radius: 8px;

  background-color: ${(props): string => props.theme.color.whiteSmoke};
  color: black;

  font-family: ${(props): string => props.theme.fontFamily.raleway};
  text-align: left;

  button {
    margin-bottom: 0;
  }

  @media ${Devices.tablet} {
    width: 450px;
    max-width: 600px;
    padding: 60px 20px 40px;
  }
`;

const Header = styled.h3`
  font-size: 24px;
  margin-bottom: 20px;
`;

const Text = styled.p`
  font-size: 14px;
  margin-top: 20px ;
`;

export default SocialWeChat;
