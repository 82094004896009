import React from 'react';
import { Devices } from '@app/styles';
import styled from 'styled-components';

export const HOFPage = ({ children }): React.ReactElement => (
  <Landing>
    <Content>
      {children}
    </Content>
    <Background />
  </Landing>
);

const Landing = styled.div`
  width: 100%;
  position: relative;
  padding: 40px 0;
  min-height: 69vh;

  background-color: #f6f6f6;

  z-index: 1;

  &::before,
  &::after {
    content: " ";
    position: absolute;
    left: 0;
    width: 100%;
    height: 200px;
    z-index: 2;
  }

  &::before {
    top: 0;
    background: linear-gradient(to bottom, rgb(246, 246, 246) 0%, rgba(245, 245, 245, 0) 100%);
  }

  &::after {
    bottom: 0;
    background: linear-gradient(to top, rgb(246, 246, 246) 0%, rgba(245, 245, 245, 0) 100%);
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 40px;
  z-index: 10;
  max-width: 960px;
  padding: 0 10px;

  width: 100%;
  margin: auto;
  position: relative;

  @media ${Devices.desktop} {
    padding: 0;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  height: calc(100% - 140px);

  background-image: url(/images/hall-of-fame/background.webp);
  background-size: 400%;
  background-position: top center;
  background-repeat: repeat-y;
  z-index: 1;

  @media ${Devices.tabletLarge} {
    background-size: contain;
  }
`;
