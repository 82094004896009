import React from 'react';
import styled from 'styled-components';

const CircleMinusIcon = (): React.ReactElement => (
  <CircleSvg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <circle cx="10" cy="10" r="10" fill="#FFF" />
      <rect width="8" height="2" x="6" y="9" fill="#C9240D" rx="1" />
    </g>
  </CircleSvg>
);

export const CircleSvg = styled.svg`
  cursor: pointer;
`;

export default CircleMinusIcon;
