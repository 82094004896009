import React, { useState } from 'react';
import styled from 'styled-components';
import Carousel from 'react-multi-carousel';
import { FormattedMessage } from 'react-intl';

import AppRoutes from '@app/constants/routes';
import { HomeMatch } from '@app/types/matchTypes';
import { GA_EVENT, GA_CATEGORY } from '@app/types/googleTypes';

import { useLanguage } from '@app/components/Hooks';
import { useCompetitionsSelector } from '@app/pages/Season/Season/SeasonHooks';
import { pushGAEvent } from '@app/helpers/googleHelpers';

import { HorizontalArrowLeftIcon, HorizontalArrowRightIcon } from '@app/components/Icons';
import { MatchDay } from '@app/components';
import { SeasonSection, SeasonInnerContainer, LinkToSeason } from './HomeSeasonComponents';

const responsive = {
  mobile: {
    breakpoint: { max: Infinity, min: 0 },
    items: 1,
  },
};

const MatchBox = ({ matches }: {matches: HomeMatch[] }): React.ReactElement => {
  const language = useLanguage();
  let CarouselElement;
  const [currentSlideId, setCurrentSlide] = useState(0);
  const competitions = useCompetitionsSelector();

  return (
    <Container headerTranslationId="home.nextMatches">
      <SeasonInnerContainer>
        {
          !!currentSlideId && (
            <LeftArrowContainer
              onClick={(): void => {
                CarouselElement.previous();
                setCurrentSlide(currentSlideId - 1);
              }}
            >
              <HorizontalArrowLeftIcon />
            </LeftArrowContainer>
          )
        }
        <Carousel
          responsive={responsive}
          ref={(el): void => { CarouselElement = el; }}
          arrows={false}
          draggable={false}
          swipeable={false}
          keyBoardControl={false}
          // class is specified in globalStyles
          containerClass="container-100hv"
          sliderClass="container-100hv"
          itemClass="container-100hv"
        >
          {
            matches.map((match) => (
              <Match key={match.gameId}>
                <MatchDay result={match} isHomePage />
                <LinkToSeason
                  pathKey={AppRoutes.Schedule.path}
                  pathParams={{
                    urlSlug: competitions?.[match.competitionId]?.url ?? '',
                    seasonId: match.seasonId,
                  }}
                  onClick={(): void => pushGAEvent({
                    event: GA_EVENT.CLICK_HOME,
                    category: GA_CATEGORY.MATCHES,
                    label: `see_${GA_CATEGORY.MATCHES}`,
                    language,
                  })}
                >
                  <FormattedMessage id="home.seeAllMatches" />
                </LinkToSeason>
              </Match>
            ))
          }
        </Carousel>
        {
          currentSlideId < matches.length - 1 && (
            <RightArrowContainer
              onClick={(): void => {
                CarouselElement.next();
                setCurrentSlide(currentSlideId + 1);
              }}
            >
              <HorizontalArrowRightIcon />
            </RightArrowContainer>
          )
        }
      </SeasonInnerContainer>
    </Container>
  );
};

const Container = styled(SeasonSection)`
  padding: 20px 30px 0;
`;

const Arrow = styled.div`
  --arrow-width: 30px;

  position: absolute;
  width: var(--arrow-width);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  top: -15px;
`;

const LeftArrowContainer = styled(Arrow)`
 left: calc(-1 * var(--arrow-width));
`;

const RightArrowContainer = styled(Arrow)`
 right: calc(-1 * var(--arrow-width));
`;

const Match = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export default MatchBox;
