import React from 'react';

import { VideoPlayerElement } from '../../VideoPlayersStyledComponents';

interface TencentPlayerProps {
  videoId: string;
  autoplay?: boolean;
}

const TencentPlayerEmbed = ({ videoId }: TencentPlayerProps): React.ReactElement => {
  const tencentEmbedUri = '//v.qq.com/txp/iframe/player.html?vid=';

  const playerProps = {
    src: `${tencentEmbedUri}${videoId}`,
    allowFullScreen: true,
  };

  return (
    <VideoPlayerElement {...playerProps} as="iframe" />
  );
};

export default TencentPlayerEmbed;
