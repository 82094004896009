import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import {
  useEventsSelector, useMatchPlayerByIdSelector, usePlayerByIdSelector,
  useIsMilanCheckPlayerById, useIsMatchMenTeamsCheck,
} from '../MatchCenterHooks';

const MatchEvent = ({ event }): React.ReactElement => {
  const isGoal = ['goal', 'penalty_goal', 'own_goal'].includes(event?.type);
  const isAutoGoal = ['own_goal'].includes(event?.type);
  const isMenTeams = useIsMatchMenTeamsCheck();
  const matchPlayer = useMatchPlayerByIdSelector(event?.playerId1 ?? '');
  const milanPlayer = usePlayerByIdSelector(`p${event?.playerId1}`);
  const isMilanPlayer = useIsMilanCheckPlayerById(`p${event?.playerId1}`);
  const isMilanAutoGoal = isAutoGoal && !isMilanPlayer;
  const isMilanGoal = (isGoal && isMilanPlayer && !isAutoGoal);
  const isTitleVisible = ![
    'contentious_referee_decisions',
    'deleted_after_review',
    'var_cancelled_goal',
    'end_5',
  ].includes(event?.type);
  const teamsGender = isMenTeams ? 'man' : 'woman';

  return (
    <Event isMilanGoal={isMilanGoal || isMilanAutoGoal}>
      <Time isMilanGoal={isMilanGoal} isMilanAutoGoal={isMilanAutoGoal}>
        {event?.time?.length && !['end_14', 'start'].includes(event?.type) && (<span>{event.time}</span>)}
      </Time>
      {isMilanGoal && !isAutoGoal && (
        <PlayerImage
          src={milanPlayer?.photoLarge.url || `/images/team/placeholder.player.${teamsGender}.png`}
          alt={`${matchPlayer?.firstName} ${matchPlayer?.lastName}`}
        />
      )}
      <Message>
        {isTitleVisible && (
          <Title isGoal={isGoal}>
            <FormattedMessage id={`matchcenter.events.type_${event?.type}`} />
            {isMilanGoal && (<span>&nbsp;&#33;&#33;&#33;&#33;&#33;</span>)}
          </Title>
        )}
        {isGoal && (
          <PlayerInfo>
            <Number>{matchPlayer?.number}</Number>
            <div>
              {matchPlayer?.knownName ? (
                <LastName>{matchPlayer?.knownName}</LastName>
              ) : (
                <>
                  <FirstName>{matchPlayer?.firstName}</FirstName>
                  <LastName>{matchPlayer?.lastName}</LastName>
                </>
              )}
            </div>
          </PlayerInfo>
        )}
        <Text>{event?.comment}</Text>
      </Message>
    </Event>
  );
};

const MatchEvents = (): React.ReactElement => {
  const events = useEventsSelector();
  return (
    <Container>
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore // TODO:REMOVE:ignore
        // eslint-disable-next-line react/no-array-index-key
        events.map((event, i) => <MatchEvent key={`${event?.time}${event?.type}${i}`} event={event} />)
      }
    </Container>
  );
};

export default MatchEvents;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50vh;
  padding: 10px 10px 0;
  overflow: auto;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 12px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 10px;
    border: 2px solid #ffffff;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }

  @media ${Devices.tabletLarge} {
    height: 100%;
    padding: 15px 15px 0;
    overflow: visible;
  }

  @media ${Devices.desktopSmall} {
    padding: 30px 30px 0 0;
  }
`;

const Event = styled.div<{ isMilanGoal: boolean }>`
  position: relative;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 25px 15px;
  color: ${({ isMilanGoal }): string => (isMilanGoal ? 'white' : 'black')};
  background-color: ${({ isMilanGoal, theme }): string => (isMilanGoal ? theme.color.freeSpeechRed : 'white')};

  &:last-of-type {
    margin-bottom: 0;
  }

  @media ${Devices.tablet} {
    padding: 25px;
  }
`;

const Time = styled.div<{ isMilanGoal: boolean; isMilanAutoGoal: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: ${({ isMilanGoal }): string => (isMilanGoal ? 'flex-start' : 'center')};
  flex: ${({ isMilanGoal }): string => (isMilanGoal ? '0 0 140px' : '0 0 auto')};
  margin-right: ${({ isMilanGoal }): string => (isMilanGoal ? '0' : '40px')};
  min-height: ${({ isMilanGoal }): string => (isMilanGoal ? '110px' : 'auto')};

  @media ${Devices.largeMobile} {
    flex: ${({ isMilanGoal }): string => (isMilanGoal ? '0 0 160px' : '0 0 auto')};
  }

  & span {
    display: inline-block;
    color: ${({ isMilanGoal, isMilanAutoGoal, theme }): string => (isMilanGoal || isMilanAutoGoal ? 'white' : theme.color.freeSpeechRed)};
    font-family: ${(props): string => props.theme.fontFamily.milan};
    font-size: 18px;
    font-weight: bold;
    position: relative;

    &::after {
      content: "";
      border-left: 1px solid ${({ isMilanGoal }): string => (isMilanGoal ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,.1)')};
      height: 50px;
      top: -14px;
      right: -20px;
      display: ${({ isMilanGoal }): string => (isMilanGoal ? 'none' : 'block')};
      position: absolute;
    }
  }

`;

const PlayerImage = styled.img`
  position: absolute;
  width: auto;
  height: auto;
  max-width: 150px;
  max-height: 165px;
  bottom: 0;
  left: 10px;

  @media ${Devices.largeMobile} {
    left: 40px;
  }
`;

const PlayerInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Number = styled.span`
  opacity: 0.2;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 38px;
  font-weight: bold;
  line-height: 1.5;
  padding-right: 10px;
`;

const FirstName = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: 12px;
  line-height: 1.1;
`;

const LastName = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 20px;
  line-height: 1.3;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 50px;
`;

const Title = styled.h3<{ isGoal: boolean }>`
  margin-bottom: 5px;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 17px;
  font-weight: bold;
  text-transform: ${({ isGoal }): string => (isGoal ? 'uppercase' : 'unset')};
`;

const Text = styled.p`
  margin: 0;
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: 12px;
  font-weight: 300;
  line-height: 1.3;
`;
