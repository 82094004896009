import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { GA_EVENT, GA_CATEGORY } from '@app/types/googleTypes';
import { MenuItemTypes, MenuItem as MenuItemInterface } from '@app/types/configurationTypes';
import { formatRelatedItemLinkProps, formatSubMenuLinkProps } from '@app/helpers/menuHelpers';
import { pushGAEvent } from '@app/helpers/googleHelpers';
import { LocalizedLink } from '@app/components/Localization';
import { ExpandArrow } from '@app/components/Icons';

import { useMenSquadAllCompetitionsParams } from '@app/pages/Season/Season/SeasonHooks';
import { useLanguage } from '@app/components/Hooks';

interface MenuItemProps {
  item: MenuItemInterface;
  isMenuOpened: boolean;
  closeMobileMenu: () => void;
}

const MobileMenuItem = ({ item, closeMobileMenu, isMenuOpened }: MenuItemProps): React.ReactElement => {
  const [isSubMenuOpened, setSubMenuOpeningState] = useState(false);

  const language = useLanguage();
  const { seasonId, urlSlug } = useMenSquadAllCompetitionsParams();

  const { relatedItems } = item;
  const subItems = Object.values(item.navigation).filter(({ isVisible }) => isVisible);

  useEffect(() => {
    setSubMenuOpeningState(false);
  }, [isMenuOpened]);

  const openSubMenu = (): void => {
    subItems.length && setSubMenuOpeningState(!isSubMenuOpened);
  };
  const hasSubItems = !!subItems?.length;

  return (
    <Container key={item.id} onClick={openSubMenu}>
      { item.type === MenuItemTypes.ExternalLink ? (
        <ExternalLinkMenuItemWrapper
          as="a"
          {...item.external.url}
          onClick={(): void => {
            pushGAEvent({
              event: GA_EVENT.CLICK_MENU,
              category: GA_CATEGORY.MENU,
              label: item.id,
              language,
            });
            closeMobileMenu();
          }}
        >
          {item.external.name}
        </ExternalLinkMenuItemWrapper>
      ) : (
        <MenuItemWrapper>
          { item.name }
          {hasSubItems && (
          <ExpandArrowContainer isTurned={isSubMenuOpened}>
            <ExpandArrow />
          </ExpandArrowContainer>
          )}
        </MenuItemWrapper>
      ) }
      {hasSubItems && (
        <SubMenuList isOpened={isSubMenuOpened}>
          {
          subItems.map((subItem) => (
            /* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */
            <li
              key={subItem.id}
              onClick={(): void => {
                pushGAEvent({
                  event: GA_EVENT.CLICK_MENU,
                  category: GA_CATEGORY.SUB_MENU,
                  label: subItem.id,
                  language,
                });
                closeMobileMenu();
              }}
            >
              {((): React.ReactElement => {
                switch (subItem.type) {
                  case MenuItemTypes.ExternalLink:
                    return (
                      <SubMenuItemTitle as="a" {...subItem.external.url}>
                        {subItem.external.name}
                      </SubMenuItemTitle>
                    );
                  case MenuItemTypes.RelatedMenuItem:
                    return (
                      <SubMenuItemTitle
                        {...formatRelatedItemLinkProps(subItem.related, seasonId, urlSlug)}
                      >
                        {subItem.related.name}
                      </SubMenuItemTitle>
                    );
                  default:
                    return (
                      <SubMenuItemTitle {...formatSubMenuLinkProps(item, subItem, seasonId, urlSlug)}>
                        {subItem.name}
                      </SubMenuItemTitle>
                    );
                }
              })()}
            </li>
          ))
        }
          {relatedItems && relatedItems.length > 0 && (
          <SubMenuTitle>
            <FormattedMessage id="menu.relatedCategories" />
          </SubMenuTitle>
          )}
          {relatedItems.map((subItem) => (
          /* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */
            <li
              key={subItem.item.id}
              onClick={(): void => {
                pushGAEvent({
                  event: GA_EVENT.CLICK_MENU,
                  category: GA_CATEGORY.SUB_MENU,
                  label: subItem.item.id,
                  language,
                });
                closeMobileMenu();
              }}
              data-related="true"
            >
              {subItem.type === MenuItemTypes.ExternalLink ? (
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
                <SubMenuItemTitle as="a" {...subItem.item.url} rel="nofollow">
                  {subItem.item.name}
                </SubMenuItemTitle>
              ) : (
                <SubMenuItemTitle
                  {...formatRelatedItemLinkProps(subItem.item, seasonId, urlSlug)}
                  isNotFollowed
                >
                  {subItem.item.name}
                </SubMenuItemTitle>
              )}
            </li>
          ))}

        </SubMenuList>
      )}
    </Container>
  );
};

const Container = styled.li`
  padding: 15px 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  cursor: pointer;
`;

const MenuItemWrapperStyle = (): string => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  color: white;
  text-decoration: none;
`;

const MenuItemWrapper = styled.div`
  ${MenuItemWrapperStyle}
`;

const ExternalLinkMenuItemWrapper = styled(LocalizedLink)`
  ${MenuItemWrapperStyle}
`;

const ExpandArrowContainer = styled.div<{ isTurned: boolean }>`
  transform: ${({ isTurned }): string => (isTurned ? 'rotate(180deg)' : 'rotate(0deg)')};
  -webkit-tap-highlight-color: yellow;

  & g[id='icon'] {
    stroke: white;
  }

`;

const SubMenuList = styled.ul<{ isOpened: boolean }>`
  margin-top: 15px;
  display: ${({ isOpened }): string => (isOpened ? 'block' : 'none')};
  font-size: 14px;

  & [data-related="true"] {
    font-size: 12px;
  }
`;

const SubMenuItemTitle = styled(LocalizedLink)`
  color: white;
  text-transform: capitalize;
  padding: 10px 0 10px 20px;
  display: block;

  &:hover {
    color: white;
    text-decoration: none;
  }
`;

const SubMenuTitle = styled.li`
  padding: 20px 0 10px 20px;
  height: 45px;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-weight: bold;
  color: #ffffff;
`;

export default MobileMenuItem;
