import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import styled from 'styled-components';
import { LineGraph } from '@app/components/Graphs/LineGraph';

interface ValueAndGraphProps {
  value?: number | string;
  progress?: number;
  caption: string;
  width?: number;
  strokeHomeColor?: string;
  strokeAwayColor?: string;
}

const ValueAndGraph = ({
  value, progress = 0, caption, width = 65,
  strokeHomeColor, strokeAwayColor,
}: ValueAndGraphProps): React.ReactElement => (
  <Container>
    <Value>{value}</Value>
    <LineGraph value={progress} width={width} strokeHomeColor={strokeHomeColor} strokeAwayColor={strokeAwayColor} />
    <Caption><FormattedHTMLMessage id={caption} /></Caption>
  </Container>
);

const Container = styled.div`
  font-family: ${(props): string => props.theme.fontFamily.milan};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Value = styled.span`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const Caption = styled.span`
  font-family: ${(props): string => props.theme.fontFamily.raleway};;
  font-size: 13px;
  font-weight: normal;
  margin-top: 7px;
`;

export default ValueAndGraph;
