import React from 'react';
import styled from 'styled-components';
import { FormattedHTMLMessage } from 'react-intl';

interface ValueAndCaptionProps {
  value?: number | string;
  additionalValue?: number | string;
  caption: string;
  isSmall?: boolean;
}

const ValueAndCaption = ({
  value, additionalValue, caption, isSmall = false,
}: ValueAndCaptionProps): React.ReactElement => (
  <div>
    <Line>
      <LargeText isSmall={isSmall}>{value}</LargeText>
      {additionalValue && (
      <span>
        /
        {additionalValue}
      </span>
      )}
    </Line>
    <Line>
      <Caption isSmall={isSmall}><FormattedHTMLMessage id={caption} /></Caption>
    </Line>
  </div>

);

const Line = styled.p`
  margin: 0;
  text-align: center;
`;

const LargeText = styled.span<{ isSmall: boolean }>`
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: ${({ isSmall }): string => (isSmall ? '26px' : '44px')};
  font-weight: bold;
  letter-spacing: -0.03px;
  color: #000000;
`;

const Caption = styled.span<{ isSmall: boolean }>`
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: ${({ isSmall }): string => (isSmall ? '12px' : '14px')};
  font-weight: normal;
  line-height: ${({ isSmall }): string => (isSmall ? '1.5' : '1.29')};
  letter-spacing: -0.01px;
  text-align: center;
  color: #000000;
`;

export default ValueAndCaption;
