import React from 'react';
import styled from 'styled-components';

import { ArrowRightIcon, ArrowLeftIcon } from '@app/components/Icons';

interface ArrowButtonsProps {
  onClick: () => void;
}

export const ArrowButtonRight = ({ onClick }: ArrowButtonsProps): React.ReactElement => (
  <ArrowButtons onClick={onClick}>
    <ArrowRightIcon />
  </ArrowButtons>
);

export const ArrowButtonLeft = ({ onClick }: ArrowButtonsProps): React.ReactElement => (
  <ArrowButtons onClick={onClick}>
    <ArrowLeftIcon />
  </ArrowButtons>
);

const ArrowButtons = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: ${(props): string => props.theme.color.freeSpeechRed};
  cursor: pointer;
  padding: 0;
  margin: 0;
  user-select: none;
  outline: none;
  border: none;
  transition: all 0.3s ease;
  
  & g[id='icon'] {
    fill: white;
    transition: all 0.3s ease;
  }
  
  @media (pointer: fine) {
    &:hover {
      background-color: white;
      transition: all 0.3s ease;
      
      & g[id='icon'] {
        fill: ${(props): string => props.theme.color.freeSpeechRed};
        transition: all 0.3s ease;
      }   
    }
  }
`;
