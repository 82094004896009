import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { CommonButton } from './CommonButton';

interface LoadMoreButtonProps {
  onClick: () => void;
  isLoading: boolean;
}

const LoadMoreButton = ({ onClick, isLoading }: LoadMoreButtonProps): React.ReactElement => (
  <StyledLoadButton onClick={onClick} isLoading={isLoading} disabled={isLoading}>
    <FormattedMessage id={isLoading ? 'buttons.loading.inProgress' : 'buttons.loading'} />
  </StyledLoadButton>
);

const StyledLoadButton = styled(CommonButton)<{ isLoading: boolean}>`
  margin: 40px auto;
  text-transform: uppercase;
  
  &:disabled {
    background-color: ${(props): string => props.theme.color.dimGray};
    cursor: auto;
    
    &:hover {
      background-color: ${(props): string => props.theme.color.dimGray};
      color: white;
      border-color: transparent;
    }
  }
`;

export default LoadMoreButton;
