import { mapImageData, mapSeoData } from '@app/helpers/configurationHelpers';

import { convertWebHallOfFameInductee } from '@app/services/kentico/converters/webTemplateConverter';
import { KenticoLangToLangCodeMap } from '@app/constants/localizationConstants';
import { WebTemplate } from '@app/services/kentico/types/webTemplateTypes';
import { HallOfFamePlayer } from '@app/types/hallOfFameTypes';
import { KenticoHallOfFameList } from '../types/hallOfFameTypes';


export const convertHallOfFame = (data: WebTemplate): HallOfFamePlayer[] => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const players: KenticoHallOfFameList = (data.items ?? []);

  return players.map(({ elements, system }) => (
    {
      id: elements.id?.value ?? '',
      firstName: elements.first_name?.value ?? '',
      lastName: elements.last_name?.value ?? '',
      hallOfFame: elements.teams?.value?.map(({ codename }) => codename) ?? [],
      number: (elements.number?.value ?? '').toString(),
      birthDate: elements.birth_date?.value ?? '',
      birthPlace: elements.birth_place?.value ?? '',
      totalMatches: elements.total_matches?.value ?? '',
      totalGoals: elements.total_goals?.value ?? '',
      serieAMatches: elements.seriea_matches?.value ?? '',
      serieAGoals: elements.seriea_goals?.value ?? '',
      biography: elements.biography?.value ?? '',
      smallPhoto: mapImageData(elements.photo_list),
      largePhoto: mapImageData(elements.photo_large),
      backgroundPhoto: mapImageData(elements.photo_background),
      urlSlug: elements.url?.value ?? '',
      ...mapSeoData(elements, KenticoLangToLangCodeMap[system?.language], true),
      inductee: elements?.inductee?.value?.[0]
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        ? convertWebHallOfFameInductee(data, data?.['modular_content']?.[elements?.inductee.value[0]])?.slug
        : undefined,
    }
  ));
};
