import React, { useState } from 'react';
import styled from 'styled-components';

import { CirclePlusIcon, CircleMinusIcon } from '@app/components/Icons';

interface SwitchCircleButtonProps {
  onClick: () => void;
}

export const SwitchCircleButton = ({ onClick }: SwitchCircleButtonProps): React.ReactElement => {
  const [isPressed, setIsPressed] = useState(false);
  const toggle = (): void => {
    setIsPressed(!isPressed);
    onClick();
  };

  return (
    <Button onClick={toggle}>
      {isPressed ? <CircleMinusIcon /> : <CirclePlusIcon />}
    </Button>
  );
};

const Button = styled.button`
  width: 20px;
  height: 20px;
  transition: all 0.3s ease;
  padding: 0;
  margin: 0;
  user-select: none;
  outline: none;
  border: none;
  background-color: transparent;
`;
