import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import AppRoutes from '@app/constants/routes';
import { Devices } from '@app/styles';
import { BaseNewsItem } from '@app/types/newsTypes';

import { getResponsiveImageConfig } from '@app/helpers/imageHelper';
import { dispatchNewsPremiumClickEvent } from '@app/helpers/newsHelpers';
import { useCategoryUrl, useIsLoggedInSelector } from '@app/components/Hooks';

import { PremiumBadgeWrapper } from '@app/components/News/NewsItemSections';
import { LocalizedLink } from '@app/components/Localization';
import { PremiumBadge } from '@app/components';
import Image from '@app/components/Image';

interface NewsContentLinkProps {
  news: BaseNewsItem;
}

const NewsContentLink = ({ news }: NewsContentLinkProps): React.ReactElement => {
  const isLoggedIn = useIsLoggedInSelector();
  const categoryName = useCategoryUrl(news);
  const imageUrl = news?.image?.url;

  const onNewsLinkClick = (event): void => {
    if (news?.isPremium && !isLoggedIn) {
      event.preventDefault();
      dispatchNewsPremiumClickEvent(window.location.pathname);
    }
  };

  return (
    <NewsContentLinkSection>
      <NewsContentLocalizedLink
        pathKey={AppRoutes.ArticleLanding.path}
        pathParams={{
          categoryName,
          publicationDate: news?.publicationDate ?? '',
          urlSlug: news.urlSlug,
        }}
        onClick={onNewsLinkClick}
      >
        <NewsContentLinkImageContainer>
          {!!imageUrl && (
            <Image
              src={imageUrl}
              description={news.image.description}
              additionalImageProps={getResponsiveImageConfig(imageUrl, { 602: 450, 376: 290, 0: 180 })}
            />
          )}
          {!!news?.isPremium && (
            <PremiumBadgeWrapper>
              <PremiumBadge />
            </PremiumBadgeWrapper>
          )}
        </NewsContentLinkImageContainer>
        <NewsContentLinkInfoContainer>
          <NewsContentLinkInfoHeading>
            <FormattedMessage id="news.additionalNews" />
          </NewsContentLinkInfoHeading>
          <NewsContentLinkInfoTitle>
            {news?.title ?? ''}
          </NewsContentLinkInfoTitle>
        </NewsContentLinkInfoContainer>
      </NewsContentLocalizedLink>
    </NewsContentLinkSection>
  );
};

export default NewsContentLink;

const NewsContentLinkImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

const NewsContentLinkInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px 0;

  @media ${Devices.mediumMobile} {
    padding: 10px 0 10px 10px;
  }
`;

const NewsContentLinkInfoHeading = styled.h4`
  text-transform: uppercase;
  padding-bottom: 20px;
  color: ${(props): string => props.theme.color.cheninYellow};
`;

const NewsContentLinkInfoTitle = styled.h3`
  text-transform: uppercase;
  overflow: hidden;
`;

const NewsContentLinkSection = styled.section`
  border-top: 1px solid rgba(128,128,128,0.5);
  border-bottom: 1px solid rgba(128,128,128,0.5);
  padding: 10px 0;

  & img {
    transition: transform 2000ms ease-out;
  }

  &:hover img {
    transform: scale(1.069);
    transition: transform 2000ms ease-out;
  }
`;

const NewsContentLocalizedLink = styled(LocalizedLink)`
  display: flex;
  flex-direction: column;
  color: inherit; // Use defined on page text color

  @media ${Devices.mediumMobile} {
    flex-direction: row;
  }

  & > * {
    flex: 0 0 50%;
  }
`;
