import React, { useState } from 'react';
import styled from 'styled-components';

import { GoogleAdsProps } from '@app/types/googleTypes';
import { GoogleAds } from '@app/components';

interface NewsListAdsProps {
  ads?: GoogleAdsProps;
}

const NewsListAds = React.memo(({ ads }: NewsListAdsProps): React.ReactElement | null => {
  const [isVisible, setIsVisible] = useState<boolean>(Boolean(ads));
  const [isPreview, setIsPreview] = useState<boolean>(Boolean(ads));

  const onAdSlotRender = (isEmpty): void => {
    if (isEmpty) setIsVisible(false);
    if (!isEmpty) setIsPreview(false);
  };

  return isVisible ? (
    <NewsListAdsContainer isPreview={isPreview}>
      {ads && (<GoogleAds {...ads} onSlotRender={onAdSlotRender} />)}
    </NewsListAdsContainer>
  ) : null;
});

export default NewsListAds;

const NewsListAdsContainer = styled.div<{ isPreview: boolean }>`
  position: ${({ isPreview }): string => (isPreview ? 'absolute' : 'relative')};
  opacity: ${({ isPreview }): string => (isPreview ? '0' : '1')};

  display: flex;
  justify-content: center;
  align-items: center;

  // width: 100%;
  height: 100%;

  -webkit-animation: ${({ isPreview }): string => (isPreview ? 'unset' : 'fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both')};
  animation: ${({ isPreview }): string => (isPreview ? 'unset' : 'fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both')};
`;
