import styled from 'styled-components';

import { Devices } from '@app/styles';

const PageTitle = styled.h1<{ color?: string }>`
  font-size: 24px;
  letter-spacing: 0.14px;
  text-transform: uppercase;
  padding-top: 40px;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  color: ${({ color }): string => (color ?? 'black')};

  -webkit-animation: slide-in-fwd-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-fwd-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

  cursor: default;

  @media ${Devices.tablet} {
    font-size: 28px;
    letter-spacing: 0.16px;
  }
`;

export default PageTitle;
