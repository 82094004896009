import React from 'react';
import styled from 'styled-components';

import { GA_EVENT, GA_CATEGORY } from '@app/types/googleTypes';
import { BaseNewsItem, NewsTypes } from '@app/types/newsTypes';

import { pushGAEvent } from '@app/helpers/googleHelpers';
import { useLanguage, useLatestVideosHook, useLatestNewsLinkParams } from '@app/components/Hooks';
import { OneFourColumnGrid } from '@app/components';
import { VideoItem } from '@app/components/News/NewsItems';

import HomeSection from './HomeSection';
import { BlackSection } from './HomeStyledComponents';

const StyledVideoItem = styled(VideoItem)`
  background-color: ${({ theme }): string => theme.color.darkGray};
`;

const HomeVideos: React.FC = () => {
  const language = useLanguage();

  const latestVideos = useLatestVideosHook();
  const latestVideosLinkParams = useLatestNewsLinkParams(NewsTypes.VIDEOS);

  return !latestVideos.length ? null : (
    <BlackSection>
      <HomeSection
        sectionNameId="news.videos"
        linkData={{
          linkParams: latestVideosLinkParams,
          linkNameId: 'home.watchMore',
          onClick: (): void => pushGAEvent({
            event: GA_EVENT.CLICK_HOME,
            category: GA_CATEGORY.VIDEOS,
            label: 'watch_more',
            language,
          }),
        }}
        isDarkTheme
      >
        <OneFourColumnGrid
          ItemComponent={StyledVideoItem}
          items={latestVideos}
          itemAdditionalProps={{
            onClick: ({ title }: BaseNewsItem): void => pushGAEvent({
              event: GA_EVENT.CLICK_HOME,
              category: GA_CATEGORY.VIDEOS,
              label: title,
              language,
            }),
          }}
        />
      </HomeSection>
    </BlackSection>
  );
};

export default HomeVideos;
