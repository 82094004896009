import { Image, Seo } from '@app/types/configurationTypes';
import { LanguageType } from '@app/types/localizationTypes';

export enum MenuItemPlayerTypes {
  ALL = 'club_storia_halloffame_all',
  CHAMPIONS_LEAGUE = 'club_storia_halloffame_championsleague',
  GOALSCORER = 'club_storia_halloffame_goleador',
  BALLON_D_OR = 'club_storia_halloffame_pallonedoro',
  CAPTAIN = 'club_storia_halloffame_capitani'
}

export enum HallOfFamePlayerTypes {
  CHAMPIONS_LEAGUE = 'champions_league',
  GOALSCORER = 'goleador',
  BALLON_D_OR = 'ballon_d_or',
  CAPTAIN = 'captains'
}

export interface HallOfFamePlayer extends Seo {
  id: string;
  firstName: string;
  lastName: string;
  hallOfFame: HallOfFamePlayerTypes[];
  number: string;
  birthDate: string;
  birthPlace: string;
  totalMatches: string;
  totalGoals: string;
  serieAMatches: string;
  serieAGoals: string;
  biography: string;
  smallPhoto: Image;
  largePhoto: Image;
  backgroundPhoto: Image;
  urlSlug: string;
  inductee?: string;
}

export type LocalizedHallOfFame = Partial<Record<LanguageType, HallOfFamePlayer[]>>
