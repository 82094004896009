import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { WebGoogleMaps } from '@app/types/webTemplateTypes';
import { ContainerLarge } from '@app/components/WebTemplates/components';
import { GoogleMaps } from '@app/components';

const WebGoogleMapsElement = ({ data }: { data: WebGoogleMaps }): React.ReactElement => (
  <Container>
    <GoogleMaps map={data.map} />
  </Container>
);

export default WebGoogleMapsElement;

const Container = styled(ContainerLarge)`
  padding: 10px;

  @media ${Devices.desktop} {
    padding: 0;
  }
`;
