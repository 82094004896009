import React from 'react';
import { FormattedMessage } from 'react-intl';

import { DailyMotionPlayerProps } from '@app/types/dailyMotionPlayerTypes';

import TencentPlayerEmbed from './components/TencentPlayerEmbed';
import { VideoPlayerContainer, VideoPlayerLoader } from '../VideoPlayersStyledComponents';

const TencentPlayer = (props: DailyMotionPlayerProps): React.ReactElement => (
  <VideoPlayerContainer>
    <TencentPlayerEmbed videoId={props.videoId} autoplay={props.autoplay} />
    <VideoPlayerLoader>
      <FormattedMessage id="video.loading" />
    </VideoPlayerLoader>
  </VideoPlayerContainer>
);

export default TencentPlayer;
