import React from 'react';

const LogoutIcon = (): React.ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" id="logout_icon" viewBox="0 0 50 30">
    <g fill="none" fillRule="evenodd">
      <rect width="48" height="28" x="1" y="1" strokeWidth="2" rx="2" />
      <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
        <path d="M3.069 1.275C1.424 2.366.334 4.115.078 6.073-.178 8.029.427 10 1.734 11.477c1.309 1.478 3.193 2.315 5.166 2.298 1.973.017 3.857-.82 5.166-2.298C13.374 10 13.978 8.03 13.722 6.073c-.256-1.957-1.346-3.707-2.99-4.798M6.9.025L6.9 6.9" transform="translate(18 8)" />
      </g>
    </g>
  </svg>
);

export default LogoutIcon;
