import React from 'react';

import { GoogleAdsOverlayProps } from '@app/types/googleTypes';
import { Modal } from '@app/components';
import { Section, Text, NoText } from './GoogleAdsSections';

const GoogleAdsFullscreen = React.memo(({
  slotId, text, color, background, isOpened, isPreview, onClose,
}: GoogleAdsOverlayProps): React.ReactElement | null => (
  <Modal
    open={isOpened}
    preview={isPreview}
    background={background}
    onClose={onClose}
    closeLabelId="button.close"
  >
    {text ? (<Text dangerouslySetInnerHTML={{ __html: text }} color={color} />) : (<NoText />)}
    <Section id={slotId} />
  </Modal>
));

export default GoogleAdsFullscreen;
