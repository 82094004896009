import React from 'react';
import styled from 'styled-components';
import Carousel from 'react-multi-carousel';
import useMedia from 'react-use/lib/useMedia';

import { Devices } from '@app/styles';
import { CustomDot } from '@app/components/Carousel/CarouselTopNews/CarouselTopNews';
import { WebCard } from '@app/types/webTemplateTypes';
import { WebCardElement } from '@app/components/WebTemplates/elements';

interface CarouselProps {
  isOverlapped?: boolean;
  slides: WebCard[];
}

export const CarouselHomeSlider = ({ isOverlapped, slides }: CarouselProps): React.ReactElement => {
  const isWideScreen = useMedia(Devices.desktopSmall);

  return (
    <Section>
      {!!slides.length && (
        <Carousel
          ssr
          infinite
          swipeable
          autoPlay={false}
          draggable={false}
          keyBoardControl={false}
          responsive={{
            mobile: {
              breakpoint: { max: Infinity, min: 0 },
              items: 1,
            },
          }}
          arrows={isWideScreen}
          showDots
          dotListClass={isOverlapped ? 'overlapped-carousel-dots' : 'carousel-dots'}
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          customDot={<CustomDot />} // arguments are passed by react-multi-carousel lib
          // class is specified in globalStyles
          containerClass="container-100hv"
          sliderClass="container-100hv"
          itemClass="container-100hv"
        >
          {slides.map((slide) => (
            <WebCardElement data={slide} key={slide.id} />
          ))}
        </Carousel>
      )}
    </Section>
  );
};

const Section = styled.section`
  margin-top: 20px;
  & > div > button {
    min-width: 35px;
    min-height: 35px;
    z-index: 199;
  }
`;
