import * as React from 'react';
import styled from 'styled-components';

import AppRoutes from '@app/constants/routes';
import { Player } from '@app/types/teamsTypes';
import { GA_CATEGORY, GA_EVENT } from '@app/types/googleTypes';

import { getStaticImageConfig } from '@app/helpers/imageHelper';
import { pushGAEvent } from '@app/helpers/googleHelpers';

import { usePhotoLarge } from '@app/pages/Teams/TeamsHooks';
import { useTabs } from '@app/pages/PlayerProfile/PlayersHooks';
import { useLanguage } from '@app/components/Hooks';

import LocalizedLink from '@app/components/Localization/LocalizedLink';

interface PlayerCardProps {
  item: Player;
  teamType: string;
}

export const PlayerCard = ({ item, teamType }: PlayerCardProps): React.ReactElement => {
  const photoLarge = usePhotoLarge(item);
  const tab = useTabs()[0].caption.toLocaleLowerCase();
  const language = useLanguage();

  return (
    <Container>
      <ContainerLink
        pathKey={AppRoutes.PlayerProfile.path}
        pathParams={{ teamType, urlSlug: item.urlSlug, tab }}
        onClick={(): void => pushGAEvent({
          event: GA_EVENT.CLICK_PLAYER,
          category: GA_CATEGORY.PLAYER_CARD,
          action: teamType,
          label: item.urlSlug,
          language,
        })}
      >
        <Text>
          <Number>{item.number}</Number>
          <img src="/images/team/rectangle-gold.svg" alt={item.photoLarge.description} />
          <FirstName>{item.firstName}</FirstName>
          <LastName>{item.lastName}</LastName>
        </Text>
        <Photo>
          <img
            {...getStaticImageConfig({ url: photoLarge, width: 220 })}
            alt={item.photoLarge.description}
          />
        </Photo>
        <Gradient />
      </ContainerLink>
    </Container>
  );
};

const Container = styled.li`
  background-color: #1d2022;
  background-image: url('/images/team/player-background.png');
  background-size: cover;
  width: 220px;
  height: 300px;
  position: relative;
  margin: 0 20px 20px 0;
  color: #ffffff;
  letter-spacing: 0.21px;
`;

const ContainerLink = styled(LocalizedLink)`
  color: white;

  &:hover,
  &:active,
  &:visited {
    color: white;
  }
`;

const Photo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;

  & > img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const Gradient = styled.div`
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 44%, #000000 84%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

const Text = styled.h3`
  position: absolute;
  top: 192px;
  left: 20px;
  z-index: 15;
  color: #ffffff;
`;

const NoMarginH3 = styled.h3`
  margin: 0;
`;

const Number = styled.p`
  margin: 0;
  font-size: 32px;
  font-weight: bold;
`;

const FirstName = styled(NoMarginH3)`
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: 18px;
  font-weight: 300;
`;

const LastName = styled(NoMarginH3)`
  font-size: 22px;
`;
