import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { dispatchNewsPremiumClickEvent } from '@app/helpers/newsHelpers';
import { useIsLoggedInSelector } from '@app/components/Hooks';

import Image from '@app/components/Image';
import { PremiumBadge } from '@app/components';
import { PremiumBadgeWrapper } from '@app/components/News/NewsItemSections';

type NewsListItemImageProps = React.FC<{
  url?: string;
  isPremium: boolean;
}>

const DailyMotionPlayerPreview: NewsListItemImageProps = ({ url = '', isPremium }) => {
  const isLoggedIn = useIsLoggedInSelector();
  const { formatMessage } = useIntl();

  const onDailyMotionPlayerPreviewClick = (): void => {
    if (isPremium && !isLoggedIn) {
      dispatchNewsPremiumClickEvent(window.location.pathname);
    }
  };

  return (
    <Container onClick={onDailyMotionPlayerPreviewClick}>
      <Image src={url} description="DailyMotion" />
      <NewsVideoIcon
        src="/images/playVideo.svg"
        alt={formatMessage({ id: 'news.playVideo.icon.alt' })}
      />
      {isPremium && (
        <PremiumBadgeWrapper left>
          <PremiumBadge />
        </PremiumBadgeWrapper>
      )}
    </Container>
  );
};

export default DailyMotionPlayerPreview;

const Container = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;

  min-height: 200px;

  & img {
    transition: all 2000ms linear;
  }

  &:hover img {
    transform: scale(1.069);
    transition: all 2000ms linear;
  }
`;

export const NewsVideoIcon = styled.img`
  position: absolute;
  left: calc(50% - 26px);
  bottom: calc(50% - 20px);
  height: 40px;

  z-index: 11;

  transform-origin: 50% 65%;
  transition: transform 2s ease-in-out;

  @media ${Devices.tablet} {
    left: calc(50% - 55px);
    bottom: calc(50% - 40px);
    height: 80px;
  }
`;
