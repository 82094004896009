import React from 'react';
import { FormattedMessage } from 'react-intl';

import theme from '@app/styles/theme';
import { MatchStatistics } from '@app/types/matchTypes';
import { CircleGraph } from '@app/components/Graphs/CircleGraph';
import { LineGraph } from '@app/components/Graphs/LineGraph';
import {
  BlockContainerColumn, Caption, StatsBlock, StatsBlockColumn, Value,
} from '@app/components/Statistics';
import { useLineupsSelector } from '@app/pages/MatchCenter/MatchCenterHooks';

const MatchBall = (): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore // TODO:REMOVE:ignore
  const { home, away } = useLineupsSelector() ?? {};

  return (
    <BlockContainerColumn>
      <StatsBlock marginTop="20px" marginBottom="40px">
        <StatsBlock>
          <Value>{`${Math.floor(home?.stats?.[MatchStatistics.STATS_POSSESSION_PERCENTAGE])}%`}</Value>
        </StatsBlock>
        <StatsBlock>
          <CircleGraph
            strokeHomeColor={theme.color.harleyDavidsonOrange}
            strokeAwayColor={theme.color.veryDarkGray}
            width={150}
            height={150}
            value={
              (away?.stats?.[MatchStatistics.STATS_POSSESSION_PERCENTAGE] !== 0
              && home?.stats?.[MatchStatistics.STATS_POSSESSION_PERCENTAGE] !== 0)
                ? Math.floor(away?.stats?.[MatchStatistics.STATS_POSSESSION_PERCENTAGE]) / 100 : 0
            }
          >
            <Caption><FormattedMessage id="statistics.possession" /></Caption>
          </CircleGraph>
        </StatsBlock>
        <StatsBlock>
          <Value>
            {`${
              (away?.stats?.[MatchStatistics.STATS_POSSESSION_PERCENTAGE] !== 0
                && home?.stats?.[MatchStatistics.STATS_POSSESSION_PERCENTAGE] !== 0)
                ? (100 - Math.floor(home?.stats?.[MatchStatistics.STATS_POSSESSION_PERCENTAGE])) : 0
            }%`}
          </Value>
        </StatsBlock>
      </StatsBlock>
      <StatsBlockColumn marginBottom="20px">
        <StatsBlock>
          <Caption><FormattedMessage id="statistics.duelsWon" /></Caption>
        </StatsBlock>
        <StatsBlock>
          <StatsBlock>
            <Value isSmall>
              {`${
                (away?.stats?.[MatchStatistics.STATS_DUELS_WON] !== 0
                  || home?.stats?.[MatchStatistics.STATS_DUELS_WON] !== 0)
                  ? (100 - Math.ceil((away?.stats?.[MatchStatistics.STATS_DUELS_WON] * 100)
                    / (home?.stats?.[MatchStatistics.STATS_DUELS_WON]
                    + away?.stats?.[MatchStatistics.STATS_DUELS_WON]))) : 0
              }%`}
            </Value>
          </StatsBlock>
          <StatsBlock>
            <LineGraph
              strokeAwayColor={theme.color.veryDarkGray}
              value={
                (away?.stats?.[MatchStatistics.STATS_DUELS_WON] !== 0
                  || home?.stats?.[MatchStatistics.STATS_DUELS_WON] !== 0)
                  ? (100 - Math.ceil((away?.stats?.[MatchStatistics.STATS_DUELS_WON] * 100)
                  / (home?.stats?.[MatchStatistics.STATS_DUELS_WON]
                    + away?.stats?.[MatchStatistics.STATS_DUELS_WON]))) : 0
              }
              width={150}
            />
          </StatsBlock>
          <StatsBlock>
            <Value isSmall>
              {`${
                (away?.stats?.[MatchStatistics.STATS_DUELS_WON] !== 0
                  || home?.stats?.[MatchStatistics.STATS_DUELS_WON] !== 0)
                  ? (Math.ceil((away?.stats?.[MatchStatistics.STATS_DUELS_WON] * 100)
                  / (home?.stats?.[MatchStatistics.STATS_DUELS_WON]
                    + away?.stats?.[MatchStatistics.STATS_DUELS_WON]))) : 0
              }%`}
            </Value>
          </StatsBlock>
        </StatsBlock>
      </StatsBlockColumn>
      <StatsBlockColumn marginBottom="20px">
        <StatsBlock>
          <Caption><FormattedMessage id="statistics.interceptions" /></Caption>
        </StatsBlock>
        <StatsBlock>
          <StatsBlock>
            <Value isSmall>{`${home?.stats?.[MatchStatistics.STATS_INTERCEPTION]}`}</Value>
          </StatsBlock>
          <StatsBlock>
            <LineGraph
              strokeAwayColor={theme.color.veryDarkGray}
              value={
                (home?.stats?.[MatchStatistics.STATS_INTERCEPTION] * 100)
                / (home?.stats?.[MatchStatistics.STATS_INTERCEPTION]
                  + away?.stats?.[MatchStatistics.STATS_INTERCEPTION])
              }
              width={150}
            />
          </StatsBlock>
          <StatsBlock>
            <Value isSmall>{`${away?.stats?.[MatchStatistics.STATS_INTERCEPTION]}`}</Value>
          </StatsBlock>
        </StatsBlock>
      </StatsBlockColumn>
      <StatsBlockColumn marginBottom="20px">
        <StatsBlock>
          <Caption><FormattedMessage id="statistics.offsides" /></Caption>
        </StatsBlock>
        <StatsBlock>
          <StatsBlock>
            <Value isSmall>{`${home?.stats?.[MatchStatistics.STATS_OFFSIDES]}`}</Value>
          </StatsBlock>
          <StatsBlock>
            <LineGraph
              strokeAwayColor={theme.color.veryDarkGray}
              value={
                (home?.stats?.[MatchStatistics.STATS_OFFSIDES] * 100)
                / (home?.stats?.[MatchStatistics.STATS_OFFSIDES] + away?.stats?.[MatchStatistics.STATS_OFFSIDES])
              }
              width={150}
            />
          </StatsBlock>
          <StatsBlock>
            <Value isSmall>{`${away?.stats?.[MatchStatistics.STATS_OFFSIDES]}`}</Value>
          </StatsBlock>
        </StatsBlock>
      </StatsBlockColumn>
      <StatsBlockColumn marginBottom="20px">
        <StatsBlock>
          <Caption><FormattedMessage id="statistics.cornerKicks" /></Caption>
        </StatsBlock>
        <StatsBlock>
          <StatsBlock>
            <Value isSmall>{`${home?.stats?.[MatchStatistics.STATS_CORNERS_TAKEN]}`}</Value>
          </StatsBlock>
          <StatsBlock>
            <LineGraph
              strokeAwayColor={theme.color.veryDarkGray}
              value={
                (home?.stats?.[MatchStatistics.STATS_CORNERS_TAKEN] * 100)
                / (home?.stats?.[MatchStatistics.STATS_CORNERS_TAKEN]
                  + away?.stats?.[MatchStatistics.STATS_CORNERS_TAKEN])
              }
              width={150}
            />
          </StatsBlock>
          <StatsBlock>
            <Value isSmall>{`${away?.stats?.[MatchStatistics.STATS_CORNERS_TAKEN]}`}</Value>
          </StatsBlock>
        </StatsBlock>
      </StatsBlockColumn>
    </BlockContainerColumn>
  );
};

export default MatchBall;
