import React from 'react';
import { FormattedMessage } from 'react-intl';

import theme from '@app/styles/theme';
import { MatchStatistics } from '@app/types/matchTypes';
import { useLineupsSelector } from '@app/pages/MatchCenter/MatchCenterHooks';
import { LineGraph } from '@app/components/Graphs/LineGraph';
import {
  BlockContainerColumn, Caption, StatsBlock, StatsBlockColumn, Value, ValueAndGraph,
} from '@app/components/Statistics';

const MatchTackles = (): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore // TODO:REMOVE:ignore
  const { home, away } = useLineupsSelector() ?? {};

  return (
    <BlockContainerColumn>
      <StatsBlock marginTop="20px" marginBottom="40px">
        <StatsBlock>
          <Value>{`${home?.stats?.[MatchStatistics.STATS_TOTAL_TACKLE]}`}</Value>
        </StatsBlock>
        <StatsBlock>
          <Caption><FormattedMessage id="statistics.tackles" /></Caption>
        </StatsBlock>
        <StatsBlock>
          <Value>{`${away?.stats?.[MatchStatistics.STATS_TOTAL_TACKLE]}`}</Value>
        </StatsBlock>
      </StatsBlock>
      <StatsBlock marginBottom="40px">
        <StatsBlock>
          <ValueAndGraph
            caption="statistics.successful"
            progress={
              home?.stats?.[MatchStatistics.STATS_TOTAL_TACKLE] === 0 ? 0 : (
                Math.round((home?.stats?.[MatchStatistics.STATS_WON_TACKLE] * 100)
                  / home?.stats?.[MatchStatistics.STATS_TOTAL_TACKLE])
              )
            }
            value={`${
              home?.stats?.[MatchStatistics.STATS_TOTAL_TACKLE] === 0 ? 0 : (
                Math.round((home?.stats?.[MatchStatistics.STATS_WON_TACKLE] * 100)
                  / home?.stats?.[MatchStatistics.STATS_TOTAL_TACKLE])
              )
            }%`}
          />
        </StatsBlock>
        <StatsBlock>
          <ValueAndGraph
            strokeHomeColor={theme.color.veryDarkGray}
            caption="statistics.successful"
            progress={
              away?.stats?.[MatchStatistics.STATS_TOTAL_TACKLE] === 0 ? 0 : (
                Math.round((away?.stats?.[MatchStatistics.STATS_WON_TACKLE] * 100)
                  / away?.stats?.[MatchStatistics.STATS_TOTAL_TACKLE])
              )
            }
            value={`${
              away?.stats?.[MatchStatistics.STATS_TOTAL_TACKLE] === 0 ? 0 : (
                Math.round((away?.stats?.[MatchStatistics.STATS_WON_TACKLE] * 100)
                  / away?.stats?.[MatchStatistics.STATS_TOTAL_TACKLE])
              )
            }%`}
          />
        </StatsBlock>
      </StatsBlock>
      <StatsBlockColumn marginBottom="20px">
        <StatsBlock>
          <Caption><FormattedMessage id="statistics.defensiveClearances" /></Caption>
        </StatsBlock>
        <StatsBlock>
          <StatsBlock>
            <Value isSmall>{`${home?.stats?.[MatchStatistics.STATS_CLEARANCE]}`}</Value>
          </StatsBlock>
          <StatsBlock>
            <LineGraph
              strokeAwayColor={theme.color.veryDarkGray}
              value={
                (home?.stats?.[MatchStatistics.STATS_CLEARANCE] * 100)
                / (home?.stats?.[MatchStatistics.STATS_CLEARANCE]
                  + away?.stats?.[MatchStatistics.STATS_CLEARANCE])
              }
              width={150}
            />
          </StatsBlock>
          <StatsBlock>
            <Value isSmall>{`${away?.stats?.[MatchStatistics.STATS_CLEARANCE]}`}</Value>
          </StatsBlock>
        </StatsBlock>
      </StatsBlockColumn>
      <StatsBlockColumn marginBottom="20px">
        <StatsBlock>
          <Caption><FormattedMessage id="statistics.foulsCommitted" /></Caption>
        </StatsBlock>
        <StatsBlock>
          <StatsBlock>
            <Value isSmall>{`${home?.stats?.[MatchStatistics.STATS_FOULS]}`}</Value>
          </StatsBlock>
          <StatsBlock>
            <LineGraph
              strokeAwayColor={theme.color.veryDarkGray}
              value={
                (home?.stats?.[MatchStatistics.STATS_FOULS] * 100)
                / (home?.stats?.[MatchStatistics.STATS_FOULS]
                  + away?.stats?.[MatchStatistics.STATS_FOULS])
              }
              width={150}
            />
          </StatsBlock>
          <StatsBlock>
            <Value isSmall>{`${away?.stats?.[MatchStatistics.STATS_FOULS]}`}</Value>
          </StatsBlock>
        </StatsBlock>
      </StatsBlockColumn>
    </BlockContainerColumn>
  );
};

export default MatchTackles;
