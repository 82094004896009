import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Devices, Orientation } from '@app/styles';
import { CloseButton } from '@app/components';
import { WebCtaButtonElement } from '@app/components/WebTemplates/elements';
import { AppState } from '@app/store/reducers';
import { CookieBanner as CookieBannerType } from '@app/types/configurationTypes';
import {
  setCookieClosedStatus,
  getCookieClosedStatus,
  getCookieAcceptedStatus,
  setCookieAcceptedStatus,
  setGoogleAdsPersonalization,
} from '@app/helpers/cookieHelpers';

const CookieBanner = (): React.ReactElement | null => {
  const [isBannerVisible, setBannerVisible] = useState(false);

  const { locale } = useIntl();
  const cookie = useSelector<AppState, CookieBannerType>(
    (state) => state.configuration[locale]?.cookie,
    shallowEqual,
  );

  const onCookieAcceptClick = (): void => {
    setBannerVisible(false);
    setCookieAcceptedStatus(true);
    setGoogleAdsPersonalization(true);
  };

  const onCookieDeclineClick = (): void => {
    setBannerVisible(false);
    setCookieAcceptedStatus(false);
  };

  const onCookieCloseClick = (): void => {
    setBannerVisible(false);
    setCookieClosedStatus(true);
  };

  useEffect(() => {
    setBannerVisible(getCookieAcceptedStatus() === null && !getCookieClosedStatus());
  }, []);

  return isBannerVisible ? (
    <Container>
      <Content>
        <Text dangerouslySetInnerHTML={{ __html: cookie?.text ?? '' }} />
        {cookie && (
          <Buttons position={cookie?.cta.position ?? ''}>
            <WebCtaButtonElement
              data={cookie?.cta.accept}
              onClick={onCookieAcceptClick}
            />
            <WebCtaButtonElement
              data={cookie?.cta.decline}
              onClick={onCookieDeclineClick}
            />
          </Buttons>
        )}
      </Content>
      <CloseContainer>
        <CloseButton onClick={onCookieCloseClick} size={20} colorInvert />
      </CloseContainer>
    </Container>
  ) : null;
};

export default CookieBanner;

const Container = styled.div`
  position: fixed;
  top: unset;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  min-height: 30%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  z-index: 9999;
  overflow: hidden;
  padding: 20px 40px 20px 20px;
  color: white;
  background-color: rgba(0,0,0,0.9);
  animation: animateShow 0.5s ease;

  @keyframes animateShow {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media ${Devices.desktop} {
    min-height: 20%;
  }
  @media ${Devices.tabletLarge} and ${Orientation.landscape} {
    min-height: 20%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 1140px;
  margin: auto;

  @media ${Devices.tabletLarge} {
    flex-direction: row;
    text-align: left;
  }
`;

const Text = styled.div`
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: 12px;
  letter-spacing: 0.06px;
  padding: 0 0 30px;
  line-height: 17px;

  & a {
    color: white;
    text-decoration: underline;
  }

  @media ${Devices.tabletLarge} {
    padding: 0 50px 0 0;
  }

  @media ${Devices.desktop} {
    padding: 0 100px 0 0;
  }

  @media (min-height: 834px) {
    font-size: 14px;
  }
  @media (min-height: 1023px) {
    font-size: 17px;
    line-height: 20px;
  }
`;

const Buttons = styled.div<{ position: string }>`
  display: flex;
  flex-direction: ${(props): string => {
    switch (props.position) {
      case 'row': return 'row';
      case 'row_reverse': return 'row-reverse';
      case 'column': return 'column';
      case 'column_reverse': return 'column-reverse';
      default: return 'row';
    }
  }};
  justify-content: space-between;

  & > button {
    min-width: 110px;
    margin: ${(props): string => {
    switch (props.position) {
      case 'row': return '0 20px 0 0';
      case 'row_reverse': return '0 0 0 20px';
      case 'column': return '0 0 20px 0';
      case 'column_reverse': return '20px 0 0 0';
      default: return '0 20px 0 0';
    }
  }};

    &:last-child {
      margin: 0;
    }
  }
`;

const CloseContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 999;
`;
