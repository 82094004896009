import React from 'react';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { LocalizedDate } from '@app/components/Localization';
import { MatchResult } from '@app/types/matchTypes';
import { MatchStatusMap } from '@app/constants/matchConstants';

import { useGameByOptaIdSelector } from '@app/pages/MatchCenter/MatchCenterHooks';
import { useCompetitionsSelector } from '@app/pages/Season/Season/SeasonHooks';
import {
  useMatchLiveSubscribe, useMatchResultsSelector, useMatchLiveResetOnUnmout,
} from './MatchDayHooks';

import { Buttons, Side, Stadium } from './components';

interface MatchDayProps {
  result: MatchResult;
  isHomePage?: boolean;
}

const MatchDay = ({ result, isHomePage = false }: MatchDayProps): React.ReactElement => {
  const {
    gameOptaId, matchDay, matchDate, matchStatus, matchTime, isHalfTime, optaMatchTimeTBC,
    teams: { first, second }, competitionOptaId, stadium, isHome,
  } = useMatchResultsSelector(result);
  const game = useGameByOptaIdSelector(gameOptaId);
  const competitions = useCompetitionsSelector();
  const competitionName = Object.values(competitions || {})
    .find(({ optaId }) => optaId === competitionOptaId)?.name ?? '';

  const date = new Date(matchDate);
  const isMatchLive = matchStatus === MatchStatusMap.live;
  const isMatchUpcoming = matchStatus === MatchStatusMap.upcoming;

  const homeTeamShortName = result.teams.first.shortName.length
    ? result.teams.first.shortName : result.teams.first.name;
  const homeTeamFullName = result.teams.first.name.length
    ? result.teams.first.name : result.teams.first.shortName;

  const awayTeamShortName = result.teams.second.shortName.length
    ? result.teams.second.shortName : result.teams.second.name;
  const awayTeamFullName = result.teams.second.name.length
    ? result.teams.second.name : result.teams.second.shortName;

  const TBCLabel = game?.kenticoMatchTimeTBCLabel;
  const TBCFlag = game?.kenticoMatchTimeTBC === true;

  const TBC = isMatchUpcoming && (!!TBCLabel || TBCFlag || optaMatchTimeTBC);

  useMatchLiveSubscribe(result);
  useMatchLiveResetOnUnmout(result);

  return (
    <Grid data-status={matchStatus} isFullScreen={!isHomePage}>
      <DateArea isFullScreen={!isHomePage}>
        <MatchCompetitionName>{competitionName}</MatchCompetitionName>
        <MatchDayRow isFullScreen={!isHomePage}>
          <MatchDayNumber isFullScreen={!isHomePage}>
            <FormattedMessage id="match.day" />
            &nbsp;
            {matchDay}
          </MatchDayNumber>
          <Side isHome={isHome} side={game?.side} />
        </MatchDayRow>
        <MatchDateContainer isFullScreen={!isHomePage} matchTimeTBC={TBC}>
          {TBC ? (
            <>
              {TBCLabel || (
                <>
                  <LocalizedDate date={date} />
                  <span>
                    <FormattedMessage id="match.tbc" />
                  </span>
                </>
              )}
            </>
          ) : (
            <>
              <LocalizedDate date={date} />
              <span>
                <FormattedNumber value={date.getHours()} minimumIntegerDigits={2} />
                :
                <FormattedNumber value={date.getMinutes()} minimumIntegerDigits={2} />
              </span>
            </>
          )}
        </MatchDateContainer>
        <StadiumName isFullScreen={!isHomePage}>
          <Stadium kentico={game?.stadium} opta={stadium} />
        </StadiumName>
      </DateArea>
      <FirstTeamArea isFullScreen={!isHomePage}>
        {isHomePage ? (
          <>
            <HomeTeamName>
              {homeTeamShortName}
            </HomeTeamName>
            <HomeTeamShortName>
              {homeTeamFullName}
            </HomeTeamShortName>
          </>
        ) : (
          <TeamFullName>
            {homeTeamFullName}
          </TeamFullName>
        )}
        <Logo src={result.teams.first.logoUrl} alt={first.name} />
      </FirstTeamArea>
      <GoalsArea>
        {isMatchLive && (<Live><FormattedMessage id="match.live" /></Live>)}
        {isMatchUpcoming ? ('VS') : (<Score>{`${first.goals} - ${second.goals}`}</Score>)}
        {
          isMatchLive && (
            <MatchTime>
              { isHalfTime ? <FormattedMessage id="match.halfTime" /> : matchTime }
            </MatchTime>
          )
        }
      </GoalsArea>
      <SecondTeamArea isFullScreen={!isHomePage}>
        <Logo src={result.teams.second.logoUrl} alt={second.name} />
        {isHomePage ? (
          <>
            <HomeTeamName>
              {awayTeamShortName}
            </HomeTeamName>
            <HomeTeamShortName>
              {awayTeamFullName}
            </HomeTeamShortName>
          </>
        ) : (
          <TeamFullName>
            {awayTeamFullName}
          </TeamFullName>
        )}
      </SecondTeamArea>
      <ActionArea>
        <Buttons result={result} isHomePage={isHomePage} />
      </ActionArea>
    </Grid>
  );
};

export default MatchDay;

const Grid = styled.div<{isFullScreen: boolean}>`
  position: relative;
  display: grid;
  grid-template:
    'dateArea dateArea dateArea'
    'firstTeamArea goalsArea secondTeamArea'
    'firstTeamArea actionArea secondTeamArea';
  grid-template-columns: 1fr 130px 1fr;
  grid-template-rows: 70px 50px auto;
  grid-gap: 10px;
  justify-items: stretch;
  align-items: center;
  padding: ${({ isFullScreen }): string => (isFullScreen ? '20px' : '0')};
  background-color: white;

  ${({ isFullScreen }): string => (
    isFullScreen ? `
      @media ${Devices.tabletLarge} {
        grid-template: 'dateArea firstTeamArea goalsArea secondTeamArea actionArea';
        grid-template-columns: 130px 1fr 100px 1fr 130px;
        grid-template-rows: 120px;
    }` : ''
  )}

  &:nth-child(odd) {
    background: rgba(255,255,255,0.8);
  }

  & div {
    width: 100%;
    height: 100%;
  }
`;

const DateArea = styled.div<{isFullScreen: boolean}>`
  display: grid;
  grid-area: dateArea;
  justify-items: center;
  align-content: center;
  text-transform: capitalize;

  ${({ isFullScreen }): string => (
    isFullScreen ? `
      @media ${Devices.tabletLarge} {
        justify-items: flex-start;
      }` : ''
  )}
`;

const FirstTeamArea = styled.div<{isFullScreen: boolean}>`
  display: flex;
  grid-area: firstTeamArea;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: center;
  text-align: center;

  ${({ isFullScreen }): string => (
    isFullScreen ? `
      @media ${Devices.tabletLarge} {
        flex-direction: row;
        text-align: right;
      }` : ''
  )}
`;

const SecondTeamArea = styled(FirstTeamArea)<{isFullScreen: boolean}>`
  grid-area: secondTeamArea;
  flex-direction: column;
  justify-content: flex-start;

  ${({ isFullScreen }): string => (
    isFullScreen ? `
      @media ${Devices.tabletLarge} {
      text-align: left;
    }` : ''
  )}
`;

const GoalsArea = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: goalsArea;
  align-items: center;
  justify-items: center;
  text-align: center;
  justify-content: space-around;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.39px;
  font-family: ${(props): string => props.theme.fontFamily.milan};
`;

const StadiumName = styled.div<{isFullScreen: boolean}>`
  position: relative;
  width: 100%;
  text-align: center;

  ${({ isFullScreen }): string => (
    isFullScreen ? `
      @media ${Devices.tabletLarge} {
        position: absolute;
        width: 100%;
        height: 1px!important;
        bottom: 0;
        left: 0;

        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding: 20px;
        z-index: 0;
    }` : ''
  )}
`;

const Score = styled.span`
  font-size: 34px;
`;

const Live = styled.span`
  display: inline-block;
  font-size: 12px;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  width: 60px;
  line-height: 20px;
  margin: 0 0 5px 0;
  color: white;
  text-transform: uppercase;
  background-color: ${(props): string => props.theme.color.freeSpeechRed};
`;

const MatchTime = styled.span`
  display: inline-block;
  font-size: 12px;
  font-weight: 300;
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  letter-spacing: normal;
`;

const ActionArea = styled.div`
  grid-area: actionArea;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9;

  & > * {
    width: 120px;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const MatchCompetitionName = styled.p`
  font-size: 12px;
  font-weight: normal;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  letter-spacing: 0.35px;
`;

const MatchDayRow = styled.div<{isFullScreen: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  ${({ isFullScreen }): string => (
    isFullScreen ? `
      @media ${Devices.tabletLarge} {
        justify-content: flex-start;
    }` : ''
  )}
`;

const MatchDayNumber = styled.span<{isFullScreen: boolean}>`
  font-size: 12px;
  font-weight: bold;
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  letter-spacing: 0.35px;
  text-transform: uppercase;

  ${({ isFullScreen }): string => (
    isFullScreen ? `
      @media ${Devices.tabletLarge} {
      font-size: 16px;
    }` : ''
  )}
`;

const MatchDateContainer = styled.div<{isFullScreen: boolean; matchTimeTBC: boolean}>`
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  padding-top: 5px;

  & > span {
    font-size: 12px;
    margin: 0 5px;
  }

  ${({ isFullScreen }): string => (
    isFullScreen ? `
      @media ${Devices.tabletLarge} {
      padding-top: 0;
      flex-direction: column;
      text-align: left;

      & > span {
        font-size: 14px;
        margin: 0;
      }
    }` : ''
  )}
`;

const TeamFullName = styled.span`
  font-size: 14px;
  font-weight: bold;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  letter-spacing: 0.35px;
  text-transform: uppercase;
  margin-top: 15px;
  word-break: break-word;

  @media ${Devices.tablet} {
    font-size: 16px;
  }

  @media ${Devices.tabletLarge} {
    font-size: 20px;
    margin-top: 0;
    padding: 0 5px;
  }
`;

const HomeTeamName = styled(TeamFullName)`
  display: inline;

  @media ${Devices.tabletLarge} {
    display: none;
  }
`;

const HomeTeamShortName = styled(HomeTeamName)`
  display: none;

  @media ${Devices.tabletLarge} {
    display: inline;

    font-size: 16px;
    margin-top: 15px;
  }
`;

const Logo = styled.img`
  width: 100%;
  height: 100%;
  max-width: 58px;
  max-height: 58px;
  margin: 0 10px;
`;
