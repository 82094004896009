import styled from 'styled-components';

const RedBoxTitle = styled.span`
  position: relative;
  display: inline-block;
  color: white;
  background-color: ${(props): string => props.theme.color.freeSpeechRed};
  height: 30px;
  min-width: 170px;
  max-width: 300px;
  padding-left: 20px;
  margin-right: 30px;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 30px;
  letter-spacing: 0.23px;
  text-transform: uppercase;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -30px;
    border: 15px solid ${(props): string => props.theme.color.freeSpeechRed};
    border-right-color: transparent;
    border-bottom-color:transparent;
  }
`;

export default RedBoxTitle;
