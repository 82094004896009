import { encodeQueryData } from '../helpers/requestHelpers';

const BaseURL = process.env.API === 'production'
  ? 'https://cdn-acmilan-api-prod.netcosports.com'
  : 'https://cdn-acmilan-api-integ.netcosports.com';
const OPTA_SOURCE_URL = `${BaseURL}/api/statistic/opta/proxy/`;
const OPTA_SD_SOURCE_URL = `${BaseURL}/api/statistic/optasd/proxy/`;
const OPTA_SD_AUTH_TOKEN = 'outletAuthKey';

export interface RequestParamsInterface {
  [name: string]: string | number | boolean | undefined;
}

interface RequestInterface {
  params?: RequestParamsInterface;
  path?: string;
  isOptaSD?: boolean;
}

interface TokenResponse {
  code: number;
  message: string;
  data?: TokenData;
}

interface TokenData {
  user: string;
  token: string;
}

async function sendRequestInternal<T>(request: RequestInterface): Promise<T> {
  const { path, params, isOptaSD } = request;
  const query = params ? `?${encodeQueryData(params)}` : '';
  const requestPath = path ? `${path}/` : '';
  const requestUrl = isOptaSD
    ? `${OPTA_SD_SOURCE_URL}${requestPath}${OPTA_SD_AUTH_TOKEN}${query}`
    : `${OPTA_SOURCE_URL}${path || ''}${query}`;

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore

  const response = await fetch(
    requestUrl,
    {
      method: 'GET',
    },
  );

  if (response.ok) return response.json();

  throw Error(`Request rejected with status ${response.status}. ${response.statusText}`);
}

export default async function sendRequest(request: RequestInterface): Promise<{[propName: string]: unknown}> {
  return sendRequestInternal<{ [propName: string]: unknown }>(request);
}

export async function sendRequestTyped<T>(request: RequestInterface): Promise<T> {
  return sendRequestInternal<T>(request);
}
