import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { ContainerMedium } from '@app/components/WebTemplates/components';

interface WebEmbeddedOneTrustElementProps {
  content: string;
}

const WebEmbeddedOneTrustElement = ({ content }: WebEmbeddedOneTrustElementProps): React.ReactElement => {
  const embeddedRef = useRef<HTMLDivElement>(document.createElement('div'));

  useEffect(() => {
    embeddedRef.current.appendChild(
      document.createRange().createContextualFragment(Buffer.from(content, 'base64').toString()),
    );
    return (): void => { embeddedRef.current.innerHTML = ''; };
  }, [content]);

  return (<Container ref={embeddedRef} />);
};

export default WebEmbeddedOneTrustElement;

const Container = styled(ContainerMedium)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 20px;

  @media ${Devices.desktop} {
    padding: 10px 0;
  }
`;
