import React from 'react';
import Carousel from 'react-multi-carousel';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { NextMatchDay } from '@app/components';
import { useNextMatchesSelector } from '../ScheduleHooks';


const responsive = {
  tablet: {
    breakpoint: { max: Infinity, min: 834 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 833, min: 0 },
    items: 1,
  },
};

const CustomDot = ({ ...rest }): React.ReactElement => {
  const { onClick, active } = rest;
  return (<Dot onClick={onClick} active={active} />);
};

const NextMatches = React.memo((): React.ReactElement | null => {
  const matches = useNextMatchesSelector();

  return matches?.length ? (
    <Widget>
      <Title><FormattedMessage id="match.day.next" /></Title>
      <Matches>
        <Carousel
          responsive={responsive}
          arrows={false}
          draggable={false}
          swipeable
          showDots={matches.length > 1}
          renderDotsOutside
          keyBoardControl={false}
          // class is specified in globalStyles
          containerClass="container-100hv"
          sliderClass="container-100hv"
          itemClass="next-carousel-item"
          dotListClass="next-carousel-dots"
          customDot={<CustomDot />}
        >
          {matches.map((match) => (
            <NextMatchDay key={match.gameId} result={match} />
          ))}
        </Carousel>
      </Matches>
    </Widget>
  ) : null;
});

export default NextMatches;

const Widget = styled.section`
  max-width: 960px;
  padding: 0 10px 25px;
  margin: auto;
`;

const Matches = styled.div`
  position: relative;
  padding: 10px 0;
  margin: auto;
`;

const Title = styled.h2`
  font-family: MilanTypeRev;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  text-transform: uppercase;
`;

const Dot = styled.button<{ active: boolean}>`
  width: 8px;
  height: 8px;
  padding: 0;
  margin: 0 5px;

  border-radius: 50%;
  background-color: #c9240d;
  opacity: ${({ active }): number => (active ? 1 : 0.2)};

  border: none;
  outline: none;
  cursor: pointer;
  transition: all .2s linear;
`;
