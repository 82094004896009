import React from 'react';
import styled from 'styled-components';

import { getWebTemplateDataForSsr } from '@app/store/actions/webTemplateActions';
import { PageType } from '@app/types/webTemplateTypes';

import {
  Metadata, MetaJsonLD, SecondLevelMenu,
} from '@app/components';
import { Question, HallOfFame, HallOfFamePlayerProfile } from '@app/pages';
import WebTemplateRosterArchivePage from './WebTemplateRosterArchivePage';
import WebTemplatePage from './WebTemplatePage';

import {
  useWebTemplateMultiLangUrl,
  useWebTemplateSecondLevelMenuItems,
  useLastLevelMenu,
  useWebTemplateJsonLD,
  usePageType,
} from './WebTemplateHooks';

const WebTemplate = (): React.ReactElement => {
  useWebTemplateMultiLangUrl();
  const secondLevelMenuItems = useWebTemplateSecondLevelMenuItems();
  const lastLevelMenu = useLastLevelMenu();
  const breadcrumbsJsonLD = useWebTemplateJsonLD();
  const pageType = usePageType();
  let pageComponent;

  switch (pageType) {
    case PageType.Question:
      pageComponent = (<Question />);
      break;
    case PageType.HallOfFamePlayer:
      pageComponent = (<HallOfFamePlayerProfile />);
      break;
    case PageType.HallOfFame:
      pageComponent = (<HallOfFame />);
      break;
    case PageType.RosterArchive:
      pageComponent = (<WebTemplateRosterArchivePage />);
      break;
    default:
      pageComponent = (<WebTemplatePage />);
  }


  return (
    <>
      {!!lastLevelMenu?.seo && <Metadata seo={lastLevelMenu.seo} />}
      {!!secondLevelMenuItems.length && <SecondLevelMenu items={secondLevelMenuItems} />}
      {!!lastLevelMenu?.seo && <MetaJsonLD json={breadcrumbsJsonLD} />}
      <Container>
        {pageComponent}
      </Container>
    </>
  );
};

WebTemplate.serverFetch = [getWebTemplateDataForSsr];

const Container = styled.div`
  background-color: ${(props): string => props.theme.color.whiteSmoke};
  height: 100%;
`;

export default WebTemplate;
