import React from 'react';
import styled from 'styled-components';

export const HOFTitle = ({ children }): React.ReactElement => (
  <Title>{children}</Title>
);

const Title = styled.h1`
  font-family: MilanTypeRev, Helvetica Neue, Helvetica, Arial, sans-serif!important;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  color: #d8be6d;
  text-align: left;
  text-transform: uppercase;
`;
