import React from 'react';
import styled from 'styled-components';

export const HOFSocialSharing = ({ children }): React.ReactElement => (
  <SocialSharing>{children}</SocialSharing>
);

const SocialSharing = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;
