import { SeasonActions, SeasonActionTypes } from '@app/store/actionTypes/seasonActionTypes';
import { StandingsTable } from '@app/types/standingsTypes';
import { ScheduleResults } from '@app/types/matchTypes';
import { Errors } from '@app/types/errorTypes';

export type SeasonState = {
  standings: StandingsTable[];
  schedule: ScheduleResults;
  allSchedule: ScheduleResults;
  error: Errors;
  isLoading: boolean;
};

const initialState: SeasonState = {
  standings: [],
  schedule: {},
  allSchedule: {},
  error: null,
  isLoading: false,
};

export default (
  state = initialState,
  action: SeasonActions,
): SeasonState => {
  switch (action.type) {
    case SeasonActionTypes.SET_STANDINGS:
      return {
        ...state,
        standings: action.payload,
      };
    case SeasonActionTypes.SET_SCHEDULE:
      return {
        ...state,
        schedule: action.payload,
      };
    case SeasonActionTypes.SET_ALL_SCHEDULE:
      return {
        ...state,
        allSchedule: action.payload,
      };
    case SeasonActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SeasonActionTypes.SET_SEASON_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SeasonActionTypes.RESET_SEASON_ERROR:
      return {
        ...state,
        error: initialState.error,
      };
    case SeasonActionTypes.RESET_SEASON:
      return initialState;
    default:
      return state;
  }
};
