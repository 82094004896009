import React from 'react';
import { useParams } from 'react-router-dom';

import { PlayerPositionFilter } from '@app/types/teamsTypes';

import { GoogleAds, PlayersCategory } from '@app/components';

import { getCategoryName, useCategoryMenu, useFilteredPlayersWithGoogleAds } from '@app/pages/Teams/TeamsHooks';
import styled from 'styled-components';
import { MemberCard } from '@app/pages/Teams/Members';

const TeamPlayersList: React.FC<{position: PlayerPositionFilter}> = ({ position }) => {
  const { teamType = '' } = useParams();
  const categoryMenu = useCategoryMenu();
  const players = useFilteredPlayersWithGoogleAds(position);

  if (!players || !players.length) return null;

  return (
    <PlayersCategory
      type={getCategoryName({ categoryMenu, position })}
      key={position}
    >
      {
        players.map((item) => ('adUnit' in item ? (
          <AdsWrapper>
            <GoogleAds {...item} />
          </AdsWrapper>
        ) : (
          <MemberCard item={item} key={item.kenticoId} teamType={teamType} />
        )))
      }
    </PlayersCategory>
  );
};

const AdsWrapper = styled.li`
  width: 220px;
  height: 300px;
  margin: 0 20px 20px 0;
  position: relative;
`;

export default TeamPlayersList;
