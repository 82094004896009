import React from 'react';
import AppRoutes from '@app/constants/routes';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import LocalizedLink from '@app/components/Localization/LocalizedLink';
import {
  Metadata, MetaJsonLD, WebTemplateSelect, HorizontalScroll,
} from '@app/components';
import { Devices } from '@app/styles';
import { TicketingTypes } from '@app/types/ticketingTypes';
import { findTabBySlug } from '@app/helpers/ticketingHelpers';
import { useLanguage, useBreadcrumbsJsonLD } from '@app/components/Hooks';
import { useLocalizedTicketsTabs } from '../TicketingHooks';
import Single from '../Single/Single';
import Packages from '../Packages/Packages';

interface TicketsTabContentProps {
  type?: TicketingTypes | unknown;
}

const TicketsTabContent = ({ type }: TicketsTabContentProps): React.ReactElement | null => {
  switch (type) {
    case TicketingTypes.SingleTickets:
      return (<Single />);
    case TicketingTypes.Packages:
      return (<Packages />);
    case TicketingTypes.Subscription:
      // return (<Subscription />);
      return null; // TODO:CHECK:WHY:WE:USE:HERE:ADDITIONAL:COMPONENT
    default:
      return null;
  }
};

const TicketsTabs = (): React.ReactElement => {
  const language = useLanguage();
  const tabs = useLocalizedTicketsTabs()[language];
  const { categoryName = tabs[0]?.url } = useParams();
  const currentTab = findTabBySlug(tabs, categoryName);
  const breadcrumbsJsonLD = useBreadcrumbsJsonLD(currentTab?.seo?.title ?? '');

  return (
    <Container>
      { !!currentTab?.seo && <Metadata seo={currentTab.seo} /> }
      { !!currentTab?.seo && <MetaJsonLD json={breadcrumbsJsonLD} /> }
      <TabContainer>
        <HorizontalScroll color="black">
          {tabs.map((tab, index) => (
            <TabLocalizedLink
              pathKey={AppRoutes.Ticketing.path}
              pathParams={{ categoryName: index > 0 ? tab.url : '' }}
              key={tab.id}
              active={tab.url === categoryName}
              isTab
            >
              {tab.name}
            </TabLocalizedLink>
          ))}
        </HorizontalScroll>
      </TabContainer>
      <TabContent>
        <TicketsTabContent type={currentTab?.id} />
        {currentTab?.content?.map((content) => (<WebTemplateSelect data={content} key={content?.id} />))}
      </TabContent>
    </Container>
  );
};


const Container = styled.div`
  width: 100%;
  position: relative;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 20;
`;

const TabContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 5px;
  color: #ffffff;
  letter-spacing: 0.21px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > * {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 25px!important;
    padding-top: 5px;
  }

  @media ${Devices.tabletLarge} {
    margin-bottom: 10px;

    & > * {
      height: 30px!important;
    }
  }
`;

const TabLocalizedLink = styled(LocalizedLink)<{ active: boolean }>`
  margin-right: 30px;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-weight: normal;
  letter-spacing: 0.16px;
  font-size: 14px;
  color: ${({ active }): string => (active ? '#000000' : 'rgba(0, 0, 0, 0.2)')};
  border-color: ${({ active, theme }): string => (active ? theme.color.harleyDavidsonOrange : 'transparent')};
  border-bottom-style: solid;
  border-bottom-width: 3px ;

  &:last-of-type {
    margin-right: 0;
  }

  @media ${Devices.tabletLarge} {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.39px;
    color: #000000;
    margin-right: 60px;
  }
`;

const TabContent = styled.div`
  width: 100%;
  margin-top: 10px;
`;

export default TicketsTabs;
