import { LanguageType } from '@app/types/localizationTypes';
import { HomeBlocks, HomeConfiguration } from '@app/types/homeConfigurationTypes';
import { sendRequestTyped } from '@app/services/kentico/request';
import { langCodeToKenticoLangMap } from '@app/constants/localizationConstants';
import { RequestInterface } from '@app/services/kentico/types/requestTypes';
import { KenticoWebTemplateModularContent } from '@app/services/kentico/types/webTemplateTypes';
import { WebGoogleAds, WebHomeLanding, WebHomeSlider } from '@app/types/webTemplateTypes';
import { KenticoHomeElement, HomeModularContent, KenticoHomeLandingElement } from './types/homeTypes';
import {
  convertHomeData,
  convertHomeLandingData,
  convertHomeSliderData,
  convertHomeGoogleAds,
} from './converters/homeConfigurationConverter';

export const getHomeConfiguration = async (language: LanguageType): Promise<HomeConfiguration> => {
  const homeConfigKenticoResponse = await sendRequestTyped<KenticoHomeElement, HomeModularContent>({
    params: {
      'system.type': 'home_web',
      depth: 2,
      language: langCodeToKenticoLangMap[language],
      'system.language': langCodeToKenticoLangMap[language],
    },
  });

  return convertHomeData(homeConfigKenticoResponse);
};

export const getHomeLandingData = async (id: HomeBlocks, language: LanguageType): Promise<WebHomeLanding> => {
  const params: RequestInterface = {
    path: `/${id}`,
    params: {
      depth: 3,
      language: langCodeToKenticoLangMap[language],
      'system.language': langCodeToKenticoLangMap[language],
    },
  };
  const kenticoResponse = await sendRequestTyped<KenticoHomeLandingElement, KenticoWebTemplateModularContent>(
    params,
  );

  return convertHomeLandingData(kenticoResponse);
};

export const getHomeSliderData = async (id: HomeBlocks, language: LanguageType): Promise<WebHomeSlider> => {
  const params: RequestInterface = {
    path: `/${id}`,
    params: {
      depth: 3,
      language: langCodeToKenticoLangMap[language],
      'system.language': langCodeToKenticoLangMap[language],
    },
  };
  const kenticoResponse = await sendRequestTyped<KenticoHomeLandingElement, KenticoWebTemplateModularContent>(
    params,
  );

  return convertHomeSliderData(kenticoResponse);
};

export const getHomeGoogleAds = async (id: HomeBlocks, language: LanguageType): Promise<WebGoogleAds | null> => {
  const params: RequestInterface = {
    path: `/${id}`,
    params: {
      depth: 3,
      language: langCodeToKenticoLangMap[language],
      'system.language': langCodeToKenticoLangMap[language],
    },
  };
  const response = await sendRequestTyped<KenticoHomeLandingElement, KenticoWebTemplateModularContent>(params);

  return convertHomeGoogleAds(response);
};
