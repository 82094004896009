import React from 'react';

import { useHomeEmbeddedOverlaySelector } from '@app/pages/Home/HomeHooks';
import { WebEmbeddedOverlayElement } from '@app/components/WebTemplates/elements';

const HomeEmbeddedOverlay = (): React.ReactElement | null => {
  const overlay = useHomeEmbeddedOverlaySelector();
  return <WebEmbeddedOverlayElement content={overlay?.content ?? ''} delay={overlay?.delay ?? 0} />;
};

export default HomeEmbeddedOverlay;
