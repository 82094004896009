import React from 'react';
import styled from 'styled-components';

import { getSchedule, setScheduleMultiLangUrl } from '@app/store/actions/seasonActions';
import { getTicketingSingle } from '@app/store/actions/ticketingActions';
import { getLocalizedGames } from '@app/store/actions/matchActions';
import { useMatchCenterButton } from '@app/pages/MatchCenter/MatchCenterHooks';
import { Loader } from '@app/components';
import { MatchCalendar, MatchMonth, NextMatches } from './components';
import { useCalendarSelector, useScheduleResultsDownload } from './ScheduleHooks';
import { useIsLoadingSelector } from '../Season/SeasonHooks';

const Schedule = (): React.ReactElement => {
  const calendar = useCalendarSelector();
  const isLoading = useIsLoadingSelector();

  useMatchCenterButton();
  useScheduleResultsDownload();

  return (
    <div>
      <NextMatches />
      <MatchCalendar />
      {isLoading ? (<Loader />) : (
        <Results>
          { calendar.map((date, i) => <MatchMonth key={date} matchDate={date} showAds={i === 0} />) }
        </Results>
      )}
    </div>
  );
};

Schedule.serverFetch = [getSchedule, getTicketingSingle, setScheduleMultiLangUrl, getLocalizedGames];

export default Schedule;

const Results = styled.div`
  max-width: 960px;
  padding: 0 10px;
  margin: auto;
`;
