import {
  LanguageType, LangListType, SwitchLangListType,
} from '@app/types/localizationTypes';
// TODO: Restore Chinese localization
export const AppLanguages: LangListType = ['en', 'it', 'cn'];
// Please note: this list is used only in Language Switcher component
export const SwitchLanguages: SwitchLangListType = ['en', 'it', 'cn'];

export const AppLanguagesMap: {
  [key in LanguageType]: LanguageType;
} = {
  en: 'en',
  it: 'it',
  cn: 'cn',
};

export const langCodeToKenticoLangMap: {
  [key in LanguageType]: string;
} = {
  en: 'english',
  it: 'italian',
  cn: 'chinese',
};

export const langCodeToSalesForceLangMap: {
  [key in LanguageType]: string;
} = {
  en: 'en_US',
  it: 'it',
  cn: 'zh',
};

export const langCodeToLanguageTerritory: {
  [key in LanguageType]: string;
} = {
  en: 'en_US',
  it: 'it_IT',
  cn: 'zh_CN',
};

export const KenticoLangToLangCodeMap: {
  [key: string]: LanguageType;
} = {
  italian: 'it',
  english: 'en',
  chinese: 'cn',
};
