import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { AppState } from '@app/store/reducers';
import { AdBaseStatus, GoogleAdsProps } from '@app/types/googleTypes';
import { initGPT, initAdSlot, resetAdSlotStatus } from '@app/store/actions/googleActions';
import { getAdUnitId } from '@app/helpers/googleHelpers';

export const useAdsStatus = (adUnit: string): AdBaseStatus => useSelector<AppState, AdBaseStatus>(
  (state) => {
    const status = state.google.gpt.slots[getAdUnitId(adUnit)];
    return status === undefined ? null : status;
  }, isEqual,
);

export const useGPTStatus = (): AdBaseStatus => useSelector<AppState, AdBaseStatus>(
  (state) => state.google.gpt.isDownloaded, isEqual,
);

export const useGPT = (ads: GoogleAdsProps): boolean => {
  const isDownloaded = useGPTStatus() === true;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDownloaded) { dispatch(initAdSlot(ads)); } else { dispatch(initGPT()); }
  }, [isDownloaded]);

  useEffect(() => (): void => { dispatch(resetAdSlotStatus(ads.adUnit)); }, []);

  return isDownloaded;
};
