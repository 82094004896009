import { LocalizedHallOfFame } from '@app/types/hallOfFameTypes';

export enum HallOfFameActionTypes {
  SET_HALL_OF_FAME = 'HALL_OF_FAME/SET_HALL_OF_FAME',
}

export interface SetHallOfFame {
  type: HallOfFameActionTypes.SET_HALL_OF_FAME;
  payload: LocalizedHallOfFame;
}

export type HallOfFameAction = SetHallOfFame;
