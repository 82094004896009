import {
  LocalizedTicketingConfiguration, LocalizedTicketingSubscription, TicketingSingle, PackageTicketsInfo,
} from '@app/types/ticketingTypes';
import { TopNews } from '@app/types/newsTypes';

export enum TicketingActionTypes {
  SET_TICKETING_CONFIGURATION = 'TICKETING/SET_TICKETING_CONFIGURATION',
  RESET_TICKETING_CONFIGURATION = 'TICKETING/RESET_TICKETING_CONFIGURATION',
  SET_TICKETING_SINGLE = 'TICKETING/SET_TICKETING_SINGLE',
  RESET_TICKETING_SINGLE = 'TICKETING/RESET_TICKETING_SINGLE',
  SET_TICKETING_PACKAGES = 'TICKETING/SET_TICKETING_PACKAGES',
  RESET_TICKETING_PACKAGES = 'TICKETING/RESET_TICKETING_PACKAGES',
  SET_TICKETING_SUBSCRIPTION = 'TICKETING/SET_TICKETING_SUBSCRIPTION',
  RESET_TICKETING_SUBSCRIPTION = 'TICKETING/RESET_TICKETING_SUBSCRIPTION',
  SET_TICKETING_CAROUSEL = 'TICKETING/SET_TICKETING_CAROUSEL',
  RESET_TICKETING_CAROUSEL = 'TICKETING/RESET_TICKETING_CAROUSEL',
}

interface ActionTypes {
  type: TicketingActionTypes;
  payload?: TicketingSingle
    | PackageTicketsInfo[]
    | LocalizedTicketingConfiguration
    | LocalizedTicketingSubscription
    | TopNews[];
}

export interface SetTicketingConfiguration extends ActionTypes {
  type: TicketingActionTypes.SET_TICKETING_CONFIGURATION;
  payload: LocalizedTicketingConfiguration;
}

export interface ResetTicketingConfiguration extends ActionTypes {
  type: TicketingActionTypes.RESET_TICKETING_CONFIGURATION;
}

export interface SetTicketingSingle extends ActionTypes {
  type: TicketingActionTypes.SET_TICKETING_SINGLE;
  payload: TicketingSingle;
}

export interface ResetTicketingSingle extends ActionTypes {
  type: TicketingActionTypes.RESET_TICKETING_SINGLE;
}

export interface SetTicketingPackages extends ActionTypes {
  type: TicketingActionTypes.SET_TICKETING_PACKAGES;
  payload: PackageTicketsInfo[];
}

export interface ResetTicketingPackages extends ActionTypes {
  type: TicketingActionTypes.RESET_TICKETING_PACKAGES;
}

export interface SetTicketingSubscription extends ActionTypes {
  type: TicketingActionTypes.SET_TICKETING_SUBSCRIPTION;
  payload: LocalizedTicketingSubscription;
}

export interface ResetTicketingSubscription extends ActionTypes {
  type: TicketingActionTypes.RESET_TICKETING_SUBSCRIPTION;
}

export interface SetTicketingCarousel extends ActionTypes {
  type: TicketingActionTypes.SET_TICKETING_CAROUSEL;
  payload: TopNews[];
}

export interface ResetTicketingCarousel extends ActionTypes {
  type: TicketingActionTypes.RESET_TICKETING_CAROUSEL;
}

export type TicketingActions = SetTicketingConfiguration
  | ResetTicketingConfiguration
  | SetTicketingSingle
  | ResetTicketingSingle
  | SetTicketingPackages
  | ResetTicketingPackages
  | SetTicketingSubscription
  | ResetTicketingSubscription
  | SetTicketingCarousel
  | ResetTicketingCarousel;
