import React from 'react';
import styled from 'styled-components';

import { NewsTypes, NewsPageProps } from '@app/types/newsTypes';

import { PageValidationWrapper } from '@app/pages/components';
import { NewsList } from '@app/components/News';

import { useRouterParamsValidation, useNewsListAdsSelector } from './NewsHooks';
import {
  NewsPageSeo, NewsPageTitle, NewsPageCategoryMenu, NewsPageSecondLevelMenu,
} from './components';

const News = React.memo(({ type }: NewsPageProps): React.ReactElement => {
  const error = useRouterParamsValidation(type);
  const ads = useNewsListAdsSelector(type);

  return (
    <PageWrapper error={error} type={type}>
      <NewsPageSeo type={type} />
      <NewsPageSecondLevelMenu type={type} />
      <Container>
        <Wrapper>
          <NewsPageTitle type={type} />
          <NewsPageCategoryMenu type={type} />
          <NewsList type={type} ads={ads} />
        </Wrapper>
      </Container>
    </PageWrapper>
  );
});

export default News;

const PageWrapper = styled(PageValidationWrapper)<{ type: NewsTypes }>`
  background: ${({ type, theme }): string => (type === NewsTypes.VIDEOS ? 'black' : theme.color.whiteSmoke)};
`;

const Container = styled.div`
  height: 100%;
  padding: 0 20px;
`;

const Wrapper = styled.div`
  max-width: 1020px;
  margin: auto;
`;
