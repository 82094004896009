import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { theme } from '@app/styles';
import { Game } from '@app/types/matchTypes';
import { ColorizedButton } from './ColorizedButton';
import Modal from '../Modal';

interface MatchInfoButtonProps {
  game: Game;
}

const MatchInfoButton = ({ game }: MatchInfoButtonProps): React.ReactElement | null => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  if (!game?.ticketsPopup) return null;

  return (
    <>
      <ColorizedButton
        color={theme.color.cheninYellow}
        onClick={(): void => {
          setIsModalOpened(true);
        }}
      >
        <FormattedMessage id="match.info" />
      </ColorizedButton>
      <Modal controls={false} open={isModalOpened} onClose={(): void => setIsModalOpened(false)}>
        <Container>
          <Header>
            <FormattedMessage id="match.info" />
          </Header>
          <MessageArea dangerouslySetInnerHTML={{ __html: game?.ticketsMessage ?? '' }} />
          <ButtonsArea>
            <ColorizedButton
              color={theme.color.whiteSmoke}
              borderColor={theme.color.cheninYellow}
              onClick={(): void => setIsModalOpened(false)}
            >
              <FormattedMessage id="buttons.cancel" />
            </ColorizedButton>
          </ButtonsArea>
        </Container>
      </Modal>
    </>
  );
};

export default MatchInfoButton;

const Container = styled.div`
  width: 100%;
  max-width: 920px;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  background-color: ${(props): string => props.theme.color.whiteSmoke};
  border-radius: 10px;
  overflow: hidden;
`;

const Header = styled.div`
  width: 100%;
  height: 40px;
  font-size: 14px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: ${(props): string => props.theme.color.cheninYellow};
`;

const MessageArea = styled.div`
  width: 100%;
  min-height: 200px;
  padding: 20px;

  color: black;
  display: block;
`;

const ButtonsArea = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  & button {
    width: 100px;
  }
`;
