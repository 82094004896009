import React from 'react';
import { useIntl } from 'react-intl';
import { format, isValid, parseISO } from 'date-fns';
import styled from 'styled-components';

import { mapLanguageTypeToDateFnsLocale } from '@app/helpers/localizationHelper';
import { AppLanguagesMap } from '@app/constants/localizationConstants';
import { DateFormatMap } from '@app/constants/dateTimeConstants';

const LocalizedDate = (
  { date, dateFormat }: { date: string | number | Date; dateFormat?: string },
): React.ReactElement => {
  const { locale } = useIntl();
  const isoDate = typeof date === 'string' ? parseISO(date) : date;
  const formattedDate = isValid(isoDate) ? format(isoDate, dateFormat ?? DateFormatMap[locale], {
    locale: mapLanguageTypeToDateFnsLocale(AppLanguagesMap[locale]),
  }) : '';

  return (
    <Date textTransform={locale === AppLanguagesMap.en ? 'capitalize' : 'lowercase'}>
      {formattedDate}
    </Date>
  );
};

export default LocalizedDate;

const Date = styled.span<{ textTransform: string }>`
  text-transform: ${({ textTransform }): string => textTransform};
`;
