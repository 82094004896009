import cloneDeep from 'lodash/cloneDeep';

import { NewsActions } from '@app/store/actionTypes/newsActionTypes';
import {
  BaseNewsItem, LocalizedArticleItem, LocalizedVideoItem, LocalizedGalleryItem,
} from '@app/types/newsTypes';

interface NewsBaseInterface {
  list: {
    items: BaseNewsItem[] | null;
    hasMoreData: boolean;
    isLoadingData: boolean;
  };
  latestItems: BaseNewsItem[];
}

interface ArticlesStateInterface extends NewsBaseInterface {
  selectedItemDetails: LocalizedArticleItem | null;
}

interface VideosStateInterface extends NewsBaseInterface {
  selectedItemDetails: LocalizedVideoItem | null;
}

interface PicturesStateInterface extends NewsBaseInterface {
  selectedItemDetails: LocalizedGalleryItem | null;
}

export type NewsState = {
  articles: ArticlesStateInterface;
  videos: VideosStateInterface;
  pictures: PicturesStateInterface;
};

const initialState: NewsState = {
  articles: {
    list: {
      items: null,
      hasMoreData: false,
      isLoadingData: false,
    },
    selectedItemDetails: null,
    latestItems: [],
  },
  videos: {
    list: {
      items: null,
      hasMoreData: false,
      isLoadingData: false,
    },
    selectedItemDetails: null,
    latestItems: [],
  },
  pictures: {
    list: {
      items: null,
      hasMoreData: false,
      isLoadingData: false,
    },
    selectedItemDetails: null,
    latestItems: [],
  },
};

export default (
  state: NewsState = cloneDeep(initialState),
  action: NewsActions,
): NewsState => {
  switch (action.type) {
    case 'NEWS/SET_ARTICLES_LIST':
      return {
        ...state,
        articles: {
          ...state.articles,
          list: {
            ...state.articles.list,
            items: state.articles.list.items
              ? [...state.articles.list.items, ...action.payload.items]
              : action.payload.items,
            hasMoreData: action.payload.hasMoreData,
          },
        },
      };
    case 'NEWS/SET_ARTICLES_LOADING_STATE':
      return {
        ...state,
        articles: {
          ...state.articles,
          list: {
            ...state.articles.list,
            isLoadingData: action.payload,
          },
        },
      };
    case 'NEWS/RESET_ARTICLES_LIST':
      return {
        ...state,
        articles: { ...initialState.articles },
      };
    case 'NEWS/SET_LATEST_ARTICLES_LIST':
      return {
        ...state,
        articles: {
          ...state.articles,
          latestItems: [...action.payload.items],
        },
      };
    case 'NEWS/RESET_LATEST_ARTICLES_LIST':
      return {
        ...state,
        articles: {
          ...state.articles,
          latestItems: [],
        },
      };
    case 'NEWS/SET_LATEST_VIDEOS_LIST':
      return {
        ...state,
        videos: {
          ...state.videos,
          latestItems: [...action.payload.items],
        },
      };
    case 'NEWS/RESET_LATEST_VIDEOS_LIST':
      return {
        ...state,
        videos: {
          ...state.videos,
          latestItems: [],
        },
      };
    case 'NEWS/SET_LATEST_PICTURES':
      return {
        ...state,
        pictures: {
          ...state.pictures,
          latestItems: [...action.payload.items],
        },
      };
    case 'NEWS/RESET_LATEST_PICTURES':
      return {
        ...state,
        pictures: {
          ...state.pictures,
          latestItems: [],
        },
      };
    case 'NEWS/SET_VIDEOS_LIST':
      return {
        ...state,
        videos: {
          ...state.videos,
          list: {
            ...state.videos.list,
            items: state.videos.list.items
              ? [...state.videos.list.items, ...action.payload.items]
              : action.payload.items,
            hasMoreData: action.payload.hasMoreData,
          },
        },
      };
    case 'NEWS/RESET_VIDEOS_LIST':
      return {
        ...state,
        videos: { ...initialState.videos },
      };
    case 'NEWS/SET_VIDEOS_LOADING_STATE':
      return {
        ...state,
        videos: {
          ...state.videos,
          list: {
            ...state.videos.list,
            isLoadingData: action.payload,
          },
        },
      };
    case 'NEWS/SET_PICTURES':
      return {
        ...state,
        pictures: {
          ...state.pictures,
          list: {
            ...state.pictures.list,
            items: state.pictures.list.items
              ? [...state.pictures.list.items, ...action.payload.items]
              : action.payload.items,
            hasMoreData: action.payload.hasMoreData,
          },
        },
      };
    case 'NEWS/RESET_PICTURES':
      return {
        ...state,
        pictures: { ...initialState.pictures },
      };
    case 'NEWS/SET_PICTURES_LOADING_STATE':
      return {
        ...state,
        pictures: {
          ...state.pictures,
          list: {
            ...state.pictures.list,
            isLoadingData: action.payload,
          },
        },
      };
    case 'NEWS/SET_ARTICLE':
      return {
        ...state,
        articles: {
          ...state.articles,
          selectedItemDetails: action.payload,
        },
      };
    case 'NEWS/RESET_ARTICLE':
      return {
        ...state,
        articles: {
          ...state.articles,
          selectedItemDetails: initialState.articles.selectedItemDetails,
        },
      };
    case 'NEWS/SET_GALLERY':
      return {
        ...state,
        pictures: {
          ...state.pictures,
          selectedItemDetails: action.payload,
        },
      };
    case 'NEWS/RESET_GALLERY':
      return {
        ...state,
        pictures: {
          ...state.pictures,
          selectedItemDetails: initialState.pictures.selectedItemDetails,
        },
      };
    case 'NEWS/SET_VIDEO':
      return {
        ...state,
        videos: {
          ...state.videos,
          selectedItemDetails: action.payload,
        },
      };
    case 'NEWS/RESET_VIDEO':
      return {
        ...state,
        videos: {
          ...state.videos,
          selectedItemDetails: initialState.videos.selectedItemDetails,
        },
      };
    default:
      return state;
  }
};
