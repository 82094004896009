import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { EditorialContentTypes, NewsTypes } from '@app/types/newsTypes';
import { getNewsListItemImageConfig } from '@app/helpers/imageHelper';
import { GalleryIcon } from '@app/components/Icons';
import Image from '@app/components/Image';

type NewsListItemImageProps = React.FC<{
  url?: string;
  description?: string;
  size: number;
  type: NewsTypes | EditorialContentTypes;
}>

const NewsListItemImage: NewsListItemImageProps = ({
  url, description = '', size, type,
}) => {
  const { formatMessage } = useIntl();
  const useVideoIcon = [NewsTypes.VIDEOS, EditorialContentTypes.VIDEO].includes(type);
  const useGalleryIcon = [NewsTypes.PICTURES, EditorialContentTypes.PHOTO].includes(type);

  return (
    <NewsItemImageContainer isGallery={type === NewsTypes.PICTURES} isLoaded={!!url}>
      {url && (
        <NewsItemMainImage
          src={url}
          description={description}
          additionalImageProps={getNewsListItemImageConfig({ url, size })}
          useLazyLoading
        />
      )}
      {useVideoIcon && (
        <NewsVideoIcon
          src="/images/playVideo.svg"
          alt={formatMessage({ id: 'news.playVideo.icon.alt' })}
        />
      )}
      {useGalleryIcon && (<GalleryIcon />)}
    </NewsItemImageContainer>
  );
};

export default NewsListItemImage;

const NewsItemImageContainer = styled.div<{ isGallery: boolean; isLoaded: boolean }>`
  position: relative;
  background-size: 300% 100%;
  background: ${({ isLoaded }): string => (isLoaded ? 'transparent' : '#c9c9c9;')};
  overflow: hidden;
  padding-top: ${({ isGallery }): string => (isGallery ? '105%' : '55%')};
  z-index: 1;
`;

const NewsItemMainImage = styled(Image)`
  transform-origin: 50% 65%;
  transition: transform 2s ease-in-out;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: unset;
  z-index: 10;
`;

export const NewsVideoIcon = styled.img`
  position: absolute;
  left: 20px;
  bottom: 20px;
  height: 20px;

  z-index: 11;

  transform-origin: 50% 65%;
  transition: transform 2s ease-in-out;

  @media ${Devices.mediumMobile} {
    left: 25px;
    height: 34px;
  }
`;
