import React from 'react';

import { NewsTypes } from '@app/types/newsTypes';
import { getVideos } from '@app/store/actions/newsActions';

import { useNewsListDownload } from '../NewsHooks';
import News from '../News';

const Videos = (): React.ReactElement => {
  useNewsListDownload(NewsTypes.VIDEOS);

  return (<News type={NewsTypes.VIDEOS} />);
};
export default Videos;

Videos.serverFetch = getVideos;
