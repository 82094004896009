import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Devices } from '@app/styles';
import { WebPerson } from '@app/types/webTemplateTypes';
import { GA_CATEGORY, GA_EVENT } from '@app/types/googleTypes';

import { pushGAEvent } from '@app/helpers/googleHelpers';
import { getStaticImageConfig } from '@app/helpers/imageHelper';
import { getPersonSchema } from '@app/helpers/structuredMarkupHelpers';

import { useLanguage } from '@app/components/Hooks';
import { SwitchCircleButton } from '@app/components/Buttons';
import { MetaJsonLD } from '@app/components';
import Image from '@app/components/Image';

interface WebPersonElementProps {
  data: WebPerson;
}

const WebPersonElement = ({ data }: WebPersonElementProps): React.ReactElement => {
  const language = useLanguage();
  const [isQuoteOpened, setIsQuoteOpened] = useState(false);
  const toggleQuote = (): void => setIsQuoteOpened(!isQuoteOpened);
  const config = data?.photo?.url?.length
    ? getStaticImageConfig({ url: data?.photo?.url, width: 220 })
    : { src: `${window?.HOST_URL || window?.location?.origin}/images/default-management.jpg` };

  const jsonLD = data ? getPersonSchema({
    personPageUrl: window?.REQUEST_URL || window?.location?.href,
    imageUrl: data.photo.url,
    name: data.firstName,
    surname: data.lastName,
  }) : null;

  return (
    <Container>
      { jsonLD && <MetaJsonLD json={jsonLD} /> }
      <PhotoBlock>
        <Photo
          src={data?.photo?.url?.length ? data?.photo?.url : `${window?.HOST_URL || window?.location?.origin}/images/default-management.jpg`}
          description={data?.photo?.description}
          additionalImageProps={config}
        />
        {data?.quote && (
          <>
            <Quote isOpened={isQuoteOpened}>
              <QuoteText>{data?.quote}</QuoteText>
            </Quote>
            <CircleButton>
              <SwitchCircleButton onClick={toggleQuote} />
            </CircleButton>
          </>
        )}
      </PhotoBlock>
      <Info>
        <Name>{data?.firstName}</Name>
        <Name>{data?.lastName}</Name>
        <RedDivider />
        <Position>{data?.position}</Position>
      </Info>
      {data?.resume && (
        <Resume
          href={data?.resume}
          onClick={(): void => pushGAEvent({
            event: GA_EVENT.CLICK_RESUME_DOWNLOAD,
            category: GA_CATEGORY.ORGANIZATION,
            label: `${data?.firstName}_${data?.lastName}`,
            language,
          })}
        >
          <FormattedMessage id="persons.downloadResume" />
        </Resume>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 130px;
  height: 410px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;

  @media ${Devices.mediumMobile} {
    width: 155px;
    height: 480px;
  }
  @media ${Devices.largeMobile} {
    width: 220px;
    height: 480px;
  }
`;

const Quote = styled.div<{ isOpened: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: ${({ isOpened }): string => (isOpened ? '1' : '0')};;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 1wepx;
  font-weight: bold;
  line-height: 1.83;
  letter-spacing: 0.18px;
  color: #ffffff;
  transition: opacity 0.2s ease-in;

  @media ${Devices.largeMobile} {
    padding: 20px;
    font-size: 16px;
    line-height: 1.63;
    letter-spacing: 0.23px;
  }
`;

const QuoteText = styled.span`
  max-height: 100%;
  text-align: center;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;

  @media ${Devices.mediumMobile} {
    -webkit-line-clamp: 8;
  }
  @media ${Devices.largeMobile} {
    -webkit-line-clamp: 11;
  }
`;


const PhotoBlock = styled.div`
  width: 100%;
  height: 235px;
  overflow: hidden;
  position: relative;

  @media ${Devices.largeMobile} {
    height: 330px;
  }

  @media ${Devices.desktop} {
    &:hover ${Quote} {
      opacity: 1;
    }
  }

`;

const Photo = styled(Image)`
  width: 100%;
`;

const CircleButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  @media ${Devices.desktop} {
    display: none;
  }
`;

const Info = styled.div`
  margin-top: 15px;
  padding: 0 20px;
`;

const Name = styled.h2`
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  color: #000000;
`;

const RedDivider = styled.div`
  margin-top: 5px;
  width: 22px;
  height: 2px;
  background-color: ${(props): string => props.theme.color.harleyDavidsonOrange};
`;

const Position = styled.h3`
  margin-top: 10px;
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: 12px;
  line-height: 1.59;
  text-transform: uppercase;
  color: #000000;
`;

const Resume = styled.a`
  position: absolute;
  bottom: 15px;
  left: 20px;
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: 10px;;
  line-height: 1.9;
  text-decoration: underline;
  color: ${(props): string => props.theme.color.harleyDavidsonOrange};
`;

export default WebPersonElement;
