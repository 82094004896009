import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getResponsiveNewsImageConfig } from '@app/helpers/imageHelper';
import { LatestNewsConfiguration } from '@app/constants/carouselConfigurationsConstants';

import { VideoItem } from '@app/components';
import { useLatestVideosWithGoogleAds } from '@app/components/Hooks';
import { CarouselBase } from './CarouselBase';
import { CarouselTitle } from './CarouselTitle';

export const CarouselLatestVideos = (): React.ReactElement => {
  const latestVideos = useLatestVideosWithGoogleAds();

  return (
    <>
      <CarouselTitle>
        <FormattedMessage id="news.relatedVideos" />
      </CarouselTitle>
      <CarouselBase
        ItemComponent={VideoItem}
        items={latestVideos || []}
        configuration={LatestNewsConfiguration}
        getResponsiveImageConfig={getResponsiveNewsImageConfig}
      />
    </>
  );
};
