import React from 'react';
import styled from 'styled-components';

interface HamburgerButton {
  closeMobileMenu: () => void;
  openMobileMenu: () => void;
  isOpened: boolean;
}

const HamburgerButton = ({ closeMobileMenu, openMobileMenu, isOpened }: HamburgerButton): React.ReactElement => (
  <Hamburger onClick={(): void => (isOpened ? closeMobileMenu() : openMobileMenu())}>
    <HamburgerLine isMenuOpened={isOpened} />
    <HamburgerLine isMenuOpened={isOpened} />
    <HamburgerLine isMenuOpened={isOpened} />
    <HamburgerLine isMenuOpened={isOpened} />
  </Hamburger>
);

const Hamburger = styled.div`
  position: relative;
  height: 25px;
  width: 34px;
  cursor: pointer;
  top: 2px;
`;

const LINE_HEIGHT = 3;
const LINE_MARGIN = 5;
const SHORT_LINE_WIDTH = 70;

const HamburgerLine = styled.span<{ isMenuOpened: boolean }>`
  display: block;
  position: absolute;
  height: ${LINE_HEIGHT}px;
  width: 100%;
  left: 0;
  transition: .25s ease-in-out;
  background-color: black;
  
  &:nth-child(1) {
    top: ${({ isMenuOpened }): string => (isMenuOpened ? `${LINE_HEIGHT + LINE_MARGIN}px` : '0px')};
  }
  
  &:nth-child(1), :nth-child(4) {
    width: ${({ isMenuOpened }): string => (isMenuOpened ? '0%' : `${SHORT_LINE_WIDTH}%`)};
    left: ${({ isMenuOpened }): string => (isMenuOpened ? '50%' : `${(100 - SHORT_LINE_WIDTH) / 2}%`)};
  }

  &:nth-child(2), :nth-child(3) {
    top: ${LINE_HEIGHT + LINE_MARGIN}px;
    width: ${({ isMenuOpened }): string => (isMenuOpened ? '80%' : '100%')};
  }
  
  &:nth-child(2) {
    transform: ${({ isMenuOpened }): string => (isMenuOpened ? 'rotate(45deg)' : 'rotate(0deg)')};
  }
  
  &:nth-child(3) { 
    transform: ${({ isMenuOpened }): string => (isMenuOpened ? 'rotate(-45deg)' : 'rotate(0deg)')};
  }

  &:nth-child(4) {
    top: ${({ isMenuOpened }): string => (isMenuOpened ? `${LINE_HEIGHT + LINE_MARGIN}px` : `${(LINE_HEIGHT + LINE_MARGIN) * 2}px`)};
  }
`;

export default HamburgerButton;
