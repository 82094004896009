import { useContext, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { AppState } from '@app/store/reducers';
import { getTicketingSingle, resetTicketingSingle } from '@app/store/actions/ticketingActions';
import { TicketingSingle } from '@app/types/ticketingTypes';
import { SportEventSchema } from '@app/types/structuredMarkup';
import { getSportEventSchema } from '@app/helpers/structuredMarkupHelpers';
import { useLanguage } from '@app/components/Hooks';
import { MountContext } from '@app/ReactContext';

export function useSingleTickets(): TicketingSingle {
  return useSelector<AppState, TicketingSingle>(
    (state) => state.ticketing.single,
    shallowEqual,
  );
}

export function useTicketingSingleDownload(): void {
  const { isInitialMount } = useContext(MountContext);
  const dispatch = useDispatch();
  const hasItems = !!useSingleTickets();
  const language = useLanguage();

  useEffect(() => {
    (!isInitialMount || !hasItems) && dispatch(getTicketingSingle(language));
    return (): void => { dispatch(resetTicketingSingle()); };
  }, [language]);
}

export function useSportEventJsonLD(): SportEventSchema[] {
  const singleTickets = useSingleTickets();
  const language = useLanguage();

  return Object.values(singleTickets ?? {})
    .reduce((result: SportEventSchema[], tickets) => (
      result.concat(tickets
        .filter((ticket) => ticket.matchTime)
        .map((ticket) => getSportEventSchema(ticket, language)))
    ), []);
}
