import { LocalizedQuestion } from '@app/types/faqQuestionTypes';

export enum QuestionActionTypes {
  SET_QUESTION = 'FAQ_QUESTION/SET_QUESTION',
  RESET_QUESTION = 'FAQ_QUESTION/RESET_QUESTION',
}

export interface SetQuestion {
  type: QuestionActionTypes.SET_QUESTION;
  payload: LocalizedQuestion;
}

export interface ResetQuestion {
  type: QuestionActionTypes.RESET_QUESTION;
}

export type QuestionActionAction = SetQuestion | ResetQuestion;
