import React from 'react';
import styled from 'styled-components';
import { format, isValid, parseISO } from 'date-fns';

import { mapLanguageTypeToDateFnsLocale } from '@app/helpers/localizationHelper';

import { Devices } from '@app/styles';

import { PageWrapper } from '@app/pages/components';
import { Metadata, PlayerMetadata } from '@app/components';
import { ValueAndCaption } from '@app/components/Statistics';
import { ContainerMedium } from '@app/components/WebTemplates/components';
import { ProfileDateFormatMap } from '@app/constants/dateTimeConstants';

import { useLanguage } from '@app/components/Hooks';
import { useHallOfFameDownload } from '@app/pages/HallOfFame/HallOfFameHooks';
import { useHallOfFamePlayerProfile, useHallOfFamePlayerProfileValidation } from './HallOfFamePlayerProfileHooks';

const HallOfFamePlayerProfile: React.FC = () => {
  const language = useLanguage();
  useHallOfFameDownload();
  const validationError = useHallOfFamePlayerProfileValidation();
  const player = useHallOfFamePlayerProfile();

  const parsedBirthDate = parseISO(player?.birthDate ?? '');
  const birthData = isValid(parsedBirthDate) ? format(parsedBirthDate, ProfileDateFormatMap[language], {
    locale: mapLanguageTypeToDateFnsLocale(language),
  }) : '';

  return (
    <PageWrapper error={validationError}>
      {
        !!player && (
          <>
            { !!player?.seo && <Metadata seo={player.seo} /> }
            <PhotoWrapper>
              <Photo src={player.largePhoto.url} alt={player.largePhoto.description} />
              <Background />
              <MetadataContainer>
                <PlayerMetadata
                  firstName={player.firstName}
                  lastName={player.lastName}
                  number={player.number}
                />
                <BirthdayData>
                  <div>{birthData}</div>
                  <BirthPlace>{player.birthPlace}</BirthPlace>
                </BirthdayData>
              </MetadataContainer>
            </PhotoWrapper>
            <ContainerMedium>
              <Biography dangerouslySetInnerHTML={{ __html: player.biography }} />
              <Statistic>
                <RightStatisticBlock>
                  <MatchStatisticWrapper>
                    <ValueAndCaption value={player.totalMatches ?? 0} caption="hallOfFame.totalMatches" />
                  </MatchStatisticWrapper>
                  <ValueAndCaption value={player.totalGoals ?? 0} caption="hallOfFame.totalGoals" />
                </RightStatisticBlock>
                <LeftStatisticBlock>
                  <MatchStatisticWrapper>
                    <ValueAndCaption value={player.serieAMatches ?? 0} caption="hallOfFame.serieAMatches" />
                  </MatchStatisticWrapper>
                  <ValueAndCaption value={player.serieAGoals ?? 0} caption="hallOfFame.serieAGoals" />
                </LeftStatisticBlock>
              </Statistic>
            </ContainerMedium>
          </>
        )
      }
    </PageWrapper>
  );
};

const PhotoWrapper = styled.div`
  max-width: 1280px;
  margin: auto;
  position: relative;
  overflow: hidden;
  height: 430px;

  @media ${Devices.tablet} {
    height: auto;
  }
`;

const Photo = styled.img`
  position: relative;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);

  @media ${Devices.tablet} {
    width: 100%;
    height: auto;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom,rgba(0,0,0,0.3),rgba(0,0,0,0)),linear-gradient(to bottom,rgba(0,0,0,0) 33%,rgba(0,0,0,0.7) 67%);
`;

const MetadataContainer = styled.div`
  position: absolute;
  bottom: 50px;
  width: 100%;
`;

const BirthdayData = styled.div`
  color: white;
  font-size: 18px;
  text-align: center;
  line-height: 1.56;
  margin-top: 20px;
`;

const BirthPlace = styled.div`
  font-weight: bold;
`;

const Biography = styled.div`
  line-height: 1.57;
  letter-spacing: 0.23px;
  text-align: justify;
  margin: 40px 0 50px;
`;

const Statistic = styled.section`
  background-color: white;
  padding: 50px 0;
  display: flex;

  @media ${Devices.tabletLarge} {
    padding: 50px 100px;
  }
`;

const StatisticBlock = styled.div`
  flex-basis: 50%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media ${Devices.tabletLarge} {
    flex-direction: row;
  }
`;

const RightStatisticBlock = styled(StatisticBlock)`
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  padding-right: 0px;

  @media ${Devices.tabletLarge} {
    padding-right: 80px;
  }
`;

const LeftStatisticBlock = styled(StatisticBlock)`
  padding-left: 0;

  @media ${Devices.tabletLarge} {
    padding-left: 80px;
  }
`;

const MatchStatisticWrapper = styled.div`
  margin-bottom: 50px;

  @media ${Devices.tabletLarge} {
    margin-bottom: 0;
  }
`;

export default HallOfFamePlayerProfile;
