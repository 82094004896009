import { UserStateType } from '@app/types/userTypes';
import { AppLanguageTypes } from '@app/types/localizationTypes';

export enum UserActionTypes {
  LOGIN = 'USER/LOGIN',
  LOGOUT = 'USER/LOGOUT',
  SET_USER_COUNTRY = 'USER/SET_USER_COUNTRY',
  SET_BROWSER_LANGUAGE = 'USER/SET_BROWSER_LANGUAGE',
}

export interface LoginInterface {
  type: UserActionTypes.LOGIN;
  payload: UserStateType;
}

export interface LogoutInterface {
  type: UserActionTypes.LOGOUT;
  payload: UserStateType;
}

export interface SetBrowserLanguageInterface {
  type: UserActionTypes.SET_BROWSER_LANGUAGE;
  payload: AppLanguageTypes;
}

export interface SetUserCountryInterface {
  type: UserActionTypes.SET_USER_COUNTRY;
  payload: string;
}

export type UserActions = LoginInterface
  | LogoutInterface
  | SetUserCountryInterface
  | SetBrowserLanguageInterface;
