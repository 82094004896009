import { GAEvent } from '@app/types/googleTypes';

export const getAdUnitId = (adUnit: string): string => (adUnit.startsWith('/') ? adUnit : `/${adUnit}`);

export const pushGAEvent = (analytic: GAEvent): void => {
  try {
    window.dataLayer && window.dataLayer.push(analytic);
    // console.log('pushGAEvent ', analytic);
  } catch (e) {
    console.error('Error send GA event: ', e);
  }
};
