import React, { useState } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';

import { getFullScreenImgConfig } from '@app/helpers/imageHelper';
import { Image as ImageType } from '@app/types/configurationTypes';
import {
  ArticleContentCoverItem, Gallery, EditorialContentTypes, VideoItem,
} from '@app/types/newsTypes';

import { GalleryCarousel, Modal, VideoPlayer } from '@app/components';
import Image from '@app/components/Image';
import { GalleryIcon } from '@app/components/Icons';

import { GalleryModalContainer } from './NewsItemSections';

interface NewsContentCoverProps {
  cover: ArticleContentCoverItem | null;
  image?: ImageType;
}

const NewsContentCover: React.FC<NewsContentCoverProps> = ({ cover, image }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const videoCover = (
    <VideoCoverSection>
      <VideoPlayer video={get(cover, 'value', '') as VideoItem} isEmbed />
    </VideoCoverSection>
  );

  const galleryCover = (
    <>
      <NewsContentCoverGallerySection onClick={(): void => setIsModalOpened(true)}>
        {
          image?.url && (
            <NewsContentCoverImage
              src={image.url}
              description={image.description}
              additionalImageProps={getFullScreenImgConfig(image.url)}
            />
          )
        }
        <GalleryIcon />
      </NewsContentCoverGallerySection>
      <Modal open={isModalOpened} onClose={(): void => setIsModalOpened(false)}>
        <GalleryModalContainer>
          <GalleryCarousel preview={get(cover, 'value', []) as Gallery} />
        </GalleryModalContainer>
      </Modal>
    </>
  );

  const imageCover = (
    image?.url ? (
      <NewsContentCoverImageSection>
        <Image
          src={image.url}
          description={image.description}
          additionalImageProps={getFullScreenImgConfig(image.url)}
        />
      </NewsContentCoverImageSection>
    ) : null
  );

  switch (get(cover, 'type', null)) {
    case EditorialContentTypes.VIDEO:
      return videoCover;
    case EditorialContentTypes.PHOTO:
      return galleryCover;
    default:
      return imageCover;
  }
};

export default NewsContentCover;

const VideoCoverSection = styled.section`
  height: auto;
  max-width: 1280px;
  margin: auto;
  padding: 0;
`;

const NewsContentCoverImage = styled(Image)`
  transition: transform 2000ms ease-out;
`;

const NewsContentCoverImageSection = styled.div`
  max-width: 1280px;
  margin: auto;
  overflow: hidden;
  position: relative;
`;

const NewsContentCoverGallerySection = styled(NewsContentCoverImageSection)`
  cursor: pointer;

  &:hover ${NewsContentCoverImage} {
    transform: scale(1.1,1.1);
  }
`;
