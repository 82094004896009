import { shallowEqual, useSelector } from 'react-redux';

import { Menu } from '@app/types/configurationTypes';
import { useLanguage } from '@app/components/Hooks/LocalizationHooks';
import { AppState } from '@app/store/reducers';

export function useMenu(): Menu {
  const locale = useLanguage();
  return useSelector<AppState, Menu>(
    (state) => state.configuration?.[locale]?.menu ?? null,
    shallowEqual,
  );
}

export const useIsConfigLoaded = (): boolean => (
  useSelector<AppState, boolean>(
    (state) => state.configuration.isConfigurationDownloadCompleted,
    shallowEqual,
  )
);
