import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { CommonButton } from '@app/components/Buttons';

interface LoadMoreButtonProps {
  onClick: () => void;
  isVisible: boolean;
  isLoading: boolean;
}

const LoadMoreButton = React.memo(({
  onClick, isLoading, isVisible,
}: LoadMoreButtonProps): React.ReactElement | null => (isVisible ? (
  <LoadButton onClick={onClick} isLoading={isLoading} disabled={isLoading}>
    <FormattedMessage id={isLoading ? 'buttons.loading.inProgress' : 'buttons.loading'} />
  </LoadButton>
) : null));

export default LoadMoreButton;

const LoadButton = styled(CommonButton)<{ isLoading: boolean }>`
  margin: 40px auto;
  text-transform: uppercase;
  transition: all .5s ease;

  &:disabled {
    background-color: ${(props): string => props.theme.color.dimGray};
    cursor: auto;

    &:hover {
      background-color: ${(props): string => props.theme.color.dimGray};
      color: white;
      border-color: transparent;
    }
  }
`;
