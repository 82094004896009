import * as React from 'react';
import styled from 'styled-components';

import { getResponsiveImageConfig } from '@app/helpers/imageHelper';
import { GetResponsiveImageConfig } from '@app/types/imageTypes';
import { NewsListItems } from '@app/types/newsTypes';
import { Devices } from '@app/styles';
import { GoogleAds } from '@app/components';

const DESKTOP_ITEM_MARGIN = '25px';
const TABLET_ITEM_MARGING = '20px';
const MOBILE_ITEM_MARGIN = '15px';

interface GridProps {
  ItemComponent: React.FunctionComponent<{item: unknown; getResponsiveImageConfig: GetResponsiveImageConfig }>;
  items: NewsListItems;
  itemAdditionalProps?: {
    [properyName: string]: unknown;
  };
}

export const TwoColumnGrid = ({ ItemComponent, items, itemAdditionalProps }: GridProps): React.ReactElement => {
  const getResponsiveImageConfiguration: GetResponsiveImageConfig = (url) => (
    getResponsiveImageConfig(url, { 1024: 335, 376: 560, 0: 335 })
  );

  return (
    <TwoColumnGridContainer>
      {
        items.map((item) => ('adUnit' in item ? (
          <GoogleAds key={item.slotId} {...item} />
        ) : (
          <ItemComponent
            key={item.id}
            item={item}
            {...itemAdditionalProps}
            getResponsiveImageConfig={getResponsiveImageConfiguration}
          />
        )))
      }
    </TwoColumnGridContainer>
  );
};


export const ThreeColumnGrid = ({ ItemComponent, items, itemAdditionalProps }: GridProps): React.ReactElement => {
  const getResponsiveImageConfiguration: GetResponsiveImageConfig = (url) => (
    getResponsiveImageConfig(url, { 1024: 335, 376: 560, 0: 335 })
  );

  console.log('[GRID 3x3] Render items: ', items);
  return (
    <ThreeColumnGridContainer>
      {
        items.map((item) => ('adUnit' in item ? (
          <GoogleAds key={item.slotId} {...item} />
        ) : (
          <ItemComponent
            key={item.id}
            item={item}
            {...itemAdditionalProps}
            getResponsiveImageConfig={getResponsiveImageConfiguration}
          />
        )))
      }
    </ThreeColumnGridContainer>
  );
};

const TwoColumnGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: ${MOBILE_ITEM_MARGIN};

  @media ${Devices.tablet} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${TABLET_ITEM_MARGING};
  }
`;


const ThreeColumnGridContainer = styled(TwoColumnGridContainer)`
  @media ${Devices.desktop} {
   grid-template-columns: repeat(3, 1fr);
   grid-gap: ${DESKTOP_ITEM_MARGIN};
  }
`;

interface TwoThreeColumnGridProps extends GridProps {
  isHomePage?: boolean;
}

export const TwoThreeColumnGrid = ({
  ItemComponent,
  items,
  isHomePage,
  itemAdditionalProps,
}: TwoThreeColumnGridProps): React.ReactElement => {
  const getResponsiveImageConfiguration: GetResponsiveImageConfig = (url) => (
    getResponsiveImageConfig(
      url,
      {
        834: 565, 602: 795, 376: 565, 0: 335,
      },
    )
  );

  return (
    <TwoThreeColumnGridContainer isHomePage={!!isHomePage}>
      {
        items.map((item) => (
          <TwoThreeColumnGridItemContainer key={('adUnit' in item ? item.slotId : item.id)} isHomePage={!!isHomePage}>
            {'adUnit' in item ? (<GoogleAds {...item} />) : (
              <ItemComponent
                item={item}
                {...itemAdditionalProps}
                getResponsiveImageConfig={getResponsiveImageConfiguration}
              />
            )}
          </TwoThreeColumnGridItemContainer>
        ))
      }
    </TwoThreeColumnGridContainer>
  );
};

const TwoThreeColumnGridContainer = styled.div<{ isHomePage: boolean }>`
  display: grid;
  grid-gap: ${MOBILE_ITEM_MARGIN};
  grid-template-columns: repeat(1, minmax(200px, 1fr));

  @media ${Devices.tablet} {
    grid-gap: ${TABLET_ITEM_MARGING};
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${({ isHomePage }): string => (isHomePage ? Devices.desktop : Devices.tabletLarge)} {
    grid-gap: ${DESKTOP_ITEM_MARGIN};
    grid-template-columns: repeat(6, 1fr);
  }
`;

const TwoThreeColumnGridItemContainer = styled.div<{ isHomePage: boolean }>`
  &:nth-child(n + 4) {
    display: ${({ isHomePage }): string => (isHomePage ? 'none' : 'block')};
  }

  @media ${Devices.tablet} {
    &:nth-child(3n + 1) {
      grid-column: auto /span 2;
    }
  }

  @media ${({ isHomePage }): string => (isHomePage ? Devices.desktop : Devices.tabletLarge)} {
    &:nth-child(n + 4) {
      display: block;
    }

    &:nth-child(5n + 1), &:nth-child(5n + 2) {
      grid-column: auto /span 3;
    }

    &:nth-child(5n + 3), &:nth-child(5n + 4), &:nth-child(5n + 5) {
      display: grid;
      grid-column: auto /span 2;
    }
  }
`;

interface OneFourColumnGridProps extends GridProps {
  ItemComponent: React.FunctionComponent<{
    item: unknown;
    isHighlighted?: boolean;
    getResponsiveImageConfig: GetResponsiveImageConfig;
  }>;
}

export const OneFourColumnGrid = ({
  ItemComponent, items, itemAdditionalProps,
}: OneFourColumnGridProps): React.ReactElement => {
  const highlightedItemImgConfigFunc: GetResponsiveImageConfig = (url) => (
    getResponsiveImageConfig(
      url,
      {
        1024: 565, 769: 945, 415: 690, 0: 375,
      },
    )
  );

  const itemImgConfigFunc: GetResponsiveImageConfig = (url) => (
    getResponsiveImageConfig(
      url,
      {
        1024: 275, 801: 465, 602: 375, 415: 560, 0: 375,
      },
    )
  );

  return (
    <OneFourColumnGridContainer>
      {
        items.map((item, index) => ('adUnit' in item ? (
          <GoogleAds {...item} />
        ) : (
          <OneFourColumnGridItemContainer key={item.id}>
            <ItemComponent
              item={item}
              isHighlighted={!index}
              {...itemAdditionalProps}
              getResponsiveImageConfig={!index ? highlightedItemImgConfigFunc : itemImgConfigFunc}
            />
          </OneFourColumnGridItemContainer>
        )))
      }
    </OneFourColumnGridContainer>
  );
};

const OneFourColumnGridContainer = styled.div`
  display: grid;
  grid-gap: ${MOBILE_ITEM_MARGIN};
  grid-template-columns: repeat(1, minmax(200px, 1fr));

  @media ${Devices.tablet} {
    grid-gap: 18px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${Devices.desktop} {
    grid-template-columns: repeat(4, 1fr);
  }

`;

const OneFourColumnGridItemContainer = styled.div`
  &:nth-child(n + 4) {
    display: none;
  }

  @media ${Devices.tablet} {
    &:nth-child(1) {
      grid-column: auto /span 2;
      grid-row: auto /span 2;
    }
  }

  @media ${Devices.desktop} {
    &:nth-child(n + 4) {
      display: block;
    }
  }
`;
