import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Devices, theme } from '@app/styles';
import { MatchTicketsInfo, PackageTicketsInfo, TicketType } from '@app/types/ticketingTypes';
import { BuyTicketsButton, ColorizedButton, ColorizedExternalLink } from '@app/components/Buttons';
import { BellIcon } from '@app/components/Icons';
import { useLanguage } from '@app/components/Hooks';

interface TicketsButtonsProps {
  ticket: MatchTicketsInfo | PackageTicketsInfo;
  ticketType: TicketType;
}

const TicketsButtons = ({ ticket }: TicketsButtonsProps): React.ReactElement => {
  const language = useLanguage();

  return (
    <Buttons>
      {ticket.ticketsActive && (<BuyTicketsButton matchTickets={ticket} />)}
      {!ticket.ticketsActive && ticket.ticketsSoon && (
        <ColorizedButton color="#9d9d9d" invertColor="white" invertBackgroundColor="#9d9d9d">
          <FormattedMessage id="ticketing.comingSoon" />
        </ColorizedButton>
      )}
      {ticket.hospitalityActive && (
        <ColorizedExternalLink url={ticket.hospitalityUrl[language]} color={theme.color.cheninYellow}>
          <FormattedMessage id="ticketing.hospitalityVip" />
        </ColorizedExternalLink>
      )}
      {/** https://netcosports.atlassian.net/browse/ACM-1374 */}
      {undefined && !ticket.ticketsActive && ( // TODO:REVERT
        <ColorizedButton
          color="#ffffff"
          borderColor={theme.color.freeSpeechRed}
          invertColor={theme.color.freeSpeechRed}
        >
          <BellIcon />
          <FormattedMessage id="ticketing.notifyMe" />
        </ColorizedButton>
      )}
      {!!ticket?.informationUrl?.[language] && (
        <ColorizedExternalLink
          url={ticket?.informationUrl?.[language]}
          color="#ffffff"
          borderColor={theme.color.freeSpeechRed}
          invertColor="#ffffff"
          invertBackgroundColor={theme.color.freeSpeechRed}
        >
          <FormattedMessage id="ticketing.info" />
        </ColorizedExternalLink>
      )}
    </Buttons>
  );
};

export default TicketsButtons;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > * {
    width: 100%;
    max-width: 150px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media ${Devices.tablet} {
    flex: 1 1 auto;
    width: auto;
  }

  @media ${Devices.tabletLarge} {
    align-items: flex-end;
  }
`;
