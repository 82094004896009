import React from 'react';

import { WebTemplateContainer } from '@app/components';
import { useHomeLandingDataDownload, useHomeLandingDataHook } from '@app/pages/Home/HomeHooks';
import { HomeBlocks } from '@app/types/homeConfigurationTypes';
import { GraySectionReducePadding } from './HomeStyledComponents';

type HomeLandingProps = React.PropsWithChildren<{
  id: HomeBlocks;
}>

const HomeLanding: React.FC = (props: HomeLandingProps) => {
  useHomeLandingDataDownload(props.id);
  const homeLandingData = useHomeLandingDataHook(props.id);
  return (
    <GraySectionReducePadding>
      <WebTemplateContainer data={[homeLandingData]} />
    </GraySectionReducePadding>
  );
};

export default HomeLanding;
