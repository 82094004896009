import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { WebEditorialDuo } from '@app/types/webTemplateTypes';
import { ContainerLarge } from '@app/components/WebTemplates/components';
import { WebEditorialElement } from '@app/components/WebTemplates/elements';
import { Image } from '@app/components';

interface WebCtaDuoButtonElementProps {
  data: WebEditorialDuo;
}

const WebEditorialDuoElement = ({ data }: WebCtaDuoButtonElementProps): React.ReactElement => (
  <Container isContentFirst={data.isContentFirst}>
    <Image src={data.image.url} description={data.image.description} />
    <WebEditorialElement content={data.content} />
  </Container>
);

export default WebEditorialDuoElement;

const Container = styled(ContainerLarge)<{ isContentFirst: boolean }>`
  display: flex;
  flex-direction: ${({ isContentFirst }): string => (isContentFirst ? 'column-reverse' : 'column')};
  justify-content: center;
  align-items: center;
  padding: 10px;

  & > * {
    flex: 0 0 50%;
    padding: 10px;
  }

  @media ${Devices.tabletLarge} {
    flex-direction: ${({ isContentFirst }): string => (isContentFirst ? 'row-reverse' : 'row')};
  }
`;
