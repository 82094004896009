import { UserActionTypes, UserActions } from '@app/store/actionTypes/userActionTypes';
import { UserLoginInfoType, UserCountryType } from '@app/types/userTypes';
import { AppLanguageTypes } from '@app/types/localizationTypes';
import { detectBrowserLanguage } from '@app/helpers/localizationHelper';

export type UserState = {
  isLoggedIn: boolean;
  userLoginInfo: UserLoginInfoType;
  browserLanguage: AppLanguageTypes;
  country: UserCountryType;
}

const initialState: UserState = {
  isLoggedIn: false,
  userLoginInfo: {},
  browserLanguage: detectBrowserLanguage(window.navigator.language),
  country: 'all',
};

export default (state: UserState = initialState, action: UserActions): UserState => {
  switch (action.type) {
    case UserActionTypes.LOGIN: return {
      ...state,
      isLoggedIn: action.payload.isLoggedIn,
      userLoginInfo: action.payload.userLoginInfo,
    };
    case UserActionTypes.LOGOUT: return {
      ...state,
      isLoggedIn: initialState.isLoggedIn,
      userLoginInfo: initialState.userLoginInfo,
    };
    case UserActionTypes.SET_USER_COUNTRY: return {
      ...state,
      country: action.payload,
    };
    case UserActionTypes.SET_BROWSER_LANGUAGE: return {
      ...state,
      browserLanguage: action.payload,
    };
    default: return { ...state };
  }
};
