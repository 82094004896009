import React from 'react';
import styled from 'styled-components';
import { Devices } from '@app/styles';
import { WebHallOfFameInducteeStat } from '@app/types/webTemplateTypes';

export const HOFInducteeStats = ({ stats }: { stats: WebHallOfFameInducteeStat[] }): React.ReactElement | null => (
  stats?.length ? (
    <Stats>
      {stats.map(({ label, value }) => (
        <Stat key={label}>
          <Value>{value}</Value>
          <Label>{label}</Label>
        </Stat>
      ))}
    </Stats>
  ) : null
);

const Stats = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  gap: 20px;
  width: 100%;
  height: auto;
  min-height: 160px;
  background-color: white;

  @media ${Devices.tabletLarge} {
    gap: 20px;
  }
`;

const Stat = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 40%;
  height: auto;
  padding: 20px;

  @media ${Devices.tabletLarge} {
    width: auto;
  }
`;

const Value = styled.p`
  font-family: MilanTypeRev, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 44px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.03px;
  text-align: center;
  color: #d8be6d;
`;

const Label = styled.p`
  font-family: Raleway, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.01px;
  text-align: center;
`;
