import React from 'react';

import { GoogleAdsOverlayProps } from '@app/types/googleTypes';
import { CloseButton } from '@app/components';
import {
  HelloBar, Section, Background, Text, NoText,
} from './GoogleAdsSections';

const GoogleAdsHelloBar = React.memo(({
  slotId, text, color, background, isOpened, isPreview, onClose,
}: GoogleAdsOverlayProps): React.ReactElement | null => (isOpened ? (
  <HelloBar preview={isPreview}>
    {text ? (<Text dangerouslySetInnerHTML={{ __html: text }} color={color} />) : (<NoText />)}
    <Section id={slotId} />
    <Background color={background} />
    <CloseButton onClick={onClose} size={20} colorInvert />
  </HelloBar>
) : null));

export default GoogleAdsHelloBar;
