import React from 'react';

import { NewsTypes } from '@app/types/newsTypes';
import { getArticles } from '@app/store/actions/newsActions';

import { useNewsListDownload } from '../NewsHooks';
import News from '../News';

const Articles = (): React.ReactElement => {
  useNewsListDownload(NewsTypes.ARTICLES);

  return (<News type={NewsTypes.ARTICLES} />);
};
export default Articles;

Articles.serverFetch = getArticles;
