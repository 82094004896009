import { TeamSquadTypes } from '@app/services/kentico/types/teamsTypes';
import { ConfigurationActions } from '@app/store/actionTypes/configurationActionTypes';
import { LocalizedConfiguration } from '@app/types/configurationTypes';

export interface ConfigurationState extends LocalizedConfiguration {
  isConfigurationDownloadCompleted: boolean;
}

const initialConfiguration = {
  menu: null,
  competitions: {},
  nextMatches: {
    [TeamSquadTypes.MenSquad]: null,
    [TeamSquadTypes.WomenSquad]: null,
    [TeamSquadTypes.PrimaveraSquad]: null,
    [TeamSquadTypes.FuturoSquad]: null,
  },
  header: null,
  footer: null,
  home: {},
  cookie: null,
  errorPages: null,
  partners: null,
  shareIcons: null,
  syncCalendarUrl: '',
  vanityUrls: null,
};

const initialState: ConfigurationState = {
  en: { ...initialConfiguration },
  it: { ...initialConfiguration },
  cn: { ...initialConfiguration },
  isConfigurationDownloadCompleted: false,
};

export default (
  state = initialState,
  action: ConfigurationActions,
): ConfigurationState => {
  switch (action.type) {
    case 'CONFIGURATION/SET_CONFIGURATION':
      return {
        ...state,
        ...action.payload,
        isConfigurationDownloadCompleted: true,
      };
    default:
      return state;
  }
};
