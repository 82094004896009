import { FeedTypes } from '@app/services/opta/constants/feedTypes';
import { sendRequestTyped } from '@app/services/opta/request';
import { getFeedData } from '@app/services/opta/feedService';
import { mapStandings, mapSDStandings } from '@app/helpers/seasonHelpers';
import { StandingsTable } from '@app/types/standingsTypes';
import { getTeamNamesData } from '@app/services/kentico/teamsService';
import { LanguageType } from '@app/types/localizationTypes';

export const getStandingsData = (seasonId: string, optaId: string): Promise<StandingsTable> => (
  getFeedData({
    path: 'competition.php',
    feedType: FeedTypes.standings,
    competition: optaId,
    seasonId,
  }).then((standings) => mapStandings(standings))
);

export const getSDStandingsData = (seasonId: string, language: LanguageType): Promise<StandingsTable> => {
  const standings = sendRequestTyped({
    path: 'soccerdata/standings',
    isOptaSD: true,
    params: {
      _rt: 'b',
      _fmt: 'json',
      live: 'yes',
      type: 'total',
      tmcl: seasonId,
    },
  });

  const teamNamesData = getTeamNamesData(language, false);

  return Promise
    .all([standings, teamNamesData])
    .then((data) => mapSDStandings(data));
};
