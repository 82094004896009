import { SeasonSubMenuId } from '@app/types/configurationTypes';

type SeasonPages = 'schedule' | 'standings';

export const SeasonNavigationTypeMap: {
  [key in SeasonPages]: SeasonSubMenuId;
} = {
  schedule: 'stagione_calendari',
  standings: 'stagione_classifiche',
};

/* eslint-disable @typescript-eslint/camelcase */
export const SeasonCategoryIdToConfigCategoryIdMap = {
  men_squad: 'squad_men_competitions',
  women_squad: 'squad_women_competitions',
  primavera_squad: 'squad_primavera_competitions',
  futuro_squad: 'squad_futuro_competitions',
};
