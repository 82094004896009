import React from 'react';
import styled from 'styled-components';

import { SecondLevelMenu } from '@app/components';
import { getTicketDataForSsr } from '@app/store/actions/ticketingActions';
import { PageValidationWrapper } from '@app/pages/components';
import { Devices } from '@app/styles';
import { TicketsTabs, TicketsCarousel } from './components';
import {
  useSecondLevelMenuItems,
  useTicketingConfigurationDownload,
  useTicketingCarouselDownload,
  useTicketingMultiLangUrl,
  useRouterParamsValidation,
} from './TicketingHooks';

const Ticketing = (): React.ReactElement => {
  useTicketingConfigurationDownload();
  useTicketingCarouselDownload();
  useTicketingMultiLangUrl();
  const secondLevelMenuItems = useSecondLevelMenuItems();
  const validationError = useRouterParamsValidation();

  return (
    <PageValidationWrapper error={validationError}>
      <SecondLevelMenu items={secondLevelMenuItems} />
      <Container>
        <TicketsCarousel />
      </Container>
      <InnerContainer>
        <TicketsTabs />
      </InnerContainer>
    </PageValidationWrapper>
  );
};

Ticketing.serverFetch = [getTicketDataForSsr];

const Container = styled.div`
  background-color: ${(props): string => props.theme.color.whiteSmoke};
  height: 100%;
  max-width: 1280px;
  margin: auto;
`;

const InnerContainer = styled.div`
  max-width: 960px;
  margin: auto;
  padding: 0 20px;
  @media ${Devices.desktop} {
    padding: 0;
  }
`;

export default Ticketing;
