import React from 'react';
import styled from 'styled-components';
import { Devices } from '@app/styles';
import { FormattedMessage } from 'react-intl';

import { GA_CATEGORY, GA_EVENT } from '@app/types/googleTypes';

import { pushGAEvent } from '@app/helpers/googleHelpers';
import { getStaticImageConfig } from '@app/helpers/imageHelper';

import { Metadata, PlayerMetadata } from '@app/components';
import { CommonButton } from '@app/components/Buttons';

import { useLanguage } from '@app/components/Hooks';
import { usePlayer } from '@app/pages/PlayerProfile/PlayersHooks';
import { usePhotoLarge } from '@app/pages/Teams/TeamsHooks';

const PlayerInfo = (): React.ReactElement => {
  const player = usePlayer();
  const language = useLanguage();
  const photoLarge = usePhotoLarge(player);

  return (
    <Container>
      { !!player?.seo && <Metadata seo={{ ...player.seo, socialType: 'profile' }} /> }
      <Text>
        <InfoSmall>
          <PlayerMetadata
            lastName={player?.lastName ?? ''}
            firstName={player?.firstName ?? ''}
            number={(player?.number ?? '').toString()}
          />
        </InfoSmall>
        <BuyJerseyWrapper>
          <CommonButton
            as="a"
            target="_blank"
            href={player?.storeUrl ?? ''}
            onClick={(): void => pushGAEvent({
              event: GA_EVENT.CLICK_BUY_JERSEY,
              category: GA_CATEGORY.PLAYER_CARD,
              label: `${player?.firstName}_${player?.lastName}`,
              language,
            })}
          >
            <FormattedMessage id="player.info.buyJersey" values={{ player: player?.lastName ?? '' }} />
          </CommonButton>
        </BuyJerseyWrapper>
      </Text>
      <Photo
        {...getStaticImageConfig({ url: photoLarge, height: 498 })}
        alt={player?.photoLarge?.description}
      />
      <Gradient />
    </Container>
  );
};

const Container = styled.div`
  background-color: #1d2022;
  background-image: url('/images/team/player-profile-background.png');
  background-size: auto;
  background-position: 50% 0%;
  width: 100%;
  height: 498px;
  position: relative;
  margin: 0;
  color: #ffffff;
  letter-spacing: 0.21px;
  overflow: hidden;

  @media ${Devices.desktopSmall} {
    background-size: contain;
    background-position: 0% 0%;
    width: 1280px;
  }
`;

const Photo = styled.img`
  position: absolute;
  z-index: 5;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  bottom: 0;
  height: 100%;
  overflow: hidden;
`;

const Gradient = styled.div`
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 28%, #000000 83%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

const Text = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  z-index: 15;
`;

const InfoSmall = styled.h1`
  position: absolute;
  top: 337px;
  left: 0;
  width: 100%;
`;

const BuyJerseyWrapper = styled.div`
  position: absolute;
  top: 408px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export default PlayerInfo;
