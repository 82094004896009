import format from 'date-fns/format';
import { utcToZonedTime } from 'date-fns-tz';

import * as Translations from '@app/locales';

import AppRoutes from '@app/constants/routes';
import { AppLanguages, KenticoLangToLangCodeMap } from '@app/constants/localizationConstants';
import { NewsTypeMap, NewsNavigationTypeMap } from '@app/constants/newsConstants';
import { convertWebGoogleAds } from '@app/services/kentico/converters/webTemplateConverter';

import {
  ArticleContentCoverItem,
  ArticleItem,
  ArticleModularContentItem,
  BaseNewsData,
  Gallery,
  NewsList,
  VideoItem,
  GalleryImage,
  LocalizedArticleItem,
  LocalizedVideoItem,
  LocalizedGalleryItem,
  ArticleContentType,
  EditorialContentTypes,
  ArticleModularValueType,
} from '@app/types/newsTypes';
import { WebPageSeparatorSizeType } from '@app/types/webTemplateTypes';
import { KenticoItem, SystemTypes, systemTypes } from '@app/services/kentico/types/responseTypes';
import { BaseNewsData as BaseNewsKenticoData } from '@app/services/kentico/types/newsTypes';
import { LocalizedCategoryMenu, NewsSubMenuId } from '@app/types/configurationTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { RoutePath } from '@app/types/routerTypes';
import { AppState } from '@app/store/reducers';

import { mapImageData, mapSeoData } from '@app/helpers/configurationHelpers';

const NewsSystemTypeToDataKey: {
  [key in SystemTypes]?: string;
} = {
  [systemTypes.video]: 'videos',
  [systemTypes.news]: 'news',
  [systemTypes.photoGallery]: 'photo_gallery',
};

export const dispatchNewsPremiumClickEvent = (redirectUrl: string, isPrivate?: boolean): void => {
  window.dispatchEvent( // eslint-disable-next-line no-undef
    new CustomEvent('showPremiumModal', { detail: { redirectUrl, isPrivate } }),
  );
};

export function getBaseNewsData(data: KenticoItem<BaseNewsKenticoData>): BaseNewsData {
  const type = NewsSystemTypeToDataKey[data?.system?.type] ?? '';

  return {
    urlSlug: data?.elements?.url?.value ?? '',
    publicationDate: format(
      utcToZonedTime(data?.elements?.publication_date?.value ?? 0, 'UTC'), 'yyyy-MM-dd',
    ),
    title: data?.elements?.title?.value ?? '',
    categoryName: (data?.elements[type]?.value[0]?.name ?? '').toLowerCase(),
    categoryCode: (data?.elements[type]?.value[0]?.codename ?? '').toLowerCase(),
    codeName: data?.system?.codename ?? '',
    id: data?.system?.id ?? '',
    type: data?.system?.type as unknown as EditorialContentTypes,
    isPremium: data?.elements?.web_premium?.value[0]?.codename === 'premium',
  };
}
export function getNewsPathKey(type: EditorialContentTypes): RoutePath {
  switch (type) {
    case EditorialContentTypes.NEWS: return AppRoutes.ArticleLanding.path;
    case EditorialContentTypes.VIDEO: return AppRoutes.VideoLanding.path;
    default: return AppRoutes.GalleryLanding.path;
  }
}

export function mapNewsItems(newsData): NewsList {
  return {
    hasMoreData: Boolean(newsData?.pagination?.next_page) ?? false,
    items: (newsData?.items ?? []).map((item) => ({
      ...getBaseNewsData(item),
      image: item?.elements?.main_image_horizontal?.value[0]?.url
        ? mapImageData(item?.elements?.main_image_horizontal)
        : mapImageData(item?.elements?.main_image_vertical),
    })),
  };
}

export const mapGalleryImages = (data): GalleryImage[] => (data?.elements?.photos?.value ?? [])
  .map(({ url, name, description }): GalleryImage => ({
    url,
    description,
    name,
  }));

export function mapGallery(data): Gallery {
  return {
    ...getBaseNewsData(data),
    photos: mapGalleryImages(data),
    image: mapImageData(data?.elements?.main_image_vertical),
    ...mapSeoData(data?.elements, KenticoLangToLangCodeMap[data?.system?.language], true),
  };
}

export function mapVideoItem(data): VideoItem {
  const item = data?.item ?? data?.items[0];
  return {
    ...getBaseNewsData(item),
    videoId: item?.elements?.dailymotion_id?.value ?? '',
    tencentVideoId: item?.elements?.tencent_video_id?.value ?? '',
    subTitle: item?.elements?.subtitle?.value ?? '',
    shareUrl: item?.elements?.share_url?.value ?? '',
    image: item?.elements?.main_image_horizontal?.value[0]?.url
      ? mapImageData(item?.elements?.main_image_horizontal)
      : mapImageData(item?.elements?.main_image_vertical),
    ...mapSeoData(item?.elements, KenticoLangToLangCodeMap[data?.system?.language], true),
  };
}

function mapArticleItemModularContentValue(data, type, id): ArticleModularValueType {
  const modularContent = data?.modular_content[id] ?? {};
  const elements = modularContent.elements ?? {};
  const mainImageWeb = elements.main_image_web?.value[0]?.url && mapImageData(elements.main_image_web);
  const mainImageResponsive = elements.main_image_horizontal?.value[0]?.url
    ? mapImageData(elements.main_image_horizontal)
    : mapImageData(elements.main_image_vertical);

  switch (type) {
    case ArticleContentType.NEWS:
      return {
        ...getBaseNewsData(modularContent),
        image: mainImageWeb ?? mainImageResponsive,
      };
    case ArticleContentType.VIDEO:
      return mapVideoItem({ item: modularContent });
    case ArticleContentType.PHOTO:
      return {
        ...mapGallery(modularContent),
        mainImage: mapImageData(elements.main_image_vertical),
      };
    case ArticleContentType.SEPARATOR:
      return (elements?.size?.value?.[0]?.codename ?? WebPageSeparatorSizeType.Full) as WebPageSeparatorSizeType;
    case ArticleContentType.EMBEDDED:
    case ArticleContentType.EMBEDDED_OVERLAY:
    case ArticleContentType.EMBEDDED_ONETRUST:
      /** We should encode embedded data to base64 string.
       * window.__PRELOADED_STATE__ on SSR is broken in case we place <iframe> and other embedded content in STORE */
      return Buffer.from(elements?.html?.value ?? '').toString('base64');
    case ArticleContentType.GOOGLE_ADS:
      return convertWebGoogleAds(modularContent);
    default:
      return '';
  }
}

export function mapEditorialModularContent(data, content): { [codeName: string]: ArticleModularContentItem } {
  return content
    .reduce((accumulator, id) => {
      const type = data?.modular_content[id]?.system?.type ?? '';
      accumulator[id] = {
        value: mapArticleItemModularContentValue(data, type, id),
        type,
      };
      return accumulator;
    }, {});
}

export function mapEditorialContent(content, modularContent): ArticleModularContentItem[] {
  return content
    .split(/(<\s*object[^>]*>(.*?)<\s*\/\s*object>)/) // TODO: rework regex to avoid usage of filter on next line
    .filter((content) => content) // We filter here content which is empty string
    .map((content) => {
      const codeName = /data-codename="(.*?)"/g.exec(content);
      return codeName
        ? modularContent?.[codeName[1]] ?? {}
        : {
          type: ArticleContentType.TEXT,
          value: content,
        };
    });
}

function mapArticleItemContent(data): ArticleModularContentItem[] {
  const item = data?.item ?? data?.items[0];
  const modularContent = mapEditorialModularContent(data, item?.elements?.content?.modular_content ?? []);
  return mapEditorialContent(item?.elements?.content?.value ?? '', modularContent);
}

function mapArticleItemCover(data): ArticleContentCoverItem | null {
  const item = data?.item ?? data?.items[0];
  const video = item?.elements?.video?.value[0];
  const gallery = item?.elements?.photo_gallery?.value[0];

  // Kentico CMS allow set Video and Gallery as top element at the same time
  // High Priority is Video
  if (video) {
    return {
      type: EditorialContentTypes.VIDEO,
      value: mapVideoItem({ item: data?.modular_content?.[video] }),
    };
  }
  // Low Priority is Gallery
  if (gallery) {
    return {
      type: EditorialContentTypes.PHOTO,
      value: mapGallery(data?.modular_content?.[gallery] ?? null),
    };
  }

  return null;
}

export function mapArticleItem(data): ArticleItem {
  const item = data?.item ?? data?.items[0];
  const mainImageWeb = item?.elements?.main_image_web?.value[0]?.url && mapImageData(item?.elements?.main_image_web);
  const mainImageResponsive = item?.elements?.main_image_horizontal?.value[0]?.url
    ? mapImageData(item?.elements?.main_image_horizontal)
    : mapImageData(item?.elements?.main_image_vertical);

  return {
    ...getBaseNewsData(item),
    image: mainImageWeb ?? mainImageResponsive,
    cover: mapArticleItemCover(data),
    content: mapArticleItemContent(data),
    shareUrl: item?.elements?.share_url?.value ?? '',
    subTitle: item?.elements?.subtitle?.value ?? '',
    ...mapSeoData(item?.elements, KenticoLangToLangCodeMap[item?.system?.language], true),
  };
}

type GetCategoryUrl= (params: {
  state: AppState;
  newsItem?: BaseNewsData | null | undefined;
  language: LanguageType;
}) => string;

export const getCategoryUrl: GetCategoryUrl = ({ state, newsItem, language }): string => {
  const type = newsItem?.type ?? '';
  const newsType = Object.entries(NewsTypeMap)
    .find((newsType) => type === newsType[1])?.[0] ?? '';

  const subMenuId = NewsNavigationTypeMap[newsType];
  const categoryCode = newsItem?.categoryCode ?? '';
  const categories = state.configuration[language]?.menu?.web_news?.navigation[subMenuId]?.categories;
  const category = (categories && categories.find((menuItem) => menuItem.value === categoryCode));

  return category?.url ?? Translations[language]['news.landingThirdLevelUrl'];
};

type IsTheSameNewsDetailsPageFunc = (params: {
  newsItem: LocalizedArticleItem | LocalizedVideoItem | LocalizedGalleryItem | null;
  publicationDate: string;
  urlSlug: string;
  categoryName: string;
  state: AppState;
  language: LanguageType;
}) => boolean

export const isTheSameNewsLandingPage: IsTheSameNewsDetailsPageFunc = ({
  newsItem, publicationDate, urlSlug, categoryName, state, language,
}) => {
  const isCorrectCategory = getCategoryUrl({
    state,
    newsItem: newsItem?.[language],
    language,
  }) === categoryName;

  return (
    AppLanguages.map((lang) => newsItem?.[lang]?.urlSlug).includes(urlSlug)
    && AppLanguages.map((lang) => newsItem?.[lang]?.publicationDate).includes(publicationDate) && isCorrectCategory
  );
};

export const getNewsCategories = (state: AppState, newsType: NewsSubMenuId): LocalizedCategoryMenu => (
  AppLanguages.reduce((acc, language) => {
    acc[language] = state.configuration[language]?.menu?.web_news?.navigation[newsType]?.categories ?? [];
    return acc;
  }, {} as LocalizedCategoryMenu)
);
