import React from 'react';
import styled from 'styled-components';

const SearchSubmit = React.memo((): React.ReactElement => (
  <SubmitButton>
    <SubmitIcon viewBox="0 0 25 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.731 9.308 16.667.361c-.353-.466-1.078-.466-1.523-.084-.447.382-.48 1.06-.09 1.466l6.502 7.204H1.076C.48 8.947 0 9.417 0 9.999c0 .582.48 1.053 1.075 1.053h20.48l-6.501 7.203c-.427.403-.307 1.096.14 1.479.448.382 1.122.355 1.473-.097l8.064-8.947c.216-.228.256-.477.269-.69-.021-.225-.116-.523-.269-.692z"
        fillRule="nonzero"
      />
    </SubmitIcon>
  </SubmitButton>
));

export default SearchSubmit;

const SubmitIcon = styled.svg`
  width: 25px;
  height: 20px;

  & path {
    fill: #D50028;
    transition: 0.3s all linear;
  }
`;

const SubmitButton = styled.button`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;

  width: 40px;
  height: 35px;

  border: none;
  background: none;
  transition: 0.3s all linear;

  -webkit-animation: fade-in-fwd 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-fwd 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation-delay: .18s;

  &:hover {
    cursor: pointer;

    & ${SubmitIcon} path {
      fill: white;
      transition: 0.3s all linear;
    }
  }

  &:disabled,
  &:disabled:hover {
    cursor: not-allowed;

    & ${SubmitIcon} path {
      fill: rgba(213, 0, 40, 0.44);
      transition: 0.3s all linear;
    }
  }
`;
