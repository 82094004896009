import { Locale } from 'date-fns';
import { enUS, it } from 'date-fns/locale';
import trimEnd from 'lodash/trimEnd';

import { NavigationLinkParams } from '@app/types/routerTypes';
import { AppLanguageTypes } from '@app/types/localizationTypes';
import * as Translations from '@app/locales';

export function localizeRouteKey({ pathKey, pathParams }: NavigationLinkParams, locale): string {
  const formattedPath = Translations[locale]?.[pathKey] || '';
  const formattedPathWithParams = pathParams
    ? Object
      .entries(pathParams)
      .reduce((path, entry) => path
        .replace(new RegExp(`(:${entry[0]})(\\?)?(\\(.*\\))?`, 'g'), entry[1]), formattedPath)
    : formattedPath;

  return `/${locale}${trimEnd(formattedPathWithParams, '/')}`;
}

export const mapLanguageTypeToDateFnsLocale = (languageType: AppLanguageTypes): Locale => {
  switch (languageType) {
    case AppLanguageTypes.EN: return enUS;
    case AppLanguageTypes.IT: return it;
    default: return enUS;
  }
};

export const detectBrowserLanguage = (browserLanguage = ''): AppLanguageTypes => {
  const language = browserLanguage.toLowerCase();

  switch (true) {
    case language.toLowerCase().includes(AppLanguageTypes.IT): return AppLanguageTypes.IT;
    case language.toLowerCase().includes(AppLanguageTypes.CN): return AppLanguageTypes.CN;
    default: return AppLanguageTypes.EN;
  }
};
