import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useLanguage } from '@app/components/Hooks';
import styled from 'styled-components';
import { Devices } from '@app/styles';
import { ExpandArrow } from '@app/components/Icons';
import * as Translations from '@app/locales';

interface Props {
  name: string;
  options: (string | number)[];
}

export const HOFFilter = ({ name, options }: Props): React.ReactElement => {
  const { pathname, search } = useLocation();
  const params = new URLSearchParams(search);
  const history = useHistory();
  const language = useLanguage();
  const localeKey = name === 'position'
    ? 'hof.inductee.position'
    : 'hof.inductee.year';

  const onChange = (option = 'all'): void => {
    if (option === 'all') {
      params.delete(name);
    } else {
      params.set(name, option);
    }

    history.push({ pathname, search: `?${params.toString()}` });
  };

  return (
    <Filter>
      <select
        defaultValue={params.get(name) || 'all'}
        onChange={(e): void => onChange && onChange(e.target.value)}
      >
        <option value="all">
          {Translations[language]?.[`${localeKey}.all`]}
        </option>
        {options?.map((option) => (
          <option value={option} key={option}>
            {name === 'position' ? Translations[language]?.[`${localeKey}.${option}`] : option}
          </option>
        ))}
      </select>
      <ExpandArrow />
    </Filter>
  );
};

const Filter = styled.div`
  position: relative;
  width: 100%;
  height: 30px;
  background: white;

  @media ${Devices.largeMobile} {
    width: auto;
    min-width: 180px;
  }

  & > select {
    z-index: 1;
  }

  & > svg {
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 0;

    & #icon {
      stroke: #c9240d;
    }
  }

  & > select {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 20px 0 5px;
    border-radius: 2px;
    border: solid 1px #dfdbdb;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: MilanTypeRev, Helvetica Neue, Helvetica, Arial, sans-serif;
    transition: all .2s ease-in-out;

    &:hover {
      cursor: pointer;
      color: #c9240d;
      border: solid 1px #c9240d;
      transition: all .2s ease-in-out;
    }
  }
`;
