import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

export const FieldInsideImage = (): React.ReactElement => {
  const { formatMessage } = useIntl();
  return (
    <FieldImage src="/images/team/surface@3x.png" alt={formatMessage({ id: 'player.goalsFromInsideBox.alt' })} />
  );
};

export const FieldOutsideImage = (): React.ReactElement => {
  const { formatMessage } = useIntl();
  return (
    <FieldImage src="/images/team/field@3x.png" alt={formatMessage({ id: 'player.goalsFromOutsideBox.alt' })} />
  );
};

export const YellowCardImage = (): React.ReactElement => {
  const { formatMessage } = useIntl();
  return (
    <img src="/images/team/yellow-card.svg" alt={formatMessage({ id: 'player.yellowCard.alt' })} />
  );
};

export const RedCardImage = (): React.ReactElement => {
  const { formatMessage } = useIntl();
  return (
    <img src="/images/team/red-card.svg" alt={formatMessage({ id: 'player.redCard.alt' })} />
  );
};

const FieldImage = styled.img`
  width: 29px;
`;
