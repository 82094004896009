import * as React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { NavigationLinkParams } from '@app/types/routerTypes';
import { localizeRouteKey } from '@app/helpers/localizationHelper';

export interface LocalizedLinkProps extends NavigationLinkParams {
  className?: string; // passed by styled component automatically
  onClick?: (event?) => void;
  isNotFollowed?: boolean;
  isTab?: boolean;
}

const LocalizedLink = ({
  pathKey, pathParams, children, className, onClick, isNotFollowed, isTab = false,
}: React.PropsWithChildren<LocalizedLinkProps>): React.ReactElement => {
  const { locale } = useIntl();
  const rel = isNotFollowed && 'nofollow';

  return (
    <Link
      to={{
        pathname: localizeRouteKey({ pathKey, pathParams }, locale),
        state: { params: { isTab } },
      }}
      className={className}
      onClick={onClick}
      {...rel}
    >
      { children }
    </Link>
  );
};

export default LocalizedLink;
