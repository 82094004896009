import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Devices } from '@app/styles';
import { TicketsPartner, WebTicketsPartners } from '@app/types/webTemplateTypes';
import { getResponsiveImageConfig } from '@app/helpers/imageHelper';
import { ContainerMedium } from '../components';

interface WebTicketsPartnerElementProps {
  data?: TicketsPartner;
}

const WebTicketsPartnerElement = ({ data }: WebTicketsPartnerElementProps): React.ReactElement => (
  <Partner
    href={data?.url}
    target="_blank"
    rel="noopener noreferrer"
  >
    <Logo
      {...getResponsiveImageConfig(data?.logo?.url ?? '', { 1024: 225, 600: 135, 0: 110 })}
      alt={data?.logo?.description}
    />
  </Partner>
);

interface WebTicketsPartnersElementProps {
  data: WebTicketsPartners;
}

const WebTicketsPartnersElement = ({ data }: WebTicketsPartnersElementProps): React.ReactElement => (
  <ContainerMedium>
    <Title><FormattedMessage id="ticketing.partners" /></Title>
    <Container>
      <WebTicketsPartnerElement data={data?.partner1} />
      <WebTicketsPartnerElement data={data?.partner2} />
      <WebTicketsPartnerElement data={data?.partner3} />
      <WebTicketsPartnerElement data={data?.partner4} />
    </Container>
  </ContainerMedium>
);

const Title = styled.h2`
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.13px;
  color: #000000;
  text-align: center;
  margin: 20px 0 25px;

  @media ${Devices.desktop} {
    margin: 50px 0 30px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media ${Devices.tablet} {
    flex-wrap: nowrap;
  }
`;

const Partner = styled.a`
  width: calc(50% - 10px);
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  margin-right: 20px;
  margin-bottom: 20px;

  &:nth-of-type(2n) {
    margin-right: 0;
  }

  @media ${Devices.tablet} {
    width: 25%;
    height: 115px;
    &:nth-of-type(2n) {
      margin-right: 20px;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }

  @media ${Devices.desktop} {
    height: 140px;
  }
`;

const Logo = styled.img`
  max-width: 100%;
`;

export default WebTicketsPartnersElement;
