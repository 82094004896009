import sortedUniq from 'lodash/sortedUniq';
import trimEnd from 'lodash/trimEnd';

import {
  ImageConfiguration,
  GetStaticImageConfig,
  GetResponsiveImageConfig,
  ResponsiveImageWidthMapType,
  GetResponsiveWidthImageConfig,
} from '@app/types/imageTypes';
import {
  NewsListItemImageConfigType,
} from '@app/types/newsTypes';

export const getResponsiveImageConfig: GetResponsiveWidthImageConfig = (url, screeWidthToImageWidthMap) => {
  if (url.includes('.gif')) {
    return {
      src: url,
      srcSet: '',
      sizes: '',
    };
  }
  const src = `${url}?auto=format`;
  const widthList = Object.values<number>(screeWidthToImageWidthMap);

  const fullwidthList = widthList
    .reduce((acc, width) => {
      acc.push(width);
      acc.push(width * 2);
      acc.push(width * 3);
      return acc;
    }, [] as number[])
    .sort((width1, width2) => width1 - width2);

  const srcSet = sortedUniq(fullwidthList).reduce((acc, width) => (
    `${acc} ${url}?w=${width}&auto=format ${width}w, `
  ), '');

  const sizes = Object.entries(screeWidthToImageWidthMap)
    .sort(([screenWidth1], [screenWidth2]) => Number(screenWidth2) - Number(screenWidth1))
    .reduce((acc, [minWidth, width]) => (
      `${acc}(min-width: ${minWidth}px) ${width}px, `
    ), '');

  return {
    src,
    srcSet: trimEnd(srcSet, ', '),
    sizes: trimEnd(sizes, ', '),
  };
};

export const getFullScreenImgConfig: GetResponsiveImageConfig = (url) => (
  getResponsiveImageConfig(
    url,
    {
      1025: 1280, 769: 1024, 376: 768, 0: 375,
    },
  )
);

export const getStaticImageConfig: GetStaticImageConfig = ({ url, width, height }) => ({
  src: `${url}&auto=format`,
  srcSet: `
    ${url}?w=${width}&h=${height}&dpr=1&auto=format,
    ${url}?w=${width}&h=${height}&dpr=2&auto=format 2x,
    ${url}?w=${width}&h=${height}&dpr=3&auto=format 3x
  `,
});

export const getStaticImageConfig3x: GetStaticImageConfig = ({ url }) => ({
  src: `${url}?auto=format`,
  srcSet: `
    ${url}?w=1&dpr=3&auto=format 3x,
    ${url}?w=0.5&dpr=2&auto=format 3x,
    ${url}?w=0.34&dpr=1&auto=format
  `,
  sizes: `
    (-webkit-min-device-pixel-ratio: 1) 3x
  `,
});

export const getGalleryPreviewConfig: GetResponsiveImageConfig = (url) => (
  getResponsiveImageConfig(url, { 602: 445, 376: 285, 0: 145 })
);

export const getResponsiveNewsImageConfig: GetResponsiveImageConfig = (url) => (
  getResponsiveImageConfig(url, { 521: 325, 361: 485, 0: 325 })
);

const getNewsListItemImagesMap = (size: number): ResponsiveImageWidthMapType => {
  switch (size) {
    case 4: case 8: // GRID 2x2
      return {
        1024: 335, 376: 560, 0: 335,
      };
    case 5: case 10: // GRID 2x3
      return {
        834: 565, 602: 795, 376: 565, 0: 335,
      };
    default: case 6: case 12: // GRID 3x3
      return {
        1024: 335, 376: 560, 0: 335,
      };
  }
};

export const getNewsListItemImageConfig = ({ url, size }: NewsListItemImageConfigType): ImageConfiguration => (
  getResponsiveImageConfig(url, getNewsListItemImagesMap(size))
);
