import { encodeQueryData } from '../helpers/requestHelpers';
import { KenticoElement, KenticoResponse } from './types/responseTypes';
import { RequestInterface } from './types/requestTypes';

/** Kentico API Configuration */
const API_ACCOUNT_KEY = process.env.API === 'staging'
  ? 'b5a85986-2580-40e6-bd1d-e02ccdd28ea5' // DEV access key
  : '8cbe5d4c-a2db-44e4-97be-7656469b56e8'; // PROD access key
const API_SOURCE_URL = process.env.API === 'staging'
  ? 'https://acmilan-api-integ.netcosports.com/kp/blind/items' // DEV source url
  : 'https://acmilan-api-prod.netcosports.com/kp/blind/items'; // PROD source url
const API_CDN_SOURCE_URL = process.env.API === 'staging'
  ? 'https://cdn-acmilan-api-integ.netcosports.com/kp/blind/items' // DEV source url
  : 'https://cdn-acmilan-api-prod.netcosports.com/kp/blind/items'; // PROD source url
const isUseCDN = true;

async function sendRequestInternal<T>(request: RequestInterface): Promise<T> {
  const { method, path, params } = request;
  const query = params ? `?${encodeQueryData(params)}` : '';

  const response = await fetch(
    `${API_SOURCE_URL}${path || ''}${query}`,
    {
      method: method || 'GET',
      headers: {
        'X-KP-API-Key': API_ACCOUNT_KEY,
      },
    },
  );

  if (response.ok) return response.json();

  throw Error(`Request rejected with status ${response.status}. ${response.statusText}`);
}

async function sendCDNRequestInternal<T>(request: RequestInterface): Promise<T> {
  const { method, path, params } = request;
  const query = params ? `?${encodeQueryData(params)}` : '';

  const response = await fetch(
    `${API_CDN_SOURCE_URL}${path || ''}${query}`,
    {
      method: method || 'GET',
      headers: {
        'X-KP-API-Key': API_ACCOUNT_KEY,
      },
    },
  );

  if (response.ok) return response.json();

  throw Error(`CDN Request rejected with status ${response.status}. ${response.statusText}`);
}

export async function sendCDNRequestTyped<T>(request: RequestInterface): Promise<T> {
  return sendCDNRequestInternal<T>(request);
}

export async function sendLocalServerRequest<T>(request: RequestInterface): Promise<T> {
  const { method, path, params } = request;
  const query = params ? `?${encodeQueryData(params)}` : '';

  const response = await fetch(
    `${path || ''}${query}`,
    {
      method: method || 'GET',
    },
  );

  if (response.ok) return response.json();

  throw Error(`Request rejected with status ${response.status}. ${response.statusText}`);
}

export default async function sendRequest(request: RequestInterface): Promise<{ [propName: string]: unknown }> {
  return isUseCDN
    ? sendCDNRequestInternal<{ [propName: string]: unknown }>(request)
    : sendRequestInternal<{ [propName: string]: unknown }>(request);
}

export async function sendRequestTyped<T extends KenticoElement, S = unknown>(request: RequestInterface):
  Promise<KenticoResponse<T, S>> {
  return isUseCDN
    ? sendCDNRequestInternal<KenticoResponse<T, S>>(request)
    : sendRequestInternal<KenticoResponse<T, S>>(request);
}
