import React from 'react';
import styled from 'styled-components';
import Carousel, { ResponsiveType } from 'react-multi-carousel';
import { ArrowProps } from 'react-multi-carousel/lib/types';

import { GetResponsiveImageConfig } from '@app/types/imageTypes';
import { NewsListItems } from '@app/types/newsTypes';
import { ArrowButtonLeft, ArrowButtonRight } from '@app/components/Buttons';

import 'react-multi-carousel/lib/styles.css';
import { GoogleAds } from '@app/components';

interface CustomLeftArrowProps extends ArrowProps {
  mediaQueryMinWidth: number;
}

const CustomLeftArrow = ({ onClick, mediaQueryMinWidth }: CustomLeftArrowProps): React.ReactElement => (
  <ArrowLeftContainer mediaQueryMinWidth={mediaQueryMinWidth}>
    <ArrowButtonLeft onClick={(): void => onClick && onClick()} />
  </ArrowLeftContainer>
);

const CustomRightArrow = ({ onClick, mediaQueryMinWidth }: CustomLeftArrowProps): React.ReactElement => (
  <ArrowRightContainer mediaQueryMinWidth={mediaQueryMinWidth}>
    <ArrowButtonRight onClick={(): void => onClick && onClick()} />
  </ArrowRightContainer>
);

interface CarouselItemsProps {
  ItemComponent: React.FunctionComponent<{ item: unknown; getResponsiveImageConfig: GetResponsiveImageConfig }>;
  items: NewsListItems;
  configuration: ResponsiveType;
  getResponsiveImageConfig: GetResponsiveImageConfig;
}

export const CarouselBase = ({
  ItemComponent,
  items, configuration,
  getResponsiveImageConfig,
}: CarouselItemsProps): React.ReactElement => {
  const listOfMaxWidthOfPartialVisibility = Object
    .values(configuration)
    .filter(({ partialVisibilityGutter }) => partialVisibilityGutter)
    .map(({ breakpoint }) => breakpoint.max);

  const maxWidthOfPartialVisibility = Math.max(...listOfMaxWidthOfPartialVisibility);

  return (
    <Carousel
      swipeable
      partialVisible
      responsive={configuration}
      infinite={false}
      keyBoardControl={false}
      ssr
      customLeftArrow={<CustomLeftArrow mediaQueryMinWidth={maxWidthOfPartialVisibility} />}
      customRightArrow={<CustomRightArrow mediaQueryMinWidth={maxWidthOfPartialVisibility} />}
    >
      {
        items.map((item) => (
          <CarouselItemContainer key={('adUnit' in item ? item.slotId : item.id)} mediaQueryMinWidth={maxWidthOfPartialVisibility}>
            {'adUnit' in item ? (
              <GoogleAds {...item} />
            ) : (
              <ItemComponent
                item={item}
                getResponsiveImageConfig={getResponsiveImageConfig}
              />
            )}
          </CarouselItemContainer>
        ))
      }
    </Carousel>
  );
};

const ArrowContainer = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${(props): string => props.theme.color.freeSpeechRed};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  z-index: 1;
  position: absolute;
`;

const ArrowLeftContainer = styled(ArrowContainer)<{mediaQueryMinWidth: number}>`
  left: 0;

  @media ${({ mediaQueryMinWidth }): string => `(min-width: ${mediaQueryMinWidth}px)`} {
    left: 12.5px;
  }
`;

const ArrowRightContainer = styled(ArrowContainer)<{mediaQueryMinWidth: number}>`
  right: 0;

  @media ${({ mediaQueryMinWidth }): string => `(min-width: ${mediaQueryMinWidth}px)`} {
   right: 12.5px;
  }
`;

const CarouselItemContainer = styled.div<{mediaQueryMinWidth: number}>`
  margin: 0 8px;
  height: 100%;

  @media ${({ mediaQueryMinWidth }): string => `(min-width: ${mediaQueryMinWidth}px)`} {
    margin: 0 12.5px;
  }
`;
