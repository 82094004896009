import React, { useState } from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { setCookie, getCookie } from '@app/helpers/cookieHelpers';
import { ContainerLarge } from '@app/components/WebTemplates/components';
import { WebEmbeddedElement } from '@app/components/WebTemplates/elements';
import { Modal } from '@app/components';

const WebEmbeddedOverlayElement = ({ content = '', delay = 0 }): React.ReactElement => {
  const contentId = content.slice(0, 16);
  const todayDate = new Date();
  const lastVisibleDateSource = getCookie(contentId);
  const lastVisibleDate = lastVisibleDateSource ? new Date(Number(lastVisibleDateSource)) : todayDate;
  const daysDifferenceInMilliseconds = Math.abs(todayDate.getMilliseconds() - lastVisibleDate.getMilliseconds());
  // number of days between today date and last shown to user date
  const daysDifference = Math.ceil(daysDifferenceInMilliseconds / (1000 * 60 * 60 * 24));
  const isModalShouldBeVisible = delay < 1 || daysDifference === 0 || daysDifference > delay;
  const [isModalOpened, setIsModalOpened] = useState(isModalShouldBeVisible);

  if (isModalShouldBeVisible) setCookie(contentId, `${todayDate.getTime()}`);

  return (
    <div>
      <Modal open={isModalOpened} onClose={(): void => setIsModalOpened(false)} closeLabelId="button.close">
        <Container>
          <WebEmbeddedElement content={content} />
        </Container>
      </Modal>
    </div>
  );
};

export default WebEmbeddedOverlayElement;

const Container = styled(ContainerLarge)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px;

  & > * {
    height: 100%;
  }

  @media ${Devices.tablet} {
    padding: 20px;
  }

  @media ${Devices.desktop} {
    padding: 40px;
  }
`;
