import React from 'react';

import { NewsPageProps, NewsTypes } from '@app/types/newsTypes';
import { CategoryMenu } from '@app/components';
import { useLanguage } from '@app/components/Hooks';
import {
  useNewsNavigationTypeSelector, useNewsNavigationPathSelector, useMenuSelector,
} from '../NewsHooks';

const NewsPageCategoryMenu = React.memo(({ type }: NewsPageProps): React.ReactElement | null => {
  const language = useLanguage();

  const navigation = useNewsNavigationTypeSelector(type);
  const pathKey = useNewsNavigationPathSelector(type);
  const items = useMenuSelector(navigation)?.categoryMenuItems[language];
  const color = type === NewsTypes.VIDEOS ? 'white' : 'black';

  return items ? (<CategoryMenu items={items} pathKey={pathKey} color={color} />) : null;
});

export default NewsPageCategoryMenu;
