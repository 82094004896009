export enum AppLanguageTypes {
  IT = 'it',
  EN = 'en',
  CN = 'cn',
}

export type LangListType = ['en', 'it', 'cn'];
export type LanguageType = LangListType[number];

// Please note: this list is used only in Language Switcher component
export type SwitchLangListType = ['en', 'it', 'cn'];
export type SwitchLanguageType = SwitchLangListType[number];
