import React from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';

import AppRoutes from '@app/constants/routes';
import * as Translations from '@app/locales/index';

import { Devices } from '@app/styles';
import { AppState } from '@app/store/reducers';
import { TopNews } from '@app/types/newsTypes';
import { GA_CATEGORY, GA_EVENT } from '@app/types/googleTypes';
import { MenuItemTypes, RelatedMenuItem, ExternalLinkMenuItem } from '@app/types/configurationTypes';

import { pushGAEvent } from '@app/helpers/googleHelpers';
import { localizeRouteKey } from '@app/helpers/localizationHelper';
import { dispatchNewsPremiumClickEvent, getNewsPathKey } from '@app/helpers/newsHelpers';
import { formatRelatedItemLinkProps } from '@app/helpers/menuHelpers';

import { CommonButtonLink } from '@app/components/Buttons';
import { useMenSquadAllCompetitionsParams } from '@app/pages/Season/Season/SeasonHooks';

import { useCategoryUrl, useLanguage } from '@app/components/Hooks';

export const CarouselSlideCta = ({ slide }: { slide: TopNews }): React.ReactElement => {
  const isLoggedIn = useSelector<AppState, boolean>((state) => state.user.isLoggedIn, shallowEqual);
  const { seasonId, urlSlug } = useMenSquadAllCompetitionsParams();
  const categoryName = useCategoryUrl(slide);
  const language = useLanguage();

  const pathProps = {
    pathKey: getNewsPathKey(slide?.type),
    pathParams: {
      categoryName,
      publicationDate: slide?.publicationDate,
      urlSlug: slide?.urlSlug,
    },
  };

  const onCtaClick = (cardName: string): void => pushGAEvent({
    event: GA_EVENT.CLICK_HOME,
    category: GA_CATEGORY.SLIDER,
    label: cardName,
    language,
  });

  const onNewsClick = (event): void => {
    onCtaClick(slide?.urlSlug ?? '');

    if (slide?.isPremium && !isLoggedIn) {
      event.preventDefault();
      dispatchNewsPremiumClickEvent(localizeRouteKey(pathProps, language));
    }
  };

  return (
    <Container>
      {(slide?.cta ?? [])
        .map((cta) => {
          switch (cta?.type) {
            case MenuItemTypes.GameMenuItem:
              return (
                <ButtonCta
                  key={cta?.label}
                  transparent={cta?.uiType === 'transparent'}
                  pathKey={AppRoutes.MatchCenter.path}
                  pathParams={{
                    urlSlug: cta?.item?.id ?? '',
                    tab: Translations[language]?.['matchcenter.tabs.lineups'].toLowerCase(),
                  }}
                  onClick={(): void => onCtaClick('matchcenter')}
                >
                  {cta?.label ?? <FormattedMessage id="buttons.readMore" />}
                </ButtonCta>
              );
            case MenuItemTypes.ExternalLink:
              // eslint-disable-next-line no-case-declarations
              const item = cta?.item as ExternalLinkMenuItem;
              return (
                <ButtonCta
                  as="a"
                  key={cta?.label}
                  {...item.url}
                  transparent={cta?.uiType === 'transparent'}
                  onClick={(): void => onCtaClick(cta?.item?.name ?? '')}
                >
                  {cta?.label ?? <FormattedMessage id="buttons.readMore" />}
                </ButtonCta>
              );
            case MenuItemTypes.RelatedMenuItem:
              return (
                <ButtonCta
                  key={cta?.label}
                  transparent={cta?.uiType === 'transparent'}
                  onClick={(): void => onCtaClick(cta?.item?.name ?? '')}
                  {...formatRelatedItemLinkProps(cta.item as RelatedMenuItem, seasonId, urlSlug)}
                >
                  {cta?.label ?? <FormattedMessage id="buttons.readMore" />}
                </ButtonCta>
              );
            default:
              return (
                <ButtonCta
                  key={cta?.label}
                  transparent={cta?.uiType === 'transparent'}
                  onClick={onNewsClick}
                  {...pathProps}
                >
                  {cta?.label ?? <FormattedMessage id="buttons.readMore" />}
                </ButtonCta>
              );
          }
        })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;

  & > * {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media ${Devices.tabletLarge} {
    display: flex;
  }
`;

const ButtonCta = styled(CommonButtonLink)<{ transparent: boolean }>`
  width: 140px;
  height: 30px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
  z-index: 2;
  background-color: ${(props): string => (props.transparent ? 'transparent' : props.theme.color.harleyDavidsonOrange)};
  border-color: ${(props): string => (props.transparent ? props.theme.color.whiteSmoke : 'transparent')};

  &:hover {
    background-color: ${(props): string => (props.transparent ? props.theme.color.whiteSmoke : 'transparent')};
    border-color: ${(props): string => (props.transparent ? props.theme.color.whiteSmoke : props.theme.color.harleyDavidsonOrange)};
    color: ${(props): string => (props.transparent ? 'black' : 'inherit')};
  }

  @media ${Devices.tabletLarge} {
    width: 160px;
    height: 40px;
    font-size: 16px;
    margin-top: 30px;
  }
`;
