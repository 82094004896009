import { GoogleAdsMapType, GoogleAdsViewTypes } from '@app/types/googleTypes';

export const GTM_ADS_KEY = 21951365859;

export const GoogleAdsMap: GoogleAdsMapType = {
  newsListItem: {
    adUnit: `${GTM_ADS_KEY}/News_List`,
    slotId: 'div-gpt-ad-1588327135183-0',
    view: GoogleAdsViewTypes.Standard,
    size: ['fluid'],
  },
  videoListItem: {
    adUnit: `${GTM_ADS_KEY}/Videos_List`,
    slotId: 'div-gpt-ad-1588327168478-0',
    view: GoogleAdsViewTypes.Standard,
    size: ['fluid'],
  },
  galleryListItem: {
    adUnit: `${GTM_ADS_KEY}/PhotoGalleries_List`,
    slotId: 'div-gpt-ad-1588923621928-0',
    view: GoogleAdsViewTypes.Standard,
    size: ['fluid'],
  },
  articleLanding: {
    adUnit: `${GTM_ADS_KEY}/News_Details_Body`,
    slotId: 'div-gpt-ad-1589384794318-0',
    view: GoogleAdsViewTypes.Standard,
    size: ['fluid'],
  },
  galleryLanding: {
    adUnit: `${GTM_ADS_KEY}/PhotoGalleries_Details_Body`,
    slotId: 'div-gpt-ad-1588923932781-0',
    view: GoogleAdsViewTypes.Standard,
    size: ['fluid'],
  },
  videoLanding: {
    adUnit: `${GTM_ADS_KEY}/Videos_Details_Body`,
    slotId: 'div-gpt-ad-1588327086318-0',
    view: GoogleAdsViewTypes.Standard,
    size: ['fluid'],
  },
  articleLandingCarouselItem: {
    adUnit: `${GTM_ADS_KEY}/news_related`,
    slotId: 'div-gpt-ad-1632816464036-0',
    view: GoogleAdsViewTypes.Standard,
    size: ['fluid'],
  },
  galleryLandingCarouselItem: {
    adUnit: `${GTM_ADS_KEY}/photogallery_related`,
    slotId: 'div-gpt-ad-1632816641896-0',
    view: GoogleAdsViewTypes.Standard,
    size: ['fluid'],
  },
  videoLandingCarouselItem: {
    adUnit: `${GTM_ADS_KEY}/video_related`,
    slotId: 'div-gpt-ad-1632816583165-0',
    view: GoogleAdsViewTypes.Standard,
    size: ['fluid'],
  },
  schedule: {
    adUnit: `${GTM_ADS_KEY}/Season_Calendars`,
    slotId: 'div-gpt-ad-1588923962857-0',
    view: GoogleAdsViewTypes.Standard,
    size: ['fluid'],
  },
  players: {
    adUnit: `${GTM_ADS_KEY}/player_team_page`,
    slotId: 'div-gpt-ad-1632816707462-0',
    view: GoogleAdsViewTypes.Standard,
    size: ['fluid'],
  },
  playerStatistics: {
    adUnit: `${GTM_ADS_KEY}/player_stats_page`,
    slotId: 'div-gpt-ad-1632816799895-0',
    view: GoogleAdsViewTypes.Standard,
    size: ['fluid'],
  },
  tickets: {
    adUnit: `${GTM_ADS_KEY}/ticket_ticketing_page`,
    slotId: 'div-gpt-ad-1632816859716-0',
    view: GoogleAdsViewTypes.Standard,
    size: ['fluid'],
  },
};
