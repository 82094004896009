import { sendRequestTyped } from '@app/services/kentico/request';
import { langCodeToKenticoLangMap } from '@app/constants/localizationConstants';

import { LanguageType } from '@app/types/localizationTypes';
import { Question } from '@app/types/faqQuestionTypes';
import { KenticoFaqWebQuestionElement } from './types/faqQuestionTypes';

import { convertQuestion } from './converters/faqQuestionConverter';

export const getQuestionBySlug = async (urlSlug: string, language: LanguageType): Promise<Question> => {
  const questionResponse = await sendRequestTyped<KenticoFaqWebQuestionElement>({
    params: {
      'system.type': 'web_faq_question',
      'elements.url': urlSlug,
      language: langCodeToKenticoLangMap[language],
      'system.language': langCodeToKenticoLangMap[language],
    },
  });

  return convertQuestion(questionResponse?.items?.[0]);
};

export const getQuestionById = async (id: string, language: LanguageType): Promise<Question> => {
  const questionResponse = await sendRequestTyped<KenticoFaqWebQuestionElement>({
    params: {
      'system.type': 'web_faq_question',
      'system.id': id,
      language: langCodeToKenticoLangMap[language],
      'system.language': langCodeToKenticoLangMap[language],
    },
  });

  return convertQuestion(questionResponse?.items?.[0]);
};
