import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { LocalizedLink } from '@app/components/Localization';

type SeasonSectionProps = React.PropsWithChildren<{
  headerTranslationId: string;
  className?: string; // passed by styled component automatically
}>

export const SeasonSection = ({ children, headerTranslationId, className }: SeasonSectionProps): React.ReactElement => (
  <Container className={className}>
    <Header>
      <FormattedMessage id={headerTranslationId} />
    </Header>
    {children}
  </Container>
);

const Container = styled.section`
  width: 100%;
  padding: 20px 10px 0;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;

  @media ${Devices.tablet} {
    padding: 20px 30px 0;
  }

  @media ${Devices.tabletLarge} {
    width: 50%;
  }

  @media ${Devices.desktop} {
    padding: 20px 60px 0;
  }

  &::before {
    content: '';
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 40px;
    right: 40px;
    height: 1px;
    top: 0;

    @media ${Devices.tabletLarge} {
      width: 1px;
      top: 40px;
      bottom: 40px;
      left: 0;
      right: initial;
      height: auto;
    }
  }
`;

const Header = styled.h2`
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;

  @media ${Devices.tablet} {
    font-size: 24px;
    line-height: 24px;
  }
`;

export const LinkToSeason = styled(LocalizedLink)`
  color: ${(props): string => props.theme.color.freeSpeechRed};
  text-decoration: underline;
  font-weight: 300;
  letter-spacing: 0.3px;
  font-size: 12px;
  margin: 20px 0;
  display: block;

  @media ${Devices.tablet} {
    font-size: 14px;
  }

  &:hover {
    text-decoration: none;
  }
`;

export const SeasonInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
`;
