import React from 'react';
import styled from 'styled-components';
import { Devices } from '@app/styles';

export const HOFInducteeBiography = ({ biography = '', preview = false }): React.ReactElement => (
  <Biography dangerouslySetInnerHTML={{ __html: biography }} preview={preview} />
);

const Biography = styled.div<{ preview: boolean }>`
  font-family: Raleway, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.23px;
  text-align: justify;

  ${({ preview }): string => (preview ? `
    @media ${Devices.tabletLarge} {
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  ` : '')}
`;
