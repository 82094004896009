import * as React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { findCategoryBySlug } from '@app/helpers/configurationHelpers';
import { HorizontalScroll } from '@app/components';
import { CategoryMenu } from '@app/types/configurationTypes';
import { RoutePath } from '@app/types/routerTypes';
import LocalizedLink from '@app/components/Localization/LocalizedLink';

interface CategoryMenuProps {
  items: CategoryMenu;
  pathKey: RoutePath;
  pathParams?: {
    [propName: string]: string;
  };
  color: string;
}

const CategoryMenuComponent = ({
  items, pathKey, color, pathParams = {},
}: CategoryMenuProps): React.ReactElement => {
  const { categoryName = '' } = useParams();
  const selectedId = findCategoryBySlug(items, categoryName)?.id;

  return (
    <HorizontalScroll color={color}>
      <Menu color={color}>
        {
          items.map((item) => (item.isVisible ? (
            <MenuItem key={item.id} selected={item.id === selectedId}>
              <LocalizedLink pathKey={pathKey} pathParams={{ ...pathParams, categoryName: item.url }}>
                {item.name}
              </LocalizedLink>
            </MenuItem>
          ) : null))
        }
      </Menu>
    </HorizontalScroll>
  );
};

const Menu = styled.ul<{ color: string }>`
  padding: 40px 0;

  & a {
    color: ${({ color }): string => color};
  }
`;

const MenuItem = styled.li<{ selected: boolean }>`
  margin-right: 20px;
  height: 100%;
  width: min-content;
  font-size: 14px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  display: inline-block;

  & a {
    transition: all .3s linear;
    opacity: ${({ selected }): string => (selected ? '1' : '0.2')};
  }

  &:hover a {
    transition: all .3s linear;
    opacity: 1;
  }

  &:hover, &:hover a {
    cursor: pointer;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export default CategoryMenuComponent;
