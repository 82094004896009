import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { dispatchNewsPremiumClickEvent } from '@app/helpers/newsHelpers';
import { getGalleryByUrlSlug, getLatestPictures } from '@app/store/actions/newsActions';
import { NewsNavigationTypeMap } from '@app/constants/newsConstants';
import { GoogleAdsMap } from '@app/constants/googleAdsConstants';
import { NewsTypes } from '@app/types/newsTypes';

import {
  CarouselLatestGallery, GalleryCarousel, Metadata, MetaJsonLD, SecondLevelMenu, SocialSharing, GoogleAds,
  NewsContentHeading,
} from '@app/components';
import { LatestNewsContainer, LatestNewsSeparator } from '@app/components/News/NewsItemSections';
import { PageValidationWrapper } from '@app/pages/components';

import { useMenuSelector, useNewsSocialPreviewImageUrl } from '@app/pages/News/NewsHooks';
import {
  useDownloadGallery, useGallery, useResetGallery, useRouterParamsValidation,
} from '@app/pages/GalleryLanding/GalleryLandingHooks';
import { useNewsBreadcrumbsJsonLD, useIsLoggedInSelector } from '@app/components/Hooks';

const GalleryLanding = (): React.ReactElement => {
  useDownloadGallery();
  useResetGallery();

  const [isPrivateMode, setPrivateMode] = useState(false);

  const { secondLevelMenuItems } = useMenuSelector(NewsNavigationTypeMap.pictures);
  const { categoryName = '' } = useParams();
  const gallery = useGallery();

  const isLoggedIn = useIsLoggedInSelector();
  const validationError = useRouterParamsValidation();
  const breadcrumbsJsonLD = useNewsBreadcrumbsJsonLD(gallery?.seo?.title ?? '', NewsTypes.PICTURES);
  const socialImageUrl = useNewsSocialPreviewImageUrl(gallery);

  useEffect((): void => {
    if (gallery?.isPremium && !isLoggedIn) {
      dispatchNewsPremiumClickEvent(window.location.pathname, true);
      setPrivateMode(true);
    }
  }, []);

  return (
    <Container error={validationError} isPrivate={isPrivateMode}>
      { !!gallery?.seo && <Metadata seo={{ ...gallery.seo, socialImageUrl }} /> }
      { !!gallery?.seo && <MetaJsonLD json={breadcrumbsJsonLD} /> }
      <SecondLevelMenu items={secondLevelMenuItems} />
      {
        !!gallery?.photos?.length && <GalleryCarousel preview={gallery} />
      }
      <DataContainer>
        <NewsContentHeading
          title={gallery?.title ?? ''}
          categoryName={categoryName}
          publicationDate={gallery?.publicationDate ?? ''}
          isPremium={!!gallery?.isPremium}
          subTitle=""
        />
        <AdsUnit>
          <GoogleAds {...GoogleAdsMap.galleryLanding} />
        </AdsUnit>
        <SocialSharing url={window.location.href} />
        <LatestNewsSeparator />
      </DataContainer>
      <LatestNews>
        <CarouselLatestGallery />
      </LatestNews>
    </Container>
  );
};

GalleryLanding.serverFetch = [getGalleryByUrlSlug, getLatestPictures];

const Container = styled(PageValidationWrapper)<{ isPrivate: boolean }>`
  filter: ${({ isPrivate }): string => (isPrivate ? 'blur(10px)!important' : 'none')};
  background-color: black;
  color: white;
  height: 100%;
`;

const DataContainer = styled.div`
  padding: 30px 10px 0;
  max-width: var(--gallery-photo-max-width);
  margin: auto;
`;

const AdsUnit = styled.div`
  margin: 20px 0;
`;

const LatestNews = styled(LatestNewsContainer)`
  max-width: var(--gallery-photo-max-width);
`;

export default GalleryLanding;
