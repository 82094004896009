import React from 'react';
import styled from 'styled-components';
import Carousel from 'react-multi-carousel';
import useMedia from 'react-use/lib/useMedia';

import { Devices } from '@app/styles';
import { TopNews } from '@app/types/newsTypes';
import { CarouselSlide } from './components/CarouselSlide';

type CustomDot = React.FC<{
  onClick: () => void;
  active: boolean;
}>

export const CustomDot: CustomDot = ({ onClick, active }) => (
  <Dot
    active={active}
    onClick={(e): void => {
      onClick();
      e.preventDefault();
    }}
  />
);

const Dot = styled.div<{active: boolean}>`
  border-radius: 50px;
  background-color: ${({ active }): string => (active ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.2)')};
  width: 10px;
  height: 10px;
  margin: 0 5px;
  cursor: pointer;
`;

interface CarouselProps {
  isOverlapped?: boolean;
  slides: TopNews[];
}

export const CarouselTopNews = ({ isOverlapped, slides }: CarouselProps): React.ReactElement => {
  const isWideScreen = useMedia(Devices.desktopSmall);
  const hasManySlides = slides.length > 1;
  return (
    <Section>
      {!!slides.length && (
        <Carousel
          ssr
          infinite={hasManySlides}
          swipeable={hasManySlides}
          autoPlay={false}
          draggable={false}
          keyBoardControl={false}
          responsive={{
            mobile: {
              breakpoint: { max: Infinity, min: 0 },
              items: 1,
            },
          }}
          arrows={hasManySlides && isWideScreen}
          showDots={hasManySlides}
          dotListClass={isOverlapped ? 'overlapped-carousel-dots' : 'carousel-dots'}
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          customDot={<CustomDot />} // arguments are passed by react-multi-carousel lib
          // class is specified in globalStyles
          containerClass="container-100hv"
          sliderClass="container-100hv"
          itemClass="container-100hv"
        >
          {slides.map((slide) => (
            <CarouselSlide key={slide.urlSlug} slide={slide} isOverlapped={isOverlapped} />
          ))}
        </Carousel>
      )}
    </Section>
  );
};

const Section = styled.section`
  & > div > button {
    min-width: 35px;
    min-height: 35px;
    z-index: 199;
  }
`;
