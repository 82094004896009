import {
  TicketingSingle,
  PackageTicketsInfo,
  TicketingConfiguration,
  TicketingSubscription,
} from '@app/types/ticketingTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { langCodeToKenticoLangMap } from '@app/constants/localizationConstants';
import { RequestInterface, RequestParamsInterface } from './types/requestTypes';
import { sendRequestTyped } from './request';
import {
  KenticoTicketingElement,
  KenticoTicketingModularContent,
  KenticoWebTicketsElement, KenticoWebTicketsModularContent,
} from './types/ticketingTypes';
import {
  convertSubscription,
  convertTicketing,
  convertTicketingPackages,
  convertTicketingConfiguration,
} from './converters/ticketingConverter';

export async function getConfigurationTicketing(language: LanguageType): Promise<TicketingConfiguration> {
  const params: RequestInterface = {
    path: '/web_tickets',
    params: {
      depth: 5,
      language: langCodeToKenticoLangMap[language],
      'system.language': langCodeToKenticoLangMap[language],
    },
  };
  const kenticoResponse = await sendRequestTyped<KenticoWebTicketsElement, KenticoWebTicketsModularContent>(
    params,
  );
  return convertTicketingConfiguration(kenticoResponse);
}

export async function getTicketingGamesList(language: LanguageType): Promise<TicketingSingle> {
  const params: RequestParamsInterface = {
    'system.type': 'ticketing',
    depth: 3,
    language: langCodeToKenticoLangMap[language] ?? langCodeToKenticoLangMap.it,
    'system.language': langCodeToKenticoLangMap[language] ?? langCodeToKenticoLangMap.it,
  };

  const ticketingGamesKenticoResponse = await sendRequestTyped<KenticoTicketingElement, KenticoTicketingModularContent>(
    { params },
  );
  return convertTicketing(ticketingGamesKenticoResponse);
}

export async function getTicketingPackages(language: LanguageType): Promise<PackageTicketsInfo[]> {
  const params: RequestParamsInterface = {
    'system.type': 'ticketing',
    depth: 3,
    language: langCodeToKenticoLangMap[language],
    'system.language': langCodeToKenticoLangMap[language],
  };

  const ticketingPackagesResponse = await sendRequestTyped<KenticoTicketingElement, KenticoTicketingModularContent>(
    { params },
  );
  return convertTicketingPackages(ticketingPackagesResponse);
}

export async function getTicketingSubscription(language: LanguageType): Promise<TicketingSubscription> {
  const params: RequestInterface = {
    path: '/web_tickets_tab_subscription',
    params: {
      depth: 1,
      language: langCodeToKenticoLangMap[language],
      'system.language': langCodeToKenticoLangMap[language],
    },
  };

  const subscriptionKenticoResponse = await sendRequestTyped<KenticoTicketingElement, KenticoTicketingModularContent>(
    params,
  );
  return convertSubscription(subscriptionKenticoResponse);
}
