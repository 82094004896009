import { useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import AppRoutes from '@app/constants/routes';
import { AppState } from '@app/store/reducers';
import { AppLanguageTypes } from '@app/types/localizationTypes';
import { SearchCategories, SearchParams, SearchConfig } from '@app/types/newsTypes';
import { setCurrentRouteActionCreator } from '@app/store/actions/currentRouteActions';
import { useLanguage } from '@app/components/Hooks';

type searchBarVisibilityType = boolean | null;

const useSearchBarVisibilitySelector = (): searchBarVisibilityType => useSelector<AppState, searchBarVisibilityType>(
  (state) => state.search.isSearchBarVisible,
  shallowEqual,
);

export const useSearchPagePath = (): string => {
  const { formatMessage } = useIntl();
  const language = useLanguage();

  return `/${language}${formatMessage({ id: AppRoutes.Search.path })}`;
};

export const useIsSearchPageValidation = (): boolean => {
  const searchPagePath = useSearchPagePath();

  return Boolean(useRouteMatch({ path: searchPagePath, exact: true }));
};

export const useIsSearchBarVisible = (): boolean | null => {
  const isSearchBarVisible = useSearchBarVisibilitySelector();
  const isSearchPage = useIsSearchPageValidation();

  if (isSearchPage) return true;
  return isSearchBarVisible;
};

export const useSearchParams = (): SearchParams => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  return {
    category: params.get('category') ?? SearchCategories.ALL,
    query: params.get('query') ?? '',
  };
};

export const useSearchConfig = (): SearchConfig => ({
  appId: process.env.API === 'staging'
    ? 'W64AVH4D5A' // DEV access key
    : 'MVLQNE3R6A', // PROD access key
  apiKey: process.env.API === 'staging'
    ? '578f946d4c81189eba415f2a33fff80a' // DEV access key
    : 'eb2ed13501d5b41761cd58d7ab806f49', // PROD access key
});

export const useSearchFilters = (): string => {
  const { category } = useSearchParams();

  switch (category) {
    case SearchCategories.ALL: return 'kenticoType:video OR kenticoType:news OR kenticoType:photo_gallery';
    case SearchCategories.NEWS: return 'kenticoType:news';
    case SearchCategories.VIDEO: return 'kenticoType:video';
    case SearchCategories.PHOTO: return 'kenticoType:photo_gallery';
    default: return '';
  }
};

export const useSearchIndex = (): string => {
  const language = useLanguage();

  switch (language) {
    case AppLanguageTypes.IT: return 'acMilanItalian';
    case AppLanguageTypes.CN: return 'acMilanChinese';
    default: case AppLanguageTypes.EN: return 'acMilanEnglish';
  }
};

export const useSearchMultiLangUrl = (): void => {
  const dispatch = useDispatch();

  const langToRouteMap = {
    [AppLanguageTypes.IT]: `/${AppLanguageTypes.IT}`,
    [AppLanguageTypes.EN]: `/${AppLanguageTypes.EN}`,
    [AppLanguageTypes.CN]: `/${AppLanguageTypes.CN}`,
  };

  useEffect(() => {
    dispatch(setCurrentRouteActionCreator(langToRouteMap));
  }, []);
};
