import React from 'react';

import { NewsTypes } from '@app/types/newsTypes';
import { getPictures } from '@app/store/actions/newsActions';

import { useNewsListDownload } from '../NewsHooks';
import News from '../News';

const PhotoGallery = (): React.ReactElement => {
  useNewsListDownload(NewsTypes.PICTURES);

  return (<News type={NewsTypes.PICTURES} />);
};
export default PhotoGallery;

PhotoGallery.serverFetch = getPictures;
