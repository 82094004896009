import React from 'react';
import styled from 'styled-components';

import { WebContentGroup } from '@app/types/webTemplateTypes';

import { ArticlesList } from '@app/components';
import { ContainerMedium } from '@app/components/WebTemplates/components';

import { useWebTemplateNews, useDataRequest } from './WebContentGroupElementHooks';

const WebContentGroupElement: React.FC<{data: WebContentGroup}> = ({ data }) => {
  const articles = useWebTemplateNews(data.id);
  const dataRequest = useDataRequest(data.id);

  if (!articles?.items?.length) return null;

  return (
    <ContainerMedium>
      <NewsContainer>
        <ArticlesList articles={articles} dataRequest={dataRequest} />
      </NewsContainer>
    </ContainerMedium>
  );
};

const NewsContainer = styled.div`
  padding: 15px 0;
`;

export default WebContentGroupElement;
