import React from 'react';

import { WebTemplateContainer } from '@app/components';
import { PageWrapper } from '@app/pages/components';

import {
  useWebTemplateData,
  useWebTemplateDownload,
  useWebTemplatePageValidation,
} from '@app/pages/WebTemplate/WebTemplateHooks';

const WebTemplatePage: React.FC = () => {
  useWebTemplateDownload();
  const data = useWebTemplateData();
  const validationError = useWebTemplatePageValidation();

  return (
    <PageWrapper error={validationError}>
      <WebTemplateContainer data={data} />
    </PageWrapper>
  );
};

export default WebTemplatePage;
