import React from 'react';

import { StandingsTable } from '@app/components';
import { getStandings, setStandingsMultiLangUrl } from '@app/store/actions/seasonActions';
import { useStandingsDownload, useStandingsCompetitionValidation } from '@app/pages/Season/Standings/StandingsHooks';

const Standings = (): React.ReactElement => {
  useStandingsDownload();
  useStandingsCompetitionValidation();

  return (<StandingsTable />);
};

Standings.serverFetch = [getStandings, setStandingsMultiLangUrl];

export default Standings;
