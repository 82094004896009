import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import * as Translations from '@app/locales';
import AppRoutes from '@app/constants/routes';
import { AppState } from '@app/store/reducers';
import { WebGoogleAds } from '@app/types/webTemplateTypes';
import { Errors, PageError } from '@app/types/errorTypes';
import {
  Game, MatchLineups, MatchResult, MatchTeamPlayer, MatchTeams,
} from '@app/types/matchTypes';
import { SeoData, CompetitionTypes } from '@app/types/configurationTypes';
import { Player } from '@app/types/teamsTypes';
import { useLanguage } from '@app/components/Hooks';
import { useStandingSelector } from '@app/pages/Season/Standings/StandingsHooks';
import {
  getLiveMatchResults, getLocalizedGames, getMatchFeeds, resetMatchCenter, getMatchLineups, getMatchEvents,
  setMatchCenterMultiLangUrl, getMatchResults,
} from '@app/store/actions/matchActions';
import { localizeRouteKey } from '@app/helpers/localizationHelper';
import { getStandings, resetSeason } from '@app/store/actions/seasonActions';
import { isTeamMilan } from '@app/helpers/teamHelpers';
import { getGameByUrlSlug, getGameGoogleAdsByUrlSlug } from '@app/helpers/matchHelpers';
import { useLiveMatchResultsSelector } from '@app/components/MatchDay/MatchDayHooks';
import { useCompetitionsSelector } from '@app/pages/Season/Season/SeasonHooks';
import { MilanTeamId } from '@app/services/opta/constants/teamConstants';
import { MatchStatusMap } from '@app/constants/matchConstants';
import { TeamSubMenuTypeMap } from '@app/constants/teamsConstants';

export const useIsLoadingSelector = (): boolean => {
  const isGamesDownloadCompleted = useSelector<AppState, boolean>(
    (state) => state.matchCenter.isGamesDownloadCompleted, shallowEqual,
  );
  const isMatchFeedsDownloadCompleted = useSelector<AppState, boolean>(
    (state) => state.matchCenter.isMatchFeedsDownloadCompleted, shallowEqual,
  );

  return !(isGamesDownloadCompleted && isMatchFeedsDownloadCompleted);
};

export const useMatchResultsSelector = (): MatchResult | null => {
  const result = useSelector<AppState, MatchResult | null>(
    (state) => state.matchCenter.results,
    shallowEqual,
  );
  const liveResults = useLiveMatchResultsSelector(result?.gameId ?? '');

  return result ? { ...result, ...liveResults } : null;
};

export const useMatchDayResultsSelector = (): MatchResult[] => useSelector<AppState, MatchResult[]>(
  (state) => state.matchCenter.matchDayResults,
  shallowEqual,
);

export const useMatchTeamsSelector = (): MatchTeams | undefined => useSelector<AppState, MatchTeams | undefined>(
  (state) => state.matchCenter.results?.teams, shallowEqual,
);

export const useIsMatchMenTeamsCheck = (): boolean => {
  const teams = useMatchTeamsSelector();
  return [teams?.first?.id, teams?.second?.id].includes(MilanTeamId);
};

export const useLineupsSelector = (): unknown => useSelector<AppState, unknown>(
  (state) => state.matchCenter.lineups,
  shallowEqual,
);

export const useEventsSelector = (): unknown => useSelector<AppState, unknown>(
  (state) => state.matchCenter.events ?? [],
  shallowEqual,
);

export const useTeamPosition = (position: number): string => {
  const language = useLanguage();
  const ordinals = ['th', 'st', 'nd', 'rd'];

  switch (language) {
    case 'en':
      return `${position + (ordinals[((position % 100) - 20) % 10] || ordinals[position % 100] || ordinals[0])}`;
    default: // it
      return `${position}°`;
  }
};

export const useTeamScoreSelector = (teamId: string): string => {
  const standing = useStandingSelector()
    .map(({ statistics }) => statistics)
    .flat()
    .find(({ id }) => id === teamId);
  const position = useTeamPosition(standing?.position ?? 0);
  return standing ? `${position} - ${standing?.points}pts` : '';
};

export const useGameByOptaIdSelector = (gameOptaId: string): Game | undefined => {
  const language = useLanguage();

  return useSelector<AppState, Game | undefined>(
    (state) => state.matchCenter.games?.[language]?.find((game) => gameOptaId === game.gameOptaId),
    shallowEqual,
  );
};

export const useGameByUrlSlugSelector = (): Game | undefined => {
  const { urlSlug = '' } = useParams();
  const language = useLanguage();

  return useSelector<AppState, Game | undefined>(
    (state) => getGameByUrlSlug(state, language, urlSlug),
    shallowEqual,
  );
};

export const useGameGoogleAdsSelector = (): WebGoogleAds | undefined => {
  const { urlSlug = '' } = useParams();
  const language = useLanguage();

  return useSelector<AppState, WebGoogleAds | undefined>(
    (state) => getGameGoogleAdsByUrlSlug(state, language, urlSlug),
    shallowEqual,
  );
};

export const useMatchCenterSeoSelector = (): SeoData => {
  const language = useLanguage();
  const result = useMatchResultsSelector();
  const game = useGameByUrlSlugSelector();

  const formatMatchCenterSeoLabel = (label = ''): string => label
    .replace('{home}', result?.teams.first.name ?? '')
    .replace('{away}', result?.teams.second.name ?? '')
    .replace('{competition}', game?.competition?.name ?? '')
    .replace('{season}', game?.seasonName ?? '');
  const localizedCanonicalUrl = localizeRouteKey({
    pathKey: AppRoutes.MatchCenter.path,
    pathParams: { urlSlug: game?.urlSlug ?? '', tab: Translations[language]?.['matchcenter.tabs.lineups'].toLowerCase() },
  }, language);

  return {
    title: formatMatchCenterSeoLabel(Translations[language]?.['matchcenter.meta.title']),
    description: formatMatchCenterSeoLabel(Translations[language]?.['matchcenter.meta.description']),
    canonicalTag: `${window?.HOST_URL || window?.location?.origin}${localizedCanonicalUrl}`,
    meta: false,
    socialTitle: formatMatchCenterSeoLabel(Translations[language]?.['matchcenter.meta.title']),
    socialDescription: formatMatchCenterSeoLabel(Translations[language]?.['matchcenter.meta.description']),
    socialImageUrl: '',
  };
};

export const useIsStandingsAvailableCheck = (): boolean => {
  const competitions = useCompetitionsSelector();
  const result = useMatchResultsSelector();
  const competition = Object
    .values(competitions)
    .find(({ optaId }) => optaId === result?.competitionOptaId);

  return !!competition && [
    CompetitionTypes.All,
    CompetitionTypes.League,
    CompetitionTypes.Tournament,
  ].includes(competition.type);
};

export const useIsResultsAvailableCheck = (): boolean => {
  const competitions = useCompetitionsSelector();
  const result = useMatchResultsSelector();
  const competition = Object
    .values(competitions)
    .find(({ optaId }) => optaId === result?.competitionOptaId);

  return !competition?.qualifiersMode ?? true;
};

export const useIsMilanCheckPlayerById = (playerId = ''): boolean => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore // TODO:REMOVE:ignore
  const { home, away } = useSelector<AppState, unknown>(
    (state) => state.matchCenter.lineups, shallowEqual,
  );
  const { players = [] } = isTeamMilan(home?.teamId) ? home : away;
  return !!players?.find((player) => player.playerId === playerId);
};

export const useMatchPlayerByIdSelector = (playerId = ''): MatchTeamPlayer | null => {
  const lineups = useSelector<AppState, MatchLineups>(
    (state) => state.matchCenter.lineups, shallowEqual,
  );

  return lineups?.home?.players?.find((player) => player.playerId === `p${playerId}`)
    ?? lineups?.away?.players?.find((player) => player.playerId === `p${playerId}`) ?? null;
};

export const usePlayerByIdSelector = (playerId = ''): Player | null => {
  const language = useLanguage();
  return useSelector<AppState, Player | null>(
    (state) => state.teams.teams?.[TeamSubMenuTypeMap.men]?.[language]
      ?.find((player) => player.id === playerId) as Player ?? null,
    shallowEqual,
  );
};

export const useMatchCenterLiveSubscribe = (): void => {
  const dispatch = useDispatch();
  const language = useLanguage();
  const game = useGameByUrlSlugSelector();
  const result = useMatchResultsSelector();
  const { matchStatus } = useLiveMatchResultsSelector(result?.gameId ?? '');
  const { seasonId = '', optaId = '', gameOptaId = '' } = game ?? {};

  useEffect(() => {
    result?.gameId && !matchStatus && dispatch(getLiveMatchResults(result));
    const subscribe = result?.gameId && matchStatus !== MatchStatusMap.finished
      && setInterval(() => {
        dispatch(getLiveMatchResults(result));
        dispatch(getMatchLineups(seasonId, optaId, gameOptaId));
        dispatch(getMatchEvents(seasonId, optaId, gameOptaId, language));
      }, 20000);

    return (): void => { subscribe && clearInterval(subscribe); };
  }, [result, matchStatus]);
};

export const useMatchCenterMultiLangUrl = (): void => {
  const { urlSlug = '', tab = '' } = useParams();
  const language = useLanguage();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMatchCenterMultiLangUrl({ urlSlug, tab, language }));
  }, [urlSlug, tab]);
};

export const useMatchCenterMultiLangEvents = (): void => {
  const dispatch = useDispatch();
  const language = useLanguage();
  const game = useGameByUrlSlugSelector();

  useEffect(() => {
    !!game && dispatch(getMatchEvents(game?.seasonId, game?.optaId, game?.gameOptaId, language));
  }, [language]);
};

export const useMatchCenter = (): void => {
  const dispatch = useDispatch();
  const { urlSlug = '' } = useParams();
  const language = useLanguage();

  useMatchCenterLiveSubscribe();
  useMatchCenterMultiLangEvents();
  useMatchCenterMultiLangUrl();

  useEffect(() => {
    dispatch(getLocalizedGames());
    dispatch(getMatchFeeds({ urlSlug, language }));

    return (): void => {
      dispatch(resetSeason());
      dispatch(resetMatchCenter());
    };
  }, [language]);
};

export const useMatchCenterByGameId = (gameId = ''): void => {
  const dispatch = useDispatch();
  const language = useLanguage();
  const result = useMatchResultsSelector();
  const { matchStatus } = useLiveMatchResultsSelector(result?.gameId ?? '');
  const {
    seasonId = '', optaId = '', gameOptaId = '', urlSlug = '',
  } = useGameByOptaIdSelector(gameId) ?? {};

  useEffect(() => {
    dispatch(getLocalizedGames());
    if (urlSlug) {
      dispatch(getMatchResults(seasonId, optaId, gameOptaId, language));
      dispatch(getMatchLineups(seasonId, optaId, gameOptaId));
      dispatch(getStandings({ seasonId, optaId, language }));
    }

    return (): void => { dispatch(resetMatchCenter()); };
  }, [gameId, urlSlug, language]);

  useEffect(() => {
    result?.gameId && !matchStatus && dispatch(getLiveMatchResults(result));
    const subscribe = result?.gameId && matchStatus !== MatchStatusMap.finished
      && setInterval(() => {
        dispatch(getLiveMatchResults(result));
        dispatch(getMatchLineups(seasonId, optaId, gameOptaId));
      }, 20000);

    return (): void => { subscribe && clearInterval(subscribe); };
  }, [result, matchStatus]);
};

export const useMatchCenterButton = (): void => {
  const dispatch = useDispatch();
  useEffect((): void => { dispatch(getLocalizedGames()); }, []);
};

export const useMatchCenterValidation = (): Errors => {
  const { tab = '' } = useParams();
  const language = useLanguage();

  const networkError = useSelector<AppState, Errors>(
    (state) => state.matchCenter.error, shallowEqual,
  );
  const isGamesDownloadCompleted = useSelector<AppState, boolean>(
    (state) => state.matchCenter.isGamesDownloadCompleted, shallowEqual,
  );

  const isUrlSlugValid = Boolean(useGameByUrlSlugSelector());
  const isStandingsAvailable = useIsStandingsAvailableCheck();
  const isResultsAvailable = useIsResultsAvailableCheck();

  const standingsTab = isStandingsAvailable ? [Translations[language]?.['matchcenter.tabs.standings'].toLowerCase()] : [];
  const resultsTab = isResultsAvailable ? [Translations[language]?.['matchcenter.tabs.results'].toLowerCase()] : [];

  const isTabValid = [
    Translations[language]?.['matchcenter.tabs.lineups'].toLowerCase(),
    Translations[language]?.['matchcenter.tabs.statistics'].toLowerCase(),
    ...resultsTab,
    ...standingsTab,
  ].includes(tab);

  if (networkError) return networkError;
  return isGamesDownloadCompleted && (!isUrlSlugValid || !isTabValid) ? PageError.NotFound : null;
};
