import { WebGoogleAds, WebPageSeparatorSizeType } from '@app/types/webTemplateTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { GoogleAdsProps } from '@app/types/googleTypes';
import { Action } from '@app/types/actionTypes';
import {
  Image,
  Seo,
  MenuItemTypes,
  ExternalLinkMenuItem,
  RelatedMenuItem,
  GameMenu,
} from '@app/types/configurationTypes';

export enum EditorialContentTypes {
  NEWS = 'news',
  VIDEO = 'video',
  PHOTO = 'photo_gallery',
  SEPARATOR = 'web_page_separator',
  EMBEDDED = 'embedded',
  EMBEDDED_ONETRUST = 'embedded_onetrust',
  EMBEDDED_OVERLAY = 'embedded_overlay',
  GOOGLE_ADS = 'web_ad_manager_banner',
}

enum Text {
  TEXT = 'text',
}

// it is a hack to merge 2 enum https://github.com/microsoft/TypeScript/issues/17592#issuecomment-449440944
type ArticleContentTypes = EditorialContentTypes | Text;
export const ArticleContentType = { ...EditorialContentTypes, ...Text };

export interface BaseNewsData {
  urlSlug: string;
  publicationDate: string;
  title: string;
  codeName: string;
  id: string;
  categoryName: string;
  categoryCode: string;
  type: EditorialContentTypes;
  isPremium: boolean;
}

export interface GalleryImage extends Image{
  name: string;
}

export interface BaseNewsItem extends BaseNewsData {
  image: Image;
}

export interface ArticleItem extends BaseNewsItem, Seo {
  cover: ArticleContentCoverItem | null;
  content: ArticleModularContentItem[];
  shareUrl: string;
  subTitle: string;
}

export type LocalizedArticleItem = {
  [key in LanguageType]: ArticleItem | undefined | null;
}

export type ArticleModularValueType = BaseNewsItem
  | WebPageSeparatorSizeType
  | WebGoogleAds
  | VideoItem
  | Gallery
  | string;

export interface ArticleModularContentItem {
  type: ArticleContentTypes;
  value: ArticleModularValueType;
}

export interface ArticleContentCoverItem {
  type: ArticleContentCoverTypes;
  value: Gallery | VideoItem | string;
}

export type ArticleContentCoverTypes = EditorialContentTypes.VIDEO | EditorialContentTypes.PHOTO;

export interface VideoItem extends BaseNewsItem, Seo {
  videoId: string;
  tencentVideoId: string;
  subTitle: string;
  shareUrl: string;
}

export type LocalizedVideoItem = {
  [key in LanguageType]: VideoItem | undefined | null;
}

export type PhotosList = GalleryImage[];

export interface Gallery extends BaseNewsItem, Seo {
  mainImage?: Image;
  photos: PhotosList;
}

export type LocalizedGalleryItem = {
  [key in LanguageType]: Gallery | undefined | null;
}

export interface NewsList {
  hasMoreData: boolean;
  items: BaseNewsItem[];
}

export enum NewsTypes {
  ARTICLES = 'articles',
  VIDEOS = 'videos',
  PICTURES = 'pictures',
}

export enum NewsNavigationTypes {
  ARTICLES = 'news_tutte_news',
  VIDEOS = 'news_video',
  PICTURES = 'news_fotogallery',
}

export interface GetNews {
  // Important to pass parameters as object to get them correctly from the server (url params are passed on server)
  (data: {
    categoryName: string;
    language: LanguageType;
    type?: NewsTypes;
  }): Action;
}

export interface TopNewsCtaButton {
  label: string;
  uiType: string;
  type?: MenuItemTypes;
  item?: ExternalLinkMenuItem | RelatedMenuItem | GameMenu;
}

export interface TopNews extends BaseNewsItem {
  subTitle: string;
  videoId: string;
  image: Image;
  verticalImage: Image;
  cta: TopNewsCtaButton[];
}

/** NEWS LIST TYPES */
export type NewsListItemImageConfigType = {
  url: string;
  size: number;
}

export type NewsListItems = (BaseNewsItem|GoogleAdsProps)[];

export interface NewsPageProps {
  type: NewsTypes;
}

export interface NewsListMenuProps {
  type: NewsNavigationTypes;
}

/** NEWS SEARCH */
export enum SearchCategories {
  ALL = 'all',
  NEWS = 'news',
  VIDEO = 'video',
  PHOTO = 'photo_gallery',
}

export interface SearchParams {
  category: string;
  query: string;
}

export interface SearchConfig {
  appId: string;
  apiKey: string;
}
