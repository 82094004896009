import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { Hostname } from '@app/services/salesforce/request';
import { LoginUser } from '@app/store/actions/userActions';
import { useLanguage, useIsLoggedInSelector } from '@app/components/Hooks';

import LoginControl from './LoginControl';
import LogoutControl from './LogoutControl';

// have to be placed here to avoid empty query string after redirection
// Note: this workaround have to be improved to be able to
// parse query string array parameters (if needed)
const params = new URLSearchParams(window?.location?.search);

const MyMilanControl: React.FC = React.memo(() => {
  const isLoggedIn = useIsLoggedInSelector();
  const history = useHistory();
  const language = useLanguage();
  const dispatch = useDispatch();

  useEffect(() => {
    const identitySrc = params?.get('sfdc_community_url');
    const identityCode = params?.get('code');

    if (identitySrc) {
      if (identitySrc.indexOf(Hostname) > 0 && identityCode) dispatch(LoginUser(identityCode));
      history.replace(`/${language}`);
    }
  }, []);

  useEffect(() => {
    const PREMIUM_NEWS_URL = 'redirect_url';
    const redirectUrl = localStorage.getItem(PREMIUM_NEWS_URL);

    if (isLoggedIn && redirectUrl?.length) {
      history.replace(redirectUrl);
      localStorage.setItem(PREMIUM_NEWS_URL, '');
    }
  }, [isLoggedIn]);

  return (
    <MyMilanSection>
      <LoginControl />
      { isLoggedIn && <LogoutControl /> }
    </MyMilanSection>
  );
});

const MyMilanSection = styled.div`
  display: inline-flex;
  max-width: 110px;
  height: 25px;

  @media ${Devices.desktopSmall} {
    height: 30px;
  }
`;

export default MyMilanControl;
