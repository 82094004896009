import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { getLineupFormation } from '@app/helpers/matchHelpers';
import { useLineupsSelector, useMatchTeamsSelector } from '../../MatchCenterHooks';
import { FormationGrid } from './components';

const MatchLineups = (): React.ReactElement => {
  const teams = useMatchTeamsSelector();
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore // TODO:REMOVE:ignore
  const { home, away } = useLineupsSelector() ?? {};
  const arePlayersLoaded = !!(home?.players?.length && away?.players?.length);

  const renderTeamNames = (label: string): React.ReactElement => (
    <TeamNames>
      <div>
        <Logo src={teams?.first.logoUrl} alt={teams?.first.name} />
        <div>
          <TeamName>{teams?.first.name}</TeamName>
          <Status><FormattedMessage id={label} /></Status>
        </div>
      </div>
      <div>
        <div>
          <TeamName>{teams?.second.name}</TeamName>
          <Status><FormattedMessage id={label} /></Status>
        </div>
        <Logo src={teams?.second.logoUrl} alt={teams?.second.name} />
      </div>
    </TeamNames>
  );

  const renderStartLineups = (team, isAway = false): React.ReactElement => (
    getLineupFormation(team?.formation).map((lineup) => {
      const player = team?.players?.find(({ place }) => place === lineup);
      return (
        <div key={`${player?.number}${player?.position}`}>
          {!isAway && (<PlayerNumber>{(`0${player?.number}`).slice(-2)}</PlayerNumber>)}
          <div>
            <PlayerName>{player?.name}</PlayerName>
            <PlayerPosition>
              <FormattedMessage id={`matchcenter.position.${player?.position}`} />
            </PlayerPosition>
          </div>
          {isAway && (<PlayerNumber>{(`0${player?.number}`).slice(-2)}</PlayerNumber>)}
        </div>
      );
    }) as unknown as React.ReactElement
  );

  return (
    <Container>
      <Heading><FormattedMessage id="matchcenter.tabs.lineups" /></Heading>
      {renderTeamNames('matchcenter.position.lineups')}
      <FormationGrid />
      {arePlayersLoaded && (
        <>
          <Lineups>
            <div>{renderStartLineups(home)}</div>
            <div>{renderStartLineups(away, true)}</div>
          </Lineups>
          {renderTeamNames('matchcenter.position.substitutes')}
          <Lineups>
            <div>
              {
                home?.players?.filter(({ place }) => place === 0).map((player) => (
                  <div key={`${player?.number}${player?.position}`}>
                    <PlayerNumber>{(`0${player?.number}`).slice(-2)}</PlayerNumber>
                    <div>
                      <PlayerName>{player?.name}</PlayerName>
                    </div>
                  </div>
                ))
              }
            </div>
            <div>
              {
                away?.players?.filter(({ place }) => place === 0).map((player) => (
                  <div key={`${player?.number}${player?.position}`}>
                    <div>
                      <PlayerName>{player?.name}</PlayerName>
                    </div>
                    <PlayerNumber>{(`0${player?.number}`).slice(-2)}</PlayerNumber>
                  </div>
                ))
              }
            </div>
          </Lineups>
        </>
      )}
    </Container>
  );
};

export default MatchLineups;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  background-color: white;
  padding: 20px;

  & > img {
    margin-bottom: 30px;
  }
`;

const Heading = styled.h2`
  display: none;
`;

const TeamNames = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: black;
  margin-bottom: 30px;

  &::after {
    content: "";
    border-bottom: 1px solid rgba(0,0,0,.1);
    width: 100%;
    height: 1px;
    bottom: -15px;
    left: 0;
    right: 0;
    display: block;
    position: absolute;
  }

  & > div {
    display: flex;
    flex-direction: row;
    flex: 0 0 50%;
    justify-content: flex-start;

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &:last-child {
      justify-content: flex-end;
      text-align: right;
    }
  }
`;

const TeamName = styled.span`
  color: black;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 13px;
  font-weight: bold;
  line-height: 1.43;
  text-transform: uppercase;
`;

const Status = styled(TeamName)`
  color: ${(props): string => props.theme.color.cheninYellow};
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 9px;
  font-weight: normal;
  text-transform: capitalize;
`;

const PlayerNumber = styled(TeamName)`
  font-size: 22px;
`;

const PlayerName = styled(TeamName)`
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  text-transform: capitalize;
`;

const PlayerPosition = styled(PlayerName)`
  color: ${(props): string => props.theme.color.harleyDavidsonOrange};
  font-size: 9px;
  font-weight: 300;
`;

const Logo = styled.img`
  width: 100%;
  height: 100%;
  max-width: 45px;
  max-height: 45px;
  margin: 0 5px;
`;

const Lineups = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:last-of-type {
    margin-bottom: 0;
  }

  & > div {
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;
    justify-content: flex-start;

    &:last-child {
      text-align: right;

      & > div {
        justify-content: flex-end;

        & > span {
          padding-right: 0;
          padding-left: 10px;
        }
      }
    }

    & > div {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding-right: 10px;
      padding-bottom: 20px;

      & > span {
        padding-right: 10px;
        padding-left: 0;
      }

      & div {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
`;
