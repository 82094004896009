import React from 'react';

import { NewsPageProps } from '@app/types/newsTypes';
import { Metadata, MetaJsonLD } from '@app/components';
import { useBreadcrumbsJsonLD } from '@app/components/Hooks';
import { useNewsCategoryByUrlSlugSelector } from '../NewsHooks';

const NewsPageSeo = React.memo(({ type }: NewsPageProps): React.ReactElement | null => {
  const seo = useNewsCategoryByUrlSlugSelector(type)?.seo;
  const breadcrumbsJsonLD = useBreadcrumbsJsonLD(seo?.title ?? '');

  return seo ? (
    <>
      <Metadata seo={seo} />
      <MetaJsonLD json={breadcrumbsJsonLD} />
    </>
  ) : null;
});

export default NewsPageSeo;
