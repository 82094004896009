import { LocalizedQuestion } from '@app/types/faqQuestionTypes';
import { QuestionActionAction, QuestionActionTypes } from '@app/store/actionTypes/questionActionTypes';

const initialState: LocalizedQuestion = {};

export default (
  state = initialState,
  action: QuestionActionAction,
): LocalizedQuestion => {
  switch (action.type) {
    case QuestionActionTypes.SET_QUESTION:
      return {
        ...state,
        ...action.payload,
      };
    case QuestionActionTypes.RESET_QUESTION:
      return initialState;
    default:
      return state;
  }
};

export { LocalizedQuestion };
