import { useIntl } from 'react-intl';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import AppRoutes from '@app/constants/routes';
import { AppState } from '@app/store/reducers';
import { getStandings, resetSeason } from '@app/store/actions/seasonActions';
import { AppLanguagesMap } from '@app/constants/localizationConstants';
import { CompetitionTypes } from '@app/types/configurationTypes';
import { StandingsTable } from '@app/types/standingsTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { MountContext } from '@app/ReactContext';
import { localizeRouteKey } from '@app/helpers/localizationHelper';
import {
  useCompetitionSelector, useCompetitionIdSelector, useOptaSeasonId, useOptaSeasonIdCheck,
} from '@app/pages/Season/Season/SeasonHooks';

export const useStandingSelector = (): StandingsTable[] => (
  useSelector<AppState, StandingsTable[]>(
    (state) => state.season.standings,
    shallowEqual,
  )
);

export const useStandingsDownload = (): void => {
  const { isInitialMount } = useContext(MountContext);
  const language = useIntl().locale as LanguageType;

  const dispatch = useDispatch();
  const competitionId = useCompetitionIdSelector();
  const competition = useCompetitionSelector();
  const seasonId = useOptaSeasonId();
  const standings = useStandingSelector();
  const isSeasonIdValid = useOptaSeasonIdCheck();

  useEffect(() => {
    if (isInitialMount) {
      // check if data has been loaded on server before very first render
      !standings.length && isSeasonIdValid && dispatch(getStandings({ competitionId, seasonId, language }));
    } else if (isSeasonIdValid) {
      dispatch(resetSeason());
      dispatch(getStandings({ competitionId, seasonId, language }));
    }
  }, [competition, seasonId]);
};

export const useStandingsCompetitionValidation = (): void => {
  const dispatch = useDispatch();
  const locale = AppLanguagesMap[useIntl().locale];
  const { seasonId = '', urlSlug = '' } = useParams();
  const competition = useCompetitionSelector();

  (competition?.type && ![
    CompetitionTypes.All,
    CompetitionTypes.League,
    CompetitionTypes.Tournament,
  ].includes(competition?.type)) && dispatch(replace(localizeRouteKey({
    pathKey: AppRoutes.Schedule.path,
    pathParams: { urlSlug, seasonId },
  }, locale)));
};

export const useStandingsPositionColor = (position = 0, startDayPosition = 0): string => {
  switch (true) {
    case position < startDayPosition: return '#008e08';
    case position > startDayPosition: return '#c91e1e';
    default: case position === startDayPosition: return '#9d9d9d';
  }
};
