import React from 'react';
import { Link } from 'react-router-dom';
import { localizeRouteKey } from '@app/helpers/localizationHelper';
import { useLanguage } from '@app/components/Hooks';

export const HOFInducteeLink = ({ slug = '', children }): React.ReactElement | null => {
  const language = useLanguage();

  return (
    <Link
      to={{
        pathname: localizeRouteKey({
          pathKey: 'routes.inductee_profile',
          pathParams: {
            urlSlug: slug,
          },
        }, language),
      }}
    >
      {children}
    </Link>
  );
};
