import { Competitions } from '@app/types/configurationTypes';
import { MatchResult, ScheduleResults } from '@app/types/matchTypes';
import { StandingsTable, TeamStats } from '@app/types/standingsTypes';
import { MilanTeamId, MilanWomenTeamId, OptaSDMilanTeamIdMap } from '@app/services/opta/constants/teamConstants';
import { convertTeamId } from '@app/services/opta/converters/teamsConverter';
import { getMenTeamLogoUrl, getWomenTeamLogoUrl } from '@app/helpers/sourceHelpers';

export function findCompetitionIdByUrlSlug(competitions: Competitions, urlSlug: string): string {
  return Object.values(competitions).find(({ url }) => url === urlSlug)?.id ?? '';
}

export function mapStandings(data): StandingsTable {
  const teams = data?.SoccerFeed?.SoccerDocument?.Team || [];
  const teamsStandings = data.SoccerFeed?.SoccerDocument?.Competition?.TeamStandings;
  const teamsRound = Array.isArray(teamsStandings) && teamsStandings.find(
    (round) => !!round.TeamRecord.find((team) => [
      `t${MilanTeamId}`, `t${MilanWomenTeamId}`,
    ].includes(team['@attributes'].TeamRef)),
  );
  const teamsRecords = teamsRound?.TeamRecord ?? teamsStandings?.TeamRecord ?? [];

  return {
    competitionGroup: teamsRound?.Round?.Name?.['@value'],
    competitionName: data?.SoccerFeed?.SoccerDocument['@attributes']?.competition_name ?? '',
    competitionId: data?.SoccerFeed?.SoccerDocument['@attributes']?.competition_id ?? '',
    seasonId: data?.SoccerFeed?.SoccerDocument['@attributes']?.season_id ?? '',
    statistics: teamsRecords.map((item): TeamStats => {
      const standing = item.Standing || {};
      const teamId = convertTeamId(item['@attributes'].TeamRef ?? '');
      return {
        id: teamId,
        logoUrl: getMenTeamLogoUrl(teamId),
        position: +standing.Position,
        startDayPosition: +standing.StartDayPosition,
        points: +standing.Points,
        played: +standing.Played,
        wons: +standing.Won,
        drawn: +standing.Drawn,
        lost: +standing.Lost,
        goalsFor: +standing.For,
        goalsAgainst: +standing.Against,
        goalsDiff: +standing.For - (+standing.Against),
        teamName: teams?.find((team) => team['@attributes'].uID === item['@attributes'].TeamRef)?.Name,
      };
    }),
  };
}

export function mapSDStandings([data, teamNames]): StandingsTable {
  const { group } = (data?.stage ?? []).reduce((acc, { division, name }) => {
    const acmGroup = division?.filter(({ ranking }) => ranking?.find(({ contestantId }) => [
      OptaSDMilanTeamIdMap.primavera_2,
      OptaSDMilanTeamIdMap.uefa_youth_league,
      OptaSDMilanTeamIdMap.futuro_seria_c,
    ].includes(contestantId)))?.[0];

    if (acmGroup) {
      acc.group = {
        name: `${name} ${acmGroup.groupName ? ` - ${acmGroup.groupName}` : ''}`,
        ranking: acmGroup.ranking,
      };
    }

    return acc;
  }, {});

  return {
    competitionName: data?.competition?.name,
    competitionGroup: group?.name,
    statistics: (group?.ranking ?? []).map((standing) => ({
      id: standing?.contestantId ?? '',
      logoUrl: getWomenTeamLogoUrl(standing?.contestantId),
      position: standing?.rank ?? 0,
      startDayPosition: standing?.lastRank ?? 0,
      points: standing?.points ?? '',
      played: standing?.matchesPlayed ?? '',
      wons: standing?.matchesWon ?? '',
      drawn: standing?.matchesDrawn ?? '',
      lost: standing?.matchesLost ?? '',
      goalsFor: standing?.goalsFor ?? '',
      goalsAgainst: standing?.goalsAgainst ?? '',
      goalsDiff: (standing?.goalsFor - standing?.goalsAgainst) ?? '',
      teamName: teamNames[standing?.contestantId]?.teamName ?? standing?.contestantShortName ?? '',
    })),
  };
}

export function mapSchedule(matchResults: MatchResult[]): ScheduleResults {
  return matchResults
    .sort((matchResult1, matchResult2) => (
      new Date(matchResult1?.matchDate).getTime() - new Date(matchResult2?.matchDate).getTime()
    ))
    .reduce((accumulator, matchResult) => {
      const matchDate = new Date(matchResult.matchDate);
      const matchDateId = `${matchDate.getFullYear()}/${matchDate.getMonth() + 1}/1`;

      if (!accumulator[matchDateId]) accumulator[matchDateId] = [];
      accumulator[matchDateId].push(matchResult);

      return accumulator;
    }, {});
}
