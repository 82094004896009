import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Devices, theme } from '@app/styles';
import { pushGAEvent } from '@app/helpers/googleHelpers';
import { getStaticImageConfig } from '@app/helpers/imageHelper';

import { GA_CATEGORY, GA_EVENT } from '@app/types/googleTypes';
import { WebPartner, WebPartnerType } from '@app/types/webTemplateTypes';

import { ColorizedExternalLink } from '@app/components/Buttons';
import { GoogleAds } from '@app/components';
import { useLanguage } from '@app/components/Hooks';
import { useUserCountry } from '@app/components/Header/HeaderHooks';

interface WebPartnerElementProps {
  data: WebPartner;
  type: string;
}

const WebPartnerElement = ({ data, type }: WebPartnerElementProps): React.ReactElement | null => {
  const {
    title, description, photo, partnerType, googleAds, redirectUrl, geo,
  } = data;
  const geoVisibility = (location = ''): boolean => {
    if (!geo?.countries?.length) return true;
    return geo?.exclude
      ? !geo?.countries?.includes(location)
      : geo?.countries?.includes(location);
  };
  const country = useUserCountry();
  const language = useLanguage();

  const [isGeoVisible] = useState(geoVisibility(country));
  const [isVisible, setIsVisible] = useState(partnerType === WebPartnerType.Cms);
  const onAdSlotRender = (isEmpty): void => { if (!isEmpty) setIsVisible(true); };

  return isGeoVisible ? (
    <Container isVisible={isVisible}>
      <PhotoBlock>
        {partnerType === WebPartnerType.GoogleAds && !!googleAds?.ads ? (
          <GoogleAds {...googleAds.ads} onSlotRender={onAdSlotRender} />
        ) : (
          <Photo
            {...getStaticImageConfig({ url: photo?.url, width: 200, height: 170 })}
            alt={photo?.description}
          />
        )}
      </PhotoBlock>
      <Info>
        <Title>{title}</Title>
        <RedDivider />
        <Description>{description}</Description>
      </Info>
      <ButtonBlock>
        <ColorizedExternalLink
          color={theme.color.freeSpeechRed}
          url={redirectUrl}
          onClick={(): void => pushGAEvent({
            event: GA_EVENT.CLICK_PARTNERS,
            category: GA_CATEGORY.PARTNERS,
            action: type,
            label: title,
            language,
          })}
        >
          <FormattedMessage id="partners.more" />
        </ColorizedExternalLink>
      </ButtonBlock>
    </Container>
  ) : null;
};

const Container = styled.div<{ isVisible: boolean }>`
  position: ${({ isVisible }): string => (isVisible ? 'relative' : 'absolute')};
  display: flex;
  flex-direction: column;
  z-index: ${({ isVisible }): string => (isVisible ? '10' : '-999999')};


  width: 130px;
  height: 310px;
  margin: 0 10px 20px 0;

  background-color: ${({ isVisible }): string => (isVisible ? '#ffffff' : 'rgba(255,255,255, 0.7)')};
  transition: all 0.3s ease;

  & > * {
    transition: opacity 0.3s ease;
    opacity: ${({ isVisible }): string => (isVisible ? '1' : '0')};
  }

  @media ${Devices.mediumMobile} {
    width: 155px;
    height: 310px;
    margin: 0 20px 20px 0;
  }

  @media ${Devices.largeMobile} {
    width: 220px;
    height: 350px;

    &:after {
      display: none;
    }
  }
`;

const PhotoBlock = styled.div`
  margin: 10px 20px 0;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${Devices.largeMobile} {
    margin: 10px 30px 0;
    height: 170px;
  }

  & > section {
    width: 100%;
  }
`;

const Photo = styled.img`
  max-width: 90%;
  max-height: 100%;
`;

const Info = styled.div`
  padding: 0 15px 0 20px;

  @media ${Devices.largeMobile} {
    padding: 0 20px 0 30px;
  }
`;

const Title = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  color: #000000;

  @media ${Devices.mediumMobile} {
    font-size: 18px;
  }
  @media ${Devices.largeMobile} {
    font-size: 20px;
  }
`;

const RedDivider = styled.div`
  margin-top: 5px;
  width: 22px;
  height: 2px;
  background-color: ${(props): string => props.theme.color.harleyDavidsonOrange};
`;

const Description = styled.p`
  margin-top: 10px;
  max-height: 80px;
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: 12px;
  line-height: 1.59;
  color: #000000;
  overflow: hidden;
`;

const ButtonBlock = styled.div`
  position: absolute;
  bottom: 20px;
  left: 15px;
  right: 20px;
  max-width: 110px;

  @media ${Devices.largeMobile} {
    left: 30px;
    right: 20px;
  }
`;

export default WebPartnerElement;
