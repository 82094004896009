import React from 'react';
import styled from 'styled-components';

import { WebPageSeparatorSizeType } from '@app/types/webTemplateTypes';
import { Devices } from '@app/styles';
import { ContainerMedium } from '@app/components/WebTemplates/components';

interface WebPageSeparatorElementProps {
  size: WebPageSeparatorSizeType;
}

const WebPageSeparatorElement = ({ size }: WebPageSeparatorElementProps): React.ReactElement => (
  <Container>
    <Separator isFullWidth={size === WebPageSeparatorSizeType.Full} />
  </Container>
);

const Container = styled(ContainerMedium)`
  position: relative;
  width: 100%;
  padding: 10px;

  @media ${Devices.desktop} {
    padding: 20px 0;
  }
`;

const Separator = styled.hr<{ isFullWidth: boolean }>`
  width: ${({ isFullWidth }): string => (isFullWidth ? '100%' : '50%')};
  height: 2px;
  color: ${(props): string => props.theme.color.darkGray};
  border: none;
  border-bottom: 2px solid rgba(9, 30, 66, 0.08);;
`;

export default WebPageSeparatorElement;
