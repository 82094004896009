import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { pushGAEvent } from '@app/helpers/googleHelpers';
import { GetAuthenticationUrl, GetRegistrationUrl } from '@app/services/salesforce/request';
import { GA_CATEGORY, GA_EVENT } from '@app/types/googleTypes';

import { useLanguage } from '@app/components/Hooks';

import { CommonButton } from '@app/components/Buttons';
import Modal from '../Modal';

const PREMIUM_NEWS_URL = 'redirect_url';

const NewsPremiumModal = React.memo((): React.ReactElement => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isPrivateRoute, seiIsPrivateRoute] = useState(false);
  const language = useLanguage();
  const history = useHistory();
  const onShowPremiumModal = (event): void => {
    if (event?.detail?.isPrivate) seiIsPrivateRoute(true);
    localStorage.setItem(PREMIUM_NEWS_URL, event?.detail?.redirectUrl);
    setIsModalOpened(true);
  };

  const onHidePremiumModal = (): void => {
    if (isModalOpened) setIsModalOpened(false);
    if (isPrivateRoute) {
      history.replace(`/${language}`);
      seiIsPrivateRoute(false);
    }
    localStorage.setItem(PREMIUM_NEWS_URL, '');
  };

  const onLoginClick = (): void => {
    pushGAEvent({
      event: GA_EVENT.CLICK_MENU,
      category: GA_CATEGORY.MY_MILAN_LOGIN,
      label: window.location.href,
      language,
    });
    window.location.href = GetAuthenticationUrl(language);
  };
  const onRegistrationClick = (): void => {
    window.location.href = GetRegistrationUrl(language);
  };

  useEffect(() => {
    window.addEventListener('showPremiumModal', onShowPremiumModal, false);
    return (): void => {
      window.removeEventListener('showPremiumModal', onShowPremiumModal, false);
    };
  }, []);

  return (
    <Modal open={Boolean(isModalOpened)} onClose={onHidePremiumModal} controls={false}>
      <PremiumContainer>
        <Title>
          <FormattedMessage id="news.premium.title" />
        </Title>
        <Description>
          <FormattedMessage id="news.premium.description" />
        </Description>
        <BackgroundImage>
          <LazyLoadImage
            src="/images/premium_modal_background.jpg"
            placeholderSrc="/images/premium_modal_background_preview.jpg"
            draggable={false}
            effect="blur"
            width="100%"
            alt="AC Milan"
          />
        </BackgroundImage>
        <LoginButton onClick={onLoginClick}>
          <FormattedMessage id="news.premium.login" />
        </LoginButton>
        <RegisterButton onClick={onRegistrationClick}>
          <FormattedMessage id="news.premium.register" />
        </RegisterButton>
        <CloseModalButton onClick={onHidePremiumModal}>
          <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.728 14.41 9.273 8l6.455-6.41c.363-.363.363-.954 0-1.273-.364-.318-.955-.363-1.274 0L8 6.727 1.59.272C1.227-.09.636-.09.317.272c-.318.364-.363.955 0 1.274L6.727 8 .272 14.41c-.363.363-.363.954 0 1.273.364.318.955.363 1.274 0L8 9.273l6.41 6.455c.363.363.954.363 1.273 0 .317-.364.363-.955.045-1.318z"
              fillRule="nonzero"
              fill="#000"
            />
          </svg>
        </CloseModalButton>
      </PremiumContainer>
    </Modal>
  );
});

export default NewsPremiumModal;

const PremiumContainer = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  align-content: center;

  width: 100%;
  max-width: 340px;
  margin: auto;
  padding: 30px 0;
  font-size: 14px;
  background: white;
  color: inherit; // Use defined on page text color
`;

const CloseModalButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;

  width: 20px;
  height: 20px;

  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  background: transparent;

  & svg {
    transition: all 0.3s linear;
  }

  &:hover svg {
    opacity: .5;
    transition: all 0.3s linear;
  }
`;

const Title = styled.h2`
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;

  padding: 0 40px;
  margin-bottom: 15px;
`;

const Description = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;

  padding: 0 40px;
`;

const BackgroundImage = styled.div`
  width: 100%;
  margin: 40px 0;
  min-height: 250px;
  transition: all 0.3s ease;
  background: transparent;
`;

const LoginButton = styled(CommonButton)`
  margin: 0 40px 15px;
  text-transform: uppercase;

  &:disabled {
    background-color: ${(props): string => props.theme.color.dimGray};
    cursor: auto;

    &:hover {
      background-color: ${(props): string => props.theme.color.dimGray};
      color: white;
      border-color: transparent;
    }
  }
`;

const RegisterButton = styled(CommonButton)`
  text-transform: uppercase;
  margin: 0 40px;

  background: transparent;
  color: ${(props): string => props.theme.color.freeSpeechRed};
  border: 1px solid ${(props): string => props.theme.color.freeSpeechRed};

  &:hover {
    transition: all 0.3s ease;
    background: ${(props): string => props.theme.color.freeSpeechRed};
    color: white;
    border: 1px solid ${(props): string => props.theme.color.freeSpeechRed};
  }
`;
