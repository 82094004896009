import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { NewsTypes } from '@app/types/newsTypes';
import { CommonButton } from '@app/components/Buttons';

import { getNewsListItems } from '@app/store/actions/newsActions';
import { useLanguage } from '@app/components/Hooks';
import {
  useNewsListHasNextItemsSelector,
  useNewsListLoadingSelector,
} from '../NewsListHooks';

interface NewsListLoadButtonProps {
  type: NewsTypes;
  onClick: () => void;
}

const NewsListLoadButton = React.memo(({ type, onClick }: NewsListLoadButtonProps): React.ReactElement | null => {
  const { categoryName = '' } = useParams();
  const language = useLanguage();
  const dispatch = useDispatch();

  const isLoading = useNewsListLoadingSelector(type);
  const isVisible = useNewsListHasNextItemsSelector(type);

  const onLoadNewsClick = (): void => {
    dispatch(getNewsListItems({ categoryName, language, type }));
    onClick && onClick();
  };

  return isVisible ? (
    <LoadButton
      onClick={onLoadNewsClick}
      isVisible={isVisible}
      isLoading={isLoading}
      disabled={isLoading}
    >
      <FormattedMessage id={isLoading ? 'buttons.loading.inProgress' : 'buttons.loading'} />
    </LoadButton>
  ) : null;
});

export default NewsListLoadButton;

const LoadButton = styled(CommonButton)<{ isLoading: boolean; isVisible: boolean}>`
  margin: 40px auto;
  text-transform: uppercase;
  transition: all .5s ease;
  opacity: ${({ isVisible }): number => (isVisible ? 1 : 0)};
  z-index: ${({ isVisible }): number => (isVisible ? 99999 : -222)};
  position: ${({ isVisible }): string => (isVisible ? 'relative' : 'absolute')};

  &:disabled {
    background-color: ${(props): string => props.theme.color.dimGray};
    cursor: auto;

    &:hover {
      background-color: ${(props): string => props.theme.color.dimGray};
      color: white;
      border-color: transparent;
    }
  }
`;
