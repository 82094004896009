import { MenCompetitionsMap, OptaSDCompetitionsMap } from '@app/services/opta/constants/competitionConstants';
import { WebTemplateData, WebEmbeddedOverlay } from '@app/types/webTemplateTypes';

export type HomeMatchBoxConfiguration = {
  gameId: string;
  competitionId: MenCompetitionsMap | OptaSDCompetitionsMap;
  seasonId: string;
}[];

export enum HomeBlocks {
  LATEST_NEWS = 'home_news_latest',
  LATEST_VIDEOS = 'home_videos_latest',
  GALLERY ='home_galleries',
  STORE = 'home_store',
  HONOURS = 'home_honours',
  SLIDER_1 = 'home_slider_1',
  SLIDER_2 = 'home_slider_2',
  SLIDER_3 = 'home_slider_3',
  SLIDER_4 = 'home_slider_4',
  LANDING_1 = 'home_landing_1',
  LANDING_2 = 'home_landing_2',
  LANDING_3 = 'home_landing_3',
  LANDING_4 = 'home_landing_4',
  LANDING_5 = 'home_landing_5',
  LANDING_6 = 'home_landing_6',
  LANDING_7 = 'home_landing_7',
  LANDING_8 = 'home_landing_8',
  LANDING_9 = 'home_landing_9',
  LANDING_10 = 'home_landing_10',
  GOOGLE_ADS_1 = 'home_ad_banner_1',
  GOOGLE_ADS_2 = 'home_ad_banner_2',
  GOOGLE_ADS_3 = 'home_ad_banner_3',
  GOOGLE_ADS_4 = 'home_ad_banner_4',
  GOOGLE_ADS_5 = 'home_ad_banner_5',
  EMBEDDED_OVERLAY = 'home_embedded_overlay',
}

export interface HomeConfiguration {
  matchBox: HomeMatchBoxConfiguration;
  sequence: HomeBlocks[];
  carousel: string;
  gameId: string;
  embeddedOverlay: WebEmbeddedOverlay;
}

export interface HomeLandingData {
  content: WebTemplateData[];
}
