import React from 'react';
import { NotFound, Sorry } from '@app/pages';
import { Errors, PageError } from '@app/types/errorTypes';

interface ErrorPageProps {
  error: Errors;
}

const ErrorPage = ({ error }: ErrorPageProps): React.ReactElement | null => {
  switch (error) {
    case PageError.NotFound:
      return (<NotFound />);
    case PageError.Sorry:
      return (<Sorry />);
    default:
      return null;
  }
};

type ValidatePageProps = React.PropsWithChildren<{
  error: Errors;
  className?: string;
}>;

const PageValidationWrapper = ({ error, children, className }: ValidatePageProps): React.ReactElement => (
  <div className={error ? '' : className}>
    {error ? (<ErrorPage error={error} />) : children}
  </div>
);

export default PageValidationWrapper;
