import * as React from 'react';
import styled from 'styled-components';

const PremiumBadge = (): React.ReactElement => (<Badge>PREMIUM</Badge>);

export default PremiumBadge;

const Badge = styled.span`
  position: relative;
  display: inline-block;
  background-color: ${(props): string => props.theme.color.cheninYellow};
  width: 80px;
  height: 20px;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  color: white;
`;
