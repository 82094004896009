import { useParams } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import { HallOfFamePlayer } from '@app/types/hallOfFameTypes';
import { AppState } from '@app/store/reducers';
import { Errors, PageError } from '@app/types/errorTypes';

import { getPlayer } from '@app/helpers/hallOfFameHelpers';

import { useIsConfigLoaded, useLanguage } from '@app/components/Hooks';
import { useHallOfFame } from '@app/pages/HallOfFame/HallOfFameHooks';
import { useTopLevelMenu, useWebTemplateError } from '@app/pages/WebTemplate/WebTemplateHooks';

import * as Translations from '@app/locales';

export const useHallOfFamePlayerProfile = (): HallOfFamePlayer | undefined => {
  const language = useLanguage();
  const { tab = '' } = useParams();

  return useSelector<AppState, HallOfFamePlayer | undefined>(
    (state) => getPlayer({ state, language, playerUrlSlug: tab }),
    shallowEqual,
  );
};

export function useHallOfFamePlayerProfileValidation(): Errors {
  const language = useLanguage();
  const { categoryName } = useParams();
  const topLevel = useTopLevelMenu()[language];
  const isIncorrectThirdLevelUrl = Translations[language]?.['hallOfFame.playerThirdLevelUrl'] !== categoryName;
  const error = useWebTemplateError();
  const isConfigLoaded = useIsConfigLoaded();
  const hallOfFame = useHallOfFame();
  const player = useHallOfFamePlayerProfile();
  const hasNoPlayerData = hallOfFame && !player;

  if (error) {
    return error;
  }

  // url is incorrect if menu items or player haven't found
  return isConfigLoaded && (!topLevel || isIncorrectThirdLevelUrl || hasNoPlayerData)
    ? PageError.NotFound : null;
}
