import React from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';

import { AppState } from '@app/store/reducers';
import { BaseNewsItem, EditorialContentTypes } from '@app/types/newsTypes';
import { GetResponsiveImageConfig } from '@app/types/imageTypes';

import { localizeRouteKey } from '@app/helpers/localizationHelper';
import { dispatchNewsPremiumClickEvent } from '@app/helpers/newsHelpers';
import { useCategoryUrl, useLanguage, usePathKey } from '@app/components/Hooks';
import { NewsItemInfo, PremiumBadge } from '@app/components';
import { GalleryIcon } from '@app/components/Icons';
import {
  Background,
  NewsInfoContainer,
  NewsItemImageContainer,
  NewsItemInfoOverlapping,
  NewsItemLinkContainer,
  NewsItemLinkContainerOverlapped,
  NewsItemMainImage,
  NewsItemVideoIcon,
  PremiumBadgeWrapper,
} from './NewsItemSections';

interface NewsItemLinkWrapperProps {
  item: BaseNewsItem;
  isOverlapped?: boolean;
  onClick?: (item: BaseNewsItem) => void;
  className?: string; // passed by styled component automatically
  children?: JSX.Element[] | JSX.Element | string | null | undefined;
}

const NewsItemLinkWrapper = ({
  item, onClick, className, children, isOverlapped,
}: NewsItemLinkWrapperProps): React.ReactElement => {
  const isLoggedIn = useSelector<AppState, boolean>((state) => state.user.isLoggedIn, shallowEqual);

  const categoryName = useCategoryUrl(item);
  const language = useLanguage();

  const Container = isOverlapped ? NewsItemLinkContainerOverlapped : NewsItemLinkContainer;

  const {
    type, publicationDate, urlSlug, isPremium,
  } = item;

  const pathProps = {
    pathKey: usePathKey(type),
    pathParams: { categoryName, publicationDate, urlSlug },
  };

  const onNewsClick = (event): void => {
    if (onClick) onClick(item);
    if (isPremium && !isLoggedIn) {
      event.preventDefault();
      dispatchNewsPremiumClickEvent(localizeRouteKey(pathProps, language));
    }
  };

  return (
    <Container onClick={onNewsClick} {...pathProps} className={className}>
      {children}
      {item?.isPremium && (
        <PremiumBadgeWrapper>
          <PremiumBadge />
        </PremiumBadgeWrapper>
      )}
    </Container>
  );
};

interface NewsItemProps {
  item: BaseNewsItem;
  onClick?: (item: BaseNewsItem) => void;
  getResponsiveImageConfig: GetResponsiveImageConfig;
  isContentGroup?: boolean;
}

export const ArticleItem = ({ item, onClick, getResponsiveImageConfig }: NewsItemProps): React.ReactElement => {
  const { formatMessage } = useIntl();
  const {
    image, title, publicationDate,
  } = item;

  return item?.type === EditorialContentTypes.PHOTO ? (
    <GalleryItemOverlapped
      item={item}
      onClick={onClick}
      getResponsiveImageConfig={getResponsiveImageConfig}
      isContentGroup
    />
  ) : (
    <NewsItemLinkWrapper item={item} onClick={onClick}>
      <NewsItemImageContainer>
        <NewsItemMainImage
          src={image?.url}
          description={image?.description}
          additionalImageProps={getResponsiveImageConfig(image?.url)}
          useLazyLoading
        />
        {item?.type === EditorialContentTypes.VIDEO && (
          <NewsItemVideoIcon src="/images/playVideo.svg" alt={formatMessage({ id: 'news.playVideo.icon.alt' })} />
        )}
      </NewsItemImageContainer>
      <NewsItemInfo time={publicationDate} title={title} background="white" color="black" />
    </NewsItemLinkWrapper>
  );
};

export const ArticleItemPartiallyOverlapped = ({
  item,
  onClick,
  getResponsiveImageConfig,
}: NewsItemProps): React.ReactElement => {
  const {
    image, title, publicationDate,
  } = item;

  return (
    <NewsItemLinkWrapper item={item} onClick={onClick} isOverlapped>
      <NewsItemImageContainer>
        <NewsItemMainImage
          src={image.url}
          description={image.description}
          additionalImageProps={getResponsiveImageConfig(image.url)}
          useLazyLoading
        />
      </NewsItemImageContainer>
      <NewsItemInfoOverlapping>
        <NewsItemInfo
          time={publicationDate}
          title={title}
          background="white"
          color="black"
          isLarge
        />
      </NewsItemInfoOverlapping>
    </NewsItemLinkWrapper>
  );
};

interface VideoItemProps extends NewsItemProps {
  className?: string; // passed by styled component automatically
  isHighlighted?: boolean;
}
export const VideoItem = ({
  item, className, isHighlighted, onClick, getResponsiveImageConfig,
}: VideoItemProps): React.ReactElement => {
  const { formatMessage } = useIntl();
  const {
    image, title, publicationDate,
  } = item;

  return (
    <NewsItemLinkWrapper item={item} onClick={onClick} className={className}>
      <NewsItemImageContainer>
        <NewsItemMainImage
          src={image.url}
          description={image.description}
          additionalImageProps={getResponsiveImageConfig(image.url)}
          useLazyLoading
        />
        <NewsItemVideoIcon src="/images/playVideo.svg" alt={formatMessage({ id: 'news.playVideo.icon.alt' })} />
      </NewsItemImageContainer>
      <NewsItemInfo
        time={publicationDate}
        title={title}
        background="transparent"
        color="white"
        isHighlighted={isHighlighted}
      />
    </NewsItemLinkWrapper>
  );
};

export const GalleryItem = ({ item, onClick, getResponsiveImageConfig }: NewsItemProps): React.ReactElement => {
  const {
    image, title, publicationDate,
  } = item;

  return (
    <NewsItemLinkWrapper item={item} onClick={onClick}>
      <NewsItemImageContainer>
        <NewsItemMainImage
          src={image.url}
          description={image.description}
          additionalImageProps={getResponsiveImageConfig(image.url)}
          useLazyLoading
        />
        <GalleryIcon />
      </NewsItemImageContainer>
      <NewsItemInfo time={publicationDate} title={title} background="white" color="black" />
    </NewsItemLinkWrapper>
  );
};

export const GalleryItemOverlapped = ({
  item,
  onClick,
  getResponsiveImageConfig,
  isContentGroup = false,
}: NewsItemProps): React.ReactElement => {
  const {
    image, title, publicationDate,
  } = item;

  return (
    <NewsItemLinkWrapper item={item} onClick={onClick}>
      <NewsItemImageContainer isContentGroup={isContentGroup}>
        <Background />
        <NewsItemMainImage
          src={image.url}
          description={image.description}
          additionalImageProps={getResponsiveImageConfig(image.url)}
          useLazyLoading
        />
        <GalleryIcon />
        <NewsInfoContainer>
          <NewsItemInfo time={publicationDate} title={title} background="transparent" color="white" />
        </NewsInfoContainer>
      </NewsItemImageContainer>
    </NewsItemLinkWrapper>
  );
};
