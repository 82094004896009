import React from 'react';
import styled from 'styled-components';
import { Image } from '@app/types/configurationTypes';

export const HOFLogo = ({ url, description, small = false }: Image & { small?: boolean }): React.ReactElement => (
  <Container small={small}>
    <Logo src={`${url}?w=512&q=100&format=auto`} alt={description} />
  </Container>
);

const Container = styled.div<{ small: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 60%;
  max-height: ${({ small }): string => (small ? '160px' : '250px')};
  aspect-ratio: 232/149;
  overflow: hidden;
  padding: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Logo = styled.img`
  width: auto;
  height: 100%;
  object-fit: contain;
`;
