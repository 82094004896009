import { SearchActions, SearchActionTypes } from '@app/store/actionTypes/searchActionTypes';

export type SearchState = {
  isSearchBarVisible: boolean | null;
  isSearchInProgress: boolean;
  result: [];
};

const initialState: SearchState = {
  isSearchBarVisible: null,
  isSearchInProgress: false,
  result: [],
};

export default (
  state = initialState,
  action: SearchActions,
): SearchState => {
  switch (action.type) {
    case SearchActionTypes.SET_SEARCH_BAR_VISIBILITY:
      return {
        ...state,
        isSearchBarVisible: action.payload,
      };
    case SearchActionTypes.SET_SEARCH_IN_PROGRESS:
      return {
        ...state,
        isSearchInProgress: action.payload,
      };
    default:
      return state;
  }
};
