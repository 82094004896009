import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import { AppState } from '@app/store/reducers';
import * as ConfigurationServices from '@app/services/kentico/configurationService';
import { LocalizedConfiguration } from '@app/types/configurationTypes';
import * as ActionTypes from '@app/store/actionTypes/configurationActionTypes';
import { reduxStore } from '@app/store';
import { AppLanguages } from '@app/constants/localizationConstants';

function setConfiguration(configuration: LocalizedConfiguration): ActionTypes.SetConfiguration {
  return {
    type: 'CONFIGURATION/SET_CONFIGURATION',
    payload: configuration,
  };
}

export const getConfiguration = (): ThunkAction<void, AppState, null, Action<string>> => (
  async (dispatch, getState): Promise<void> => {
    try {
      const languages = Object.values(AppLanguages);
      const { country } = getState().user;
      const configurationRequests = languages.map((lang) => (
        ConfigurationServices.getInitialConfiguration(lang, country)
      ));

      const configurations = await Promise.all(configurationRequests);
      const langToConfigMap = languages.reduce((acc, lang, index) => {
        acc[lang] = configurations[index];
        return acc;
      }, {} as LocalizedConfiguration);

      dispatch(setConfiguration(langToConfigMap));
    } catch (e) {
      console.error('Error on fetching configuration', e);
      // Todo clarify with PO error scenario
    }
  }
);

export function waitForConfigurationDownload(): Promise<void> {
  const state = reduxStore.store.getState();

  if (state.configuration.isConfigurationDownloadCompleted) return Promise.resolve();

  return new Promise((resolve): void => {
    const unsubscribe = reduxStore.store.subscribe(() => {
      const state = reduxStore.store.getState();

      if (state.configuration.isConfigurationDownloadCompleted) {
        resolve();
        unsubscribe && unsubscribe();
      }
    });
  });
}
