import { MilanTeamId, MilanWomenTeamId, OptaSDMilanTeamIdMap } from '@app/services/opta/constants/teamConstants';
import { AppLanguages } from '@app/constants/localizationConstants';

import { AppState } from '@app/store/reducers';
import { LanguageType } from '@app/types/localizationTypes';
import { LocalizedSubMenuItem, SubMenuItem, TeamSubMenuId } from '@app/types/configurationTypes';
import { LocalizedPlayer, Player, TeamNames } from '@app/types/teamsTypes';

import { convertTeamId } from '@app/services/opta/converters/teamsConverter';
import { findCategoryById, findCategoryBySlug } from '@app/helpers/configurationHelpers';
import { PlayerBiography, PlayerCommonStatistics } from '@app/pages/PlayerProfile/Profiles';
import { Tab } from '@app/pages/PlayerProfile/PlayersHooks';
import * as Translations from '@app/locales';

export const getTeamPositionWithOrdinal = (position: number): string => {
  const ordinals = ['th', 'st', 'nd', 'rd'];
  return position + (ordinals[((position % 100) - 20) % 10] || ordinals[position % 100] || ordinals[0]);
};

export const getTeamNameFromKentico = (teamName: string, teamId: string, teamNames: TeamNames | null): string => (
  teamNames?.[teamId]?.teamName?.length ? teamNames?.[teamId]?.teamName : teamName
);


export const isTeamMilan = (id: string): boolean => (
  [
    MilanTeamId,
    MilanWomenTeamId,
    OptaSDMilanTeamIdMap.primavera_2,
    OptaSDMilanTeamIdMap.futuro_seria_c,
    OptaSDMilanTeamIdMap.uefa_youth_league,
  ].includes(convertTeamId(id))
);

type GetLocalizedSubMenuItem = (
  state: AppState,
  locale: LanguageType,
  teamType: string,
) => LocalizedSubMenuItem | null

export const getLocalizedSubMenuItem: GetLocalizedSubMenuItem = (state, locale, teamType) => {
  const { configuration } = state;
  const navigation = configuration?.[locale]?.menu?.web_teams?.navigation;
  const subCategoryId = findCategoryBySlug<SubMenuItem>(Object.values(navigation ?? {}), teamType)?.id ?? '';

  return navigation
    ? AppLanguages.reduce((acc, language) => {
      const navigation = configuration?.[language]?.menu?.web_teams?.navigation;
      acc[language] = findCategoryById<SubMenuItem>(Object.values(navigation ?? {}), subCategoryId) ?? null;
      return acc;
    }, {} as LocalizedSubMenuItem)
    : null;
};

type GetLocalizedPlayer = (
  state: AppState,
  teamType: TeamSubMenuId,
  language: LanguageType,
  urlSlug: string,
) => LocalizedPlayer | null


export const getLocalizedPlayer: GetLocalizedPlayer = (state, teamType, language, urlSlug) => {
  const localizedTeam = state.teams.teams?.[teamType];
  const teamPlayerId = localizedTeam?.[language]?.find((teamPlayer) => (teamPlayer as Player).urlSlug === urlSlug)?.id;

  return teamPlayerId ? (
    AppLanguages.reduce((acc, language) => {
      acc[language] = localizedTeam?.[language]?.find((teamPlayer) => teamPlayer.id === teamPlayerId) as Player;
      return acc;
    }, {} as LocalizedPlayer)
  ) : null;
};

export const gepPlayerTabs = (locale: LanguageType): Tab[] => ([
  {
    name: 'player.tabs.statistics',
    component: PlayerCommonStatistics,
    caption: Translations[locale]?.['player.tabs.statistics'].toLowerCase(),
  },
  {
    name: 'player.tabs.biography',
    component: PlayerBiography,
    caption: Translations[locale]?.['player.tabs.biography'].toLowerCase(),
  },
]);
