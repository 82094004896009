import pull from 'lodash/pull';

import * as NewsServices from '@app/services/kentico/newsService';

import {
  NewsList, LocalizedArticleItem, LocalizedVideoItem, NewsTypes, LocalizedGalleryItem, GetNews,
} from '@app/types/newsTypes';
import { Action } from '@app/types/actionTypes';
import { RoutePath } from '@app/types/routerTypes';
import { LanguageType } from '@app/types/localizationTypes';
import * as ActionTypes from '@app/store/actionTypes/newsActionTypes';
import { KenticoResponse, KenticoElement } from '@app/services/kentico/types/responseTypes';
import { AppState } from '@app/store/reducers';

import AppRoutes from '@app/constants/routes';
import { LayoutConfiguration, ApplicationConfig } from '@app/constants/configurationConstants';
import { NewsNavigationTypeMap, NewsTypeMap } from '@app/constants/newsConstants';
import { AppLanguages, AppLanguagesMap } from '@app/constants/localizationConstants';

import {
  mapArticleItem, mapVideoItem, mapGallery, mapNewsItems, getNewsCategories, getCategoryUrl,
} from '@app/helpers/newsHelpers';
import { findCategoryById, findCategoryBySlug } from '@app/helpers/configurationHelpers';
import { waitForConfigurationDownload } from '@app/store/actions/configurationActions';
import { CurrentRouteParams, setCurrentRoute } from '@app/store/actions/currentRouteActions';

function setArticlesList(news: NewsList): ActionTypes.SetArticlesList {
  return {
    type: 'NEWS/SET_ARTICLES_LIST',
    payload: news,
  };
}

export const resetArticles = (): ActionTypes.ResetArticlesList => ({ type: 'NEWS/RESET_ARTICLES_LIST' });

function setArticlesLoadingState(state: boolean): ActionTypes.SetArticlesLoadingState {
  return {
    type: 'NEWS/SET_ARTICLES_LOADING_STATE',
    payload: state,
  };
}

function setLatestArticlesList(latestNews: NewsList): ActionTypes.SetLatestArticlesList {
  return {
    type: 'NEWS/SET_LATEST_ARTICLES_LIST',
    payload: latestNews,
  };
}

export const resetLatestArticles = (): ActionTypes.ResetLatestArticlesList => ({ type: 'NEWS/RESET_LATEST_ARTICLES_LIST' });

function setVideosList(videos: NewsList): ActionTypes.SetVideos {
  return {
    type: 'NEWS/SET_VIDEOS_LIST',
    payload: videos,
  };
}

export const resetVideos = (): ActionTypes.ResetVideos => ({ type: 'NEWS/RESET_VIDEOS_LIST' });

function setVideosLoadingState(state: boolean): ActionTypes.SetVideosLoadingState {
  return {
    type: 'NEWS/SET_VIDEOS_LOADING_STATE',
    payload: state,
  };
}

function setLatestVideosList(latestNews: NewsList): ActionTypes.SetLatestVideosList {
  return {
    type: 'NEWS/SET_LATEST_VIDEOS_LIST',
    payload: latestNews,
  };
}

export const resetLatestVideos = (): ActionTypes.ResetLatestVideosList => ({ type: 'NEWS/RESET_LATEST_VIDEOS_LIST' });

function setPictures(pictures: NewsList): ActionTypes.SetPictures {
  return {
    type: 'NEWS/SET_PICTURES',
    payload: pictures,
  };
}

export const resetLatestPictures = (): ActionTypes.ResetLatestPictures => ({ type: 'NEWS/RESET_LATEST_PICTURES' });

export const setLatestPictures = (latestPictures: NewsList): ActionTypes.SetLatestPictures => ({
  type: 'NEWS/SET_LATEST_PICTURES',
  payload: latestPictures,
});

export const resetPictures = (): ActionTypes.ResetPictures => ({ type: 'NEWS/RESET_PICTURES' });

function setPicturesLoadingState(state: boolean): ActionTypes.SetPicturesLoadingState {
  return {
    type: 'NEWS/SET_PICTURES_LOADING_STATE',
    payload: state,
  };
}

function setArticle(article: LocalizedArticleItem): ActionTypes.SetArticle {
  return {
    type: 'NEWS/SET_ARTICLE',
    payload: article,
  };
}

export const resetArticle = (): ActionTypes.ResetArticle => ({ type: 'NEWS/RESET_ARTICLE' });

export const setGallery = (gallery: LocalizedGalleryItem): ActionTypes.SetGallery => ({
  type: 'NEWS/SET_GALLERY',
  payload: gallery,
});

export const resetGallery = (): ActionTypes.ResetGallery => ({ type: 'NEWS/RESET_GALLERY' });

function setVideo(video: LocalizedVideoItem): ActionTypes.SetVideo {
  return {
    type: 'NEWS/SET_VIDEO',
    payload: video,
  };
}

export const resetVideo = (): ActionTypes.ResetVideo => ({ type: 'NEWS/RESET_VIDEO' });

function setNewsListPageMultiLangRoute(newsType: NewsTypes, categoryName, language): Action {
  return (dispatch, getState): void => {
    const state = getState();
    const categoryMenuItems = getNewsCategories(state, NewsNavigationTypeMap[newsType]);
    const category = findCategoryBySlug(categoryMenuItems[language], categoryName);
    const categoryId = category?.id ?? '';

    const params = AppLanguages.reduce((acc, language) => {
      acc.categoryName[language] = findCategoryById(categoryMenuItems[language], categoryId)?.url ?? '';
      return acc;
    }, { categoryName: {} } as unknown as CurrentRouteParams);

    let pathId;

    switch (newsType) {
      case NewsTypes.ARTICLES:
        pathId = AppRoutes.Articles.path;
        break;
      case NewsTypes.VIDEOS:
        pathId = AppRoutes.Videos.path;
        break;
      case NewsTypes.PICTURES:
        pathId = AppRoutes.Gallery.path;
        break;
      default:
        break;
    }

    dispatch(setCurrentRoute({
      pathId,
      params,
    }));
  };
}

interface GetNewsByType {
  type: NewsTypes;
  categoryName?: string;
  language: LanguageType;
  limit?: number;
  shouldSetCurrentRoute?: boolean;
}

// Todo: specify return type
export function getNews({
  type,
  categoryName = '',
  limit = 0,
  language,
  shouldSetCurrentRoute = false,
}: GetNewsByType) {
  return async (dispatch, getState): Promise<NewsList> => {
    await waitForConfigurationDownload();

    const state: AppState = getState();
    const newsConfiguration = state.configuration[language]?.menu?.web_news?.navigation[NewsNavigationTypeMap[type]];
    const categories = newsConfiguration?.categories || [];
    const layoutConfig = newsConfiguration && LayoutConfiguration[newsConfiguration?.layout];
    const numberDownloadedNews = state.news[type].list.items?.length ?? 0;

    const news = await NewsServices.getNewsList({
      type: [NewsTypeMap[type]],
      limit: limit || layoutConfig?.itemsToDownload || ApplicationConfig.news.defaultNewsLimit,
      skip: numberDownloadedNews,
      category: findCategoryBySlug(categories, categoryName)?.value,
      'system.language': language,
      language,
    });

    shouldSetCurrentRoute && !numberDownloadedNews
      && dispatch(setNewsListPageMultiLangRoute(type, categoryName, language));

    return mapNewsItems(news);
  };
}

export const getArticles: GetNews = ({ categoryName, language }) => (
  async (dispatch): Promise<void> => {
    dispatch(setArticlesLoadingState(true));

    try {
      const news = await dispatch(getNews({
        type: NewsTypes.ARTICLES,
        categoryName,
        language,
        shouldSetCurrentRoute: true,
      }));
      dispatch(setArticlesList(news));
    } catch (e) {
      console.error('Error on fetching articles', e);
      // Todo clarify with PO error scenario
    } finally {
      dispatch(setArticlesLoadingState(false));
    }
  }
);

export function getLatestArticles({ codeName, language }: GetLatestNewsDetails): Action {
  return async (dispatch): Promise<void> => {
    try {
      const limit = codeName
        ? ApplicationConfig.news.latestNewsLimit.articles
        : ApplicationConfig.home.latestNewsLimit.articles;
      const news = await dispatch(getNews({ type: NewsTypes.ARTICLES, limit, language }));
      news.items = news.items.filter((item) => item.codeName !== codeName);
      dispatch(setLatestArticlesList(news));
    } catch (e) {
      console.error('Error on fetching articles', e);
    }
  };
}

export const getVideos: GetNews = ({ categoryName, language }) => (
  async (dispatch): Promise<void> => {
    dispatch(setVideosLoadingState(true));

    try {
      const news = await dispatch(getNews({
        type: NewsTypes.VIDEOS,
        categoryName,
        language,
        shouldSetCurrentRoute: true,
      }));
      dispatch(setVideosList(news));
    } catch (e) {
      console.error('Error on fetching videos', e);
      // Todo clarify with PO error scenario
    } finally {
      dispatch(setVideosLoadingState(false));
    }
  }
);

export function getLatestVideos({ codeName, language }: GetLatestNewsDetails): Action {
  return async (dispatch): Promise<void> => {
    try {
      const limit = codeName
        ? ApplicationConfig.news.latestNewsLimit.videos
        : ApplicationConfig.home.latestNewsLimit.videos;
      const news = await dispatch(getNews({ type: NewsTypes.VIDEOS, limit, language }));
      news.items = news.items.filter((item) => item.codeName !== codeName);
      dispatch(setLatestVideosList(news));
    } catch (e) {
      console.error('Error on fetching videos', e);
    }
  };
}

export const getPictures: GetNews = ({ categoryName, language }) => (
  async (dispatch): Promise<void> => {
    dispatch(setPicturesLoadingState(true));

    try {
      const news = await dispatch(getNews({
        type: NewsTypes.PICTURES,
        categoryName,
        language,
        shouldSetCurrentRoute: true,
      }));
      dispatch(setPictures(news));
    } catch (e) {
      console.error('Error on fetching photo gallery', e);
      // Todo clarify with PO error scenario
    } finally {
      dispatch(setPicturesLoadingState(false));
    }
  }
);

export function getLatestPictures({ codeName, language }: GetLatestNewsDetails): Action {
  return async (dispatch): Promise<void> => {
    try {
      const limit = codeName
        ? ApplicationConfig.news.latestNewsLimit.pictures
        : ApplicationConfig.home.latestNewsLimit.pictures;

      const pictures = await dispatch(getNews({
        type: NewsTypes.PICTURES,
        limit,
        language,
      }));

      pictures.items = pictures.items.filter((item) => item.codeName !== codeName);
      dispatch(setLatestPictures(pictures));
    } catch (e) {
      console.error('Error on fetching pictures', e);
    }
  };
}

export const getNewsListItems: GetNews = ({ categoryName, language, type }) => (
  (dispatch): void => {
    switch (type) {
      case NewsTypes.VIDEOS: dispatch(getVideos({ categoryName, language })); break;
      case NewsTypes.PICTURES: dispatch(getPictures({ categoryName, language })); break;
      case NewsTypes.ARTICLES: default: dispatch(getArticles({ categoryName, language })); break;
    }
  }
);

export const resetNewsListItems = (type: NewsTypes): Action => (
  (dispatch): void => {
    switch (type) {
      case NewsTypes.VIDEOS: dispatch(resetVideos()); break;
      case NewsTypes.PICTURES: dispatch(resetPictures()); break;
      case NewsTypes.ARTICLES: default: dispatch(resetArticles()); break;
    }
  }
);

interface GetNewsDetails {
  // Important to pass parameters as object to get them correctly from the server (url params are passed on server)
  codeName: string;
  language: LanguageType;
}

interface GetLatestNewsDetails {
  // Important to pass parameters as object to get them correctly from the server (url params are passed on server)
  codeName?: string;
  language: LanguageType;
}

async function getNewsDetailsByCodeName({
  codeName, language,
}: GetNewsDetails): Promise<KenticoResponse<KenticoElement> | undefined> {
  try {
    return codeName
      ? await NewsServices.getNewsDetailsByCodeName({ language, codeName })
      : Promise.resolve(undefined);
  } catch (e) {
    console.error('Error on fetching news details', e);
    // Todo clarify with PO error scenario
  }
}

interface SetNewsLandingPageMultiLangRouteFunc {
  newsItem: LocalizedArticleItem | LocalizedVideoItem | LocalizedGalleryItem | null;
  pathId: RoutePath;
}

function setNewsLandingPageMultiLangRoute({
  newsItem, pathId,
}: SetNewsLandingPageMultiLangRouteFunc): Action {
  return async (dispatch, getState): Promise<void> => {
    await waitForConfigurationDownload();
    const state = getState();

    const categoryNameEn = getCategoryUrl({
      state,
      newsItem: newsItem?.[AppLanguagesMap.en],
      language: AppLanguagesMap.en,
    });

    const categoryNameIt = getCategoryUrl({
      state,
      newsItem: newsItem?.[AppLanguagesMap.it],
      language: AppLanguagesMap.it,
    });

    const categoryNameCn = getCategoryUrl({
      state,
      newsItem: newsItem?.[AppLanguagesMap.cn],
      language: AppLanguagesMap.cn,
    });

    dispatch(setCurrentRoute({
      pathId,
      params: {
        categoryName: {
          en: categoryNameEn,
          it: categoryNameIt,
          cn: categoryNameCn,
        },
        publicationDate: {
          en: newsItem?.[AppLanguagesMap.en]?.publicationDate ?? '',
          it: newsItem?.[AppLanguagesMap.it]?.publicationDate ?? '',
          cn: newsItem?.[AppLanguagesMap.cn]?.publicationDate ?? '',
        },
        urlSlug: {
          en: newsItem?.[AppLanguagesMap.en]?.urlSlug ?? '',
          it: newsItem?.[AppLanguagesMap.it]?.urlSlug ?? '',
          cn: newsItem?.[AppLanguagesMap.cn]?.urlSlug ?? '',
        },
      },
    }));
  };
}

interface GetNewsDetailsByUrlSlug {
  // Important to pass parameters as object to get them correctly from the server (url params are passed on server)
  urlSlug: string;
  publicationDate: string;
  language: LanguageType;
}

export function getArticleByUrlSlug({ urlSlug, publicationDate, language }: GetNewsDetailsByUrlSlug): Action {
  return async (dispatch, getState): Promise<void> => {
    const restLanguages = pull([...AppLanguages], language);
    const requestParams = {
      type: NewsTypeMap.articles,
      publicationDate,
      urlSlug,
      language,
    };

    await waitForConfigurationDownload();
    // PART 1 - get Article by url Slug and publication Date
    try {
      const data = await NewsServices.getNewsDetailsByUrlSlug(requestParams);
      const article = AppLanguages.reduce((acc, appLanguage) => {
        acc[appLanguage] = appLanguage === language ? mapArticleItem(data) : null;
        return acc;
      }, {} as LocalizedArticleItem);

      dispatch(setArticle(article));
    } catch (e) {
      console.error('Error on fetching Article', e);
      // Todo clarify with PO error scenario
    }
    // PART 2 - get Article data in another language
    try {
      const codeName = getState().news.articles.selectedItemDetails?.[language]?.codeName;

      if (codeName) {
        const data = await Promise.all(restLanguages.map((restLanguage) => (
          getNewsDetailsByCodeName({ language: restLanguage, codeName })
        )));
        const article = restLanguages.reduce((acc, restLanguage, index) => {
          acc[restLanguage] = mapArticleItem(data[index]);
          return acc;
        }, {} as LocalizedArticleItem);

        article[language] = getState().news.articles.selectedItemDetails?.[language];
        dispatch(setArticle(article));
      }
    } catch (e) {
      console.error('Error on fetching another languages Articles', e);
      // Todo clarify with PO error scenario
    }
    // PART 3 - set Article MultiLang route
    dispatch(setNewsLandingPageMultiLangRoute({
      newsItem: getState().news.articles.selectedItemDetails,
      pathId: AppRoutes.ArticleLanding.path,
    }));
  };
}

export function getVideoByUrlSlug({ urlSlug, publicationDate, language }: GetNewsDetailsByUrlSlug): Action {
  return async (dispatch, getState): Promise<void> => {
    const restLanguages = pull([...AppLanguages], language);
    const requestParams = {
      type: NewsTypeMap.videos,
      publicationDate,
      urlSlug,
      language,
    };
    // PART 1 - get Video by url Slug and publication Date
    try {
      const data = await NewsServices.getNewsDetailsByUrlSlug(requestParams);
      const video = AppLanguages.reduce((acc, appLanguage) => {
        acc[appLanguage] = appLanguage === language ? mapVideoItem(data) : null;
        return acc;
      }, {} as LocalizedVideoItem);

      dispatch(setVideo(video));
    } catch (e) {
      console.error('Error on fetching Video', e);
      // Todo clarify with PO error scenario
    }
    // PART 2 - get Video data in another language
    try {
      const codeName = getState().news.videos.selectedItemDetails?.[language]?.codeName;

      if (codeName) {
        const data = await Promise.all(restLanguages.map((lang) => (
          getNewsDetailsByCodeName({ language: lang, codeName })
        )));
        const video = restLanguages.reduce((acc, lang, index) => {
          acc[lang] = mapVideoItem(data[index]);
          return acc;
        }, {} as LocalizedVideoItem);

        video[language] = getState().news.videos.selectedItemDetails?.[language];
        dispatch(setVideo(video));
      }
    } catch (e) {
      console.error('Error on fetching another languages Videos', e);
      // Todo clarify with PO error scenario
    }
    // PART 3 - set Video MultiLang route
    dispatch(setNewsLandingPageMultiLangRoute({
      newsItem: getState().news.videos.selectedItemDetails,
      pathId: AppRoutes.VideoLanding.path,
    }));
  };
}

export function getGalleryByUrlSlug({ urlSlug, publicationDate, language }: GetNewsDetailsByUrlSlug): Action {
  return async (dispatch, getState): Promise<void> => {
    const restLanguages = pull([...AppLanguages], language);
    const requestParams = {
      type: NewsTypeMap.pictures,
      publicationDate,
      urlSlug,
      language,
    };
    // PART 1 - get Gallery by url Slug and publication Date
    try {
      const data = await NewsServices.getNewsDetailsByUrlSlug(requestParams);
      const gallery = AppLanguages.reduce((acc, appLanguage) => {
        acc[appLanguage] = appLanguage === language ? mapGallery(data?.items[0]) : null;
        return acc;
      }, {} as LocalizedGalleryItem);

      dispatch(setGallery(gallery));
    } catch (e) {
      console.error('Error on fetching Gallery', e);
      // Todo clarify with PO error scenario
    }
    // PART 2 - get Gallery data in another languages
    try {
      const codeName = getState().news.pictures.selectedItemDetails?.[language]?.codeName;

      if (codeName) {
        const restLanguagesNewsData = await Promise.all(restLanguages.map((restLanguage) => (
          getNewsDetailsByCodeName({ language: restLanguage, codeName })
        )));
        const gallery = restLanguages.reduce((acc, restLanguage, index) => {
          acc[restLanguage] = mapGallery(restLanguagesNewsData[index]?.item);
          return acc;
        }, {} as LocalizedGalleryItem);

        gallery[language] = getState().news.pictures.selectedItemDetails?.[language];
        dispatch(setGallery(gallery));
      }
    } catch (e) {
      console.error('Error on fetching another languages Galleries', e);
      // Todo clarify with PO error scenario
    }
    // PART 3 - set Gallery MultiLang route
    dispatch(setNewsLandingPageMultiLangRoute({
      newsItem: getState().news.pictures.selectedItemDetails,
      pathId: AppRoutes.GalleryLanding.path,
    }));
  };
}
