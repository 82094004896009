import styled from 'styled-components';
import { Devices } from '@app/styles';

export const StatsContainer = styled.div`
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding-top: 10px;
  color: #ffffff;
  letter-spacing: 0.21px;

  @media ${Devices.tabletLarge} {
    width: 781px;
  }
`;

export const StatsColumns = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;

  & > div {
    flex: 1;
    margin-bottom: 10px;
  }

  @media ${Devices.tabletLarge} {
    flex-direction: row;

    & > div {
      margin-right: 10px;
    }

    & > div:last-of-type {
      margin-right: 0;
    }
  }
`;

export const StatsColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  & > div {
    flex: 1;
    margin-bottom: 10px;
  }

  & > div:last-of-type {
    margin-bottom: 0;
  }
`;

export const BlockContainer = styled.div<{
  padding?: string;
}>`
  font-family: ${(props): string => props.theme.fontFamily.milan};
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  padding: ${({ padding }): string => (padding || '20px 0')};
  background-color: #ffffff;
  color: #000000;
  letter-spacing: 0.21px;
  display: flex;
  flex-direction: row;
`;

export const BlockContainerColumn = styled(BlockContainer)`
  flex-direction: column;
`;

export const StatsBlock = styled.div<{
  marginBottom?: string; marginTop?: string; alignItems?: string; borderWidth?: string;
}>`
  flex: 1;
  display: flex;
  align-items: ${({ alignItems }): string => (alignItems || 'center')};
  justify-content: center;
  margin-top: ${({ marginTop }): string => (marginTop || '0')};
  margin-bottom: ${({ marginBottom }): string => (marginBottom || '0')};
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 40px);
  border-width: ${({ borderWidth }): string => (borderWidth || '0')};
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.05);
`;

export const StatsBlockColumn = styled(StatsBlock)`
  flex-direction: column;
`;
