import React from 'react';
import styled from 'styled-components';

import { WebLanding } from '@app/types/webTemplateTypes';
import { PageTitle } from '@app/components';
import WebTemplateSelect from '../WebTemplateSelect';
import { ContainerMedium } from '../components';

interface WebLandingElementProps {
  data: WebLanding;
}

const WebLandingElement = ({ data }: WebLandingElementProps):
  React.ReactElement | null => (
  data?.isVisible ? (
    <ContainerMedium>
      {data?.name && (<PageTitle>{data?.name}</PageTitle>)}
      <Content>
        {data?.content?.map((content) => content !== null && (
          <WebTemplateSelect data={content} key={content.id} />
        ))}
      </Content>
    </ContainerMedium>
  ) : null);

const Content = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
`;

export default WebLandingElement;
