import React from 'react';
import styled from 'styled-components';

import { CircleBundleIcon, CircleInfosIcon, CirclePromoIcon } from '@app/components/Icons';
import { BaseTicketInfo } from '@app/types/ticketingTypes';

interface TicketsIconsProps {
  ticket: BaseTicketInfo;
}

const TicketsIcons = ({ ticket }: TicketsIconsProps): React.ReactElement => (
  <Circles>
    {!!ticket.packageDescription.length && (
      <Circle>
        <CircleBundleIcon />
        <CirclePopup>
          {ticket.packageDescription}
          <span />
        </CirclePopup>
      </Circle>
    )}
    {!!ticket.promoDescription.length && (
      <Circle>
        <CirclePromoIcon />
        <CirclePopup>
          {ticket.promoDescription}
          <span />
        </CirclePopup>
      </Circle>
    )}
    {!!ticket.infoDescription.length && (
      <Circle>
        <CircleInfosIcon />
        <CirclePopup>
          {ticket.infoDescription}
          <span />
        </CirclePopup>
      </Circle>
    )}
  </Circles>
);

export default TicketsIcons;

const CirclePopup = styled.span`
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.2s ease-in;
      position: absolute;
      bottom: 39px;
      left: -95px;
      width: 220px;
      background-color: ${(props): string => props.theme.color.harleyDavidsonOrange};
      padding: 15px;
      font-family: ${(props): string => props.theme.fontFamily.raleway};
      font-size: 12px;
      letter-spacing: 0.22px;
      text-align: center;
      color: #ffffff;

      & > span {
        position: absolute;
        bottom: -5px;
        left: 105px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid ${(props): string => props.theme.color.harleyDavidsonOrange};
      }
`;

const Circle = styled.div`
  position: relative;
  cursor: pointer;

  &:hover ${CirclePopup} {
    visibility: visible;
    opacity: 1;
  }
`;

const Circles = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > * {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
