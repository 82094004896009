import { LanguageType } from '@app/types/localizationTypes';
import { langCodeToKenticoLangMap } from '@app/constants/localizationConstants';
import { NewsTypeMap } from '@app/constants/newsConstants';
import sendRequest, { sendRequestTyped } from './request';
import { RequestContentType, RequestParamsInterface } from './types/requestTypes';
import { KenticoResponse, KenticoElement } from './types/responseTypes';

interface GetNewsParamsInterface {
  type: RequestContentType[];
  category?: string | string[];
  tags?: string[];
  skip: number;
  limit: number;
  language: LanguageType;
  'system.language'?: LanguageType;
}

export function getNewsList(requestParams: GetNewsParamsInterface): Promise<Record<string, unknown>> {
  const language = langCodeToKenticoLangMap[requestParams.language];
  const { category } = requestParams;
  const typesCount = requestParams.type?.length ?? 0;

  const params: RequestParamsInterface = {
    order: 'elements.publication_date[desc]',
    skip: requestParams.skip,
    limit: requestParams.limit,
    language,
    'system.language': language,
    'elements.platform[contains]': 'web',
  };

  if (typesCount > 1) params['system.type[in]'] = requestParams.type.join(',');
  if (typesCount === 1) {
    const type = requestParams?.type[0] ?? NewsTypeMap.articles;

    if (category?.length) {
      const categoryType = type === 'video' ? 'videos' : type;
      params[`elements.${categoryType}[contains]`] = Array.isArray(category) ? category.join(',') : category;
    }
    params['system.type'] = type;
  }
  if (requestParams.tags?.length && (typesCount > 1 || (typesCount === 1 && !category?.length))) {
    params['elements.tags[all]'] = requestParams.tags.join(',');
  }

  return sendRequest({ params });
}

interface GetNewsDetailsByCodeNameFunc {
  (requestParams: {
    codeName: string;
    language: LanguageType;
  }): Promise<KenticoResponse<KenticoElement>>;
}

export const getNewsDetailsByCodeName: GetNewsDetailsByCodeNameFunc = (requestParams) => sendRequestTyped({
  path: `/${requestParams.codeName}`,
  params: {
    language: langCodeToKenticoLangMap[requestParams.language],
    'system.language': langCodeToKenticoLangMap[requestParams.language],
  },
});

interface GetNewsDetailsByUrlSlugFunc {
  (requestParams: {
    type: RequestContentType;
    urlSlug: string;
    publicationDate: string;
    language: LanguageType;
  }): Promise<KenticoResponse<KenticoElement>>;
}

export const getNewsDetailsByUrlSlug: GetNewsDetailsByUrlSlugFunc = ({
  urlSlug, publicationDate, type, language,
}) => sendRequestTyped({
  params: {
    'system.type': type,
    language: langCodeToKenticoLangMap[language],
    'system.language': langCodeToKenticoLangMap[language],
    'elements.url': urlSlug,
    'elements.publication_date[gte]': `${publicationDate}T00:00:00Z`,
    'elements.publication_date[lte]': `${publicationDate}T23:59:59Z`,
  },
});
