import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { setSearchBarVisibility } from '@app/store/actions/searchActions';
import { useIsSearchBarVisible } from './SearchBarHooks';

const SearchButton = React.memo((): React.ReactElement => {
  const isActive = useIsSearchBarVisible();
  const isAnimated = isActive !== null;
  const dispatch = useDispatch();

  return (
    <SearchButtonStyled
      onClick={(): void => { dispatch(setSearchBarVisibility(!isActive)); }}
      isAnimated={isAnimated}
      isActive={isActive}
      type="button"
    >
      <SearchIconSvg viewBox="0 0 16 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="iconSvgG" transform="translate(-1116.000000, -89.000000)" strokeWidth="1.53599984">
            <g>
              <g transform="translate(1117.000000, 90.000000)">
                <g>
                  <path
                    d="M13,8.25 L8.41176471,12"
                    strokeLinecap="square"
                    transform="translate(10.323529, 9.750000) scale(1, -1) translate(-10.323529, -9.750000)"
                  />
                  <ellipse id="Oval" cx="4.58823529" cy="4.5" rx="4.58823529" ry="4.5" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </SearchIconSvg>
    </SearchButtonStyled>
  );
});

export default SearchButton;

const SearchButtonStyled = styled.button<{ isActive: boolean | null; isAnimated: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;

  width: 40px;
  height: 25px;
  margin: 0 5px;
  z-index: 2;

  background: ${({ isActive, theme }): string => (isActive ? theme.color.freeSpeechRed : 'transparent')};
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s all linear;

  @media ${Devices.desktopSmall} {
    height: 30px;
  }

  &:hover {
    background: ${(props): string => props.theme.color.freeSpeechRed};
    transition: 0.3s all linear;
  }

  & #iconSvgG {
    stroke: ${({ isActive }): string => (isActive ? 'white' : 'black')};
    transition: 0.3s all linear;
  }

  &:hover #iconSvgG {
    stroke: white;
    transition: 0.3s all linear;
  }

  &::before {
    display: ${({ isAnimated }): string => (isAnimated ? 'block' : 'none')};
    content: ' ';
    position: absolute;
    bottom: 25px;
    left: 0;

    width: 40px;
    height: 15px;

    z-index: 1;

    -webkit-animation: ${({ isActive }): string => (isActive ? 'scale-in-ver-bottom 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both' : 'scale-out-ver-bottom 0.1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both')};
    animation: ${({ isActive }): string => (isActive ? 'scale-in-ver-bottom 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both' : 'scale-out-ver-bottom 0.1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both')};

    background: ${(props): string => props.theme.color.freeSpeechRed};
  }

  &::after {
    display: ${({ isAnimated }): string => (isAnimated ? 'block' : 'none')};
    content: ' ';
    position: absolute;
    top: 25px;
    left: 0;

    width: 40px;
    height: 30px;

    z-index: 1;

    -webkit-animation: ${({ isActive }): string => (isActive ? 'scale-in-ver-top 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both' : 'scale-out-ver-top 0.1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both')};
    animation: ${({ isActive }): string => (isActive ? 'scale-in-ver-top 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;' : 'scale-out-ver-top 0.1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both')};

    background: ${(props): string => props.theme.color.freeSpeechRed};

    @media ${Devices.desktopSmall} {
      top: 30px;
    }
  }
`;

const SearchIconSvg = styled.svg`
  width: 18px;
  height: 15px;
`;
