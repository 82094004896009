import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { WebPartner } from '@app/types/webTemplateTypes';

import { ContainerLarge } from '@app/components/WebTemplates/components';
import { Section } from '@app/pages/Home/componets/HomeStyledComponents';

import { useIsPartnersVisible, usePartnersBottomData } from './PartnersHooks';
import { PartnerLogo } from './components';

const Partners = (): React.ReactElement | null => {
  const partners = usePartnersBottomData();
  const isPartnersVisible = useIsPartnersVisible();

  return isPartnersVisible ? (
    <Background>
      <ContainerLarge>
        <Section>
          <Title>{partners?.title}</Title>
          {partners?.content?.map((partnerList) => partnerList !== null && (
            <Container key={partnerList.id}>
              <PartnerRow>
                {partnerList.content.map((partner: WebPartner) => (<PartnerLogo partner={partner} />))}
              </PartnerRow>
              <Divider />
            </Container>
          ))}
        </Section>
      </ContainerLarge>
    </Background>
  ) : null;
};

const Background = styled.div`
  background-color: #ffffff;
  padding-bottom: 80px;

  @media ${Devices.tablet} {
    padding-bottom: 120px;
  }
`;

const Title = styled.h2`
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.26px;
  text-align: center;
  text-transform: uppercase;
  color: ${(props): string => props.theme.color.harleyDavidsonOrange};
  padding: 40px 0 15px;

  @media ${Devices.tablet} {
    font-size: 20px;
    letter-spacing: 0.29px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.1;
  border-bottom: solid 1px #000000;
  margin: 10px auto;

  @media ${Devices.tablet} {
    width: 505px;
    margin: 10px auto;
  }
`;

const Container = styled.div`
  &:last-of-type ${Divider} {
    display: none;
  }
`;

const PartnerRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

export default Partners;
