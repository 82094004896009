import { useContext, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { AppState } from '@app/store/reducers';
import { getSchedule, resetSeason } from '@app/store/actions/seasonActions';
import {
  getTicketingSingle,
  resetTicketingSingle,
} from '@app/store/actions/ticketingActions';
import { MatchResult } from '@app/types/matchTypes';
import { useLanguage } from '@app/components/Hooks';
import {
  useCompetitionsSelector,
  useSeasonIdActiveStateCheck,
  useOptaSeasonIdCheck,
  useOptaSeasonId,
  useCompetitionIdSelector,
  useCompetitionCategorySelector,
} from '@app/pages/Season/Season/SeasonHooks';
import { MatchStatusMap } from '@app/constants/matchConstants';
import { MountContext } from '@app/ReactContext';

export const useCalendarSelector = (): string[] => (
  useSelector<AppState, string[]>(
    (state) => Object.keys(state.season.schedule),
    shallowEqual,
  )
);

export const useResultsSelector = (id: string): MatchResult[] => (
  useSelector<AppState, MatchResult[]>(
    (state) => state.season.schedule[id] ?? [],
    isEqual,
  )
);

export const useAllResultsSelector = (): MatchResult[] => (
  useSelector<AppState, MatchResult[]>(
    (state) => Object.values(state.season.allSchedule).flat(1),
    isEqual,
  )
);

export const useNextMatchesSelector = (): MatchResult[] | null => {
  const categoryId = useCompetitionCategorySelector();
  const matches = useAllResultsSelector();

  const language = useLanguage();

  const manualNextGames = useSelector<AppState, string[] | null>(
    (state) => state.configuration[language]?.nextMatches[categoryId],
    shallowEqual,
  );
  const manualNextMatches = manualNextGames ? matches
    .filter(({ matchStatus, gameOptaId }) => (
      matchStatus === MatchStatusMap.upcoming && manualNextGames.includes(gameOptaId)
    ))
    .slice(0, 2) : null;
  const automaticNextMatches = matches
    .filter(({ matchStatus }) => (matchStatus === MatchStatusMap.upcoming))
    .slice(0, 2);

  switch (true) {
    case manualNextGames?.length === 0: return null;
    case Boolean(manualNextGames?.length): return manualNextMatches;
    default: case manualNextGames === null: return automaticNextMatches;
  }
};

export const useCheckResultsSelector = (): boolean => (
  useSelector<AppState, boolean>(
    (state) => Boolean(Object.keys(state.season.schedule ?? {}).length),
    shallowEqual,
  )
);

export const useScheduleResultsDownload = (): void => {
  const { isInitialMount } = useContext(MountContext);
  const language = useLanguage();

  const dispatch = useDispatch();
  const competitionId = useCompetitionIdSelector();
  const seasonId = useOptaSeasonId();
  const areResultsLoaded = useCheckResultsSelector();
  const isSeasonIdValid = useOptaSeasonIdCheck();
  const competitions = useCompetitionsSelector();
  const isSeasonIdActive = useSeasonIdActiveStateCheck();

  useEffect((): void => {
    if (isInitialMount) {
      // check if data has been loaded on server before very first render
      if (!areResultsLoaded && isSeasonIdValid) {
        dispatch(getSchedule({ competitionId, seasonId, language }));
        if (isSeasonIdActive) dispatch(getTicketingSingle(language));
      }
    } else if (isSeasonIdValid) {
      dispatch(resetSeason());
      dispatch(resetTicketingSingle());
      dispatch(getSchedule({ competitionId, seasonId, language }));
      if (isSeasonIdActive) dispatch(getTicketingSingle(language));
    }
  }, [competitionId, seasonId, competitions]);
};
