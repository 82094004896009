import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';

const DESKTOP_ITEM_MARGIN = '25px';
const TABLET_ITEM_MARGING = '20px';
const MOBILE_ITEM_MARGIN = '15px';

type NewsListGridProps = React.FC<{
  children?: JSX.Element | JSX.Element[] | (JSX.Element|JSX.Element[])[] | null | undefined;
  size: number;
}>

const NewsListGrid: NewsListGridProps = ({ children, size }) => {
  switch (size) {
    case 8: return (<TwoColumnGrid>{ children }</TwoColumnGrid>);
    case 10: return (<TwoThreeColumnGrid>{ children }</TwoThreeColumnGrid>);
    case 12: default: return (<ThreeColumnGrid>{ children }</ThreeColumnGrid>);
  }
};

export default NewsListGrid;

/** GRID 2x2 */
const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: ${MOBILE_ITEM_MARGIN};
  transition: 2s;

  @media ${Devices.tablet} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${TABLET_ITEM_MARGING};
  }
`;

/** GRID 3x3 */
export const ThreeColumnGrid = styled(TwoColumnGrid)`
  @media ${Devices.desktop} {
   grid-template-columns: repeat(3, 1fr);
   grid-gap: ${DESKTOP_ITEM_MARGIN};
  }
`;

/** GRID 2x3 */
const TwoThreeColumnGrid = styled.div<{ isHomePage?: boolean }>`
  display: grid;
  grid-gap: ${MOBILE_ITEM_MARGIN};
  grid-template-columns: repeat(1, minmax(200px, 1fr));

  @media ${Devices.tablet} {
    grid-gap: ${TABLET_ITEM_MARGING};
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${({ isHomePage }): string => (isHomePage ? Devices.desktop : Devices.tabletLarge)} {
    grid-gap: ${DESKTOP_ITEM_MARGIN};
    grid-template-columns: repeat(6, 1fr);
  }

  & > * {
    &:nth-child(n + 4) {
      display: ${({ isHomePage }): string => (isHomePage ? 'none' : 'block')};
    }

    @media ${Devices.tablet} {
      &:nth-child(3n + 1) {
        grid-column: auto /span 2;
      }
    }

    @media ${({ isHomePage }): string => (isHomePage ? Devices.desktop : Devices.tabletLarge)} {
      & h2 {
        min-height: 50px;
      }

      &:nth-child(n + 4) {
        display: block;
      }

      &:nth-child(5n + 1), &:nth-child(5n + 2) {
        grid-column: auto /span 3;
      }

      &:nth-child(5n + 3), &:nth-child(5n + 4), &:nth-child(5n + 5) {
        display: grid;
        grid-column: auto /span 2;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
      }
    }
  }
`;

/** GRID 2x3 - ITEM WRAPPER */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TwoThreeColumnGridItem = styled.div<{ isHomePage?: boolean }>`
  &:nth-child(n + 4) {
    display: ${({ isHomePage }): string => (isHomePage ? 'none' : 'block')};
  }

  @media ${Devices.tablet} {
    &:nth-child(3n + 1) {
      grid-column: auto /span 2;
    }
  }

  @media ${({ isHomePage }): string => (isHomePage ? Devices.desktop : Devices.tabletLarge)} {
    &:nth-child(n + 4) {
      display: block;
    }

    &:nth-child(5n + 1), &:nth-child(5n + 2) {
      grid-column: auto /span 3;
    }

    &:nth-child(5n + 3), &:nth-child(5n + 4), &:nth-child(5n + 5) {
      display: grid;
      grid-column: auto /span 2;
    }
  }
`;
