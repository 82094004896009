import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import { Gallery } from '@app/types/newsTypes';
import { useLanguage, useMenu } from '@app/components/Hooks';
import { AppState } from '@app/store/reducers';
import { isTheSameNewsLandingPage } from '@app/helpers/newsHelpers';
import { getGalleryByUrlSlug, resetGallery } from '@app/store/actions/newsActions';
import { Errors, PageError } from '@app/types/errorTypes';

export function useGallery(): Gallery | null | undefined {
  const language = useLanguage();
  return useSelector<AppState, Gallery | null | undefined>(
    (state) => state.news.pictures.selectedItemDetails?.[language],
    isEqual,
  );
}

export function useIsTheSameGallery(): boolean {
  const { categoryName = '', publicationDate = '', urlSlug = '' } = useParams();
  const language = useLanguage();
  return useSelector<AppState, boolean>(
    (state) => isTheSameNewsLandingPage({
      newsItem: state.news.pictures.selectedItemDetails,
      urlSlug,
      publicationDate,
      categoryName,
      state,
      language,
    }),
    isEqual,
  );
}

export function useDownloadGallery(): void {
  const gallery = useGallery();
  const isTheSameGallery = useIsTheSameGallery();
  const dispatch = useDispatch();
  const { publicationDate = '', urlSlug = '' } = useParams();
  const language = useLanguage();

  useEffect(() => {
    if (!gallery || !isTheSameGallery) {
      dispatch(resetGallery());
      dispatch(getGalleryByUrlSlug({
        publicationDate, urlSlug, language,
      }));
    }
  }, [urlSlug, publicationDate]);
}

export function useResetGallery(): void {
  const dispatch = useDispatch();
  useEffect(() => (): void => { dispatch(resetGallery()); }, []);
}

export function useRouterParamsValidation(): Errors {
  const menu = useMenu();
  const gallery = useGallery();
  const isTheSameGallery = useIsTheSameGallery();
  return menu && gallery && (!isTheSameGallery) ? PageError.NotFound : null;
}
