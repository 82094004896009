import { useContext, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { useParams } from 'react-router-dom';

import { AppState } from '@app/store/reducers';
import { MountContext } from '@app/ReactContext';
import {
  getTicketingCarousel,
  resetTicketingCarousel,
  getTicketingConfiguration,
  resetTicketingConfiguration,
  setTicketPageMultiLangRoute,
} from '@app/store/actions/ticketingActions';
import { TicketingTypes, LocalizedTabs, LocalizedCurrentTab } from '@app/types/ticketingTypes';
import { SecondLevelMenuItems } from '@app/components/SecondLevelMenu';
import { getLocalizedTicketsTabs, getLocalizedCurrentTab } from '@app/helpers/ticketingHelpers';
import { mapSecondLevelMenuItems } from '@app/helpers/menuHelpers';
import { useLanguage, useMenu } from '@app/components/Hooks';
import { Errors, PageError } from '@app/types/errorTypes';
import { MenuItem } from '@app/types/configurationTypes';

export function useTicketingConfigurationDownload(): void {
  const dispatch = useDispatch();
  const language = useLanguage();
  const { isInitialMount } = useContext(MountContext);

  const hasItems = useSelector<AppState, boolean>(
    (state) => !!state.ticketing.configuration[language],
    shallowEqual,
  );

  useEffect(() => {
    (!isInitialMount || !hasItems) && dispatch(getTicketingConfiguration({ language }));
    return (): void => { dispatch(resetTicketingConfiguration()); };
  }, [language]);
}

export function useTicketingCarouselDownload(): void {
  const dispatch = useDispatch();
  const language = useLanguage();

  const carousel = useSelector<AppState, string>(
    (state) => state.ticketing.configuration[language]?.carousel ?? '',
    shallowEqual,
  );

  useEffect(() => {
    carousel && dispatch(getTicketingCarousel(carousel, language));
    return (): void => { dispatch(resetTicketingCarousel()); };
  }, [carousel, language]);
}

export function useLocalizedTicketsTabs(): LocalizedTabs {
  return useSelector<AppState, LocalizedTabs>(
    (state) => getLocalizedTicketsTabs(state),
    shallowEqual,
  );
}

function useLocalizedCurrentTab(): LocalizedCurrentTab {
  const language = useLanguage();
  const { categoryName = '' } = useParams();

  return useSelector<AppState, LocalizedCurrentTab>(
    (state) => getLocalizedCurrentTab(state, categoryName, language),
    shallowEqual,
  );
}

export function useCurrentTabId(): TicketingTypes {
  const language = useLanguage();
  const currentTab = useLocalizedCurrentTab()[language];
  return currentTab?.id as TicketingTypes;
}

export function useTicketingMultiLangUrl(): void {
  const dispatch = useDispatch();
  const { categoryName = '' } = useParams();
  const language = useLanguage();
  const currentTabId = useLocalizedCurrentTab()[language]?.id;

  useEffect(() => {
    if (currentTabId) {
      dispatch(setTicketPageMultiLangRoute({ categoryName, language }));
    }
  }, [currentTabId, language]);
}

export const useSecondLevelMenuItems = (): SecondLevelMenuItems => {
  const language = useLanguage();

  return useSelector<AppState, SecondLevelMenuItems>(
    (state) => {
      const menu = state.configuration[language]?.menu?.web_ticket_office;
      return menu ? mapSecondLevelMenuItems({ menu, selectedItemId: 'web_tickets' }) : [];
    },
    isEqual,
  );
};

export const useFirstLevelMenuItems = (): MenuItem | null => {
  const language = useLanguage();

  return useSelector<AppState, MenuItem | null>(
    (state) => state.configuration[language]?.menu?.web_ticket_office ?? null,
    isEqual,
  );
};

export function useRouterParamsValidation(): Errors {
  const menu = useMenu();
  const topLevelMenu = useFirstLevelMenuItems();
  const language = useLanguage();
  const tabs = useLocalizedTicketsTabs()[language];
  const currentTabType = useCurrentTabId();

  const webTickets = topLevelMenu?.navigation?.web_tickets;
  const isTopLevel = topLevelMenu?.isVisible && webTickets?.isVisible;

  return menu && (!isTopLevel || (tabs.length > 0 && !currentTabType)) ? PageError.NotFound : null;
}
