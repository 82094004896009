import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { dispatchNewsPremiumClickEvent } from '@app/helpers/newsHelpers';
import { getLatestVideos, getVideoByUrlSlug } from '@app/store/actions/newsActions';
import { NewsNavigationTypeMap } from '@app/constants/newsConstants';
import { GoogleAdsMap } from '@app/constants/googleAdsConstants';
import { NewsTypes } from '@app/types/newsTypes';

import {
  CarouselLatestVideos,
  GoogleAds,
  Metadata,
  MetaJsonLD,
  NewsContentContainer,
  NewsContentHeading,
  SecondLevelMenu,
  SocialSharing,
  VideoPlayer,
} from '@app/components';
import { LatestNewsContainer, LatestNewsSeparator } from '@app/components/News/NewsItemSections';
import { PageValidationWrapper } from '@app/pages/components';

import { useMenuSelector, useNewsSocialPreviewImageUrl } from '@app/pages/News/NewsHooks';
import {
  useDownloadVideo,
  useResetVideo,
  useRouterParamsValidation,
  useVideo,
} from '@app/pages/VideoLanding/VideoLandingHooks';
import { useNewsBreadcrumbsJsonLD, useIsLoggedInSelector } from '@app/components/Hooks';

const VideoLanding = (): React.ReactElement => {
  useDownloadVideo();
  useResetVideo();

  const [isPrivateMode, setPrivateMode] = useState(false);

  const { secondLevelMenuItems } = useMenuSelector(NewsNavigationTypeMap.videos);
  const { categoryName = '' } = useParams();
  const video = useVideo();
  const isLoggedIn = useIsLoggedInSelector();
  const validationError = useRouterParamsValidation();
  const breadcrumbsJsonLD = useNewsBreadcrumbsJsonLD(video?.seo?.title ?? '', NewsTypes.VIDEOS);
  const socialImageUrl = useNewsSocialPreviewImageUrl(video);

  useEffect((): void => {
    if (video?.isPremium && !isLoggedIn) {
      dispatchNewsPremiumClickEvent(window.location.pathname, true);
      setPrivateMode(true);
    }
  }, []);

  return (
    <VideoPage error={validationError} isPrivate={isPrivateMode}>
      { !!video?.seo && <Metadata seo={{ ...video.seo, socialType: 'video.other', socialImageUrl }} /> }
      { !!video?.seo && <MetaJsonLD json={breadcrumbsJsonLD} /> }
      <SecondLevelMenu items={secondLevelMenuItems} />
      <NewsContentContainer>
        <VideoPlayer video={video ?? undefined} autoplay isEmbed hidePremiumLabel />
        <NewsContentHeading
          title={video?.title ?? ''}
          categoryName={categoryName}
          publicationDate={video?.publicationDate ?? ''}
          subTitle={video?.subTitle ?? ''}
          isPremium={!!video?.isPremium}
        />
        <GoogleAds {...GoogleAdsMap.videoLanding} />
        <SocialSharingContainer>
          <SocialSharing url={window.location.href} />
        </SocialSharingContainer>
        <LatestNewsSeparator />
      </NewsContentContainer>
      <LatestNews>
        <CarouselLatestVideos />
      </LatestNews>
    </VideoPage>
  );
};

VideoLanding.serverFetch = [getVideoByUrlSlug, getLatestVideos];


export default VideoLanding;

const VideoPage = styled(PageValidationWrapper)<{ isPrivate: boolean }>`
  filter: ${({ isPrivate }): string => (isPrivate ? 'blur(10px)!important' : 'none')};
  width: 100%;
  height: 100%;
  background-color: black;
  color: white;
`;

const LatestNews = styled(LatestNewsContainer)`
  max-width: 920px;
`;

const SocialSharingContainer = styled.div`
  margin-top: 45px;
`;
