import React from 'react';
import Helmet from 'react-helmet';
import { shallowEqual, useSelector } from 'react-redux';

import { SeoData } from '@app/types/configurationTypes';
import { langCodeToLanguageTerritory, AppLanguages } from '@app/constants/localizationConstants';
import { useLanguage } from '@app/components/Hooks';
import { AppState } from '@app/store/reducers';

interface Seo extends SeoData {
  socialType?: string;
}

interface Metadata {
  seo: Seo;
}

const Metadata = ({ seo }: Metadata): React.ReactElement => {
  const language = langCodeToLanguageTerritory[useLanguage()];
  const currentRoute = useSelector<AppState, AppState['currentRoute']>(
    (state) => state.currentRoute,
    shallowEqual,
  );
  const hrefLangData = !seo.canonicalTag
    ? AppLanguages.reduce((acc, lang) => {
      acc.push(<link
        key={lang}
        rel="alternate"
        href={`${window?.HOST_URL || window?.location?.origin}${currentRoute[lang]}`}
        hrefLang={lang}
      />);
      return acc;
    }, [] as React.ReactElement[])
    : null;
  const socialImageUrl = seo.socialImageUrl?.length
    ? `${seo.socialImageUrl}?w=1200&h=630&format=auto`
    : `${window?.HOST_URL || window?.location?.origin}/images/social/acmilan.png`;
  const title = seo.socialTitle || seo.title;
  const description = (seo.socialDescription || seo.description).slice(0, 200);

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link
        rel="canonical"
        href={(seo.canonicalTag || window?.REQUEST_URL || (
          `${window.location.origin}${window.location.pathname}`
        ))}
      />
      { hrefLangData }
      {!!seo.meta && (<meta name="robots" content="noindex, nofollow" />)}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta property="twitter:image" content={socialImageUrl} />
      <meta property="og:type" content={seo.socialType || 'website'} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={window?.REQUEST_URL || window?.location?.href} />
      <meta property="og:description" content={description} />
      <meta property="og:locale" content={language} />
      <meta property="og:site_name" content="AC Milan" />
      <meta name="msapplication-TileImage" content={socialImageUrl} />
      <meta property="og:image" itemProp="image" content={socialImageUrl} />
      <meta property="og:image:width" content="600" />
      <meta property="og:image:height" content="315" />
    </Helmet>
  );
};

export default Metadata;
