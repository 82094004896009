import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { VideoItem } from '@app/types/newsTypes';
import { AppState } from '@app/store/reducers';
import { useLanguage, useMenu } from '@app/components/Hooks';
import { isTheSameNewsLandingPage } from '@app/helpers/newsHelpers';
import { getVideoByUrlSlug, resetVideo } from '@app/store/actions/newsActions';
import { Errors, PageError } from '@app/types/errorTypes';

export function useVideo(): VideoItem | null | undefined {
  const language = useLanguage();
  return useSelector<AppState, VideoItem | null | undefined>(
    (state) => state.news.videos.selectedItemDetails?.[language],
    shallowEqual,
  );
}
export function useIsTheSameVideo(): boolean {
  const { categoryName = '', publicationDate = '', urlSlug = '' } = useParams();
  const language = useLanguage();
  return useSelector<AppState, boolean>(
    (state) => isTheSameNewsLandingPage({
      newsItem: state.news.videos.selectedItemDetails,
      urlSlug,
      publicationDate,
      categoryName,
      state,
      language,
    }),
    isEqual,
  );
}

export function useDownloadVideo(): void {
  const video = useVideo();
  const isTheSameVideo = useIsTheSameVideo();
  const dispatch = useDispatch();
  const { publicationDate = '', urlSlug = '' } = useParams();
  const language = useLanguage();

  useEffect(() => {
    if (!video || !isTheSameVideo) {
      dispatch(resetVideo());
      dispatch(getVideoByUrlSlug({
        publicationDate, urlSlug, language,
      }));
    }
  }, [urlSlug, publicationDate]);
}

export function useResetVideo(): void {
  const dispatch = useDispatch();
  useEffect(() => (): void => { dispatch(resetVideo()); }, []);
}

export function useRouterParamsValidation(): Errors {
  const menu = useMenu();
  const video = useVideo();
  const isTheSameVideo = useIsTheSameVideo();
  return menu && video && (!isTheSameVideo) ? PageError.NotFound : null;
}
