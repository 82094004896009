import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { format, parse } from 'date-fns';

import { Devices } from '@app/styles';
import { mapLanguageTypeToDateFnsLocale } from '@app/helpers/localizationHelper';
import { useLanguage } from '@app/components/Hooks';
import { GoogleAds, MetaJsonLD } from '@app/components';
import { GoogleAdsMap } from '@app/constants/googleAdsConstants';
import SingleMatch from './components/SingleMatch';
import { useSportEventJsonLD, useSingleTickets, useTicketingSingleDownload } from './SingleHooks';

const GoogleAdsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-bottom: 20px;

  @media ${Devices.desktop} {
    flex-direction: row;
    width: 480px;
    margin: 0;
  }
`;

const Single = (): React.ReactElement => {
  useTicketingSingleDownload();

  const language = useLanguage();
  const singleTickets = useSingleTickets();
  const months = singleTickets && Object.keys(singleTickets).sort();
  const sportEventSchemaJsonLD = useSportEventJsonLD();

  return (
    <Container>
      { !!sportEventSchemaJsonLD.length && <MetaJsonLD json={sportEventSchemaJsonLD} /> }
      {months && months.length > 0 ? months?.map((month, monthIndex) => (
        <div key={month}>
          <Month>
            {format(parse(month, 'yyyy-MM', new Date()), 'MMMM', {
              locale: mapLanguageTypeToDateFnsLocale(language),
            })}
          </Month>
          <TicketsSingleMatchContainer>
            {singleTickets && singleTickets[month]?.map((matchTickets, ticketIndex) => (
              <>
                <SingleMatch matchTickets={matchTickets} key={matchTickets.gameOptaId} />
                {monthIndex === 0 && ticketIndex === 0 && (
                  <GoogleAds {...GoogleAdsMap.tickets} Container={GoogleAdsContainer} />
                )}
              </>
            ))}
          </TicketsSingleMatchContainer>
        </div>
      )) : (
        <>
          <NoEvents>
            <FormattedMessage id="ticketing.noEvents" />
          </NoEvents>
          <NoEventsStay>
            <FormattedMessage id="ticketing.noEventsStay" />
          </NoEventsStay>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  position: relative;
  margin: 30px 0 35px;
  color: #ffffff;
  letter-spacing: 0.21px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  z-index: 20;

  @media ${Devices.desktop} {
    margin: 30px 0 70px;
  }
`;

const Month = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 18px;
  letter-spacing: 0.11px;
  color: ${(props): string => props.theme.color.cheninYellow};
  margin-bottom: 15px;
  text-transform: uppercase;
`;

const TicketsSingleMatchContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media ${Devices.desktop} {
    margin: 30px 0 20px;
  }
`;

const NoEvents = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-weight: bold;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: 0.11px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
`;

const NoEventsStay = styled(NoEvents)`
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-weight: normal;
  text-transform: none;
`;

export default Single;
