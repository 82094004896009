export function openCenteredPopup(url: string, title: string, w: number, h: number): void {
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
  const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width;
  const height = window.innerHeight || document.documentElement.clientHeight || window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(url, title, `scrollbars=yes,menubar=no,toolbar=no,status=no, width=${w / systemZoom}, height=${h / systemZoom}, top=${top}, left=${left}`);

  if (window.focus && newWindow) newWindow.focus();
}
