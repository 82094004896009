import React, { useState } from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { Gallery } from '@app/types/newsTypes';

import { getGalleryPreviewConfig, getResponsiveImageConfig } from '@app/helpers/imageHelper';
import { dispatchNewsPremiumClickEvent } from '@app/helpers/newsHelpers';
import { useIsLoggedInSelector } from '@app/components/Hooks';

import { GalleryModalContainer, PremiumBadgeWrapper } from '@app/components/News/NewsItemSections';
import { Modal, GalleryCarousel, PremiumBadge } from '@app/components';
import Image from '@app/components/Image';

interface GalleryPreviewProps {
  gallery: Gallery;
}

const GalleryPreview = ({ gallery }: GalleryPreviewProps): React.ReactElement => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const isLoggedIn = useIsLoggedInSelector();

  const mainImage = gallery?.mainImage ?? gallery?.photos?.[0];
  const photosCount = gallery?.photos?.length > 2 ? gallery?.photos?.length - 2 : 0;

  const onGalleryPreviewClick = (): void => {
    if (gallery?.isPremium && !isLoggedIn) {
      dispatchNewsPremiumClickEvent(window.location.pathname);
    } else {
      setIsModalOpened(true);
    }
  };

  return (
    <>
      <GalleryPreviewSection onClick={onGalleryPreviewClick} title={gallery?.title ?? ''}>
        <ImageContainer>
          <MainImage
            src={mainImage?.url ?? ''}
            description={mainImage?.description ?? ''}
            additionalImageProps={getResponsiveImageConfig(mainImage?.url ?? '', { 602: 505, 376: 330, 0: 210 })}
          />
        </ImageContainer>
        <InfoSection>
          <ImageContainer>
            <ImageElement
              src={gallery?.photos?.[0]?.url}
              description={gallery?.photos?.[0]?.description}
              additionalImageProps={getGalleryPreviewConfig(gallery?.photos?.[0]?.url)}
            />
          </ImageContainer>
          <Info count={photosCount}>
            <ImageContainer>
              <ImageElement
                src={gallery?.photos?.[1]?.url}
                description={gallery?.photos?.[1]?.description}
                additionalImageProps={getGalleryPreviewConfig(gallery?.photos?.[1]?.url)}
              />
            </ImageContainer>
          </Info>
        </InfoSection>
        {gallery?.isPremium && (
          <PremiumBadgeWrapper>
            <PremiumBadge />
          </PremiumBadgeWrapper>
        )}
      </GalleryPreviewSection>
      <Modal open={isModalOpened} onClose={(): void => setIsModalOpened(false)}>
        <GalleryModalContainer>
          <GalleryCarousel preview={gallery} />
        </GalleryModalContainer>
      </Modal>
    </>
  );
};

export default GalleryPreview;

const ImageContainer = styled.div`
  height: 100%;
  overflow: hidden;
`;

const MainImage = styled(Image)`
  height: auto!important;
  width: auto!important;
  min-height: 100%!important;
  min-width: 100%!important;
  left: 50%!important;
  transform: translateX(-50%)!important;
  transition: transform 2000ms ease-out!important;
`;

const ImageElement = styled(Image)`
  transition: transform 2000ms ease-out;
`;

const GalleryPreviewSection = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  height: calc(100vw * 9/16);

  @media ${Devices.responsiveContent} {
    height: 505px;
  }

  & > * {
    flex: 0 0 50%;
  }

   &:hover ${MainImage} {
    transform: translateX(-50%) scale(1.069)!important;
  }

  &:hover ${ImageElement} {
    transform: scale(1.069)!important;
  }
`;

const InfoSection = styled.div`
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & > * {
    flex: 0 0 50%;
  }
`;

const Info = styled.div<{ count: number }>`
  position: relative;
  padding-top: 5px;

  &::after {
    content: "${({ count }): string => (count > 0 ? `+${count}` : '')}";
    position: absolute;
    top: 5px;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    color: white;
    display: ${({ count }): string => (count > 0 ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    font-size: 20px;

    @media ${Devices.tablet} {
      font-size: 26px;
    }

    @media ${Devices.responsiveContent} {
      font-size: 36px;
    }
  }
`;
