import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { ContainerLarge } from '@app/components/WebTemplates/components';

interface WebEmbeddedElementProps {
  content: string;
}

const WebEmbeddedElement = ({ content }: WebEmbeddedElementProps): React.ReactElement => {
  const embeddedRef = useRef<HTMLDivElement>(document.createElement('div'));

  useEffect(() => {
    embeddedRef.current.appendChild(
      document.createRange().createContextualFragment(Buffer.from(content, 'base64').toString()),
    );

    /** Instagram manual re-process for dynamic embeds support
     * https://stackoverflow.com/questions/27408917/instagram-embeds-not-working-when-adding-embeds-dynamically */
    // eslint-disable-next-line no-unused-expressions
    window.instgrm?.Embeds?.process();

    /** Playbuzz manual re-render for dynamic embeds support */
    // eslint-disable-next-line no-unused-expressions
    window.Playbuzz?.render();

    return (): void => { embeddedRef.current.innerHTML = ''; };
  }, [content]);

  return (<Container ref={embeddedRef} />);
};

export default WebEmbeddedElement;

const Container = styled(ContainerLarge)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > * {
    width: 100%;

    &.video {
      height: 0;
      overflow: hidden;
      padding-top: 56.25%;
      background: white;
      position: relative;

      & > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
`;
