import styled from 'styled-components';

import { Devices } from '@app/styles';
import Image from '@app/components/Image';
import { LocalizedLink } from '@app/components/Localization';

export const NewsItemImageContainer = styled.div<{ isContentGroup?: boolean }>`
  overflow: hidden;
  position: relative;
  flex: 0 0 auto;
  min-height: 50px; // required for correct lazy-load on tablets and desktop
  max-height: ${({ isContentGroup }): string => (isContentGroup ? '350px' : '100%')};
`;

export const NewsItemMainImage = styled(Image)`
  transition: transform 2000ms ease-out;
`;

export const NewsItemLinkContainer = styled(LocalizedLink)`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 100%;

  &:hover ${NewsItemMainImage} {
    transform: scale(1.1,1.1);
  }
`;

export const NewsInfoContainer = styled.div`
  position: absolute;
  top: calc(100% - 121px); // width of info container with 3 rows header
  z-index: 2;

  @media ${Devices.tablet} {
    top: calc(100% - 136px);
  }
`;

export const NewsItemVideoIcon = styled.img`
  position: absolute;
  left: 20px;
  bottom: 20px;
  height: 20px;

  @media ${Devices.mediumMobile} {
    left: 25px;
    height: 34px;
  }
`;

export const NewsItemLinkContainerOverlapped = styled(NewsItemLinkContainer)`
  --news-info-height: 105px; // height of item with max 2 line header. We have to know height to make correct gaps between items
  margin-bottom: calc(-1 * var(--news-info-height) / 2);

  @media ${Devices.tablet} {
    --news-info-height: 133px;;
  }
`;

export const NewsItemInfoOverlapping = styled.div`
  transform: translateY(-50%);
  width: 90%;
  margin: auto;
  height: var(--news-info-height);
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 32%, #000000);
  z-index: 1;
`;

export const GalleryModalContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LatestNewsContainer = styled.section`
  padding-bottom: 20px;
  background-color: inherit;
  margin: 0 auto;

  @media ${Devices.tablet} {
    padding-bottom: 50px;
  }
`;

export const LatestNewsSeparator = styled.hr`
  border: none;
  border-bottom: 1px solid gray;
  margin: 50px 0;
`;

export const PremiumBadgeWrapper = styled.div<{ left?: boolean }>`
  position: absolute;
  top: 15px;
  left: ${({ left }): string => (left ? '15px' : 'unset')};
  right: ${({ left }): string => (left ? 'unset' : '15px')};
  z-index: 9;
`;
