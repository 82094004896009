import { findCategoryById, findCategoryBySlug } from '@app/helpers/configurationHelpers';
import { AppLanguages } from '@app/constants/localizationConstants';
import { WebTemplatePagesSecondLevelMenuId } from '@app/constants/configurationConstants';

import { AppState } from '@app/store/reducers';
import { LanguageType } from '@app/types/localizationTypes';
import {
  BaseMenuItem, LocalizedMenuItem, LocalizedSubMenuItem, MenuItem, SubMenuItem,
} from '@app/types/configurationTypes';
import {
  News, PageType, WebTabItem, WebTabs, WebTemplateData, WebTemplateTypes, LocalizedWebTemplateData, UrlParams,
} from '@app/types/webTemplateTypes';

type GetTopLevelMenu = (
  state: AppState,
  topLevel: string,
  language: LanguageType,
) => LocalizedMenuItem

export const getTopLevelMenu: GetTopLevelMenu = (state, topLevel, language): LocalizedMenuItem => {
  const { configuration } = state;
  const menuItems = Object.values(configuration[language]?.menu ?? {});
  const topLevelMenuId = findCategoryBySlug<MenuItem>(menuItems, topLevel)?.id ?? '';

  return AppLanguages.reduce((acc, language) => {
    const menu = configuration[language]?.menu;
    acc[language] = findCategoryById<MenuItem>(Object.values(menu ?? {}), topLevelMenuId) ?? null;
    return acc;
  }, {} as LocalizedMenuItem);
};

type GetSecondLevelMenu = (
  state: AppState,
  topLevel: string,
  secondLevel: string,
  language: LanguageType,
) => LocalizedSubMenuItem

export const getSecondLevelMenu: GetSecondLevelMenu = (state, topLevel, secondLevel, language) => {
  const topLevelMenu = getTopLevelMenu(state, topLevel, language);
  const navigation = Object.values(topLevelMenu[language]?.navigation ?? {});
  const secondLevelMenuId = findCategoryBySlug<SubMenuItem>(navigation, secondLevel)?.id ?? '';

  return AppLanguages.reduce((acc, language) => {
    const topLevelMenuItems = Object.values(topLevelMenu?.[language]?.navigation ?? {});
    acc[language] = findCategoryById<SubMenuItem>(topLevelMenuItems, secondLevelMenuId) ?? null;
    return acc;
  }, {} as LocalizedSubMenuItem);
};

type GetMenuSubItem = (data: {
  state: AppState;
  topLevel: string;
  secondLevel: string;
  categoryName?: string;
  language: LanguageType;
}) => MenuItem | BaseMenuItem | null

export const getMenuSubItem: GetMenuSubItem = ({
  state, topLevel, secondLevel, categoryName, language,
}) => {
  const topLevelMenu = getTopLevelMenu(state, topLevel, language)?.[language];
  const secondLevelMenuData = getSecondLevelMenu(state, topLevel, secondLevel, language)?.[language];
  const secondLevelMenu = secondLevelMenuData?.type === 'related_menu_item' ? undefined : secondLevelMenuData;
  const categories = secondLevelMenu?.categories
    ?.find((item) => item?.url === categoryName);

  return categories || secondLevelMenu || topLevelMenu || null;
};

type GetLastLevelMenu = (params: {
  state: AppState;
  language: LanguageType;
  urlParams: UrlParams;
}) => MenuItem | BaseMenuItem | null

export const getLastLevelMenu: GetLastLevelMenu = ({ state, language, urlParams }) => {
  const { topLevel = '', secondLevel = '', categoryName } = urlParams;
  const topLevelMenu = getTopLevelMenu(state, topLevel, language)[language];
  const secondLevelMenuData = getSecondLevelMenu(state, topLevel, secondLevel, language)[language];
  const secondLevelMenu = secondLevelMenuData?.type === 'related_menu_item' ? undefined : secondLevelMenuData;

  const lastLevelMenu = secondLevelMenu?.categories
    .find((item) => item?.url === categoryName) ?? secondLevelMenu;
  return lastLevelMenu ?? topLevelMenu ?? null;
};

interface LocalizedWebTemplateDataParams {
  state: AppState;
  language: LanguageType;
  urlParams: UrlParams;
}

type GetLocalizedWebTemplateData = (params: LocalizedWebTemplateDataParams) => LocalizedWebTemplateData | null
export const getLocalizedWebTemplateData: GetLocalizedWebTemplateData = ({ language, state, urlParams }) => {
  const lastLevelMenu = getLastLevelMenu({ state, language, urlParams });
  const data = state.webTemplate.templates[lastLevelMenu?.id ?? ''];
  return data ?? null;
};

type GetWebTemplateData = (params: LocalizedWebTemplateDataParams) => WebTemplateData[] | null
export const getWebTemplateData: GetWebTemplateData = (params) => {
  const localizedWebTemplateData = getLocalizedWebTemplateData(params);
  return localizedWebTemplateData?.[params.language] ?? null;
};

function findTabs(templateData: WebTemplateData[]): WebTabs | null {
  const tabs = templateData?.find((data) => data?.type === WebTemplateTypes.WebTabs
    || (data?.type === WebTemplateTypes.WebLanding && findTabs(data.content)));
  return tabs ? tabs as WebTabs : null;
}

type GetCurrentTab = (params: {
  state: AppState;
  language: LanguageType;
  urlParams: UrlParams;
}) => Partial<Record<LanguageType, WebTabItem | null>>

export const getCurrentLocalizedTab: GetCurrentTab = ({ language, state, urlParams }) => {
  const { categoryName, tab } = urlParams;
  const localizedWebTemplateData = getLocalizedWebTemplateData({ state, language, urlParams });
  const tabUrl = tab || categoryName;
  const webTemplateData = localizedWebTemplateData?.[language];
  const tabs = webTemplateData && findTabs(webTemplateData);
  const currentTabId = tabs?.tabs?.find((tabItem) => tabItem.url === tabUrl)?.id;

  return AppLanguages.reduce((acc, language) => {
    const webTemplateData = localizedWebTemplateData?.[language];
    const tabs = webTemplateData && findTabs(webTemplateData);

    acc[language] = tabs?.tabs?.find((tabItem) => tabItem.id === currentTabId)
      ?? null;
    return acc;
  }, {} as unknown as Partial<Record<LanguageType, WebTabItem | null>>);
};

type IsQuestion = (secondLevelMenuId: string, questionSlug: string) => boolean;

export const isQuestion: IsQuestion = (secondLevelMenuId, questionSlug) => (
  Boolean(secondLevelMenuId === WebTemplatePagesSecondLevelMenuId.FAQ_MENU && questionSlug)
);

type IsHallOfFame = (secondLevelMenuId: string) => boolean;

export const isHallOfFame: IsHallOfFame = (secondLevelMenuId) => (
  secondLevelMenuId === WebTemplatePagesSecondLevelMenuId.HALL_OF_FAME
);

type PageTypeCheckProps = (secondLevelMenuId: string, slug?: string) => boolean;

export const isHallOfFamePlayer: PageTypeCheckProps = (secondLevelMenuId, slug) => (
  Boolean(secondLevelMenuId === WebTemplatePagesSecondLevelMenuId.HALL_OF_FAME && slug)
);

export const isHallOfFameInductee: PageTypeCheckProps = (secondLevelMenuId, slug) => (
  Boolean(secondLevelMenuId === WebTemplatePagesSecondLevelMenuId.HALL_OF_FAME_INDUCTEES && slug)
);

export const isRosterArchive: PageTypeCheckProps = (secondLevelMenuId) => (
  Boolean(secondLevelMenuId === WebTemplatePagesSecondLevelMenuId.MEN_FIRST_TEAM_ARCHIVE)
);

type GetNewsDataById = (params: {
  state: AppState;
  id: string;
  language: LanguageType;
}) => News | null

export const getNewsDataById: GetNewsDataById = ({ state, id, language }) => (
  state.webTemplate.newsData[language]?.[id] ?? null
);

interface GetPageType {
  id: string;
  categoryName?: string;
  tab?: string;
}

export const getPageType = ({ id, categoryName = '', tab = '' }: GetPageType): PageType => {
  switch (true) {
    case isQuestion(id, tab):
      return PageType.Question;
    case isHallOfFameInductee(id, categoryName):
      return PageType.HallOfFameInductee;
    case isHallOfFamePlayer(id, tab):
      return PageType.HallOfFamePlayer;
    case isHallOfFame(id):
      return PageType.HallOfFame;
    case isRosterArchive(id):
      return PageType.RosterArchive;
    default:
      return PageType.Template;
  }
};
