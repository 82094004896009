import { WebTemplateTypes } from '@app/types/webTemplateTypes';
import { MenuItemTypes } from '@app/types/configurationTypes';
import { ElementValue, ElementValueTypes } from './elementTypes';

export enum SystemTypesEnum {
  news = 'news',
  video = 'video',
  photoGallery = 'photo_gallery',
  configuration = 'configuration',
  competition = 'competition',
  standings = 'opta_standings',
  matchBox = 'opta_matchbox',
  player = 'player',
  team = 'team',
}

// it is a hack to merge 2 enum https://github.com/microsoft/TypeScript/issues/17592#issuecomment-449440944
export type SystemTypes = SystemTypesEnum | WebTemplateTypes | MenuItemTypes;
export const systemTypes = { ...SystemTypesEnum, ...WebTemplateTypes, ...MenuItemTypes };

interface KenticoResponsePagination {
  skip: number;
  limit: number;
  count: number;
  next_page: string;
}

interface KenticoItemSystem {
  id: string;
  name: string;
  codename: string;
  language: string;
  type: SystemTypes;
  sitemap_locations: unknown[];
  last_modified: string;
}

export interface KenticoItem<T extends KenticoElement> {
  system: KenticoItemSystem;
  elements: T;
}

export interface KenticoResponse<T extends KenticoElement, S = unknown> {
  item?: KenticoItem<T>;
  items: KenticoItem<T>[];
  modular_content: S;
  pagination: KenticoResponsePagination;
}

export interface KenticoElement {
  [propName: string]: ElementValue<ElementValueTypes> | undefined;
}
