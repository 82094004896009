export const DevicesSize = {
  mobileMedium: 375,
  mobileLarge: 520,
  tabletSmall: 600,
  tabletMedium: 834,
  newsContent: 920, // Special size for News content container
  desktop: 1024,
  desktopSmall: 1280,
  desktopMedium: 1440,
};

export const Devices = {
  // mobile view is default
  mediumMobile: `(min-width: ${DevicesSize.mobileMedium}px)`,
  largeMobile: `(min-width: ${DevicesSize.mobileLarge}px)`,
  tablet: `(min-width: ${DevicesSize.tabletSmall}px)`,
  tabletLarge: `(min-width: ${DevicesSize.tabletMedium}px)`,
  responsiveContent: `(min-width: ${DevicesSize.newsContent}px)`, // Special size for News content container
  desktop: `(min-width: ${DevicesSize.desktop}px)`,
  desktopSmall: `(min-width: ${DevicesSize.desktopSmall}px)`,
  desktopMedium: `(min-width: ${DevicesSize.desktopMedium}px)`,
};

export const Orientation = {
  landscape: '(orientation: landscape)',
  portrait: '(orientation: portrait)',
};
