import React from 'react';
import { useLocation } from 'react-router-dom';
import { WebHallOfFameInductee } from '@app/types/webTemplateTypes';
import { HOFInducteesGrid } from './hof.inductees.grid';

interface Props { inductees: WebHallOfFameInductee[] | undefined | null }

export const HOFInducteesList = ({ inductees }: Props): React.ReactElement => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const yearFilter = params.get('year') || 'all';

  const allYears = (inductees || [])
    .map(({ year }) => Number(year))
    .filter((year, i, array) => array.indexOf(year) === i)
    .sort((a, b) => b - a);

  const years = yearFilter === 'all' ? allYears : allYears
    .filter((year) => yearFilter === `${year}`);

  return (
    <>
      {years.map((year) => (
        <HOFInducteesGrid
          inductees={(inductees || []).filter((inductee) => Number(inductee.year) === year)}
          year={year}
          position={params.get('position') || 'all'}
          key={year}
        />
      ))}
    </>
  );
};
