import React from 'react';

import { NewsPageProps } from '@app/types/newsTypes';

import { SecondLevelMenu } from '@app/components';
import { useNewsNavigationTypeSelector, useMenuSelector } from '../NewsHooks';

const NewsPageSecondLevelMenu = React.memo(({ type }: NewsPageProps): React.ReactElement | null => {
  const navigation = useNewsNavigationTypeSelector(type);
  const items = useMenuSelector(navigation).secondLevelMenuItems;
  return items ? (<SecondLevelMenu items={items} />) : null;
});

export default NewsPageSecondLevelMenu;
