import React from 'react';
import styled from 'styled-components';
import { format, isValid, parseISO } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';

import { useLanguage } from '@app/components/Hooks';
import { mapLanguageTypeToDateFnsLocale } from '@app/helpers/localizationHelper';
import { BlockContainerColumn } from '@app/components/Statistics';
import { DateFormatMap } from '@app/constants/dateTimeConstants';
import { usePlayer } from '../PlayersHooks';

interface InfoLineProps {
  message: string;
  value?: string;
}

export const InfoLine = ({ message, value }: InfoLineProps): React.ReactElement => (
  <>
    {value && (
    <Info>
      <span>
        <FormattedMessage id={message} />
        :
      </span>
      <span>{value}</span>
    </Info>
    )}
  </>
);

const PlayerBiography = (): React.ReactElement => {
  const { formatMessage } = useIntl();
  const language = useLanguage();
  const player = usePlayer();

  const biography = player?.biography ?? '';
  const parsedBirthDate = parseISO(player?.birthDate ?? '');
  const birthData = isValid(parsedBirthDate) ? format(parsedBirthDate, DateFormatMap[language], {
    locale: mapLanguageTypeToDateFnsLocale(language),
  }) : '';
  const parsedJoinDate = parseISO(player?.joinDate ?? '');
  const joinDate = isValid(parsedJoinDate) ? format(parsedJoinDate, 'yyyy') : '';

  return (
    <BlockContainerColumn padding="20px">
      <MainInfo>
        <Name>
          <span>{player?.firstName}</span>
          <span>{player?.lastName}</span>
        </Name>
        {player?.number && (<Number>{`#${player?.number}`}</Number>)}
      </MainInfo>
      <InfoLine message="player.biography.birthDate" value={birthData} />
      <InfoLine message="player.biography.birthPlace" value={player?.birthPlace} />
      <InfoLine message="player.biography.nationality" value={player?.nationality} />
      <InfoLine message="player.biography.height" value={player?.height} />
      <InfoLine message="player.biography.weight" value={player?.weight} />
      <InfoLine
        message="player.biography.preferredFoot"
        value={formatMessage({
          id: `player.biography.preferredFoot.${(player?.preferredFoot ?? 'right').toLowerCase()}`,
        })}
      />
      <InfoLine message="player.biography.atMilanSince" value={joinDate} />
      {/* eslint-disable-next-line react/no-danger */}
      <Biography dangerouslySetInnerHTML={{ __html: biography }} />
    </BlockContainerColumn>
  );
};

const MainInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Name = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 30px;
  font-weight: bold;
`;

const Number = styled.div`
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 50px;
  opacity: 0.2;
`;

const Info = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 12px;

  & > span:first-of-type {
    font-family: ${(props): string => props.theme.fontFamily.milan};
    font-weight: bold;
    margin-right: 5px;
  }
`;

const Biography = styled.p`
  text-align: justify;
`;

export default PlayerBiography;
