import trimEnd from 'lodash/trimEnd';

import { Action } from '@app/types/actionTypes';
import { RoutePath } from '@app/types/routerTypes';
import { LanguageType } from '@app/types/localizationTypes';
import * as ActionTypes from '@app/store/actionTypes/currentRouteActionTypes';
import { CurrentRouteState } from '@app/store/reducers/currentRouteReducer';
import { GA_EVENT } from '@app/types/googleTypes';

import { AppLanguages } from '@app/constants/localizationConstants';
import { localizeRouteKey } from '@app/helpers/localizationHelper';
import { pushGAEvent } from '@app/helpers/googleHelpers';

export function setCurrentRouteActionCreator(route: CurrentRouteState): ActionTypes.SetCurrentRoute {
  return {
    type: 'ROUTE/SET_CURRENT_ROUTE',
    payload: route,
  };
}

export type CurrentRouteParams = {
  [name: string]: {
    [name in LanguageType]: string
  };
};

interface SetCurrentRouteParams {
  pathId: RoutePath;
  params?: CurrentRouteParams;
}

export function setCurrentRoute({ pathId, params }: SetCurrentRouteParams): Action {
  return (dispatch, getState): void => {
    // get current page params for provided language
    const langToRouteMap = AppLanguages.reduce((langToRouteMap, lang) => {
      const pathParams = params && Object
        .entries(params)
        .reduce((pathParams, [paramName, value]) => ({
          ...pathParams,
          [paramName]: value[lang],
        }), {});

      // format current page link for provided language
      const path = localizeRouteKey({
        pathKey: pathId,
        pathParams,
      }, lang);

      return {
        ...langToRouteMap,
        [lang]: trimEnd(path, '/'),
      };
    }, {} as CurrentRouteState);

    if (getState().currentRoute.en === langToRouteMap.en) return;

    const URL = langToRouteMap.en.slice(1).split('/');
    const pageCategory = URL[1]?.replace(/-/g, '_');
    const pageName = URL[2] ? URL.slice(2).reverse().join('_').replace(/-/g, '_') : pageCategory;

    pushGAEvent({
      event: GA_EVENT.PAGE_LOAD, // eslint-disable-next-line @typescript-eslint/camelcase
      page_name: !URL[1] ? 'homepage' : pageName, // eslint-disable-next-line @typescript-eslint/camelcase
      page_category: !URL[1] ? 'home' : pageCategory,
    });
    dispatch(setCurrentRouteActionCreator(langToRouteMap));
  };
}
