import React from 'react';
import styled from 'styled-components';

import { WebFaqGroup, WebTemplateData } from '@app/types/webTemplateTypes';
import { Devices } from '@app/styles';
import WebTemplateSelect from '../WebTemplateSelect';
import { ContainerMedium } from '../components';

const WebFaqCategoryGroupElement: React.FC<{data: WebFaqGroup}> = ({ data }) => {
  const items = data?.content?.filter((item) => item);
  const firstColumn: WebTemplateData[] = [];
  const secondColumn: WebTemplateData[] = [];

  if (!items.length) return null;

  items.forEach((item, index) => {
    if (!(index % 2)) {
      firstColumn.push(item);
    } else {
      secondColumn.push(item);
    }
  });

  return (
    <ContainerMedium>
      <Content>
        <Column isOnlyColumn={!secondColumn.length}>
          {
            firstColumn.map((item) => (
              <WebTemplateSelect data={item} key={item?.id} />
            ))
          }
        </Column>
        <Column>
          {
            secondColumn.map((item) => (
              <WebTemplateSelect data={item} key={item?.id} />
            ))
          }
        </Column>
      </Content>
    </ContainerMedium>
  );
};


const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Column = styled.div<{isOnlyColumn?: boolean}>`
  flex-basis: 100%;

  @media ${Devices.tabletLarge} {
    flex-basis: ${({ isOnlyColumn }): string => (isOnlyColumn ? '100%' : '50%')};

     &:first-child {
       padding-right: ${({ isOnlyColumn }): string => (isOnlyColumn ? '0px' : '20px')};
     }

     &:last-child {
       padding-left: 20px;
     }
  }
`;

export default WebFaqCategoryGroupElement;
