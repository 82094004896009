import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Devices } from '@app/styles';

import AppRoutes from '@app/constants/routes';
import { localizeRouteKey } from '@app/helpers/localizationHelper';
import { useLanguage, useMenu } from '@app/components/Hooks';

import { ExpandArrow } from '@app/components/Icons';

const RosterArchiveDropdown = React.memo((): React.ReactElement => {
  const { categoryName = '' } = useParams();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const language = useLanguage();
  const menu = useMenu();

  const menFirstTeamMenu = menu?.web_teams?.navigation?.squadre_primasquadramaschile;

  const archiveMenu = menu?.web_roster_archive;
  const archiveMenFirstTeamMenu = archiveMenu?.navigation?.web_archive_men_first_team;
  const archiveMenFirstTeamPages = archiveMenFirstTeamMenu?.categories ?? [];

  const selectedActiveCategoryName = menFirstTeamMenu?.categories
    ?.find(({ url }) => url === categoryName) ? categoryName : null;
  const selectedArchiveCategoryName = archiveMenFirstTeamPages
    ?.find(({ url }) => url === categoryName)?.isVisible ? categoryName : null;
  const selectedCategoryName = selectedActiveCategoryName ?? selectedArchiveCategoryName ?? 'placeholder';

  const onArchiveYearSelect = (categoryName): void => {
    dispatch(push(localizeRouteKey({
      pathKey: AppRoutes.WebTemplateTab.path,
      pathParams: {
        topLevel: archiveMenu?.url ?? '',
        secondLevel: archiveMenFirstTeamMenu?.url ?? '',
        categoryName,
        tab: '',
      },
    }, language)));
  };

  const onCurrentYearSelect = (): void => {
    dispatch(push(localizeRouteKey({
      pathKey: AppRoutes.Teams.path,
      pathParams: {
        teamType: menFirstTeamMenu?.url ?? '',
        categoryName: menFirstTeamMenu?.categories?.[0]?.url ?? '',
      },
    }, language)));
  };

  const onSelectChange = (e): void => {
    const { value } = e.target;

    if (value === 'teams') {
      onCurrentYearSelect();
    } else {
      onArchiveYearSelect(value);
    }
  };

  return (
    <Dropdown>
      <Select value={selectedCategoryName} onChange={onSelectChange}>
        <option selected disabled value="placeholder">
          {formatMessage({ id: 'teams.archive.selectYear' })}
        </option>
        <option value="teams">
          {archiveMenFirstTeamPages[0]?.related?.name ?? new Date().getFullYear()}
        </option>
        {archiveMenFirstTeamPages
          .slice(1)
          .filter(({ isVisible }) => isVisible)
          .map(({ id, url, name }) => (
            <option key={id} value={url}>{name}</option>
          ))}
      </Select>
      <ExpandArrow />
    </Dropdown>
  );
});

export default RosterArchiveDropdown;

const Dropdown = styled.div`
  position: relative;

  & > svg {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
`;

const Select = styled.select`
  width: 100%;
  min-width: 100px;
  height: 30px;
  padding: 0 20px 0 5px;
  border-radius: 2px;
  border: solid 1px #dfdbdb;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: ${(props): string => props.theme.fontFamily.milan};

  @media ${Devices.mediumMobile} {
    width: auto;
  }
`;
