import { Action } from '@app/types/actionTypes';
import {
  UserActionTypes,
  LoginInterface,
  LogoutInterface,
  SetUserCountryInterface,
  SetBrowserLanguageInterface,
} from '@app/store/actionTypes/userActionTypes';
import { AppLanguageTypes } from '@app/types/localizationTypes';
import { UserStateType } from '@app/types/userTypes';
import { GetToken, RefreshToken, Logout } from '@app/services/salesforce/request';

enum StatusEnum {
  OK = 'OK',
  Error = 'ERROR',
}

const ACCESS_TOKEN_STORE_NAME = 'accessToken';
const REFRESH_TOKEN_STORE_NAME = 'refreshToken';

function setLoggedIn(LoginInfo: UserStateType): LoginInterface {
  return {
    type: UserActionTypes.LOGIN,
    payload: LoginInfo,
  };
}

function setLoggedOut(): LogoutInterface {
  return {
    type: UserActionTypes.LOGOUT,
    payload: { isLoggedIn: false, userLoginInfo: {} },
  };
}

export const setBrowserLanguage = (language: AppLanguageTypes): SetBrowserLanguageInterface => ({
  type: UserActionTypes.SET_BROWSER_LANGUAGE,
  payload: language,
});

export const setUserCountry = (country = ''): SetUserCountryInterface => ({
  type: UserActionTypes.SET_USER_COUNTRY,
  payload: country,
});

export function detectUser(dispatch): void {
  const storedAccessToken = localStorage.getItem(ACCESS_TOKEN_STORE_NAME);
  const storedRefreshToken = localStorage.getItem(REFRESH_TOKEN_STORE_NAME);

  if (storedAccessToken && storedRefreshToken) {
    dispatch(setLoggedIn({
      isLoggedIn: true,
      userLoginInfo: {
        accessToken: storedAccessToken,
        refreshToken: storedRefreshToken,
      },
    }));
  }
}

export function LoginUser(Code: string): Action {
  return (dispatch): void => {
    GetToken(Code)
      .then((response: Response) => response.json())
      .then((data) => {
        if (data?.access_token && data?.refresh_token && data?.id_token) {
          // Set Auth data in Redux for current session
          dispatch(setLoggedIn({
            isLoggedIn: true,
            userLoginInfo: {
              accessToken: data.access_token,
              refreshToken: data.refresh_token,
            },
          }));
          // Set Auth data to localStorage to recover session after browser restart
          localStorage.setItem(ACCESS_TOKEN_STORE_NAME, data.access_token);
          localStorage.setItem(REFRESH_TOKEN_STORE_NAME, data.refresh_token);

          return { status: StatusEnum.OK };
        }
        const returnErrStatus = { status: StatusEnum.Error, message: data.error_description };
        throw returnErrStatus;
      })
      .catch((error) => {
        console.error('Login error: ', error);
        // Todo clarify with PO error scenario
      });
  };
}

export function RefreshUserToken(callback?: Function): Action {
  return (dispatch, getState): void => {
    const state = getState();
    if (state.user?.userLoginInfo?.refreshToken) {
      RefreshToken(state.user?.userLoginInfo?.refreshToken)
        .then((response: Response) => response.json())
        .then((data) => {
          if (data?.access_token && data?.refresh_token) {
            // Set Auth data in Redux for current session
            dispatch(setLoggedIn({
              isLoggedIn: true,
              userLoginInfo: {
                accessToken: data.access_token,
                refreshToken: data.refresh_token,
              },
            }));
          }
        })
        .catch((error) => {
          console.error('Refresh token error: ', error);
          // Todo clarify with PO error scenario
        })
        .finally(() => callback && callback());
    }
  };
}

export function LogoutUser(callback?: Function): Action {
  return (dispatch, getState): void => {
    const state = getState();
    if (state.user?.userLoginInfo?.accessToken) {
      Logout(state.user.userLoginInfo.accessToken)
        .then((response) => response.text())
        .then((data) => {
          if (data.includes('error')) {
            const returnErrStatus = { status: StatusEnum.Error, message: data };
            throw returnErrStatus;
          }
        })
        .catch((error) => {
          console.error('Logout error: ', error);
          // Todo clarify with PO error scenario
        })
        .finally(() => {
          // Remove Auth data from Redux
          dispatch(setLoggedOut());
          // Remove Auth data from localStorage
          localStorage.removeItem(ACCESS_TOKEN_STORE_NAME);
          localStorage.removeItem(REFRESH_TOKEN_STORE_NAME);

          callback && callback();
        });
    } else dispatch(setLoggedOut());
  };
}
