import * as React from 'react';
import styled from 'styled-components';
import { Devices } from '@app/styles';

type PlayersCategory = React.FC<{
  type: string;
  isLarge?: boolean;
}>

const PlayersCategory: PlayersCategory = ({ type, isLarge = false, children }) => (
  <Container>
    <PlayerType>{type}</PlayerType>
    <Items isLarge={isLarge}>
      {children}
    </Items>
  </Container>
);

const Container = styled.div`
  font-family: ${(props): string => props.theme.fontFamily.milan};
  width: 100%;
  margin-bottom: 40px;
`;

const Items = styled.ul<{isLarge: boolean}>`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  @media ${({ isLarge }): string => (isLarge ? '(min-width: 670px)' : Devices.largeMobile)} {
    justify-content: flex-start;
  }
`;

const PlayerType = styled.h2`
  font-size: 22px;
  letter-spacing: 0.17px;
  color: ${(props): string => props.theme.color.cheninYellow};
  text-transform: uppercase;
`;

export default PlayersCategory;
