import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { TeamStats } from '@app/types/standingsTypes';
import { isTeamMilan, getTeamNameFromKentico } from '@app/helpers/teamHelpers';
import { useStandingSelector, useStandingsPositionColor } from '@app/pages/Season/Standings/StandingsHooks';
import { useIsLoadingSelector } from '@app/pages/Season/Season/SeasonHooks';
import { useTeamNamesSelector } from '@app/pages/Teams/TeamsHooks';
import { Loader } from '@app/components';

interface TableProps {
  isWidget?: boolean;
}

interface Group {
  group?: string;
  color?: string;
}

interface TableTeamRowProps extends TableProps, Group {
  team: TeamStats;
}

const PositionIcon = ({ position, startDayPosition }): React.ReactElement => {
  const color = useStandingsPositionColor(position, startDayPosition);
  return (<Position color={color} />);
};

const TableLoading = ({ isWidget = false }): React.ReactElement => (
  <>
    <Table>
      <TableHeading isWidget={isWidget} />
    </Table>
    <Loader />
  </>
);

const TableHeading = ({ isWidget = false }): React.ReactElement => (
  <TableHeadRow isWidget={isWidget}>
    <TableCell><FormattedMessage id="standings.position" /></TableCell>
    <TableCell />
    <TableCell />
    <TableCell><FormattedMessage id="standings.points" /></TableCell>
    <TableCell><FormattedMessage id="standings.played" /></TableCell>
    <TableCell><FormattedMessage id="standings.wons" /></TableCell>
    <TableCell><FormattedMessage id="standings.drawn" /></TableCell>
    <TableCell><FormattedMessage id="standings.lost" /></TableCell>
    <TableCell isVisible={!isWidget}><FormattedMessage id="standings.goalsFor" /></TableCell>
    <TableCell isVisible={!isWidget}><FormattedMessage id="standings.goalsAgainst" /></TableCell>
    <TableCell><FormattedMessage id="standings.goalsDiff" /></TableCell>
  </TableHeadRow>
);

const TableTeamRow = ({
  team, isWidget, group, color,
}: TableTeamRowProps): React.ReactElement => {
  const teamNames = useTeamNamesSelector();
  return (
    <TableBodyRow key={team.id} isMilan={isTeamMilan(team.id)} isWidget={isWidget}>
      <TableBodyCell>
        <TableGroup group={group} style={{ background: color }} />
        {team.position}
      </TableBodyCell>
      <TableBodyCell>
        <PositionIcon position={team.position} startDayPosition={team.startDayPosition} />
      </TableBodyCell>
      <TableBodyCell>
        <Team isWidget={isWidget}>
          <img src={team.logoUrl} alt={team.teamName} />
          <span>{getTeamNameFromKentico(team.teamName, team.id, teamNames)}</span>
        </Team>
      </TableBodyCell>
      <TableBodyCell>{team.points}</TableBodyCell>
      <TableBodyCell>{team.played}</TableBodyCell>
      <TableBodyCell>{team.wons}</TableBodyCell>
      <TableBodyCell>{team.drawn}</TableBodyCell>
      <TableBodyCell>{team.lost}</TableBodyCell>
      <TableBodyCell isVisible={!isWidget}>{team.goalsFor}</TableBodyCell>
      <TableBodyCell isVisible={!isWidget}>{team.goalsAgainst}</TableBodyCell>
      <TableBodyCell>{team.goalsDiff}</TableBodyCell>
    </TableBodyRow>
  );
};

const TableCompetitionName = ({ name = '', group = '' }): React.ReactElement => (
  <CompetitionName>
    {name}
    {group && (
      <CompetitionGroup>
        &nbsp;&minus;&nbsp;
        {group}
      </CompetitionGroup>
    )}
  </CompetitionName>
);

interface TableGroupProps extends TableProps {
  group: string;
  color?: string;
  results: TeamStats[];
}

const TableGroupResults = ({
  isWidget, results, group = '', color = '#bdbdbd',
}: TableGroupProps): React.ReactElement | null => (results?.length ? (
  <>
    <TableRow isWidget={isWidget}>
      <ResultsGroup style={{ color }}>
        <FormattedMessage id={`standings.group.${group}`} />
      </ResultsGroup>
    </TableRow>
    {results.map((team) => (
      <TableTeamRow key={team.id + group} team={team} isWidget={isWidget} group={group} color={color} />
    ))}
  </>
) : null);

const TableGroupedResults = ({ isWidget, results }: TableProps & { results: TeamStats[] }): React.ReactElement => (
  <>
    <TableGroupResults group="round16" results={results.slice(0, 8)} isWidget={isWidget} color="#0E2CDD" />
    <TableGroupResults group="playoff" results={results.slice(8, 24)} isWidget={isWidget} color="#FA0DFF" />
    <TableGroupResults group="disqualified" results={results.slice(24, 36)} isWidget={isWidget} />
  </>
);


const StandingsTable = ({ isWidget = false }: TableProps): React.ReactElement => {
  const standings = useStandingSelector();
  const isLoading = useIsLoadingSelector();

  return (
    <Container>
      {isLoading
        ? <TableLoading />
        : standings.map(({ statistics, ...props }) => (
          <TableWrapper key={props.competitionName}>
            <TableCompetitionName name={props.competitionName} group={props.competitionGroup} />
            <Table>
              <TableHeading isWidget={isWidget} />
              {(Number(props.competitionId) === 5) && (Number(props.seasonId) >= 2024)
                ? <TableGroupedResults results={statistics} isWidget={isWidget} />
                : statistics.map((team) => (
                  <TableTeamRow key={team.id + props.competitionName} team={team} isWidget={isWidget} />
                ))}
            </Table>
          </TableWrapper>
        ))}
    </Container>
  );
};

export default StandingsTable;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 940px;
  margin: auto;
`;

const CompetitionName = styled.p`
  font-size: 14px;
  font-style: italic;
  font-weight: bold;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  margin-bottom: 10px;
  padding: 0 10px;
`;


const CompetitionGroup = styled.span`
  font-size: 14px;
  font-style: italic;
  font-weight: normal;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  margin-bottom: 10px;
`;


const ResultsGroup = styled(CompetitionGroup)`
  font-size: 14px;
  font-style: italic;
  font-weight: normal;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const TableGroup = styled.div<Group>`
  display: ${({ group }): string => (group ? 'block' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 6px;
  height: 100%;
  font-style: normal;
  background-color: #bdbdbd;
`;


const TableWrapper = styled.div`
  margin-top: 20px;

  &:first-of-type {
    margin-top: 0;
  }
`;

const Table = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TableRow = styled.div<{ isWidget?: boolean }>`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  color: inherit;
`;

const TableHeadRow = styled(TableRow)`
  background-color: ${({ isWidget }): string => (isWidget ? 'white' : 'black')};
  color: ${({ isWidget }): string => (isWidget ? 'black' : 'white')};
`;

const TableBodyRow = styled(TableRow)<{ isMilan?: boolean }>`
  background-color: ${({ isMilan, isWidget, theme }): string => {
    if (isMilan) return theme.color.harleyDavidsonOrange;
    return isWidget ? theme.color.whiteSmoke : 'transparent';
  }};
  color: ${({ isMilan }): string => (isMilan ? 'white' : 'inherit')};
  margin-bottom: ${({ isWidget }): string => (isWidget ? '5px' : 'none')};;
`;

const TableCell = styled.div<{ isVisible?: boolean }>`
  display: flex;
  flex: 0 0 8%;
  justify-content: center;
  align-items: center;
  padding: 15px 5px;
  min-width: 30px;

  font-style: italic;
  font-weight: normal;
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  text-align: center;

  &:first-child {
    padding: 15px 5px 15px 10px;
  }
  &:last-child {
    padding: 15px 10px 15px 5px;
  }
  &:nth-child(3) {
    flex: 1;
    text-align: left;
    justify-content: flex-start;
    word-break: break-word;
  }
  &:nth-child(9),
  &:nth-child(10) {
    display: none;
  }

  @media ${Devices.tabletLarge} {
    flex: 0 0 7%;
    padding: 15px 10px;
    min-width: 35px;

    &:first-child {
      text-align: left;
      padding: 15px 5px 15px 15px;
    }
    &:last-child {
      padding: 15px 15px 15px 5px;
    }
    &:nth-child(9),
    &:nth-child(10) {
      display: ${({ isVisible }): string => (isVisible ? 'flex' : 'none')};
    }
  }
`;

const TableBodyCell = styled(TableCell)`
  position: relative;
  font-style: normal;

  &:nth-child(2) {
    width: 10px;
    min-width: 10px;
    max-width: 10px;
    padding-left: 0;
    padding-right: 0;
  }

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    font-weight: bold;
    font-family: ${(props): string => props.theme.fontFamily.milan};
  }
`;

const Team = styled.div<{ isWidget?: boolean }>`
  display: flex;
  align-items: center;

  & img {
    width: 30px;
    height: 30px;
    margin-right: 10px;

    @media ${Devices.tablet} {
      margin-right: ${({ isWidget }): string => (isWidget ? '10' : '30')}px;
    }
  }
`;


const Position = styled.span<{ color: string }>`
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  border: none;
  background-color: ${({ color }): string => color};
`;
