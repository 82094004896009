import React from 'react';

import { HomeBlocks } from '@app/types/homeConfigurationTypes';
import { useHomeGoogleAdsDownload, useHomeGoogleAdsSelector } from '@app/pages/Home/HomeHooks';
import { GoogleAds } from '@app/components';
import { Section } from './HomeStyledComponents';

type HomeGoogleAdsProps = React.PropsWithChildren<{
  id: HomeBlocks;
}>

const HomeGoogleAds = React.memo(({ id }: HomeGoogleAdsProps): React.ReactElement | null => {
  const ads = useHomeGoogleAdsSelector(id);
  useHomeGoogleAdsDownload(id);

  return ads ? (<GoogleAds {...ads} Container={Section} />) : null;
});

export default HomeGoogleAds;
