import { GA_CATEGORY, GA_EVENT } from '@app/types/googleTypes';
import { NewsTypes, EditorialContentTypes } from '@app/types/newsTypes';
import { NewsSubMenuId } from '@app/types/configurationTypes';

export const NewsTypeMap: {
  [key in NewsTypes]: EditorialContentTypes;
} = {
  articles: EditorialContentTypes.NEWS,
  videos: EditorialContentTypes.VIDEO,
  pictures: EditorialContentTypes.PHOTO,
};

export const NewsNavigationTypeMap: {
  [key in NewsTypes]: NewsSubMenuId;
} = {
  articles: 'news_tutte_news',
  videos: 'news_video',
  pictures: 'news_fotogallery',
};

export const GA_NEWS_EVENT = {
  [NewsTypes.ARTICLES]: GA_EVENT.CLICK_NEWS,
  [NewsTypes.VIDEOS]: GA_EVENT.CLICK_VIDEO,
  [NewsTypes.PICTURES]: GA_EVENT.CLICK_PHOTO,
};


export const GA_NEWS_CATEGORY = {
  [NewsTypes.ARTICLES]: GA_CATEGORY.NEWS,
  [NewsTypes.VIDEOS]: GA_CATEGORY.VIDEOS,
  [NewsTypes.PICTURES]: GA_CATEGORY.PHOTOS,
};
