import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { NavigationLinkParams } from '@app/types/routerTypes';

import { Devices } from '@app/styles';

import { LocalizedLink } from '@app/components/Localization';

type HomeSectionProps = React.PropsWithChildren<{
  sectionNameId: string;
  linkData?: {
    linkParams: NavigationLinkParams;
    linkNameId: string;
    onClick?: () => void;
  };
  isDarkTheme?: boolean;
}>

const HomeSection = ({
  sectionNameId, linkData, isDarkTheme, children,
}: HomeSectionProps): React.ReactElement => (
  <>
    <SectionName isDarkTheme={!!isDarkTheme}>
      <FormattedMessage id={sectionNameId} />
    </SectionName>
    {
      !!linkData && (
        <SeeMoreLink
          {...linkData.linkParams}
          isDarkTheme={!!isDarkTheme}
          onClick={(): void => { linkData.onClick && linkData.onClick(); }}
        >
          <FormattedMessage id={linkData.linkNameId} />
        </SeeMoreLink>
      )
    }
    {children}
  </>
);

const SectionName = styled.h2<{ isDarkTheme: boolean }>`
  font-size: 18px;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  display: inline-block;
  margin: 0 15px 15px 0;
  color: ${({ isDarkTheme }): string => (isDarkTheme ? 'white' : 'black')};
  
  @media ${Devices.tablet} {
    font-size: 26px;
    margin: 0 25px 20px 0;
  }
`;

const SeeMoreLink = styled(LocalizedLink)<{ isDarkTheme: boolean }>`
  color: ${({ isDarkTheme, theme }): string => (isDarkTheme ? theme.color.barleyCorn : theme.color.harleyDavidsonOrange)};
  letter-spacing: 0.25px;
  border-left: solid 0.5px ${({ isDarkTheme }): string => (isDarkTheme ? 'rgb(255,255,255, 0.5)' : 'rgb(20,20,20, 0.5)')};
  padding-left: 15px;
  font-size: 12px;
  text-transform: capitalize;
  
  @media ${Devices.tablet} {
    font-size: 18px;
    padding-left: 25px;
  }
`;

export default HomeSection;
