import React, { useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';
import pull from 'lodash/pull';
import styled from 'styled-components';

import { AppState } from '@app/store/reducers';
import { GA_EVENT, GA_CATEGORY } from '@app/types/googleTypes';
import { SwitchLanguageType } from '@app/types/localizationTypes';
import { SwitchLanguages } from '@app/constants/localizationConstants';

import { pushGAEvent } from '@app/helpers/googleHelpers';
import { useLanguage } from '@app/components/Hooks';

import Language, { ExpandArrowState } from './LanguageItem';

interface UseLangSwitcher {
  isOpened: boolean;
  openLangList: { (): void };
  closeLangList: { (): void };
}

const useLangSwitcherState = (): UseLangSwitcher => {
  const [isOpened, setIsOpened] = useState(false);
  const openLangList = (): void => setIsOpened(true);
  const closeLangList = (): void => setIsOpened(false);

  return { isOpened, openLangList, closeLangList };
};

const LanguageSwitcher: React.FC = () => {
  const dispatch = useDispatch();
  const currentRoute = useSelector<AppState, AppState['currentRoute']>(
    (state) => state.currentRoute,
    shallowEqual,
  );
  const { isOpened, openLangList, closeLangList } = useLangSwitcherState();
  const language = useLanguage();

  const selectLang = (lang: SwitchLanguageType): void => {
    const googleTag = window.googletag ?? null;

    dispatch(replace(currentRoute[lang]));
    closeLangList();
    googleTag && googleTag.cmd.push(() => {
      googleTag.pubads().refresh();
    });
  };

  const languages = pull([...SwitchLanguages], language);
  languages.unshift(language);

  return (
    <LanguageControl
      onClick={openLangList}
      onMouseLeave={closeLangList}
    >
      <SelectedLanguage
        isOpened={isOpened}
        lang={language}
        expandArrow={ExpandArrowState.Closed}
      />
      <LangList isOpened={isOpened}>
        {
          languages.map((lang, index) => (
            <Language
              key={lang}
              lang={lang}
              expandArrow={!index ? ExpandArrowState.Opened : ExpandArrowState.Hidden}
              onClick={(e): void => {
                selectLang(lang);
                pushGAEvent({
                  event: GA_EVENT.CLICK_MENU,
                  category: GA_CATEGORY.LANGUAGE_ICON,
                  label: lang,
                  language,
                });
                e.stopPropagation();
              }}
            />
          ))
        }
      </LangList>
    </LanguageControl>
  );
};

const LanguageControl = styled.div`
  cursor: pointer;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  position: relative;
  text-transform: uppercase;
  z-index: 11;
  margin-left: 0;
`;

const SelectedLanguage = styled(Language)<{ isOpened: boolean }>`
  visibility: ${({ isOpened }): string => (isOpened ? 'hidden' : 'visible')};
  margin: 0 10px 0 0;
`;

const LangList = styled.ul<{ isOpened: boolean }>`
  position: absolute;
  top: 0;
  right: -5px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  visibility: ${({ isOpened }): string => (isOpened ? 'visible' : 'hidden')};
  width: auto;
`;

export default LanguageSwitcher;
