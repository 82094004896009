import React, { useEffect, useState } from 'react';

import { BaseNewsItem, NewsTypes } from '@app/types/newsTypes';
import {
  useNewsListSizeSelector,
  useNewsListItemsSelector,
  useNewsListLoadingSelector,
} from '../NewsListHooks';
import NewsListItem from './NewsListItem';

interface NewsListGridItemProps {
  type: NewsTypes;
  id: number;
}

const NewsListGridItem = ({ id, type }: NewsListGridItemProps): React.ReactElement => {
  const [item, setItem] = useState<BaseNewsItem | undefined | null>(null);

  const isLoading = useNewsListLoadingSelector(type);
  const news = useNewsListItemsSelector(type);
  const size = useNewsListSizeSelector(type);

  useEffect(() => {
    if (Array.isArray(news) && !isLoading && item !== news[id]) { setItem(news[id]); }
    if (news === null && isLoading && Boolean(item)) { setItem(null); }
  }, [isLoading, news]);

  return (<NewsListItem item={item} size={size} type={type} />);
};

export default NewsListGridItem;
