import React from 'react';
import styled from 'styled-components';

import { WebGoogleAds } from '@app/types/webTemplateTypes';
import { ContainerMedium } from '@app/components/WebTemplates/components';
import { GoogleAds } from '@app/components';

const WebGoogleAdsElement = ({ data }: { data: WebGoogleAds }): React.ReactElement | null => {
  const { endDate } = data.ads;
  const useGoogleAds = endDate ? new Date(endDate).getTime() > new Date().getTime() : true;

  return useGoogleAds ? (
    <Container>
      <GoogleAds {...data.ads} />
    </Container>
  ) : null;
};

export default WebGoogleAdsElement;

const Container = styled(ContainerMedium)`
  margin: 20px auto;
`;
