import React from 'react';
import styled from 'styled-components';

export const HOFInducteeThumbnail = ({ url = '', alt = '' }): React.ReactElement => (
  <Thumbnail>
    <Image src={`${url}?w=500&q=100&format=auto`} alt={alt} />
  </Thumbnail>
);

const Thumbnail = styled.div`
  position: relative;
  width: 100%;
  max-width: 520px;
  aspect-ratio: 30/42;
  background-color: #c9c9c9;
  transition: all .2s ease-in-out;

  outline: 1px solid transparent;
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
  will-change: transform;
  -webkit-perspective: 1000px;

  &:after {
    position: absolute;
    content: " ";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: default;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.021,1.021);
    transition: all .2s ease-in-out;

    &:after {
      cursor: pointer;
    }
  }
`;

const Image = styled.img`
  position: absolute;
  object-position: center;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
