import React, { useState } from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { WebPartner, WebPartnerType } from '@app/types/webTemplateTypes';
import { useUserCountry } from '@app/components/Header/HeaderHooks';

import { GoogleAds } from '@app/components';

interface PartnerLogoProps {
  partner: WebPartner;
}

const PartnerLogo = React.memo(({ partner }: PartnerLogoProps): React.ReactElement | null => {
  const {
    id, photo, partnerType, googleAds, redirectUrl, geo,
  } = partner;
  const geoVisibility = (location = ''): boolean => {
    if (!geo?.countries?.length) return true;
    return geo?.exclude
      ? !geo?.countries?.includes(location)
      : geo?.countries?.includes(location);
  };
  const country = useUserCountry();

  const [isGeoVisible] = useState(geoVisibility(country));
  const [isVisible, setIsVisible] = useState(true);
  const onAdSlotRender = (isEmpty): void => { if (isEmpty) setIsVisible(false); };

  return isGeoVisible && isVisible ? (
    <Partner key={id} href={redirectUrl} target="_blank" rel="noopener noreferrer">
      {partnerType === WebPartnerType.GoogleAds && !!googleAds?.ads ? (
        <GoogleAds {...googleAds.ads} onSlotRender={onAdSlotRender} />
      ) : (
        <Logo src={photo.url} alt={photo.description} />
      )}
    </Partner>
  ) : null;
});

const Partner = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 60px;
  height: 65px;
  margin: 0 15px;
  opacity: 1;

  z-index: 10;

  @media ${Devices.desktop} {
    width: 90px;
    height: 95px;
  }

  & section {
    width: 100%;
  }
`;

const Logo = styled.img`
  max-width: 90%;
  max-height: 90%;

  z-index: 9;
`;

export default PartnerLogo;
