import { useContext, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import {
  getPositionByFilter, LocalizedTeam, Player, PlayerPositionFilter, PlayersWithAds, Team, TeamNames,
} from '@app/types/teamsTypes';
import { LanguageType } from '@app/types/localizationTypes';
import AppRoutes from '@app/constants/routes';
import { RoutePath } from '@app/types/routerTypes';
import {
  CategoryMenu,
  LocalizedSubMenuItem,
  SubMenuItem,
  TeamSubMenuId,
} from '@app/types/configurationTypes';
import { AppState } from '@app/store/reducers';
import { getPlayers, setTeamsPageMultiLangRoute } from '@app/store/actions/teamsActions';
import { MountContext } from '@app/ReactContext';
import { SecondLevelMenuItems } from '@app/components/SecondLevelMenu';

import { findCategoryBySlug } from '@app/helpers/configurationHelpers';
import { mapSecondLevelMenuItems } from '@app/helpers/menuHelpers';
import { getLocalizedSubMenuItem } from '@app/helpers/teamHelpers';

import { useLanguage } from '@app/components/Hooks';
import { useAdsStatus } from '@app/components/GoogleAds/GoogleAdsHooks';
import { TeamSubMenuTypeMap, TeamsPaths } from '@app/constants/teamsConstants';
import { WebTemplateData } from '@app/types/webTemplateTypes';
import { localizeRouteKey } from '@app/helpers/localizationHelper';
import { GoogleAdsMap } from '@app/constants/googleAdsConstants';

export function getPathKey(teamType: TeamSubMenuId): RoutePath {
  switch (teamType) {
    case TeamSubMenuTypeMap.men: return TeamsPaths.MEN_SQUAD;
    case TeamSubMenuTypeMap.women: return TeamsPaths.WOMEN_SQUAD;
    case TeamSubMenuTypeMap.primavera: return TeamsPaths.PRIMAVERA_SQUAD;
    case TeamSubMenuTypeMap.futuro: return TeamsPaths.MILAN_FUTURO_SQUAD;
    default: return AppRoutes.Home.path;
  }
}
export function useLocalizedTeam(teamType: TeamSubMenuId): LocalizedTeam | undefined {
  return useSelector<AppState, LocalizedTeam | undefined>(
    (state) => state.teams.teams?.[teamType],
    shallowEqual,
  );
}

export function useTeam(teamType: TeamSubMenuId): Team | undefined {
  const language = useLanguage();
  return useLocalizedTeam(teamType)?.[language];
}

export const useTeamNamesSelector = (): TeamNames | null => (
  useSelector<AppState, TeamNames | null>(
    (state) => state.teams.teamNames,
    shallowEqual,
  )
);

export function getFilter(
  categoryMenu: CategoryMenu,
  locale: LanguageType,
  categoryName?: string,
): PlayerPositionFilter {
  const baseMenuItem = findCategoryBySlug(categoryMenu, categoryName ?? '');

  return baseMenuItem?.id as PlayerPositionFilter;
}

export function useLocalizedSubMenuItem(): LocalizedSubMenuItem | null {
  const { teamType = '' } = useParams();
  const locale = useLanguage();

  return useSelector<AppState, LocalizedSubMenuItem | null>(
    (state) => getLocalizedSubMenuItem(state, locale, teamType),
    shallowEqual,
  );
}

export function useSubMenuItem(): SubMenuItem | null {
  const locale = useLanguage();
  return useLocalizedSubMenuItem()?.[locale] ?? null;
}

export function useTeamSubMenuId(): TeamSubMenuId {
  const subMenuItem = useSubMenuItem();
  return subMenuItem?.id as TeamSubMenuId ?? TeamSubMenuTypeMap.men;
}

export function usePlayersDownload(): void {
  const { isInitialMount } = useContext(MountContext);
  const locale = useLanguage();
  const { teamType = '' } = useParams();
  const teamSubMenuId = useTeamSubMenuId();
  const dispatch = useDispatch();

  const hasItems = useSelector<AppState, boolean>(
    (state) => !!state.teams.teams[teamSubMenuId],
    shallowEqual,
  );

  useEffect(() => {
    if (teamType && (!isInitialMount || !hasItems)) {
      // check if data has been loaded on server before very first render
      !hasItems && dispatch(getPlayers({ teamType, language: locale }));
    }
  }, [teamType, locale]);
}

export function useTeamsMultiLangUrl(localizedSubItem: LocalizedSubMenuItem | null): void {
  const { teamType = '', categoryName = '' } = useParams();
  const dispatch = useDispatch();
  const language = useLanguage();
  const subItem = localizedSubItem?.[language];

  useEffect(() => {
    subItem && dispatch(setTeamsPageMultiLangRoute({ teamType, language, categoryName }));
  }, [subItem, categoryName]);
}

export function useCategoryMenu(): CategoryMenu {
  const language = useLanguage();
  const localizedSubItem = useLocalizedSubMenuItem();
  const subItem = localizedSubItem?.[language];
  return subItem?.categories || [];
}

export function useFilteredPlayers(filter: PlayerPositionFilter): Team | undefined {
  const { categoryName = '' } = useParams();
  const language = useLanguage();
  const teamSubMenuId = useSubMenuItem()?.id as TeamSubMenuId;
  const categoryMenu = useCategoryMenu();
  const team = useTeam(teamSubMenuId);
  const showOnFilter = getFilter(categoryMenu, language, categoryName);
  const type = categoryMenu.find((item) => item.id === filter)?.name;

  return type && [
    PlayerPositionFilter.All,
    PlayerPositionFilter.AllWomen,
    PlayerPositionFilter.AllPrimavera,
    PlayerPositionFilter.AllMilanFuturo,
    filter,
  ].includes(showOnFilter)
    ? team?.filter((player) => player.position === getPositionByFilter(filter) && !player.hide) : [];
}

export const useFilteredPlayersWithGoogleAds = (filter: PlayerPositionFilter): PlayersWithAds => {
  const { categoryName = '' } = useParams();
  const categoryMenu = useCategoryMenu();
  const language = useLanguage();

  const currentPositionFilter = getFilter(categoryMenu, language, categoryName);
  const withAds = (PlayerPositionFilter.Defenders === filter && PlayerPositionFilter.All === currentPositionFilter)
    || (PlayerPositionFilter.DefendersWomen === filter && PlayerPositionFilter.AllWomen === currentPositionFilter);

  const [isGoogleAdsVisible, setIsGoogleAdsVisible] = useState(withAds);

  const players: PlayersWithAds = [...(useFilteredPlayers(filter) || [])];
  const useGoogleAds = withAds && isGoogleAdsVisible && !!players?.length;
  const adsVisibility = useAdsStatus(GoogleAdsMap.players.adUnit);

  useGoogleAds && players.splice(1, 0, GoogleAdsMap.players);

  useEffect(() => {
    if (withAds && adsVisibility !== null) setIsGoogleAdsVisible(adsVisibility);
  }, [withAds, adsVisibility]);

  return players;
};

interface GetCategoryParams {
  position: PlayerPositionFilter;
  categoryMenu: CategoryMenu;
}

export function getCategoryName({ categoryMenu, position }: GetCategoryParams): string {
  return categoryMenu.find((item) => item.id === position)?.name ?? '';
}

export function usePhotoLarge(player?: Player | null): string {
  const teamSubMenuId = useTeamSubMenuId();
  const genre = teamSubMenuId === TeamSubMenuTypeMap.women ? 'woman' : 'man';
  const position = player?.position === 'goalkeeper' ? 'goalkeeper' : 'player';

  return player?.photoLarge?.url || `/images/team/placeholder.${position}.${genre}.png`;
}

export const useSecondLevelMenuItems = (): SecondLevelMenuItems => {
  const language = useLanguage();
  const teamSubMenuId = useTeamSubMenuId();

  return useSelector<AppState, SecondLevelMenuItems>(
    (state) => {
      const menu = state.configuration[language]?.menu?.web_teams;
      return menu ? mapSecondLevelMenuItems({ menu, selectedItemId: teamSubMenuId }) : [];
    },
    isEqual,
  );
};

export function useTeamsWebTemplateData(): WebTemplateData[] {
  const language = useLanguage();
  const teamSubMenuId = useTeamSubMenuId();
  return useSelector<AppState, WebTemplateData[]>(
    (state) => {
      const data = state.webTemplate.templates[teamSubMenuId ?? ''] ?? {};
      return data[language] ?? [];
    },
    shallowEqual,
  );
}

export function useTeamListUrl(): string | null {
  const language = useLanguage();
  const secondLevelMenuItems = useSecondLevelMenuItems();
  if (secondLevelMenuItems.length === 0) return null;

  const menuItem = secondLevelMenuItems?.find((item) => item.isActive) ?? secondLevelMenuItems[0];
  return localizeRouteKey(menuItem.linkData, language);
}

export const useMenFirstTeamCheck = (teamType: TeamSubMenuId): boolean => teamType === TeamSubMenuTypeMap.men;

export const useMenFirstTeamNameSelector = (): string => {
  const secondLevelMenuItems = useSecondLevelMenuItems();
  return secondLevelMenuItems?.find(({ id }) => (id === TeamSubMenuTypeMap.men))?.name ?? '';
};
