import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { Errors, PageError } from '@app/types/errorTypes';
import { useLanguage, useMenu } from '@app/components/Hooks';
import { AppState } from '@app/store/reducers';
import { isTheSameNewsLandingPage } from '@app/helpers/newsHelpers';
import { ArticleItem } from '@app/types/newsTypes';
import { getArticleByUrlSlug, resetArticle } from '@app/store/actions/newsActions';

export function useArticle(): ArticleItem | null | undefined {
  const language = useLanguage();
  return useSelector<AppState, ArticleItem | null | undefined>(
    (state) => state.news.articles.selectedItemDetails?.[language],
    isEqual,
  );
}

export function useIsTheSameArticle(): boolean {
  const { categoryName = '', publicationDate = '', urlSlug = '' } = useParams();
  const language = useLanguage();
  return useSelector<AppState, boolean>(
    (state) => isTheSameNewsLandingPage({
      newsItem: state.news.articles.selectedItemDetails,
      urlSlug,
      publicationDate,
      categoryName,
      state,
      language,
    }),
    isEqual,
  );
}

export function useDownloadArticle(): void {
  const article = useArticle();
  const isTheSameArticle = useIsTheSameArticle();
  const dispatch = useDispatch();
  const { publicationDate = '', urlSlug = '' } = useParams();
  const language = useLanguage();

  useEffect(() => {
    if (!article || !isTheSameArticle) {
      dispatch(resetArticle());
      dispatch(getArticleByUrlSlug({
        publicationDate, urlSlug, language,
      }));
    }
  }, [urlSlug, publicationDate]);
}

export function useResetArticle(): void {
  const dispatch = useDispatch();
  useEffect(() => (): void => { dispatch(resetArticle()); }, []);
}

export function useRouterParamsValidation(): Errors {
  const menu = useMenu();
  const article = useArticle();
  const isTheSameArticle = useIsTheSameArticle();
  return menu && article && (!isTheSameArticle) ? PageError.NotFound : null;
}
