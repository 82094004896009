import React from 'react';
import styled from 'styled-components';
import { Devices } from '@app/styles';

export const HOFInducteesHeader = ({ children }): React.ReactElement => (
  <Header>{children}</Header>
);

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: start;
  gap: 10px;
  width: 100%;

  @media ${Devices.tabletLarge} {
    gap: 20px;
  }
`;
