import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import AppRoutes from '@app/constants/routes';
import { AppState } from '@app/store/reducers';
import { RoutePath } from '@app/types/routerTypes';
import { LayoutConfigurationItem } from '@app/types/configurationTypes';
import {
  NewsTypes, BaseNewsItem, NewsNavigationTypes,
} from '@app/types/newsTypes';

import { LayoutConfiguration } from '@app/constants/configurationConstants';

import { useLanguage } from '@app/components/Hooks';

export const useNewsListLoadingSelector = (type: NewsTypes): boolean => useSelector<AppState, boolean>(
  (state) => state.news[type].list.isLoadingData, isEqual,
);

export const useNewsListHasNextItemsSelector = (type: NewsTypes): boolean => useSelector<AppState, boolean>(
  (state) => state.news[type].list.hasMoreData, isEqual,
);

const useNewsNavigationTypeSelector = (type: NewsTypes): NewsNavigationTypes => {
  switch (type) {
    case NewsTypes.VIDEOS: return NewsNavigationTypes.VIDEOS;
    case NewsTypes.PICTURES: return NewsNavigationTypes.PICTURES;
    case NewsTypes.ARTICLES: default: return NewsNavigationTypes.ARTICLES;
  }
};

export const useNewsListLayoutTypeSelector = (type: NewsTypes): LayoutConfigurationItem => {
  const navigation = useNewsNavigationTypeSelector(type);
  const language = useLanguage();

  return useSelector<AppState, LayoutConfigurationItem>(
    (state) => {
      const layoutType = state.configuration[language]?.menu?.web_news?.navigation[navigation]?.layout;
      return layoutType ? LayoutConfiguration[layoutType] : LayoutConfiguration.plain_3x3;
    }, isEqual,
  );
};

export const useNewsNavigationPathSelector = (type: NewsTypes): RoutePath => {
  switch (type) {
    case NewsTypes.VIDEOS: return AppRoutes.Videos.path;
    case NewsTypes.PICTURES: return AppRoutes.Gallery.path;
    case NewsTypes.ARTICLES: default: return AppRoutes.Articles.path;
  }
};

export const useNewsListSizeSelector = (type: NewsTypes): number => (
  useNewsListLayoutTypeSelector(type).itemsToDownload
);

export const useNewsListItemsSelector = (type: NewsTypes): BaseNewsItem[] | null => (
  useSelector<AppState, BaseNewsItem[] | null>((state) => state.news[type].list.items, isEqual)
);

export const useNewsListLengthSelector = (type: NewsTypes): number => (
  useNewsListItemsSelector(type)?.length ?? 0
);
