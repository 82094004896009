import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ResponsiveType } from 'react-multi-carousel';
import throttle from 'lodash/throttle';

import { getResponsiveNewsImageConfig } from '@app/helpers/imageHelper';
import { GalleryItem } from '@app/components';
import { DevicesSize } from '@app/styles';
import { useLatestPhotosWithGoogleAds } from '@app/components/Hooks';

import { CarouselBase } from './CarouselBase';
import { CarouselTitle } from './CarouselTitle';

const getContainerWidth = (): number => Number((window.getComputedStyle(document.documentElement)
  .getPropertyValue('--gallery-photo-max-width')
  .match(/\d+/g) || [])[0]);

const useConfiguration = (): ResponsiveType => {
  const [containerWidth, setWidth] = useState(getContainerWidth());

  useEffect(() => {
    const resizeListener = throttle((): void => {
      setWidth(getContainerWidth());
    }, 200);

    window.addEventListener('resize', resizeListener);

    return (): void => { window.removeEventListener('resize', resizeListener); };
  }, []);

  const latestNewsConfiguration = {
    tablet: {
      breakpoint: { max: DevicesSize.tabletSmall, min: DevicesSize.mobileLarge },
      items: 2,
      slidesToSlide: 2,
      partialVisibilityGutter: 20,
    },
    mobile: {
      breakpoint: { max: DevicesSize.mobileLarge, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 20,
    },
  };

  if (Number(containerWidth) < DevicesSize.desktopSmall) {
    return {
      desktop: {
        breakpoint: { max: Infinity, min: DevicesSize.tabletSmall },
        items: 3,
        slidesToSlide: 3,
      },
      ...latestNewsConfiguration,
    };
  }

  return {
    desktop: {
      breakpoint: { max: Infinity, min: DevicesSize.tabletMedium },
      items: 4,
      slidesToSlide: 4,
    },
    tabletMedium: {
      breakpoint: { max: DevicesSize.tabletMedium, min: DevicesSize.tabletSmall },
      items: 3,
      slidesToSlide: 3,
    },
    ...latestNewsConfiguration,
  };
};

export const CarouselLatestGallery = (): React.ReactElement => {
  const latestPhotos = useLatestPhotosWithGoogleAds();
  const latestNewsConfiguration = useConfiguration();

  return (
    <>
      <CarouselTitle>
        <FormattedMessage id="news.relatedGalleries" />
      </CarouselTitle>
      <CarouselBase
        ItemComponent={GalleryItem}
        items={latestPhotos || []}
        configuration={latestNewsConfiguration}
        getResponsiveImageConfig={getResponsiveNewsImageConfig}
      />
    </>
  );
};
