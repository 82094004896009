export enum MenCompetitionsMap {
  SuperCoppaItalia = 'competition_men_supercoppaitaliana',
  CoppaItalia = 'men_coppaitalia',
  ChampionsLeague = 'competition_men_championsleague',
  EuropaLeague = 'competition_men_europaleague',
  SeriaA = 'men_seriea',
  ICC = 'competition_men_icc',
}

export enum OptaSDCompetitionsMap {
  Primavera = 'primavera_1',
  Primavera2 = 'primavera_2',
  UefaYouthLeague = 'uefa_youth_league',
  FuturoSeriaC = 'futuro_seria_c',
  FuturoCoppaItalia = 'futuro_coppaitalia',
}
