import { TopNews } from '@app/types/newsTypes';
import { RelatedItemsType, MenuItemTypes, GameMenuItem } from '@app/types/configurationTypes';
import { mapRelatedItem, mapExternalLink, mapGameMenuItem } from '@app/helpers/configurationHelpers';
import { getBaseNewsData } from '@app/helpers/newsHelpers';

const convertRelatedItems = (data, ctaId): RelatedItemsType | GameMenuItem | null => {
  const subMenuId = data?.modular_content?.[ctaId]?.elements?.action_type?.value[0] ?? '';
  const type = data?.modular_content?.[subMenuId]?.system?.type;

  switch (type) {
    case MenuItemTypes.GameMenuItem: return {
      type: MenuItemTypes.GameMenuItem,
      item: mapGameMenuItem(data, subMenuId),
    };
    case MenuItemTypes.ExternalLink: return {
      type: MenuItemTypes.ExternalLink,
      item: mapExternalLink(data, subMenuId),
    };
    case MenuItemTypes.RelatedMenuItem: return {
      type: MenuItemTypes.RelatedMenuItem,
      item: mapRelatedItem(data, subMenuId),
    };
    default: return null;
  }
};

export const convertCarouselData = (data): TopNews[] => (
  (data?.item?.elements?.items?.value ?? [])
    .filter((slideId) => { // filter by check if slideData exist
      const slideDataId = data?.modular_content?.[slideId]?.elements?.content?.value[0];
      return Boolean(data?.modular_content?.[slideDataId]);
    })
    .map((slideId) => {
      const slideCtaIds = data?.modular_content?.[slideId]?.elements?.cta?.value ?? [];
      const slideDataId = data?.modular_content?.[slideId]?.elements?.content?.value[0];
      const slideData = data?.modular_content?.[slideDataId];

      return {
        ...getBaseNewsData(slideData),
        subTitle: slideData?.elements?.subtitle?.value ?? '',
        videoId: (slideData?.elements?.live?.value?.[0]?.codename ?? '').toLowerCase() === 'true'
          ? slideData?.elements?.dailymotion_id?.value ?? '' : '',
        image: {
          url: slideData?.elements?.main_image_web.value[0]?.url
            ?? slideData?.elements?.main_image_horizontal?.value[0]?.url ?? '',
          description: slideData?.elements?.main_image_web?.value[0]?.description
            ?? slideData?.elements?.main_image_horizontal?.value[0]?.description ?? '',
        },
        verticalImage: {
          url: slideData?.elements?.main_image_vertical?.value[0]?.url ?? '',
          description: slideData?.elements?.main_image_vertical?.value[0]?.description ?? '',
        },
        cta: slideCtaIds.map((ctaId) => ({
          label: data?.modular_content?.[ctaId]?.elements?.label?.value ?? '',
          uiType: data?.modular_content?.[ctaId]?.elements?.ui_template?.value[0]?.codename ?? '',
          ...convertRelatedItems(data, ctaId),
        })),
      };
    })
);
