import {
  format, isAfter, isBefore, isValid, parseISO,
} from 'date-fns';

import {
  KenticoTicketingCompetitionData,
  KenticoTicketingElement, KenticoTicketingMatchData,
  KenticoTicketingModularContent, KenticoTicketingTeamData, KenticoTicketingPackageData,
} from '@app/services/kentico/types/ticketingTypes';
import {
  Competition, MatchTicketsInfo, PackageTicketsInfo, TabItem,
  TicketingSingle, TicketingConfiguration, TicketingSubscription, BaseTicketInfo, MatchTeam,
} from '@app/types/ticketingTypes';
import { KenticoResponse } from '@app/services/kentico/types/responseTypes';
import { mapImageData, mapSeoData } from '@app/helpers/configurationHelpers';
import { convertWebTemplateElement } from '@app/services/kentico/converters/webTemplateConverter';
import { KenticoLangToLangCodeMap } from '@app/constants/localizationConstants';

function convertTeam(data: KenticoTicketingTeamData): MatchTeam {
  return {
    goals: 0,
    id: data?.team_opta_id?.value ?? '',
    logoUrl: mapImageData(data?.flag),
    name: data?.regular_name?.value ?? data?.shortname?.value ?? '',
    shortName: data?.shortname?.value ?? '',
  };
}

function convertCompetition(data: KenticoTicketingCompetitionData): Competition {
  return {
    logo: mapImageData(data?.logo),
    name: data?.competition_name?.value ?? '',
    optaId: data?.competition_opta_id?.value ?? '',
  };
}

function convertBaseTicketsInfo(data: KenticoTicketingMatchData | KenticoTicketingPackageData): BaseTicketInfo {
  return {
    ticketsActive: data?.tickets_active?.value[0]?.codename === 'true',
    ticketsSoon: data?.tickets_soon?.value[0]?.codename === 'true',
    ticketsSoonUrl: decodeURIComponent(data?.tickets_soon_url?.value ?? ''),
    ticketsUrl: {
      en: decodeURIComponent(data?.tickets_url_english?.value ?? data?.tickets_url?.value ?? ''),
      it: decodeURIComponent(data?.tickets_url?.value ?? ''),
    },
    hospitalityActive: data?.hospitality_active?.value[0]?.codename === 'true',
    hospitalityUrl: {
      en: decodeURIComponent(data?.hospitality_url_english?.value ?? data?.hospitality_url?.value ?? ''),
      it: decodeURIComponent(data?.hospitality_url?.value ?? ''),
    },
    ticketsPopup: (data?.tickets_popup?.value?.[0]?.codename ?? '').toLowerCase() === 'true',
    ticketsMessage: data?.tickets_message?.value ?? '',
    informationUrl: {
      en: data?.information_url_english?.value ?? data?.information_url?.value ?? '',
      it: data?.information_url?.value ?? '',
    },
    startingPrice: data?.starting_price?.value,
    ticketsPricing: data?.tickets_pricing?.value[0],
    packageDescription: data?.package_icon?.value ?? '',
    promoDescription: data?.promo_icon?.value ?? '',
    infoDescription: data?.info_icon?.value ?? '',
  };
}

export function convertMatchTicketsInfo(
  data: KenticoTicketingMatchData,
  kenticoResponse: KenticoResponse<KenticoTicketingElement, KenticoTicketingModularContent>,
): MatchTicketsInfo {
  return {
    ...convertBaseTicketsInfo(data),
    competition: convertCompetition(
      kenticoResponse.modular_content[data?.competition?.value[0]]?.elements as KenticoTicketingCompetitionData,
    ),
    seasonId: data?.season?.value?.[0]?.name ?? '',
    gameOptaId: data?.game_opta_id?.value ?? '',
    matchTime: data?.datetime?.value ?? '',
    kenticoMatchTimeTBC: data?.datetime_tbc?.value[0]?.codename === 'true',
    teams: {
      home: convertTeam(
        kenticoResponse.modular_content[data?.home_team?.value[0]]?.elements as KenticoTicketingTeamData,
      ),
      away: convertTeam(
        kenticoResponse.modular_content[data?.away_team?.value[0]]?.elements as KenticoTicketingTeamData,
      ),
    },
    companion: {
      ad: mapImageData(data?.companion_ad),
      url: {
        en: data?.companion_ad_url_english?.value,
        it: data?.companion_ad_url?.value,
      },
    },
    scriptDescription: data?.mark_up_script_description?.value ?? '',
    packageDescription: data?.package_icon?.value ?? '',
    promoDescription: data?.promo_icon?.value ?? '',
    infoDescription: data?.info_icon?.value ?? '',
  };
}

function sortByMatchTime(matchA: MatchTicketsInfo, matchB: MatchTicketsInfo): number {
  if (!matchA.matchTime) {
    return 1;
  }
  if (!matchB.matchTime) {
    return -1;
  }
  if (matchA.matchTime < matchB.matchTime) {
    return -1;
  }
  if (matchA.matchTime > matchB.matchTime) {
    return 1;
  }
  return 0;
}

export function convertTicketing(
  kenticoResponse: KenticoResponse<KenticoTicketingElement, KenticoTicketingModularContent>,
): TicketingSingle {
  const games = kenticoResponse?.items[0]?.elements.ticketing_games?.value ?? [];
  const matches = games.map(
    (game) => convertMatchTicketsInfo(
      kenticoResponse?.modular_content[game]?.elements as KenticoTicketingMatchData,
      kenticoResponse,
    ),
  );
  const ticketing: TicketingSingle = {};

  matches.filter((match) => {
    const parsedMatchTime = parseISO(match?.matchTime ?? '');
    return isValid(parsedMatchTime) && isAfter(parsedMatchTime, new Date());
  }).sort(sortByMatchTime).forEach((match) => {
    const parsedMatchTime = parseISO(match?.matchTime ?? '');
    const matchMonth = isValid(parsedMatchTime) ? format(parsedMatchTime, 'yyyy-MM') : null;

    if (matchMonth) {
      if (!ticketing[matchMonth]) {
        ticketing[matchMonth] = [];
      }
      ticketing[matchMonth].push(match);
    }
  });

  return ticketing;
}

export const convertTicketingPackages = (
  data: KenticoResponse<KenticoTicketingElement, KenticoTicketingModularContent>,
): PackageTicketsInfo[] => (data?.items?.[0]?.elements?.ticketing_packages?.value ?? [])
  .map((packageId) => {
    const packageData = data?.modular_content?.[packageId]?.elements as KenticoTicketingPackageData;
    return {
      packageId,
      imageMobile: mapImageData(packageData?.package_image_mobile),
      imageDesktop: mapImageData(packageData?.package_image_desktop),
      name: packageData?.ticketing_package_name?.value ?? '',
      games: (packageData?.games_in_the_package?.value ?? [])
        .map((game) => convertMatchTicketsInfo(
          data?.modular_content[game]?.elements as KenticoTicketingMatchData, data,
        )),
      ...convertBaseTicketsInfo(packageData),
    };
  })
  .filter(({ games }) => (!games.filter((game) => {
    const parsedMatchTime = parseISO(game?.matchTime ?? '');
    return isValid(parsedMatchTime) && isBefore(parsedMatchTime, new Date());
  }).length));

function convertTicketingTabItem(data, tabItemId): TabItem {
  const tabData = data?.modular_content[tabItemId]?.elements ?? null;
  return {
    id: tabData?.id?.value ?? '',
    name: tabData?.name?.value ?? '',
    url: tabData?.url?.value ?? '',
    isVisible: (tabData?.is_visible?.value[0]?.name ?? '').toLowerCase() === 'true',
    content: tabData?.content?.value?.map((value) => convertWebTemplateElement(data, value)) ?? [],
    ...mapSeoData(tabData, KenticoLangToLangCodeMap[data?.item?.system?.language]),
  };
}

function convertTicketingTabs(data, tabsId): TabItem[] {
  return (data?.modular_content[tabsId]?.elements?.tabs?.value ?? [])
    .map((tabItemId) => convertTicketingTabItem(data, tabItemId));
}

export function convertSubscription(data): TicketingSubscription {
  const subscriptionId = data?.item?.elements?.content?.value?.[0];
  return {
    html: data?.modular_content[subscriptionId]?.elements?.content?.value ?? '',
  };
}

export function convertTicketingConfiguration(data): TicketingConfiguration {
  return {
    carousel: 'web_tickets_carousel', // TODO:USE:CMS:CONFIG
    tabs: convertTicketingTabs(data, 'web_tickets_tabs'),
  };
}
