import React from 'react';
import styled from 'styled-components';

const CircleInfosIcon = (): React.ReactElement => (
  <CircleSvg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <g fill="none" fillRule="evenodd">
      <circle cx="15" cy="15" r="15" fill="#C9240D" />
      <path fill="#FFF" fillRule="nonzero" d="M15.546 18.608c0 .27-.219.488-.488.488h-.523a.488.488 0 0 1-.488-.488v-5.193c0-.27.218-.488.488-.488h.523c.27 0 .488.218.488.488v5.193zm-.75-8.051a.871.871 0 1 0 0 1.742.871.871 0 0 0 0-1.742z" />
      <path stroke="#FFF" strokeWidth=".93" d="M21.47 14.826a6.674 6.674 0 1 1-13.347 0 6.674 6.674 0 0 1 13.347 0" />
    </g>
  </CircleSvg>
);

export const CircleSvg = styled.svg`
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.1s ease-in;

  &:hover {
    opacity: 0.4;
  }
`;

export default CircleInfosIcon;
