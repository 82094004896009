import React from 'react';
import styled from 'styled-components';
import { Devices } from '@app/styles';
import WebEditorialElement from '@app/components/WebTemplates/elements/WebEditorialElement';
import { HOFTitle } from './hof.title';

export const HOFInducteeCareer = ({ title = '', table = '' }): React.ReactElement => (
  <Career>
    <HOFTitle>{title}</HOFTitle>
    <WebEditorialElement content={table} />
  </Career>
);

const Career = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  height: auto;
  padding: 10px;
  background-color: white;

  @media ${Devices.tabletLarge} {
    padding: 30px 40px;
  }
`;
