import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { WebTabs } from '@app/types/webTemplateTypes';
import LocalizedLink from '@app/components/Localization/LocalizedLink';
import AppRoutes from '@app/constants/routes';
import { Devices } from '@app/styles';
import { Metadata, HorizontalScroll } from '@app/components';
import WebTemplateSelect from '../WebTemplateSelect';
import { ContainerMedium } from '../components';

interface WebTabsElementProps {
  data: WebTabs;
}

const WebTabsElement = ({ data }: WebTabsElementProps): React.ReactElement => {
  const {
    topLevel = '', secondLevel = '', categoryName, tab,
  } = useParams();
  const tabLevel = !tab && (!categoryName || data?.tabs?.find((tabItem) => tabItem.url === categoryName)) ? 3 : 4;
  const currentTab = data?.tabs?.find(
    (tabItem) => tabItem.url === ((tabLevel === 3 ? categoryName : tab) ?? data?.tabs?.[0]?.url)
  );

  const activeSeo = data?.tabs?.find((tab) => tab.url === currentTab?.url)?.seo;

  return (
    <ContainerMedium>
      { !!activeSeo && <Metadata seo={activeSeo} /> }
      <Container>
        <TabContainer>
          <HorizontalScroll color="black">
            {data?.tabs?.map((tabItem, index) => (tabItem.isVisible ? (
              <TabLocalizedLink
                pathKey={tabLevel === 4 ? AppRoutes.WebTemplateTab.path : AppRoutes.WebTemplate.path}
                pathParams={{
                  topLevel,
                  secondLevel,
                  categoryName: (tabLevel === 3 && index === 0 ? '' : null) ?? (tabLevel === 3 ? tabItem.url : categoryName) ?? '',
                  tab: tabLevel === 4 && index > 0 ? tabItem.url : '',
                }}
                key={tabItem.id}
                active={tabItem.url === currentTab?.url}
                isTab
              >
                {tabItem.name}
              </TabLocalizedLink>
            ) : null))}
          </HorizontalScroll>
        </TabContainer>
        {currentTab && currentTab.content.map((data) => (<WebTemplateSelect data={data} />))}
      </Container>
    </ContainerMedium>
  );
};

const Container = styled.div`
  width: 100%;
  position: relative;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TabContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  color: #ffffff;
  letter-spacing: 0.21px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > * {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 25px!important;
    padding-top: 5px;
  }

  @media ${Devices.tabletLarge} {
    margin-bottom: 40px;

    & > * {
      height: 30px!important;
    }
  }
`;

const TabLocalizedLink = styled(LocalizedLink)<{ active: boolean }>`
  margin-right: 15px;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-weight: normal;
  letter-spacing: 0.16px;
  font-size: 14px;
  color: ${({ active }): string => (active ? '#000000' : 'rgba(0, 0, 0, 0.2)')};
  border-color: ${({ active, theme }): string => (active ? theme.color.harleyDavidsonOrange : 'transparent')};
  border-bottom-style: solid;
  border-bottom-width: 3px ;
  text-align: center;

  &:last-of-type {
    margin-right: 0;
  }

  @media ${Devices.tabletLarge} {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.39px;
    color: #000000;
    margin-right: 60px;
  }
`;

export default WebTabsElement;
