import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { LanguageSwitcher } from '@app/components/Localization';
import { MyMilanControl } from '@app/components/Login';
import { useLanguage } from '@app/components/Hooks';

import { useIsHomeDetection } from '../HeaderHooks';

import Menu from './Menu/Menu';
import HamburgerButton from './HamburgerButton';
import MobileLogo from './MobileLogo';
import { SearchButton } from './SearchBar';

interface MenuHeaderProps {
  openMobileMenu: () => void;
  closeMobileMenu: () => void;
  isMobileMenuOpened: boolean;
}

const MenuHeader = ({
  isMobileMenuOpened,
  openMobileMenu,
  closeMobileMenu,
}: MenuHeaderProps): React.ReactElement => {
  const isHome = useIsHomeDetection();
  const language = useLanguage();

  return (
    <Container>
      <MobileMenuSection>
        <HamburgerButton
          openMobileMenu={openMobileMenu}
          closeMobileMenu={closeMobileMenu}
          isOpened={isMobileMenuOpened}
        />
        {
          isHome ? (
            <h1>
              <MobileLogo />
            </h1>
          ) : (
            <MobileLogo />
          )
        }
      </MobileMenuSection>
      <Menu />
      <Section>
        { ['it', 'en'].includes(language) && (<MyMilanControl />)}
        <SearchButton />
        <DesktopLangWrapper>
          <LanguageSwitcher />
        </DesktopLangWrapper>
      </Section>
    </Container>
  );
};

const Container = styled.div`
  height: var(--navigation-header-height);
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 0 var(--mobile-header-padding);
  background-color: white;
  color: black;
  z-index: 100;

  @media ${Devices.desktopSmall} {
    padding: 0 var(--desktop-header-padding) 0 var(--nav-header-left-padding);
  }
`;

const Section = styled.section`
  display: flex;
  align-items: center;
  position: relative;
  right: -7px;
`;

const DesktopLangWrapper = styled.section`
  display: none;

  @media ${Devices.desktopSmall} {
    display: block;
  }
`;

const MobileMenuSection = styled.section`
  display: flex;
  align-items: center;

  @media ${Devices.desktopSmall} {
    display: none;
  }
`;

export default MenuHeader;
