import React from 'react';
import styled from 'styled-components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { AppState } from '@app/store/reducers';
import { GA_CATEGORY, GA_EVENT } from '@app/types/googleTypes';
import { Menu as MenuInterface } from '@app/types/configurationTypes';
import { Devices } from '@app/styles';

import { LanguageSwitcher } from '@app/components/Localization';
import { useLanguage } from '@app/components/Hooks';
import { pushGAEvent } from '@app/helpers/googleHelpers';
import { LogoutUser, RefreshUserToken } from '@app/store/actions/userActions';
import { GetLogoutUrl } from '@app/services/salesforce/request';

import MenuItem from './MobileMenuItem';

interface MobileMenuProps {
  isOpened: boolean;
  isOnTop: boolean;
  closeMobileMenu: () => void;
}
const MobileMenu = ({ isOpened, closeMobileMenu, isOnTop }: MobileMenuProps): React.ReactElement => {
  const { locale } = useIntl();
  const menuItems = useSelector<AppState, MenuInterface>(
    (state) => state.configuration[locale]?.menu,
    shallowEqual,
  );
  const isLoggedIn = useSelector<AppState, boolean>(
    (state) => state.user.isLoggedIn,
    shallowEqual,
  );
  const language = useLanguage();
  const dispatch = useDispatch();
  const menu = (menuItems && Object.values(menuItems).filter(({ isVisible }) => isVisible)) || [];

  const onLogout = (): void => {
    pushGAEvent({
      event: GA_EVENT.CLICK_MENU,
      category: GA_CATEGORY.MY_MILAN_LOGOUT,
      label: window.location.href,
      language,
    });
    closeMobileMenu();

    if (isLoggedIn) {
      dispatch(RefreshUserToken(() => {
        dispatch(LogoutUser(() => {
          if (window.location.replace) {
            window.location.replace(GetLogoutUrl(language));
          } else {
            window.location.href = GetLogoutUrl(language);
          }
        }));
      }));
    }
  };

  return (
    <Container isOpened={isOpened} isOnTop={isOnTop}>
      <MenuItemsList>
        <LangMenuItem>
          <LanguageSwitcher />
        </LangMenuItem>
        {
          menu.map((item) => (
            <MenuItem item={item} key={item.id} closeMobileMenu={closeMobileMenu} isMenuOpened={isOpened} />))
        }
        {['it', 'en'].includes(language) && isLoggedIn && (
          <MenuItemLogout onClick={onLogout}>
            LOGOUT
          </MenuItemLogout>
        )}
      </MenuItemsList>
    </Container>
  );
};

export default MobileMenu;

const Container = styled.nav<{ isOpened: boolean; isOnTop: boolean }>`
  position: fixed;
  background-color: black;
  opacity: 0.85;
  top: ${({ isOnTop }): string => (
    isOnTop ? 'var(--top-header-height)' : ' calc(var(--top-header-height) + var(--navigation-header-height))'
  )};
  bottom: ${({ isOpened }): string => (isOpened ? '0px' : '100%')};
  width: 100%;
  user-select: none;
  z-index: 400;
  transition: all 300ms ease-in;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  overflow: auto;

  @media ${Devices.desktopSmall} {
    bottom: 100%;
  )};
  }
`;

const MenuItemsList = styled.ul`
  font-size: 16px;
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  color: white;
  padding: 25px;
`;

const LangMenuItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
`;

const MenuItemLogout = styled.div`
  padding: 15px 5px;
  font-weight: bold;
  cursor: pointer;
`;
