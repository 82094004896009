import styled from 'styled-components';

import { Devices } from '@app/styles';

export const CarouselTitle = styled.h2`
  text-transform: uppercase;
  margin: 10px 8px;
  font-size: 16px;

@media ${Devices.tablet} {
  margin: 15px 12.5px;
}
`;
