import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { getWebTemplateNews } from '@app/store/actions/webTemplateActions';
import { AppState } from '@app/store/reducers';
import { News } from '@app/types/webTemplateTypes';
import { getNewsDataById } from '@app/helpers/webTemlateHelpers';
import { useLanguage } from '@app/components/Hooks';

export const useWebTemplateNews = (id: string): News | null => {
  const language = useLanguage();

  return useSelector<AppState, News | null>(
    (state) => getNewsDataById({ state, id, language }),
    shallowEqual,
  );
};

export const useDataRequest = (newsGroupId: string): () => void => {
  const dispatch = useDispatch();
  const language = useLanguage();

  return (): void => {
    dispatch(getWebTemplateNews(newsGroupId, language));
  };
};
