import {
  MatchResult, LocalizedGames, LiveMatchResults, MatchLineups,
} from '@app/types/matchTypes';
import { Errors } from '@app/types/errorTypes';

export enum MatchActionTypes {
  SET_ERROR = 'MATCHCENTER/SET_ERROR',
  SET_GAMES = 'MATCHCENTER/SET_GAMES',
  SET_GAMES_DOWNLOAD_COMPLETED = 'MATCHCENTER/SET_GAMES_DOWNLOAD_COMPLETED',
  SET_MATCH_FEEDS_DOWNLOAD_COMPLETED = 'MATCHCENTER/SET_MATCH_FEEDS_DOWNLOAD_COMPLETED',
  SET_MATCH_LINEUPS = 'MATCHCENTER/SET_MATCH_LINEUPS',
  SET_MATCH_EVENTS = 'MATCHCENTER/SET_MATCH_EVENTS',
  SET_MATCH_RESULTS = 'MATCHCENTER/SET_MATCH_RESULTS',
  SET_MATCH_DAY_RESULTS = 'MATCHCENTER/SET_MATCH_DAY_RESULTS',
  SET_LIVE_MATCH = 'MATCHCENTER/SET_LIVE_MATCH',
  RESET_LIVE_MATCH = 'MATCHCENTER/RESET_LIVE_MATCH',
  RESET_MATCH_CENTER = 'MATCHCENTER/RESET_MATCH_CENTER',
}

export interface SetError {
  type: MatchActionTypes.SET_ERROR;
  payload: Errors;
}

export interface SetGames {
  type: MatchActionTypes.SET_GAMES;
  payload: LocalizedGames;
}

export interface SetMatchLineups {
  type: MatchActionTypes.SET_MATCH_LINEUPS;
  payload: MatchLineups;
}

export interface SetMatchEvents {
  type: MatchActionTypes.SET_MATCH_EVENTS;
  payload: unknown;
}

export interface SetMatchResults {
  type: MatchActionTypes.SET_MATCH_RESULTS;
  payload: MatchResult | null;
}

export interface SetMatchDayResults {
  type: MatchActionTypes.SET_MATCH_DAY_RESULTS;
  payload: MatchResult[];
}

export interface SetLiveMatch {
  type: MatchActionTypes.SET_LIVE_MATCH;
  payload: LiveMatchResults;
}

export interface ResetLiveMatch {
  type: MatchActionTypes.RESET_LIVE_MATCH;
  payload: string;
}

export interface ResetMatchCenter {
  type: MatchActionTypes.RESET_MATCH_CENTER;
  payload: null;
}

export interface SetGamesDownloadCompleted {
  type: MatchActionTypes.SET_GAMES_DOWNLOAD_COMPLETED;
  payload: boolean;
}

export interface SetMatchFeedsDownloadCompleted {
  type: MatchActionTypes.SET_MATCH_FEEDS_DOWNLOAD_COMPLETED;
  payload: boolean;
}

export type MatchCenterActions = SetGamesDownloadCompleted
  | SetMatchFeedsDownloadCompleted
  | SetError
  | SetGames
  | SetMatchEvents
  | SetMatchLineups
  | SetMatchResults
  | SetMatchDayResults
  | SetLiveMatch
  | ResetMatchCenter
  | ResetLiveMatch;
