import pull from 'lodash/pull';

import { getHallOfFame } from '@app/services/kentico/hallOfFameService';

import { loadingWebTemplateData, setWebTemplateError } from '@app/store/actions/webTemplateActions';

import { ActionWithPromiseReturn } from '@app/types/actionTypes';
import { LocalizedHallOfFame } from '@app/types/hallOfFameTypes';
import { HallOfFameActionTypes, SetHallOfFame } from '@app/store/actionTypes/hallOfFameActionTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { PageError } from '@app/types/errorTypes';

import { AppLanguages } from '@app/constants/localizationConstants';

const setHallOfFame = (data: LocalizedHallOfFame): SetHallOfFame => ({
  type: HallOfFameActionTypes.SET_HALL_OF_FAME,
  payload: data,
});

type GetHallOfFameData = (data: {
  language: LanguageType;
}) => ActionWithPromiseReturn;

export const getHallOfFameByLanguage: GetHallOfFameData = ({ language }) => (
  async (dispatch): Promise<void> => {
    const hallOfFame = await getHallOfFame(language);
    const data: LocalizedHallOfFame = {};
    data[language] = hallOfFame;

    dispatch(setHallOfFame(data));
  }
);

export const getHallOfFameData: GetHallOfFameData = ({ language }) => (
  async (dispatch): Promise<void> => {
    try {
      dispatch(loadingWebTemplateData(true));
      try {
        await dispatch(getHallOfFameByLanguage({ language }));
      } catch (e) {
        console.error('Error on fetching hall of fame', e);
        dispatch(setWebTemplateError(language, PageError.Sorry));
      }

      const restLanguages = pull([...AppLanguages], language);
      await Promise.all(restLanguages.map(async (language) => {
        try {
          await dispatch(getHallOfFameByLanguage({ language }));
        } catch (e) {
          console.error('Error on fetching hall of fame', e);
          dispatch(setWebTemplateError(language, PageError.Sorry));
        }
      }));
    } catch (e) {
      // reset error?
      console.error('Error on fetching hall of fame', e);
    } finally {
      dispatch(loadingWebTemplateData(false));
    }
  }
);
