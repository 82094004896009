import { shallowEqual, useSelector } from 'react-redux';

import { AppState } from '@app/store/reducers';
import { ConfigurationState } from '@app/store/reducers/configurationReducer';

import { PageError } from '@app/types/errorTypes';
import { VanityUrl } from '@app/types/configurationTypes';
import { AppLanguageTypes } from '@app/types/localizationTypes';

import { localizeRouteKey } from '@app/helpers/localizationHelper';
import { formatRelatedItemLinkProps } from '@app/helpers/menuHelpers';
import { useLocalizedMenSquadAllCompetitionsParams } from '@app/pages/Season/Season/SeasonHooks';

const VANITY_URL = document.location.pathname.substr(1);

const useVanityLanguage = (): AppLanguageTypes => {
  const config = useSelector<AppState, ConfigurationState>((state) => state.configuration, shallowEqual);

  return Object.values(AppLanguageTypes).find((language) => config[language]?.vanityUrls
    ?.find(({ url }) => url === VANITY_URL)) ?? AppLanguageTypes.IT;
};

const useBrowserLanguage = (): AppLanguageTypes => useSelector<AppState, AppLanguageTypes>(
  (state) => state.user.browserLanguage, shallowEqual,
);

const useVanityUrlSelector = (): VanityUrl | undefined => {
  const language = useVanityLanguage();

  return useSelector<AppState, VanityUrl | undefined>(
    (state) => state.configuration[language]?.vanityUrls
      ?.find((vanityUrl) => vanityUrl.url === VANITY_URL),
    shallowEqual,
  );
};

const useVanityUrlByIdSelector = (id = '', language: AppLanguageTypes): VanityUrl | undefined => useSelector<AppState, VanityUrl | undefined>(
  (state) => state.configuration[language]?.vanityUrls
    ?.find((vanity) => vanity.id === id),
  shallowEqual,
);

const useVanityRedirectionLanguage = (): AppLanguageTypes => {
  const browserLanguage = useBrowserLanguage();
  const vanityLanguage = useVanityLanguage();
  const vanity = useVanityUrlSelector();

  const isVanityExistEN = !!useVanityUrlByIdSelector(vanity?.id, AppLanguageTypes.EN);
  const isVanityExistCN = !!useVanityUrlByIdSelector(vanity?.id, AppLanguageTypes.CN);

  switch (vanityLanguage) {
    case AppLanguageTypes.IT:
      switch (browserLanguage) {
        case AppLanguageTypes.IT: return AppLanguageTypes.IT;
        case AppLanguageTypes.CN: return isVanityExistCN ? AppLanguageTypes.CN : AppLanguageTypes.IT;
        default: case AppLanguageTypes.EN: return isVanityExistEN ? AppLanguageTypes.EN : AppLanguageTypes.IT;
      }
    case AppLanguageTypes.CN: return AppLanguageTypes.CN;
    default: case AppLanguageTypes.EN: return AppLanguageTypes.EN;
  }
};

export const useVanityUrl = (): PageError | string | null => {
  const originalVanity = useVanityUrlSelector();
  const redirectionLanguage = useVanityRedirectionLanguage();

  const vanity = useVanityUrlByIdSelector(originalVanity?.id, redirectionLanguage);
  const { seasonId, urlSlug } = useLocalizedMenSquadAllCompetitionsParams(redirectionLanguage);

  const isConfigurationDownloaded = useSelector<AppState, boolean>(
    (state) => state.configuration.isConfigurationDownloadCompleted, shallowEqual,
  );

  if (!isConfigurationDownloaded) return null;
  if (!vanity) return PageError.NotFound;

  const link = formatRelatedItemLinkProps(vanity.relatedItem, seasonId, urlSlug);
  return (link && localizeRouteKey(link, redirectionLanguage)) ?? PageError.NotFound;
};
