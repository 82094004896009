import { reduxStore } from '@app/store';

import { Action } from '@app/types/actionTypes';
import { AdBaseStatus, GoogleAdsProps } from '@app/types/googleTypes';
import {
  GoogleActionTypes,
  ResetGoogleAdSlotStatus,
  SetGoogleAdSlotStatus,
  SetGooglePublisherTagsStatus,
} from '@app/store/actionTypes/googleActionTypes';

import { getAdUnitId } from '@app/helpers/googleHelpers';

const setGPTStatus = (status: AdBaseStatus): SetGooglePublisherTagsStatus => ({
  type: GoogleActionTypes.SET_GOOGLE_PUBLISHER_TAGS_STATUS,
  payload: status,
});

export const setAdSlotStatus = (adUnit: string, status: AdBaseStatus): SetGoogleAdSlotStatus => ({
  type: GoogleActionTypes.SET_GOOGLE_AD_SLOT_STATUS,
  payload: { [getAdUnitId(adUnit)]: status },
});

export const resetAdSlotStatus = (adUnit: string): ResetGoogleAdSlotStatus => ({
  type: GoogleActionTypes.RESET_GOOGLE_AD_SLOT_STATUS,
  payload: getAdUnitId(adUnit),
});

export const initAdSlot = ({ adUnit, size, slotId }: GoogleAdsProps): Action => (
  (dispatch): void => {
    try {
      if (adUnit?.length && slotId?.length) {
        dispatch(setAdSlotStatus(adUnit, null));
        window.googletag.cmd.push(() => {
          const slot = window.googletag.pubads().getSlots()
            ?.find((slot) => slot.getSlotElementId() === slotId);

          if (slot) {
            window.googletag.pubads().refresh([slot]);
          } else {
            window.googletag.defineSlot(adUnit, size, slotId).addService(window.googletag.pubads());
            window.googletag.pubads().enableSingleRequest();
            window.googletag.pubads().collapseEmptyDivs(true);
            window.googletag.enableServices();
            window.googletag.display(slotId);
          }
        });
      } else {
        console.error('[GPT][ERROR] Invalid AD Slot: ', { adUnit, slotId, size });
        dispatch(setAdSlotStatus(adUnit, false));
      }
    } catch (error) {
      console.error('[GPT][ERROR] AD Slot display failed: ', error);
      dispatch(setAdSlotStatus(adUnit, false));
    }
  }
);

/** CUSTOMIZED GOOGLE PUBLISHER TAGS (GPT) DOWNLOAD */
export const initGPT = (): Action => (
  (dispatch): void => {
    if (reduxStore.store.getState().google.gpt.isDownloaded === null) {
      dispatch(setGPTStatus(false));

      try {
        const gptScript = document.createElement('script');

        gptScript.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
        gptScript.type = 'text/javascript';
        gptScript.async = true;
        gptScript.onload = (): void => {
          window.googletag.cmd.push(() => {
            window.googletag.pubads().addEventListener('slotRenderEnded', (event) => {
              const adUnit = getAdUnitId(event.slot.getAdUnitPath());
              const isInitStatus = reduxStore.store.getState().google.gpt.slots[adUnit] === null;
              if (isInitStatus && event.isEmpty) dispatch(setAdSlotStatus(adUnit, false));
            });
            window.googletag.pubads().addEventListener('slotOnload', (event) => {
              const adUnit = getAdUnitId(event.slot.getAdUnitPath());
              dispatch(setAdSlotStatus(adUnit, true));
            });
          });
          dispatch(setGPTStatus(true));
        };

        document.head.appendChild(gptScript);
      } catch (error) {
        console.error('[GPT][ERROR] Script download failed ', error);
        dispatch(setGPTStatus(null));
      }
    }
  }
);
