import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import useMedia from 'react-use/lib/useMedia';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { Devices } from '@app/styles';
import { SearchBar } from './components/SearchBar';

import {
  NavigationHeader, TopHeader, Logo, MobileMenu,
} from './components';

const useMenuPosition = (): { isUpScroll: boolean; isOnTop: boolean } => {
  const [scrollPos, setScrollPos] = useState(window.pageYOffset);
  const [isUpScroll, setIsUpScroll] = useState(false);

  const documentStyles = window.getComputedStyle(document.documentElement);

  const topHeaderHeight = (documentStyles.getPropertyValue('--top-header-height').match(/\d+/g) || [])[0];
  const navigationHeaderHeight = (documentStyles.getPropertyValue('--navigation-header-height').match(/\d+/g) || [])[0];

  window
    .getComputedStyle(document.documentElement)
    .getPropertyValue('--navigation-header-height');

  useEffect(() => {
    const onScroll = (): void => {
      const currentScrollPos = window.pageYOffset;

      if (scrollPos > currentScrollPos) {
        setIsUpScroll(true);
      } else {
        setIsUpScroll(false);
      }

      setScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', onScroll);

    return (): void => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [scrollPos]);

  // required for correct ssr work
  if (!topHeaderHeight && !navigationHeaderHeight) {
    return {
      isUpScroll: false,
      isOnTop: true,
    };
  }

  return {
    isUpScroll,
    isOnTop: scrollPos < Number(topHeaderHeight) + Number(navigationHeaderHeight),
  };
};

interface UseMobileMenuState {
  (): {
    isMobileMenuOpened: boolean;
    openMobileMenu: () => void;
    closeMobileMenu: () => void;
  };
}

const useMobileMenuState: UseMobileMenuState = () => {
  const [isMobileMenuOpened, setMobileMenuOpeningState] = useState(false);

  const openMobileMenu = (): void => {
    disableBodyScroll();
    setMobileMenuOpeningState(true);
  };

  const closeMobileMenu = (): void => {
    enableBodyScroll();
    setMobileMenuOpeningState(false);
  };

  return {
    isMobileMenuOpened,
    openMobileMenu,
    closeMobileMenu,
  };
};

const Header = (): React.ReactElement => {
  const { isUpScroll, isOnTop } = useMenuPosition();
  const { isMobileMenuOpened, openMobileMenu, closeMobileMenu } = useMobileMenuState();
  const { pathname } = useLocation();
  const isWideScreen = useMedia(Devices.desktopSmall);
  const shouldMenuBeClosed = isMobileMenuOpened && isWideScreen;

  // close mobile menu when click links from top header (e.g. go to user account)
  // close menu when screen has been expanded
  useEffect(() => {
    closeMobileMenu();
  }, [pathname, shouldMenuBeClosed]);

  return (
    <>
      <Container isUpScroll={isUpScroll} isOnTop={isOnTop}>
        <InnerContainer>
          <Logo isSmall={!isOnTop} />
          <TopHeader />
          <NavigationHeader
            openMobileMenu={openMobileMenu}
            closeMobileMenu={closeMobileMenu}
            isMobileMenuOpened={isMobileMenuOpened}
          />
        </InnerContainer>
        <SearchBar />
      </Container>
      <MobileMenu
        isOpened={isMobileMenuOpened}
        closeMobileMenu={closeMobileMenu}
        isOnTop={!isOnTop && isUpScroll}
      />
    </>
  );
};

const getHeaderYCoordinate = (isOnTop: boolean, isUpScroll: boolean, top: string): string => {
  if (isOnTop) return '0px';
  if (isUpScroll) return top;
  return '-100%';
};

const Container = styled.header<{ isUpScroll: boolean; isOnTop: boolean }>`
  --desktop-header-padding: 70px;
  --mobile-header-padding: 20px;

  flex: 0 0 calc(var(--top-header-height) + var(--navigation-header-height));
  position: fixed;
  top: 0;
  // [ACM-912] -50px is a --top-header-height;
  transform: translateY(${({ isOnTop, isUpScroll }): string => getHeaderYCoordinate(isOnTop, isUpScroll, '-50px')});
  width: 100%;
  transition: transform 300ms;
  z-index: 300;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);

  @media ${Devices.desktopSmall} {
    // [ACM-912] -70px is a --top-header-height;
    transform: translateY(${({ isOnTop, isUpScroll }): string => getHeaderYCoordinate(isOnTop, isUpScroll, '-70px')});
  }
`;

const InnerContainer = styled.div`
  max-width: 1280px;
  margin: auto;
  position: relative;
`;

export default Header;
