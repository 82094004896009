import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { StandingsTable } from '@app/components';

const MatchStandings = (): React.ReactElement => (
  <Container>
    <StandingsTable isWidget />
  </Container>
);

export default MatchStandings;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  background-color: white;
  color: black;

  @media ${Devices.tablet} {
    padding: 10px;
  }
`;
