import { shallowEqual, useSelector } from 'react-redux';

import { useLanguage, useMenu } from '@app/components/Hooks';
import { AppState } from '@app/store/reducers';
import { WebPartner, WebPartnerBottom } from '@app/types/webTemplateTypes';
import { useParams, useLocation } from 'react-router-dom';
import { findCategoryBySlug } from '@app/helpers/configurationHelpers';
import { useUserCountry } from '@app/components/Header/HeaderHooks';

export function usePartnersBottomData(): WebPartnerBottom | undefined {
  const language = useLanguage();
  return useSelector<AppState, WebPartnerBottom | undefined>(
    (state) => state.configuration[language]?.partners?.bottom,
    shallowEqual,
  );
}

export function usePartnersHeaderData(): WebPartner[] {
  const language = useLanguage();
  const country = useUserCountry();
  const partners = useSelector<AppState, WebPartner[]>(
    (state) => state.configuration[language]?.partners?.header ?? [],
    shallowEqual,
  );

  return partners.filter(({ geo }) => {
    if (!geo?.countries?.length) return true;
    return geo?.exclude
      ? !geo?.countries?.includes(country)
      : geo?.countries?.includes(country);
  });
}

export function useIsPartnersVisible(): boolean {
  const menu = useMenu();
  const { lang } = useParams();
  const location = useLocation();
  const locationParts = location.pathname.split('/');

  const topMenuPosition = locationParts.findIndex((part) => part === lang) + 1;

  const topLevelMenuItem = findCategoryBySlug(Object.values(menu ?? {}), locationParts[topMenuPosition]);
  const secondLevelMenuItem = findCategoryBySlug(
    Object.values(topLevelMenuItem?.navigation ?? {}), locationParts[topMenuPosition + 1],
  );
  const thirdLevelMenuItem = findCategoryBySlug(
    Object.values(secondLevelMenuItem?.categories ?? {}), locationParts[topMenuPosition + 2],
  );

  return !(thirdLevelMenuItem?.isPartnersInvisible || secondLevelMenuItem?.isPartnersInvisible
    || topLevelMenuItem?.isPartnersInvisible || false);
}
