import { DevicesSize } from '@app/styles';

export const LatestNewsConfiguration = {
  desktop: {
    breakpoint: { max: 3000, min: DevicesSize.tabletSmall },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: DevicesSize.tabletSmall, min: DevicesSize.mobileLarge },
    items: 2,
    slidesToSlide: 2,
    partialVisibilityGutter: 20,
  },
  mobile: {
    breakpoint: { max: DevicesSize.mobileLarge, min: 0 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 20,
  },
};

export const HomePhotoGalleryConfiguration = {
  desktop: {
    breakpoint: { max: 3000, min: DevicesSize.desktop },
    items: 4,
    slidesToSlide: 4,
  },
  tabletLarge: {
    breakpoint: { max: DevicesSize.desktop, min: DevicesSize.tabletMedium },
    items: 3,
    slidesToSlide: 3,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: DevicesSize.tabletMedium, min: DevicesSize.mobileLarge },
    items: 2,
    slidesToSlide: 2,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: { max: DevicesSize.mobileLarge, min: 0 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 48,
  },
};
