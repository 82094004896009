import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { Devices } from '@app/styles';
import { GA_EVENT, GA_CATEGORY } from '@app/types/googleTypes';

import { useLanguage, useIsLoggedInSelector } from '@app/components/Hooks';
import { pushGAEvent } from '@app/helpers/googleHelpers';
import { LogoutIcon } from '@app/components/Icons';
import { LogoutUser, RefreshUserToken } from '@app/store/actions/userActions';
import { GetLogoutUrl } from '@app/services/salesforce/request';

const LogoutControl: React.FC = () => {
  const isLoggedIn = useIsLoggedInSelector();
  const language = useLanguage();
  const dispatch = useDispatch();
  const btnProps = {
    onClick: (): void => {
      pushGAEvent({
        event: GA_EVENT.CLICK_MENU,
        category: GA_CATEGORY.MY_MILAN_LOGOUT,
        label: window.location.href,
        language,
      });

      if (isLoggedIn) {
        dispatch(RefreshUserToken(() => {
          dispatch(LogoutUser(() => {
            if (window.location.replace) {
              window.location.replace(GetLogoutUrl(language));
            } else {
              window.location.href = GetLogoutUrl(language);
            }
          }));
        }));
      }
    },
  };
  return (
    <LogoutButton {...btnProps}>
      <LogoutIcon />
    </LogoutButton>
  );
};

const LogoutButton = styled.div`
  display: none;
  cursor: pointer;
  width: 40px;
  height: inherit;
  margin-left: 10px;
  transition: all 0.3s ease;

  & #logout_icon {
    width: inherit;
    height: inherit;
    & g {
      fill: none;
      stroke: ${(props): string => props.theme.color.freeSpeechRed};
      transition: all 0.3s ease;
    }
  }

  &:hover {
    & #logout_icon g {
      fill: ${(props): string => props.theme.color.freeSpeechRed};
      & g {
        stroke: white;
      }
    }
  }

  @media ${Devices.desktopSmall} {
    display: flex;
    width: 50px;
  }
`;

export default LogoutControl;
