import cloneDeep from 'lodash/cloneDeep';
import { WebTemplateActions, WebTemplateActionTypes } from '@app/store/actionTypes/webTemplateActionTypes';
import {
  ElementWebTemplateData,
  LocalizedNewsData,
  LocalizedWebTemplateError,
  News,
} from '@app/types/webTemplateTypes';

export type WebTemplateState = {
  templates: ElementWebTemplateData;
  isLoading: boolean;
  newsData: LocalizedNewsData;
  error: LocalizedWebTemplateError;
};

const initialState: WebTemplateState = {
  templates: {},
  newsData: {},
  isLoading: false,
  error: {},
};

export default (
  state: WebTemplateState = cloneDeep(initialState),
  action: WebTemplateActions,
): WebTemplateState => {
  switch (action.type) {
    case WebTemplateActionTypes.LOADING_WEB_TEMPLATE_DATA:
      return {
        ...state,
        isLoading: action.payload,
      };
    case WebTemplateActionTypes.SET_WEB_TEMPLATE_DATA: {
      const { templates, newsData } = action.payload;
      return {
        ...state,
        templates: {
          ...state.templates,
          [templates.id]: {
            ...state.templates[templates.id],
            ...templates.data,
          },
        },
        newsData: {
          ...state.newsData,
          ...newsData,
        },
      };
    }
    case WebTemplateActionTypes.SET_NEWS_DATA: {
      const { newsData, id, language } = action.payload;
      const previousNewsDataMap = state.newsData[language];
      const previousNewsValue = previousNewsDataMap?.[id] as News;

      const currentNewsData = { ...state.newsData };
      currentNewsData[language] = {
        ...previousNewsDataMap,
        [id]: {
          ...previousNewsValue,
          hasMoreData: newsData.hasMoreData,
          items: [
            ...(previousNewsValue.items ?? []),
            ...newsData.items,
          ],
        },
      };

      return {
        ...state,
        newsData: currentNewsData,
      };
    }
    case WebTemplateActionTypes.SET_NEWS_LOADING_STATE: {
      const { isLoading, id, language } = action.payload;
      const previousNewsDataMap = state.newsData[language];

      const currentNewsData = { ...state.newsData };
      currentNewsData[language] = {
        ...previousNewsDataMap,
        [id]: {
          ...(previousNewsDataMap?.[id] as News),
          isLoadingData: isLoading,
        },
      };

      return {
        ...state,
        newsData: currentNewsData,
      };
    }
    case WebTemplateActionTypes.RESET_WEB_TEMPLATE_DATA:
      return {
        ...initialState,
      };
    case WebTemplateActionTypes.SET_WEB_TEMPLATE_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          ...action.payload,
        },
      };
    case WebTemplateActionTypes.RESET_WEB_TEMPLATE_ERROR:
      return {
        ...state,
        error: initialState.error,
      };
    default:
      return state;
  }
};
