import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { useParams } from 'react-router-dom';

import { HallOfFamePlayerTypeToMenuItemPlayerTypeMap } from '@app/constants/hallOfFameConstants';

import { HallOfFamePlayer, HallOfFamePlayerTypes } from '@app/types/hallOfFameTypes';
import { Errors, PageError } from '@app/types/errorTypes';
import { AppState } from '@app/store/reducers';

import { getHallOfFameData } from '@app/store/actions/hallOfFameActions';
import { resetWebTemplateError } from '@app/store/actions/webTemplateActions';

import { useIsConfigLoaded, useLanguage } from '@app/components/Hooks';
import {
  useTopLevelMenu,
  useWebTemplateError,
  useSecondLevelMenu,
  useThirdLevelMenu,
} from '@app/pages/WebTemplate/WebTemplateHooks';

export const useHallOfFame = (): HallOfFamePlayer[] | undefined => {
  const language = useLanguage();

  return useSelector<AppState, HallOfFamePlayer[] | undefined>(
    (state) => state.hallOfFame[language],
    isEqual,
  );
};

type UseGroupsOfPlayers = () => {
  id: string;
  categoryName: string;
  players: HallOfFamePlayer[];
}[]
export const useGroupsOfPlayers: UseGroupsOfPlayers = () => {
  const language = useLanguage();
  const { categoryName = '' } = useParams();
  const players = useHallOfFame() ?? [];
  const secondLevelMenu = useSecondLevelMenu()?.[language];
  const categories = secondLevelMenu?.categories ?? [];
  const selectedCategory = categories.find(({ url }) => url === categoryName);
  const hallOfFamePlayerTypes = Object.values(HallOfFamePlayerTypes);

  let filteredHallOfFamePlayerTypes = hallOfFamePlayerTypes
    .filter((type) => HallOfFamePlayerTypeToMenuItemPlayerTypeMap[type] === selectedCategory?.id);

  // array consists of selected category or all categories if selected category haven't found
  filteredHallOfFamePlayerTypes = filteredHallOfFamePlayerTypes.length
    ? filteredHallOfFamePlayerTypes : hallOfFamePlayerTypes;

  return filteredHallOfFamePlayerTypes
    .map((type) => {
      const filteredPlayers = players.filter(({ hallOfFame }) => hallOfFame.includes(type));
      const category = categories.find(({ id }) => id === HallOfFamePlayerTypeToMenuItemPlayerTypeMap[type]);

      return {
        players: filteredPlayers,
        categoryName: category?.name ?? '',
        id: category?.id ?? '',
      };
    })
    .filter(({ players }) => players.length);
};

export function useHallOfFameDownload(): void {
  const language = useLanguage();
  const dispatch = useDispatch();

  const hasData = Boolean(useHallOfFame()?.length);

  useEffect(() => {
    if (!hasData) {
      dispatch(getHallOfFameData({ language }));
    }

    return (): void => { dispatch(resetWebTemplateError()); };
  }, []);
}

export function useHallOfFameValidation(): Errors {
  const language = useLanguage();
  const topLevel = useTopLevelMenu()[language];
  const thirdLevelMenu = useThirdLevelMenu();
  const error = useWebTemplateError();
  const isConfigLoaded = useIsConfigLoaded();

  if (error) {
    return error;
  }

  // url is incorrect if menu items haven't found
  return isConfigLoaded && (!topLevel || !thirdLevelMenu)
    ? PageError.NotFound : null;
}
