import { LanguageType } from '@app/types/localizationTypes';
import { TabItem, LocalizedTabs, LocalizedCurrentTab } from '@app/types/ticketingTypes';
import { AppState } from '@app/store/reducers';
import { AppLanguages } from '@app/constants/localizationConstants';

type FindTabBySlug = (
  tabItems: TabItem[],
  categoryName: string,
) => TabItem | undefined
export const findTabBySlug: FindTabBySlug = (tabItems, categoryName) => (
  tabItems.find((tab) => tab?.url === decodeURIComponent(categoryName))
);

export const getLocalizedTicketsTabs = (state: AppState): LocalizedTabs => (
  AppLanguages.reduce((acc, lang) => {
    const configuration = state.ticketing.configuration[lang];
    acc[lang] = configuration?.tabs?.filter((tab) => tab.isVisible) ?? [];
    return acc;
  }, {} as LocalizedTabs)
);

type GetCurrentTab = (
  state: AppState,
  categoryName: string,
  language: LanguageType,
) => LocalizedCurrentTab

export const getLocalizedCurrentTab: GetCurrentTab = (state, categoryName, language) => {
  const tabs = getLocalizedTicketsTabs(state);
  const currentTabId = findTabBySlug(tabs[language], categoryName)?.id
    ?? (tabs && !categoryName ? tabs[language][0] : null)?.id;

  return AppLanguages.reduce((acc, lang) => {
    acc[lang] = tabs[lang].find((tab) => tab.id === currentTabId) ?? tabs[0];
    return acc;
  }, {} as LocalizedCurrentTab);
};
