import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  color: {
    freeSpeechRed: '#d50000',
    whiteSmoke: '#f5f5f5',
    harleyDavidsonOrange: '#c9240d',
    cheninYellow: '#d8be6d',
    barleyCorn: '#a29161 ',
    dimGray: '#626262',
    veryDarkGray: '#141414',
    darkGray: '#2b2b2b',
  },
  fontFamily: {
    milan: 'MilanTypeRev, Helvetica Neue, Helvetica, Arial, sans-serif',
    raleway: 'Raleway, Helvetica Neue, Helvetica, Arial, sans-serif',
  },
};

export default theme;
