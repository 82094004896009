import React from 'react';
import styled from 'styled-components';

interface NewsContentContainerProps {
  children: JSX.Element[] | JSX.Element | null | undefined;
}

const NewsContentContainer = ({ children }: NewsContentContainerProps): React.ReactElement => (
  <NewsContentSection>
    {children}
  </NewsContentSection>
);

export default NewsContentContainer;

const NewsContentSection = styled.section`
  max-width: 920px;
  margin: auto;
  padding: 10px;
  font-size: 14px;
  background: transparent;
  color: inherit; // Use defined on page text color
`;
