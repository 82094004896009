import { TopNews } from '@app/types/newsTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { sendRequestTyped } from '@app/services/kentico/request';
import { langCodeToKenticoLangMap } from '@app/constants/localizationConstants';
import { convertCarouselData } from './converters/carouselConverter';

interface GetCarouselDataFunc {
  (requestParams: {
    carousel: string;
    language: LanguageType;
  }): Promise<TopNews[]>;
}
export const getCarouselData: GetCarouselDataFunc = async ({ carousel, language }) => {
  const carouselSlidesResponse = await sendRequestTyped({
    path: `/${carousel}`,
    params: {
      depth: 4,
      language: langCodeToKenticoLangMap[language],
      'system.language': langCodeToKenticoLangMap[language],
    },
  });

  return convertCarouselData(carouselSlidesResponse);
};
