import {
  GeoTargetingAppearingTypes, GeoTargetingData,
  MenuItem, RelatedMenuItem, SubMenuItem,
} from '@app/types/configurationTypes';
import { NavigationLinkParams } from '@app/types/routerTypes';

import AppRoutes from '@app/constants/routes';
import { menuIdToPathIdMap } from '@app/constants/configurationConstants';
import { SeasonNavigationTypeMap } from '@app/constants/seasonConstants';

import { SecondLevelMenuItems } from '@app/components/SecondLevelMenu';
import { UserCountryType } from '@app/types/userTypes';

interface FormatMenuLinkPropsFunc {
  (item: MenuItem, seasonId: string, seasonUrlSlug: string): NavigationLinkParams;
}

export const formatMenuLinkProps: FormatMenuLinkPropsFunc = (
  { id, navigation, url }, seasonId, seasonUrlSlug,
) => {
  const items = Object.values(navigation);
  const categoryName = items?.[0]?.categories?.length ? items[0].categories[0].url : '';
  const topLevel = url ?? '';
  const secondLevel = items?.[0]?.url ?? '';

  return {
    // Use Schedule route as default for Season Menu
    pathKey: menuIdToPathIdMap[id] === menuIdToPathIdMap.web_season
      ? menuIdToPathIdMap[SeasonNavigationTypeMap.schedule]
      : menuIdToPathIdMap[id] ?? menuIdToPathIdMap.web_template,
    pathParams: {
      // Standard page(s) param
      categoryName,
      // Web Template Page(s) params
      topLevel,
      secondLevel,
      // Season page(s) default params
      urlSlug: seasonUrlSlug,
      seasonId,
    },
  };
};

interface FormatSubMenuLinkPropsFunc {
  (parentItem: MenuItem, item: SubMenuItem, seasonId: string, seasonUrlSlug: string): NavigationLinkParams;
}

export const formatSubMenuLinkProps: FormatSubMenuLinkPropsFunc = (
  parentItem, { id, categories, url }, seasonId, seasonUrlSlug,
) => {
  const topLevel = parentItem?.url ?? '';
  const secondLevel = url ?? '';

  return {
    pathKey: menuIdToPathIdMap[id] || menuIdToPathIdMap.web_template,
    pathParams: {
      categoryName: categories?.[0]?.url ?? '',
      urlSlug: seasonUrlSlug,
      seasonId,
      topLevel,
      secondLevel,
    },
  };
};

interface FindAndFormatSubMenuLinkPropsFunc {
  (menu: MenuItem[], id: string): NavigationLinkParams;
}

export const findAndFormatSubMenuLinkByIdProps: FindAndFormatSubMenuLinkPropsFunc = (menu, id) => {
  let topLevelMenu;
  let secondLevelMenu;
  let thirdLevelMenu;
  menu.forEach((topMenuItem) => {
    if (!topLevelMenu) {
      if (topMenuItem.id === id) {
        topLevelMenu = topMenuItem;
        secondLevelMenu = Object.values(topLevelMenu.navigation)?.[0];
        thirdLevelMenu = secondLevelMenu?.categories?.[0];
      } else {
        Object.values(topMenuItem.navigation).forEach((secondMenuItem) => {
          if (secondMenuItem.id === id) {
            topLevelMenu = topMenuItem;
            secondLevelMenu = secondMenuItem;
            thirdLevelMenu = secondLevelMenu?.categories?.[0];
          } else {
            secondMenuItem.categories.forEach((thirdMenuItem) => {
              if (thirdMenuItem.id === id) {
                topLevelMenu = topMenuItem;
                secondLevelMenu = secondMenuItem;
                thirdLevelMenu = thirdMenuItem;
              }
            });
          }
        });
      }
    }
  });

  const topLevel = topLevelMenu?.url ?? '';
  const secondLevel = secondLevelMenu?.url ?? '';
  const categoryName = thirdLevelMenu?.url ?? '';

  return {
    pathKey: menuIdToPathIdMap.web_template,
    pathParams: {
      categoryName,
      topLevel,
      secondLevel,
    },
  };
};


interface FormatRelatedItemPropsFunc {
  (item: RelatedMenuItem, seasonId: string, seasonUrlSlug: string): NavigationLinkParams;
}

export const formatRelatedItemLinkProps: FormatRelatedItemPropsFunc = (
  item, seasonId, seasonUrlSlug: string,
) => ({
  pathKey: (item.secondLevel
    ? item.secondLevel && menuIdToPathIdMap[item.secondLevel.id]
    : item.topLevel && menuIdToPathIdMap[item.topLevel.id]) ?? AppRoutes.WebTemplate.path,
  pathParams: {
    topLevel: item.topLevel?.url ?? '',
    secondLevel: item.secondLevel?.url ?? '',
    thirdLevel: item.thirdLevel?.url ?? '',
    categoryName: item.thirdLevel?.url ?? '',
    urlSlug: seasonUrlSlug,
    seasonId,
  },
});

type MapSecondLevelMenuItems = (params: {
  menu: MenuItem;
  selectedItemId?: string;
  selectedItemUrl?: string;
}) => SecondLevelMenuItems

export const mapSecondLevelMenuItems: MapSecondLevelMenuItems = ({ menu, selectedItemId, selectedItemUrl }) => (
  Object
    .values(menu?.navigation ?? {})
    .map(({
      id, name, categories, type, external, url, related, isVisible, geo,
    }) => ({
      id,
      name,
      isActive: selectedItemId ? id === selectedItemId : url === selectedItemUrl,
      linkData: {
        pathKey: menuIdToPathIdMap[id] ?? menuIdToPathIdMap.web_template,
        pathParams: {
          topLevel: menu?.url ?? '',
          secondLevel: url ?? '',
          categoryName: categories[0]?.url ?? '',
        },
      },
      type,
      external,
      related,
      isVisible,
      geo,
    }))
);

export const checkGetTargetingData = (
  geo: GeoTargetingData, userCountry: UserCountryType,
): boolean => !geo?.active || (!!userCountry && (
  (geo?.appearing === GeoTargetingAppearingTypes.Allow
      && !!geo?.countries?.find((country) => country === userCountry.toLowerCase()))
    || (geo?.appearing === GeoTargetingAppearingTypes.Deny
      && !geo?.countries?.find((country) => country === userCountry.toLowerCase()))
));
