import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import chunk from 'lodash/chunk';

import { Devices } from '@app/styles';
import { GA_EVENT, GA_CATEGORY } from '@app/types/googleTypes';
import { MenuItemTypes, Social } from '@app/types/configurationTypes';
import { LocalizedLink } from '@app/components/Localization';

import { useFooter } from '@app/components/Footer/FooterHooks';
import { useLanguage } from '@app/components/Hooks';
import { useMenSquadAllCompetitionsParams } from '@app/pages/Season/Season/SeasonHooks';

import { formatRelatedItemLinkProps } from '@app/helpers/menuHelpers';
import { pushGAEvent } from '@app/helpers/googleHelpers';
import { getStaticImageConfig } from '@app/helpers/imageHelper';

const Footer = (): React.ReactElement => {
  const { formatMessage } = useIntl();
  const language = useLanguage();
  const footer = useFooter();
  const { seasonId, urlSlug } = useMenSquadAllCompetitionsParams();

  return (
    <Container>
      <DividerLineTop />
      <FooterWrapper>
        <LogoContainer>
          <LeftHashTag>
            <FormattedMessage id="footer.leftHashTag" />
          </LeftHashTag>
          <LogoIcon src="/images/acm_125_years.png" alt={formatMessage({ id: 'mainLogo.alt' })} />
          <HashTag>
            <FormattedMessage id="footer.rightHashTag" />
          </HashTag>
        </LogoContainer>
        <SocialSection>
          <SocialTitle><FormattedMessage id="footer.milanSocialMedia" /></SocialTitle>
          {chunk(footer?.social, 4).map((row, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <SocialIcons key={index}>
              {row.map(({ name, url, icon }: Social) => (
                <SocialIcon
                  key={name}
                  onClick={(): void => pushGAEvent({
                    event: GA_EVENT.CLICK_FOOTER,
                    category: GA_CATEGORY.SOCIAL_ICON,
                    label: name,
                    language,
                  })}
                >
                  <a {...url}>
                    <img
                      {...getStaticImageConfig({ url: icon.url, height: 30 })}
                      alt={icon.description}
                    />
                  </a>
                </SocialIcon>
              ))}
            </SocialIcons>
          ))}
        </SocialSection>
        <DividerLine />
        <CopyrightLinksContainer>
          <CopyrightText dangerouslySetInnerHTML={{ __html: footer?.copyright ?? '' }} />
          <FillRemainingSpace />
          <LinksSectionWrapper>
            {chunk(footer?.legalLinks, 3).map((column, index) => (
            // eslint-disable-next-line react/no-array-index-key
              <LinksSection key={index}>
                {column.map((legalLink) => (
                  <LegalLinkWrapper key={legalLink.item.id}>
                    {legalLink.type === MenuItemTypes.ExternalLink ? (
                      <LegalLink as="a" {...legalLink.item.url}>
                        {legalLink.item.name}
                      </LegalLink>
                    ) : (
                      <LegalLink {...formatRelatedItemLinkProps(legalLink.item, seasonId, urlSlug)}>
                        {legalLink.item.name}
                      </LegalLink>
                    )}
                  </LegalLinkWrapper>
                ))}
              </LinksSection>
            ))}
          </LinksSectionWrapper>
        </CopyrightLinksContainer>
      </FooterWrapper>
    </Container>
  );
};

const Container = styled.footer`
  width: 100%;
  flex: 0 0 auto;
  padding: 0 20px 20px 20px;
  background-color: black;
  color: white;
`;

const FooterWrapper = styled.div`
  max-width: 1280px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1000;
`;

const LogoContainer = styled.div`
  --logo-height: 100px;

  position: absolute;
  width: 100%;
  height: var(--logo-height);
  top: calc(-1 * var(--logo-height) / 2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  @media ${Devices.tablet} {
    --logo-height: 180px;
    height: var(--logo-height);
  }
`;

const LogoIcon = styled.img`
  height: 100%;
  margin: 0 10px;

  @media (min-width: 412px) {
    margin: 0 20px;
  }

  @media (min-width: 768px) {
    margin: 0 50px;
  }

  @media ${Devices.tabletLarge} {
    margin: 0 100px;
  }
`;

const HashTag = styled.span`
  display: block;
  flex: 1;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.61px;
  position: relative;
  top: 73px;
  text-transform: uppercase;

  @media (min-width: 412px) {
    font-size: 17px;
  }

  @media ${Devices.tablet} {
    font-size: 24px;
    top: 135px;
  }

  @media (min-width: 768px) {
    font-size: 26px;
  }
`;

const LeftHashTag = styled(HashTag)`
  text-align: right;
`;

const SocialSection = styled.div`
  padding-top: 90px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${Devices.tablet} {
    padding-top: 140px;
  }
`;

const SocialTitle = styled.h2`
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.44px;
  font-family: ${(props): string => props.theme.fontFamily.milan};
`;

const SocialIcons = styled.ul`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const SocialIcon = styled.li`
  padding: 0 16.5px;

  & a {
    display: block;
    width: 25px;
    height: 25px;
  }

  & img {
    height: 100%;
    width: 100%;
  }

  @media ${Devices.tablet} {
    & a {
      width: 30px;
      height: 30px;
    }
  }
`;

const DividerLineTop = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.1;
  border: solid 1px #ffffff;
`;

const DividerLine = styled.div`
  margin: 20px auto 0;
  width: calc(100% - 40px);
  height: 1px;
  opacity: 0.1;
  border: solid 1px #ffffff;

  @media ${Devices.responsiveContent} {
    width: 505px;
  }
`;

const CopyrightLinksContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;

  @media ${Devices.responsiveContent} {
    flex-direction: row;
    align-items: initial;
  }
`;

const CopyrightText = styled.div`
  color: gray;
  font-size: 12px;
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-weight: 300;
  line-height: 1.83;
  letter-spacing: 0.33px;
  color: #ffffff;

  & > * {
    padding: 3px 0;
  }
`;

const FillRemainingSpace = styled.div`
 flex: 1 1 auto;
`;

const LinksSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const LinksSection = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-right: 30px;
  height: 90px;

  &:last-of-type {
    margin-right: 0;
  }

  @media ${Devices.responsiveContent} {
    margin-top: 0;
  }
`;

const LegalLinkWrapper = styled.li`
  padding-bottom: 5px;
`;

const LegalLink = styled(LocalizedLink)`
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71;
  letter-spacing: 0.39px;
  color: #ffffff;
  text-transform: uppercase;
`;

export default Footer;
