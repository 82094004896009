import pull from 'lodash/pull';

import { waitForConfigurationDownload } from '@app/store/actions/configurationActions';
import {
  setWebTemplateError,
  loadingWebTemplateData,
} from '@app/store/actions/webTemplateActions';

import { getQuestionBySlug, getQuestionById } from '@app/services/kentico/questionService';
import { getWebTemplateData } from '@app/services/kentico/webTemplateService';

import { ActionWithPromiseReturn } from '@app/types/actionTypes';
import {
  WebPageImage, WebPageTitle, WebTemplateData, WebTemplateTypes,
} from '@app/types/webTemplateTypes';
import { LocalizedQuestion, Question, QuestionData } from '@app/types/faqQuestionTypes';
import { QuestionActionTypes, ResetQuestion, SetQuestion } from '@app/store/actionTypes/questionActionTypes';
import { LanguageType } from '@app/types/localizationTypes';

import { AppLanguages } from '@app/constants/localizationConstants';
import { getMenuSubItem } from '@app/helpers/webTemlateHelpers';
import { PageError } from '@app/types/errorTypes';

const setQuestion = (data: LocalizedQuestion): SetQuestion => ({
  type: QuestionActionTypes.SET_QUESTION,
  payload: data,
});

export const resetQuestion = (): ResetQuestion => ({
  type: QuestionActionTypes.RESET_QUESTION,
});

type MapQuestionData = (
  templates: WebTemplateData[],
  question: Question,
) => QuestionData;

const mapQuestionData: MapQuestionData = (templates, question) => {
  const titleData = templates
    .find((template) => template?.type === WebTemplateTypes.WebPageTitle) as WebPageTitle;

  const imageData = templates
    .find((template) => template?.type === WebTemplateTypes.WebPageImage) as WebPageImage;

  return {
    question,
    topicTitle: titleData?.title ?? '',
    imageData,
  };
};

type GetRestQuestionData = (
  questionId: string,
  webTemplateId: string,
  language: LanguageType,
) => ActionWithPromiseReturn;

const getQuestionDataForNotActiveLangs: GetRestQuestionData = (questionId, webTemplateId, language) => (
  async (dispatch): Promise<void> => {
    const restLanguages = pull([...AppLanguages], language);

    const questionRequests = Promise.all(restLanguages.map((language) => (
      getQuestionById(questionId, language)
    )));

    const webTemplateRequests = Promise.all(restLanguages.map((language) => (
      getWebTemplateData(webTemplateId, language)
    )));

    const [questions, webTemplates] = await Promise.all([questionRequests, webTemplateRequests]);

    const languageToQuestionDataMap = restLanguages.reduce((acc, language, index) => {
      acc[language] = mapQuestionData(webTemplates[index].templateHierarchy, questions[index]);
      return acc;
    }, {} as LocalizedQuestion);

    dispatch(setQuestion(languageToQuestionDataMap));
  }
);

type GetQuestionData = (data: {
  topLevel: string;
  secondLevel: string;
  categoryName: string;
  tab: string;
  language: LanguageType;
}) => ActionWithPromiseReturn;

export const getQuestionData: GetQuestionData = ({
  topLevel, secondLevel, categoryName, language, tab,
}) => (
  async (dispatch, getState): Promise<void> => {
    await waitForConfigurationDownload();

    try {
      const lastLevelMenu = getMenuSubItem({
        state: getState(), topLevel, secondLevel, categoryName, language,
      });

      const webTemplateId = lastLevelMenu?.id ?? '';

      const requestQuestion = getQuestionBySlug(tab, language);
      const requestWebTemplateData = getWebTemplateData(webTemplateId, language);
      const [question, webTemplateData] = await Promise.all([requestQuestion, requestWebTemplateData]);

      const templates = Object.values(webTemplateData.templateHierarchy);
      // TS doesn't check computed object properties values (considers them as dynamic values)
      // but this hack helps to get around this problem somehow
      const questionData: LocalizedQuestion = {};
      questionData[language] = mapQuestionData(templates, question);

      dispatch(setQuestion(questionData));
      await dispatch(getQuestionDataForNotActiveLangs(question.id, webTemplateId, language));
    } catch (e) {
      console.error('Error on fetching question', e);
      dispatch(setWebTemplateError(language, PageError.Sorry));
    } finally {
      dispatch(loadingWebTemplateData(false));
    }
  }
);
