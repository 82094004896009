import React from 'react';
import styled from 'styled-components';
import { shallowEqual, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Devices } from '@app/styles';

import { AppState } from '@app/store/reducers';
import { Menu as MenuInterface } from '@app/types/configurationTypes';

import MenuItem from './MenuItem';

const Menu: React.FC = () => {
  const { locale } = useIntl();
  const menuItems = useSelector<AppState, MenuInterface>(
    (state) => state.configuration[locale]?.menu,
    shallowEqual,
  );

  const menu = (menuItems && Object.values(menuItems).filter(({ isVisible }) => isVisible)) || [];

  return (
    <Container>
      <MenuItemsList>
        {
          menu.map((item) => (
            <MenuItem item={item} key={item.id} />))
        }
      </MenuItemsList>
    </Container>
  );
};

export default Menu;

const Container = styled.nav`
  display: none;

  @media ${Devices.desktopSmall} {
    display: block;
  }
`;

const MenuItemsList = styled.ul`
  font-size: 16px;
  height: 100%;
  display: flex;
  font-family: ${(props): string => props.theme.fontFamily.milan};
`;
