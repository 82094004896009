import { HomeConfiguration, HomeBlocks } from '@app/types/homeConfigurationTypes';
import { MenCompetitionsMap, OptaSDCompetitionsMap } from '@app/services/opta/constants/competitionConstants';
import { KenticoWebTemplateModularContent } from '@app/services/kentico/types/webTemplateTypes';
import { convertWebTemplateElement } from '@app/services/kentico/converters/webTemplateConverter';
import {
  WebGoogleAds, WebHomeLanding, WebHomeSlider, WebTemplateTypes,
} from '@app/types/webTemplateTypes';
import { KenticoResponse } from '../types/responseTypes';
import { HomeModularContent, KenticoHomeElement, KenticoHomeLandingElement } from '../types/homeTypes';

export function convertHomeData(kentico: KenticoResponse<KenticoHomeElement, HomeModularContent>): HomeConfiguration {
  const elements = kentico?.items[0]?.elements;
  const matchBoxIds = elements?.matchbox?.value || [];
  const sequence = elements?.sequence?.value ?? [];
  const embeddedOverlayElementId = kentico?.modular_content[
    sequence.find((id) => id === HomeBlocks.EMBEDDED_OVERLAY) ?? ''
    ]?.elements?.content?.value?.[0] ?? '';
  const embeddedOverlayData = kentico?.modular_content[embeddedOverlayElementId];

  const matchBox = matchBoxIds.map((id) => {
    const matchDataElememt = kentico?.modular_content[id]?.elements;

    return {
      gameId: matchDataElememt?.game_opta_id?.value,
      competitionId: matchDataElememt?.competition?.value[0] as MenCompetitionsMap | OptaSDCompetitionsMap,
      seasonId: matchDataElememt?.season?.value[0]?.name,
    };
  });

  return {
    matchBox,
    sequence,
    gameId: elements?.match_day?.value?.[0]?.codename?.toLowerCase() === 'on'
      ? kentico?.modular_content[elements?.match?.value[0] ?? '']?.elements?.game_opta_id?.value : '',
    carousel: elements?.top_element?.value[0] ?? '',
    embeddedOverlay: {
      id: embeddedOverlayData?.system?.codename ?? '',
      type: WebTemplateTypes.WebEmbeddedOverlay,
      content: Buffer.from(`${embeddedOverlayData?.elements?.html?.value ?? ''}`).toString('base64'),
      delay: Number(embeddedOverlayData?.elements?.delay?.value ?? 0),
    },
  };
}

export function convertHomeLandingData(
  kentico: KenticoResponse<KenticoHomeLandingElement, KenticoWebTemplateModularContent>,
): WebHomeLanding {
  const value = kentico?.item?.elements?.content?.value[0] ?? '';
  const element = convertWebTemplateElement(kentico, value) as WebHomeLanding;
  element.id = kentico?.item?.system?.codename || element.id;
  return element;
}

export function convertHomeSliderData(
  kentico: KenticoResponse<KenticoHomeLandingElement, KenticoWebTemplateModularContent>,
): WebHomeSlider {
  const value = kentico?.item?.elements?.content?.value[0] ?? '';
  const element = convertWebTemplateElement(kentico, value) as WebHomeSlider;
  element.id = kentico?.item?.system?.codename || element.id;
  return element;
}

export function convertHomeGoogleAds(
  kentico: KenticoResponse<KenticoHomeLandingElement, KenticoWebTemplateModularContent>,
): WebGoogleAds | null {
  const value = kentico?.item?.elements?.content?.value[0] ?? '';
  const element = convertWebTemplateElement(kentico, value) as WebGoogleAds;

  if (!element) return null;

  element.id = kentico?.item?.system?.codename || element?.id;
  return element;
}
