import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { GA_NEWS_EVENT, GA_NEWS_CATEGORY } from '@app/constants/newsConstants';
import { AppState } from '@app/store/reducers';
import { BaseNewsItem, NewsTypes, EditorialContentTypes } from '@app/types/newsTypes';
import { localizeRouteKey } from '@app/helpers/localizationHelper';
import { getNewsPathKey, dispatchNewsPremiumClickEvent } from '@app/helpers/newsHelpers';
import { pushGAEvent } from '@app/helpers/googleHelpers';

import { PremiumBadge } from '@app/components';
import { LocalizedDate, LocalizedLink } from '@app/components/Localization';
import { useCategoryUrl, useLanguage } from '@app/components/Hooks';
import NewsListItemImage from './NewsListItemImage';
import { PremiumBadgeWrapper } from '../../NewsItemSections';

interface NewsListItemProps {
  item: BaseNewsItem | null | undefined;
  type: NewsTypes;
  size: number;
}

const NewsListItem = React.memo(({ item, size, type }: NewsListItemProps): React.ReactElement | null => {
  const isLoggedIn = useSelector<AppState, boolean>((state) => state.user.isLoggedIn, shallowEqual);

  const categoryName = useCategoryUrl(item);
  const language = useLanguage();
  const isLoaded = Boolean(item);

  const pathKey = getNewsPathKey(item?.type ?? EditorialContentTypes.NEWS);
  const pathParams = {
    publicationDate: item?.publicationDate ?? '',
    urlSlug: item?.urlSlug ?? '',
    categoryName,
  };

  const background = type === NewsTypes.VIDEOS ? 'black' : 'white';
  const textColor = type === NewsTypes.VIDEOS ? 'white' : 'black';
  const color = isLoaded ? textColor : 'transparent';

  const isHighlighted = false;
  const isHome = false;
  const isLarge = false;

  const onClick = (event): void => {
    if (item?.isPremium && !isLoggedIn) {
      event.preventDefault();
      dispatchNewsPremiumClickEvent(
        localizeRouteKey({ pathKey, pathParams }, language),
      );
    }

    if (isLoaded) {
      pushGAEvent({
        event: GA_NEWS_EVENT[type],
        category: GA_NEWS_CATEGORY[type],
        action: categoryName,
        label: item?.title,
        language,
      });
    }
  };

  return item !== undefined ? (
    <NewsItemContainerLink
      isLoaded={isLoaded}
      pathKey={pathKey}
      pathParams={pathParams}
      onClick={onClick}
      background={background}
    >
      <NewsListItemImage
        url={item?.image?.url}
        description={item?.image?.description}
        size={size}
        type={type}
      />
      <Container background={background} color={color} isHighlighted={isHighlighted}>
        <Time isHighlighted={isHighlighted || isLarge} isLoaded={isLoaded}>
          <LocalizedDate date={item?.publicationDate as string} />
          <Line isHighlighted={isHighlighted} />
        </Time>
        <Heading isLoaded={isLoaded}>
          <Title isHighlighted={isHighlighted} isLarge={isLarge} as={isHome ? 'h3' : 'h2'}>
            {item?.title}
          </Title>
        </Heading>
      </Container>
      {Boolean(item?.isPremium) && (
        <PremiumBadgeWrapper>
          <PremiumBadge />
        </PremiumBadgeWrapper>
      )}
    </NewsItemContainerLink>
  ) : null;
});

export default NewsListItem;

const NewsItemContainerLink = styled(LocalizedLink)<{ isLoaded: boolean; background: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: ${({ isLoaded }): string => (isLoaded ? 'pointer' : 'progress')};
  height: 100%;

  overflow: hidden;

  background: ${({ background }): string => background};
  -webkit-animation: fade-in .3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in .3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

  &:nth-child(2n+2) {
    animation-delay: .05s;
  }

  &:nth-child(3n+3) {
    animation-delay: .1s;
  }

  &:hover img {
    transform: scale(1.2);
  }
`;

interface ContainerProps {
  isHighlighted: boolean;
  background: string;
  color: string;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  color: ${({ color }): string => color};
  background: ${({ background }): string => background};
  padding: 10px;
  min-height: 25px;
  flex: 1;

  @media ${Devices.tablet} {
    padding: ${({ isHighlighted }): string => (isHighlighted ? '40px' : '10px')} 10px 10px;
  }
`;

const Time = styled.div<{ isHighlighted: boolean; isLoaded: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 15px;

  font-size: 12px;
  letter-spacing: 0.33px;
  position: relative;
  margin-bottom: 10px;
  min-width: 100px;
  width: ${({ isLoaded }): string => (isLoaded ? 'auto' : '100%')};

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    min-width: 150px;
    background: #c9c9c9;
    content: '';
    z-index: 9999;

    -webkit-animation: ${({ isLoaded }): string => (isLoaded ? 'scale-out-vertical 0.1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both' : 'scale-in-ver-center 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both')};
    animation: ${({ isLoaded }): string => (isLoaded ? 'scale-out-vertical 0.1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both' : 'scale-in-ver-center 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both')};
  }

  @media ${Devices.tablet} {
    font-size: ${({ isHighlighted }): string => (isHighlighted ? '14px' : '12px')};
  }
`;

const Line = styled.hr<{isHighlighted: boolean}>`
  height: 2px;
  width: 15px;
  border: none;
  background-color: ${(props): string => props.theme.color.freeSpeechRed};
  transition: all .3s ease;
  margin: auto 10px;

  @media ${Devices.tablet} {
    height: ${({ isHighlighted }): string => (isHighlighted ? '3px' : '2px')};
    width: ${({ isHighlighted }): string => (isHighlighted ? '25px' : '15px')};
    right: ${({ isHighlighted }): string => (isHighlighted ? '-40px' : '-25px')};
  }
`;

const Heading = styled.div<{ isLoaded: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex: ${({ isLoaded }): string => (isLoaded ? '1' : '50px')};

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #c9c9c9;
    content: '';
    z-index: 9999;

    -webkit-animation: ${({ isLoaded }): string => (isLoaded ? 'scale-out-vertical 0.1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both' : 'scale-in-ver-center 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both')};
    animation: ${({ isLoaded }): string => (isLoaded ? 'scale-out-vertical 0.1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both' : 'scale-in-ver-center 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both')};
  }
`;

const Title = styled.h3<{ isHighlighted: boolean; isLarge: boolean }>`
  line-height: 1.25;
  text-transform: uppercase;\
  display: -webkit-box;
  -webkit-line-clamp: ${({ isLarge }): number => (isLarge ? 2 : 3)};;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: ${({ isLarge }): string => (isLarge ? '18px' : '16px')};
  transition: all .3s ease;

  @media ${Devices.tablet} {
    font-size: ${({ isHighlighted, isLarge }): string => { /* eslint-disable indent */
      if (isHighlighted) return '26px';
      return isLarge ? '21px' : '16px';
    }};
    line-height: 1.6;
  }
`;
