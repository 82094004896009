import React from 'react';

const ArrowLeftIcon = (): React.ReactElement => (
  <svg width="10px" height="18px" viewBox="0 0 10 18" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon" transform="translate(-7.000000, -287.000000)">
        <g transform="translate(13.000000, 295.500000) rotate(-180.000000) translate(-13.000000, -295.500000) translate(0.000000, 277.000000)">
          <polygon
            transform="translate(15.000000, 18.500000) scale(1, -1) rotate(90.000000) translate(-15.000000, -18.500000) "
            points="15 14.5 23.5 22.5 6.5 22.5"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ArrowLeftIcon;
