import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import AppRoutes from '@app/constants/routes';
import { LocalizedLink } from '@app/components/Localization';
import { Devices } from '@app/styles';

import { useIsHomeDetection } from '../HeaderHooks';

interface LogoProps {
  isSmall: boolean;
}

const Logo = ({ isSmall }: LogoProps): React.ReactElement => {
  const { formatMessage } = useIntl();
  const isHome = useIsHomeDetection();

  return (
    <Container as={isHome ? 'h1' : 'div'}>
      <LogoBackground isSmall={isSmall} isVisible={false} />
      <LocalizedLink
        pathKey={AppRoutes.Home.path}
        onClick={(): void => { window.scrollTo(0, 0); }}
      >
        <LogoIcon src="/images/acm_125_years.png" isSmall={isSmall} alt={formatMessage({ id: 'mainLogo.alt' })} />
      </LocalizedLink>
    </Container>
  );
};

const Container = styled.div`
  display: none;

  @media ${Devices.desktopSmall} {
    display: block;
  }
`;

const LogoBackground = styled.div<{ isSmall: boolean; isVisible: boolean }>`
  width: 381px;
  height: calc(var(--top-header-height) + var(--navigation-header-height));
  transition: all 300ms;
  position: absolute;
  left: -66px;
  background-image: url(/images/logoCircles.png);
  background-repeat: no-repeat;
  background-position-y: ${({ isSmall }): string => (isSmall ? '-46px' : '-127px')};
  background-position-x: ${({ isSmall }): string => (isSmall ? '68px' : '0px')};
  background-size: ${({ isSmall }): string => (isSmall ? '235px' : '381px')};
  opacity: ${({ isVisible }): string => (isVisible ? '1' : '0')};
`;

const LogoIcon = styled.img<{ isSmall: boolean }>`
  transition: all 300ms;
  height: ${({ isSmall }): string => (isSmall ? '82px' : '132px')};
  position: absolute;
  top: ${({ isSmall }): string => (isSmall ? '74px' : '68px')};
  left: ${({ isSmall }): string => (isSmall ? '94px' : '54px')};
  z-index: 401;
`;

export default Logo;
