import React from 'react';
import { push } from 'connected-react-router';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Route, useParams, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import AppRoutes from '@app/constants/routes';
import { Devices } from '@app/styles';
import { TeamSquadTypes } from '@app/services/kentico/types/teamsTypes';
import { GA_CATEGORY, GA_EVENT } from '@app/types/googleTypes';

import { pushGAEvent } from '@app/helpers/googleHelpers';
import { isOptaSDCompetition } from '@app/services/opta/helpers/competitionHelpers';
import { findCompetitionIdByUrlSlug } from '@app/helpers/seasonHelpers';
import { localizeRouteKey } from '@app/helpers/localizationHelper';
import { ExpandArrow } from '@app/components/Icons';
import { LocalizedLink, LocalizedSwitch } from '@app/components/Localization';
import { useBreadcrumbsJsonLD, useLanguage } from '@app/components/Hooks';
import {
  Metadata, MetaJsonLD, PageTitle, SecondLevelMenu,
} from '@app/components';
import { PageValidationWrapper } from '@app/pages/components';
import {
  useCompetitionIdSelector,
  useCompetitionSelector,
  useCompetitionsSelector,
  useIsLoadingSelector,
  useIsMenSquadAllCompetitionsSelected,
  useNavigationSelector,
  useSeasonPageErrorReset,
  useSeasonPagePathKey,
  useSeasonPageTitle,
  useSeasonResetOnUnmount,
  useSeasonRoutesValidation,
  useSeasonSeoSelector,
  useSelectedCategory,
  useSelectOptions,
  useStandingsPageLocalizedCheck,
} from './SeasonHooks';

const Season = (): React.ReactElement => {
  const dispatch = useDispatch();
  const language = useLanguage();

  const { formatMessage } = useIntl();
  const { page = '', seasonId = '', urlSlug = '' } = useParams();

  const title = useSeasonPageTitle();
  const options = useSelectOptions();
  const seo = useSeasonSeoSelector();
  const isLoading = useIsLoadingSelector();
  const competitions = useCompetitionsSelector();
  const seasonPagePathKey = useSeasonPagePathKey();
  const competition = useCompetitionSelector();
  const competitionId = useCompetitionIdSelector();
  const { categoryMenuItems, secondLevelMenuItems } = useNavigationSelector();
  const isStandingsPage = useStandingsPageLocalizedCheck();
  const breadcrumbsJsonLD = useBreadcrumbsJsonLD(useSelectedCategory()?.seo?.title ?? '');
  const validationError = useSeasonRoutesValidation();
  const isAllCategorySelected = useIsMenSquadAllCompetitionsSelected();

  useSeasonPageErrorReset();
  useSeasonResetOnUnmount();
  useSeasonRoutesValidation();

  const onCompetitionSelection = (e): void => {
    const selectedCompetitionId = findCompetitionIdByUrlSlug(competitions, e.target.value);
    const selectedSeasonId = isOptaSDCompetition(selectedCompetitionId)
      ? formatMessage({ id: 'season.active' })
      : competitions[selectedCompetitionId]?.seasonIds?.find(({ isActive }) => isActive)
      ?.seasonId ?? competitions[selectedCompetitionId]?.seasonIds?.[0]?.seasonId;

    pushGAEvent({
      event: isStandingsPage ? GA_EVENT.CLICK_STANDINGS : GA_EVENT.CLICK_SCHEDULE_RESULT,
      category: isStandingsPage ? GA_CATEGORY.STANDINGS : GA_CATEGORY.SCHEDULE,
      label: `${selectedCompetitionId}|${selectedSeasonId}`,
      language,
    });

    // Here we should push urlSlug directly in router - without effects usage
    dispatch(push(localizeRouteKey({
      pathKey: seasonPagePathKey,
      pathParams: {
        urlSlug: e.target.value,
        seasonId: selectedSeasonId,
      },
    }, language)));
  };
  const onSeasonSelection = (e): void => {
    pushGAEvent({
      event: isStandingsPage ? GA_EVENT.CLICK_STANDINGS : GA_EVENT.CLICK_SCHEDULE_RESULT,
      category: isStandingsPage ? GA_CATEGORY.STANDINGS : GA_CATEGORY.SCHEDULE,
      label: `${competitionId}|${e.target.value}`,
      language,
    });
    // Here we should push SeasonId directly in router - without effects usage
    dispatch(push(localizeRouteKey({
      pathKey: seasonPagePathKey,
      pathParams: { urlSlug, seasonId: e.target.value },
    }, language)));
  };

  const getCategoryPathParams = (id: string): { [prop: string]: string } => {
    const categoryCompetition = Object.values(competitions ?? {}).find((comp) => comp.categoryId === id);

    switch (id) {
      case TeamSquadTypes.MenSquad: return {
        urlSlug: formatMessage({ id: 'season.active.all' }).toLowerCase(),
        seasonId: formatMessage({ id: 'season.active' }).toLowerCase(),
        page,
      };
      case TeamSquadTypes.WomenSquad: return {
        urlSlug: categoryCompetition?.url ?? '',
        seasonId: categoryCompetition?.seasonIds?.find(({ isActive }) => isActive)?.seasonId ?? '',
        page,
      };
      case TeamSquadTypes.PrimaveraSquad:
      case TeamSquadTypes.FuturoSquad:
      default: return {
        urlSlug: categoryCompetition?.url ?? '',
        seasonId: formatMessage({ id: 'season.active' }).toLowerCase(),
        page,
      };
    }
  };

  return (
    <SeasonPage error={validationError}>
      { !!seo && <Metadata seo={seo} /> }
      { !!breadcrumbsJsonLD && <MetaJsonLD json={breadcrumbsJsonLD} /> }
      <SecondLevelMenu items={secondLevelMenuItems} />
      <FiltersSection>
        <Title>
          <PageTitle>
            {title}
          </PageTitle>
        </Title>
        <FiltersContainer isLoading={isLoading}>
          <TeamsContainer>
            {categoryMenuItems.map(({ id, name }) => (
              <Category
                key={id}
                pathKey={AppRoutes.Season.path}
                pathParams={getCategoryPathParams(id)}
                isActive={id === competition?.categoryId || (isAllCategorySelected && id === TeamSquadTypes.MenSquad)}
              >
                {name}
              </Category>
            ))}
          </TeamsContainer>
          <SelectContainer>
            <SelectWrapper>
              <Select value={urlSlug} onChange={onCompetitionSelection}>
                { options.map(({ id, name, url }) => <option key={id} value={url}>{name}</option>) }
              </Select>
              <ExpandArrow />
            </SelectWrapper>
            <SelectWrapper>
              <Select value={seasonId} onChange={onSeasonSelection}>
                {(competition?.seasonIds ?? [])
                  .map(({ seasonId, seasonName }) => (
                    <option key={`${seasonId}${seasonName}`} value={seasonId}>{seasonName}</option>))}
              </Select>
              <ExpandArrow />
            </SelectWrapper>
          </SelectContainer>
        </FiltersContainer>
      </FiltersSection>
      <LocalizedSwitch>
        <Route path={AppRoutes.Schedule.path} component={AppRoutes.Schedule.component} />
        <Route path={AppRoutes.Standings.path} component={AppRoutes.Standings.component} />
      </LocalizedSwitch>
    </SeasonPage>
  );
};

const SeasonPage = styled(PageValidationWrapper)`
  height: 100%;
  background-color: ${(props): string => props.theme.color.whiteSmoke};
`;

const FiltersSection = styled.section`
  max-width: 960px;
  padding: 0 10px 25px;
  margin: auto;
`;

const FiltersContainer = styled.div<{ isLoading: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  opacity: ${({ isLoading }): number => (isLoading ? 0.7 : 1)};
  transition: all .21s linear;

  @media ${Devices.tabletLarge} {
    flex-direction: row;
  }

  &:after {
    content: ' ';
    display: ${({ isLoading }): string => (isLoading ? 'block' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    width: 100%;
    height: 100%;

    background: transparent;
    z-index: 999;
    cursor: progress;
  }
`;

const Title = styled.div`
  margin-bottom: 25px;
`;

const Category = styled(LocalizedLink)<{isActive: boolean}>`
  color: black;
  opacity: ${({ isActive }): number => (isActive ? 1.0 : 0.2)};
  font-family: ${(props): string => props.theme.fontFamily.milan};
  margin-right: 10px;
`;

const SelectContainer = styled.div`
  display: flex;
`;

const SelectWrapper = styled.div`
  position: relative;
  width: auto;
  height: auto;
  margin: 10px 0 0 10px;

  &:first-child {
    margin: 10px 0 0 0;
  }

  &:first-of-type {
    & > select {
      min-width: 150px;
    }
  }

  & > svg {
    position: absolute;
    right: 5px;
    bottom: 10px;
  }

  @media ${Devices.tabletLarge} {
    margin: 0 0 0 10px;

    &:first-child {
      margin: 0 0 0 10px;
    }
  }
`;

const Select = styled.select`
  width: 100%;
  min-width: 100px;
  height: 30px;
  padding: 0 20px 0 5px;
  border-radius: 2px;
  border: solid 1px #dfdbdb;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: ${(props): string => props.theme.fontFamily.milan};

  @media ${Devices.mediumMobile} {
    width: auto;
  }
`;

const TeamsContainer = styled.div`
  height: 100%;
  font-size: 14px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media ${Devices.tablet} {
    flex-direction: row;
  }
`;

export default withRouter(Season);
