import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';

import { CommonButton } from '@app/components/Buttons';
import { Devices, theme } from '@app/styles';
import { UserIcon } from '@app/components/Icons';
import { LanguageType } from '@app/types/localizationTypes';
import { GA_EVENT, GA_CATEGORY } from '@app/types/googleTypes';
import { langCodeToSalesForceLangMap } from '@app/constants/localizationConstants';
import { AppState } from '@app/store/reducers';
import { GetAuthenticationUrl, GetProfileUrl } from '@app/services/salesforce/request';
import { pushGAEvent } from '@app/helpers/googleHelpers';

const LoginControl: React.FC = () => {
  const isLoggedIn = useSelector<AppState, boolean>((state) => state.user.isLoggedIn, shallowEqual);
  const language = useIntl().locale as LanguageType;
  const btnProps = {
    background: isLoggedIn ? theme.color.freeSpeechRed : 'unset',
    onClick: (): void => {
      if (isLoggedIn) {
        pushGAEvent({
          event: GA_EVENT.CLICK_MENU,
          category: GA_CATEGORY.MY_MILAN_PROFILE,
          label: window.location.href,
          language,
        });

        window.open(GetProfileUrl(langCodeToSalesForceLangMap[language]), '_blank');
      } else {
        pushGAEvent({
          event: GA_EVENT.CLICK_MENU,
          category: GA_CATEGORY.MY_MILAN_LOGIN,
          label: window.location.href,
          language,
        });
        window.location.href = GetAuthenticationUrl(language);
      }
    },
  };
  return (
    <LoginButton {...btnProps}>
      <LoginIcon isLoggedIn={isLoggedIn}>
        <UserIcon />
      </LoginIcon>
      { !isLoggedIn
      && (
        <LoginButtonText>
          <FormattedMessage id="buttons.login" />
        </LoginButtonText>
      )}
    </LoginButton>
  );
};

const LoginIcon = styled.div<{ isLoggedIn: boolean }>`
  cursor: pointer;

  & g {
    fill: #000;
    stroke: #000;
    transition: all 0.3s ease;
  }

  & #icon {
    width: 15px;
    height: 18px;
  }

  & #logged-in-dot {
    display: ${({ isLoggedIn }): string => (isLoggedIn ? 'block' : 'none')};
  }

  @media ${Devices.desktopSmall} {
    & g {
      fill: white;
      stroke: white;
    }

    & #icon {
      width: 12px;
      height: 14px;
    }

    & #logged-in-dot {
      display: none;
    }
  }
`;

const LoginButton = styled(CommonButton)<{ background: string }>`
  display: flex;
  width: 40px;
  height: inherit;
  transition: all 0.3s ease;
  background-color: transparent;

  &:hover {
    cursor: pointer;
    background-color: unset;
    & #icon g {
      fill: ${(props): string => props.theme.color.freeSpeechRed};
      stroke: ${(props): string => props.theme.color.freeSpeechRed};
    }
  }

  @media ${Devices.desktopSmall} {
    width: 10vw;
    background-color: ${(props): string => props.theme.color.freeSpeechRed};

    &:hover {
      cursor: pointer;
      background-color: unset;
      & #icon g {
        fill: ${({ background }): string => background};
        stroke: ${(props): string => props.theme.color.freeSpeechRed};
      }
    }
  }
`;

const LoginButtonText = styled.div`
  display: none;

  @media ${Devices.desktopSmall} {
    display: initial;
    margin-left: 10px;
  }
`;

export default LoginControl;
