import React from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { PageTitle } from '@app/components';
import { NewsPageProps, NewsTypes } from '@app/types/newsTypes';
import {
  useNewsListHeadingLabelSelector, useNewsCategoryByUrlSlugSelector,
} from '../NewsHooks';

const NewsPageTitle = React.memo(({ type }: NewsPageProps): React.ReactElement | null => {
  const { categoryName = '' } = useParams();
  const category = useNewsCategoryByUrlSlugSelector(type)?.name ?? categoryName;
  const labelId = useNewsListHeadingLabelSelector(type);

  return (
    <PageTitle color={type === NewsTypes.VIDEOS ? 'white' : 'black'}>
      <FormattedMessage id={labelId} values={{ category }} />
    </PageTitle>
  );
});

export default NewsPageTitle;
