import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { getLocalizedGames, getMatchFeeds, setMatchCenterMultiLangUrl } from '@app/store/actions/matchActions';
import { PageWrapper } from '@app/pages/components';
import { Loader, Metadata, WebTemplateSelect } from '@app/components';
import { ContainerLarge } from '@app/components/WebTemplates/components/WebContainers';
import { MatchHeader, MatchEvents, MatchCenterTabs } from './components';
import {
  useMatchCenter,
  useIsLoadingSelector,
  useMatchCenterValidation,
  useGameByUrlSlugSelector,
  useGameGoogleAdsSelector,
  useMatchCenterSeoSelector,
} from './MatchCenterHooks';

const MatchCenter = (): React.ReactElement => {
  const ads = useGameGoogleAdsSelector();
  const seo = useMatchCenterSeoSelector();
  const game = useGameByUrlSlugSelector();
  const error = useMatchCenterValidation();
  const isLoading = useIsLoadingSelector();

  useMatchCenter();

  return isLoading ? (<Loader />) : (
    <PageWrapper error={error}>
      { !!seo && <Metadata seo={seo} /> }
      <Container>
        <MatchHeader />
        <Main>
          <MatchEvents />
          <MatchCenterTabs isGalleryAvailable={!!game?.photos.length} />
        </Main>
        {!!ads && <WebTemplateSelect data={ads} />}
      </Container>
    </PageWrapper>
  );
};

export default MatchCenter;

MatchCenter.serverFetch = [getLocalizedGames, getMatchFeeds, setMatchCenterMultiLangUrl];

const Container = styled(ContainerLarge)`
  height: 100%;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%; // TODO:REMOVE
  min-height: 100vh;

  @media ${Devices.tabletLarge} {
    flex-direction: row;
  }
`;
