import { Image, Seo } from '@app/types/configurationTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { WebTemplateData } from '@app/types/webTemplateTypes';

export enum TicketingTypes {
  SingleTickets = 'web-tickets-tab-single',
  Packages = 'web-tickets-tab-package',
  Subscription = 'web-tickets-tab-subscription',
}

export interface MultiLangString {
  it: string;
  en: string;
}

export interface Competition {
  name: string;
  optaId: string;
  logo: Image;
}

export interface MatchTeam {
  id: string;
  name: string;
  shortName: string;
  goals: number;
  logoUrl: Image;
}

export interface BaseTicketInfo {
  ticketsActive: boolean;
  ticketsSoon: boolean;
  ticketsSoonUrl: string;
  ticketsUrl: MultiLangString;
  hospitalityActive: boolean;
  hospitalityUrl: MultiLangString;
  ticketsMessage: string;
  ticketsPopup: boolean;
  startingPrice?: string;
  ticketsPricing?: string;
  informationUrl?: MultiLangString;
  packageDescription: string;
  promoDescription: string;
  infoDescription: string;
}

export interface MatchTicketsInfo extends BaseTicketInfo{
  seasonId: string;
  gameOptaId: string;
  teams: {
    home: MatchTeam;
    away: MatchTeam;
  };
  competition: Competition;
  matchTime?: string;
  kenticoMatchTimeTBC: boolean;
  companion: {
    ad: Image;
    url: MultiLangString;
  };
  scriptDescription: string;
}

export interface PackageTicketsInfo extends BaseTicketInfo {
  packageId: string;
  name: string;
  games: MatchTicketsInfo[];
  imageMobile: Image;
  imageDesktop: Image;
}

export interface TicketingItem {
  [matchMonth: string]: MatchTicketsInfo[];
}

export type TicketingSingle = TicketingItem | null;

export interface TicketingSubscriptionItem {
  html: string;
}

export type TicketingSubscription = TicketingSubscriptionItem | null;

export interface TabItem extends Seo {
  id: string;
  name: string;
  url: string;
  isVisible: boolean;
  content: WebTemplateData[];
}

export interface TicketingConfigurationItem {
  carousel: string;
  tabs: TabItem[];
}

export type TicketingConfiguration = TicketingConfigurationItem | null;

export type LocalizedTicketingConfiguration = {
  [key in LanguageType]?: TicketingConfiguration;
}

export type LocalizedTicketingSubscription = {
  [key in LanguageType]?: TicketingSubscription;
}

export type LocalizedTabs = {
  [key in LanguageType]: TabItem[];
}

export type LocalizedCurrentTab = {
  [key in LanguageType]: TabItem | null;
}

export enum TicketType {
  Single,
  Package,
}
