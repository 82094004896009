import { createGlobalStyle } from 'styled-components';
import { Devices } from './devices';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  :root {
    --navigation-header-height: 70px;
    --top-header-height: 50px;
    --gallery-photo-max-width: 860px;

    @media (min-width: 1280px) {
      --navigation-header-height: 90px;
      --top-header-height: 70px;
    }

    @media (min-height: 1080px) {
      --gallery-photo-max-width: 1280px;
    }
  }

  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    background-color: ${(props): string => props.theme.color.whiteSmoke};
  }

  body {
    font-family: ${(props): string => props.theme.fontFamily.raleway};
    font-size: 14px;
  }

  a, button {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  ul, p, h1, h2, h3, h4, h5, h6  {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${(props): string => props.theme.fontFamily.milan};
    font-weight: bold;
  }

  h6 {
    font-size: 16px;
  }

  button:focus {
    outline: none;
  }

  select:focus {
    outline: none;
  }

  input {
    outline: none;
  }

  ul {
    list-style-type: none;
  }

  img {
    vertical-align: top;
  }

  .container-100hv {
    height: 100%;
  }

  .next-carousel-item {
    height: 100%;
  }
  .next-carousel-item:first-child {
    padding-right: 10px;
  }
  .next-carousel-dots {
    bottom: -10px!important;

    @media ${Devices.tabletLarge} {
      display: none!important;
    }
  }

  .overlapped-carousel-dots {
    bottom: 70px !important;
  }

  .carousel-dots {
    bottom: 30px !important;
  }


  /**
   * ----------------------------------------
   * animation fade-out
   * ----------------------------------------
   */
  .fade-out {
    -webkit-animation: fade-out 1s ease-out both;
    animation: fade-out 1s ease-out both;
  }
  @-webkit-keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    99% {
      width: 100%;
    }
    100% {
      opacity: 0;
      width: 0;
    }
  }

  /**
   * ----------------------------------------
   * animation slide-out-bck-center
   * ----------------------------------------
   */
  .slide-out-bck-center {
    -webkit-animation: slide-out-bck-center 0.15s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: slide-out-bck-center 0.15s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }
  @-webkit-keyframes slide-out-bck-center {
    0% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateZ(-1100px);
      transform: translateZ(-1100px);
      opacity: 0;
    }
  }
  @keyframes slide-out-bck-center {
    0% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateZ(-1100px);
      transform: translateZ(-1100px);
      opacity: 0;
    }
  }

  /**
   * ----------------------------------------
   * animation slide-in-fwd-center
   * ----------------------------------------
   */
  .slide-in-fwd-center {
    -webkit-animation: slide-in-fwd-center 0.15s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-fwd-center 0.15s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  @-webkit-keyframes slide-in-fwd-center {
    0% {
      -webkit-transform: translateZ(-1400px);
      transform: translateZ(-1400px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-fwd-center {
    0% {
      -webkit-transform: translateZ(-1400px);
      transform: translateZ(-1400px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
    }
  }

  /**
   * ----------------------------------------
   * animation swing-in-bottom-fwd
   * ----------------------------------------
   */
  .swing-in-bottom-fwd {
    -webkit-animation: swing-in-bottom-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
    animation: swing-in-bottom-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
  }
  @-webkit-keyframes swing-in-bottom-fwd {
    0% {
      -webkit-transform: rotateX(100deg);
      transform: rotateX(100deg);
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0);
      transform: rotateX(0);
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
      opacity: 1;
    }
  }
  @keyframes swing-in-bottom-fwd {
    0% {
      -webkit-transform: rotateX(100deg);
      transform: rotateX(100deg);
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0);
      transform: rotateX(0);
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
      opacity: 1;
    }
  }
  /**
   * ----------------------------------------
   * animation scale-in-ver-center
   * ----------------------------------------
   */
  .scale-in-ver-center {
    -webkit-animation: scale-in-ver-center 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-ver-center 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  @-webkit-keyframes scale-in-ver-center {
    0% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-ver-center {
    0% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      opacity: 1;
    }
  }
  /**
   * ----------------------------------------
   * animation scale-out-vertical
   * ----------------------------------------
   */
  .scale-out-vertical {
    -webkit-animation: scale-out-vertical 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: scale-out-vertical 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }
  @-webkit-keyframes scale-out-vertical {
    0% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      opacity: 1;
    }
  }
  @keyframes scale-out-vertical {
    0% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      opacity: 1;
    }
  }
  /**
   * ----------------------------------------
   * animation scale-in-hor-left
   * ----------------------------------------
   */
  .scale-in-hor-left {
    -webkit-animation: scale-in-hor-left 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-hor-left 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  @-webkit-keyframes scale-in-hor-left {
    0% {
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transform-origin: 0% 0%;
      transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
      transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  @keyframes scale-in-hor-left {
    0% {
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transform-origin: 0% 0%;
      transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
      transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  /**
   * ----------------------------------------
   * animation scale-in-ver-top
   * ----------------------------------------
   */
  .scale-in-ver-top {
    -webkit-animation: scale-in-ver-top 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-ver-top 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  @-webkit-keyframes scale-in-ver-top {
    0% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }
  @keyframes scale-in-ver-top {
    0% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }

  /**
   * ----------------------------------------
   * animation scale-out-ver-top
   * ----------------------------------------
   */
  .scale-out-ver-top {
    -webkit-animation: scale-out-ver-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: scale-out-ver-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }
  @-webkit-keyframes scale-out-ver-top {
    0% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }
  @keyframes scale-out-ver-top {
    0% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }

  /**
   * ----------------------------------------
   * animation scale-in-ver-bottom
   * ----------------------------------------
   */
  .scale-in-ver-bottom {
    -webkit-animation: scale-in-ver-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-ver-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  @-webkit-keyframes scale-in-ver-top {
    0% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }
  @keyframes scale-in-ver-top {
    0% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }

  /**
   * ----------------------------------------
   * animation scale-out-ver-bottom
   * ----------------------------------------
   */
  .scale-out-ver-bottom {
    -webkit-animation: scale-out-ver-bottom 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: scale-out-ver-bottom 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }
  @-webkit-keyframes scale-out-ver-bottom {
    0% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 0% 100%;
      transform-origin: 0% 100%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: 0% 100%;
      transform-origin: 0% 100%;
      opacity: 1;
    }
  }
  @keyframes scale-out-ver-bottom {
    0% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 0% 100%;
      transform-origin: 0% 100%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: 0% 100%;
      transform-origin: 0% 100%;
      opacity: 1;
    }
  }

  /**
   * ----------------------------------------
   * animation fade-out-bck
   * ----------------------------------------
   */
  .fade-out-bck {
    -webkit-animation: fade-out-bck 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: fade-out-bck 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  @-webkit-keyframes fade-out-bck {
    0% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateZ(-80px);
      transform: translateZ(-80px);
      opacity: 0;
    }
  }
  @keyframes fade-out-bck {
    0% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateZ(-80px);
      transform: translateZ(-80px);
      opacity: 0;
    }
  }
  /**
   * ----------------------------------------
   * animation fade-in-fwd
   * ----------------------------------------
   */
  .fade-in-fwd {
    -webkit-animation: fade-in-fwd 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-fwd 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  @-webkit-keyframes fade-in-fwd {
    0% {
      -webkit-transform: translateZ(-80px);
      transform: translateZ(-80px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-fwd {
    0% {
      -webkit-transform: translateZ(-80px);
      transform: translateZ(-80px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
    }
  }

  /**
   * ----------------------------------------
   * animation tracking-in-expand
   * ----------------------------------------
   */
  .tracking-in-expand {
    -webkit-animation: tracking-in-expand 0.3s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    animation: tracking-in-expand 0.3s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  }
  @-webkit-keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
`;
