import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getHallOfFameInductees } from '@app/services/kentico/hallOfFameService';
import { WebHallOfFameInductees, WebHallOfFameInductee } from '@app/types/webTemplateTypes';
import { NotFound } from '@app/pages';
import { Loader, SocialSharing } from '@app/components';
import { useLanguage } from '@app/components/Hooks';
import {
  HOFPage, HOFTitle, HOFLogo,
  HOFInducteeHero, HOFInducteeMetadata, HOFInducteeStats,
  HOFInducteeBiography, HOFInducteeCareer, HOFSocialSharing,
  HOFInducteesHeader, HOFInducteesFilters, HOFInducteesList,
} from './hof.atoms';

export const HallOfFameInductees = ({ title, logo, filters }: WebHallOfFameInductees): React.ReactElement => {
  const [inductees, setInductees] = useState<WebHallOfFameInductee[] | undefined | null>(undefined);
  const { categoryName } = useParams();
  const language = useLanguage();

  useEffect(() => {
    getHallOfFameInductees(language)
      .then((items) => setInductees(items))
      .catch(() => setInductees(null));
  }, [language]);

  switch (true) {
    case inductees === undefined: return <Loader />;

    // INDUCTEE PROFILE
    case !!categoryName:
      // eslint-disable-next-line no-case-declarations
      const inductee = inductees?.find(({ slug }) => slug === categoryName) as WebHallOfFameInductee;
      switch (true) {
        case inductee === undefined:
          return <NotFound />;
        default:
          return (
            <>
              <HOFInducteeMetadata name={inductee.name.full} imageUrl={inductee.image.hero.url} />
              <HOFInducteeHero {...inductee} />
              <HOFPage>
                <HOFInducteeStats stats={inductee.stats} />
                <HOFInducteeBiography biography={inductee.biography} />
                <HOFInducteeCareer title={inductee.career.title} table={inductee.career.table} />
                <HOFSocialSharing>
                  <SocialSharing url={window.location.href} />
                </HOFSocialSharing>
              </HOFPage>
            </>
          );
      }

    // INDUCTEES LIST
    default:
      return (
        <HOFPage>
          <HOFLogo {...logo} small />
          <HOFInducteesHeader>
            <HOFTitle>{title}</HOFTitle>
            <HOFInducteesFilters filters={filters} inductees={inductees} />
          </HOFInducteesHeader>
          <HOFInducteesList inductees={inductees} />
        </HOFPage>
      );
  }
};
