import React from 'react';
import styled from 'styled-components';

export const HOFSectionHighlights = ({ children }): React.ReactElement => (
  <Section>
    {children}
  </Section>
);

const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;

  width: 100%;
  height: auto;
`;
