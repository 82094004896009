import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getResponsiveNewsImageConfig } from '@app/helpers/imageHelper';
import { LatestNewsConfiguration } from '@app/constants/carouselConfigurationsConstants';

import { ArticleItem } from '@app/components';
import { useLatestArticlesWithGoogleAds } from '@app/components/Hooks';

import { CarouselBase } from './CarouselBase';
import { CarouselTitle } from './CarouselTitle';

export const CarouselLatestArticles = (): React.ReactElement => {
  const latestArticles = useLatestArticlesWithGoogleAds();

  return (
    <>
      <CarouselTitle>
        <FormattedMessage id="news.latest" />
      </CarouselTitle>
      <CarouselBase
        ItemComponent={ArticleItem}
        items={latestArticles || []}
        configuration={LatestNewsConfiguration}
        getResponsiveImageConfig={getResponsiveNewsImageConfig}
      />
    </>
  );
};
