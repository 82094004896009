import React from 'react';
import styled from 'styled-components';

const Stadium = ({ opta, kentico }): React.ReactElement | null => {
  switch (true) {
    /** Kentico - Hide Stadium label ON */
    case kentico === null:
      return <Name>&nbsp;</Name>;
    /** Kentico - Hide Stadium label OFF */
    // Kentico - Stadium Custom Name ON
    case kentico?.length > 0: // Kentico - Hide Stadium label ON
      return <Name>{kentico}</Name>;
    // Kentico - Stadium Custom Name OFF
    default:
      return <Name>{opta}</Name>;
  }
};

export default Stadium;

const Name = styled.span`
  font-family: Raleway;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: 0.26px;
  color: #000;
  padding: 0;
`;
