import styled from 'styled-components';
import { Devices } from '@app/styles';

export const ContainerFullWidth = styled.div`
  width: 100%;
  position: relative;
`;

export const ContainerLarge = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: auto;
  position: relative;
`;

export const ContainerMedium = styled(ContainerLarge)`
  max-width: 960px;
  padding: 0 20px;

  @media ${Devices.desktop} {
    padding: 0;
  }
`;

export const WebEditorialContainer = styled(ContainerLarge)`
  max-width: 960px;
  padding: 0;
`;

export const ContainerCta = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 20px 20px;

  & > * {
    width: auto;
    min-width: 150px;
  }
`;
