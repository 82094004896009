export enum SearchActionTypes {
  SET_SEARCH_BAR_VISIBILITY = 'SEARCH/SET_SEARCH_BAR_VISIBILITY',
  SET_SEARCH_IN_PROGRESS = 'SEARCH/SET_SEARCH_IN_PROGRESS',
  SET_SEARCH_RESULTS = 'SEARCH/SET_SEARCH_RESULTS',
  RESET_SEARCH = 'SEARCH/RESET_SEARCH',
}

export interface SetSearchBarVisibility {
  type: SearchActionTypes.SET_SEARCH_BAR_VISIBILITY;
  payload: boolean;
}

export interface SetSearchInProgress {
  type: SearchActionTypes.SET_SEARCH_IN_PROGRESS;
  payload: boolean;
}

export interface SetSearchResults {
  type: SearchActionTypes.SET_SEARCH_RESULTS;
  payload: [];
}

export interface ResetSearch {
  type: SearchActionTypes.RESET_SEARCH;
}

export type SearchActions = SetSearchBarVisibility
  | SetSearchInProgress
  | SetSearchResults
  | ResetSearch;
