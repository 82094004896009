import React from 'react';
import { Redirect } from 'react-router-dom';

import { PageError } from '@app/types/errorTypes';
import { useVanityUrl } from '@app/pages/VanityUrls/VanityUrlsHooks';
import { Loader } from '@app/components';
import { NotFound } from '@app/pages';

const VanityUrls = (): React.ReactElement => {
  const url = useVanityUrl();

  if (!url) return (<Loader />);
  return url === PageError.NotFound ? (<NotFound />) : (<Redirect to={url} />);
};

export default VanityUrls;
