import React from 'react';
import styled from 'styled-components';

import { useHomeSliderDataDownload, useHomeSliderDataHook } from '@app/pages/Home/HomeHooks';
import { CarouselHomeSlider } from '@app/components/Carousel';
import { WebSliderLayoutSize } from '@app/types/webTemplateTypes';
import { HomeBlocks } from '@app/types/homeConfigurationTypes';
import PageTitle from '@app/components/PageTitle';
import { Devices } from '@app/styles';
import { GraySection, GraySectionReducePadding, GraySectionWideReducePadding } from './HomeStyledComponents';

type HomeSliderProps = React.PropsWithChildren<{
  id: HomeBlocks;
}>

const HomeSlider: React.FC = (props: HomeSliderProps) => {
  useHomeSliderDataDownload(props.id);
  const homeSliderData = useHomeSliderDataHook(props.id);

  return (
    <>
      { homeSliderData?.layoutSize === WebSliderLayoutSize.Big
        ? (
          <GraySectionWideReducePadding>
            {homeSliderData?.title && (
              <GraySectionWithoutPadding>
                <PageTitleWithoutPadding>{homeSliderData?.title}</PageTitleWithoutPadding>
              </GraySectionWithoutPadding>
            )}
            <CarouselHomeSlider slides={homeSliderData?.content || []} />
          </GraySectionWideReducePadding>
        ) : (
          <GraySectionReducePadding>
            {homeSliderData?.title && (<PageTitleWithoutPadding>{homeSliderData?.title}</PageTitleWithoutPadding>)}
            <CarouselHomeSlider slides={homeSliderData?.content || []} />
          </GraySectionReducePadding>
      )}
    </>
  );
};

const PageTitleWithoutPadding = styled(PageTitle)`
  padding-top: 0;
`;

const GraySectionWithoutPadding = styled(GraySection)`
  padding-bottom: 0;
  padding-top: 0;

  @media ${Devices.mediumMobile} {
    padding-top: 0;
    padding-bottom: 0;
  }

  @media ${Devices.tablet} {
    padding-top: 0;
    padding-bottom: 0;
  }

  @media ${Devices.desktopSmall} {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export default HomeSlider;
