import React from 'react';
import styled from 'styled-components';

const CirclePlusIcon = (): React.ReactElement => (
  <CircleSvg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <circle cx="10" cy="10" r="10" fill="#C9240D" />
      <path fill="#FFF" d="M10 6c.552 0 1 .448 1 1v2h2c.552 0 1 .448 1 1s-.448 1-1 1h-2v2c0 .552-.448 1-1 1s-1-.448-1-1v-2H7c-.552 0-1-.448-1-1s.448-1 1-1l2-.001V7c0-.552.448-1 1-1z" />
    </g>
  </CircleSvg>
);

export const CircleSvg = styled.svg`
  cursor: pointer;
`;

export default CirclePlusIcon;
