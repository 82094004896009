import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { findCategoryBySlug } from '@app/helpers/configurationHelpers';

import { getPlayers, setTeamsPageMultiLangRoute } from '@app/store/actions/teamsActions';

import { PageError } from '@app/types/errorTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { TeamSubMenuId } from '@app/types/configurationTypes';
import { PlayerPositionFilter } from '@app/types/teamsTypes';

import {
  getFilter,
  getPathKey,
  usePlayersDownload,
  useSecondLevelMenuItems,
  useLocalizedSubMenuItem,
  useTeamsMultiLangUrl,
  useTeamsWebTemplateData,
  useMenFirstTeamCheck,
} from '@app/pages/Teams/TeamsHooks';
import { useMenu, useBreadcrumbsJsonLD } from '@app/components/Hooks';

import {
  CategoryMenu, Metadata, MetaJsonLD, SecondLevelMenu, WebTemplateContainer,
  PageTitle, GoogleAds, RosterArchiveDropdown,
} from '@app/components';
import { PageValidationWrapper } from '@app/pages/components';
import TeamPlayersList from './TeamPlayersList';


const Teams = (): React.ReactElement => {
  const { locale } = useIntl();
  const language = locale as LanguageType;
  const { categoryName = '' } = useParams();
  const localizedSubItem = useLocalizedSubMenuItem();
  const menu = useMenu();
  const subItem = localizedSubItem?.[locale];
  const teamSubMenuId = subItem?.id as TeamSubMenuId;
  const secondLevelMenuItems = useSecondLevelMenuItems();
  const categoryMenu = subItem?.categories || [];
  usePlayersDownload();
  useTeamsMultiLangUrl(localizedSubItem);

  const filter = getFilter(categoryMenu, language, categoryName);
  const teamName = subItem?.name;
  const category = findCategoryBySlug(categoryMenu, categoryName);
  const breadcrumbsJsonLD = useBreadcrumbsJsonLD(category?.seo?.title ?? '');
  const webTemplateData = useTeamsWebTemplateData();

  const showRosterArchiveDropdown = useMenFirstTeamCheck(teamSubMenuId);

  return (
    <PageValidationWrapper error={menu && (!localizedSubItem || !filter) ? PageError.NotFound : null}>
      { !!category?.seo && <Metadata seo={category.seo} /> }
      { !!category?.seo && <MetaJsonLD json={breadcrumbsJsonLD} /> }
      <SecondLevelMenu items={secondLevelMenuItems} />
      <Container>
        <InnerContainer>
          <PageTitle>{teamName}</PageTitle>
          <FiltersContainer showRosterArchiveDropdown={showRosterArchiveDropdown}>
            <CategoryMenu items={categoryMenu} pathKey={getPathKey(teamSubMenuId)} color="black" />
            {showRosterArchiveDropdown ? <RosterArchiveDropdown /> : <div />}
          </FiltersContainer>
          {Object.values(PlayerPositionFilter).map((position) => (
            <>
              <TeamPlayersList position={position} />
              {[
                PlayerPositionFilter.Goalkeepers,
                PlayerPositionFilter.GoalkeepersWomen,
                PlayerPositionFilter.GoalkeepersPrimavera,
                PlayerPositionFilter.GoalkeepersMilanFuturo,
              ].includes(position) && category?.banner?.ads && (
                <GoogleAdsBlock>
                  <GoogleAds {...category.banner.ads} animated />
                </GoogleAdsBlock>
              )}
            </>
          ))}
        </InnerContainer>
      </Container>
      <WebTemplateContainer data={webTemplateData} />
    </PageValidationWrapper>
  );
};

Teams.serverFetch = [getPlayers, setTeamsPageMultiLangRoute];

const Container = styled.div`
  background-color: ${(props): string => props.theme.color.whiteSmoke};
  height: 100%;
  padding: 0 20px;
`;

const InnerContainer = styled.div`
  max-width: 960px;
  margin: auto;
`;

const FiltersContainer = styled.div<{ showRosterArchiveDropdown: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > div:first-child {
    width: ${({ showRosterArchiveDropdown }): string => (showRosterArchiveDropdown ? 'calc(100% - 150px)' : '100%')};
  }

  & > div:last-child,
  & > div:last-child > select {
    width: auto!important;
  }

  & > div:last-child {
    margin-left: 25px !important;
  }
`;

const GoogleAdsBlock = styled.div`
  margin-bottom: 40px;
`;

export default Teams;
