import { waitForConfigurationDownload } from '@app/store/actions/configurationActions';
import { CurrentRouteParams, setCurrentRoute } from '@app/store/actions/currentRouteActions';
import * as TicketingService from '@app/services/kentico/ticketingService';
import * as CarouselService from '@app/services/kentico/carouselService';

import { Action, ActionWithPromiseReturn } from '@app/types/actionTypes';
import {
  LocalizedTicketingConfiguration,
  LocalizedTicketingSubscription,
  PackageTicketsInfo,
  TicketingSingle,
} from '@app/types/ticketingTypes';
import {
  ResetTicketingSubscription,
  ResetTicketingSingle,
  ResetTicketingPackages,
  ResetTicketingConfiguration,
  SetTicketingSubscription,
  SetTicketingSingle,
  SetTicketingPackages,
  SetTicketingConfiguration,
  TicketingActionTypes,
  SetTicketingCarousel,
  ResetTicketingCarousel,
} from '@app/store/actionTypes/ticketingActionTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { TopNews } from '@app/types/newsTypes';
import { getWebTemplateDataById } from '@app/store/actions/webTemplateActions';
import { SubMenuItem } from '@app/types/configurationTypes';
import { AppState } from '@app/store/reducers';

import { AppLanguages } from '@app/constants/localizationConstants';
import AppRoutes from '@app/constants/routes';
import { getLocalizedCurrentTab } from '@app/helpers/ticketingHelpers';

const setTicketingConfiguration = (configuration: LocalizedTicketingConfiguration): SetTicketingConfiguration => ({
  type: TicketingActionTypes.SET_TICKETING_CONFIGURATION,
  payload: configuration,
});

export const resetTicketingConfiguration = (): ResetTicketingConfiguration => ({
  type: TicketingActionTypes.RESET_TICKETING_CONFIGURATION,
});

const setTicketingCarousel = (carousel: TopNews[]): SetTicketingCarousel => ({
  type: TicketingActionTypes.SET_TICKETING_CAROUSEL,
  payload: carousel,
});

export const resetTicketingCarousel = (): ResetTicketingCarousel => ({
  type: TicketingActionTypes.RESET_TICKETING_CAROUSEL,
});

const setTicketingSingle = (single: TicketingSingle): SetTicketingSingle => ({
  type: TicketingActionTypes.SET_TICKETING_SINGLE,
  payload: single,
});

export const resetTicketingSingle = (): ResetTicketingSingle => ({
  type: TicketingActionTypes.RESET_TICKETING_SINGLE,
});

const setTicketingPackages = (packages: PackageTicketsInfo[]): SetTicketingPackages => ({
  type: TicketingActionTypes.SET_TICKETING_PACKAGES,
  payload: packages,
});

export const resetTicketingPackages = (): ResetTicketingPackages => ({
  type: TicketingActionTypes.RESET_TICKETING_PACKAGES,
});

const setTicketingSubscription = (subscription: LocalizedTicketingSubscription): SetTicketingSubscription => ({
  type: TicketingActionTypes.SET_TICKETING_SUBSCRIPTION,
  payload: subscription,
});

export const resetTicketingSubscription = (): ResetTicketingSubscription => ({
  type: TicketingActionTypes.RESET_TICKETING_SUBSCRIPTION,
});

interface GetTicketingTypeSubItemType {
  state: AppState;
  language: LanguageType;
}

const getTicketingTypeSubItem = (
  { state, language }: GetTicketingTypeSubItemType,
): SubMenuItem => {
  const navigation = state.configuration[language]?.menu?.web_ticket_office?.navigation;
  let subItem;
  if (navigation) {
    [subItem] = Object.values(navigation);
  }
  return subItem;
};

interface GetTicketingConfigurationType {
  language: LanguageType;
}

export const getTicketingConfiguration = ({ language }: GetTicketingConfigurationType): ActionWithPromiseReturn => (
  async (dispatch, getState): Promise<void> => {
    await waitForConfigurationDownload();

    try {
      const configurationEn = await TicketingService.getConfigurationTicketing('en');
      const configurationIt = await TicketingService.getConfigurationTicketing('it');
      dispatch(setTicketingConfiguration({
        en: configurationEn,
        it: configurationIt,
      }));

      const subItem = getTicketingTypeSubItem({ state: getState(), language });
      if (subItem) {
        dispatch(getWebTemplateDataById({
          id: subItem.id,
          language,
        }));
      }
    } catch (e) {
      console.error('Error on fetching players', e);
      // Todo clarify with PO error scenario
    }
  }
);

export const getTicketingCarousel = (carousel: string, language: LanguageType): Action => (
  async (dispatch): Promise<void> => {
    try {
      const carouselData = await CarouselService.getCarouselData({ carousel, language });
      dispatch(setTicketingCarousel(carouselData));
    } catch (e) {
      console.error('Error on fetching ticketing carousel data ', e);
      // Todo clarify with PO error scenario
    }
  }
);

export const getTicketingSingle = (language: LanguageType): Action => (
  async (dispatch): Promise<void> => {
    await waitForConfigurationDownload();

    try {
      const ticketing: TicketingSingle = await TicketingService.getTicketingGamesList(language);
      dispatch(setTicketingSingle(ticketing));
    } catch (e) {
      console.error('Error on fetching players', e);
      // Todo clarify with PO error scenario
    }
  }
);

export const getTicketingPackages = (language: LanguageType): Action => (
  async (dispatch): Promise<void> => {
    await waitForConfigurationDownload();

    try {
      const packages = await TicketingService.getTicketingPackages(language);
      dispatch(setTicketingPackages(packages));
    } catch (e) {
      console.error('Error on fetching players', e);
      // Todo clarify with PO error scenario
    }
  }
);

export const getTicketingSubscription = (): Action => (
  async (dispatch): Promise<void> => {
    await waitForConfigurationDownload();

    try {
      const subscriptionEn = await TicketingService.getTicketingSubscription('en');
      const subscriptionIt = await TicketingService.getTicketingSubscription('it');
      dispatch(setTicketingSubscription({
        en: subscriptionEn,
        it: subscriptionIt,
      }));
    } catch (e) {
      console.error('Error on fetching players', e);
      // Todo clarify with PO error scenario
    }
  }
);

type SetTicketPageMultiLangRoute = (params: {
  categoryName: string;
  language: LanguageType;
}) => Action

export const setTicketPageMultiLangRoute: SetTicketPageMultiLangRoute = ({ categoryName, language }) => (
  async (dispatch, getState): Promise<void> => {
    await waitForConfigurationDownload();

    const state = getState();
    const currentTab = getLocalizedCurrentTab(state, categoryName, language);

    const params = AppLanguages.reduce((acc, language) => {
      acc.categoryName[language] = currentTab[language]?.url ?? '';
      return acc;
    }, { categoryName: {} } as unknown as CurrentRouteParams);

    dispatch(setCurrentRoute({
      pathId: AppRoutes.Ticketing.path,
      params,
    }));
  }
);

export const getTicketDataForSsr = (params): Action => (
  (dispatch): Promise<void[]> => (
    Promise.all([
      dispatch(getTicketingSingle(params.language)),
      dispatch(getTicketingSubscription()),
      dispatch(getTicketingConfiguration(params))
        .then(() => dispatch(setTicketPageMultiLangRoute(params))),
    ])
  )
);
