// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import algoliasearch from 'algoliasearch/lite';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { PageError } from '@app/types/errorTypes';
import { BaseNewsItem } from '@app/types/newsTypes';
import { PageWrapper } from '@app/pages/components';
import { Loader } from '@app/components';
import {
  useSearchMultiLangUrl,
  useSearchConfig,
  useSearchParams,
  useSearchFilters,
  useSearchIndex,
} from '@app/components/Header/components/SearchBar/SearchBarHooks';

import { ThreeColumnGrid } from '@app/components/News/NewsList/components/NewsListGrid';
import NewsListItem from '@app/components/News/NewsList/components/NewsListItem';

import {
  Categories, TotalResults, LoadMoreButton,
} from './components';

const getNewsItemFromHitItem = (hit): BaseNewsItem => ({
  urlSlug: hit?.urlSlug ?? '',
  publicationDate: hit?.publicationDate?.slice(0, 10) ?? '',
  title: hit?.title ?? '',
  codeName: hit?.kenticoCodename ?? '',
  id: hit?.objectID ?? '',
  categoryName: hit?.categories?.[0] ?? '',
  categoryCode: hit?.categories?.[0] ?? '',
  type: hit?.kenticoType ?? '',
  image: hit?.imageHorizontal?.[0] ?? hit?.imageVertical?.[0] ?? hit?.image?.[0] ?? '',
  isPremium: hit?.webPremium?.[0] === 'premium',
});

const Search = (): React.ReactElement => {
  const { query, category } = useSearchParams();
  const { appId, apiKey } = useSearchConfig();
  const filters = useSearchFilters();
  const index = useSearchIndex();

  const searchClient = algoliasearch(appId, apiKey);
  const searchIndex = searchClient.initIndex(index);

  const [hitsList, setHitsList] = useState(null);
  const [pageError, setPageError] = useState(null);
  const [results, setResults] = useState(null);

  const isLoading = Boolean(!results);
  const hasResults = hitsList?.length > 0 && !isLoading;
  const hasNoResults = hitsList?.length === 0 && !isLoading;

  const onLoadMoreButtonClick = (): void => {
    searchIndex
      .search(query, { /* tslint:disable */
        page: results?.page + 1, /* tslint:disable */
        hitsPerPage: 12,
        filters,
      })
      .then((response) => {
        setResults(response);
        setHitsList(hitsList?.concat(response?.hits));
      })
      .catch(() => setPageError(PageError.Sorry));
  };

  const onSearchQueryChange = (): void => {
    setResults(null);
    setHitsList(null);

    searchIndex
      .search(query, {
        page: 0,
        hitsPerPage: 12,
        filters,
      })
      .then((response) => {
        setResults(response);
        setHitsList(response?.hits);
      })
      .catch(() => setPageError(PageError.Sorry));
  };

  useSearchMultiLangUrl();

  useEffect(() => {
    onSearchQueryChange();
  }, [query, category]);

  return (
    <PageWrapper error={pageError}>
      <Container isLoading={isLoading}>
        <Content>
          <Heading>
            <TotalResults total={results?.nbHits} />
            <Categories />
          </Heading>
          {isLoading && (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          )}
          {hasResults && (
            <>
              <ThreeColumnGrid>
                {hitsList.map((hit) => (
                  <NewsListItem item={getNewsItemFromHitItem(hit)} size={12} type={hit.kenticoType} />
                ))}
              </ThreeColumnGrid>
              <LoadMoreButton
                onClick={onLoadMoreButtonClick}
                isVisible={results?.page + 1 < results?.nbPages}
                isLoading={false}
              />
            </>
          )}
          {hasNoResults && (
            <NoResults>
              <FormattedMessage id="news.search.results.message" />
            </NoResults>
          )}
        </Content>
      </Container>
    </PageWrapper>
  );
};

export default Search;

const Container = styled.div<{ isLoading: boolean }>`
  opacity: ${({ isLoading }): number => (isLoading ? 0.33 : 1)};
  cursor: ${({ isLoading }): string => (isLoading ? 'wait' : 'unset')};
  width: 100%;
  height: 100%;
  max-width: 1280px;
  padding: 100px 20px 0;
  margin: auto;

  @media ${Devices.desktopSmall} {
    padding: 100px 70px 0 200px;
  }
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 300px;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 9999;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
`;

const NoResults = styled.p`
  font-family: MilanTypeRev;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  line-height: 1.47;
  letter-spacing: 0.17px;
  padding: 100px 0;
  color: #232323;;
`;
