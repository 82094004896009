import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Swipeable } from 'react-swipeable';
import styled from 'styled-components';
import get from 'lodash/get';
import { useIntl, FormattedMessage } from 'react-intl';
import { useEvent } from 'react-use';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { Devices } from '@app/styles';
import { Game } from '@app/types/matchTypes';
import { Gallery } from '@app/types/newsTypes';
import { AppState } from '@app/store/reducers';
import { ArrowButtonLeft, ArrowButtonRight } from '@app/components/Buttons';

interface GalleryCarouselProps {
  preview: Gallery | Game;
}

interface GalleryCarouselSelectorProps {
  gallery: Gallery | null;
}

const GalleryCarousel = ({ preview }: GalleryCarouselProps): React.ReactElement => {
  const { locale } = useIntl();

  const { gallery } = useSelector<AppState, GalleryCarouselSelectorProps>(
    (state) => ({
      gallery: state.news.pictures.selectedItemDetails?.[locale],
    }),
    shallowEqual,
  );

  const slides = preview
    ? get(preview, 'photos', [])
    : get(gallery, 'photos', []);

  // Gallery controls and handlers
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  const selectPreviousImage = (): void => setSelectedItemIndex(selectedItemIndex - 1);
  const selectNextImage = (): void => setSelectedItemIndex(selectedItemIndex + 1);

  const canSelectPreviousItem = !!selectedItemIndex;
  const canSelectNextItem = selectedItemIndex + 1 < slides.length;

  const handlers = {
    onSwipedLeft: (): false | void => canSelectNextItem && selectNextImage(),
    onSwipedRight: (): false | void => canSelectPreviousItem && selectPreviousImage(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  };

  const handleKeyDown = ({ keyCode }): void => {
    switch (keyCode) {
      case 37:
        canSelectPreviousItem && selectPreviousImage();
        break;
      case 39:
        canSelectNextItem && selectNextImage();
        break;
      default:
        break;
    }
  };
  useEvent('keydown', handleKeyDown);

  return (
    <GlobalWrapper>
      <CarouselSection>
        <Swipeable {...handlers}>
          <Carousel selectedItemIndex={selectedItemIndex}>
            {
              slides.map(({ url, description, name }) => (
                <Slide key={name}>
                  <LazyLoadImage
                    src={`${url}&auto=format`}
                    placeholderSrc={`${url}?w=20&auto=format`}
                    srcSet={`
                      ${url}?w=375&auto=format 375w,
                      ${url}?w=605&auto=format 605w,
                      ${url}?w=860&auto=format 860w,
                      ${url}?w=1280&auto=format 1280w
                    `}
                    sizes="
                      (min-height: 1080px) and (min-width: 1025px) 1280px,
                      (min-width: 602px) 860px,
                      (min-width: 376px) 605px,
                      375px
                    "
                    effect="blur"
                    alt={description ?? name}
                    width="100%"
                    draggable={false}
                  />
                </Slide>
              ))
            }
          </Carousel>
        </Swipeable>
        <SlidesCount>
          <FormattedMessage id="gallery.photo" />
          { ` ${selectedItemIndex + 1} / ${slides.length}`}
        </SlidesCount>
        <ControlLeft isVisible={canSelectPreviousItem}>
          <ArrowButtonLeft onClick={selectPreviousImage} />
        </ControlLeft>
        <ControlRight isVisible={canSelectNextItem}>
          <ArrowButtonRight onClick={selectNextImage} />
        </ControlRight>
      </CarouselSection>
    </GlobalWrapper>

  );
};

export default GalleryCarousel;

const GlobalWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ControlLeft = styled.div<{ isVisible: boolean }>`
  position: absolute;
  display: none;
  top: calc(50% - 40px);
  left: 0;
  z-index: 1;

  @media (pointer: fine), ${Devices.tablet} {
    display: ${({ isVisible }): string => (isVisible ? 'block' : 'none')};
  }
`;

const ControlRight = styled(ControlLeft)`
  left: unset;
  right: 0;
`;

const CarouselSection = styled.section`
  position: relative;
  overflow: hidden;
  max-width: var(--gallery-photo-max-width);
  flex: 0 0 100%;
`;

const Carousel = styled.ul<{ selectedItemIndex: number }>`
  transform: ${({ selectedItemIndex }): string => `translateX(-${selectedItemIndex * 100}%)`};
  transition: transform 250ms ease-out 0s;
  white-space: nowrap;
  width: 100%;
`;

const Slide = styled.li`
  display: inline-block;
  width: 100%;
`;

const SlidesCount = styled.div`
  position: absolute;
  height: 25px;
  opacity: 0.8;
  background-color: black;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #ffffff;
  bottom: 0;
  width: auto;
  padding: 5px 20px;
`;
