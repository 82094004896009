import React from 'react';

import { WebPageVideoSizeType, WebPageVideo } from '@app/types/webTemplateTypes';
import { DailyMotionPlayer } from '@app/components';
import { ContainerLarge, ContainerMedium } from '@app/components/WebTemplates/components';

interface WebPageVideoElementProps {
  data: WebPageVideo;
}

const WebPageVideoElement = ({ data }: WebPageVideoElementProps): React.ReactElement => (
  data.size === WebPageVideoSizeType.Full ? (
    <ContainerLarge>
      <DailyMotionPlayer videoId={data.videoId} isEmbed />
    </ContainerLarge>
  ) : (
    <ContainerMedium>
      <DailyMotionPlayer videoId={data.videoId} isEmbed />
    </ContainerMedium>
  )
);

export default WebPageVideoElement;
