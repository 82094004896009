import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { GoogleMaps as GoogleMapsType } from '@app/types/googleTypes';
import { useGoogleMaps } from './GoogleMapsHooks';

const GoogleMaps = ({ map }: { map: GoogleMapsType }): React.ReactElement => {
  useGoogleMaps(map);

  return (
    <Container>
      <Map id={`map.${map.position.lat}.${map.position.lat}`} />
    </Container>
  );
};

export default GoogleMaps;

const Container = styled.section`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 20px;
  height: 0;
  z-index: 0;
  overflow: hidden;

  @media ${Devices.desktop} {
    padding-bottom: 590px;
    padding-top: 0;
  }
`;

const Map = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  max-height: 590px;
  z-index: 1;
`;
