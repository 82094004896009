import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import * as Translations from '@app/locales/index';
import { useLanguage } from '@app/components/Hooks';
import { SecondLevelMenu } from '@app/components';

import { useMatchCenterTabs } from './MatchCenterTabsHooks';
import MatchLineups from '../MatchLineups/MatchLineups';
import MatchStatistics from '../MatchStatistics/MatchStatistics';
import MatchFixtures from '../MatchFixtures';
import MatchStandings from '../MatchStandings';

const MatchCenterTabs = ({ isGalleryAvailable = false }): React.ReactElement | null => {
  const { tab = '' } = useParams();
  const tabs = useMatchCenterTabs();
  const language = useLanguage();

  const renderActiveTab = (): React.ReactElement | null => {
    switch (tab) {
      case Translations[language]?.['matchcenter.tabs.lineups'].toLowerCase():
        return (<MatchLineups />);
      case Translations[language]?.['matchcenter.tabs.statistics'].toLowerCase():
        return (<MatchStatistics />);
      case Translations[language]?.['matchcenter.tabs.results'].toLowerCase():
        return (<MatchFixtures />);
      case Translations[language]?.['matchcenter.tabs.standings'].toLowerCase():
        return (<MatchStandings />);
      default:
        return null;
    }
  };

  return (
    <Container>
      <Tabs isGalleryAvailable={isGalleryAvailable}>
        <SecondLevelMenu items={tabs} usePadding={false} backgroundColor="black" isTabs />
      </Tabs>
      {renderActiveTab()}
    </Container>
  );
};

export default MatchCenterTabs;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  margin: 10px;
  justify-content: flex-start;

  @media ${Devices.tabletLarge} {
    margin: 0;
    flex: 0 0 360px;
  }

  @media ${Devices.desktop} {
    flex: 0 0 420px;
  }
`;

const Tabs = styled.div<{ isGalleryAvailable: boolean }>`
  position: relative;

  @media ${Devices.tabletLarge} {
    position: ${({ isGalleryAvailable }): string => (isGalleryAvailable ? 'absolute' : 'relative')};
    top: ${({ isGalleryAvailable }): string => (isGalleryAvailable ? '-35px' : '0')};
    right: 0;
    left: 0;
    z-index: 101;
  }
`;
