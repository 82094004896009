import React from 'react';

const ArrowRightIcon = (): React.ReactElement => (
  <svg width="10px" height="18px" viewBox="0 0 10 18" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1258.000000, -391.000000)">
        <g id="icon" transform="translate(1240.000000, 380.000000)">
          <polygon
            transform="translate(22.500000, 20.000000) scale(1, -1) rotate(90.000000) translate(-22.500000, -20.000000) "
            points="22.5 16 31 24 14 24"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ArrowRightIcon;
