import { DefaultTheme, StyledComponent } from 'styled-components';

export interface GAEvent {
  event: string;
  language?: string;
  category?: string;
  action?: string;
  label?: string;
  match?: string;
  page_name?: string;
  page_category?: string;
}

export enum GA_EVENT {
  PAGE_LOAD = 'page_load',
  CLICK_HOME = 'click_home',
  CLICK_MENU = 'click_menu',
  CLICK_FOOTER = 'click_footer',
  CLICK_NEWS = 'click_news',
  CLICK_VIDEO = 'click_video',
  CLICK_PHOTO = 'click_photo',
  CLICK_PLAYER = 'click_player',
  CLICK_BUY_JERSEY = 'buy_jersey',
  CLICK_SCHEDULE = 'click_schedule',
  CLICK_SCHEDULE_RESULT = 'click_schedule_result',
  CLICK_STANDINGS = 'click_standings',
  CLICK_ADD_CALENDAR = 'add_calendar',
  CLICK_MATCH_CENTER = 'click_matchcenter',
  CLICK_PARTNERS = 'click_partners',
  CLICK_YOUTH = 'click_youth',
  CLICK_RESUME_DOWNLOAD = 'resume_download',
  CLICK_HR_EMAIL = 'click_hr_email',
}

export enum GA_CATEGORY {
  MENU = 'menu_category',
  SUB_MENU = 'menu_subcategory',
  SOCIAL_ICON = 'social_icon',
  PARTNER_ICON = 'partners_icon',
  LANGUAGE_ICON = 'language',
  MY_MILAN_LOGIN = 'my_milan_login',
  MY_MILAN_PROFILE = 'my_milan_profile',
  MY_MILAN_LOGOUT = 'my_milan_logout',
  PLAYER_CARD = 'player_card',
  SCHEDULE = 'schedule',
  STANDINGS = 'standings',
  MATCHES = 'matches',
  MATCHES_WIDGET = 'matches_widget',
  PARTNERS = 'partners_section',
  ORGANIZATION = 'organization_section',
  YOUTH_TEAM = 'youth_team',
  NEWS = 'news',
  VIDEOS = 'videos',
  PHOTOS = 'photos',
  SLIDER = 'slider',
  HR = 'hr',
}

export enum GoogleAdsViewTypes {
  Standard = 'standard',
  Fullscreen = 'fullscreen',
  HelloBar = 'hellobar',
}
export type GoogleAdsProps = {
  adUnit: string;
  slotId: string;
  size: string[];
  view?: GoogleAdsViewTypes;
  text?: string;
  color?: string;
  endDate?: string;
  background?: string;
}

export interface GoogleAdsBaseViewProps extends GoogleAdsProps {
  Container?: StyledComponent<'div' | 'section', DefaultTheme>;
  animated?: boolean;
}

export interface GoogleAdsViewProps extends GoogleAdsBaseViewProps {
  onSlotRender?: (isEmpty: boolean) => void;
}

export interface GoogleAdsOverlayProps extends GoogleAdsProps {
  isOpened: boolean;
  isPreview: boolean;
  onClose: () => void;
}

export type GoogleAdsMapType = {
  [adUnit: string]: GoogleAdsProps;
}

export type GoogleMaps = {
  zoom: number;
  position: {
    lat: number;
    lng: number;
  };
}

export type AdBaseStatus = boolean | null;

export type AdSlotStatus = {
  [adUnit: string]: AdBaseStatus;
};
