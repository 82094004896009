import React from 'react';
import { CircleSvg } from '@app/components/Icons/CircleInfosIcon';

const CirclePromoIcon = (): React.ReactElement => (
  <CircleSvg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <g fill="none" fillRule="evenodd">
      <circle cx="15" cy="15" r="15" fill="#C9240D" />
      <g fill="#FFF" fillRule="nonzero">
        <path d="M22.833 14.686l-.973-1.484.097-1.776c.025-.243-.122-.462-.34-.583l-1.606-.803-.802-1.605c-.097-.219-.34-.34-.584-.34l-1.775.121-1.484-.973a.681.681 0 0 0-.681 0l-1.484.973-1.776-.097c-.243-.025-.462.122-.583.34l-.803 1.606-1.605.803c-.219.096-.34.34-.34.583l.096 1.775-.972 1.484a.681.681 0 0 0 0 .681l.972 1.484-.096 1.776c-.025.243.121.462.34.583l1.605.803.803 1.605c.097.219.34.34.583.34l1.776-.096 1.484.972a.555.555 0 0 0 .34.097.561.561 0 0 0 .34-.097l1.484-.972 1.775.097c.243.024.462-.122.584-.34l.802-1.606 1.606-.803c.218-.097.34-.34.34-.583l-.12-1.801.972-1.484a.627.627 0 0 0 .025-.68zm-2.117 1.677a.684.684 0 0 0-.097.365l.097 1.58-1.41.706a.485.485 0 0 0-.268.268l-.705 1.41-1.605-.073a.638.638 0 0 0-.365.097l-1.337.876-1.338-.876a.555.555 0 0 0-.34-.097h-.025l-1.58.097-.705-1.41a.485.485 0 0 0-.268-.268l-1.411-.705.073-1.605a.638.638 0 0 0-.097-.365l-.876-1.337.876-1.338a.684.684 0 0 0 .097-.365l-.097-1.58 1.411-.705a.485.485 0 0 0 .268-.268l.705-1.411 1.604.073a.638.638 0 0 0 .365-.097l1.338-.876 1.337.876a.684.684 0 0 0 .365.097l1.58-.097.706 1.41a.485.485 0 0 0 .268.269l1.41.705-.073 1.604c0 .122.025.268.097.365l.876 1.338-.876 1.337z" />
        <path d="M13.128 14.83c1.07 0 1.921-.875 1.921-1.92 0-1.07-.876-1.921-1.92-1.921-1.07 0-1.921.876-1.921 1.92a1.94 1.94 0 0 0 1.92 1.922zm0-2.65a.705.705 0 1 1 0 1.41.705.705 0 0 1 0-1.41zM16.923 15.22c-1.07 0-1.921.876-1.921 1.921 0 1.07.876 1.921 1.92 1.921 1.047 0 1.922-.876 1.922-1.92a1.94 1.94 0 0 0-1.921-1.922zm0 2.65a.705.705 0 1 1 0-1.41.705.705 0 0 1 0 1.41zM11.483 17.708l6.243-6.242.86.86-6.243 6.242z" />
      </g>
    </g>
  </CircleSvg>
);

export default CirclePromoIcon;
