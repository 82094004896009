import React from 'react';
import { FormattedMessage } from 'react-intl';
import { format, isValid, parseISO } from 'date-fns';
import useMedia from 'react-use/lib/useMedia';
import styled from 'styled-components';

import { getFullScreenImgConfig, getStaticImageConfig } from '@app/helpers/imageHelper';
import { TicketType } from '@app/types/ticketingTypes';

import { Devices } from '@app/styles';
import { RedBoxTitle, Image } from '@app/components';
import { useLanguage } from '@app/components/Hooks';
import { ProfileDateFormatMap } from '@app/constants/dateTimeConstants';

import { useTicketsPackages, useTicketsPackagesDownload } from './PackagesHooks';
import { TicketsButtons, TicketsIcons } from '../components';


const Packages = (): React.ReactElement => {
  const language = useLanguage();
  const packages = useTicketsPackages();
  const isWideScreen = useMedia(Devices.tabletLarge);

  useTicketsPackagesDownload();

  return (
    <Page>
      {packages.map((ticketsPackage) => (
        <Package key={ticketsPackage.packageId} useImage={!!ticketsPackage.imageDesktop.url.length}>
          <TitleWrapper>
            <RedBoxTitle>{ticketsPackage.name}</RedBoxTitle>
          </TitleWrapper>
          {!!ticketsPackage.startingPrice && (
            <StartingPrice>
              <FormattedMessage id="ticketing.startingFrom" values={{ amount: ticketsPackage.startingPrice }} />
            </StartingPrice>
          )}
          <Content useImage={!!ticketsPackage.imageDesktop.url.length}>
            {ticketsPackage.imageDesktop.url.length ? (
              <ImageWrapper>
                <Image
                  src={isWideScreen ? ticketsPackage.imageDesktop.url : ticketsPackage.imageMobile.url}
                  description={
                    isWideScreen ? ticketsPackage.imageDesktop.description : ticketsPackage.imageMobile.description
                  }
                  additionalImageProps={getFullScreenImgConfig(
                    isWideScreen ? ticketsPackage.imageDesktop.url : ticketsPackage.imageMobile.url,
                  )}
                />
              </ImageWrapper>
            ) : (
              <>
                <HomeTeam>
                  <TeamName>{ticketsPackage.games[0]?.teams.home.name}</TeamName>
                  <img
                    {...getStaticImageConfig({
                      url: ticketsPackage.games[0]?.teams.home.logoUrl.url, width: 83, height: 83,
                    })}
                    alt={ticketsPackage.games[0]?.teams.home.logoUrl.description}
                  />
                </HomeTeam>
                <Games>
                  {ticketsPackage.games.map((game) => (
                    <Game key={game.gameOptaId}>
                      <Teams>
                        <Home>
                          <TeamName>{game.teams.home.name ?? game.teams.home.shortName}</TeamName>
                          <img
                            {...getStaticImageConfig({ url: game.teams.home.logoUrl.url, width: 35, height: 35 })}
                            alt={game.teams.home.logoUrl?.description}
                          />
                        </Home>
                        <Away>
                          <img
                            {...getStaticImageConfig({ url: game.teams.away.logoUrl.url, width: 35, height: 35 })}
                            alt={game.teams.away.logoUrl.description}
                          />
                          <TeamName>{game.teams.away.name ?? game.teams.away.shortName}</TeamName>
                        </Away>
                      </Teams>
                      <Date>
                        <img
                          {...getStaticImageConfig({ url: game.competition.logo.url, width: 30, height: 30 })}
                          alt={game.competition.logo.description}
                        />
                        {
                          game.kenticoMatchTimeTBC && game.matchTime && isValid(parseISO(game.matchTime ?? ''))
                            ? (
                              <MatchTime>
                                {format(parseISO(game.matchTime ?? ''), ProfileDateFormatMap[language])}
                              </MatchTime>
                            )
                            : (
                              <NoMatchTime>
                                <FormattedMessage id="ticketing.noMatchTime" />
                              </NoMatchTime>
                            )
                        }
                      </Date>
                    </Game>
                  ))}
                </Games>
              </>
            )}
            <MobileStartingPrice>
              <FormattedMessage id="ticketing.startingFrom" values={{ amount: ticketsPackage.startingPrice }} />
            </MobileStartingPrice>
            <TicketsCtaContainer>
              <TicketsButtons ticket={ticketsPackage} ticketType={TicketType.Package} />
              <TicketsIcons ticket={ticketsPackage} />
            </TicketsCtaContainer>
          </Content>
        </Package>
      ))}
    </Page>
  );
};

export default Packages;

const Page = styled.div`
  margin-top: 10px;

  @media ${Devices.tabletLarge} {
     padding: 20px 0;
  }
`;

const Package = styled.section<{ useImage: boolean }>`
  position: relative;
  background-color: white;
  padding: ${({ useImage }): string => (useImage ? '0 0 20px 0' : '20px')};
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;

  @media ${Devices.tabletLarge} {
    padding: ${({ useImage }): string => (useImage ? '0 20px 0 0' : '20px')};
  }
`;

const TitleWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
`;

const ImageWrapper = styled.section`
  flex: 0 0 75%!important;
  padding: 0!important;
`;

const StartingPrice = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 99;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.26px;
  color: ${(props): string => props.theme.color.freeSpeechRed};
  text-transform: uppercase;
  text-align: right;
  padding-top: 0;
  display: none;

  @media ${Devices.tabletLarge} {
    display: block;
  }
`;

const MobileStartingPrice = styled(StartingPrice)`
  position: relative;
  top: unset;
  right: unset;
  padding: 0!important;
  margin: 20px 0;
  display: flex;
  justify-content: center;

  @media ${Devices.tabletLarge} {
    display: none;
  }
`;

const Content = styled.div<{ useImage: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ useImage }): string => (useImage ? '0' : '40px')};

  & > * {
    flex: 0 0 25%;
    padding: 10px;
  }

  @media ${Devices.tabletLarge} {
    flex-direction: row;
    align-items: center;
  }
`;

const Games = styled.div`
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${Devices.tabletLarge} {
    flex: 0 0 50%;
    align-items: start;
  }
`;

const Game = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  padding: 20px 0;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: calc(50% - 25px);
    width: 50px;
    border: 1px solid ${(props): string => props.theme.color.harleyDavidsonOrange};
  }

  &:last-of-type::after {
    display: none;
  }

  @media ${Devices.tabletLarge} {
    flex-direction: row;
    justify-content: space-between;

    &::after {
      display: none;
    }
  }
`;

const Teams = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 0 0 100%;
  justify-content: center;

  @media ${Devices.tabletLarge} {
    width: auto;
    flex: 0 0 50%;
    justify-content: start;
  }
`;

const Away = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 0 50%;
  justify-content: flex-start;

  span {
    text-align: left;
  }

  img {
    margin: 0 5px;
    max-width: 35px;
    max-height: 35px;

    @media ${Devices.tabletLarge} {
      margin: 0 15px;
    }
  }
`;

const Home = styled(Away)`
  justify-content: flex-end;

  @media ${Devices.tabletLarge} {
    display: none;
  }
`;

const MatchTime = styled.span`
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.32px;
  text-transform: uppercase;
`;

const NoMatchTime = styled(MatchTime)`
  color: ${(props): string => props.theme.color.freeSpeechRed};
`;

const Date = styled.div`
  display: flex;
  flex: 0 0 50%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin: 0 15px;
    max-width: 30px;
    max-height: 30px;
  }

  ${MatchTime}, ${NoMatchTime} {
    padding: 10px 0 25px;
  }

  @media ${Devices.tabletLarge} {
    font-size: 15px;
    flex-direction: row;
    justify-content: start;

    ${MatchTime}, ${NoMatchTime} {
      padding: 0;
    }
  }
`;

const HomeTeam = styled.div`
  display: none;

  img {
    max-width: 83px;
    max-height: 83px;
  }

  @media ${Devices.tabletLarge} {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const TeamName = styled.span`
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.43px;
  text-align: right;
  text-transform: uppercase;

  @media ${Devices.tabletLarge} {
    font-size: 20px;
  }
`;

const TicketsCtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    width: 150px;
  }

  @media ${Devices.tabletLarge} {
    align-items: flex-end;
  }
`;
