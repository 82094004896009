import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { getStandings, resetSeason } from '@app/store/actions/seasonActions';
import {
  getHomeData, getHomeLandingData, getHomeSliderData, resetHomeData, setHomePageMultiLangRoute, getHomeGoogleAds,
} from '@app/store/actions/homeActions';
import {
  getTicketingSingle,
  resetTicketingSingle,
} from '@app/store/actions/ticketingActions';

import { MenCompetitionsMap } from '@app/services/opta/constants/competitionConstants';
import { HOME_STANDINGS_TEAMS_NUMBER, HomeBlockIdToComponentMap } from '@app/constants/configurationConstants';

import { TeamStats } from '@app/types/standingsTypes';
import { HomeMatch } from '@app/types/matchTypes';
import { HomeBlocks } from '@app/types/homeConfigurationTypes';
import { Seo } from '@app/types/configurationTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { AppState } from '@app/store/reducers';

import { isTeamMilan } from '@app/helpers/teamHelpers';

import { useStandingSelector } from '@app/pages/Season/Standings/StandingsHooks';
import { GoogleAdsProps } from '@app/types/googleTypes';
import { WebEmbeddedOverlay, WebHomeLanding, WebHomeSlider } from '@app/types/webTemplateTypes';
import { MountContext } from '@app/ReactContext';
import { useLanguage } from '@app/components/Hooks';

interface SeasonData {
  standings: TeamStats[];
  matches: HomeMatch[];
}
export const useSeasonData = (): SeasonData => {
  const dispatch = useDispatch();
  const standings = useStandingSelector()?.[0]?.statistics ?? [];
  const language = useIntl().locale as LanguageType;

  const matches = useSelector<AppState, HomeMatch[]>(
    (state) => state.home.matchBox,
    shallowEqual,
  );

  const milanIndex = standings.findIndex((team) => isTeamMilan(team.id));
  let homeStandings;

  if (milanIndex === -1 || milanIndex < HOME_STANDINGS_TEAMS_NUMBER) {
    homeStandings = standings.slice(0, HOME_STANDINGS_TEAMS_NUMBER);
  } else if (milanIndex >= standings.length - HOME_STANDINGS_TEAMS_NUMBER) {
    homeStandings = standings.slice(standings.length - HOME_STANDINGS_TEAMS_NUMBER);
  } else {
    // show Milan on the 2d row
    homeStandings = standings.slice(milanIndex - 1, milanIndex + HOME_STANDINGS_TEAMS_NUMBER - 1);
  }

  useEffect(() => {
    dispatch(getStandings({ competitionId: MenCompetitionsMap.SeriaA, language }));
    // Todo: Home data request should be moved outside useSeasonData component
    dispatch(getHomeData({ language }));
    dispatch(getTicketingSingle(language));

    return (): void => {
      dispatch(resetSeason());
      dispatch(resetHomeData());
      dispatch(resetTicketingSingle());
    };
  }, [language]);

  return {
    standings: homeStandings,
    matches,
  };
};

export const useOrderedHomeBlocks = (): {HomeBlock: React.FunctionComponent; params: { id: HomeBlocks }}[] => {
  const sequence = useSelector<AppState, HomeBlocks[]>(
    (state) => state.home.sequence,
    shallowEqual,
  );

  return sequence.map((blockId) => ({ HomeBlock: HomeBlockIdToComponentMap[blockId], params: { id: blockId } }));
};

export const useSeoData = (): Seo => {
  const { locale } = useIntl();

  return useSelector<AppState, Seo>(
    (state) => state.configuration[locale]?.home,
    shallowEqual,
  );
};

export const useIsOverlapped = (): boolean => (
  useSelector<AppState, boolean>(
    (state) => !!state.home.carousel.length && !state.home.gameId.length,
    shallowEqual,
  )
);

export const useGameId = (): string => (
  useSelector<AppState, string>(
    (state) => state.home.gameId,
    shallowEqual,
  )
);

export const useHomeMultiLangUrl = (): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setHomePageMultiLangRoute());
  }, []);
};


export function useHomeLandingDataDownload(id: HomeBlocks): void {
  const { isInitialMount } = useContext(MountContext);
  const language = useLanguage();
  const dispatch = useDispatch();

  const hasItems = useSelector<AppState, boolean>(
    (state) => !!state.home.landingData?.find((landingData) => landingData.id === id.toString()),
    shallowEqual,
  );

  useEffect(() => {
    if (!isInitialMount || !hasItems) {
      // check if data has been loaded on server before very first render
      !hasItems && dispatch(getHomeLandingData({ id, language }));
    }
  }, [language]);
}

export const useHomeLandingDataHook = (id: HomeBlocks): WebHomeLanding | null => (
  useSelector<AppState, WebHomeLanding | null>(
    (state) => state.home.landingData?.find((landingData) => landingData.id === id.toString()) || null,
    shallowEqual,
  )
);

export function useHomeSliderDataDownload(id: HomeBlocks): void {
  const { isInitialMount } = useContext(MountContext);
  const language = useLanguage();
  const dispatch = useDispatch();

  const hasItems = useSelector<AppState, boolean>(
    (state) => !!state.home.sliderData?.find((sliderData) => sliderData.id === id.toString()),
    shallowEqual,
  );

  useEffect(() => {
    if (!isInitialMount || !hasItems) {
      // check if data has been loaded on server before very first render
      !hasItems && dispatch(getHomeSliderData({ id, language }));
    }
  }, [language]);
}

export const useHomeSliderDataHook = (id: HomeBlocks): WebHomeSlider | null => (
  useSelector<AppState, WebHomeSlider | null>(
    (state) => state.home.sliderData?.find((sliderData) => sliderData.id === id.toString()) || null,
    shallowEqual,
  )
);

export function useHomeGoogleAdsDownload(id: HomeBlocks): void {
  const { isInitialMount } = useContext(MountContext);
  const language = useLanguage();
  const dispatch = useDispatch();

  const hasItems = useSelector<AppState, boolean>(
    (state) => !!state.home.googleAds?.find((ads) => ads.id === id.toString()),
    shallowEqual,
  );

  useEffect(() => {
    if (!isInitialMount || !hasItems) {
      // check if data has been loaded on server before very first render
      !hasItems && dispatch(getHomeGoogleAds({ id, language }));
    }
  }, [language]);
}

export const useHomeGoogleAdsSelector = (id: HomeBlocks): GoogleAdsProps | null => (
  useSelector<AppState, GoogleAdsProps | null>(
    (state) => state.home.googleAds
      ?.find((ads) => ads.id === id.toString())?.ads || null,
    shallowEqual,
  )
);

export const useHomeEmbeddedOverlaySelector = (): WebEmbeddedOverlay | null => (
  useSelector<AppState, WebEmbeddedOverlay | null>(
    (state) => state.home.embeddedOverlay,
    shallowEqual,
  )
);
