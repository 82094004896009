import pull from 'lodash/pull';

import {
  SetTeamPayload, Team, TeamNames, TeamStatistics,
} from '@app/types/teamsTypes';
import {
  ResetAllTeams, ResetAllTeamsStatistics, SetTeam, SetTeamNames, SetTeamStatistics, TeamsActionTypes,
} from '@app/store/actionTypes/teamsActionTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { Action, ActionWithPromiseReturn } from '@app/types/actionTypes';
import { SubMenuItem, TeamSubMenuId } from '@app/types/configurationTypes';
import { TeamSquadTypes } from '@app/services/kentico/types/teamsTypes';

import { waitForConfigurationDownload } from '@app/store/actions/configurationActions';
import { CurrentRouteParams, setCurrentRoute } from '@app/store/actions/currentRouteActions';
import { getCoachesList, getPlayersList, getTeamNamesData } from '@app/services/kentico/teamsService';
import { getTeamStatistics } from '@app/services/opta/teamsService';
import { AppState } from '@app/store/reducers';

import { mapTeamSquadType, sortPlayers } from '@app/services/kentico/helpers/teamsHelpers';
import { mapTeamTypeToCompetition, findCategoryBySlug, findCategoryById } from '@app/helpers/configurationHelpers';
import { gepPlayerTabs, getLocalizedPlayer, getLocalizedSubMenuItem } from '@app/helpers/teamHelpers';
import { getWebTemplateDataById } from '@app/store/actions/webTemplateActions';

import { AppLanguages } from '@app/constants/localizationConstants';
import AppRoutes from '@app/constants/routes';
import * as Translations from '@app/locales';

const setTeam = (team: SetTeamPayload): SetTeam => ({
  type: TeamsActionTypes.SET_TEAM,
  payload: team,
});

export const resetAllTeams = (): ResetAllTeams => ({
  type: TeamsActionTypes.RESET_ALL_TEAMS,
});

const setTeamStatistics = (statistics: TeamStatistics, type: TeamSubMenuId): SetTeamStatistics => ({
  type: TeamsActionTypes.SET_TEAM_STATISTICS,
  payload: { type, statistics },
});

export const resetAllTeamsStatistics = (): ResetAllTeamsStatistics => ({
  type: TeamsActionTypes.RESET_ALL_TEAMS_STATISTICS,
});

const getTeamTypeSubItem = (
  { state, teamType, language }: GetTeamTypeSubItemType,
): SubMenuItem => {
  const navigation = state.configuration[language]?.menu?.web_teams?.navigation;
  let subItem;
  if (navigation) {
    if (teamType) {
      subItem = findCategoryBySlug(Object.values(navigation), teamType);
    } else {
      [subItem] = Object.values(navigation);
    }
  }
  return subItem;
};

interface GetPlayersType {
  teamType: string;
  menuSubItemId?: TeamSubMenuId;
  language: LanguageType;
}

interface GetPlayersForNotActiveLangs {
  teamType: TeamSquadTypes;
  language: LanguageType;
  menuSubItemId: TeamSubMenuId;
}

const getPlayersForNotActiveLangs = ({ language, teamType, menuSubItemId }: GetPlayersForNotActiveLangs): Action => (
  async (dispatch): Promise<void> => {
    const restLanguages = pull([...AppLanguages], language);

    const playersRequests = restLanguages.map((language) => (
      getPlayersList({
        language,
        teamType,
      })
    ));
    const coachesRequests = restLanguages.map((language) => (
      getCoachesList({
        language,
        teamType,
      })
    ));

    const multiLangPlayersData = await Promise.all(playersRequests);
    const multiLangCoachesData = await Promise.all(coachesRequests);

    const languageToPlayerDataMap = restLanguages.reduce((acc, language, index) => {
      acc[language] = sortPlayers(multiLangPlayersData[index]).concat(multiLangCoachesData[index]);
      return acc;
    }, {});

    dispatch(setTeam({
      type: menuSubItemId,
      players: languageToPlayerDataMap,
    }));
  }
);

export const getPlayers = ({ teamType, menuSubItemId, language }: GetPlayersType): ActionWithPromiseReturn => (
  async (dispatch, getState): Promise<void> => {
    await waitForConfigurationDownload();
    if (!teamType && !menuSubItemId) return;

    try {
      const subItem = getTeamTypeSubItem({ state: getState(), teamType, language });
      const subItemId = menuSubItemId ?? subItem.id as TeamSubMenuId;
      const teamSquadType = mapTeamSquadType(subItemId);
      if (!teamSquadType) return;

      const players: Team = await getPlayersList({
        language,
        teamType: teamSquadType,
      });
      const coaches: Team = await getCoachesList({
        language,
        teamType: teamSquadType,
      });
      sortPlayers(players);
      dispatch(setTeam({
        type: subItemId,
        players: {
          [language]: players.concat(coaches),
        },
      }));

      dispatch(getWebTemplateDataById({
        id: subItemId,
        language,
      }));

      return dispatch(getPlayersForNotActiveLangs({ language, teamType: teamSquadType, menuSubItemId: subItemId }));
    } catch (e) {
      console.error('Error on fetching players', e);
      // Todo clarify with PO error scenario
    }
  }
);

interface GetPlayersStatisticsType {
  teamType: string;
  language: LanguageType;
}

export const getPlayersStatistics = ({ teamType, language }: GetPlayersStatisticsType): Action => (
  async (dispatch, getState): Promise<void> => {
    await waitForConfigurationDownload();

    try {
      const subItem = getTeamTypeSubItem({ state: getState(), teamType, language });
      const subItemId = subItem.id as TeamSubMenuId;
      const competition = mapTeamTypeToCompetition(subItemId, getState().configuration[language]?.competitions ?? {});
      if (subItem && teamType && competition) {
        const { seasonIds, optaId } = competition;
        const teamId = subItem.optaId;
        const statistics = await getTeamStatistics({
          seasonId: seasonIds[0]?.seasonId, teamId, optaId,
        });// todo: remove hardcode
        dispatch(setTeamStatistics(statistics, subItemId));
      }
    } catch (e) {
      console.error('Error on fetching players', e);
      // Todo clarify with PO error scenario
    }
  }
);

interface GetTeamTypeSubItemType {
  state: AppState;
  teamType: string;
  language: LanguageType;
}

type SetTeamsPageMultiLangRoute = (params: {
  teamType: string;
  categoryName: string;
  language: LanguageType;
}) => Action

export const setTeamsPageMultiLangRoute: SetTeamsPageMultiLangRoute = ({ teamType, categoryName, language }) => (
  async (dispatch, getState): Promise<void> => {
    if (!teamType || !categoryName) return;
    await waitForConfigurationDownload();

    const state = getState();
    const localizedSubMenuItem = getLocalizedSubMenuItem(state, language, teamType);
    const subItem = localizedSubMenuItem?.[language];

    const categoryId = findCategoryBySlug(subItem?.categories ?? [], categoryName)?.id ?? '';

    const params = AppLanguages.reduce((acc, language) => {
      const langSubItem = localizedSubMenuItem?.[language];
      const category = findCategoryById(langSubItem?.categories ?? [], categoryId);

      acc.teamType[language] = langSubItem?.url ?? '';
      acc.categoryName[language] = category?.url ?? '';
      return acc;
    }, { categoryName: {}, teamType: {} } as unknown as CurrentRouteParams);

    dispatch(setCurrentRoute({
      pathId: AppRoutes.Teams.path,
      params,
    }));
  }
);

type PlayerProfileAction = (params: {
  urlSlug: string;
  tab: string;
  language: LanguageType;
  teamType: string;
}) => Action

export const setPlayerPageMultiLangRoute: PlayerProfileAction = ({
  teamType, urlSlug, tab, language,
}) => (
  async (dispatch, getState): Promise<void> => {
    await waitForConfigurationDownload();
    const state = getState();
    const localizedSubMenuItem = getLocalizedSubMenuItem(state, language, teamType);
    const subMenuItem = localizedSubMenuItem?.[language];
    const teamSubMenuId = subMenuItem?.id as TeamSubMenuId;

    const localizedPlayer = getLocalizedPlayer(state, teamSubMenuId, language, urlSlug);
    const tabs = gepPlayerTabs(language);
    const selectedTab = tabs.find((item) => item.caption === tab) || tabs[0];

    const params = AppLanguages.reduce((acc, language) => {
      const subItem = localizedSubMenuItem?.[language];

      acc.teamType[language] = subItem?.url ?? '';
      acc.tab[language] = Translations[language]?.[selectedTab.name].toLowerCase();
      acc.urlSlug[language] = localizedPlayer?.[language]?.urlSlug ?? '';

      return acc;
    }, { teamType: {}, tab: {}, urlSlug: {} } as unknown as CurrentRouteParams);

    dispatch(setCurrentRoute({
      pathId: AppRoutes.PlayerProfile.path,
      params,
    }));
  }
);

export const getPlayerProfileDataForSsr: PlayerProfileAction = ({
  teamType,
  urlSlug,
  tab,
  language,
}) => (
  async (dispatch): Promise<void[]> => (
    Promise.all([
      dispatch(getPlayersStatistics({ teamType, language })),
      dispatch(getPlayers({ teamType, language }))
        .then(() => dispatch(setPlayerPageMultiLangRoute({
          teamType,
          urlSlug,
          tab,
          language,
        }))),
    ])
  )
);

const setTeamNames = (names: TeamNames): SetTeamNames => ({
  type: TeamsActionTypes.SET_TEAM_NAMES,
  payload: names,
});

export const getTeamNames = (language?: LanguageType): Action => (
  async (dispatch): Promise<void> => {
    await waitForConfigurationDownload();
    const teamNames = await getTeamNamesData(language);

    dispatch(setTeamNames(teamNames));
  }
);
