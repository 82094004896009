import { LanguageType } from '@app/types/localizationTypes';

export const DateFormatMap: {
  [key in LanguageType]: string;
} = {
  en: 'dd MMMM yyyy',
  it: 'dd MMMM yyyy',
  cn: 'yyyy年MM月dd日',
};

export const MatchDayDateFormatMap: {
  [key in LanguageType]: string;
} = {
  en: 'yyyy-MM-dd',
  it: 'yyyy-MM-dd',
  cn: 'yyyy年MM月dd日',
};

export const ProfileDateFormatMap: {
  [key in LanguageType]: string;
} = {
  en: 'dd/MM/yyyy',
  it: 'dd/MM/yyyy',
  cn: 'yyyy年MM月dd日',
};

export const TicketingDateFormatMap: {
  [key in LanguageType]: string;
} = {
  en: 'EEE. dd MMMM',
  it: 'EEE. dd MMMM',
  cn: 'yyyy年MM月dd日',
};

export const MatchDateFormatMap: {
  [key in LanguageType]: string;
} = {
  en: 'EEEE dd MMMM',
  it: 'EEEE dd MMMM',
  cn: 'yyyy年MM月dd日',
};
