import { Action, ActionWithPromiseReturn } from '@app/types/actionTypes';
import { FeedTypes } from '@app/services/opta/constants/feedTypes';
import { PageError } from '@app/types/errorTypes';
import { Competitions } from '@app/types/configurationTypes';
import {
  MatchResult, LiveMatchResults, LocalizedGames, MatchLineups,
} from '@app/types/matchTypes';
import { LanguageType } from '@app/types/localizationTypes';
import {
  SetGames, SetLiveMatch, ResetMatchCenter, ResetLiveMatch, MatchActionTypes, SetError, SetMatchResults,
  SetMatchEvents, SetMatchLineups, SetGamesDownloadCompleted, SetMatchFeedsDownloadCompleted,
  SetMatchDayResults,
} from '@app/store/actionTypes/matchActionTypes';

import AppRoutes from '@app/constants/routes';
import { AppLanguages, AppLanguagesMap } from '@app/constants/localizationConstants';
import { TeamSubMenuTypeMap } from '@app/constants/teamsConstants';
import { MilanTeamId } from '@app/services/opta/constants/teamConstants';
import { reduxStore } from '@app/store';
import { CurrentRouteParams, setCurrentRoute } from '@app/store/actions/currentRouteActions';
import * as Translations from '@app/locales/index';

import { getPlayers } from '@app/store/actions/teamsActions';
import { getStandings } from '@app/store/actions/seasonActions';
import { waitForConfigurationDownload } from '@app/store/actions/configurationActions';
import {
  mapLiveMatchResults, mapMatchLineups, getGameByUrlSlug, getGameByGameOptaId,
} from '@app/helpers/matchHelpers';
import { getFeedData } from '@app/services/opta/feedService';
import { getGames } from '@app/services/kentico/gamesService';
import { getMatchResultsData } from '@app/services/opta/matchService';


export function waitForGamesDownload(): Promise<void> {
  const state = reduxStore.store.getState();

  if (state.matchCenter.isGamesDownloadCompleted) return Promise.resolve();

  return new Promise((resolve): void => {
    const unsubscribe = reduxStore.store.subscribe(() => {
      const state = reduxStore.store.getState();

      if (state.matchCenter.isGamesDownloadCompleted) {
        resolve();
        unsubscribe && unsubscribe();
      }
    });
  });
}

export const setMatchCenterError = (error: PageError): SetError => ({
  type: MatchActionTypes.SET_ERROR,
  payload: error,
});

export const resetLiveMatchResults = (gameId: string): ResetLiveMatch => ({
  type: MatchActionTypes.RESET_LIVE_MATCH,
  payload: gameId,
});

export const resetMatchCenter = (): ResetMatchCenter => ({
  type: MatchActionTypes.RESET_MATCH_CENTER,
  payload: null,
});

export const setLiveMatchResults = (payload: LiveMatchResults): SetLiveMatch => ({
  type: MatchActionTypes.SET_LIVE_MATCH,
  payload,
});

export const getLiveMatchResults = ({ competitionOptaId, seasonId }: MatchResult): Action => (
  async (dispatch): Promise<void> => {
    try {
      const liveData = await getFeedData({
        path: 'competition.php',
        feedType: FeedTypes.live,
        competition: competitionOptaId,
        seasonId,
      });

      const liveMatchResults = mapLiveMatchResults({ liveData, seasonId, optaId: competitionOptaId });

      dispatch(setLiveMatchResults(liveMatchResults));
    } catch (e) {
      console.error('Error on fetching Match Live data', e);
      // TODO: clarify with PO error scenario
    }
  }
);

const setGames = (payload: LocalizedGames): SetGames => ({ type: MatchActionTypes.SET_GAMES, payload });

const setGamesDownLoadCompleted = (): SetGamesDownloadCompleted => ({
  type: MatchActionTypes.SET_GAMES_DOWNLOAD_COMPLETED, payload: true,
});

const setMatchFeedsDownLoadCompleted = (): SetMatchFeedsDownloadCompleted => ({
  type: MatchActionTypes.SET_MATCH_FEEDS_DOWNLOAD_COMPLETED, payload: true,
});

type GetGamesData = (
  language: LanguageType,
  competitions: Competitions,
) => ActionWithPromiseReturn;

const getGamesByLanguage: GetGamesData = (language, competitions) => (
  async (dispatch): Promise<void> => {
    const games = await getGames(language, competitions);
    const data: LocalizedGames = {};
    data[language] = games;

    dispatch(setGames(data));
  }
);

export const getLocalizedGames = (): Action => (
  async (dispatch, getState): Promise<void> => {
    await waitForConfigurationDownload();

    try {
      const areGamesLoaded = !!getState().matchCenter.games;
      const { configuration } = getState();

      !areGamesLoaded && await Promise.all(
        AppLanguages.map((language) => dispatch((getGamesByLanguage(
          language, configuration[language]?.competitions,
        )))),
      ).finally(() => dispatch(setGamesDownLoadCompleted()));
    } catch (e) {
      console.error('Error on fetching Games data', e);
      dispatch(setMatchCenterError(PageError.Sorry));
    }
  }
);

const setMatchResults = (payload: MatchResult | null): SetMatchResults => ({
  type: MatchActionTypes.SET_MATCH_RESULTS, payload,
});

const setMatchDayResults = (payload: MatchResult[]): SetMatchDayResults => ({
  type: MatchActionTypes.SET_MATCH_DAY_RESULTS, payload,
});

type GetMatchFeeds = (
  seasonId: string,
  optaId: string,
  gameOptaId: string,
  language?: LanguageType,
) => ActionWithPromiseReturn;

export const getMatchResults: GetMatchFeeds = (seasonId, optaId, gameOptaId, language) => (
  async (dispatch): Promise<void> => {
    try {
      const allResults = await getMatchResultsData({
        seasonId, optaId, useFilter: false, language,
      }) ?? [];
      const matchResult = allResults.find((result) => result.gameOptaId === gameOptaId) ?? null;
      const matchDayResults = allResults.filter((result) => result.matchDay === matchResult?.matchDay && ![
        result.teams.first.id, result.teams.second.id,
      ].includes(MilanTeamId));

      dispatch(setMatchResults(matchResult));
      dispatch(setMatchDayResults(matchDayResults));
    } catch (e) {
      console.error('Error on fetching Match Results data', e);
      dispatch(setMatchCenterError(PageError.Sorry));
    }
  }
);

const setMatchLineups = (payload: MatchLineups): SetMatchLineups => ({
  type: MatchActionTypes.SET_MATCH_LINEUPS, payload,
});

export const getMatchLineups: GetMatchFeeds = (seasonId, optaId, gameOptaId) => (
  async (dispatch): Promise<void> => {
    try {
      dispatch(setMatchLineups(mapMatchLineups(await getFeedData({
        feedType: FeedTypes.lineups,
        competition: optaId,
        gameId: gameOptaId,
        seasonId,
      }), gameOptaId)));
    } catch (e) {
      console.error('Error on fetching Match Lineups data', e);
    }
  }
);

const setMatchEvents = (payload: unknown): SetMatchEvents => ({ type: MatchActionTypes.SET_MATCH_EVENTS, payload });

export const getMatchEvents: GetMatchFeeds = (seasonId, optaId, gameOptaId, language) => (
  async (dispatch): Promise<void> => {
    try {
      const eventsData = await getFeedData({
        feedType: FeedTypes.events,
        competition: optaId,
        gameId: gameOptaId,
        seasonId,
        language: language === AppLanguagesMap.cn ? AppLanguagesMap.en : language,
      });

      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore // TODO:REMOVE: ignore
      const messages = Array.from(eventsData?.Commentary?.message);
      const events = messages.map((message) => ({
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore // TODO:REMOVE: ignore
        time: message?.['@attributes']?.time,
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore // TODO:REMOVE: ignore
        type: (message?.['@attributes']?.type ?? '').toLowerCase().replace(/ /g, '_'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore // TODO:REMOVE: ignore
        comment: message?.['@attributes']?.comment ?? '',
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore // TODO:REMOVE: ignore
        playerId1: message?.['@attributes']?.player_ref1,
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore // TODO:REMOVE: ignore
        playerId2: message?.['@attributes']?.player_ref2,
      }));

      dispatch(setMatchEvents(events));
    } catch (e) {
      console.error('Error on fetching Match Lineups data', e);
    }
  }
);

type GetMatchFeedsData = {
  urlSlug: string;
  language: LanguageType;
};

export const getMatchFeeds = ({ urlSlug, language }: GetMatchFeedsData): Action => (
  async (dispatch, getState): Promise<void> => {
    await waitForConfigurationDownload();
    await waitForGamesDownload();

    try {
      const game = getGameByUrlSlug(getState(), language, urlSlug);
      const { seasonId = '', optaId = '', gameOptaId = '' } = game ?? {};

      await dispatch(getMatchResults(seasonId, optaId, gameOptaId, language));
      await dispatch(getMatchLineups(seasonId, optaId, gameOptaId));
      await dispatch(getMatchEvents(seasonId, optaId, gameOptaId, language));
      await dispatch(getStandings({ seasonId, optaId, language }));
      await dispatch(getPlayers({ menuSubItemId: TeamSubMenuTypeMap.men, teamType: '', language }));

      dispatch(setMatchFeedsDownLoadCompleted());
    } catch (e) {
      console.error('Error on fetching Match Feeds data', e);
      dispatch(setMatchCenterError(PageError.Sorry));
    }
  }
);

type SetMatchCenterMultiLangUrl = (args: {
  tab: string;
  urlSlug: string;
  language: LanguageType;
}) => Action;

export const setMatchCenterMultiLangUrl: SetMatchCenterMultiLangUrl = ({
  urlSlug, tab, language,
}) => (
  async (dispatch, getState): Promise<void> => {
    await waitForConfigurationDownload();
    await waitForGamesDownload();

    const state = getState();
    const gameOptaId = getGameByUrlSlug(state, language, urlSlug)?.gameOptaId ?? '';
    const tabKey = ((): string => {
      switch (tab) {
        case Translations[language]?.['matchcenter.tabs.lineups'].toLowerCase(): return 'matchcenter.tabs.lineups';
        case Translations[language]?.['matchcenter.tabs.statistics'].toLowerCase(): return 'matchcenter.tabs.statistics';
        case Translations[language]?.['matchcenter.tabs.results'].toLowerCase(): return 'matchcenter.tabs.results';
        case Translations[language]?.['matchcenter.tabs.standings'].toLowerCase(): return 'matchcenter.tabs.standings';
        default: return '';
      }
    })();

    const params = AppLanguages.reduce((acc, locale) => {
      acc.urlSlug[locale] = getGameByGameOptaId(state, locale, gameOptaId)?.urlSlug ?? '';
      acc.tab[locale] = Translations[locale]?.[tabKey]?.toLowerCase();
      return acc;
    }, { urlSlug: {}, tab: {} } as unknown as CurrentRouteParams);

    dispatch(setCurrentRoute({ pathId: AppRoutes.MatchCenter.path, params }));
  }
);
