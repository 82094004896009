import React, { useRef, useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import styled from 'styled-components';

import { HorizontalArrowLeftIcon, HorizontalArrowRightIcon } from '@app/components/Icons';
import { determineOverflow } from '@app/helpers/horizontalScrollHelper';

interface HorizontalScrollProps {
  color: string;
}

enum ScrollDirection {
  left = 'left',
  right = 'right',
  both = 'both'
}

const HorizontalScroll = ({ color, children }: React.PropsWithChildren<HorizontalScrollProps>): React.ReactElement => {
  const SCROLL_DISTANCE = 50;
  const { width } = useWindowSize();
  const scrollContainerRef = useRef<HTMLUListElement>(document.createElement('ul'));
  const scrollContentRef = useRef<HTMLDivElement>(document.createElement('div'));
  const [scrollContainer, setScrollContainer] = useState(scrollContainerRef.current);
  const [scrollContent, setScrollContent] = useState(scrollContentRef.current);

  useEffect(() => {
    setScrollContainer(scrollContainerRef.current);
  }, [scrollContainerRef.current]);
  useEffect(() => {
    setScrollContent(scrollContentRef.current);
  }, [scrollContentRef.current]);

  const handleScroll = (): void => {
    scrollContainer.setAttribute('data-overflow', determineOverflow(scrollContent, scrollContainer));
  };

  const handleClickArrowLeft = (): void => {
    const direction = scrollContainer.getAttribute('data-overflow');
    if (direction === ScrollDirection.left || direction === ScrollDirection.both) {
      const availableScroll = scrollContainer.scrollLeft;
      if (availableScroll < SCROLL_DISTANCE * 2) {
        scrollContainer.scrollLeft -= availableScroll;
      } else {
        scrollContainer.scrollLeft -= SCROLL_DISTANCE;
      }
    }
  };

  const handleClickArrowRight = (): void => {
    const direction = scrollContainer.getAttribute('data-overflow');
    if (direction === ScrollDirection.right || direction === ScrollDirection.both) {
      const containerRightBorder = scrollContainer.getBoundingClientRect().right;
      const contentRightBorder = scrollContent.getBoundingClientRect().right;
      const availableScroll = Math.floor(contentRightBorder - containerRightBorder);
      if (availableScroll < SCROLL_DISTANCE * 2) {
        scrollContainer.scrollLeft += availableScroll;
      } else {
        scrollContainer.scrollLeft += SCROLL_DISTANCE;
      }
    }
  };

  useEffect(() => {
    scrollContainer.setAttribute('data-overflow', determineOverflow(scrollContent, scrollContainer));
    scrollContainer.addEventListener('scroll', handleScroll);

    return (): void => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, [children, width, scrollContent]);

  return (
    <HorizontalScrollWrapper>
      <ScrollContainer ref={scrollContainerRef}>
        <ScrollContent ref={scrollContentRef}>
          {children}
        </ScrollContent>
      </ScrollContainer>
      <ArrowButtonLeft type="button" onClick={handleClickArrowLeft} color={color}>
        <HorizontalArrowLeftIcon />
      </ArrowButtonLeft>
      <ArrrowButtonRight type="button" onClick={handleClickArrowRight} color={color}>
        <HorizontalArrowRightIcon />
      </ArrrowButtonRight>
    </HorizontalScrollWrapper>
  );
};

const HorizontalScrollWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ScrollContainer = styled.ul`
  height: 100%;
  margin: 0;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

  &[data-overflow=${ScrollDirection.right}] {
    margin-right: 17px;
  }
  &[data-overflow=${ScrollDirection.left}] {
    margin-left: 17px;
  }
  &[data-overflow=${ScrollDirection.both}] {
    margin: 0 17px;
  }
`;

const ScrollContent = styled.div`
  height: 100%;
  width: min-content;
`;

const ArrowButton = styled.button<{ color: string }>`
  appearance: none;
  background: transparent;
  padding: 0;
  border: 0;
  &:focus {
    outline: 0;
  }
  position: absolute;
  top: 0;
  bottom: 0;
  display: none;
  & svg {
    fill: ${({ color }): string => color};
    stroke: ${({ color }): string => color};
  }
  [data-overflow=${ScrollDirection.both}] ~ & {
    display: flex;
    align-items: center;
  }
`;

const ArrowButtonLeft = styled(ArrowButton)`
  left: 0;
  [data-overflow=${ScrollDirection.left}] ~ & {
    display: flex;
    align-items: center;
  }
`;

const ArrrowButtonRight = styled(ArrowButton)`
  right: 0;
  [data-overflow=${ScrollDirection.right}] ~ & {
    display: flex;
    align-items: center;
  }
`;

export default HorizontalScroll;
