import { useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { format, isValid } from 'date-fns';

import { AppState } from '@app/store/reducers';
import { MatchTicketsInfo } from '@app/types/ticketingTypes';
import { MatchResult, LiveMatchResult } from '@app/types/matchTypes';
import { getLiveMatchResults, resetLiveMatchResults } from '@app/store/actions/matchActions';
import { MatchStatusMap } from '@app/constants/matchConstants';
import { useSingleTickets } from '@app/pages/Ticketing/Single/SingleHooks';

export const useLiveMatchResultsSelector = (gameId: string): LiveMatchResult => (
  useSelector<AppState, LiveMatchResult>(
    (state) => state.matchCenter.live[gameId] || {},
    shallowEqual,
  )
);

export const useMatchResultsSelector = (matchResult: MatchResult): MatchResult => {
  const liveMatchResults = useLiveMatchResultsSelector(matchResult.gameId);
  return {
    ...matchResult,
    ...liveMatchResults,
  };
};

export const useMatchTicketSelector = (matchResult: MatchResult): MatchTicketsInfo | undefined => {
  const date = new Date(matchResult?.matchDate);
  const matchMonth = isValid(date) ? format(date, 'yyyy-MM') : '';
  return Object
    .values(useSingleTickets()?.[matchMonth] ?? {})
    .find(({ gameOptaId }) => gameOptaId === matchResult.gameOptaId);
};

export const useMatchLiveSubscribe = (matchResult: MatchResult): void => {
  const dispatch = useDispatch();
  const { gameId } = matchResult;
  const { matchStatus } = useLiveMatchResultsSelector(gameId);

  useEffect(() => {
    !matchStatus && gameId && dispatch(getLiveMatchResults(matchResult));
    const subscribe = gameId && matchStatus !== MatchStatusMap.finished
      && setInterval(() => dispatch(getLiveMatchResults(matchResult)), 20000);

    return (): void => { subscribe && clearInterval(subscribe); };
  }, [matchStatus]);
};

export const useMatchLiveResetOnUnmout = ({ gameId }: MatchResult): void => {
  const dispatch = useDispatch();

  useEffect(() => (): void => {
    gameId && dispatch(resetLiveMatchResults(gameId));
  }, []);
};
