import React from 'react';
import styled from 'styled-components';

import { WebPageTitle } from '@app/types/webTemplateTypes';
import { Devices } from '@app/styles';
import { ContainerMedium } from '@app/components/WebTemplates/components';

interface WebPageTitleElementProps {
  data: WebPageTitle;
}

const WebPageTitleElement = ({ data }: WebPageTitleElementProps): React.ReactElement => (
  <Container>
    <Title>{data?.title}</Title>
    {data?.subTitle && (<SubTitle>{data?.subTitle}</SubTitle>)}
  </Container>
);

const Container = styled(ContainerMedium)`
  position: relative;
  width: 100%;
  margin-bottom: 30px;

  @media ${Devices.desktop} {
    margin-bottom: 30px;
  }
`;

const Title = styled.h1`
  margin-top: 20px;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 26px;
  font-weight: bold;
  line-height: 1.19;
  text-align: center;
  color: #000000;

  @media ${Devices.desktop} {
    margin-top: 40px;
  }
`;

const SubTitle = styled.h2`
  margin-top: 5px;
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: 14px;
  font-weight: normal;
  line-height: 1.29;
  letter-spacing: 0.08px;
  text-align: center;
  color: #000000;

  @media ${Devices.desktop} {
    font-size: 16px;
  }
`;

export default WebPageTitleElement;
