import React from 'react';

import { GoogleAdsBaseViewProps } from '@app/types/googleTypes';
import { Section } from './GoogleAdsSections';

const GoogleAdsStandard = React.memo(({ slotId, animated, Container }: GoogleAdsBaseViewProps): React.ReactElement => (
  Container ? (<Container id={slotId} />) : (<Section id={slotId} animated={animated} />)
));

export default GoogleAdsStandard;
