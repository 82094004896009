import React from 'react';
import { Switch, RouteProps } from 'react-router-dom';
import { useIntl } from 'react-intl';
import isArray from 'lodash/isArray';

const LocalizedSwitch: React.FC = ({ children }) => {
  const { formatMessage, locale } = useIntl();

  const localizeRoutePath = (path?: string | string[]): string | undefined | string[] => {
    if (!path) return;

    const localize = (id: string): string => `/${locale}${formatMessage({ id })}`;

    if (isArray(path)) {
      return path.map((key) => localize(key));
    }

    return path === '*' ? path : localize(path);
  };

  return (
    <Switch>
      {React.Children.map(children, (child) => (
        /** Check if children element is <Route /> component. If it is a Route localize path */
        React.isValidElement<RouteProps>(child)
          ? React.cloneElement(child, {
            ...child.props,
            path: localizeRoutePath(child.props.path),
          })
          : child
      ))}
    </Switch>
  );
};

export default LocalizedSwitch;
