import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { ForwardStatistics } from '@app/types/teamsTypes';

import {
  ValueAndCaption, BlockContainerColumn, StatsBlock, StatsBlockColumn, StatsColumn, StatsColumns, ValueAndGraphInline,
  CircleCaption, Caption, FieldInsideImage, FieldOutsideImage,
} from '@app/components/Statistics';
import { CircleGraph } from '@app/components/Graphs/CircleGraph';
import {
  DisciplineStatistics,
  DuelsStatistics,
  PassesStatistics,
} from '@app/pages/PlayerProfile/Profiles/PlayerPositionStatistics/StatisticsSections';

interface ForwardStatisticsProps {
  statistics?: ForwardStatistics;
}

const ForwardPlayerStatistics = ({ statistics }: ForwardStatisticsProps): React.ReactElement => (
  <BlockContainerColumn>
    <StatsBlock borderWidth="0 0 1px 0">
      <StatsBlock marginBottom="25px">
        <ValueAndCaption value={statistics?.goals ?? 0} caption="statistics.goals" />
      </StatsBlock>
      <OnBlock><FormattedMessage id="statistics.on" /></OnBlock>
      <StatsBlock marginBottom="25px">
        <ValueAndCaption value={statistics?.totalShots ?? 0} caption="statistics.shots" />
      </StatsBlock>
    </StatsBlock>
    <StatsBlock marginTop="25px" marginBottom="25px">
      <StatsBlock>
        <ValueAndCaption value={statistics?.rightFootGoals ?? 0} caption="statistics.rightFoot" isSmall />
      </StatsBlock>
      <StatsBlock>
        <ValueAndCaption value={statistics?.leftFootGoals ?? 0} caption="statistics.leftFoot" isSmall />
      </StatsBlock>
      <StatsBlock><ValueAndCaption value={statistics?.headedGoals ?? 0} caption="statistics.header" isSmall /></StatsBlock>
    </StatsBlock>
    <StatsBlock marginTop="25px" alignItems="flex-start">
      <StatsBlockColumn borderWidth="0 1px 0 0">
        <StatsBlock marginBottom="33px">
          <StatsBlock>
            <FieldInsideImage />
          </StatsBlock>
          <StatsBlock>
            <FieldOutsideImage />
          </StatsBlock>
        </StatsBlock>
        <StatsBlock marginBottom="18px">
          <StatsBlock>
            <CircleGraph
              width={65}
              height={65}
              value={(
                statistics?.goalsFromInsideBox && statistics?.goals
                  ? (statistics.goalsFromInsideBox / statistics.goals)
                  : 0
              )}
            >
              <CircleCaption>{statistics?.goalsFromInsideBox ?? 0}</CircleCaption>
            </CircleGraph>
          </StatsBlock>
          <StatsBlock>
            <CircleGraph
              width={52}
              height={52}
              value={(
                statistics?.goalsFromOutsideBox && statistics?.goals
                  ? (statistics.goalsFromOutsideBox / statistics.goals)
                  : 0
              )}
            >
              <CircleCaption>{statistics?.goalsFromOutsideBox ?? 0}</CircleCaption>
            </CircleGraph>
          </StatsBlock>
        </StatsBlock>
        <Caption><FormattedMessage id="statistics.goalsRepartition" /></Caption>
      </StatsBlockColumn>
      <StatsBlockColumn>
        <ValueAndGraphInline
          caption="statistics.accuracy"
          progress={
            statistics?.shotsOnTarget && statistics?.totalShots
              ? ((statistics.shotsOnTarget * 100) / statistics.totalShots)
              : 0
          }
          value={
            statistics?.shotsOnTarget && statistics?.totalShots
              ? `${Math.round((statistics.shotsOnTarget * 100) / statistics.totalShots)}%`
              : '0%'
          }
        />
        <ValueAndGraphInline
          caption="statistics.onTarget"
          progress={
            statistics?.shotsOnTarget && statistics?.totalShots
              ? ((statistics.shotsOnTarget * 100) / statistics.totalShots)
              : 0
          }
          value={statistics?.shotsOnTarget ?? 0}
        />
        <ValueAndGraphInline
          caption="statistics.offTarget"
          progress={
            statistics?.shotsOffTarget && statistics?.totalShots
              ? ((statistics.shotsOffTarget * 100) / statistics.totalShots)
              : 0
          }
          value={statistics?.shotsOffTarget ?? 0}
        />
        <ValueAndGraphInline
          caption="statistics.blocked"
          progress={
            statistics?.blockedShots && statistics?.totalShots
              ? ((statistics.blockedShots * 100) / statistics.totalShots)
              : 0
          }
          value={statistics?.blockedShots ?? 0}
        />
      </StatsBlockColumn>
    </StatsBlock>
  </BlockContainerColumn>
);

const ForwardLayoutStatistics = ({ statistics }: ForwardStatisticsProps): React.ReactElement => (
  <StatsColumns>
    <ForwardPlayerStatistics statistics={statistics} />
    <StatsColumn>
      <PassesStatistics statistics={statistics} />
      <DuelsStatistics statistics={statistics} />
      <DisciplineStatistics statistics={statistics} />
    </StatsColumn>
  </StatsColumns>
);

const OnBlock = styled.div`
  font-size: 18px;
  font-style: italic;
  letter-spacing: -0.01px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;

export default ForwardLayoutStatistics;
