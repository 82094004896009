import React from 'react';
import styled from 'styled-components';

import { WebHomeLanding } from '@app/types/webTemplateTypes';
import PageTitle from '@app/components/PageTitle';
import WebTemplateSelect from '../WebTemplateSelect';
import { ContainerLarge } from '../components';

interface WebHomeLandingElementProps {
  data: WebHomeLanding;
}

const WebHomeLandingElement = ({ data }: WebHomeLandingElementProps): React.ReactElement | null => (
  <ContainerLarge>
    {data?.title && (<PageTitleWithoutPadding>{data?.title}</PageTitleWithoutPadding>)}
    <Content>
      {data?.content?.map((content) => content !== null && (
        <WebTemplateSelect data={content} key={content.id} />
      ))}
    </Content>
  </ContainerLarge>
);


const Content = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
`;

const PageTitleWithoutPadding = styled(PageTitle)`
    padding-top: 0;
`;

export default WebHomeLandingElement;
