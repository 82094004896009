export const determineOverflow = (content, container): string => {
  const containerMetrics = container.getBoundingClientRect();
  const containerMetricsRight = Math.floor(containerMetrics.right);
  const containerMetricsLeft = Math.floor(containerMetrics.left);
  const contentMetrics = content.getBoundingClientRect();
  const contentMetricsRight = Math.floor(contentMetrics.right);
  const contentMetricsLeft = Math.floor(contentMetrics.left);
  if (containerMetricsLeft > contentMetricsLeft && containerMetricsRight < contentMetricsRight) {
    return 'both';
  } if (contentMetricsLeft < containerMetricsLeft) {
    return 'left';
  } if (contentMetricsRight > containerMetricsRight) {
    return 'right';
  }
  return 'none';
};
