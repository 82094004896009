import React from 'react';
import styled from 'styled-components';
import { Devices } from '@app/styles';

type PlayerMetadata = React.FC<{
  number: string;
  firstName: string;
  lastName: string;
  isSmall?: boolean;
}>

const PlayerMetadata: PlayerMetadata = ({
  number, firstName, lastName, isSmall = false,
}): React.ReactElement => (
  <InfoSmall>
    <Number isSmall={isSmall}>{number}</Number>
    <Devider isSmall={isSmall} />
    <Name>
      <FirstName isSmall={isSmall}>{firstName}</FirstName>
      <LastName isSmall={isSmall}>{lastName}</LastName>
    </Name>
  </InfoSmall>
);

const InfoSmall = styled.h1`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: ${(props): string => props.theme.fontFamily.milan};
`;

const Number = styled.div<{isSmall: boolean}>`
  font-size: ${({ isSmall }): string => (isSmall ? '38px' : '48px')};
  font-weight: bold;
  line-height: 0.53;
  text-align: right;
  color: #ffffff;

  @media ${Devices.largeMobile} {
    font-size: ${({ isSmall }): string => (isSmall ? '38px' : '68px')};
  }
`;

const Devider = styled.span<{isSmall: boolean}>`
  width: ${({ isSmall }): string => (isSmall ? '2px' : '4px')};
  margin: 0  ${({ isSmall }): string => (isSmall ? '10px' : '18px')};
  height: ${({ isSmall }): string => (isSmall ? '20px' : '37px')};
  background-color: ${(props): string => props.theme.color.freeSpeechRed};
`;

const Name = styled.div`
  font-size: 32px;
  color: #ffffff;
  line-height: 1.2;
`;

const LastName = styled.div<{isSmall: boolean}>`
  font-size: ${({ isSmall }): string => (isSmall ? '22px' : '32px')};

  @media ${Devices.largeMobile} {
    font-size: ${({ isSmall }): string => (isSmall ? '22px' : '35px')};
  }
`;

const FirstName = styled.div<{isSmall: boolean}>`
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: ${({ isSmall }): string => (isSmall ? '10px' : '16px')};
  font-weight: normal;

  @media ${Devices.largeMobile} {
    font-size: ${({ isSmall }): string => (isSmall ? '10px' : '18px')};
  }
`;


export default PlayerMetadata;
