import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { WebCtaDuoButton } from '@app/types/webTemplateTypes';
import { ContainerLarge } from '@app/components/WebTemplates/components';
import { WebCtaImageElement } from '@app/components/WebTemplates/elements';

interface WebCtaDuoButtonElementProps {
  data: WebCtaDuoButton;
}

const WebCtaDuoButtonElement = ({ data }: WebCtaDuoButtonElementProps): React.ReactElement => (
  <Container>
    {data?.content?.map((content) => (
      <WebCtaImageElement data={content} key={content.id} />
    ))}
  </Container>
);

export default WebCtaDuoButtonElement;

const Container = styled(ContainerLarge)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  & > * {
    margin: 0 0 20px 0;

    &:last-of-type {
      margin: 0;
    }
  }


  @media ${Devices.largeMobile} {
    flex-direction: row;

    & > * {
      margin: 0 20px 0 0;
    }
  }
`;
