import { TeamStatistics, SetTeamPayload, TeamNames } from '@app/types/teamsTypes';
import { TeamSubMenuId } from '@app/types/configurationTypes';

export enum TeamsActionTypes {
  SET_TEAM = 'TEAMS/SET_TEAM',
  RESET_ALL_TEAMS = 'TEAMS/RESET_ALL_TEAMS',
  SET_TEAM_NAMES = 'TEAMS/SET_TEAM_NAMES',
  SET_TEAM_STATISTICS = 'TEAMS/SET_TEAM_STATISTICS',
  RESET_ALL_TEAMS_STATISTICS = 'TEAMS/RESET_ALL_TEAMS_STATISTICS',
}

interface ActionTypes {
  type: TeamsActionTypes;
  payload?: SetTeamPayload | { type: TeamSubMenuId; statistics: TeamStatistics } | TeamNames;
}

export interface SetTeam extends ActionTypes {
  type: TeamsActionTypes.SET_TEAM;
  payload: SetTeamPayload;
}

export interface ResetAllTeams extends ActionTypes {
  type: TeamsActionTypes.RESET_ALL_TEAMS;
}

export interface SetTeamStatistics extends ActionTypes {
  type: TeamsActionTypes.SET_TEAM_STATISTICS;
  payload: { type: TeamSubMenuId; statistics: TeamStatistics };
}

export interface SetTeamNames extends ActionTypes {
  type: TeamsActionTypes.SET_TEAM_NAMES;
  payload: TeamNames;
}

export interface ResetAllTeamsStatistics extends ActionTypes {
  type: TeamsActionTypes.RESET_ALL_TEAMS_STATISTICS;
}

export type TeamsActions = SetTeam
  | ResetAllTeams
  | SetTeamNames
  | SetTeamStatistics
  | ResetAllTeamsStatistics;
