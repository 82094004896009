import * as React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { getStaticImageConfig } from '@app/helpers/imageHelper';
import { HallOfFamePlayer } from '@app/types/hallOfFameTypes';
import AppRoutes from '@app/constants/routes';
import * as Translations from '@app/locales';

import { theme } from '@app/styles';
import { PlayerMetadata } from '@app/components';
import Image from '@app/components/Image';
import { HOFInducteeLink } from '@app/components/WebTemplates/hall-of-fame/hof.atoms/hof.inductee.link';
import { ColorizedButtonLink, ColorizedButton } from '@app/components/Buttons/ColorizedButton';
import { useLanguage } from '@app/components/Hooks';

interface PlayerCardProps {
  player: HallOfFamePlayer;
}

const HallOfFamePlayerCard = ({ player }: PlayerCardProps): React.ReactElement => {
  const photo = player.smallPhoto;
  const { topLevel = '', secondLevel = '' } = useParams();
  const language = useLanguage();

  return (
    <Container>
      <PhotoContainer>
        <Photo
          src={photo?.url}
          additionalImageProps={getStaticImageConfig({ url: photo?.url, width: 290, height: 242 })}
          description={photo?.description}
          useLazyLoading
        />
        <Gradient />
      </PhotoContainer>
      <MetadataContainer>
        <PlayerMetadata
          firstName={player.firstName}
          lastName={player.lastName}
          number={player.number}
          isSmall
        />
      </MetadataContainer>
      <ButtonContainer>
        {player?.inductee?.length ? (
          <HOFInducteeLink slug={player.inductee}>
            <ColorizedButton color={theme.color.freeSpeechRed}>
              <FormattedMessage id="hallOfFame.button" />
            </ColorizedButton>
          </HOFInducteeLink>
        ) : (
          <ColorizedButtonLink
            pathKey={AppRoutes.WebTemplateTab.path}
            pathParams={{
              topLevel,
              secondLevel,
              categoryName: Translations[language]?.['hallOfFame.playerThirdLevelUrl'].toLowerCase(),
              tab: player.urlSlug,
            }}
            color={theme.color.freeSpeechRed}
          >
            <FormattedMessage id="hallOfFame.button" />
          </ColorizedButtonLink>
        )}
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 290px;
  height: 242px;
  position: relative;
  margin: 0 25px 25px 0;
  color: white;
`;

const PhotoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Photo = styled(Image)`
  width: 100%;
  height: 100%;
`;

const Gradient = styled.div`
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 44%, #000000 84%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

const MetadataContainer = styled.div`
  position: relative;
  z-index: 10;
  padding: 0 40px;
`;

const ButtonContainer = styled.div`
  width: 160px;
  position: relative;
  z-index: 10;
  margin: 20px 0 30px;
`;

export default HallOfFamePlayerCard;
