import { sendRequestTyped } from '@app/services/kentico/request';
import { langCodeToKenticoLangMap } from '@app/constants/localizationConstants';

import { LanguageType } from '@app/types/localizationTypes';
import { Competitions } from '@app/types/configurationTypes';
import { Game } from '@app/types/matchTypes';
import { KenticoGameElement, KenticoGamesModularContent } from './types/gamesTypes';

import { convertGames } from './converters/gamesConverter';

export const getGames = async (language: LanguageType, competitions: Competitions): Promise<Game[]> => {
  const response = await sendRequestTyped<KenticoGameElement, KenticoGamesModularContent>({
    params: {
      'system.type': 'game',
      language: langCodeToKenticoLangMap[language],
      'system.language': langCodeToKenticoLangMap[language],
    },
  });

  return convertGames(response, competitions);
};
