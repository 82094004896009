import styled from 'styled-components';

import { Devices } from '@app/styles';

export const Section = styled.section<{ animated?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  -webkit-animation: ${({ animated }): string => (animated ? 'fade-in-fwd 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both' : '')};
  animation: ${({ animated }): string => (animated ? 'fade-in-fwd 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both' : '')};
`;

export const Slot = styled(Section)`
  -webkit-animation: fade-in-fwd 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-fwd 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
`;

export const Text = styled.section<{ color?: string }>`
  text-align: center;
  text-transform: uppercase;
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: 28px;
  color: ${({ color }): string => (color ?? 'white')};

  letter-spacing: 0.06px;
  line-height: 30px;

  width: 100%;

  margin: auto;
  padding: 0 10px 40px;

  flex: 0 0 100%;
  z-index: 1000;
`;

export const NoText = styled.span`
  position: absolute;

  width: 0;
  height: 0;

  opacity: 0;
  z-index: -1;
`;

export const Background = styled.section<{ color?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  z-index: 998;
  background-color: ${({ color }): string => (color ?? 'black')};
`;

export const HelloBar = styled.div<{ preview: boolean }>`
  position: fixed;
  top: unset;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  overflow: hidden;
  padding: 40px 20px;
  color: white;

  z-index: ${({ preview }): number => (preview ? -9999 : 9990)};
  opacity: ${({ preview }): number => (preview ? 0 : 1)};
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation-delay: 1s;

  & > button {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 999;
  }

  & > ${Section} {
    width: 100%;
    flex: 0 0 100%;
    z-index: 1000;
    transition: all 0.5s ease-in;
  }

  @media ${Devices.tablet} {
    padding: 40px;
  }
`;
