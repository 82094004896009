import styled from 'styled-components';
import { CommonButtonLink } from './CommonButton';

export const MatchCenterButton = styled(CommonButtonLink)`
  font-size: 11px;
  transition: all 0.3s ease;
  width: auto;
  min-width: 95px;
  background: transparent;
  color: ${(props): string => props.theme.color.freeSpeechRed};
  border: 1px solid ${(props): string => props.theme.color.freeSpeechRed};

  &:hover {
    transition: all 0.3s ease;
    background: ${(props): string => props.theme.color.freeSpeechRed};
    color: white;
    border: 1px solid ${(props): string => props.theme.color.freeSpeechRed};
  }
`;
