import React from 'react';
import styled from 'styled-components';

import { getResponsiveImageConfig } from '@app/helpers/imageHelper';
import { pushGAEvent } from '@app/helpers/googleHelpers';
import { GA_CATEGORY, GA_EVENT } from '@app/types/googleTypes';
import { HomePhotoGalleryConfiguration } from '@app/constants/carouselConfigurationsConstants';
import { BaseNewsItem, NewsTypes } from '@app/types/newsTypes';
import { GetResponsiveImageConfig } from '@app/types/imageTypes';

import { DevicesSize } from '@app/styles';

import {
  useLatestPhotosHook,
  useLanguage,
  useLatestNewsLinkParams,
} from '@app/components/Hooks';
import { GalleryItemOverlapped } from '@app/components';
import { CarouselBase } from '@app/components/Carousel';
import HomeSection from './HomeSection';
import { BlackSection } from './HomeStyledComponents';

const GalleryItem = (props): React.ReactElement => {
  const language = useLanguage();

  return (
    <GalleryItemOverlapped
      {...props}
      onClick={({ title }: BaseNewsItem): void => pushGAEvent({
        event: GA_EVENT.CLICK_HOME,
        category: GA_CATEGORY.PHOTOS,
        label: title,
        language,
      })}
    />
  );
};

const HomePhotoGallery: React.FC = () => {
  const language = useLanguage();
  const latestPhotos = useLatestPhotosHook();
  const latestGalleriesLinkParams = useLatestNewsLinkParams(NewsTypes.PICTURES);

  const getResponsiveImageConfiguration: GetResponsiveImageConfig = (url) => (
    getResponsiveImageConfig(url, { 835: 290, 376: 425, 0: 290 })
  );

  return (
    <Container>
      <HomeSection
        sectionNameId="news.photoGallery"
        linkData={{
          linkParams: latestGalleriesLinkParams,
          linkNameId: 'home.seeMore',
          onClick: (): void => pushGAEvent({
            event: GA_EVENT.CLICK_HOME,
            category: GA_CATEGORY.PHOTOS,
            label: 'see_more',
            language,
          }),
        }}
        isDarkTheme
      >
        <CarouselBase
          ItemComponent={GalleryItem}
          items={latestPhotos}
          configuration={HomePhotoGalleryConfiguration}
          getResponsiveImageConfig={getResponsiveImageConfiguration}
        />
      </HomeSection>
    </Container>
  );
};

const Container = styled(BlackSection)`
  @media (max-width: ${DevicesSize.desktop}px) {
    padding-right: 0;
  }
`;

export default HomePhotoGallery;
