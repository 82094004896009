import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { GalleryCarousel } from '@app/components';
import { useGameByOptaIdSelector, useGameByUrlSlugSelector } from '../MatchCenterHooks';
import MatchStatus from './MatchStatus';

const MatchHeader = ({ gameId = '' }): React.ReactElement | null => {
  const gameByUrlSlug = useGameByUrlSlugSelector(); // default game selector for Match Center page
  const gameByOptaId = useGameByOptaIdSelector(gameId); // apply when Match Header is used like a widget on other pages
  const game = gameId ? gameByOptaId : gameByUrlSlug;

  return (
    <Header>
      {!!game?.photos.length && <section><GalleryCarousel preview={game} /></section>}
      <MatchStatus game={game} isCtaVisible={!!gameId} />
    </Header>
  );
};

export default MatchHeader;

const Header = styled.div`
  display: flex;
  flex-direction: column-reverse;
  background-color: black;

  & > section {
    width: 100%;
  }

  @media ${Devices.tabletLarge} {
    flex-direction: row;
  }
`;
