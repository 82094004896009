import React from 'react';
import { FormattedDate } from 'react-intl';
import styled from 'styled-components';

import { GoogleAdsMap } from '@app/constants/googleAdsConstants';
import { GoogleAds, MatchDay, RedBoxTitle } from '@app/components';
import { useResultsSelector } from '../ScheduleHooks';

interface MatchMonthProps {
  matchDate: string;
  showAds: boolean;
}
const MatchMonth = ({ matchDate, showAds }: MatchMonthProps): React.ReactElement => {
  const results = useResultsSelector(matchDate);

  return (
    <Section id={matchDate}>
      <RedBoxTitle>
        <FormattedDate
          value={new Date(matchDate)}
          year="numeric"
          month="long"
        />
      </RedBoxTitle>
      { results.map((result) => <MatchDay key={result.matchDate} result={result} />) }
      { showAds && (
        <AdContainer>
          <GoogleAds {...GoogleAdsMap.schedule} animated />
        </AdContainer>
      )}
    </Section>
  );
};

export default MatchMonth;

const Section = styled.section`
  padding-bottom: 40px;
`;

const AdContainer = styled.div`
  & iframe {
    margin-top: 40px;
  }
`;
