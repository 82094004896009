import * as React from 'react';
import styled from 'styled-components';

import { Coach } from '@app/types/teamsTypes';

import { getStaticImageConfig } from '@app/helpers/imageHelper';

import { useLanguage } from '@app/components/Hooks';

import { InfoLine } from '@app/pages/PlayerProfile/Profiles/PlayerBiography';
import { format, isValid, parseISO } from 'date-fns';
import { DateFormatMap } from '@app/constants/dateTimeConstants';
import { mapLanguageTypeToDateFnsLocale } from '@app/helpers/localizationHelper';
import { Devices } from '@app/styles';

interface CoachCardProps {
  item: Coach;
}

export const CoachCard = ({ item }: CoachCardProps): React.ReactElement => {
  const language = useLanguage();

  const parsedBirthDate = parseISO(item?.birthDate ?? '');
  const birthData = isValid(parsedBirthDate) ? format(parsedBirthDate, DateFormatMap[language], {
    locale: mapLanguageTypeToDateFnsLocale(language),
  }) : '';

  const parsedJoinDate = parseISO(item?.joinDate ?? '');
  const joinDate = isValid(parsedJoinDate) ? format(parsedJoinDate, 'yyyy') : '';

  return (
    <Container>
      <ContainerData>
        <Photo>
          <img
            {...getStaticImageConfig({ url: item?.photoLarge?.url, width: 220 })}
            alt={item.photoLarge.description}
          />
        </Photo>
        <Data>
          <FirstName>{item.firstName}</FirstName>
          <LastName>{item.lastName}</LastName>

          <InfoLine message="player.biography.birthDate" value={birthData} />
          <InfoLine message="player.biography.birthPlace" value={item?.birthPlace} />
          <InfoLine message="player.biography.nationality" value={item?.nationality} />
          <InfoLine message="player.biography.coachSince" value={item?.coachSince} />
          <InfoLine message="player.biography.atMilanSince" value={joinDate} />
        </Data>
      </ContainerData>
      <Biography>
        <BiographyTitle>{item?.careerTitle}</BiographyTitle>
        <BiographyData dangerouslySetInnerHTML={{ __html: item?.career }} />
        <BiographyTitle>{item?.biographyTitle}</BiographyTitle>
        <BiographyData dangerouslySetInnerHTML={{ __html: item?.biography }} />
      </Biography>
    </Container>
  );
};

const Container = styled.li`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  color: #000000;
  letter-spacing: 0.21px;
  background-color: #ffffff;
  padding: 20px;

  @media ${Devices.desktop} {
    flex-direction: row;
  }
`;

const ContainerData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  @media ${Devices.desktop} {
    width: 50%;
  }
  @media ${Devices.tablet} {
    flex-direction: row;
  }
`;

const Photo = styled.div`
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;

  @media ${Devices.tablet} {
    margin-right: 20px;
    justify-content: flex-start;
    width: fit-content;
  }

  & > img {
    object-fit: contain;
    align-self: flex-start;
  }
`;

const Data = styled.div`
  flex: 1;
  width: 100%;

  @media ${Devices.tablet} {
    width: 50%;
  }
`;

const Biography = styled.div`
  width: 100%;

  @media ${Devices.desktop} {
    width: 50%;
  }
`;


const NoMarginH2 = styled.h2`
  margin: 0;
`;


const FirstName = styled(NoMarginH2)`
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 20px;
  font-weight: 300;

  @media ${Devices.desktop} {
    font-size: 31.3px;
  }
`;

const LastName = styled(NoMarginH2)`
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 30px;
  font-weight: 500;
  padding-bottom: 10px;

  @media ${Devices.desktop} {
    font-size: 45px;
  }
`;

const BiographyTitle = styled.h3`
  margin: 0 0 10px 0;
  text-transform: uppercase;
`;

const BiographyData = styled.div`
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 20px;
`;
