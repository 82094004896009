import React from 'react';
import { useIntl, FormattedDate } from 'react-intl';
import styled from 'styled-components';

import { GA_EVENT, GA_CATEGORY } from '@app/types/googleTypes';
import { Devices } from '@app/styles';
import { HorizontalScroll } from '@app/components';
import { scrollToElement } from '@app/helpers/documentHelpers';
import { pushGAEvent } from '@app/helpers/googleHelpers';
import {
  useIsLoadingSelector,
  useCompetitionIdSelector,
  useSeasonIdActiveStateCheck,
  useSyncCalendarUrlSelector,
  useIsMenSquadAllCompetitionsSelected,
} from '@app/pages/Season/Season/SeasonHooks';
import { useLanguage } from '@app/components/Hooks';
import { isOptaSDCompetition } from '@app/services/opta/helpers/competitionHelpers';
import { useCalendarSelector } from '../ScheduleHooks';

const MatchCalendar = (): React.ReactElement => {
  const { formatMessage } = useIntl();
  const language = useLanguage();

  const calendar = useCalendarSelector();
  const syncCalendarUrl = useSyncCalendarUrlSelector();

  const isLoading = useIsLoadingSelector();
  const isSeasonIdActive = useSeasonIdActiveStateCheck();
  const isAllCategorySelected = useIsMenSquadAllCompetitionsSelected();
  const isOptaSD = isOptaSDCompetition(useCompetitionIdSelector());
  const useCalendar = isSeasonIdActive || isAllCategorySelected || isOptaSD;

  return (
    <Container>
      <HorizontalScrollWrapper>
        <HorizontalScroll color="white">
          <Calendar>
            {calendar.map((date) => {
              const month = new Date(date);
              const today = new Date();

              const matchDateFormatted = `${month.getMonth()}-${month.getFullYear()}`;
              const todayDateFormatted = `${today.getMonth()}-${today.getFullYear()}`;

              return (
                <Month
                  key={date}
                  onClick={(): void => scrollToElement(date, true)}
                  isSelected={useCalendar && matchDateFormatted === todayDateFormatted}
                >
                  <FormattedDate value={month} month="short" />
                </Month>
              );
            })}
          </Calendar>
        </HorizontalScroll>
      </HorizontalScrollWrapper>
      <AddToCalendar
        // isVisible={false}
        isVisible={!!syncCalendarUrl.length && isSeasonIdActive && !isLoading}
        href={syncCalendarUrl}
        onClick={(): void => pushGAEvent({
          event: GA_EVENT.CLICK_ADD_CALENDAR,
          category: GA_CATEGORY.SCHEDULE,
          language,
        })}
        target="_blank"
      >
        <span>{formatMessage({ id: 'season.addToCalendar' })}</span>
        <img src="/images/calendar.png" alt={formatMessage({ id: 'season.addToCalendar' })} />
      </AddToCalendar>
    </Container>
  );
};

export default MatchCalendar;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 940px;
  height: 40px;
  padding: 0 10px;
  margin: auto;
  background-color: black;
`;

const HorizontalScrollWrapper = styled.div`
  width: calc(100% - 60px);
  height: inherit;
`;

const Calendar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 auto;
  height: 100%;
`;

const AddToCalendar = styled.a<{ isVisible: boolean }>`
  display: ${({ isVisible }): string => (isVisible ? 'flex' : 'none')};
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  font-family: ${(props): string => props.theme.fontFamily.raleway};

  & span {
    display: none;
  }

  @media ${Devices.desktop} {
    justify-content: flex-end;
    align-items: center;
    flex: 0 0 200px;

    & span {
      display: inline;
    }
  }

  & img {
    display: inline-block;
    width: 28px;
    height: 28px;
    margin-left: 20px;
  }
`;

const Month = styled.span<{ isSelected: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  color: white;
  opacity: ${({ isSelected }): number => (isSelected ? 1 : 0.6)};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  line-height: normal;
  letter-spacing: 0.2px;
  margin-right: 20px;
  text-transform: uppercase;
  transition: all 0.3s ease;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    opacity: 1;
    cursor: pointer;
    transition: all 0.3s ease;

    &:after {
      opacity: 1;
      transition: all 0.3s ease;
    }
  }

  &:after {
    opacity: ${({ isSelected }): number => (isSelected ? 1 : 0)};
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    border-bottom: 3px solid;
    transition: all 0.3s ease;
  }
`;
