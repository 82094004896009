import trimEnd from 'lodash/trimEnd';

import { localizeRouteKey } from '@app/helpers/localizationHelper';
import { getBreadcrumbsListSchema } from '@app/helpers/structuredMarkupHelpers';
import { BreadcrumbsListSchema } from '@app/types/structuredMarkup';
import { NavigationLinkParams } from '@app/types/routerTypes';

import { useSeoData } from '@app/pages/Home/HomeHooks';
import { useLanguage } from '@app/components/Hooks';

type UseBreadcrumbsJsonLD = (
  pageTitle: string,
  secondLevelPageData?: {
    pageTitle: string;
    linkParams: NavigationLinkParams;
  }
) => BreadcrumbsListSchema

export const useBreadcrumbsJsonLD: UseBreadcrumbsJsonLD = (pageTitle, secondLevelPageData) => {
  const language = useLanguage();
  const homeSeoData = useSeoData();

  const pagesList = [
    {
      url: trimEnd(`${window?.HOST_URL || window?.location?.origin}${localizeRouteKey({ pathKey: 'routes.home' }, language)}`, '/'),
      pageName: homeSeoData.seo?.title ?? '',
    },
    {
      url: window?.REQUEST_URL || window?.location?.href,
      pageName: pageTitle,
    },
  ];

  if (secondLevelPageData) {
    pagesList.splice(1, 0, {
      url: `${window?.HOST_URL || window?.location?.origin}${localizeRouteKey(secondLevelPageData.linkParams, language)}`,
      pageName: secondLevelPageData.pageTitle,
    });
  }
  return getBreadcrumbsListSchema(pagesList);
};
