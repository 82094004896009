import { combineReducers, Reducer } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';

import news, { NewsState } from './newsReducer';
import configuration, { ConfigurationState } from './configurationReducer';
import season, { SeasonState } from './seasonReducer';
import matchCenter, { matchCenterState } from './matchReducer';
import currentRoute, { CurrentRouteState } from './currentRouteReducer';
import teams, { TeamsState } from './teamsReducer';
import ticketing, { TicketingState } from './ticketingReducer';
import webTemplate, { WebTemplateState } from './webTemplateReducer';
import home, { HomeState } from './homeReducer';
import user, { UserState } from './userReducer';
import question, { LocalizedQuestion } from './questionReducer';
import hallOfFame, { LocalizedHallOfFame } from './hallOfFameReducer';
import google, { GoogleState } from './googleReducer';
import search, { SearchState } from './searchReducer';

export default (history: History): Reducer<AppState> => combineReducers({
  router: connectRouter(history),
  news,
  search,
  season,
  matchCenter,
  configuration,
  currentRoute,
  teams,
  ticketing,
  webTemplate,
  home,
  user,
  google,
  question,
  hallOfFame,
});

export interface AppState {
  router: RouterState;
  news: NewsState;
  search: SearchState;
  season: SeasonState;
  matchCenter: matchCenterState;
  configuration: ConfigurationState;
  currentRoute: CurrentRouteState;
  teams: TeamsState;
  ticketing: TicketingState;
  webTemplate: WebTemplateState;
  home: HomeState;
  user: UserState;
  google: GoogleState;
  question: LocalizedQuestion;
  hallOfFame: LocalizedHallOfFame;
}
