import { AppState } from '@app/store/reducers';
import { LanguageType } from '@app/types/localizationTypes';
import { HallOfFamePlayer } from '@app/types/hallOfFameTypes';

type GetPlayer = (params: {
  state: AppState;
  playerUrlSlug: string;
  language: LanguageType;
}) => HallOfFamePlayer | undefined

export const getPlayer: GetPlayer = ({ state, playerUrlSlug, language }) => (
  state.hallOfFame[language]?.find(({ urlSlug }) => urlSlug === playerUrlSlug)
);
