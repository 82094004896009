import React from 'react';

import { GA_CATEGORY, GA_EVENT } from '@app/types/googleTypes';
import { BaseNewsItem, NewsListItems } from '@app/types/newsTypes';
import { LayoutConfigurationItem } from '@app/types/configurationTypes';

import { pushGAEvent } from '@app/helpers/googleHelpers';
import { useLanguage } from '@app/components/Hooks';
import {
  ArticleItem, TwoColumnGrid, ThreeColumnGrid, TwoThreeColumnGrid, LoadMoreButton,
} from '@app/components/index';

interface NewsListComponent {
  items: NewsListItems;
  hasMoreData: boolean;
  isLoadingData: boolean;
  layoutConfiguration: LayoutConfigurationItem;
}

type ArticlesList = React.FC<{
  articles: NewsListComponent;
  dataRequest: () => void;
  categoryName?: string;
}>

const ArticlesList: ArticlesList = ({ articles, dataRequest, categoryName }) => {
  const language = useLanguage();
  const {
    items, hasMoreData, layoutConfiguration, isLoadingData,
  } = articles;

  const gridProperties = {
    ItemComponent: ArticleItem,
    items,
    itemAdditionalProps: {
      onClick: (item: BaseNewsItem): void => {
        if (!categoryName) return;

        pushGAEvent({
          event: GA_EVENT.CLICK_NEWS,
          category: GA_CATEGORY.NEWS,
          action: categoryName,
          label: item?.title,
          language,
        });
      },
    },
  };

  const MultiColumnGrid = (): React.ReactElement => {
    switch (layoutConfiguration?.itemsToDownload) {
      case 8: return (<TwoColumnGrid {...gridProperties} />);
      case 10: return (<TwoThreeColumnGrid {...gridProperties} />);
      case 12: default: return (<ThreeColumnGrid {...gridProperties} />);
    }
  };

  return (
    <>
      <MultiColumnGrid />
      {hasMoreData && (
        <LoadMoreButton onClick={dataRequest} isLoading={isLoadingData} />
      )}
    </>
  );
};


export default ArticlesList;
