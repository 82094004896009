import {
  addHours, format, isValid, parseISO,
} from 'date-fns';

import {
  WebSiteSchema,
  OrganizationSchema,
  PersonSchema,
  NewsArticleSchema,
  BreadcrumbsListSchema,
  SportEventSchema,
} from '@app/types/structuredMarkup';
import { MatchTicketsInfo } from '@app/types/ticketingTypes';
import { LanguageType } from '@app/types/localizationTypes';

export const getWebSiteSchema = (): WebSiteSchema => ({
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: 'AC Milan',
  url: 'https://www.acmilan.com',
});

export const getOrganizationSchema = (): OrganizationSchema => ({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Associazione Calcio Milan',
  url: 'https://www.acmilan.com',
  sameAs: [
    'https://www.facebook.com/ACMilan',
    'https://twitter.com/acmilan',
    'https://www.youtube.com/acmilan',
    'https://www.instagram.com/acmilan/',
    'https://www.linkedin.com/company/ac-milan/',
    'https://www.weibo.com/acmilanofficial',
  ],
});

type GetPersonSchema = (params: {
  personPageUrl: string;
  imageUrl: string;
  name: string;
  surname: string;
  socialNetworkUrl?: string;
}) => PersonSchema;

export const getPersonSchema: GetPersonSchema = ({
  personPageUrl, imageUrl, name, surname, socialNetworkUrl = '',
}) => ({
  '@context': 'https://schema.org',
  '@type': 'Person',
  url: personPageUrl,
  image: imageUrl,
  name: `${name} ${surname}`,
  sameAs: socialNetworkUrl,
  memberOf: {
    '@type': 'Organization',
    name: 'Associazione Calcio Milan',
  },
});

type GetNewsArticleSchema = (params: {
  title: string;
  articleUrl: string;
  datePublished: string;
  dateModified?: string;
  photoUrl: string;
  photoDescription: string;
}) => NewsArticleSchema;

export const getNewsArticleSchema: GetNewsArticleSchema = ({
  title, articleUrl, datePublished, dateModified = '', photoUrl, photoDescription,
}) => ({
  '@context': 'https://www.schema.org',
  '@type': 'NewsArticle',
  name: title,
  url: articleUrl,
  publisher: {
    '@type': 'Organization',
    name: 'Associazione Calcio Milan',
    logo: {
      '@type': 'ImageObject',
      url: `${window?.location?.origin ?? window?.HOST_URL}/images/social/acmilan.png`,
    },
  },
  mainEntityOfPage: articleUrl,
  headline: title,
  datePublished,
  dateModified,
  author: {
    '@type': 'Person',
    name: 'AC Milan',
  },
  image: {
    '@type': 'ImageObject',
    url: photoUrl,
    description: photoDescription,
  },
});

type GetBreadcrumbsListSchema = (pagesList: {
  url: string;
  pageName: string;
}[]) => BreadcrumbsListSchema

export const getBreadcrumbsListSchema: GetBreadcrumbsListSchema = (pagesList) => {
  const itemListElement = pagesList.map(({ url, pageName }, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    item: {
      '@id': url,
      name: pageName,
    },
  }));

  return {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement,
  };
};

type GetSportEventSchema = (
  ticket: MatchTicketsInfo,
  language: LanguageType
) => SportEventSchema

export const getSportEventSchema: GetSportEventSchema = (ticket, language) => {
  const parsedMatchTime = parseISO(ticket?.matchTime ?? '');
  const startMatchTime = isValid(parsedMatchTime) ? format(parsedMatchTime, 'yyyy-MM-dd[T]HH:mm') : '';
  const endMatchTime = isValid(parsedMatchTime)
    ? format(addHours(parsedMatchTime, 2), 'yyyy-MM-dd[T]HH:mm')
    : '';
  const name = `${ticket.teams.home.name} - ${ticket.teams.away.name} | ${ticket.competition.name}`;

  return {
    '@context': 'https://schema.org',
    '@type': 'SportsEvent',
    name,
    startDate: startMatchTime,
    endDate: endMatchTime,
    location: {
      '@type': 'Place',
      name: 'Stadio San Siro',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Piazzale Angelo Moratti',
        addressLocality: 'Milano',
        postalCode: '20151',
        addressRegion: 'MI',
        addressCountry: 'IT',
      },
    },
    image: [
      'https://singletickets.acmilan.com/public/gallery/P85/maxi/gallery-new-5.jpg',
      'https://singletickets.acmilan.com/public/gallery/P85/maxi/gallery-new-8.jpg',
      'https://singletickets.acmilan.com/public/gallery/P85/maxi/gallery-new-3.jpg',
    ],
    description: ticket.scriptDescription ? ticket.scriptDescription : name,
    offers: {
      '@type': 'Offer',
      url: ticket.ticketsUrl[language],
      price: ticket.startingPrice ?? '',
      priceCurrency: 'EUR',
      availability: 'https://schema.org/InStock',
    },
    performer: {
      '@type': 'Organization',
      name: 'Associazione Calcio Milan',
    },
  };
};
