import { Question } from '@app/types/faqQuestionTypes';
import { KenticoFaqWebQuestionItem } from '@app/services/kentico/types/faqQuestionTypes';

export function convertQuestion(data: KenticoFaqWebQuestionItem): Question {
  return {
    id: data?.system?.id ?? '',
    question: data?.elements?.question?.value ?? '',
    answer: data?.elements?.answer?.value ?? '',
    urlSlug: data?.elements?.url?.value ?? '',
  };
}
