import { AdSlotStatus, AdBaseStatus } from '@app/types/googleTypes';

export enum GoogleActionTypes {
  SET_GOOGLE_AD_SLOT_STATUS = 'GOOGLE/SET_GOOGLE_AD_SLOT_STATUS',
  RESET_GOOGLE_AD_SLOT_STATUS = 'GOOGLE/RESET_GOOGLE_AD_SLOT_STATUS',
  SET_GOOGLE_PUBLISHER_TAGS_STATUS = 'GOOGLE/SET_GOOGLE_PUBLISHER_TAGS_STATUS',
}

export interface SetGoogleAdSlotStatus {
  type: GoogleActionTypes.SET_GOOGLE_AD_SLOT_STATUS;
  payload: AdSlotStatus;
}

export interface ResetGoogleAdSlotStatus {
  type: GoogleActionTypes.RESET_GOOGLE_AD_SLOT_STATUS;
  payload: string;
}

export interface SetGooglePublisherTagsStatus {
  type: GoogleActionTypes.SET_GOOGLE_PUBLISHER_TAGS_STATUS;
  payload: AdBaseStatus;
}

export type GoogleActions = SetGooglePublisherTagsStatus
  | SetGoogleAdSlotStatus
  | ResetGoogleAdSlotStatus;
