import React from 'react';
import styled from 'styled-components';

import { getLatestArticles, getLatestVideos } from '@app/store/actions/newsActions';
import { getStandings } from '@app/store/actions/seasonActions';
import { getHomeData } from '@app/store/actions/homeActions';
import { getTicketingSingle } from '@app/store/actions/ticketingActions';
import { getLocalizedGames } from '@app/store/actions/matchActions';

import { Devices } from '@app/styles';

import { getOrganizationSchema, getBreadcrumbsListSchema } from '@app/helpers/structuredMarkupHelpers';

import { useActiveSeasonIdForCompetitionSeriaA } from '@app/pages/Season/Season/SeasonHooks';
import { useMatchCenterButton } from '@app/pages/MatchCenter/MatchCenterHooks';
import { Metadata, MetaJsonLD } from '@app/components';
import HomeCarousel from './componets/HomeCarousel';
import HomeMatchCenter from './componets/HomeMatchCenter';
import { GraySection } from './componets/HomeStyledComponents';
import Standings from './componets/HomeSeason/HomeStandings';
import MatchBox from './componets/HomeSeason/MatchBox';
import {
  useSeasonData, useOrderedHomeBlocks, useSeoData, useIsOverlapped, useHomeMultiLangUrl, useGameId,
} from './HomeHooks';

const Home = (): React.ReactElement => {
  const gameId = useGameId();
  const isOverlapped = useIsOverlapped();
  const { standings, matches } = useSeasonData();
  const seasonId = useActiveSeasonIdForCompetitionSeriaA();
  const hasSeasonsInfo = Boolean(standings.length && matches.length);

  const homeBlocks = useOrderedHomeBlocks();
  const seoData = useSeoData();
  const organizationJsonLD = getOrganizationSchema();
  const breadcrumbsJsonLD = !!seoData.seo && getBreadcrumbsListSchema([
    {
      url: window?.REQUEST_URL || window?.location?.href,
      pageName: seoData.seo.title,
    },
  ]);

  useMatchCenterButton();
  useHomeMultiLangUrl();

  return (
    <Container>
      { !!seoData.seo && <Metadata seo={seoData.seo} /> }
      <MetaJsonLD json={organizationJsonLD} />
      { !!breadcrumbsJsonLD && <MetaJsonLD json={breadcrumbsJsonLD} /> }
      { gameId ? (<HomeMatchCenter />) : (<HomeCarousel isOverlapped={isOverlapped && hasSeasonsInfo} />) }
      {
        !gameId && hasSeasonsInfo && (
          <OverlappingSectionContainer isOverlapped={isOverlapped}>
            <InnerContainer isOverlapped={isOverlapped}>
              <SeasonSection>
                <MatchBox matches={matches} />
                <Standings standings={standings} seasonId={seasonId} />
              </SeasonSection>
            </InnerContainer>
          </OverlappingSectionContainer>
        )
      }
      {
        // eslint-disable-next-line react/no-array-index-key
        homeBlocks.map(({ HomeBlock, params }, index) => (HomeBlock ? <HomeBlock {...params} key={index} /> : null))
      }
    </Container>
  );
};

Home.serverFetch = [
  getLatestArticles, getLatestVideos, getStandings, getHomeData, getTicketingSingle, getLocalizedGames,
];

const Container = styled.div`
  max-width: 1280px;
  margin: auto;
`;

const OverlappingSectionContainer = styled(GraySection)<{isOverlapped: boolean}>`
  margin: ${({ isOverlapped }): string => (isOverlapped ? '0' : '20px 0')};
  padding-top: ${({ isOverlapped }): string => (isOverlapped ? '0px' : '20px')};
  padding-bottom: ${({ isOverlapped }): string => (isOverlapped ? '0px' : '20px')};

  @media ${Devices.mediumMobile} {
    padding-top: ${({ isOverlapped }): string => (isOverlapped ? '0px' : '30px')};
    padding-bottom: ${({ isOverlapped }): string => (isOverlapped ? '0px' : '30px')};
  }
`;

const InnerContainer = styled.div<{isOverlapped: boolean}>`
  position: relative;
  top: ${({ isOverlapped }): string => (isOverlapped ? '-40px' : '0px')};
  z-index: 100;
`;

const SeasonSection = styled.div`
  background-color: white;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  flex-wrap: wrap;

  @media ${Devices.tabletLarge} {
    flex-wrap: nowrap;
  }
`;

export default Home;
