import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

interface TotalResults {
  total?: number | string;
}

const TotalResults = React.memo(({ total }: TotalResults): React.ReactElement => (
  <Results>
    {total}
    &nbsp;
    <FormattedMessage id={`news.search.result${Number(total) === 1 ? '' : 's'}`} />
  </Results>
));

export default TotalResults;

const Results = styled.span`
  margin: 3px 14px 1px 0;
  font-family: MilanTypeRev;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  padding-right: 15px;
  border-right: 1px solid black;
  transition: all 0.3s linear;
`;
