import { Action, ActionWithPromiseReturn } from '@app/types/actionTypes';

import AppRoutes from '@app/constants/routes';
import { AppLanguages } from '@app/constants/localizationConstants';
import { getMatchResultsData, getSDMatchResultsData } from '@app/services/opta/matchService';
import { getStandingsData, getSDStandingsData } from '@app/services/opta/standingsService';
import { mapSchedule, findCompetitionIdByUrlSlug } from '@app/helpers/seasonHelpers';
import { isOptaSDCompetition } from '@app/services/opta/helpers/competitionHelpers';
import { getMenSquadActiveCompetition, getSquadActiveCompetition } from '@app/helpers/configurationHelpers';
import { PageError } from '@app/types/errorTypes';
import { RoutePath } from '@app/types/routerTypes';
import { StandingsTable } from '@app/types/standingsTypes';
import { MatchResult, ScheduleResults } from '@app/types/matchTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { TeamSquadTypes } from '@app/services/kentico/types/teamsTypes';
import { CompetitionTypes } from '@app/types/configurationTypes';
import {
  SetLoading,
  ResetSeason,
  SetSchedule,
  SetStandings,
  SeasonActionTypes,
  SetSeasonError,
  ResetSeasonError,
  SetAllSchedule,
} from '@app/store/actionTypes/seasonActionTypes';
import { getTeamNames } from '@app/store/actions/teamsActions';
import { CurrentRouteParams, setCurrentRoute } from '@app/store/actions/currentRouteActions';
import { waitForConfigurationDownload } from '@app/store/actions/configurationActions';
import { MenCompetitionsMap } from '@app/services/opta/constants/competitionConstants';
import * as Translations from '@app/locales';

const setLoadings = (isLoading: boolean): SetLoading => ({
  type: SeasonActionTypes.SET_LOADING,
  payload: isLoading,
});

const setStandings = (standings: StandingsTable[]): SetStandings => ({
  type: SeasonActionTypes.SET_STANDINGS,
  payload: standings,
});

export const resetSeason = (): ResetSeason => ({ type: SeasonActionTypes.RESET_SEASON });

const setSeasonError = (error: PageError): SetSeasonError => ({
  type: SeasonActionTypes.SET_SEASON_ERROR,
  payload: error,
});

export const resetSeasonError = (): ResetSeasonError => ({
  type: SeasonActionTypes.RESET_SEASON_ERROR,
});

type GetStandings = (args: {
  urlSlug?: string;
  competitionId?: string;
  seasonId?: string;
  optaId?: string;
  language: LanguageType;
}) => ActionWithPromiseReturn;

export const getStandings: GetStandings = ({
  competitionId = MenCompetitionsMap.SeriaA,
  urlSlug,
  seasonId,
  optaId,
  language,
}) => (
  async (dispatch, getState): Promise<void> => {
    dispatch(setLoadings(true));
    dispatch(getTeamNames(language));

    await waitForConfigurationDownload();

    try {
      const { configuration } = getState();
      const competitions = configuration[language]?.competitions;
      const activeCompetitionId = urlSlug ? findCompetitionIdByUrlSlug(competitions, urlSlug) : competitionId;
      const competition = configuration[language]?.competitions[activeCompetitionId];
      const defaultSeasonId = competition?.seasonIds.find(({ isActive }) => isActive)?.seasonId ?? '';

      let standings: StandingsTable[];

      if (competition?.type === CompetitionTypes.All) {
        standings = await Promise.all(getMenSquadActiveCompetition(competitions)
          .filter(({ type }) => [CompetitionTypes.League, CompetitionTypes.Tournament].includes(type))
          .map((comp) => getStandingsData(
            comp.seasonIds.find(({ isActive }) => isActive)?.seasonId ?? '', comp?.optaId ?? '',
          )));
      } else {
        standings = [isOptaSDCompetition(activeCompetitionId)
          ? await getSDStandingsData(defaultSeasonId, language)
          : await getStandingsData(seasonId ?? defaultSeasonId, optaId ?? competition?.optaId ?? '')];
      }

      dispatch(setStandings(standings));
    } catch (e) {
      console.error('Error on fetching Standings Opta data', e);
      dispatch(setSeasonError(PageError.Sorry));
    } finally {
      dispatch(setLoadings(false));
    }
  }
);

const setSchedule = (schedule: ScheduleResults): SetSchedule => ({
  type: SeasonActionTypes.SET_SCHEDULE,
  payload: schedule,
});

const setAllSchedule = (schedule: ScheduleResults): SetAllSchedule => ({
  type: SeasonActionTypes.SET_ALL_SCHEDULE,
  payload: schedule,
});

type GetSchedule = (args: {
  urlSlug?: string;
  competitionId: string;
  seasonId: string;
  language: LanguageType;
}) => Action;

export const getSchedule: GetSchedule = ({
  urlSlug, competitionId, seasonId, language,
}) => (
  async (dispatch, getState): Promise<void> => {
    dispatch(setLoadings(true));
    await waitForConfigurationDownload();

    try {
      const state = getState();
      const competitions = state.configuration[language]?.competitions;
      const activeCompetitionId = urlSlug ? findCompetitionIdByUrlSlug(competitions, urlSlug) : competitionId;
      const competition = competitions?.[activeCompetitionId];

      let matchResults: MatchResult[];

      if ([
        TeamSquadTypes.PrimaveraSquad, TeamSquadTypes.FuturoSquad,
      ].includes(competition?.categoryId as TeamSquadTypes)) {
        matchResults = await getSDMatchResultsData({
          id: competition?.id ?? '',
          optaId: competition?.optaId ?? '',
          seasonId: competition?.seasonIds.find(({ isActive }) => isActive)?.seasonId ?? '',
          language,
        });
        const allMatchResults = mapSchedule(matchResults);

        dispatch(setSchedule(allMatchResults));
        dispatch(setAllSchedule(allMatchResults));
      } else {
        const allMatchResults = await Promise.all(getSquadActiveCompetition(competitions, competition?.categoryId)
          .map((comp) => getMatchResultsData({
            seasonId: comp.seasonIds.find(({ isActive }) => isActive)?.seasonId ?? '',
            optaId: comp?.optaId ?? '',
            language,
          })));

        dispatch(setAllSchedule(mapSchedule(allMatchResults.flat())));

        if (competition?.type === CompetitionTypes.All) {
          matchResults = allMatchResults.flat();
        } else {
          matchResults = await getMatchResultsData({
            optaId: competition?.optaId ?? '',
            seasonId,
            language,
          });
        }
        dispatch(setSchedule(mapSchedule(matchResults)));
      }
    } catch (e) {
      console.error('Error on fetching Schedule Opta data', e);
      dispatch(setSeasonError(PageError.Sorry));
    } finally {
      dispatch(setLoadings(false));
    }
  }
);

type SetSeasonMultiLangUrl = (args: {
  pathId?: RoutePath;
  urlSlug: string;
  seasonId: string;
  language: LanguageType;
}) => Action;

export const setSeasonMultiLangUrl: SetSeasonMultiLangUrl = ({
  pathId, urlSlug, seasonId, language,
}) => (
  async (dispatch, getState): Promise<void> => {
    await waitForConfigurationDownload();

    const { configuration } = getState();
    const competitionId = findCompetitionIdByUrlSlug(configuration[language]?.competitions, urlSlug);
    const useSeasonIdFromTranslations = competitionId === 'men_squad_all' || isOptaSDCompetition(competitionId);

    const params = AppLanguages.reduce((acc, locale) => {
      acc.urlSlug[locale] = configuration[locale].competitions?.[competitionId]?.url;
      acc.seasonId[locale] = useSeasonIdFromTranslations ? Translations[locale]?.['season.active'] : seasonId;

      return acc;
    }, { urlSlug: {}, seasonId: {} } as unknown as CurrentRouteParams);

    dispatch(setCurrentRoute({ pathId: pathId ?? AppRoutes.Season.path, params }));
  }
);

export const setScheduleMultiLangUrl: SetSeasonMultiLangUrl = ({
  pathId = AppRoutes.Schedule.path, urlSlug, seasonId, language,
}) => ((dispatch): void => (dispatch(
  setSeasonMultiLangUrl({
    pathId, urlSlug, seasonId, language,
  }),
)));

export const setStandingsMultiLangUrl: SetSeasonMultiLangUrl = ({
  pathId = AppRoutes.Standings.path, urlSlug, seasonId, language,
}) => ((dispatch): void => (dispatch(
  setSeasonMultiLangUrl({
    pathId, urlSlug, seasonId, language,
  }),
)));
