import styled from 'styled-components';
import LocalizedLink from '@app/components/Localization/LocalizedLink';

const Button = (props): string => `
  width: 250px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  background-color: ${props.theme.color.freeSpeechRed};
  color: white;
  font-family: ${props.theme.fontFamily.milan};
  font-weight: bold;
  font-size: 11px;
  cursor: pointer;
  line-height: 1;
  border-radius: 2px;
  transition: background-color 0.3s ease-in, color 0.3s ease-in, border 0.3s ease-in;
  border: 2px solid transparent;

  & h3 {
    font-size: 11px;
    display: flex;
    align-items: center;
  }

  @media (pointer: fine) {
    &:hover {
      background-color: transparent;
      color: ${props.theme.color.harleyDavidsonOrange};
      border: 2px solid ${props.theme.color.harleyDavidsonOrange};
    }
  }
`;

export const CommonButton = styled.button`
  ${Button}
`;

export const CommonButtonLink = styled(LocalizedLink)`
  ${Button}
`;
