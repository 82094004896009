import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { theme } from '@app/styles';
import { MatchTicketsInfo, PackageTicketsInfo } from '@app/types/ticketingTypes';
import { useLanguage } from '@app/components/Hooks';
import { ColorizedExternalLink, ColorizedButton } from './ColorizedButton';
import Modal from '../Modal';

interface BuyTicketsButton {
  matchTickets: MatchTicketsInfo | PackageTicketsInfo;
  onClick?: () => void;
}

const BuyTicketsButton = ({ matchTickets, onClick }: BuyTicketsButton): React.ReactElement => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const language = useLanguage();
  const buyTicketUrl = matchTickets.ticketsUrl[language];

  return matchTickets?.ticketsPopup
    ? (
      <>
        <ColorizedButton
          color={theme.color.freeSpeechRed}
          onClick={(): void => {
            setIsModalOpened(true);
            onClick && onClick();
          }}
        >
          <FormattedMessage id="ticketing.buyTickets" />
        </ColorizedButton>
        <Modal controls={false} open={isModalOpened} onClose={(): void => setIsModalOpened(false)}>
          <Container>
            <MessageArea>
              {matchTickets?.ticketsMessage?.[language] ?? ''}
            </MessageArea>
            <ButtonsArea>
              <ColorizedButton
                color={theme.color.freeSpeechRed}
                onClick={(): void => setIsModalOpened(false)}
              >
                <FormattedMessage id="buttons.cancel" />
              </ColorizedButton>
              <ColorizedButton
                color={theme.color.freeSpeechRed}
                onClick={(): void => {
                  setIsModalOpened(false);
                  window.open(buyTicketUrl, '_blank');
                }}
              >
                <FormattedMessage id="buttons.ok" />
              </ColorizedButton>
            </ButtonsArea>
          </Container>
        </Modal>
      </>
    )
    : (
      <ColorizedExternalLink color={theme.color.freeSpeechRed} url={buyTicketUrl}>
        <FormattedMessage id="ticketing.buyTickets" />
      </ColorizedExternalLink>
    );
};

export default BuyTicketsButton;

const Container = styled.div`
  width: 100%;
  max-width: 920px;
  height: 100%;
  padding: 10px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MessageArea = styled.div`
  width: 100%;
  min-height: 200px;
  padding: 20px;
  background-color: ${(props): string => props.theme.color.whiteSmoke};
  box-shadow: 0 0 8px red;

  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonsArea = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;

  & button {
    width: 100px;
    margin-left: 10px;
  }
`;
