import * as React from 'react';
import styled, { Keyframes, keyframes } from 'styled-components';
import { theme } from '@app/styles';

interface LineGraphProps {
  width: number;
  value: number;
  strokeWidth?: number;
  dividerWidth?: number;
  strokeHomeColor?: string;
  strokeAwayColor?: string;
}

export const LineGraph = ({
  width, value, strokeWidth = 3, dividerWidth = 5,
  strokeAwayColor = 'rgba(157,157,157,0.1)', strokeHomeColor = theme.color.harleyDavidsonOrange,
}: LineGraphProps): React.ReactElement => (
  <SvgStyled width={width} height={strokeWidth * 2} offset={dividerWidth} value={value}>
    <path
      d={`M 0 3 H ${width}`}
      fill="transparent"
      stroke={strokeAwayColor}
      strokeWidth={strokeWidth}
    />
    <path
      d={`M 0 3 H ${width}`}
      fill="transparent"
      className="divider"
      stroke="#fff"
      strokeWidth={strokeWidth}
    />
    <path
      d={`M 0 3 H ${width}`}
      fill="transparent"
      className="progress"
      stroke={strokeHomeColor}
      strokeWidth={strokeWidth}
    />
  </SvgStyled>
);

const move = ({ value, width, offset = 0 }): Keyframes => keyframes`
  to {
    stroke-dashoffset: ${(((100 - value) * width) / 100) - offset};
  }
`;

const SvgStyled = styled.svg<{ width: number; value: number; offset: number }>`
  & .progress {
    stroke-dasharray: ${({ width }): number => width};
    stroke-dashoffset: ${({ width }): number => width};
    animation: ${({ value, width }): Keyframes => move({ value, width })} 500ms ease-out forwards;
  }

  & .divider {
    stroke-dasharray: ${({ width }): number => width};
    stroke-dashoffset: ${({ width }): number => width - 5};
    animation: ${({ value, width, offset }): Keyframes => move({ value, width, offset })} 500ms ease-out forwards;
  }
`;
