import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

interface CloseButtonProps {
  onClick: () => void;
  colorInvert?: boolean;
  size?: number;
  labelId?: string;
}

const CloseButton = ({
  onClick, size = 35, colorInvert = false, labelId,
}: CloseButtonProps): React.ReactElement => (
  <StyledCloseButton onClick={onClick} size={size} colorInvert={colorInvert} hasLabel={!!labelId}>
    {labelId ? (
      <FormattedMessage id={labelId} />
    ) : (
      <InnerContainer>
        <Line1 />
        <Line2 />
      </InnerContainer>
    )}
  </StyledCloseButton>
);

const Line = styled.div`
  height: 100%;
  border-radius: 10px;
  transition: all 0.3s ease;
`;

const StyledCloseButton = styled.button<{ size: number; colorInvert: boolean; hasLabel: boolean }>`
  outline: none;
  border: none;
  padding: ${({ hasLabel }): string => (hasLabel ? '0 10px' : '0')};
  margin: 0;
  background-color: ${(props): string => (props.colorInvert ? 'white' : props.theme.color.freeSpeechRed)};
  color: ${(props): string => (props.colorInvert ? props.theme.color.freeSpeechRed : 'white')};
  width: ${({ size, hasLabel }): string => (hasLabel ? 'auto' : `${size}px`)};
  min-width: ${({ size, hasLabel }): string => (hasLabel ? '50px' : `${size}px`)};;
  height: ${({ size }): string => `${size}px`};
  cursor: pointer;
  border-radius: ${({ hasLabel }): string => (hasLabel ? '2px' : '50%')};
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  & ${Line} {
      background-color: ${(props): string => (props.colorInvert ? props.theme.color.freeSpeechRed : 'white')};
  }

  &:hover {
    background-color: ${(props): string => (props.colorInvert ? props.theme.color.freeSpeechRed : 'white')};
    color: ${(props): string => (props.colorInvert ? 'white' : props.theme.color.freeSpeechRed)};
    transition: all 0.3s ease;

    ${Line} {
      background-color: ${(props): string => (props.colorInvert ? 'white' : props.theme.color.freeSpeechRed)};
      transition: all 0.3s ease;
    }
  }
`;

const InnerContainer = styled.div`
  height: 3px;
  width: 65%;
  position: relative;
`;

const Line1 = styled(Line)`
  transform: rotate(45deg);
`;

const Line2 = styled(Line)`
  position: absolute;
  top: 0;
  width: 100%;
  transform: rotate(-45deg);
`;

export default CloseButton;
