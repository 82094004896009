import React from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { AppState } from '@app/store/reducers';
import { TopNews } from '@app/types/newsTypes';
import { CarouselTopNews } from '@app/components';

const HomeCarousel = ({ isOverlapped }: { isOverlapped: boolean }): React.ReactElement => {
  const slides = useSelector<AppState, TopNews[]>(
    (state) => state.home.carousel,
    isEqual,
  );

  return (<CarouselTopNews isOverlapped={isOverlapped} slides={slides} />);
};

export default HomeCarousel;
