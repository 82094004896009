import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { useMenu } from '@app/components/Hooks';
import { ContainerMedium } from '@app/components/WebTemplates/components';
import { SecondLevelMenu, RosterArchiveDropdown, WebTemplateContainer } from '@app/components';
import { PageWrapper } from '@app/pages/components';

import {
  useWebTemplateData,
  useWebTemplateDownload,
  useWebTemplatePageValidation,
} from '@app/pages/WebTemplate/WebTemplateHooks';

import { useSecondLevelMenuItems, useMenFirstTeamNameSelector } from '@app/pages/Teams/TeamsHooks';

const WebTemplateRosterArchivePage: React.FC = React.memo(() => {
  const { categoryName = '' } = useParams();
  const { formatMessage } = useIntl();
  const menu = useMenu();
  const data = useWebTemplateData();
  const validationError = useWebTemplatePageValidation();
  const secondLevelMenuItems = useSecondLevelMenuItems();
  const title = useMenFirstTeamNameSelector();
  const selectedArchive = menu
    ?.web_roster_archive?.navigation
    ?.web_archive_men_first_team?.categories
    ?.find(({ url }) => (url === categoryName));

  const season = selectedArchive?.name;

  useWebTemplateDownload();

  return (
    <>
      {/** ROSTER ARCHIVE TEMPLATE -> START */}
      {!!secondLevelMenuItems.length && <SecondLevelMenu items={secondLevelMenuItems} />}
      <Container>
        <Title>{title}</Title>
        <FiltersContainer>
          <SubTitle>
            {formatMessage({ id: 'teams.archive' }, { season })}
          </SubTitle>
          <RosterArchiveDropdown />
        </FiltersContainer>
      </Container>
      {/** ROSTER ARCHIVE TEMPLATE -> END */}
      {/** WEB TEMPLATE -> START */}
      <PageWrapper error={validationError}>
        <WebTemplateContainer data={data} />
      </PageWrapper>
      {/** WEB TEMPLATE -> END */}
    </>
  );
});

export default WebTemplateRosterArchivePage;

const Container = styled(ContainerMedium)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  position: relative;
  width: 100%;
`;

const FiltersContainer = styled(ContainerMedium)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  position: relative;
  width: 100%;

  padding: 40px 0 20px;

  @media ${Devices.desktop} {
    padding: 40px 0 40px;
  }
`;

const Title = styled.h2`
  font-size: 24px;
  letter-spacing: 0.14px;
  text-transform: uppercase;
  padding-top: 40px;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  color: ${({ color }): string => (color ?? 'black')};

  cursor: default;

  @media ${Devices.tablet} {
    font-size: 28px;
    letter-spacing: 0.16px;
  }
`;

const SubTitle = styled.h1`
  margin-right: 20px;
  height: 100%;
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  display: block;
  cursor: default;
`;
