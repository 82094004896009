import { useEffect, useContext, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import isEqual from 'lodash/isEqual';

import {
  getLatestArticles, resetLatestArticles, getLatestVideos, resetLatestVideos, getLatestPictures, resetLatestPictures,
} from '@app/store/actions/newsActions';

import { BreadcrumbsListSchema } from '@app/types/structuredMarkup';
import { BaseMenuItem } from '@app/types/configurationTypes';
import { NavigationLinkParams, RoutePath } from '@app/types/routerTypes';
import {
  BaseNewsItem, NewsTypes, EditorialContentTypes, NewsListItems,
} from '@app/types/newsTypes';
import { AppState } from '@app/store/reducers';

import { AppLanguagesMap } from '@app/constants/localizationConstants';
import { NewsNavigationTypeMap } from '@app/constants/newsConstants';
import AppRoutes from '@app/constants/routes';

import { getCategoryUrl } from '@app/helpers/newsHelpers';

import { MountContext } from '@app/ReactContext';
import { useBreadcrumbsJsonLD, useLanguage } from '@app/components/Hooks';
import { useAdsStatus } from '@app/components/GoogleAds/GoogleAdsHooks';
import { GoogleAdsMap } from '@app/constants/googleAdsConstants';
import { GoogleAdsProps } from '@app/types/googleTypes';

export const useLatestArticlesHook = (): BaseNewsItem[] => {
  const dispatch = useDispatch();
  const language = AppLanguagesMap[useIntl().locale];
  const { isInitialMount } = useContext(MountContext);

  const latestNews = useSelector<AppState, BaseNewsItem[]>(
    (state) => state.news.articles.latestItems,
    shallowEqual,
  );

  const codeName = useSelector<AppState, string>(
    (state) => state.news.articles.selectedItemDetails?.[language]?.codeName ?? '',
    isEqual,
  );

  useEffect(() => {
    (!isInitialMount || !latestNews?.length) && dispatch(getLatestArticles({ codeName, language }));
    return (): void => { dispatch(resetLatestArticles()); };
  }, [language, codeName]);

  return latestNews;
};

const useCommonListWithGoogleAds = (useList: () => BaseNewsItem[], props: GoogleAdsProps): NewsListItems => {
  const adsStatus = useAdsStatus(props.adUnit);
  const [positionForAds] = useState(Math.floor(Math.random() * 3));
  const [isGoogleAdsVisible, setIsGoogleAdsVisible] = useState(true);

  const items: NewsListItems = [...useList()];
  const useGoogleAds = isGoogleAdsVisible && !!items.length;

  useGoogleAds && items.splice(positionForAds, 0, props);

  useEffect(() => {
    if (adsStatus === false) setIsGoogleAdsVisible(false);
  }, [adsStatus]);

  return items;
};

export const useLatestArticlesWithGoogleAds = (): NewsListItems => useCommonListWithGoogleAds(
  useLatestArticlesHook, GoogleAdsMap.articleLandingCarouselItem,
);

export const useLatestVideosHook = (): BaseNewsItem[] => {
  const dispatch = useDispatch();
  const language = AppLanguagesMap[useIntl().locale];
  const { isInitialMount } = useContext(MountContext);

  const latestVideos = useSelector<AppState, BaseNewsItem[]>(
    (state) => state.news.videos.latestItems,
    shallowEqual,
  );

  const codeName = useSelector<AppState, string>(
    (state) => state.news.videos.selectedItemDetails?.[language]?.codeName ?? '',
    isEqual,
  );

  useEffect(() => {
    (!isInitialMount || !latestVideos?.length) && dispatch(getLatestVideos({ codeName, language }));
    return (): void => { dispatch(resetLatestVideos()); };
  }, [codeName, language]);

  return latestVideos;
};

export const useLatestVideosWithGoogleAds = (): NewsListItems => useCommonListWithGoogleAds(
  useLatestVideosHook, GoogleAdsMap.videoLandingCarouselItem,
);

export const useLatestPhotosHook = (): BaseNewsItem[] => {
  const dispatch = useDispatch();
  const language = AppLanguagesMap[useIntl().locale];
  const { isInitialMount } = useContext(MountContext);

  const latestPhotos = useSelector<AppState, BaseNewsItem[]>(
    (state) => state.news.pictures.latestItems,
    shallowEqual,
  );

  const codeName = useSelector<AppState, string>(
    (state) => state.news.pictures.selectedItemDetails?.[language]?.codeName ?? '',
    isEqual,
  );

  useEffect(() => {
    if (!isInitialMount || !latestPhotos?.length) {
      dispatch(getLatestPictures({ codeName, language }));
    }

    return (): void => { dispatch(resetLatestPictures()); };
  }, [language, codeName]);

  return latestPhotos;
};

export const useLatestPhotosWithGoogleAds = (): NewsListItems => useCommonListWithGoogleAds(
  useLatestPhotosHook, GoogleAdsMap.galleryLandingCarouselItem,
);

export const useLatestNewsMenuItem = (type: NewsTypes): BaseMenuItem | undefined => {
  const language = useLanguage();
  return useSelector<AppState, BaseMenuItem | undefined>(
    (state) => (state.configuration[language]?.menu
      ?.web_news?.navigation[NewsNavigationTypeMap[type]]?.categories[0]),
    shallowEqual,
  );
};

export const useLatestNewsLinkParams = (type: NewsTypes): NavigationLinkParams => {
  const latestNewsMenuItem = useLatestNewsMenuItem(type);
  let pathKey;

  switch (type) {
    case 'articles':
      pathKey = AppRoutes.Articles.path;
      break;
    case 'videos':
      pathKey = AppRoutes.Videos.path;
      break;
    case 'pictures':
      pathKey = AppRoutes.Gallery.path;
      break;
    default:
      break;
  }

  return {
    pathKey,
    pathParams: {
      categoryName: latestNewsMenuItem?.url ?? '',
    },
  };
};

type UseNewsBreadcrumbsJsonLD = (pageTitle: string, newsType: NewsTypes) => BreadcrumbsListSchema

export const useNewsBreadcrumbsJsonLD: UseNewsBreadcrumbsJsonLD = (pageTitle, newsType) => {
  const latestNewsLinkParams = useLatestNewsLinkParams(newsType);
  const latestArticlesPageTitle = useLatestNewsMenuItem(newsType)?.seo?.title ?? '';

  return useBreadcrumbsJsonLD(
    pageTitle,
    {
      pageTitle: latestArticlesPageTitle,
      linkParams: latestNewsLinkParams,
    },
  );
};

export const useCategoryUrl = (newsItem?: BaseNewsItem | null): string => {
  const language = useLanguage();

  return useSelector<AppState, string>(
    (state) => getCategoryUrl({ state, language, newsItem }),
    isEqual,
  );
};

export const usePathKey = (type: EditorialContentTypes): RoutePath => {
  switch (type) {
    case EditorialContentTypes.VIDEO: return AppRoutes.VideoLanding.path;
    case EditorialContentTypes.PHOTO: return AppRoutes.GalleryLanding.path;
    case EditorialContentTypes.NEWS:
    default: return AppRoutes.ArticleLanding.path;
  }
};
