import { langCodeToKenticoLangMap } from '@app/constants/localizationConstants';
import { Coach, Player, TeamNames } from '@app/types/teamsTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { RequestParamsInterface } from './types/requestTypes';
import {
  KenticoCoach, KenticoPlayer, KenticoTeamName, TeamSquadTypes,
} from './types/teamsTypes';
import { convertCoach, convertPlayer, convertTeamNames } from './converters/teamsConverter';
import { sendRequestTyped } from './request';

export interface GetPlayersParamsInterface extends RequestParamsInterface {
  language: LanguageType;
  teamType: TeamSquadTypes;
}

export async function getPlayersList(requestParams: GetPlayersParamsInterface): Promise<Player[]> {
  const params: RequestParamsInterface = {
    'system.type': 'player',
    language: langCodeToKenticoLangMap[requestParams.language],
    'system.language': langCodeToKenticoLangMap[requestParams.language],
    'elements.squad[contains]': requestParams.teamType,
  };

  const playerKenticoResponse = await sendRequestTyped<KenticoPlayer>({ params });
  return playerKenticoResponse?.items?.map(
    (item) => convertPlayer(item.elements, item.system.id, requestParams.language)
  ) || [];
}

export async function getCoachesList(requestParams: GetPlayersParamsInterface): Promise<Coach[]> {
  const params: RequestParamsInterface = {
    'system.type': 'coach',
    language: langCodeToKenticoLangMap[requestParams.language],
    'system.language': langCodeToKenticoLangMap[requestParams.language],
    'elements.squad[contains]': requestParams.teamType,
  };

  const playerKenticoResponse = await sendRequestTyped<KenticoCoach>({ params });
  return playerKenticoResponse?.items?.map(
    (item) => convertCoach(item.elements, item.system.id, requestParams.language)
  ) || [];
}

export async function getTeamNamesData(language?: LanguageType, sliceId = true): Promise<TeamNames> {
  const params: RequestParamsInterface = {
    'system.type': 'team',
  };
  if (language) {
    params.language = langCodeToKenticoLangMap[language];
    params['system.language'] = langCodeToKenticoLangMap[language];
  }

  const teamNamesResponse = await sendRequestTyped<KenticoTeamName>({ params });
  return convertTeamNames(teamNamesResponse?.items ?? [], sliceId);
}
