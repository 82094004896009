import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import * as Translations from '@app/locales';
import { useLanguage } from '@app/components/Hooks';
import { isOptaSDCompetition } from '@app/services/opta/helpers/competitionHelpers';
import { setSeasonMultiLangUrl, resetSeason, resetSeasonError } from '@app/store/actions/seasonActions';
import {
  BaseMenuItem, CategoryMenu, Competition, Competitions, SeoData, CompetitionTypes,
} from '@app/types/configurationTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { Errors, PageError } from '@app/types/errorTypes';
import { RoutePath } from '@app/types/routerTypes';
import { SecondLevelMenuItems } from '@app/components/SecondLevelMenu';
import { AppState } from '@app/store/reducers';
import AppRoutes from '@app/constants/routes';
import { menuIdToPathIdMap } from '@app/constants/configurationConstants';
import { MenCompetitionsMap } from '@app/services/opta/constants/competitionConstants';
import { SeasonNavigationTypeMap } from '@app/constants/seasonConstants';

const useSchedulePageLocalizedCheck = (): boolean => {
  const { formatMessage } = useIntl();
  const { page } = useParams();

  return Boolean(formatMessage({ id: AppRoutes.Schedule.path }).includes(`/${page}/`));
};

export const useStandingsPageLocalizedCheck = (): boolean => {
  const { formatMessage } = useIntl();
  const { page } = useParams();

  return Boolean(formatMessage({ id: AppRoutes.Standings.path }).includes(`/${page}/`));
};

export const useSeasonPageTitle = (): string => {
  const { formatMessage } = useIntl();
  const isSchedulePage = useSchedulePageLocalizedCheck();

  return isSchedulePage
    ? formatMessage({ id: 'season.scheduleTitle' })
    : formatMessage({ id: 'season.standingsTitle' });
};

export const useSeasonPagePathKey = (): RoutePath => {
  const isSchedulePage = useSchedulePageLocalizedCheck();
  const isStandingsPage = useStandingsPageLocalizedCheck();

  if (isSchedulePage) { return AppRoutes.Schedule.path; }
  if (isStandingsPage) { return AppRoutes.Standings.path; }
  // default
  return AppRoutes.Schedule.path;
};

export const useCompetitionsSelector = (): Competitions => {
  const { locale } = useIntl();
  return useSelector<AppState, Competitions>(
    (state) => state.configuration[locale]?.competitions,
    shallowEqual,
  );
};

export const useSyncCalendarUrlSelector = (): string => {
  const { locale } = useIntl();
  return useSelector<AppState, string>(
    (state) => state.configuration[locale]?.syncCalendarUrl,
    shallowEqual,
  );
};

export const useCompetitionSelector = (): Competition | undefined => {
  const { urlSlug = '' } = useParams();
  const competitions = useCompetitionsSelector();
  return Object.values(competitions).find(({ url }) => url === urlSlug);
};

export const useCompetitionIdSelector = (): string => useCompetitionSelector()?.id ?? '';

export const useCompetitionCategorySelector = (): string => useCompetitionSelector()?.categoryId ?? '';

export const useIsLoadingSelector = (): boolean => useSelector<AppState, boolean>(
  (state) => state.season.isLoading,
  shallowEqual,
);

export const useSeasonPageErrorSelector = (): Errors => useSelector<AppState, Errors>(
  (state) => state.season.error,
  shallowEqual,
);

export const useSelectOptions = (): Competition[] => {
  const competitions = useCompetitionsSelector();
  const competitionId = useCompetitionIdSelector();
  return Object.values(competitions ?? {})
    .filter(({ categoryId }) => categoryId === competitions[competitionId]?.categoryId);
};

/** Hook is used only for seasonId route param validation */
export const useOptaSeasonIdCheck = (): boolean => {
  const { formatMessage } = useIntl();
  const { seasonId = '' } = useParams();
  const competitionId = useCompetitionIdSelector();
  const competition = useCompetitionSelector();

  return isOptaSDCompetition(competitionId)
    ? seasonId === formatMessage({ id: 'season.active' })
    : !!competition?.seasonIds.find((season) => season.seasonId === seasonId);
};

/** Hook is used only in Standing/Schedule DownloadResults Hooks */
export const useOptaSeasonId = (): string => {
  const { seasonId = '' } = useParams();
  const competitionId = useCompetitionIdSelector();
  const competitions = useCompetitionsSelector();

  return isOptaSDCompetition(competitionId)
    // Use SD seasonId from competition config
    ? competitions[competitionId]?.seasonIds?.find(({ isActive }) => isActive)
    ?.seasonId ?? competitions[competitionId]?.seasonIds?.[0]?.seasonId ?? ''
    // Use seasonID directly from URL
    : seasonId;
};

export const useSeasonIdActiveStateCheck = (): boolean => {
  const { seasonId = '', urlSlug = '' } = useParams();
  const competition = useCompetitionSelector();
  const language = useLanguage();

  return Translations[language]?.['season.active.all'].toLowerCase() === urlSlug || !!competition?.seasonIds
    .find((season) => season.seasonId === seasonId && season.isActive);
};

interface MenuData {
  categoryMenuItems: CategoryMenu;
  secondLevelMenuItems: SecondLevelMenuItems;
}

export const useNavigationSelector = (): MenuData => {
  const { formatMessage, locale } = useIntl();
  const { page = '', seasonId = '', urlSlug = '' } = useParams();
  const competition = useCompetitionSelector();

  return useSelector<AppState, MenuData>(
    (state) => {
      const seasonNavigation = state.configuration[locale]?.menu?.web_season?.navigation;

      const secondLevelMenuItems = Object
        .values(seasonNavigation ?? {})
        .filter(({ id }) => {
          switch (id) {
            case SeasonNavigationTypeMap.standings: return competition?.type && [
              CompetitionTypes.All,
              CompetitionTypes.League,
              CompetitionTypes.Tournament,
            ].includes(competition?.type);
            case SeasonNavigationTypeMap.schedule: return true;
            default: return false;
          }
        })
        .map(({
          id, name, type, external, related, isVisible, geo,
        }) => ({
          id,
          name,
          linkData: {
            pathKey: menuIdToPathIdMap[id],
            pathParams: { urlSlug, seasonId },
          },
          isActive: formatMessage({ id: menuIdToPathIdMap[id] }).includes(`/${page}/`),
          type,
          external,
          related,
          isVisible,
          geo,
        }));

      return {
        categoryMenuItems: seasonNavigation?.[SeasonNavigationTypeMap.schedule]?.categories ?? [],
        secondLevelMenuItems,
      };
    }, isEqual,
  );
};

export const useActiveSeasonIdForCompetitionSeriaA = (): string => {
  const { locale } = useIntl();
  return useSelector<AppState, string>(
    (state) => state.configuration[locale]?.competitions?.[MenCompetitionsMap.SeriaA]?.seasonIds
      .find(({ isActive }) => isActive)?.seasonId ?? '',
    shallowEqual,
  );
};

export const useActiveUrlSlugForCompetitionSeriaA = (): string => {
  const { locale } = useIntl();
  return useSelector<AppState, string>(
    (state) => state.configuration[locale]?.competitions?.[MenCompetitionsMap.SeriaA]?.url,
    shallowEqual,
  );
};

interface MenSquadAllCompetitionsProps {
  seasonId: string;
  urlSlug: string;
}

export const useLocalizedMenSquadAllCompetitionsParams = (language: LanguageType): MenSquadAllCompetitionsProps => ({
  seasonId: Translations[language]?.['season.active'].toLowerCase(),
  urlSlug: Translations[language]?.['season.active.all'].toLowerCase(),
});

export const useMenSquadAllCompetitionsParams = (): MenSquadAllCompetitionsProps => {
  const language = useLanguage();

  return useLocalizedMenSquadAllCompetitionsParams(language);
};

export const useIsMenSquadAllCompetitionsSelected = (): boolean => {
  const { seasonId = '', urlSlug = '' } = useParams();
  const params = useMenSquadAllCompetitionsParams();

  return seasonId === params.seasonId && urlSlug === params.urlSlug;
};

export const useSeasonRoutesValidation = (): Errors => {
  const dispatch = useDispatch();

  const language = useLanguage();
  const pathId = useSeasonPagePathKey();
  const { seasonId = '', urlSlug = '' } = useParams();

  const seasonPageError = useSeasonPageErrorSelector();
  const competitions = useCompetitionsSelector();

  const isAllCategorySelected = useIsMenSquadAllCompetitionsSelected();
  const isStandingsPage = useStandingsPageLocalizedCheck();
  const isSchedulePage = useSchedulePageLocalizedCheck();
  const isSeasonIdValid = useOptaSeasonIdCheck();

  const isSeasonPageValid = isSchedulePage || isStandingsPage;

  /** Network Error - return 503 */
  if (seasonPageError) return seasonPageError;

  /** Stop validation if no competitions */
  if (!(Object.values(competitions ?? {}).length)) return null;

  /** Valid - set current Multi-lang url */
  if (isSeasonPageValid && (isSeasonIdValid || isAllCategorySelected)) {
    dispatch(setSeasonMultiLangUrl({
      pathId, urlSlug, seasonId, language,
    }));
    return null;
  }

  /** Not Valid - default - return 404 */
  return PageError.NotFound;
};

export const useSeasonResetOnUnmount = (): void => {
  const dispatch = useDispatch();

  useEffect(() => (): void => {
    dispatch(resetSeason());
    dispatch(resetSeasonError());
  }, []);
};

export const useSeasonPageErrorReset = (): void => {
  const dispatch = useDispatch();
  const { competitionId, seasonId, page } = useParams();

  useEffect(() => (): void => {
    dispatch(resetSeasonError());
  }, [competitionId, seasonId, page]);
};

export const useSelectedCategory = (): BaseMenuItem | undefined => {
  const competition = useCompetitionSelector();
  const { locale } = useIntl();
  const isSchedule = useSchedulePageLocalizedCheck();
  const secondLevelMenuId = isSchedule ? SeasonNavigationTypeMap.schedule : SeasonNavigationTypeMap.standings;

  const categories = useSelector<AppState, CategoryMenu>(
    (state) => state.configuration[locale]?.menu
      ?.web_season?.navigation?.[secondLevelMenuId]?.categories ?? [],
    isEqual,
  );

  return categories.find((category) => category?.id === decodeURIComponent(competition?.categoryId ?? ''));
};

export const useSeasonSeoSelector = (): SeoData => {
  const { seasonId = '' } = useParams();
  const language = useLanguage();
  const competition = useCompetitionSelector();
  const competitionId = useCompetitionIdSelector();
  const isSchedulePage = useSchedulePageLocalizedCheck();
  const pageKey = isSchedulePage ? 'schedule' : 'standings';
  const allKey = competition?.name?.toLowerCase() === Translations[language]?.['season.active.all'].toLowerCase() ? '.all' : '';

  const formatSeasonSeoLabel = (label = ''): string => label
    .replace('{competition}', competition?.name ?? '')
    .replace('{season}', isOptaSDCompetition(competitionId)
      ? competition?.seasonIds?.[0]?.seasonName ?? Translations[language]?.['season.active']
      : competition?.seasonIds?.find((season) => season.seasonId === seasonId)?.seasonName ?? seasonId);
  const meta = `${pageKey}.${competition?.categoryId}${allKey}.meta`;
  const title = formatSeasonSeoLabel(Translations[language]?.[`${meta}.title`]);
  const description = formatSeasonSeoLabel(Translations[language]?.[`${meta}.description`]);

  return {
    title,
    description,
    canonicalTag: '',
    meta: false,
    socialTitle: title,
    socialDescription: description,
    socialImageUrl: '',
  };
};
