import React from 'react';
import styled from 'styled-components';

import { useParams, useLocation, Link } from 'react-router-dom';

import { ContainerMedium } from '@app/components/WebTemplates/components';
import { WebPageImageElement } from '@app/components/WebTemplates/elements';
import { PageWrapper } from '@app/pages/components';

import { useQuestion, useQuestionDownload, useQuestionValidation } from './QuestionHooks';

const Question: React.FC = () => {
  const { tab = '' } = useParams();
  const { pathname } = useLocation();
  const questionData = useQuestion();
  useQuestionDownload();
  const validationError = useQuestionValidation();

  const regex = new RegExp(`/${tab}`, 'gi');
  const topicUrl = pathname.replace(regex, '');

  return (
    <PageWrapper error={validationError}>
      {
        !!questionData && (
          <>
            <WebPageImageElement data={questionData.imageData} />
            <ContainerMedium>
              <BreadCrumbs>
                <span>{'< '}</span>
                <Link to={topicUrl}>{questionData.topicTitle}</Link>
              </BreadCrumbs>
              <Title>
                {questionData.question.question}
              </Title>
              <Answer dangerouslySetInnerHTML={{ __html: questionData.question.answer }} />
            </ContainerMedium>
          </>
        )
      }
    </PageWrapper>
  );
};

const BreadCrumbs = styled.div`
  color: black;
  font-size: 13px;
  margin-top: 30px;

  & a {
    color: inherit;
    text-decoration-line: underline;
  }
`;

const Title = styled.h1`
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 19px;
  font-weight: bold;
  margin: 40px 0 13px;
  text-transform: uppercase;
`;

const Answer = styled.div`
  font-size: 16px;
  line-height: 1.5;
  text-align: justify;

  & a {
    color: ${(props): string => props.theme.color.harleyDavidsonOrange};
  }
`;

export default Question;
