import {
  WebCard,
  WebCardLayoutSize,
  WebCardLayoutType,
  WebCtaButton,
  WebCtaImage,
  WebCtaDuoButton,
  WebCtaButtonTemplateType,
  WebDepartment,
  WebGoogleAds,
  WebGoogleMaps,
  WebEditorial,
  WebEditorialDuo,
  WebEmbedded,
  WebLanding,
  WebPageImage,
  WebPageImageSizeType,
  WebPageVideo,
  WebPageVideoSizeType,
  WebPageTitle,
  WebPageSeparator,
  WebPageSeparatorSizeType,
  WebPartner,
  WebPartnerType,
  WebPartnerAdditionalSectionsType,
  WebPerson,
  WebShare,
  WebTabItem,
  WebTabs,
  WebTemplateData,
  WebTemplateTypes,
  WebTicketsPartners,
  WebFaqCategory,
  WebFaqGroup,
  WebContentGroup,
  WebTemplate,
  NewsConfiguration,
  MenuItem,
  WebHomeLanding,
  WebCardOptions,
  WebHomeSlider,
  WebSliderLayoutSize,
  WebEmbeddedOverlay,
  WebEmbeddedOneTrust,
  WebCardRosterArchive,
  WebHallOfFameHighlights,
  WebHallOfFameInductee,
  WebHallOfFameInductees,
  WebHallOfFameInducteeStat,
} from '@app/types/webTemplateTypes';
import {
  KenticoCtaButtonData,
  KenticoWebCtaImageData,
  KenticoWebCtaDuoButtonData,
  KenticoWebCardData,
  KenticoWebDepartmentData,
  KenticoWebGoogleAds,
  KenticoWebGoogleMapsData,
  KenticoWebEditorialData,
  KenticoWebEditorialDuoData,
  KenticoWebEmbeddedData,
  KenticoWebLandingData,
  KenticoWebPageImageData,
  KenticoWebPageVideoData,
  KenticoWebPageTitleData,
  KenticoWebPageSeparatorData,
  KenticoWebPartnerData,
  KenticoWebPersonData,
  KenticoWebTabItemData,
  KenticoWebTabsData,
  KenticoWebTicketsPartners,
  KenticoWebFaqCategory,
  KenticoWebFaqCategoryGroup,
  KenticoWebContentGroup,
  KenticoWebTemplateData,
  WebTemplate as KenticoWebTemplate,
  KenticoWebHomeLandingData,
  KenticoWebGoogleAnalyticsSnippet,
  KenticoWebHallOfFameHighlightsData,
  KenticoWebHallOfFameInducteesData,
  KenticoWebHallOfFameInducteeData,
  KenticoWebHallOfFameInducteeStatData,
} from '@app/services/kentico/types/webTemplateTypes';
import { AssetElementValue } from '@app/services/kentico/types/elementTypes';
import { Image, MenuItemTypes } from '@app/types/configurationTypes';
import { KenticoItem } from '@app/services/kentico/types/responseTypes';
import { KenticoFaqWebQuestionItem } from '@app/services/kentico/types/faqQuestionTypes';
import { EditorialContentTypes } from '@app/types/newsTypes';
import { GoogleAdsViewTypes, GAEvent } from '@app/types/googleTypes';

import { convertQuestion } from '@app/services/kentico/converters/faqQuestionConverter';
import {
  mapBaseMenuItem, mapRelatedItem, mapSeoData,
} from '@app/helpers/configurationHelpers';
import { mapEditorialContent, mapEditorialModularContent } from '@app/helpers/newsHelpers';
import { KenticoLangToLangCodeMap } from '@app/constants/localizationConstants';
import { LayoutConfiguration } from '@app/constants/configurationConstants';
import { GOOGLE_MAPS_ZOOM } from '@app/constants/googleMapsConstants';

export function convertWebTemplateElement(data, value): WebTemplateData {
  const type = data?.modular_content[value]?.system.type as WebTemplateTypes;
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const converter = converters[type];

  if (converter) {
    return type === WebTemplateTypes.WebGoogleAds
      ? converter(data?.modular_content[value])
      : converter(data, data?.modular_content[value]);
  }
  return null;
}

export function convertImage(asset: AssetElementValue): Image {
  return {
    url: asset?.value?.[0]?.url ?? '',
    description: asset?.value?.[0]?.description ?? '',
  };
}

function formatButtonMenuItem(data: KenticoWebTemplate, menuId: string): MenuItem {
  const actionType = data?.modular_content[menuId]?.system?.type ?? '';
  switch (actionType) {
    case MenuItemTypes.ExternalLink:
    case MenuItemTypes.BaseMenuItem:
      return mapBaseMenuItem(data, menuId, null);
    case MenuItemTypes.RelatedMenuItem:
      return mapRelatedItem(data, menuId);
    default:
      return null;
  }
}

export const convertWebGoogleAds = ({ elements, system }: KenticoItem<KenticoWebGoogleAds>): WebGoogleAds => ({
  type: WebTemplateTypes.WebGoogleAds,
  id: system?.codename ?? '',
  ads: {
    adUnit: elements?.ad_unit?.value ?? '',
    slotId: elements?.gpt_tag?.value ?? '',
    size: ['fluid'],
    view: elements?.view?.value?.length
      ? elements?.view?.value?.[0]?.codename as GoogleAdsViewTypes
      : GoogleAdsViewTypes.Standard,
    text: elements?.text?.value ?? '',
    color: `#${elements?.color?.value.length ? elements.color?.value : 'fff'}`,
    endDate: elements?.end_date?.value,
    background: `#${elements?.background?.value?.length ? elements?.background?.value : '000'}`,
  },
});

export const convertWebGoogleAnalyticsSnippet = (data: KenticoWebGoogleAnalyticsSnippet): GAEvent | null => {
  const getSnippetValue = (name: string): string | null => (
    data[`tracking__${name}`]?.value?.length ? data[`tracking__${name}`].value : null
  );

  const event = getSnippetValue('event');

  const labelSnippet = getSnippetValue('label');
  const label = labelSnippet ? { label: labelSnippet } : {};

  const actionSnippet = getSnippetValue('action');
  const action = actionSnippet ? { action: actionSnippet } : {};

  const categorySnippet = getSnippetValue('category');
  const category = categorySnippet ? { category: categorySnippet } : {};

  return event ? {
    event, ...label, ...action, ...category,
  } : null;
};

export function convertWebCtaButton(data: KenticoWebTemplate, value): WebCtaButton {
  const element: KenticoCtaButtonData = value?.elements;
  const menuId = element?.action_type?.value?.[0];

  return {
    type: WebTemplateTypes.WebCtaButton,
    id: value?.system?.codename ?? '',
    label: element?.label?.value ?? '',
    template: (element?.ui_template?.value[0]?.name ?? '').toLowerCase() as WebCtaButtonTemplateType,
    menuItem: formatButtonMenuItem(data, menuId),
    analytic: convertWebGoogleAnalyticsSnippet(value?.elements),
  };
}

export function convertWebCtaImage(
  data: KenticoWebTemplate,
  value: KenticoItem<KenticoWebCtaImageData>,
): WebCtaImage {
  return {
    id: value?.system?.codename ?? '',
    type: WebTemplateTypes.WebCtaImage,
    image: convertImage(value?.elements?.cover_image),
    menuItem: formatButtonMenuItem(data, value?.elements?.action_type?.value?.[0]),
    analytic: convertWebGoogleAnalyticsSnippet(value?.elements),
  };
}

function convertWebCtaDuoButton(
  data: KenticoWebTemplate,
  value: KenticoItem<KenticoWebCtaDuoButtonData>,
): WebCtaDuoButton {
  return {
    id: value?.system?.codename ?? '',
    type: WebTemplateTypes.WebCtaDuoButton,
    content: value?.elements?.cta?.value?.map((cta) => convertWebCtaImage(
      data, data.modular_content[cta] as KenticoItem<KenticoWebCtaImageData>,
    )),
  };
}

function convertWebPerson(data, value): WebPerson {
  const element: KenticoWebPersonData = value?.elements;
  return {
    type: WebTemplateTypes.WebPerson,
    id: value?.system?.codename ?? '',
    firstName: element?.first_name?.value ?? '',
    lastName: element?.last_name?.value ?? '',
    position: element?.position?.value ?? '',
    quote: element?.quote?.value ?? '',
    photo: convertImage(element?.photo_large),
    resume: element?.resume?.value?.[0]?.url ?? '',
    url: element?.url?.value ?? '',
  };
}

function convertWebPartner(data, value): WebPartner {
  const element: KenticoWebPartnerData = value?.elements;
  const countries = element?.['geo_location__countries']?.value ?? [];
  const exclude = element?.['geo_location__visibility']?.value?.[0]?.codename === 'hide';
  const additionalSections = element?.additional_sections?.value ?? [];
  const googleAdsItem: KenticoItem<KenticoWebGoogleAds> = data?.modular_content?.[`${element?.banner?.value?.[0]}`];

  return {
    type: WebTemplateTypes.WebPartner,
    id: value?.system?.codename ?? '',
    title: element?.title?.value ?? '',
    description: element?.description?.value ?? '',
    photo: convertImage(element?.photo_large),
    redirectUrl: element?.redirect_url?.value ?? '',
    additionalSections: additionalSections.map(
      (value) => (value?.codename ?? '') as WebPartnerAdditionalSectionsType,
    ),
    geo: {
      countries: countries.map(({ codename }) => codename.toUpperCase()),
      exclude,
    },
    partnerType: (element?.partner_type?.value?.[0]?.codename ?? WebPartnerType.Cms) as WebPartnerType,
    googleAds: googleAdsItem ? convertWebGoogleAds(googleAdsItem) : null,
  };
}

const defaultContentFilter = (): boolean => true;

export function convertWebDepartment(
  data,
  value,
  contentFilter: (value: WebTemplateData) => boolean = defaultContentFilter,
): WebDepartment {
  const element: KenticoWebDepartmentData = value?.elements;
  return {
    type: WebTemplateTypes.WebDepartment,
    id: value?.system?.codename ?? '',
    title: element?.title?.value ?? '',
    name: element?.name?.value ?? '',
    showDivider: (element?.show_divider?.value[0]?.name ?? '').toLowerCase() === 'true',
    content: element?.content?.value?.map((value) => convertWebTemplateElement(data, value)).filter(contentFilter),
  };
}

function convertWebCard(data, value, options?: WebCardOptions): WebCard {
  const element: KenticoWebCardData = value?.elements;
  return {
    type: WebTemplateTypes.WebCard,
    id: value?.system?.codename ?? '',
    title: element?.title?.value ?? '',
    subTitle: element?.sub_title?.value ?? '',
    layoutSize: (options?.layoutSize ?? element?.layout_size?.value[0]?.name ?? '') as WebCardLayoutSize,
    layoutType: (options?.layoutType ?? element?.layout_type?.value[0]?.name ?? '') as WebCardLayoutType,
    backgroundImage: convertImage(element?.background_image),
    link: formatButtonMenuItem(data, element?.link?.value?.[0] ?? ''),
    cta: element?.cta?.value?.map((value) => convertWebCtaButton(data, data?.modular_content[value])),
    analytic: convertWebGoogleAnalyticsSnippet(value?.elements),
  };
}

function convertWebCardRosterArchive(data, value, options?: WebCardOptions): WebCardRosterArchive {
  const element: KenticoWebCardData = value?.elements;
  return {
    type: WebTemplateTypes.WebCardRosterArchive,
    id: value?.system?.codename ?? '',
    title: element?.title?.value ?? '',
    subTitle: element?.sub_title?.value ?? '',
    layoutSize: (options?.layoutSize ?? element?.layout_size?.value[0]?.name ?? '') as WebCardLayoutSize,
    layoutType: (options?.layoutType ?? element?.layout_type?.value[0]?.name ?? '') as WebCardLayoutType,
    backgroundImage: convertImage(element?.background_image),
    link: formatButtonMenuItem(data, element?.link?.value?.[0] ?? ''),
    cta: element?.cta?.value?.map((value) => convertWebCtaButton(data, data?.modular_content[value])),
    analytic: convertWebGoogleAnalyticsSnippet(value?.elements),
  };
}

function convertWebPageImage(data, value): WebPageImage {
  const element: KenticoWebPageImageData = value?.elements;
  return {
    type: WebTemplateTypes.WebPageImage,
    id: value?.system?.codename ?? '',
    image: convertImage(element?.image),
    size: (element?.image_size?.value?.[0]?.codename ?? '') as WebPageImageSizeType,
  };
}

function convertWebPageVideo(data, value): WebPageVideo {
  const element: KenticoWebPageVideoData = value?.elements;
  return {
    type: WebTemplateTypes.WebPageVideo,
    id: value?.system?.codename ?? '',
    videoId: element?.dailymotion_id?.value ?? '',
    size: (element?.video_size?.value?.[0]?.codename ?? '') as WebPageVideoSizeType,
  };
}

function convertWebPageTitle(data, value): WebPageTitle {
  const element: KenticoWebPageTitleData = value?.elements;
  return {
    type: WebTemplateTypes.WebPageTitle,
    id: value?.system?.codename ?? '',
    title: element?.page_title?.value ?? '',
    subTitle: element?.page_subtitle?.value ?? '',
  };
}

function convertWebPageSeparator(data, value): WebPageSeparator {
  const element: KenticoWebPageSeparatorData = value?.elements;
  return {
    type: WebTemplateTypes.WebPageSeparator,
    id: value?.system?.codename ?? '',
    size: (element?.size?.value?.[0]?.codename ?? WebPageSeparatorSizeType.Full) as WebPageSeparatorSizeType,
  };
}

function convertWebEditorial(data, value): WebEditorial {
  const element: KenticoWebEditorialData = value?.elements;
  const modularContent = mapEditorialModularContent(data, element?.content?.modular_content ?? []);

  return {
    type: WebTemplateTypes.WebEditorial,
    id: value?.system?.codename ?? '',
    content: mapEditorialContent(element?.content?.value ?? '', modularContent),
  };
}

function convertWebEditorialDuo(data, value): WebEditorialDuo {
  const element: KenticoWebEditorialDuoData = value?.elements;
  return {
    id: value?.system?.codename ?? '',
    type: WebTemplateTypes.WebEditorialDuo,
    isContentFirst: !!element?.display_order?.value?.[0]?.codename,
    content: element?.content?.value ?? '',
    image: convertImage(element?.image),
  };
}

function convertWebEmbedded(
  data: KenticoWebTemplate,
  value: KenticoItem<KenticoWebEmbeddedData>,
): WebEmbedded {
  return {
    id: value?.system?.codename ?? '',
    type: WebTemplateTypes.WebEmbedded,
    content: Buffer.from(value?.elements?.html?.value ?? '').toString('base64'),
  };
}

function convertWebEmbeddedOneTrust(
  data: KenticoWebTemplate,
  value: KenticoItem<KenticoWebEmbeddedData>,
): WebEmbeddedOneTrust {
  return {
    id: value?.system?.codename ?? '',
    type: WebTemplateTypes.WebEmbeddedOneTrust,
    content: Buffer.from(value?.elements?.html?.value ?? '').toString('base64'),
  };
}

function convertWebEmbeddedOverlay(
  data: KenticoWebTemplate,
  value: KenticoItem<KenticoWebEmbeddedData>,
): WebEmbeddedOverlay {
  return {
    id: value?.system?.codename ?? '',
    type: WebTemplateTypes.WebEmbeddedOverlay,
    content: Buffer.from(value?.elements?.html?.value ?? '').toString('base64'),
    delay: value?.elements?.delay?.value ?? 0,
  };
}

function convertWebGoogleMaps(
  data: KenticoWebTemplate,
  value: KenticoItem<KenticoWebGoogleMapsData>,
): WebGoogleMaps {
  const element: KenticoWebGoogleMapsData = value?.elements;
  return {
    type: WebTemplateTypes.WebGoogleMaps,
    id: value?.system?.codename ?? '',
    map: {
      zoom: GOOGLE_MAPS_ZOOM,
      position: {
        lat: Number(element?.latitude?.value ?? ''),
        lng: Number(element?.longitude?.value ?? ''),
      },
    },
  };
}

function convertWebTabItem(data, value): WebTabItem {
  const element: KenticoWebTabItemData = value?.elements;
  return {
    type: WebTemplateTypes.WebTabItem,
    id: value?.system?.codename ?? '',
    name: element?.name?.value ?? '',
    isVisible: (element?.is_visible?.value[0]?.name ?? '').toLowerCase() === 'true',
    url: element?.url?.value ?? '',
    content: element?.content?.value?.map((item) => convertWebTemplateElement(data, item)) ?? [],
    ...mapSeoData(element, KenticoLangToLangCodeMap[data?.item?.system?.language]),
  };
}

function convertWebTabs(data, value): WebTabs {
  const element: KenticoWebTabsData = value?.elements;
  return {
    type: WebTemplateTypes.WebTabs,
    id: value?.system?.codename ?? '',
    tabs: element?.tabs?.value?.map((tab) => convertWebTemplateElement(data, tab) as WebTabItem) ?? [],
  };
}

function convertWebLanding(data, value): WebLanding {
  const element: KenticoWebLandingData = value?.elements;
  return {
    type: WebTemplateTypes.WebLanding,
    id: value?.system?.codename ?? '',
    name: element?.name?.value ?? '',
    isVisible: (element?.is_visible?.value[0]?.name ?? '').toLowerCase() === 'true',
    content: element?.content?.value?.map((value) => convertWebTemplateElement(data, value)),
    url: element?.web_url?.value ?? '',
  };
}

function convertWebHomeLanding(data, value): WebHomeLanding {
  const element: KenticoWebHomeLandingData = value?.elements;
  const layoutSize = (element?.layout_size?.value[0]?.name ?? '') as WebCardLayoutSize;
  return {
    type: WebTemplateTypes.WebHomeLanding,
    id: value?.system?.codename ?? '',
    title: element?.title?.value ?? '',
    layoutSize,
    content: element?.content?.value?.map((value) => convertWebCard(data, data?.modular_content[value], {
      layoutSize,
    })),
  };
}

function convertWebHomeSlider(data, value): WebHomeSlider {
  const element: KenticoWebHomeLandingData = value?.elements;
  const layoutSize = (element?.layout_size?.value[0]?.name ?? '') as WebSliderLayoutSize;
  return {
    type: WebTemplateTypes.WebHomeSlider,
    id: value?.system?.codename ?? '',
    title: element?.title?.value ?? '',
    layoutSize,
    content: element?.content?.value?.map((value) => convertWebCard(data, data?.modular_content[value], {
      layoutSize: WebCardLayoutSize.Large,
    })),
  };
}

function convertWebTicketsPartners(data, value): WebTicketsPartners {
  const element: KenticoWebTicketsPartners = value?.elements;
  return {
    type: WebTemplateTypes.WebTicketsPartners,
    id: value?.system?.codename ?? '',
    partner1: {
      url: element?.partner_url1?.value ?? '',
      logo: convertImage(element?.partner_logo1),
    },
    partner2: {
      url: element?.partner_url2?.value ?? '',
      logo: convertImage(element?.partner_logo2),
    },
    partner3: {
      url: element?.partner_url3?.value ?? '',
      logo: convertImage(element?.partner_logo3),
    },
    partner4: {
      url: element?.partner_url4?.value ?? '',
      logo: convertImage(element?.partner_logo4),
    },
  };
}

function convertWebShare(data, value): WebShare {
  return {
    type: WebTemplateTypes.WebShare,
    id: value?.system?.codename ?? '',
  };
}

function convertWebFaqCategory(
  data: KenticoWebTemplate,
  value: KenticoWebFaqCategory,
): WebFaqCategory {
  const questions = value?.elements?.questions?.value?.map((questionId) => {
    const questionData = data?.modular_content[questionId] as KenticoFaqWebQuestionItem;
    return convertQuestion(questionData);
  });

  return {
    type: WebTemplateTypes.WebFaqCategory,
    id: value?.system?.codename ?? '',
    categoryName: value?.elements?.category_name?.value ?? '',
    questions,
  };
}

function convertWebFaqCategoryGroup(
  data: KenticoWebTemplate,
  value: KenticoWebFaqCategoryGroup,
): WebFaqGroup {
  return {
    type: WebTemplateTypes.WebFaqCategoryGroup,
    id: value?.system?.codename ?? '',
    content: value?.elements?.categories?.value?.map((value) => convertWebTemplateElement(data, value)),
  };
}

function convertWebContentGroup(
  data: KenticoWebTemplate,
  value: KenticoItem<KenticoWebContentGroup>,
): WebContentGroup {
  return {
    type: WebTemplateTypes.WebContentGroup,
    id: value?.system?.codename,
  };
}

function convertWebHallOfFameInducteeStat(
  stat: KenticoItem<KenticoWebHallOfFameInducteeStatData>,
): WebHallOfFameInducteeStat {
  return {
    id: stat?.system?.id,
    type: WebTemplateTypes.WebHallOfFameInducteeStat,
    label: stat?.elements?.stat_label.value || '',
    value: stat?.elements?.stat_value.value || '',
  };
}

export function convertWebHallOfFameInductee(
  data: KenticoWebTemplate,
  value: KenticoItem<KenticoWebHallOfFameInducteeData>,
): WebHallOfFameInductee {
  const firstName = value?.elements?.first_name?.value;
  const lastName = value?.elements?.last_name?.value;
  const fullName = `${firstName?.length ? `${firstName} ` : ''}${lastName}`;
  const slugName = encodeURI(fullName?.replace(/\s/g, '-').toLowerCase());

  return {
    type: WebTemplateTypes.WebHallOfFameInductee,
    id: value?.system?.codename,
    name: {
      first: value?.elements?.first_name?.value,
      last: value?.elements?.last_name?.value,
      full: `${value?.elements?.first_name?.value?.length ? `${value?.elements?.first_name?.value} ` : ''}${value?.elements?.last_name?.value}`,
    },
    slug: value?.elements?.url_slug?.value || slugName,
    year: value?.elements?.year?.value?.[0]?.name,
    role: value?.elements?.role?.value?.[0]?.codename,
    number: value?.elements?.number?.value,
    biography: value?.elements?.biography?.value,
    image: {
      hero: value?.elements?.image_hero?.value?.[0],
      card: value?.elements?.image_card?.value?.[0],
    },
    stats: value?.elements?.stats?.modular_content
      ?.map((codename) => convertWebHallOfFameInducteeStat(data?.['modular_content']?.[codename as string] as KenticoItem<KenticoWebHallOfFameInducteeStatData>)),
    career: {
      title: value?.elements?.career_title?.value,
      table: value?.elements?.career?.value,
      years: {
        main: value?.elements?.career_years?.value,
        additional: value?.elements?.career_years_2?.value,
      },
    },
  };
}

function convertWebHallOfFameHighlights(
  data: KenticoWebTemplate,
  value: KenticoItem<KenticoWebHallOfFameHighlightsData>,
): WebHallOfFameHighlights {
  const inductees = value?.elements?.highlighted_inductees?.value?.map((inducteeId) => {
    const item = data?.['modular_content']?.[inducteeId] as KenticoItem<KenticoWebHallOfFameInducteeData>;
    return convertWebHallOfFameInductee(data, item);
  });
  const sponsor = data?.['modular_content']?.[value?.elements?.sponsor_banner?.value?.[0]] as KenticoItem<KenticoWebGoogleAds>;

  return {
    type: WebTemplateTypes.WebHallOfFameHighlights,
    id: value?.system?.codename,
    logo: value?.elements?.hall_of_fame_hero__logo?.value?.[0],
    title: value?.elements?.hall_of_fame_hero__title?.value,
    banner: {
      title: value?.elements?.banner_title?.value,
      description: value?.elements?.banner_text?.value,
      cta: convertWebCtaButton(data, data?.['modular_content']?.[value?.elements?.banner_cta?.value?.[0]]),
    },
    sponsor: sponsor ? convertWebGoogleAds(sponsor) : undefined,
    inductees,
  };
}

function convertWebHallOfFameInductees(
  data: KenticoWebTemplate,
  value: KenticoItem<KenticoWebHallOfFameInducteesData>,
): WebHallOfFameInductees {
  return {
    type: WebTemplateTypes.WebHallOfFameInductees,
    id: value?.system?.codename,
    logo: value?.elements?.hall_of_fame_hero__logo?.value?.[0],
    title: value?.elements?.hall_of_fame_hero__title?.value,
    filters: value?.elements?.filters?.value?.[0]?.codename,
  };
}

const converters: {[propName: string]: Function} = {
  [WebTemplateTypes.WebDepartment]: convertWebDepartment,
  [WebTemplateTypes.WebPerson]: convertWebPerson,
  [WebTemplateTypes.WebPartner]: convertWebPartner,
  [WebTemplateTypes.WebLanding]: convertWebLanding,
  [WebTemplateTypes.WebCard]: convertWebCard,
  [WebTemplateTypes.WebCardRosterArchive]: convertWebCardRosterArchive,
  [WebTemplateTypes.WebPageImage]: convertWebPageImage,
  [WebTemplateTypes.WebPageVideo]: convertWebPageVideo,
  [WebTemplateTypes.WebPageTitle]: convertWebPageTitle,
  [WebTemplateTypes.WebPageSeparator]: convertWebPageSeparator,
  [WebTemplateTypes.WebEditorial]: convertWebEditorial,
  [WebTemplateTypes.WebEditorialDuo]: convertWebEditorialDuo,
  [WebTemplateTypes.WebEmbedded]: convertWebEmbedded,
  [WebTemplateTypes.WebEmbeddedOneTrust]: convertWebEmbeddedOneTrust,
  [WebTemplateTypes.WebEmbeddedOverlay]: convertWebEmbeddedOverlay,
  [WebTemplateTypes.WebTabItem]: convertWebTabItem,
  [WebTemplateTypes.WebTabs]: convertWebTabs,
  [WebTemplateTypes.WebCtaButton]: convertWebCtaButton,
  [WebTemplateTypes.WebCtaImage]: convertWebCtaImage,
  [WebTemplateTypes.WebCtaDuoButton]: convertWebCtaDuoButton,
  [WebTemplateTypes.WebTicketsPartners]: convertWebTicketsPartners,
  [WebTemplateTypes.WebShare]: convertWebShare,
  [WebTemplateTypes.WebFaqCategoryGroup]: convertWebFaqCategoryGroup,
  [WebTemplateTypes.WebFaqCategory]: convertWebFaqCategory,
  [WebTemplateTypes.WebContentGroup]: convertWebContentGroup,
  [WebTemplateTypes.WebGoogleAds]: convertWebGoogleAds,
  [WebTemplateTypes.WebGoogleMaps]: convertWebGoogleMaps,
  [WebTemplateTypes.WebHomeLanding]: convertWebHomeLanding,
  [WebTemplateTypes.WebHomeSlider]: convertWebHomeSlider,
  // HALL OF FAME
  [WebTemplateTypes.WebHallOfFameHighlights]: convertWebHallOfFameHighlights,
  [WebTemplateTypes.WebHallOfFameInductees]: convertWebHallOfFameInductees,
  [WebTemplateTypes.WebHallOfFameInductee]: convertWebHallOfFameInductee,
};

const convertNews = (data: KenticoItem<KenticoWebContentGroup>[]): NewsConfiguration[] => (
  data
    .filter((value) => value.elements.editorial_type?.value
      ?.find(({ codename }) => [
        EditorialContentTypes.NEWS, EditorialContentTypes.VIDEO, EditorialContentTypes.PHOTO,
      ].includes(codename)))
    .map((value) => {
      const type = value.elements?.editorial_type?.value?.map(({ codename }) => codename) ?? [];
      const formattedData: NewsConfiguration = {
        layoutConfiguration: LayoutConfiguration[value.elements.web_editorial_layout?.value[0]?.name],
        id: value?.system?.codename ?? '',
        type,
      };

      if (value.elements.tags?.value?.length) {
        formattedData.tags = value.elements.tags?.value?.map(({ codename }) => codename) ?? [];
      }

      if (type.length === 1) {
        switch (type[0]) {
          case EditorialContentTypes.VIDEO:
            formattedData.categories = value.elements?.videos?.value?.map(({ codename }) => codename) ?? [];
            break;
          case EditorialContentTypes.PHOTO:
            formattedData.categories = value.elements?.photo_gallery?.value?.map(({ codename }) => codename) ?? [];
            break;
          // case EditorialContentTypes.NEWS:
          default:
            formattedData.categories = value.elements?.news?.value?.map(({ codename }) => codename) ?? [];
            break;
        }
      }

      return formattedData;
    })
);

function isKenticoGroupTemplate(template: KenticoItem<KenticoWebTemplateData>):
  template is KenticoItem<KenticoWebContentGroup> {
  return template?.system?.type === WebTemplateTypes.WebContentGroup;
}

type ConvertWebTemplate = (
  data: KenticoWebTemplate
) => WebTemplate

export const convertWebTemplate: ConvertWebTemplate = (data) => {
  const values = data?.item?.elements?.web_template?.value ?? [];
  const newsTemplates: KenticoItem<KenticoWebContentGroup>[] = Object.values(data?.modular_content ?? {})
    .filter(isKenticoGroupTemplate);

  return {
    templateHierarchy: values.map((value) => convertWebTemplateElement(data, value)),
    newData: convertNews(newsTemplates),
  };
};
