import React from 'react';
import Countdown, { CountdownRenderProps, zeroPad } from 'react-countdown';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

interface CountDownProps {
  date: Date;
}

// Styled Renderer callback with condition
const Renderer = ({
  days, hours, minutes, seconds, completed,
}: CountdownRenderProps): React.ReactElement | null => (completed ? null : (
  <DateTime>
    {zeroPad(days)}
    <span>
      <FormattedMessage id="match.countdown.days" />
    </span>
    &nbsp;
    {zeroPad(hours)}
    <span>
      <FormattedMessage id="match.countdown.hours" />
    </span>
    &nbsp;
    {zeroPad(minutes)}
    <span>
      <FormattedMessage id="match.countdown.minutes" />
    </span>
    &nbsp;
    {zeroPad(seconds)}
    <span>
      <FormattedMessage id="match.countdown.seconds" />
    </span>
  </DateTime>
));

const CountDown = ({ date }: CountDownProps): React.ReactElement => (
  <>
    <Heading>
      <FormattedMessage id="match.kickoff" />
    </Heading>
    <Countdown renderer={Renderer} date={date} />
  </>
);

export default CountDown;

const Heading = styled.p`
  font-family: Raleway;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  text-align: center;
  color: #000;
  text-transform: none;
  margin-bottom: 5px;
`;

const DateTime = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;

  font-family: MilanTypeRev;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.39px;
  text-align: center;
  color: black;
  margin-bottom: 10px;

  & span {
    font-size: 14px;
    line-height: 15px;
    margin-left: 2px;
    margin-right: 3px;
  }
`;
