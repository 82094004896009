import React from 'react';

import { VideoPlayerTypes } from '@app/types/videoPlayerTypes';
import { DailyMotionPlayer, TencentPlayer } from '@app/components';
import { useLanguage } from '@app/components/Hooks';
import { AppLanguagesMap } from '@app/constants/localizationConstants';

const VideoPlayer = (props: VideoPlayerTypes): React.ReactElement => {
  const { video, hidePremiumLabel } = props;
  const language = useLanguage();

  return (
    [AppLanguagesMap.en, AppLanguagesMap.it].includes(language)
      ? (
        <DailyMotionPlayer
          videoId={video?.videoId ?? ''}
          isPremium={video?.isPremium}
          hidePremiumLabel={hidePremiumLabel}
          coverImageUrl={video?.image?.url}
          {...props}
        />
      ) : (
        <TencentPlayer videoId={video?.tencentVideoId ?? ''} {...props} />
      )
  );
};

export default VideoPlayer;
