import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { MatchResult } from '@app/types/matchTypes';
import { MatchStatusMap } from '@app/constants/matchConstants';
import { LocalizedDate } from '@app/components/Localization';
import { useMatchResultsSelector } from '@app/components/MatchDay/MatchDayHooks';
import { useGameByUrlSlugSelector, useMatchDayResultsSelector } from '../MatchCenterHooks';

interface ResultProps {
  result: MatchResult;
}
const Result = ({ result }: ResultProps): React.ReactElement => {
  const {
    stadium, matchDate, matchStatus, matchTime, isHalfTime, teams: { first, second },
  } = useMatchResultsSelector(result);

  return (
    <Fixture>
      <Stadium>{stadium}</Stadium>
      <Teams>
        <Logo src={result.teams.first.logoUrl} alt={result.teams.first.shortName} />
        <Name>{result.teams.first.shortName}</Name>
        {matchStatus === MatchStatusMap.upcoming
          ? (<DateTime><LocalizedDate date={new Date(matchDate)} /></DateTime>)
          : (<Score>{`${first.goals} - ${second.goals}`}</Score>)}
        <Name>{result.teams.second.shortName}</Name>
        <Logo src={result.teams.second.logoUrl} alt={result.teams.second.shortName} />
      </Teams>
      <Time>
        {matchStatus === MatchStatusMap.upcoming && (
          <>
            <FormattedNumber value={(new Date(matchDate)).getHours()} minimumIntegerDigits={2} />
            :
            <FormattedNumber value={(new Date(matchDate)).getMinutes()} minimumIntegerDigits={2} />
          </>
        )}
        {matchStatus === MatchStatusMap.live && !isHalfTime && matchTime}
        {matchStatus === MatchStatusMap.live && isHalfTime && (
          <FormattedMessage id="match.halfTime" />
        )}
        {matchStatus === MatchStatusMap.finished && (<FormattedMessage id="match.fullTime" />)}
      </Time>
    </Fixture>
  );
};

const MatchFixtures = (): React.ReactElement => {
  const results = useMatchDayResultsSelector();
  const game = useGameByUrlSlugSelector();

  return (
    <Container>
      <Heading>
        {game?.competition?.name}
        &nbsp;-&nbsp;
        <FormattedMessage id="match.day" />
        &nbsp;
        {results?.[0]?.matchDay}
      </Heading>
      {results.map((result) => (<Result result={result} />))}
    </Container>
  );
};

export default MatchFixtures;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: white;
  color: black;

  @media ${Devices.tablet} {
    padding: 20px;
  }
`;

const Heading = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 20px;
`;

const Fixture = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: #f5f5f5;
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Stadium = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  margin: 0;
  color: #323232;
`;

const Time = styled(Stadium)`
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 0;
`;


const Teams = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.img`
  display: flex;
  flex: 0 0 45px;
  width: 100%;
  height: 100%;
  max-width: 45px;
  max-height: 45px;
`;

const Name = styled.span`
  display: flex;
  flex: 0 0 60px;
  justify-content: center;
  align-items: center;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #323232;
  word-break: break-word;
  text-transform: uppercase;
  padding: 0 5px;
`;

const Score = styled(Name)`
  flex: 1;
  font-size: 30px;
  line-height: normal;
  text-transform: uppercase;
`;

const DateTime = styled(Name)`
  flex: 1;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  padding: 0;
`;
