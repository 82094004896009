import styled from 'styled-components';
import { Devices } from '@app/styles';

export const Section = styled.section`
  padding: 20px 10px;

  @media ${Devices.mediumMobile} {
    padding: 20px;
  }

  @media ${Devices.tablet} {
    padding: 40px;
  }

  @media ${Devices.desktopSmall} {
    padding: 50px 70px;
  }
`;

export const GraySection = styled(Section)`
  background-color: ${(props): string => props.theme.color.whiteSmoke};
`;

export const GraySectionReducePadding = styled(GraySection)`
  @media ${Devices.tablet} {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @media ${Devices.desktopSmall} {
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

export const GraySectionWideReducePadding = styled(GraySectionReducePadding)`
  padding-left: 0;
  padding-right: 0;

  @media ${Devices.mediumMobile} {
    padding-left: 0;
    padding-right: 0;
  }

  @media ${Devices.tablet} {
    padding-left: 0;
    padding-right: 0;
  }

  @media ${Devices.desktopSmall} {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const BlackSection = styled(Section)`
  background-color: ${(props): string => props.theme.color.veryDarkGray};
`;

export const BackgroundGradient = styled.div`
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 87%);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  pointer-events: none;
`;
