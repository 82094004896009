import React from 'react';

const UserIcon = (): React.ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" id="icon" viewBox="0 0 12 14">
    <g>
      <path fillRule="nonzero" strokeWidth=".5" d="M11 12.455a.55.55 0 0 1-.556.545.55.55 0 0 1-.555-.545c0-2.109-1.742-3.819-3.889-3.819s-3.889 1.71-3.889 3.819a.55.55 0 0 1-.555.545.55.55 0 0 1-.556-.545c0-2.712 2.239-4.91 5-4.91s5 2.198 5 4.91zM6 7C4.313 7 2.944 5.657 2.944 4S4.313 1 6 1s3.056 1.343 3.056 3S7.687 7 6 7zm0-1.09c1.074 0 1.944-.855 1.944-1.91S7.074 2.09 6 2.09c-1.074 0-1.944.855-1.944 1.91S4.926 5.91 6 5.91z" />
      <circle stroke="#FFF" fill="#C9240D" cx="10" cy="3" r="2.5" id="logged-in-dot" />
    </g>
  </svg>
);

export default UserIcon;
