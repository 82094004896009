import React from 'react';
import { FormattedMessage } from 'react-intl';

import theme from '@app/styles/theme';
import { MatchStatistics } from '@app/types/matchTypes';
import { useLineupsSelector } from '@app/pages/MatchCenter/MatchCenterHooks';
import {
  BlockContainerColumn, Caption, StatsBlock, Value, ValueAndGraph,
} from '@app/components/Statistics';

const MatchPasses = (): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore // TODO:REMOVE:ignore
  const { home, away } = useLineupsSelector() ?? {};

  return (
    <BlockContainerColumn>
      <StatsBlock marginTop="20px" marginBottom="40px">
        <StatsBlock>
          <Value>{`${home?.stats?.[MatchStatistics.STATS_PASSES_TOTAL]}`}</Value>
        </StatsBlock>
        <StatsBlock>
          <Caption><FormattedMessage id="statistics.passes" /></Caption>
        </StatsBlock>
        <StatsBlock>
          <Value>{`${away?.stats?.[MatchStatistics.STATS_PASSES_TOTAL]}`}</Value>
        </StatsBlock>
      </StatsBlock>
      <StatsBlock marginBottom="40px">
        <StatsBlock>
          <ValueAndGraph
            caption="statistics.successful"
            progress={
              (home?.stats?.[MatchStatistics.STATS_SUCCESFULL_PASSES] * 100)
              / home?.stats?.[MatchStatistics.STATS_PASSES_TOTAL]
            }
            value={`${
              home?.stats?.[MatchStatistics.STATS_PASSES_TOTAL] === 0 ? 0 : (
                Math.round((home?.stats?.[MatchStatistics.STATS_SUCCESFULL_PASSES] * 100)
                  / home?.stats?.[MatchStatistics.STATS_PASSES_TOTAL])
              )
            }%`}
          />
        </StatsBlock>
        <StatsBlock>
          <ValueAndGraph
            strokeHomeColor={theme.color.veryDarkGray}
            caption="statistics.successful"
            progress={
              (away?.stats?.[MatchStatistics.STATS_SUCCESFULL_PASSES] * 100)
              / away?.stats?.[MatchStatistics.STATS_PASSES_TOTAL]
            }
            value={`${
              away?.stats?.[MatchStatistics.STATS_PASSES_TOTAL] === 0 ? 0 : (
                Math.round((away?.stats?.[MatchStatistics.STATS_SUCCESFULL_PASSES] * 100)
                  / away?.stats?.[MatchStatistics.STATS_PASSES_TOTAL])
              )
            }%`}
          />
        </StatsBlock>
      </StatsBlock>
      <StatsBlock marginBottom="40px">
        <StatsBlock>
          <Value>{`${home?.stats?.[MatchStatistics.STATS_CROSSES_TOTAL]}`}</Value>
        </StatsBlock>
        <StatsBlock>
          <Caption><FormattedMessage id="statistics.crossovers" /></Caption>
        </StatsBlock>
        <StatsBlock>
          <Value>{`${away?.stats?.[MatchStatistics.STATS_CROSSES_TOTAL]}`}</Value>
        </StatsBlock>
      </StatsBlock>
      <StatsBlock marginBottom="20px">
        <StatsBlock>
          <ValueAndGraph
            caption="statistics.successful"
            progress={
              (home?.stats?.[MatchStatistics.STATS_SUCCESFULL_CROSES] * 100)
              / home?.stats?.[MatchStatistics.STATS_CROSSES_TOTAL]
            }
            value={`${
              home?.stats?.[MatchStatistics.STATS_CROSSES_TOTAL] === 0 ? 0 : (
                Math.round((home?.stats?.[MatchStatistics.STATS_SUCCESFULL_CROSES] * 100)
                  / home?.stats?.[MatchStatistics.STATS_CROSSES_TOTAL])
              )
            }%`}
          />
        </StatsBlock>
        <StatsBlock>
          <ValueAndGraph
            caption="statistics.successful"
            strokeHomeColor={theme.color.veryDarkGray}
            progress={
              (away?.stats?.[MatchStatistics.STATS_SUCCESFULL_CROSES] * 100)
              / away?.stats?.[MatchStatistics.STATS_CROSSES_TOTAL]
            }
            value={`${
              away?.stats?.[MatchStatistics.STATS_CROSSES_TOTAL] === 0 ? 0 : (
                Math.round((away?.stats?.[MatchStatistics.STATS_SUCCESFULL_CROSES] * 100)
                  / away?.stats?.[MatchStatistics.STATS_CROSSES_TOTAL])
              )
            }%`}
          />
        </StatsBlock>
      </StatsBlock>
    </BlockContainerColumn>
  );
};

export default MatchPasses;
