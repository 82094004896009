import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import trimEnd from 'lodash/trimEnd';

import { Header } from '@app/types/configurationTypes';
import { AppState } from '@app/store/reducers';
import { useLanguage } from '@app/components/Hooks';
import { UserCountryType } from '@app/types/userTypes';

export function useHeader(): Header {
  const { locale } = useIntl();
  return useSelector<AppState, Header>(
    (state) => state.configuration[locale]?.header,
    shallowEqual,
  );
}

export function useIsHomeDetection(): boolean {
  const { formatMessage } = useIntl();
  const language = useLanguage();
  const homeUrl = formatMessage({ id: 'routes.home' });

  return !!useRouteMatch({
    path: `${trimEnd(homeUrl, '/')}/${language}`,
    exact: true,
  });
}

export function useUserCountry(): UserCountryType {
  return useSelector<AppState, UserCountryType>(
    (state) => state.user.country ?? 'IT',
    shallowEqual,
  );
}
