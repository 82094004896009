import React from 'react';

import { GA_EVENT, GA_CATEGORY } from '@app/types/googleTypes';
import { BaseNewsItem, NewsTypes } from '@app/types/newsTypes';

import {
  ArticleItemPartiallyOverlapped, TwoThreeColumnGrid,
} from '@app/components';
import { useLanguage, useLatestArticlesHook, useLatestNewsLinkParams } from '@app/components/Hooks';
import { pushGAEvent } from '@app/helpers/googleHelpers';

import HomeSection from './HomeSection';
import { GraySection } from './HomeStyledComponents';

const HomeArticles: React.FC = () => {
  const language = useLanguage();

  const latestArticlesLinkParams = useLatestNewsLinkParams(NewsTypes.ARTICLES);
  const latestNews = useLatestArticlesHook();

  return !latestNews.length ? null : (
    <GraySection>
      <HomeSection
        sectionNameId="news.latest"
        linkData={{
          linkParams: latestArticlesLinkParams,
          linkNameId: 'home.seeMore',
          onClick: (): void => pushGAEvent({
            event: GA_EVENT.CLICK_HOME,
            category: GA_CATEGORY.NEWS,
            label: 'see_more',
            language,
          }),
        }}
      >
        <TwoThreeColumnGrid
          ItemComponent={ArticleItemPartiallyOverlapped}
          items={latestNews}
          isHomePage
          itemAdditionalProps={{
            onClick: ({ title }: BaseNewsItem): void => pushGAEvent({
              event: GA_EVENT.CLICK_HOME,
              category: GA_CATEGORY.NEWS,
              label: title,
              language,
            }),
          }}
        />
      </HomeSection>
    </GraySection>
  );
};

export default HomeArticles;
