import React, { useState, useEffect } from 'react';

import { NewsTypes } from '@app/types/newsTypes';
import { GoogleAdsProps } from '@app/types/googleTypes';

import { useAdsStatus } from '@app/components/GoogleAds/GoogleAdsHooks';
import { useNewsListSizeSelector, useNewsListHasNextItemsSelector } from './NewsListHooks';
import {
  NewsListGrid, NewsListGridItem, NewsListAds, NewsListLoadButton,
} from './components';

type NewsListProps = React.FC<{
  type: NewsTypes;
  ads?: GoogleAdsProps;
}>

const NewsList: NewsListProps = ({ type, ads }) => {
  const isSlotVisible = useAdsStatus(`${ads?.adUnit}`) === true;
  const hasMoreData = useNewsListHasNextItemsSelector(type);
  const size = useNewsListSizeSelector(type);
  const [limit, setLimit] = useState(size);

  useEffect(() => {
    if (isSlotVisible) {
      if (hasMoreData) setLimit(limit - 1);
      if (!hasMoreData && limit > size) setLimit(limit + 1);
    }
  }, [isSlotVisible, hasMoreData]);
  return (
    <>
      <NewsListGrid size={size}>
        {Array(limit).fill('').map((value, id) => (
          <>
            {id === 2 && !!ads && (<NewsListAds ads={ads} />)}
            {/* eslint-disable-next-line react/no-array-index-key */}
            <NewsListGridItem key={size + id} id={id} type={type} />
          </>
        ))}
      </NewsListGrid>
      <NewsListLoadButton type={type} onClick={(): void => setLimit(limit + size)} />
    </>
  );
};

export default NewsList;
