import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import { MatchStatistics } from '@app/types/matchTypes';
import { useLineupsSelector } from '@app/pages/MatchCenter/MatchCenterHooks';
import {
  BlockContainerColumn, StatsBlock, Value, Caption, YellowCardImage, RedCardImage,
} from '@app/components/Statistics';

const MatchDisciplines = (): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore // TODO:REMOVE:ignore
  const { home, away } = useLineupsSelector() ?? {};

  return (
    <BlockContainerColumn>
      <StatsBlock>
        <StatsBlock marginTop="20px" marginBottom="20px">
          <YellowCardImage />
          <Value>{`${home?.stats?.[MatchStatistics.STATS_YELLOW_CARDS]}`}</Value>
        </StatsBlock>
        <StatsBlock>
          <Caption><FormattedHTMLMessage id="statistics.match.yellowCards" /></Caption>
        </StatsBlock>
        <StatsBlock>
          <Value>{`${away?.stats?.[MatchStatistics.STATS_YELLOW_CARDS]}`}</Value>
          <YellowCardImage />
        </StatsBlock>
      </StatsBlock>
      <StatsBlock marginTop="20px" marginBottom="20px">
        <StatsBlock>
          <RedCardImage />
          <Value>{`${home?.stats?.[MatchStatistics.STATS_RED_CARDS]}`}</Value>
        </StatsBlock>
        <StatsBlock>
          <Caption><FormattedHTMLMessage id="statistics.match.redCards" /></Caption>
        </StatsBlock>
        <StatsBlock>
          <Value>{`${away?.stats?.[MatchStatistics.STATS_RED_CARDS]}`}</Value>
          <RedCardImage />
        </StatsBlock>
      </StatsBlock>
    </BlockContainerColumn>
  );
};

export default MatchDisciplines;
