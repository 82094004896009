import React, { useState } from 'react';
import styled from 'styled-components';
import chunk from 'lodash/chunk';
import { FormattedMessage } from 'react-intl';

import { GA_EVENT, GA_CATEGORY } from '@app/types/googleTypes';
import { MenuItemTypes, MenuItem as MenuItemInterface } from '@app/types/configurationTypes';
import { formatRelatedItemLinkProps, formatSubMenuLinkProps } from '@app/helpers/menuHelpers';
import { pushGAEvent } from '@app/helpers/googleHelpers';
import { LocalizedLink } from '@app/components/Localization';
import { useMenSquadAllCompetitionsParams } from '@app/pages/Season/Season/SeasonHooks';
import { useLanguage } from '@app/components/Hooks';

interface MenuItemProps {
  item: MenuItemInterface;
}

const MenuItem = ({ item }: MenuItemProps): React.ReactElement => {
  const [isOpened, setIsOpened] = useState(false);

  const language = useLanguage();
  const { seasonId, urlSlug } = useMenSquadAllCompetitionsParams();

  const { relatedItems } = item;
  const subItems = Object.values(item.navigation).filter(({ isVisible }) => isVisible);
  // If (first SecondLevel item is ExternalLink) { make TopLevel item the same ExternalLink }
  const topLevelItem = subItems[0] ?? item;

  const openMenu = (): void => { subItems.length && setIsOpened(true); };
  const closeMenu = (): void => setIsOpened(false);

  const topMenuClick = (): void => {
    pushGAEvent({
      event: GA_EVENT.CLICK_MENU,
      category: GA_CATEGORY.MENU,
      label: item.id,
      language,
    });
    closeMenu();
  };

  return (
    <StyledMenuItem>
      <div
        onMouseOver={openMenu}
        onFocus={openMenu}
        onMouseOut={closeMenu}
        onBlur={closeMenu}
      >
        {topLevelItem.type === MenuItemTypes.ExternalLink && (
          <MenuTitle as="a" {...topLevelItem.external.url} onClick={topMenuClick}>
            {item.name || topLevelItem.external.name}
          </MenuTitle>
        )}
        {topLevelItem.type === MenuItemTypes.BaseMenuItem && (
          <MenuTitle
            onClick={topMenuClick}
            {...formatSubMenuLinkProps(item, topLevelItem, seasonId, urlSlug)}
          >
            {item.name}
          </MenuTitle>
        )}
        {topLevelItem.type === MenuItemTypes.RelatedMenuItem && (
          <MenuTitle
            onClick={topMenuClick}
            {...formatRelatedItemLinkProps(topLevelItem.related, seasonId, urlSlug)}
          >
            {item.name}
          </MenuTitle>
        )}
        <SubMenu isOpened={isOpened}>
          <Background />
          {
            chunk(subItems, 7).map((column, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <SubMenuList key={index}>
                {
                  column.map((subItem) => (
                    <SubMenuItem
                      onClick={(): void => {
                        pushGAEvent({
                          event: GA_EVENT.CLICK_MENU,
                          category: GA_CATEGORY.SUB_MENU,
                          label: subItem.id,
                          language,
                        });
                        closeMenu();
                      }}
                      key={subItem.id}
                    >
                      {((): React.ReactElement => {
                        switch (subItem.type) {
                          case MenuItemTypes.ExternalLink:
                            return (
                              <SubMenuItemTitle as="a" {...subItem.external.url}>
                                {subItem.external.name}
                              </SubMenuItemTitle>
                            );
                          case MenuItemTypes.RelatedMenuItem:
                            return (
                              <SubMenuItemTitle
                                {...formatRelatedItemLinkProps(subItem.related, seasonId, urlSlug)}
                              >
                                {subItem.related.name}
                              </SubMenuItemTitle>
                            );
                          default:
                            return (
                              <SubMenuItemTitle {...formatSubMenuLinkProps(item, subItem, seasonId, urlSlug)}>
                                {subItem.name}
                              </SubMenuItemTitle>
                            );
                        }
                      })()}
                    </SubMenuItem>
                  ))
                }
              </SubMenuList>
            ))
          }

          {relatedItems?.length && (
            <RelatedItemsSubMenuList>
              {chunk(relatedItems, 5).map((column, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <SubMenuList key={index} onMouseOver={openMenu} onFocus={openMenu}>
                  <SubMenuTitle>
                    {index === 0 && <FormattedMessage id="menu.relatedCategories" />}
                  </SubMenuTitle>
                  {
                    column.map((subItem) => (
                      <SubMenuItem
                        onClick={(): void => {
                          pushGAEvent({
                            event: GA_EVENT.CLICK_MENU,
                            category: GA_CATEGORY.SUB_MENU,
                            label: subItem.item.id,
                            language,
                          });
                          closeMenu();
                        }}
                        key={subItem.item.id}
                      >
                        {subItem.type === MenuItemTypes.ExternalLink ? (
                          <SubMenuItemTitle as="a" {...subItem.item.url} rel="nofollow">
                            {subItem.item.name}
                          </SubMenuItemTitle>
                        ) : (
                          <SubMenuItemTitle
                            {...formatRelatedItemLinkProps(subItem.item, seasonId, urlSlug)}
                            isNotFollowed
                          >
                            {subItem.item.name}
                          </SubMenuItemTitle>
                        )}
                      </SubMenuItem>
                    ))
                  }
                </SubMenuList>
              ))}
            </RelatedItemsSubMenuList>
          )}
        </SubMenu>
      </div>
    </StyledMenuItem>
  );
};

export default MenuItem;

const SubMenu = styled.div<{ isOpened: boolean }>`
  --submenu-height: calc(260px + 20px);
  --submenu-additional-top-margin: 30px;

  font-family: ${(props): string => props.theme.fontFamily.raleway};
  display: flex;
  position: absolute;
  top: calc(var(--navigation-header-height) - var(--submenu-additional-top-margin));
  padding-top: var(--submenu-additional-top-margin);
  background-color: transparent;
  font-size: 12px;
  width: auto;
  visibility: ${({ isOpened }): string => (isOpened ? 'visible' : 'hidden')};
  padding-left: var(--munu-item-left-padding);
  z-index: 300;
`;

const MenuTitle = styled(LocalizedLink)`
  opacity: 0.7;
  transition: opacity 0.2s ease-in;
  color: black;
  padding: 10px var(--munu-item-left-padding);

  &:hover {
    color: black;
    text-decoration: none;
  }
`;

const StyledMenuItem = styled.li`
  --munu-item-left-padding: 15px;
  text-transform: uppercase;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  &:hover ${MenuTitle} {
    opacity: 1;
  }
`;

const SubMenuList = styled.ul`
  padding-right: 40px;
  min-width: 200px;
  text-align: center;
  height: var(--submenu-height);
  z-index: 5;
`;

const SubMenuItemTitle = styled(LocalizedLink)`
  transition: opacity 0.2s ease-in;
  color: white;
  display: flex;
  height: 100%;
  align-items: flex-end;
  padding: 5px 0;
  text-transform: capitalize;
  width: 100%;
  text-align: left;
  white-space: nowrap;

  &:hover {
    color: white;
    text-decoration: none;
  }
`;

const SubMenuItem = styled.li`
  height: 35px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  font-size: 14px;

   &:hover {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
`;

const SubMenuTitle = styled.li`
  padding: 15px 0 0;
  height: 35px;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  text-transform: none;
  text-align: left;
`;

const Background = styled.div`
  position: absolute;
  height: var(--submenu-height);
  top: var(--submenu-additional-top-margin);
  opacity: 0.8;
  background-color: #000000;
  left: -100vw;
  right: -100vw;
`;

const RelatedItemsSubMenuList = styled.div`
  opacity: 0.99;
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0) 67%, rgba(255, 255, 255, 0.1));
  padding-left: 30px;
  display: flex;

  & ${SubMenuItem} {
    font-size: 12px;
  }
`;
