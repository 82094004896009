import { HomeActions } from '@app/store/actionTypes/homeActionTypes';
import { TopNews } from '@app/types/newsTypes';
import { HomeMatch } from '@app/types/matchTypes';
import { HomeBlocks } from '@app/types/homeConfigurationTypes';
import {
  WebHomeLanding, WebHomeSlider, WebGoogleAds, WebEmbeddedOverlay,
} from '@app/types/webTemplateTypes';

export interface HomeState {
  gameId: string;
  embeddedOverlay: WebEmbeddedOverlay | null;
  matchBox: HomeMatch[];
  sequence: HomeBlocks[];
  carousel: TopNews[];
  landingData: WebHomeLanding[];
  sliderData: WebHomeSlider[];
  googleAds: WebGoogleAds[];
}

const initialState: HomeState = {
  gameId: '',
  embeddedOverlay: null,
  matchBox: [],
  sequence: [],
  carousel: [],
  landingData: [],
  sliderData: [],
  googleAds: [],
};

export default (
  state = initialState,
  action: HomeActions,
): HomeState => {
  switch (action.type) {
    case 'HOME/SET_MATCHBOX':
      return {
        ...state,
        matchBox: action.payload,
      };
    case 'HOME/SET_GAME_ID':
      return {
        ...state,
        gameId: action.payload,
      };
    case 'HOME/SET_EMBEDDED_OVERLAY':
      return {
        ...state,
        embeddedOverlay: action.payload,
      };
    case 'HOME/SET_SEQUENCE':
      return {
        ...state,
        sequence: action.payload,
      };
    case 'HOME/SET_CAROUSEL_SLIDES':
      return {
        ...state,
        carousel: action.payload,
      };
    case 'HOME/RESET':
      return {
        ...initialState,
      };
    case 'HOME/SET_HOME_LANDING_DATA':
      return {
        ...state,
        landingData: state.landingData.concat(action.payload),
      };
    case 'HOME/SET_HOME_SLIDER_DATA':
      return {
        ...state,
        sliderData: state.sliderData.concat(action.payload),
      };
    case 'HOME/SET_HOME_GOOGLE_ADS':
      return {
        ...state,
        googleAds: state.googleAds.concat(action.payload),
      };
    default:
      return state;
  }
};
