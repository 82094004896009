import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { AppState } from '@app/store/reducers';
import { RoutePath } from '@app/types/routerTypes';
import { GoogleAdsProps } from '@app/types/googleTypes';
import { Errors, PageError } from '@app/types/errorTypes';
import { NewsSubMenuId, BaseMenuItem, LocalizedCategoryMenu } from '@app/types/configurationTypes';
import {
  ArticleItem, Gallery, NewsNavigationTypes, NewsTypes, VideoItem,
} from '@app/types/newsTypes';

import AppRoutes from '@app/constants/routes';
import { GoogleAdsMap } from '@app/constants/googleAdsConstants';
import { mapSecondLevelMenuItems } from '@app/helpers/menuHelpers';
import { findCategoryBySlug } from '@app/helpers/configurationHelpers';
import { getNewsCategories } from '@app/helpers/newsHelpers';
import { getNewsListItems, resetNewsListItems } from '@app/store/actions/newsActions';

import { useLanguage, useMenu } from '@app/components/Hooks';
import { SecondLevelMenuItems } from '@app/components/SecondLevelMenu';

interface MenuData {
  categoryMenuItems: LocalizedCategoryMenu;
  secondLevelMenuItems: SecondLevelMenuItems;
}

export const useMenuSelector = (newsType: NewsSubMenuId): MenuData => {
  const language = useLanguage();

  return useSelector<AppState, MenuData>(
    (state) => {
      const newsMenu = state.configuration[language]?.menu?.web_news;
      const categories = getNewsCategories(state, newsType);

      return {
        categoryMenuItems: categories,
        secondLevelMenuItems: newsMenu ? mapSecondLevelMenuItems({ menu: newsMenu, selectedItemId: newsType }) : [],
      };
    },
    isEqual,
  );
};

export const useNewsNavigationTypeSelector = (type: NewsTypes): NewsNavigationTypes => {
  switch (type) {
    case NewsTypes.VIDEOS: return NewsNavigationTypes.VIDEOS;
    case NewsTypes.PICTURES: return NewsNavigationTypes.PICTURES;
    case NewsTypes.ARTICLES: default: return NewsNavigationTypes.ARTICLES;
  }
};

export const useNewsNavigationPathSelector = (type: NewsTypes): RoutePath => {
  switch (type) {
    case NewsTypes.VIDEOS: return AppRoutes.Videos.path;
    case NewsTypes.PICTURES: return AppRoutes.Gallery.path;
    case NewsTypes.ARTICLES: default: return AppRoutes.Articles.path;
  }
};

export const useNewsListDownload = (type: NewsTypes): void => {
  const { categoryName = '' } = useParams();
  const language = useLanguage();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNewsListItems({ categoryName, language, type }));
    return (): void => { dispatch(resetNewsListItems(type)); };
  }, [categoryName, language]);
};

export const useNewsListHeadingLabelSelector = (type: NewsTypes): string => {
  switch (type) {
    case NewsTypes.VIDEOS: return 'news.allMilanVideos';
    case NewsTypes.PICTURES: return 'news.allMilanPhotos';
    case NewsTypes.ARTICLES: default: return 'news.allMilanNews';
  }
};

export const useNewsListAdsSelector = (type: NewsTypes): GoogleAdsProps => {
  switch (type) {
    case NewsTypes.VIDEOS: return GoogleAdsMap.videoListItem;
    case NewsTypes.PICTURES: return GoogleAdsMap.galleryListItem;
    case NewsTypes.ARTICLES: default: return GoogleAdsMap.newsListItem;
  }
};

export const useNewsCategoryByUrlSlugSelector = (type: NewsTypes): BaseMenuItem | undefined => {
  const navigationType = useNewsNavigationTypeSelector(type);
  const { categoryMenuItems } = useMenuSelector(navigationType);
  const { categoryName = '' } = useParams();
  const language = useLanguage();

  return findCategoryBySlug(categoryMenuItems[language], categoryName);
};

export function useRouterParamsValidation(type: NewsTypes): Errors {
  const navigationType = useNewsNavigationTypeSelector(type);
  const { categoryMenuItems } = useMenuSelector(navigationType);
  const { categoryName = '' } = useParams();
  const language = useLanguage();
  const menu = useMenu();
  const selected = findCategoryBySlug(categoryMenuItems[language], categoryName);

  return menu && !selected ? PageError.NotFound : null;
}

export const useNewsSocialPreviewImageUrl = (news: VideoItem | Gallery | ArticleItem | null | undefined): string => (
  news?.seo?.socialImageUrl?.length ? news?.seo?.socialImageUrl : news?.image?.url ?? ''
);

// TEMPORARY
/*
export const useVideosWithGoogleAds = (): NewsListComponent => {
  const [isGoogleAdsVisible, setIsGoogleAdsVisible] = useState(true);
  const onAdsVisible = (e): void => setIsGoogleAdsVisible(!e.detail?.isEmpty);

  const videos = useVideosSelector();
  const useGoogleAds = isGoogleAdsVisible && !!videos.items.length;
  const items = useGoogleAds && videos.hasMoreData ? [...videos.items.slice(0, -1)] : [...videos.items];

  useGoogleAds && items.splice(2, 0, GoogleAdsMap.videoListItem);

  useEffect(() => {
    window.addEventListener('onAdsVisible', onAdsVisible);
    return (): void => window.removeEventListener('onAdsVisible', onAdsVisible);
  }, []);

  return { ...videos, ...{ items } };
};
*/
