import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useMedia from 'react-use/lib/useMedia';
import styled from 'styled-components';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { Devices } from '@app/styles';
import { getFullScreenImgConfig } from '@app/helpers/imageHelper';
import { TopNews } from '@app/types/newsTypes';

import { DailyMotionPlayer, PremiumBadge } from '@app/components';
import { PremiumBadgeWrapper } from '@app/components/News/NewsItemSections';

import { CarouselSlideCta } from './CarouselSlideCta';

interface CarouselSlideProps {
  isOverlapped?: boolean;
  slide: TopNews;
}

export const CarouselSlide = ({ isOverlapped = false, slide }: CarouselSlideProps): React.ReactElement => {
  const [isPaused, setPausedState] = useState(true);
  const isWideScreen = useMedia(Devices.tablet);
  // temporary don't show player
  const isVideo = false;
  const image = isWideScreen ? slide?.image : slide?.verticalImage;
  const imageConfig = getFullScreenImgConfig(image.url);

  return (
    <Slide isOverlapped={isOverlapped}>
      <BackgroundGradient />
      {isVideo ? (
        <DailyMotionPlayer
          onPlay={(): void => { setPausedState(false); }}
          onPause={(): void => { setPausedState(true); }}
          videoId={slide?.videoId}
        />
      ) : (
        <LazyLoadImage
          src={imageConfig.src}
          srcSet={imageConfig.srcSet}
          sizes={imageConfig.sizes}
          placeholderSrc={`${image.url}?w=20&auto=format`}
          alt={image.description}
          draggable={false}
          effect="blur"
          width="100%"
        />
      )}
      {isPaused && (
        <InfoContainer isVideo={isVideo} isOverlapped={isOverlapped}>
          <Metadata isVideo={isVideo}>
            <Title>{ slide?.title }</Title>
            <Subtitle>{ slide?.subTitle }</Subtitle>
          </Metadata>
          <CarouselSlideCta slide={slide} />
        </InfoContainer>
      )}
      {slide?.isPremium && (
        <PremiumBadgeWrapper>
          <PremiumBadge />
        </PremiumBadgeWrapper>
      )}
    </Slide>
  );
};

const Slide = styled.section<{isOverlapped: boolean}>`
  position: relative;
  background-color: black;
  height: 105vw; // imageHeight/imageWidth * 100% = (750 / 710) * 100

  @media ${Devices.tablet} {
    height: 47vw; // imageHeight/imageWidth * 100% = (600 / 1280) * 100
  }

  @media ${Devices.desktopSmall} {
    height: 600px;
  }

  // 760px is image height(600px) + header height (160px)
  @media ${Devices.desktopSmall} and (max-height: 760px) and (min-height: 550px) {
    height: ${({ isOverlapped }): string => (isOverlapped ? 'calc(100vh - 160px + 40px)' : 'calc(100vh - 160px)')};
  }
`;

const BackgroundGradient = styled.div`
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 100%);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  pointer-events: none;

  @media ${Devices.desktop} {
    background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%);
  }
`;

const InfoContainer = styled.div<{ isVideo: boolean; isOverlapped: boolean }>`
  position: absolute;
  bottom: ${({ isOverlapped }): string => (isOverlapped ? '100px' : '70px')};
  right: 0;
  width: ${({ isVideo }): string => (isVideo ? '75%' : '100%')};
  padding: ${({ isVideo }): string => (isVideo ? '0 20px 0 0' : '0 20px')};
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  flex-direction: column;
  align-items: ${({ isVideo }): string => (isVideo ? 'flex-end' : 'flex-start')};
  z-index: 101;

  @media ${Devices.tablet} {
    padding: 0 40px;
  }

  @media ${Devices.tabletLarge} {
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
  }

  @media ${Devices.desktopSmall} {
    padding: 0 70px;
  }
`;

const Metadata = styled.div<{ isVideo: boolean }>`
  margin-right: 0;
  z-index: 2;
  text-align: ${({ isVideo }): string => (isVideo ? 'right' : 'left')};

  @media ${Devices.tabletLarge} {
    margin-right: 30px;
    text-align: left;
    flex-grow: 1;
  }
`;

const Title = styled.h2`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media ${Devices.tabletLarge} {
    font-size: 32px;
    margin-bottom: 15px;
  }
`;

const Subtitle = styled.p`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media ${Devices.tabletLarge} {
    font-size: 18px;
    display: -webkit-box;
  }
`;
