import { CurrentRouteActions } from '@app/store/actionTypes/currentRouteActionTypes';

import { LanguageType } from '@app/types/localizationTypes';

export type CurrentRouteState = {[key in LanguageType]: string};

const initialState: CurrentRouteState = {
  en: '/en',
  it: '/it',
  cn: '/cn',
};

export default (
  state = initialState,
  action: CurrentRouteActions,
): CurrentRouteState => (
  action.type === 'ROUTE/SET_CURRENT_ROUTE' ? action.payload : state
);
