import React from 'react';
import { FormattedMessage } from 'react-intl';
import noop from 'lodash/noop';
import styled from 'styled-components';

import { SwitchLanguageType } from '@app/types/localizationTypes';
import { ExpandArrow } from '@app/components/Icons';
import { Devices } from '@app/styles';

export enum ExpandArrowState {
  Closed,
  Opened,
  Hidden
}

interface LanguageProps {
  onClick?: (e: MouseEvent) => void;
  lang: SwitchLanguageType;
  expandArrow: ExpandArrowState;
  className?: string; // passed by styled component automatically
}

const Language = ({
  onClick, lang, expandArrow, className,
}: LanguageProps): React.ReactElement => (
  <Lang onClick={onClick || noop} className={className} isOpened={expandArrow === ExpandArrowState.Opened}>
    <div>
      <LangIcon src={`/images/${lang}.png`} alt={`${lang} language`} />
      <LangLabel>
        <FormattedMessage id={lang} />
      </LangLabel>
    </div>
    {
      (expandArrow !== ExpandArrowState.Hidden) && (
        <ExpandArrowContainer isOpened={expandArrow === ExpandArrowState.Opened}>
          <ExpandArrow />
        </ExpandArrowContainer>
      )
    }
  </Lang>
);

const Lang = styled.li<{ isOpened: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  margin: 0 15px;
  border: none;
  letter-spacing: 0.29px;

  &:last-child {
    border: none;
  }

  & > div:first-of-type {
    display: flex;
  }

  @media ${Devices.desktopSmall} {
    height: 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }
`;

const LangIcon = styled.img`
  height: 16px;
  margin: 0 5px;

  @media ${Devices.desktopSmall} {
    height: 12px;
    margin: 0 5px 0 0;
  }
`;

const LangLabel = styled.span`
  display: inline-block;
  width: max-content;
`;

const ExpandArrowContainer = styled.div<{isOpened: boolean}>`
  transform: rotate(${({ isOpened }): string => (isOpened ? '180deg' : '0')});
  display: block;
  margin-left: 15px;

  & g[id='icon'] {
    stroke: white;
  }

  @media ${Devices.desktopSmall} {
    & g[id='icon'] {
      stroke: ${({ isOpened }): string => (isOpened ? 'white' : 'black')};
    }
  }
`;

export default Language;
