import React from 'react';
import styled from 'styled-components';
import { useLocation, Link } from 'react-router-dom';

import { WebFaqCategory } from '@app/types/webTemplateTypes';

const WebFaqCategoryElement: React.FC<{data: WebFaqCategory}> = ({ data }) => {
  const { pathname } = useLocation();

  return (
    <div>
      <CategoryTitle>{data.categoryName}</CategoryTitle>
      <QuestionList>
        {
          data.questions?.map(({ question, urlSlug }) => (
            <Question>
              <QuestionLink to={`${pathname}/${urlSlug}`} key={urlSlug}>{question}</QuestionLink>
            </Question>
          ))
        }
      </QuestionList>
    </div>
  );
};

export default WebFaqCategoryElement;

const CategoryTitle = styled.h2`
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 16px;
  margin-top: 33px;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  text-transform: uppercase;
`;

const QuestionList = styled.ol`
  padding-left: 15px;
`;

const Question = styled.li`
  font-size: 16px;
  margin-bottom: 24px;
`;

const QuestionLink = styled(Link)`
  color: black;
`;
