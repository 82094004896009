import { Coach, Player, PlayerPosition } from '@app/types/teamsTypes';
import * as React from 'react';
import { PlayerCard } from '@app/pages/Teams/Members/Player';
import { CoachCard } from '@app/pages/Teams/Members/Coach';

interface PlayerCardProps {
  item: Player | Coach;
  teamType: string;
}

export const MemberCard = ({ item, teamType }: PlayerCardProps): React.ReactElement => (
  item.position === PlayerPosition.Coach ? (
    <CoachCard item={item as Coach} />
  ) : (
    <PlayerCard item={item as Player} teamType={teamType} />
  )
);
