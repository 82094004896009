import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { WebHallOfFameInductee } from '@app/types/webTemplateTypes';
import { Devices } from '@app/styles';

export const HOFInducteeHero = (inductee: WebHallOfFameInductee): React.ReactElement => (
  <Hero>
    <Image src={`${inductee.image.hero.url}?w=1980&q=100&format=auto`} alt={inductee.image.hero.description} />
    <h1 style={{ position: 'absolute', opacity: 0 }}>{inductee.name.full}</h1>
    <Content>
      {inductee.number ? (
        <Number>
          <Value>{inductee.number < 10 ? (`0${inductee.number}`).slice(-2) : inductee.number}</Value>
          <RedLine />
        </Number>
      ) : null}
      <Information>
        <Name>
          <First>{inductee.name.first}</First>
          <Last>{inductee.name.last}</Last>
        </Name>

        <Carrier>
          <Years>{inductee.career.years.main}</Years>
          {inductee.career.years.additional ? (
            <Years>{inductee.career.years.additional}</Years>
          ) : null}
          <Position>
            <FormattedMessage id={`hof.inductee.position.${inductee.role}`} />
            <RedLineX />
          </Position>
        </Carrier>
      </Information>
    </Content>
  </Hero>
);

const Hero = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 3/4;
  background-color: rgba(0, 0, 0, 0.69);
  overflow: hidden;
  z-index: 0;

  @media ${Devices.tabletLarge} {
    aspect-ratio: 2/1;
  }

  &:after {
    position: absolute;
    content: " ";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: default;

    background-image: url(/images/hall-of-fame/hero.svg);
    background-size: cover;
    background-position: center;
    z-index: 0;
  }
`;

const Image = styled.img`
  position: absolute;
  object-position: center top;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-content: flex-end;
  align-items: flex-start;
  gap: 20px;

  width: 100%;
  max-width: 960px;
  padding: 0 20px 70px;
  height: 100%;
  color:  white;
  z-index: 19;
  margin: auto;

  @media ${Devices.tabletLarge} {
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-end;
    gap: 20px;

    padding: 0 0 100px;
  }
`;

const Number = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: end;
  gap: 20px;
`;

const Value = styled.div`
  font-family: MilanPulseRegular, sans-serif;
  font-size: 90px;
  line-height: 100px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;

  color: #d8be6d;
  text-align: center;

  @media ${Devices.tabletLarge} {
    font-size: 180px;
    line-height: 205px;
  }
`;

const RedLine = styled.div`
  width: 3px;
  height: 40px;
  background-color: #d50000;
`;

const RedLineX = styled.div`
  width: 15px;
  height: 2px;
  background-color: #d50000;
`;

const Information = styled.div`
  position: relative;
`;

const Name = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0;

  font-family: MilanPulseRegular, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  color: #fff;
  text-transform: uppercase;
`;

const First = styled.span`
  font-size: 24px;
  // line-height: 2.17;

  @media ${Devices.tabletLarge} {
    font-size: 30px;
    // line-height: 60px;
  }
`;

const Last = styled.span`
  font-size: 50px;
  line-height: 52px;

  @media ${Devices.tabletLarge} {
    font-size: 90px;
    line-height: 93px;
    margin-bottom: 5px;
  }
`;

const Carrier = styled.div`
  position: absolute;
  bottom: -40px;
  left: 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;

  @media ${Devices.tabletLarge} {
    bottom: -50px;
  }
`;

const Years = styled.span`
  display: block;
  font-family: Raleway, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.76;
  letter-spacing: 0.16px;
  text-wrap: nowrap;
  color: #fa2528;
`;

const Position = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  font-family: Raleway, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.33px;
  text-transform: none;
  color: #fff;
`;
