import React from 'react';

import { App } from '@app/components';
import {
  ArticleLanding, VideoLanding, GalleryLanding, Articles, Videos, PhotoGallery, Home,
  MatchCenter, PlayerProfile, Teams, Ticketing, WebTemplate, Search,
} from '@app/pages';
import {
  Season, Schedule, Standings,
} from '@app/pages/Season';

import { RoutePath } from '@app/types/routerTypes';
import VanityUrls from '@app/pages/VanityUrls/VanityUrls';

type AppRoutes = Record<string, {
  path: RoutePath;
  component: React.ComponentClass | React.FunctionComponent<unknown> | undefined;
  exact?: boolean;
}>

const AppRoutes: AppRoutes = {
  App: {
    path: 'routes.home',
    component: App,
  },
  Home: {
    path: 'routes.home',
    component: Home,
    exact: true,
  },
  Articles: {
    path: 'routes.articles',
    component: Articles,
    exact: true,
  },
  Videos: {
    path: 'routes.videos',
    component: Videos,
    exact: true,
  },
  Gallery: {
    path: 'routes.gallery',
    component: PhotoGallery,
    exact: true,
  },
  ArticleLanding: {
    path: 'routes.articleLanding',
    component: ArticleLanding,
  },
  VideoLanding: {
    path: 'routes.videoLanding',
    component: VideoLanding,
  },
  GalleryLanding: {
    path: 'routes.galleryLanding',
    component: GalleryLanding,
  },
  Teams: {
    path: 'routes.teams',
    component: Teams,
    exact: true,
  },
  PlayerProfile: {
    path: 'routes.player_profile',
    component: PlayerProfile,
  },
  MatchCenter: {
    path: 'routes.matchCenter',
    component: MatchCenter,
  },
  Search: {
    path: 'routes.search',
    component: Search,
  },
  Season: {
    path: 'routes.season',
    component: Season,
  },
  Schedule: {
    path: 'routes.schedule',
    component: Schedule,
  },
  Standings: {
    path: 'routes.standings',
    component: Standings,
  },
  Ticketing: {
    path: 'routes.tickets',
    component: Ticketing,
  },
  WebTemplate: {
    path: 'routes.webTemplate',
    component: WebTemplate,
    exact: true,
  },
  WebTemplateTab: {
    path: 'routes.webTemplateTab',
    component: WebTemplate,
    exact: true,
  },
  NotFound: {
    path: 'routes.notFound',
    component: VanityUrls,
  },
};

export default AppRoutes;
