import React from 'react';

import { Errors } from '@app/types/errorTypes';

import { useWebTemplateLoading } from '@app/pages/WebTemplate/WebTemplateHooks';

import { Loader } from '@app/components';
import PageValidationWrapper from './PageValidationWrapper';

type PageWrapper = React.FC<{
  error: Errors;
}>

const PageWrapper: PageWrapper = ({ children, error }) => {
  const isLoading = useWebTemplateLoading();

  return (
    <PageValidationWrapper error={error}>
      { isLoading ? (<Loader />) : children }
    </PageValidationWrapper>
  );
};

export default PageWrapper;
